import React, { type ReactNode, forwardRef } from "react";
import { View } from "react-native";
import { type UseTransformFlexProps, useTransformFlexProps } from "../style";

export type Props = Omit<UseTransformFlexProps, "direction"> & {
  reverse?: boolean;
  children?: ReactNode;
};

// eslint-disable-next-line react/prop-types
const Column = forwardRef<View, Props>(({ reverse, ...props }, ref) => {
  const transformedProps = useTransformFlexProps({
    direction: reverse ? "column-reverse" : "column",
    ...props
  });
  return <View ref={ref} {...transformedProps} />;
});

export default Column;
