import React from "react";
import type { ReactNode } from "react";
import Column from "../quarks/Column";
import Icon from "../quarks/Icon";
import type { IconName } from "../quarks/Icon";
import Row from "../quarks/Row";
import Spacer, { type UnitSize } from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useStyles } from "../style";

interface Props {
  testID?: string;
  title: string;
  icon: IconName;
  gap?: UnitSize;
  action?: ReactNode;
  children?: ReactNode;
}

export default function SurfaceSection({
  testID,
  title,
  icon,
  action,
  gap,
  children
}: Props) {
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      container: {
        paddingLeft: getUnits(4),
        paddingRight: getUnits(2),
        paddingVertical: getUnits(2),
        backgroundColor: getColor("foreground", "fill"),
        borderRadius: getUnits(1)
      },
      childrenContainer: {
        padding: getUnits(4),
        paddingLeft: getUnits(11)
      },
      text: { flex: 1, marginLeft: getUnits(2) },
      icon: {
        width: 20,
        textAlign: "center"
      }
    }),
    []
  );
  return (
    <Column testID={testID}>
      <Row style={styles.container} alignItems="center">
        <Icon
          name={icon}
          size="small"
          color="primary"
          variant="solid"
          style={styles.icon}
        />
        <Text style={styles.text} fill color="primary" weight="bold">
          {title}
        </Text>
        {action}
        <Spacer horizontal />
      </Row>
      <Column style={styles.childrenContainer} gap={gap}>
        {children}
      </Column>
    </Column>
  );
}
