import React, { forwardRef, type ReactNode } from "react";
import { View } from "react-native";
import { type ViewStyle, useStyle } from "../style";

interface Props {
  children: ReactNode;
  style?: ViewStyle;
  fill?: true | number;
}

const FullWidthContainer = forwardRef<View, Props>(
  ({ style, children, fill }, ref) => {
    const fullWidthStyle = useStyle(() => ({
      width: "100%",
      flex: fill === true ? 1 : fill
    }));
    return (
      <View ref={ref} style={[style, fullWidthStyle]}>
        {children}
      </View>
    );
  }
);

export default FullWidthContainer;
