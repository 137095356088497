import type { TypeForStyleKey } from "../type-helpers";
export default function margin(...args: Array<TypeForStyleKey<"margin">>) {
  if (args.length === 1) {
    return { margin: args[0] };
  }
  if (args.length === 2) {
    return {
      marginVertical: args[0],
      marginHorizontal: args[1]
    };
  }
  if (args.length === 3) {
    return {
      marginTop: args[0],
      marginHorizontal: args[1],
      marginBottom: args[2]
    };
  }
  return {
    marginTop: args[0],
    marginRight: args[1],
    marginBottom: args[2],
    marginLeft: args[3]
  };
}
