import * as Immutable from "immutable";
import {
  type DependencyList,
  type ReactElement,
  useEffect,
  useState
} from "react";

import { useFormSubmit } from "./form-submit";
import { errorsEqual, processFieldErrors } from "./helpers";
import { type FormSceneType, useActor, useInit, useResponder } from "./scene";
import type { Validator } from "./types";

export interface FormFieldOptions<T = any> {
  name: string;
  validates?: Validator<T> | Array<Validator<T>> | null;
  sensitive?: boolean | string;
  shouldShowErrors?: (scene: FormSceneType) => boolean;
  initialValue?: T | null;
}

export interface FormFieldArgs<T> {
  value: T | null;
  initialValue: T | null;
  dirty: boolean;
  invalid: boolean;
  setValue: (v: T | null) => void;
  removeField: () => void;
  setErrors: (err?: Error[] | null | undefined) => void;
  errors: Error[] | null;
  errorMessage: string | null;
  showErrors: () => void;
  hideErrors: () => void;
  name: string;
  submit: () => void;
  submitting: boolean;
  formDirty: boolean;
  formInvalid: boolean;
  triggerFocus: () => void;
  triggerBlur: () => void;
}

export function useFormField<T>(
  options: string | FormFieldOptions<T>,
  deps: DependencyList = []
): FormFieldArgs<T> {
  const {
    name,
    validates = [],
    shouldShowErrors,
    sensitive,
    initialValue: formFieldInitialValue
  }: FormFieldOptions<T> = typeof options === "string"
    ? { name: options, validates: [] }
    : options;

  const {
    submit,
    submitting,
    dirty: formDirty,
    invalid: formInvalid
  } = useFormSubmit();
  const [showErrorsInternalValue, setShowErrors] = useState(false);
  const [initial, setInitial] = useState<T | null>(null);
  const [value, setInternalValue] = useState<T | null>(null);
  const [errors, setInternalErrors] = useState<Error[] | null>(null);

  // Show the errors
  const showErrors = () => {
    setShowErrors(true);
  };

  // Hide the errors
  const hideErrors = () => {
    setShowErrors(false);
  };
  // Trigger Focus Actions
  const triggerFocus = () => {
    if (typeof sensitive !== "undefined" && !dirty) {
      sensitive === true ? setValue(null) : triggerSensitive();
    }
  };

  // Trigger Blur actions
  const triggerBlur = () => {
    showErrors();
  };

  // Get the Initial value
  const initialValue = initial;

  // Track Dirty
  const dirty = !Immutable.is(initialValue, value);

  const deregisterSensitives = (prevState: any, name: string) => {
    let sensitives = prevState.sensitives;
    sensitives.forEach((_value: string, key = "") => {
      let set = sensitives.get(key);
      if (set) set = set.delete(name);
      sensitives =
        !set || set.isEmpty()
          ? sensitives.remove(key)
          : sensitives.set(key, set);
    });

    return sensitives;
  };
  // Build the set value function
  const setValue = useActor<T | null>(
    "values",
    (nextValue, prevState) => {
      // track sensitive values
      let sensitives = prevState.sensitives;
      if (nextValue !== null && typeof sensitive === "string" && !dirty) {
        // regisister the field as sensitive
        const set = sensitives.get(sensitive) ?? Immutable.Set<string>();
        sensitives = sensitives.set(sensitive, set.add(name));
      } else {
        // deregister the field as sensitive
        sensitives = deregisterSensitives(prevState, name);
      }

      return {
        ...prevState,
        sensitives,
        values: prevState.values.set(name, nextValue)
      };
    },

    [name]
  );

  const removeField = useActor<T | null>(
    "values",
    (_, prevState) => {
      const sensitives = deregisterSensitives(prevState, name);
      return {
        ...prevState,
        sensitives,
        values: prevState.values.delete(name)
      };
    },
    [name]
  );

  // Dispatch the error changes
  const setErrors = useActor<Error[] | null>(
    "errors",
    (nextErrors, prevState) => ({
      ...prevState,
      errors: errorsEqual(prevState.errors.get(name), nextErrors)
        ? prevState.errors
        : prevState.errors.set(name, nextErrors)
    }),
    [name]
  );

  useEffect(() => {
    return () => {
      setErrors([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useResponder(
    "values",
    (scene) => {
      const { values: allValues } = scene;
      let nextValue = allValues.get(name);
      if (typeof nextValue === "undefined") nextValue = null;
      const nextErrors = processFieldErrors(
        name,
        nextValue,
        validates,
        allValues
      );

      setErrors(nextErrors);
      if (!Immutable.is(nextValue, value)) setInternalValue(nextValue);
      if (shouldShowErrors?.(scene)) showErrors();
    },
    [name, setErrors, setInternalValue, value, shouldShowErrors, validates]
  );

  // Update internal errors
  useResponder(
    "errors",
    ({ errors: allErrors }) => {
      const nextErrors = allErrors.get(name) ?? null;
      const errorsMatch = errorsEqual(nextErrors, errors);
      if (!errorsMatch) setInternalErrors(nextErrors);
    },
    [name, setInternalErrors, errors]
  );

  // Update showErrorsInternalValue when submitting
  useResponder(
    "submitting",
    ({ submitting: isSubmitting }) => {
      if (showErrorsInternalValue !== isSubmitting) setShowErrors(isSubmitting);
    },
    [name, setShowErrors]
  );

  // Set the initial values
  useInit(
    ({ initialValues, values }) => {
      const currentValue = values.get(name);
      const initValue = initialValues.get(name);
      const initErrors = processFieldErrors(
        name,
        currentValue ?? initValue,
        validates,
        currentValue ? values : initialValues
      );
      if (formFieldInitialValue || formFieldInitialValue === false) {
        initialValues?.set(name, formFieldInitialValue);
        values?.set(name, formFieldInitialValue);
        setInitial(formFieldInitialValue);
        setValue(formFieldInitialValue);
      } else {
        setInitial(initValue);
        setValue(currentValue ?? initValue);
      }
      if (initErrors) {
        setErrors(initErrors);
        setShowErrors(showErrorsInternalValue);
      }
    },
    [name, ...deps]
  );

  // Track invalid
  const invalid = errors !== null && errors.length > 0;

  const errorMessage = errors?.map(({ message }) => message).join(", ") ?? null;

  // Clear sensitive values
  const triggerSensitive = useActor<T>(
    "values",
    (_nextValue, prevState) => {
      if (typeof sensitive !== "string") return prevState;
      let values = prevState.values;
      const fields = prevState.sensitives.get(sensitive);
      if (fields) fields.forEach((key = "") => (values = values.set(key, "")));
      return {
        ...prevState,
        values
      };
    },
    [name]
  );

  // return the fields
  return {
    name,
    initialValue,
    value,
    dirty,
    invalid,
    setValue,
    removeField,
    setErrors,
    errors: showErrorsInternalValue ? errors : null,
    errorMessage: showErrorsInternalValue ? errorMessage : null,
    showErrors,
    hideErrors,
    submit,
    submitting,
    formDirty,
    formInvalid,
    triggerFocus,
    triggerBlur
  };
}
export function useFormFields<FieldName extends string>(
  specs:
    | FieldName[]
    | { [name in FieldName]: Validator<any> | Array<Validator<any>> | null }
): { [name in FieldName]: FormFieldArgs<any> } {
  type FinalSpecs = { [name in FieldName]: Array<Validator<any>> | null } | {};

  const inputs: {
    [name in FieldName]: FormFieldArgs<any>;
  } = {} as unknown as {
    [name in FieldName]: FormFieldArgs<any>;
  };
  const finalSpecs: any = Array.isArray(specs)
    ? specs.reduce<FinalSpecs>((m, n) => ({ ...m, [n]: [] }), {})
    : specs;
  for (const name of Object.keys(finalSpecs).sort((a, b) =>
    a.localeCompare(b)
  )) {
    const validates: Validator<any> | Array<Validator<any>> | null =
      Object.keys(finalSpecs).length !== 0 ? finalSpecs[name as FieldName] : [];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    inputs[name as FieldName] = useFormField({ name, validates });
  }
  return inputs;
}

type Props = FormFieldOptions<any> & {
  children?: (fieldArgs: FormFieldArgs<any>) => ReactElement | null;
};

export function FormField({
  children = () => null,
  ...formFieldOptions
}: Props) {
  return children(useFormField<any>(formFieldOptions));
}
