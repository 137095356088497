import type { ReactElement } from "react";
import { isInstrumented, useInstrument } from "./instrumentation-client";

export type OnScrollParams =
  | ReactElement
  | { readonly x?: number; readonly y?: number }
  | "end"
  | "start";

interface Props {
  targetId?: string;
  onScroll: (params: OnScrollParams) => unknown;
}

function InstrumentScroller({ targetId, onScroll }: Props) {
  useInstrument("scrollTo", targetId, (params) => onScroll(params));
  return null;
}

export default !isInstrumented ? () => null : InstrumentScroller;
