import React from "react";
import { View } from "react-native";
import { Button } from "../atoms";
import { useSafeAreaInsets } from "../quarks/SafeArea";
import { useStyles } from "../style";

interface Props {
  onPress: () => void;
}

export default function FloatingCloseButton({ onPress }: Props) {
  const { top } = useSafeAreaInsets();
  const styles = useStyles(
    ({ getUnits }) => ({
      container: {
        left: getUnits(4),
        position: "absolute",
        top: top + getUnits(4)
      },
      pressed: {
        opacity: 0.5
      }
    }),
    [top]
  );
  return (
    <View style={styles.container}>
      <Button
        color="black"
        testID="close-media-picker"
        eventTargetName="Close Media Picker Button"
        onPress={onPress}
        icon="times"
        size="small"
        variant="rounded"
      />
    </View>
  );
}
