import React from "react";
import { Platform } from "react-native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome6Pro";
import GooglePaySvg from "../brand/GooglePaySvg";
import { BrandIcon } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import { useStyles } from "../style";

interface Props {
  variant: "google" | "apple";
  onPress: () => void;
  testID: string;
  fullWidth?: boolean;
}

export default function NativePayButton({
  variant,
  onPress,
  testID,
  fullWidth = true
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      container: {
        alignSelf: fullWidth ? undefined : "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColor("surface", "placement"),
        flexDirection: "row",
        width: fullWidth ? "100%" : undefined,
        borderRadius: getUnits(1),
        height: getUnits(10)
      },
      icon: {
        fontSize: 36,
        color: "white"
      }
    }),
    [fullWidth, variant]
  );
  const Icon = Platform.OS === "ios" ? BrandIcon : FontAwesomeIcon;
  return (
    <Pressable
      testID={testID}
      eventEntityType="NativePayButton"
      eventTargetName="Native Pay Button"
      onPress={onPress}
      style={styles.container}
    >
      {variant === "google" && <GooglePaySvg height={20} />}
      {variant === "apple" && <Icon name="apple-pay" style={styles.icon} />}
    </Pressable>
  );
}
