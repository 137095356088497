import React, { type ReactComponentElement } from "react";
import { FlatList } from "react-native";

import type DocumentTile from "../atoms/DocumentTile";
import Column from "../quarks/Column";
import { useStyle } from "../style";

interface Props {
  documentTiles: Array<ReactComponentElement<typeof DocumentTile>>;
}

export default function DocumentTileContainer({ documentTiles }: Props) {
  const documentTileWrapper = useStyle(({ getUnits }) => ({
    paddingVertical: getUnits(1),
    paddingRight: getUnits(4)
  }));

  if (!documentTiles.length) return null;

  return (
    <FlatList
      horizontal
      data={documentTiles}
      renderItem={({ item, index }) => (
        <Column
          style={documentTileWrapper}
          key={`${index}`}
          testID={`documentTile-wrapper-${index}`}
        >
          {item}
        </Column>
      )}
    />
  );
}
