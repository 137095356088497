import { load } from "@fingerprintjs/botd";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { Persistence } from "@gigsmart/atorasu/utils/persistence";
import { captureError } from "@gigsmart/dekigoto";
import { DisableFpjs } from "@gigsmart/feature-flags";
import { getAuthToken } from "../relay/auth-token";
import { getDeviceId as fallbackGetDeviceId } from "./device-id-fallback";
import type { Fingerprint, FingerprintIdentity } from "./device-id-types";

const persistenceKey = Persistence.keep("___browser-identity___");
export async function getDeviceId(): Promise<Fingerprint> {
  const { bot, botKind } = {
    botKind: null,
    ...(await load().then((botd) => botd.detect()))
  };
  const fingerprint = await Persistence.fetch(persistenceKey, async () => {
    const useFpjs =
      (!!(await getAuthToken()) || DisableFpjs.isDisabled()) &&
      process.env.IS_TESTING !== "true";
    let identity: FingerprintIdentity | null = null;
    if (useFpjs) {
      try {
        const fp = await FingerprintJS.load({
          token: "GzfXVVF0zcUhUCbCDVFW",
          region: "us",
          endpoint: "https://fp.gigsmart.com"
        });
        const { visitorId } = await fp.get();
        if (visitorId)
          identity = { id: visitorId, strategy: "browserFingerprint" };
      } catch (e) {
        captureError(e);
      }
    }
    return identity ?? (await fallbackGetDeviceId());
  });
  return { ...fingerprint, bot };
}
