import { type DependencyList, useEffect } from "react";

export interface EventStore<T extends Record<string | number, unknown>> {
  useEventListener: <E extends keyof T>(
    event: E,
    fn: (payload: T[E]) => void | (() => void),
    deps?: DependencyList
  ) => void;
  addEventListener: <E extends keyof T>(
    event: E,
    fn: (payload: T[E]) => void | (() => void)
  ) => { remove: () => void };
  emit: <E extends keyof T>(event: E, payload: T[E]) => () => void;
}

export default function createEventStore<
  T extends Record<string | number, unknown>
>(): EventStore<T> {
  type Listener<E extends keyof T> = (payload: T[E]) => void | (() => void);

  const registry = new Map<keyof T, Set<Listener<keyof T>>>();

  const eventListenersFor = <E extends keyof T>(event: E): Set<Listener<E>> => {
    let set = registry.get(event);
    if (!set) registry.set(event, (set = new Set()));
    return set;
  };

  const addEventListener = <E extends keyof T>(event: E, fn: Listener<E>) => {
    eventListenersFor<E>(event).add(fn);
    return {
      remove: () => {
        eventListenersFor(event).delete(fn);
      }
    };
  };

  const useEventListener = <E extends keyof T>(
    event: E,
    fn: Listener<E>,
    deps: DependencyList = []
  ) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => addEventListener(event, fn).remove, [event, fn, ...deps]);
  };

  const emit = <E extends keyof T>(event: E, payload: T[E]) => {
    const listeners = eventListenersFor(event);
    if (!listeners.size) {
      setTimeout(emit, 100, event, payload);
      return () => [];
    }
    const disposeFns = Array.from(listeners).map((listener) =>
      listener(payload)
    );
    return () => {
      disposeFns.map((fn) => fn?.());
    };
  };

  return { useEventListener, addEventListener, emit };
}
