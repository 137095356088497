import React from "react";
import Text from "../quarks/Text";
import { useResponsiveValue } from "../style";
import { humanizeDate } from "./date-helpers";
import type { DateTimeConvertable, TimeSizes } from "./date-helpers";

interface Props
  extends Omit<
    React.ComponentProps<typeof Text>,
    "onPress" | "eventTargetName"
  > {
  startsAt: DateTimeConvertable | null;
  actualStartsAt?: DateTimeConvertable | null;
  endsAt?: DateTimeConvertable | null;
  timezone?: string | null;
  size?: TimeSizes;
  showDayOfWeek?: boolean;
}

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export default function Date({
  startsAt,
  endsAt,
  size: sizeOverride,
  timezone,
  actualStartsAt,
  showDayOfWeek,
  ...textProps
}: Props) {
  const size = useResponsiveValue({ mini: "sm", medium: "md", large: "lg" });
  return (
    <Text {...textProps}>
      {humanizeDate({
        startsAt,
        endsAt,
        timezone,
        actualStartsAt,
        showDayOfWeek,
        size: sizeOverride ?? size ?? "lg"
      })}
    </Text>
  );
}
