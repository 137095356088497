import type { ReactNode } from "react";
import { useRelayLoader } from "./use-relay-loader";

interface Props {
  children: (arg0: { isLoading: boolean; progress: number }) => ReactNode;
}

export function RelayLoader({ children }: Props) {
  const { isLoading, progress } = useRelayLoader();
  return children({ isLoading, progress });
}
