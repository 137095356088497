import ShadowView from "@gigsmart/shadow-view";
import React, { type ReactNode } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useModalContext } from "../organisms/ModalContext";
import { Column, Spacer } from "../quarks";
import { useStyles } from "../style";
import Constraint from "./Constraint";
import ContentArea from "./ContentArea";
import Stack from "./Stack";

interface Props {
  horizontal?: boolean;
  children?: ReactNode;
  zIndex?: number;
  spacing?: "none" | "compact" | "standard";
  constraint?: "xsmall" | "none";

  // customizable when outside ModalContainer
  isFull?: boolean;
  isShadow?: boolean;
}

const ModalFooter = ({
  zIndex,
  spacing,
  horizontal,
  constraint = "xsmall",
  isFull,
  isShadow,
  children
}: Props) => {
  const { size, variant } = useModalContext();
  const bottomInset = useSafeAreaInsets().bottom;
  const styles = useStyles(
    ({ getUnits }) => ({
      withShadow: {
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: 3,
        shadowOffset: { width: 0, height: -3 },
        position: "relative",
        zIndex: 1
      },
      extraSpace: { height: bottomInset / 2 },
      emptyFooter: { height: getUnits(size === "small" ? 4 : 8) }
    }),
    [size]
  );

  isFull ??= variant.includes("full");
  isShadow ??= variant.includes("shadow");

  if (!children) {
    return isFull || isShadow ? null : <Column style={styles.emptyFooter} />;
  }

  const extraBottomSpacer =
    variant === "prompt" ||
    (size !== "small" && (variant === "border" || variant === "none"));
  return (
    <ShadowView style={[isShadow && styles.withShadow, { zIndex }]}>
      <ContentArea>
        <Constraint size={constraint}>
          <Stack horizontal={horizontal} size={spacing} testID="modal-footer">
            {children}
          </Stack>
        </Constraint>
      </ContentArea>

      {extraBottomSpacer && <Spacer size="large" />}
      {isFull && <Column style={styles.extraSpace} />}
    </ShadowView>
  );
};

export default ModalFooter;
