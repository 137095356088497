import type { LatLng } from "react-native-maps";

interface Props {
  variant?: "default";
  coordinate: LatLng;
}

/**
 * Web fallback
 */
export default function MapCircle(_props: Props) {
  return null;
}
