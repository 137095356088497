import ShadowView from "@gigsmart/shadow-view";
import React, { useMemo } from "react";

import { Surface } from "../../atoms";
import { Column, Divider, FancyScroll, Row } from "../../quarks";
import { useStyles } from "../../style";
import { typedMemo } from "../../utils";
import { useCalendarContext } from "./CalendarContext";
import DayHeader from "./DayHeader";
import DayIndicator from "./DayIndicator";
import WeekRow from "./WeekRow";
import { type CalendarVariant, renderWithDivider, weeklyView } from "./helpers";

type Props<T> = CalendarVariant<T>;

export default typedMemo(
  function WeeklyCalendar<T>({ date, events, renderDay }: Props<T>) {
    const { isMd } = useCalendarContext();
    const view = useMemo(() => weeklyView(date), [date]);
    const styles = useStyles(({ getColor }) => ({
      shadow: {
        backgroundColor: getColor("surface", "fill"),
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 3 }
      }
    }));

    return (
      <Surface variant="outline" fill>
        <ShadowView style={!isMd && styles.shadow}>
          <Row>
            {renderWithDivider(view, "y", (it) =>
              isMd ? (
                <DayHeader
                  fill
                  color={it.isToday ? "primary" : "neutral"}
                  label={it.value.toFormat(it.isToday ? "EEE" : "EEE d")}
                >
                  {it.isToday && <DayIndicator day={it} />}
                </DayHeader>
              ) : (
                <Column fill>
                  <DayHeader label={it.value.toFormat("EEE")[0]} />
                  <DayIndicator day={it} />
                </Column>
              )
            )}
          </Row>
        </ShadowView>

        {isMd && <Divider />}
        <FancyScroll testID="weekly-calendar-scroll" fill>
          <WeekRow
            grow
            displayDayIndicator={false}
            week={view}
            events={events}
            renderDay={renderDay}
          />
        </FancyScroll>
      </Surface>
    );
  },
  (p1, p2) =>
    p1.events === p2.events && p1.date.weekNumber === p2.date.weekNumber
);
