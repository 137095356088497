import type { DeepPartial } from "@gigsmart/type-utils";
import { merge } from "lodash";

import fontColorContrast from "font-color-contrast";
import colors, { type Color, type ColorProp, type ColorSpec } from "./colors";
import fonts from "./fonts";
import measurements from "./measurements";
import media from "./media";

/** @deprecated there's no "fluid" layout anymore */
export type ThemeLayout = "contained" | "fluid";
export type ThemeVariables = typeof baseVariables;
export type Theme = ReturnType<typeof applyThemeHelpers>;

interface GetColorOptions {
  fallthrough?: boolean;
  opacity?: number;
}

const baseVariables = {
  dark: false,
  /** @deprecated there's no "fluid" layout anymore */
  layout: "contained" as ThemeLayout,
  measurements,
  fonts,
  colors,
  media
};

const applyThemeHelpers = (theme: ThemeVariables) => {
  const getUnits = (multiplier = 1) => theme.measurements.units * multiplier;
  const getFontSize = (multiplier = 3.5, lineHeight = 1.2) => {
    const fontSize = getUnits(multiplier);
    return { fontSize, lineHeight: Math.round(fontSize * lineHeight) };
  };
  const getColorSpec = (
    color: Color | undefined,
    type: keyof ColorSpec,
    fallthrough = true
  ): ColorSpec | undefined => {
    if (typeof color === "string" && color in theme.colors) {
      return theme.colors[color as ColorProp];
    }
    if (!color || !fallthrough) return;
    const contrastColor = fontColorContrast(color as string, 0.6) as Color;
    return type === "fill"
      ? { fill: contrastColor, placement: color }
      : { fill: color, placement: contrastColor };
  };
  const getColor = (
    color: Color | undefined,
    type: keyof ColorSpec,
    { fallthrough = true, opacity }: GetColorOptions = {}
  ): Color | undefined => {
    const spec = getColorSpec(
      color,
      type === "placement" ? "fill" : "placement",
      fallthrough
    );
    let localColor: string | undefined = spec?.[type];
    if (localColor && opacity && localColor !== "transparent") {
      localColor =
        String(localColor).slice(0, 7) +
        Math.floor(opacity * 0xff).toString(16);
    }
    return localColor as Color;
  };

  return { ...theme, getUnits, getFontSize, getColor, getColorSpec };
};

export const createTheme = (
  ...overrides: Array<DeepPartial<Theme> | undefined>
): Theme => {
  return applyThemeHelpers(merge({}, ...overrides));
};

export const defaultTheme = createTheme(baseVariables);
