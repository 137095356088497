import React, { type ReactElement } from "react";
import { Image, StyleSheet, View } from "react-native";
import Grayscale from "../quarks/Grayscale";
import Icon, { type IconName, type IconVariant } from "../quarks/Icon";
import Text from "../quarks/Text";
import { ColorPlacementProvider } from "../style/colorPlacement";
import { useStyles } from "../style/styles";
import type { Color } from "../style/theme/colors";
import type Sticker from "./Sticker";

const specs = {
  imageSize: { small: 28, medium: 36, large: 56, xLarge: 100 },
  // FIXME: doesnt vary with sizes
  squareSize: { small: 36, medium: 36, large: 46, xLarge: 100 },
  textSize: { small: 3, medium: 3, large: 6, xLarge: 8 },
  iconSize: {
    small: "tiny",
    medium: "medium",
    large: "large",
    xLarge: "extraLarge"
  } as const
};

export interface Props {
  variant?: "default" | "square";
  icon?: IconName;
  iconColor?: Color;
  iconVariant?: IconVariant;
  testID?: string;
  initials?: string;
  size?: "small" | "medium" | "large" | "xLarge";
  iconSize?: "small" | "medium" | "large" | "extraLarge";
  uri?: string | null;
  color?: Color;
  disabled?: boolean;
  sticker?: ReactElement<typeof Sticker>;
  fade?: boolean;
}

export function getAvatarSize({
  variant,
  size = "large"
}: Pick<Props, "variant" | "size">) {
  const isSquare = variant === "square";
  return isSquare ? specs.squareSize[size] : specs.imageSize[size];
}

export function getDisplayInitials(name?: string | null) {
  if (!name) return "";
  return name
    .split(" ")
    .map((piece) => piece[0])
    .join("")
    .substring(0, 2)
    .toUpperCase();
}

export default function Avatar({
  variant,
  size = "large",
  iconSize,
  uri,
  color = "primary",
  disabled,
  sticker,
  icon,
  testID,
  iconColor,
  iconVariant,
  initials,
  fade
}: Props) {
  const styles = useStyles(
    ({ getColor, getFontSize }) => {
      const isSquare = variant === "square";
      const px = getAvatarSize({ variant, size });
      const borderRadius = isSquare ? 4 : px / 2;
      const bgColor = fade || !uri ? color : "surface";

      return {
        container: {
          height: px,
          width: px,
          borderRadius,
          backgroundColor: getColor(bgColor, "fill", {
            opacity: isSquare || fade ? 0.08 : undefined
          }),
          justifyContent: "center",
          alignItems: "center"
        },
        text: {
          ...getFontSize(specs.textSize[size])
        },
        sticker: {
          position: "absolute",
          display: size === "small" ? "none" : "flex",
          right: 0,
          bottom: 0
        },
        img: {
          ...StyleSheet.absoluteFillObject,
          height: px,
          width: px,
          borderRadius
        }
      };
    },
    [size, variant, color, fade, uri]
  );

  const tintColor = variant === "square" ? color : undefined;

  // fallback element is rendered in the background while the image is loading
  const fallback = icon ? (
    <Icon
      name={icon}
      size={iconSize ?? specs.iconSize[size]}
      variant={iconVariant ?? "solid"}
      color={tintColor ?? iconColor}
    />
  ) : initials ? (
    <Text style={styles.text} color={tintColor}>
      {initials}
    </Text>
  ) : null;

  // avatar image
  const img = !!uri && (
    <View style={StyleSheet.absoluteFill}>
      <Grayscale enabled={disabled}>
        <Image style={styles.img} source={{ uri }} />
      </Grayscale>
    </View>
  );

  return (
    <ColorPlacementProvider color={color}>
      <View testID={testID} style={styles.container}>
        {fallback}
        {img}
        {!!sticker && <View style={styles.sticker}>{sticker}</View>}
      </View>
    </ColorPlacementProvider>
  );
}

Avatar.defaultProps = {
  variant: "icon"
};
