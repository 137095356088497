import React, { type ReactNode, type ComponentProps } from "react";

import Column from "../quarks/Column";

interface Props extends ComponentProps<typeof Column> {
  width: number;
  children: ReactNode;
}

export default function MaxWidthContainer({ width, children, ...rest }: Props) {
  return (
    <Column {...rest} style={{ width }}>
      {children}
    </Column>
  );
}
