import type { StepperMethods, StepperState } from "./Stepper.types";

type Direction = 1 | -1;

export const BaseStepperMethods = <T, TData>(): StepperMethods<T, TData> => ({
  getInitialState(steps, initialData, initialStepName) {
    const state: StepperState<T, TData> = ensureAvailableStepIndex({
      steps,
      data: initialData,
      index: initialStepName
        ? steps.findIndex((it) => it.name === initialStepName)
        : 0
    });

    return state;
  },
  updateSteps(steps, prevState) {
    const state: StepperState<T, TData> = ensureAvailableStepIndex({
      ...prevState,
      steps
    });

    /// sanity check
    if (!state.steps[state.index]) {
      throw new Error("Warning: No current step");
    }

    return state;
  },

  nextStep: handleStepChange(1),
  prevStep: handleStepChange(-1),
  gotoStep: (stepName, state) => {
    const nextIndex = nextAvailableStepIndex(state, stepName, false);
    return { ...state, index: nextIndex };
  }
});

const handleStepChange =
  (diff: Direction) =>
  (state: StepperState<any, any>): StepperState<any, any> => {
    const nextIndex = nextAvailableStepIndex(state, diff, false);
    return { ...state, index: nextIndex };
  };

const nextAvailableStepIndex = (
  state: StepperState<any, any>,
  diff: Direction | string,
  clamp = true
): number => {
  const len = state.steps.length;
  let nextIndex: number;
  if (typeof diff === "string") {
    nextIndex = state.steps.findIndex((it) => it.name === diff);
    diff = -1;
  } else {
    nextIndex = state.index + diff;
  }
  while (nextIndex >= 0 && nextIndex < len) {
    const step = state.steps[nextIndex];
    if (!step?.hidden?.(state.data)) break;
    nextIndex = nextIndex + diff;
  }

  if (clamp) {
    if (nextIndex < 0) nextIndex = 0;
    if (nextIndex >= len) nextIndex = len - 1;
  }

  return nextIndex;
};

const ensureAvailableStepIndex = (
  state: StepperState<any, any>,
  recover: Direction = -1
): StepperState<any, any> => {
  /// if the steps change, we need to make sure the current step is still valid
  const step = state.steps[state.index];
  if (step?.hidden?.(state.data)) {
    return { ...state, index: nextAvailableStepIndex(state, recover) };
  }

  return state;
};
