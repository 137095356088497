import React from "react";
import Column from "../quarks/Column";
import Icon, { type IconName, type IconVariant } from "../quarks/Icon";
import type { FlexStyle } from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";
import { useStyles } from "../style/styles";
import type { Color } from "../style/theme/colors";

export interface Props {
  size: keyof typeof specs.size;
  icon: IconName;
  iconColor?: Color;
  color: Color;
  iconVariant?: IconVariant;
  variant?: "standard" | "well";
  alignSelf?: FlexStyle["alignSelf"];
}

const specs = {
  size: {
    micro: { width: 16, height: 16, borderRadius: 8 },
    tiny: { width: 24, height: 24, borderRadius: 12 },
    small: { width: 28, height: 28, borderRadius: 14 },
    medium: { width: 56, height: 56, borderRadius: 28 },
    large: { width: 124, height: 124, borderRadius: 62 },
    extraLarge: { width: 160, height: 160, borderRadius: 80 }
  },
  icon: {
    micro: { fontSize: 8, lineHeight: 8 * 1.2 },
    tiny: { fontSize: 14, lineHeight: 14 * 1.2 },
    small: { fontSize: 16, lineHeight: 16 * 1.2 },
    medium: { fontSize: 28, lineHeight: 28 * 1.2 },
    large: { fontSize: 64, lineHeight: 64 * 1.2 },
    extraLarge: { fontSize: 80, lineHeight: 80 * 1.2 }
  }
};

export default function IconCircle({
  size,
  icon,
  iconColor,
  iconVariant = "solid",
  variant = "standard",
  color,
  alignSelf
}: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      icon: specs.icon[size],
      column: {
        ...specs.size[size],
        backgroundColor: getColor(color, "fill", {
          opacity: variant === "well" ? 0.08 : undefined
        })
      },
      wrapper: {
        ...specs.size[size],
        backgroundColor: getColor("surface", "fill")
      }
    }),
    [size]
  );

  return (
    <ColorPlacementProvider color={color}>
      <Column style={styles.wrapper} alignSelf={alignSelf}>
        <Column
          style={styles.column}
          justifyContent="center"
          alignItems="center"
        >
          <Icon
            style={styles.icon}
            name={icon}
            color={iconColor ?? (variant === "well" ? color : iconColor)}
            variant={iconVariant}
          />
        </Column>
      </Column>
    </ColorPlacementProvider>
  );
}
