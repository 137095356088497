import React, { type ReactElement } from "react";
import ContentArea from "../atoms/ContentArea";
import IconCircle from "../atoms/IconCircle";
import Stack from "../atoms/Stack";
import Surface from "../atoms/Surface";
import type { IconName } from "../quarks/Icon";
import Text from "../quarks/Text";

interface Props {
  iconName: IconName;
  message: string | ReactElement;
}

const EmptyViewAlt = ({ iconName, message }: Props) => (
  <Surface variant="outline">
    <ContentArea>
      <Stack alignItems="center">
        <IconCircle
          icon={iconName}
          size="medium"
          color="neutral"
          variant="well"
        />
        <Text align="center">{message}</Text>
      </Stack>
    </ContentArea>
  </Surface>
);

export default EmptyViewAlt;
