import * as Crashlytics from "@gigsmart/isomorphic-crashlytics";
import {
  addErrorHook,
  addIdentifyHook,
  addScreenHook,
  addTrackHook,
  collectHooks
} from "../hooks";

export default async () => {
  await Crashlytics.setNumberValue(
    "js-bundle-version",
    Number(process.env.PACKAGE_BUILD_NUMBER ?? 0)
  );

  return collectHooks([
    addTrackHook((event) => Crashlytics.log(event)),
    addScreenHook((name) => {
      Crashlytics.log(`Screen: ${name}`);
    }),
    addErrorHook((error) => {
      Crashlytics.recordError(error);
    }),
    addIdentifyHook(async (userId) => {
      if (userId) await Crashlytics.setUserId(userId);
    })
  ]);
};
