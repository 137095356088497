import React, { Component } from "react";
import { Clipboard, View } from "react-native";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import StyledButton from "../tappable/styled-button";
import StyledText from "./styled-text";

type Props = StylesProps & {
  text: string;
  buttonText?: string;
};

interface State {
  pressed: boolean;
}
@withStyles(({ color, font }) => ({
  copyableContainer: {
    flexDirection: "row",
    borderWidth: 1,
    marginVertical: 10,
    borderColor: color.blue,
    borderRadius: 4,
    justifyContent: "space-between",
    alignItems: "center"
  },
  copyableTextContainer: { flex: 3, alignItems: "center" },
  copyButton: {
    flex: 1,
    marginBottom: 0,
    marginTop: 0
  },
  buttonText: {
    fontSize: font.size.medium,
    ...font.body("bold")
  }
}))
export default class CopyableText extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    text: "",
    buttonText: "COPY"
  };

  state = { pressed: false };

  get getTextStyle() {
    const { theme } = this.props;
    const { pressed } = this.state;
    if (!pressed) return {};
    return {
      backgroundColor: theme.color.blue
    };
  }

  render() {
    const { styles, text, buttonText } = this.props;
    const { pressed } = this.state;

    return (
      <View style={styles.copyableContainer}>
        <View style={styles.copyableTextContainer}>
          <StyledText
            color={pressed ? "white" : "blue"}
            style={this.getTextStyle}
            testID="referral-code"
          >
            {text}
          </StyledText>
        </View>
        <StyledButton
          title={buttonText}
          color="blue"
          containerViewStyle={styles.copyButton}
          textStyle={styles.buttonText}
          onPress={() => Clipboard.setString(text)}
          onPressIn={() => this.setState({ pressed: true })}
          onPressOut={() => this.setState({ pressed: false })}
          testID="copy-button"
        />
      </View>
    );
  }
}
