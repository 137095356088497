import React from "react";
import { View } from "react-native";
import { type UnitSize, getSpaceUnits } from "../quarks";
import { useStyles } from "../style";
import { useGridGap } from "./GridContainer";

interface Props {
  horizontal?: boolean;
  fallback?: UnitSize;
}

export default function GridNull({ fallback, horizontal }: Props) {
  const gap = useGridGap() ?? fallback;
  const styles = useStyles(
    () => ({
      fixgap: {
        [horizontal ? "width" : "height"]: 1,
        [horizontal ? "marginLeft" : "marginBottom"]: -getSpaceUnits(gap) - 1
      }
    }),
    [gap]
  );

  return <View pointerEvents="none" style={styles.fixgap} />;
}
