import type { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import type { ComponentProps } from "react";
import { View } from "react-native";
import Checkbox from "../../atoms/Checkbox";
import Stack from "../../atoms/Stack";
import TextInput from "../../molecules/TextInput";
import Icon from "../../quarks/Icon";
import Pressable from "../../quarks/Pressable";
import Text from "../../quarks/Text";
import { useStyle } from "../../style";
import type CalendarPicker from "../CalendarPicker";
import CalendarModal from "./CalendarModal";
interface Props
  extends Omit<
      ComponentProps<typeof TextInput>,
      "onChange" | "onChangeText" | "value" | "min" | "max"
    >,
    Pick<ComponentProps<typeof CalendarPicker>, "multiple" | "min" | "max"> {
  value?: DateTime[] | null | "INDEFINITE";
  onChange: (value?: DateTime[] | null | "INDEFINITE") => void;
  placeholder?: string;
  disabled?: boolean;
  modalTitle?: string;
  submitBtnLabel?: string;
  indefiniteLabel?: string;
  timezone?: string;
}

export default function DatePicker({
  testID,
  placeholder,
  value,
  onChange,
  label,
  disabled,
  modalTitle = "Select a Date",
  submitBtnLabel = "Select Date",
  multiple,
  indefiniteLabel,
  min,
  max,
  timezone,
  ...rest
}: Props) {
  const [disabledCheck, setDisabledCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState<string>();
  const textInputStyle = useStyle(() => ({
    color: "black"
  }));
  useEffect(() => {
    if (value?.length && !inputValue) {
      if (value === "INDEFINITE") {
        setInputValue(indefiniteLabel);
        setDisabledCheck(true);
      } else {
        setInputValue(value?.[0]?.toFormat("M/d/yy"));
      }
    }
  }, [value]);

  const renderRightAccessory = () => {
    return disabled ? null : (
      <Icon variant="solid" name="caret-down" size="small" />
    );
  };

  const handleModalChange = (values?: DateTime[]) => {
    const timezonedValues = values?.map((v) =>
      v.setZone(timezone, { keepLocalTime: true })
    );
    onChange(timezonedValues);
    const value = timezonedValues?.[0];
    if (value) {
      setInputValue(value.toFormat("M/d/yy"));
      setDisabledCheck(false);
    }
  };

  return (
    <>
      <Stack size="compact">
        <Pressable
          testID={testID}
          eventEntityType="picker"
          eventTargetName="Picker"
          onPress={() => setShowModal(true)}
        >
          <View pointerEvents="none">
            <TextInput
              {...rest}
              testID={testID}
              placeholder={placeholder}
              value={inputValue ?? ""}
              pointerEvents="none"
              rightAccessory={renderRightAccessory()}
              label={label}
              style={textInputStyle}
            />
          </View>
        </Pressable>
        {indefiniteLabel && (
          <Stack horizontal size="compact" alignItems="center">
            <Checkbox
              testID="current"
              color="primary"
              selected={disabledCheck}
              onChange={(v) => {
                setDisabledCheck(v);
                setInputValue(v ? indefiniteLabel : undefined);
                onChange(v ? "INDEFINITE" : undefined);
              }}
            />
            <Text color="primary">{indefiniteLabel}</Text>
          </Stack>
        )}
      </Stack>
      <CalendarModal
        testID={`${testID}-date-picker-calendar`}
        visible={showModal}
        title={modalTitle}
        submitLabel={submitBtnLabel}
        onClose={() => setShowModal(false)}
        onChange={handleModalChange}
        multiple={multiple}
        min={min}
        max={max}
        initialValues={value === "INDEFINITE" ? undefined : value ?? undefined}
      />
    </>
  );
}
