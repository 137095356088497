import type { DateTime } from "luxon";
import React, { type ComponentProps } from "react";
import { Button, Stack } from "../../atoms";
import { Column, Text } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import type { PeriodType } from "./types";

type Props = Pick<ComponentProps<typeof Stack>, "fill"> & {
  period: PeriodType;
  date: DateTime;
  onDateChange?: (date: DateTime) => void;
};

export default function DateTraverser({ date, period, onDateChange }: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const isMd = useMatchesViewport((media) => media.size.small.down);

  const styles = useStyles(
    ({ getUnits }) => ({
      headerLabel: {
        minWidth: isSm ? getUnits(18) : getUnits(24.5)
      }
    }),
    [isSm]
  );

  const handleChange = (diff: 1 | -1) => {
    onDateChange?.(date.plus({ [period]: diff }));
  };

  const formatTitle = () => {
    if (period === "day") {
      if (isSm) {
        return date.toFormat("MM/dd/yyyy");
      }
      if (isMd) {
        return date.toFormat("MMM yyyy").toUpperCase();
      }
      return date.toFormat("MMM d, yyyy").toUpperCase();
    }

    return date.toFormat("MMM yyyy").toUpperCase();
  };

  return (
    <Stack
      size={isSm ? "compact" : "medium"}
      horizontal
      alignItems="center"
      justifyContent="center"
    >
      <Button
        label=""
        size="small"
        outline
        icon="chevron-left"
        testID="calendar-decrease-period"
        onPress={() => handleChange(-1)}
      />
      <Column justifyContent="center">
        <Text
          style={styles.headerLabel}
          variant={isSm ? "subheader" : "title"}
          color="primary"
          weight="bold"
          align="center"
        >
          {formatTitle()}
        </Text>
      </Column>
      <Button
        label=""
        size="small"
        outline
        icon="chevron-right"
        testID="calendar-increase-period"
        onPress={() => handleChange(1)}
      />
    </Stack>
  );
}
