import { createNavigationContainerRef } from "@react-navigation/native";
import type { AppParamList } from "./types";

export const navigationRef = createNavigationContainerRef<AppParamList>();

export {
  createBottomTabNavigator,
  type BottomTabBarProps,
  type BottomTabScreenProps
} from "@react-navigation/bottom-tabs";

export {
  CommonActions,
  findFocusedRoute,
  getPathFromState,
  LinkingContext,
  StackActions,
  TabActions,
  useIsFocused,
  useLinkTo,
  useNavigation,
  useRoute,
  type NavigationAction,
  type NavigationProp,
  type ParamListBase,
  type RouteProp,
  type PathConfigMap,
  type PathConfig
} from "@react-navigation/native";

export {
  createNativeStackNavigator,
  NativeStackView,
  type NativeStackNavigationProp
} from "@react-navigation/native-stack";
