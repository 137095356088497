import React from "react";
import { Image, View } from "react-native";
import { useStyles } from "../style";
import FloatingCloseButton from "./FloatingCloseButton";
import FloatingDeleteButton from "./FloatingDeleteButton";

interface Props {
  photo: string;
  onClose: () => void;
  onDelete?: () => void;
}

export default function PhotoPreview({ photo, onClose, onDelete }: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      backgroundColor: getColor("black", "fill"),
      flex: 1,
      justifyContent: "center"
    },
    photo: {
      height: "100%",
      width: "100%"
    }
  }));
  const handleDelete = () => {
    onDelete?.();
    onClose();
  };
  return (
    <View style={styles.container}>
      <Image
        source={{ uri: photo }}
        style={styles.photo}
        resizeMode="contain"
      />
      <FloatingCloseButton onPress={onClose} />
      {!!onDelete && <FloatingDeleteButton onPress={handleDelete} />}
    </View>
  );
}
