import type { DateTime } from "luxon";
import React, { useState } from "react";
import type { FlatListProps } from "react-native";
import { useWindowDimensions } from "react-native";
import { TableView } from "../../layout";
import type { PeriodType } from "./types";
import { useSectionListColumns } from "./useSectionListColumns";

export type Props<T> = Omit<
  FlatListProps<T>,
  | "data"
  | "renderItem"
  | "ListHeaderComponent"
  | "ListEmptyComponent"
  | "contentContainerStyle"
  | "showsVerticalScrollIndicator"
  | "stickyHeaderIndices"
> & {
  data: T[] | null;
  emptyContent?: JSX.Element | null;
  renderListItem: (item: T, isCollapsed: boolean) => JSX.Element | null;
  renderFooter?: () => JSX.Element | null;
  renderCalendarItem?: (
    item: T,
    date: DateTime,
    isCollapsed: boolean
  ) => JSX.Element | null;
  onPressAddNewCalendarItem: (item: T, date: DateTime) => void;
  itemHeight?: number;
  variant?: "standard" | "collapsible";
  searchPlaceholder: string;
  searchTerm: string;
  onSearch: (term: string) => void;
  period: Exclude<PeriodType, "month">;
  date: DateTime;

  onEndReached?: () => void;
  isLoading?: boolean;
};

export default function SectionList<T>({
  data,
  emptyContent,
  itemHeight = 160,
  variant = "standard",
  renderListItem,
  renderCalendarItem,
  onPressAddNewCalendarItem,
  period = "week",
  onEndReached,
  isLoading,
  searchPlaceholder,
  searchTerm,
  onSearch,
  renderFooter,
  date
}: Props<T>) {
  const parsedData = data?.length ? data : (["empty"] as T[]);
  const { height: screenHeight } = useWindowDimensions();
  const isEmpty = !data?.length;
  const [tableWidth, setTableWidth] = useState(0);
  const dataCount = data?.length ?? 0;
  const { left, columns } = useSectionListColumns({
    period,
    date,
    renderListItem,
    renderCalendarItem,
    onPressAddNewCalendarItem,
    variant,
    searchPlaceholder,
    searchTerm,
    onSearch,
    emptyContent,
    isEmpty,
    cellHeight: itemHeight,
    tableWidth,
    dataCount,
    renderFooter
  });

  return (
    <TableView
      onEndReached={onEndReached}
      loading={isLoading}
      left={left}
      columns={columns}
      data={parsedData}
      {...(isEmpty ? { cellHeight: screenHeight - 200 } : undefined)}
      headerVariant="shadow"
      headerBackgroundColor="input"
      showScrollHelper={false}
      onLayout={setTableWidth}
    />
  );
}
