import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect
} from "react";
import Stack from "../atoms/Stack";
import ExpandableRadioRow, {
  type Props as ExpandableRadioRowProps
} from "../molecules/ExpandableRadioRow";

export interface ExpandableRadioGroupRow<T>
  extends Omit<ExpandableRadioRowProps, "selected" | "onSelect"> {
  value: T;
}

export interface ExpandableRadioGroupRefType {
  expand: (val: any) => void;
}

interface Props<T> {
  rows: Array<ExpandableRadioGroupRow<T> | null>;
  value?: T | null;
  onChange: (value: T) => void;
  onExpandOrChange?: () => void;
  renderChildrenCollapsed?: boolean;
}

export default forwardRef<ExpandableRadioGroupRefType, Props<any>>(
  function ExpandableRadioGroup<T>(
    {
      rows,
      value,
      onChange,
      onExpandOrChange,
      renderChildrenCollapsed
    }: Props<T>,
    ref: any
  ) {
    const [expanded, setExpanded] = useState(value);
    useImperativeHandle(ref, () => ({
      expand: (val: T) => {
        if (val !== expanded) setExpanded(val);
      }
    }));

    useEffect(() => {
      // autoexpand selected
      const exists = !!(value && rows.find((it) => it?.value === value));
      if (exists && expanded !== value) setExpanded(value);
    }, [value]);

    return (
      <Stack variant="divider">
        {rows.map((rowData) => {
          if (!rowData) return null;
          const { value: rowValue, ...row } = rowData;
          return (
            <ExpandableRadioRow
              {...row}
              renderChildrenCollapsed={renderChildrenCollapsed}
              key={`row-${rowValue}`}
              selected={value === rowValue}
              onSelect={() => {
                setExpanded(rowValue);
                onExpandOrChange?.();
                onChange(rowValue);
              }}
              expanded={rowValue === expanded}
              toggleExpanded={() => {
                setExpanded(expanded === rowValue ? null : rowValue);
                onExpandOrChange?.();
              }}
            />
          );
        })}
      </Stack>
    );
  }
);
