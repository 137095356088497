import React, { type ReactNode } from "react";
import { View } from "react-native";

import { type TextStyleProp, type ViewStyleProp, useStyles } from "../style";
import StyledText from "./styled-text";

interface Props {
  ordered?: boolean;
  items: Array<string | ReactNode>;
  bulletStyle?: TextStyleProp;
  textStyle?: TextStyleProp;
  bulletChar: ReactNode;
  style: ViewStyleProp;
}

export default function StyledTextList({
  ordered,
  items,
  textStyle,
  bulletStyle,
  bulletChar,
  style
}: Props) {
  const { styles, theme } = useStyles(({ units }) => ({
    container: {
      display: "flex",
      marginVertical: units(4)
    },
    row: {
      marginVertical: units(2),
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    bulletContainer: {
      flexGrow: 0,
      paddingLeft: units(3),
      paddingRight: units(3)
    },
    contentContainer: {
      flex: 1
    }
  }));
  return (
    <View style={[styles.container, style]}>
      {items.map((text, index) => (
        <View key={`item-${index}`} style={styles.row}>
          <View style={styles.bulletContainer}>
            <StyledText fontSize={theme.units(5)} style={bulletStyle}>
              {ordered ? index + 1 : bulletChar}
            </StyledText>
          </View>
          <View style={styles.contentContainer}>
            <StyledText style={textStyle}>{text}</StyledText>
          </View>
        </View>
      ))}
    </View>
  );
}

StyledTextList.defaultProps = {
  bulletChar: "•"
};
