import React, { type ReactNode } from "react";
import { ContentArea, Stack } from "../atoms";
import { Column, Text } from "../quarks";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";
import ExternalLink from "./ExternalLink";

interface Props {
  header?: ReactNode;
  content: ReactNode;
  learnMore?: {
    text: string;
    url: string;
  };
  headerColor?: Color;
  contentColor?: Color;
  border?: boolean;
  testID: string;
}

export default function InfoBox({
  header,
  headerColor,
  contentColor,
  content,
  border,
  learnMore,
  testID
}: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      borderRadius: getUnits(1),

      ...(border
        ? {
            borderWidth: 1,
            borderColor: getColor("neutral", "fill", { opacity: 0.25 })
          }
        : {})
    },
    header: {
      backgroundColor: getColor(headerColor, "fill", { opacity: 0.08 }),
      borderRadiusTopRight: getUnits(1),
      borderRadiusTopLeft: getUnits(1)
    },
    content: {
      ...(header
        ? {
            borderRadiusBottomRight: getUnits(1),
            borderRadiusBottomLeft: getUnits(1)
          }
        : { borderRadius: getUnits(1) }),
      backgroundColor: getColor(contentColor, "fill", { opacity: 0.08 })
    }
  }));

  return (
    <Column style={styles.container}>
      {header && (
        <Column style={styles.header}>
          <ContentArea>{header}</ContentArea>
        </Column>
      )}
      <Column style={styles.content}>
        <ContentArea>
          <Stack>
            {typeof content === "string" ? (
              <Text variant="note">{content}</Text>
            ) : (
              content
            )}
            {learnMore && (
              <ExternalLink
                testID={`${testID}-learn-more-link`}
                linkText={learnMore.text}
                url={learnMore.url}
              />
            )}
          </Stack>
        </ContentArea>
      </Column>
    </Column>
  );
}
