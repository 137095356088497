import { createComponentPortal } from "@gigsmart/aperture";
import { useFormSubmit } from "@gigsmart/fomu";
import React, { type ReactNode } from "react";
import { useStepperV2 } from "../../layout";
import { ScreenTop } from "../../molecules";
import { Text } from "../../quarks";
import { defaultBackPrompt, defaultCancelPrompt } from "../../utils";
import { NavAction } from "../Navigation";

type Props = {
  dirty?: boolean;
  onCancel?: false | (() => void);
  inline?: boolean;
};

const { Entrance, Realm, Exit } = createComponentPortal();

export function StepHeader({ dirty, onCancel, inline }: Props) {
  const stepper = useStepperV2();
  const action = !!onCancel && (
    <NavAction
      testID="cancel-btn"
      onPress={async () => {
        if (await defaultCancelPrompt()) onCancel();
      }}
    >
      <Text color="primary" weight="bold">
        Cancel
      </Text>
    </NavAction>
  );

  const handleBack = () => {
    if (dirty) {
      defaultBackPrompt().then((ok) => {
        ok && stepper.prevStep();
      });
    } else {
      stepper.prevStep();
    }
    return true;
  };

  return inline ? (
    <ScreenTop onBackPress={handleBack} rightAccessory={action} />
  ) : (
    <StepHeader.Entrance>
      <ScreenTop onBackPress={handleBack} rightAccessory={action} />
    </StepHeader.Entrance>
  );
}

type ExitProps = {
  fallback?: ReactNode;
};
StepHeader.Exit = ({ fallback }: ExitProps) => {
  return <Exit>{fallback ?? <StepHeader inline />}</Exit>;
};

StepHeader.Form = ({ dirty, ...rest }: Props) => {
  const formSubmit = useFormSubmit();
  return <StepHeader dirty={dirty || formSubmit.dirty} {...rest} />;
};

StepHeader.Entrance = Entrance;
StepHeader.Realm = Realm;
