import React, { type ReactNode, useEffect } from "react";
import { Dimensions, Platform } from "react-native";
import {
  SafeAreaProvider as RNSafeAreaProvider,
  initialWindowMetrics,
  useSafeAreaInsets
} from "react-native-safe-area-context";

// To support SSR on web, we need to have empty insets for initial values
// Otherwise there can be mismatch between SSR and client output
// We also need to specify empty values to support tests environments
const { width = 0, height = 0 } = Dimensions.get("window");
const initialMetrics =
  Platform.OS === "web" || initialWindowMetrics == null
    ? {
        frame: { x: 0, y: 0, width, height },
        insets: { top: 0, left: 0, right: 0, bottom: 0 }
      }
    : initialWindowMetrics;

export const windowInsets = {
  top: initialMetrics.insets.top,
  bottom: initialMetrics.insets.bottom
};

interface Props {
  children: ReactNode;
}

export const SafeAreaProvider = ({ children }: Props) => (
  <RNSafeAreaProvider initialMetrics={initialMetrics}>
    <InsetListener />
    {children}
  </RNSafeAreaProvider>
);

const InsetListener = () => {
  const inset = useSafeAreaInsets();
  useEffect(() => {
    if (inset) {
      windowInsets.top = inset.top;
      windowInsets.bottom = inset.bottom;
    }
  }, [inset]);
  return null;
};

export {
  useSafeAreaInsets,
  initialWindowMetrics
} from "react-native-safe-area-context";
