import React, {
  type ComponentType,
  useEffect,
  useContext,
  type ReactNode,
  useMemo
} from "react";
import Context from "./context";
import { screen } from "./hooks";
import { generateContextName } from "./naming";
interface Props {
  name?: string | null;
  disabled?: true;
  children: ReactNode;
  noScreen?: boolean;
  Component?: ComponentType<any>;
  properties?: {};
}

export const EventContext = ({
  name,
  disabled: nextDisabled,
  properties,
  Component,
  noScreen,
  children
}: Props) => {
  const { name: contextName, disabled: prevDisabled } = useContext(Context);
  const nextContextName =
    typeof name === "string"
      ? generateContextName({
          contextName,
          name
        })
      : contextName;
  useEffect(() => {
    if (typeof nextContextName === "string" && !noScreen) {
      return screen(nextContextName, properties ?? {}, Component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noScreen, nextContextName, JSON.stringify(properties), Component]);

  const disabled = nextDisabled ?? prevDisabled;
  const value = useMemo(
    () => ({ name: nextContextName, disabled }),
    [nextContextName, disabled]
  );

  if (typeof name !== "string") return <>{children}</>;
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
