import React, { type ComponentProps } from "react";
import Button from "../atoms/Button";
import IconText from "../atoms/IconText";
import Icon from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";

interface DataProps {
  testID: string;
  helpText?: string | true;
  title?: string;
}

interface IconProps extends DataProps {
  variant: "icon";
  color?: "info" | "primary";
  iconVariant?: "light" | "solid";
}

interface ButtonProps extends DataProps {
  variant: "button";
}

interface TextProps extends DataProps {
  color?: "info" | "primary";
  variant: "text";
  textVariant?: ComponentProps<typeof Text>["variant"];
}

export type Props = IconProps | ButtonProps | TextProps;

export default function InfoElement({
  openModal,
  testID,
  title,
  helpText,
  ...props
}: Props & { openModal: () => void }) {
  switch (props.variant) {
    case "icon":
      return (
        <Pressable
          onPress={openModal}
          eventEntityType="Info Circle"
          eventTargetName="Info Circle Icon"
          testID={`${testID}-info-icon`}
          hitSlop={18}
        >
          {helpText ? (
            <IconText
              size="small"
              icon="circle-question"
              color={props.color ?? "info"}
              iconVariant={props.iconVariant ?? "light"}
            >
              {helpText === true ? title : helpText ?? ""}
            </IconText>
          ) : (
            <Icon
              size="small"
              name="circle-question"
              color={props.color ?? "info"}
              variant={props.iconVariant ?? "light"}
            />
          )}
        </Pressable>
      );
    case "button":
      return (
        <Button
          onPress={openModal}
          testID={`${testID}-info-btn`}
          label={typeof helpText === "string" ? helpText : "Learn More"}
          variant="clear"
          size="xsmall"
          icon="circle-question"
        />
      );
    case "text":
      return (
        <Text
          onPress={openModal}
          testID={testID}
          eventEntityType="Info Element"
          color={props.color ?? "info"}
          variant={props.textVariant}
        >
          {typeof helpText === "string" ? helpText : title}
        </Text>
      );
  }
}
