import type { ForwardableProps } from "@gigsmart/type-utils";
import React from "react";
import { Flex } from "../flex";
import { useStyles } from "../style";
import KatanaText from "./katana-text";

type Props = ForwardableProps<
  typeof Flex,
  {
    testID?: string;
    subtitleTestID?: string;
    label: string;
    subTitle?: string;
  },
  "children"
>;

const PageTitle = ({
  testID,
  subtitleTestID,
  label,
  subTitle,
  ...props
}: Props) => {
  const { styles } = useStyles(({ font, unit }) => ({
    subTitle: {
      marginBottom: unit.margin.small
    },
    label: {
      ...font.withSize(font.size.heading1)
    }
  }));

  return (
    <Flex {...props}>
      {Boolean(subTitle) && (
        <KatanaText testID={subtitleTestID} style={styles.subTitle}>
          {subTitle}
        </KatanaText>
      )}
      <KatanaText testID={testID} style={styles.label}>
        {label}
      </KatanaText>
    </Flex>
  );
};

export default PageTitle;
