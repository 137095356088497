import { useHashMemo } from "@gigsmart/aperture";
import React, { useMemo, useCallback } from "react";
import { Animated, View } from "react-native";
import { IsConstrainedProvider, Surface } from "../../atoms";
import { Row, getSpaceUnits, useScrollRef } from "../../quarks";
import { type Color, useMatchesViewport, useStyles } from "../../style";
import { useThrottledState } from "../../utils";
import { SimpleTable } from "./SimpleTable";
import { SyncScrollProvider } from "./SyncScroll";
import TableScrollHelper from "./TableScrollHelper";
import {
  type TableCol,
  type TableKey,
  type TableSortBy,
  normalizeColumns
} from "./TableView.helpers";

export type TableViewProps<T> = {
  loading?: boolean;
  data?: T[] | null;
  stickyColumn?: number;
  sortBy?: TableKey<T> | null;
  sortOrder?: "asc" | "desc" | null;
  cellHeight?: number;
  cellMinWidth?: number;
  cellMaxWidth?: number;
  onSort?: (attr: TableKey<T>, dir: "asc" | "desc") => void;
  keyExtractor?: (item: T, idx: number) => string;
  columns?: TableCol<T>[];
  left?: TableCol<T>[] | null;
  right?: TableCol<T>[] | null;
  onEndReached?: () => void;
  listLength?: number;
  onRowPress?: (item: T) => void;
  variant?: "shadow" | "flat";
  headerVariant?: "default" | "shadow";
  headerBackgroundColor?: Color;
  scrollEnabled?: boolean;
  form?: boolean;
  showScrollHelper?: boolean;
  onLayout?: (width: number) => void;
};

export function TableView<T>({
  loading,
  data,
  sortBy,
  sortOrder,
  onSort,
  keyExtractor,
  cellHeight,
  cellMinWidth,
  cellMaxWidth,
  columns,
  left,
  right,
  onEndReached,
  listLength,
  onRowPress,
  variant,
  headerVariant,
  headerBackgroundColor,
  scrollEnabled = true,
  form = false,
  showScrollHelper = true,
  onLayout
}: TableViewProps<T>) {
  const [tableWidth, setTableWidth] = useThrottledState(0);
  const [centerWidth, setCenterWidth] = useThrottledState(0);

  const isLg = useMatchesViewport(({ size }) => size.large.up);
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const tableSortBy = useMemo<TableSortBy<T>>(
    () => (sortOrder && sortBy ? [sortBy, sortOrder] : [null, null]),
    [sortBy, sortOrder]
  );

  const table = useHashMemo(
    () =>
      normalizeColumns({
        columns,
        left,
        right,
        isLg,
        tableWidth,
        cellMinWidth,
        cellMaxWidth
      }),
    [isLg, tableWidth, columns, left, right]
  );

  const styles = useStyles(({ getColor }) => {
    const borderColor = getColor("black", "fill", { opacity: 0.08 });
    return {
      wrapper: {
        flex: 1,
        overflowY: "scroll",
        backgroundColor: getColor("white", "fill")
      },
      inset: {
        paddingHorizontal: getSpaceUnits("standard"),
        paddingBottom: getSpaceUnits("standard")
      },
      content: {
        overflow: "hidden",
        flexDirection: "row",
        flexGrow: 1
      },
      center: {
        alignItems: "flex-start",
        zIndex: 5
      },
      sticky: {
        zIndex: 10,
        overflow: "hidden"
      },
      left: {
        borderRightWidth: 1,
        borderRightColor: borderColor
      },
      right: {
        borderLeftWidth: 1,
        borderLeftColor: borderColor
      }
    };
  });

  const { getCellHeight } = useTableHeight(cellHeight);
  const scrollX = useMemo(() => new Animated.Value(0), []);
  const scrollXRef = useScrollRef();
  const renderContent = (section: "left" | "center" | "right") => {
    const cols = table[section];
    if (!cols.length) return null;

    const viewStyle = {
      width: cols.reduce((acc, c) => acc + c.cellWidth, 0)
    };

    switch (section) {
      case "left":
      case "right":
        return (
          <View style={[styles.sticky, viewStyle, styles[section]]}>
            <SimpleTable
              id={section}
              columns={cols}
              data={data}
              sortBy={tableSortBy}
              onSort={onSort}
              getCellHeight={getCellHeight}
              keyExtractor={keyExtractor}
              loading={loading}
              listLength={listLength}
              onRowPress={onRowPress}
              headerVariant={headerVariant}
              scrollEnabled={scrollEnabled}
              form={form}
              headerBackgroundColor={headerBackgroundColor}
            />
          </View>
        );
      case "center":
        return (
          <Animated.ScrollView
            horizontal
            bounces={false}
            ref={scrollXRef}
            style={viewStyle}
            scrollEventThrottle={16}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { x: scrollX } } }],
              { useNativeDriver: true }
            )}
            onLayout={(e) => {
              if (!e.nativeEvent) return;
              setCenterWidth(e.nativeEvent.layout.width);
              onLayout?.(e.nativeEvent.layout.width);
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <SimpleTable
              id={section}
              columns={cols}
              data={data}
              sortBy={tableSortBy}
              onSort={onSort}
              getCellHeight={getCellHeight}
              keyExtractor={keyExtractor}
              onEndReached={onEndReached}
              loading={loading}
              listLength={listLength}
              onRowPress={onRowPress}
              headerVariant={headerVariant}
              scrollEnabled={scrollEnabled}
              form={form}
              headerBackgroundColor={headerBackgroundColor}
            />
          </Animated.ScrollView>
        );
    }
  };

  return (
    <IsConstrainedProvider value={isMd}>
      <Surface
        fill={scrollEnabled}
        variant={variant}
        overflow="hidden"
        onLayout={(e) => {
          const tableWidth = e.nativeEvent?.layout.width;
          if (tableWidth) setTableWidth(tableWidth);
        }}
      >
        <SyncScrollProvider>
          <Row fill={scrollEnabled} alignItems="stretch">
            {renderContent("left")}
            {renderContent("center")}
            {renderContent("right")}
          </Row>
          {showScrollHelper && (
            <TableScrollHelper
              scrollX={scrollX}
              scrollRef={scrollXRef}
              width={centerWidth}
              columns={table.center}
            />
          )}
        </SyncScrollProvider>
      </Surface>
    </IsConstrainedProvider>
  );
}

function useTableHeight(cellHeight = 52) {
  // TODO: make it dynamic like it was before
  const getCellHeight = useCallback(() => ({ cellHeight }), [cellHeight]);
  return { getCellHeight };
}
