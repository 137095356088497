import React, { useEffect, useMemo, useRef } from "react";
import { Animated, View } from "react-native";
import Stack from "../atoms/Stack";
import Icon from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";
import { ColorPlacementProvider, useStyle, useStyles } from "../style";

interface Props {
  toggled?: boolean | null;
  locked?: boolean;
  onChange?: (v: boolean) => unknown;
  testID: string;
  labels?: { true: string; false: string } | false;
  size?: "standard" | "mini";
}

const DEFAULT_LABELS = { true: "yes", false: "no" };

const getLetterWidth = (letter: string): number => {
  if ([..."1i"].includes(letter)) return 3.5;
  if ([..."W"].includes(letter)) return 13.5;
  return 9.5;
};
const getWordWidth = (word: string) =>
  [...word].map(getLetterWidth).reduce((l, r) => l + r);

export default function Switch({
  toggled,
  onChange,
  testID,
  labels = DEFAULT_LABELS,
  locked,
  size = "standard"
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      container: {
        width: getUnits(size === "standard" ? 11 : 5.5),
        height: getUnits(size === "standard" ? 6 : 3),
        borderRadius: getUnits(size === "standard" ? 7.5 : 3.75),
        backgroundColor: getColor("surface", "fill")
      },
      active: { backgroundColor: getColor("primary", "fill") },
      inactive: {
        backgroundColor: getColor("disabled", "fill")
      },
      circleContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      },
      circle: {
        width: getUnits(size === "standard" ? 5 : 2.5),
        height: getUnits(size === "standard" ? 5 : 2.5),
        borderRadius: getUnits(size === "standard" ? 5 : 2.5),
        backgroundColor: getColor("primary", "placement"),
        justifyContent: "center",
        alignItems: "center"
      },
      locked: {
        opacity: 0.32
      }
    }),
    [size]
  );

  const maxLabelSize = useMemo(
    () => (labels ? Math.max(...Object.values(labels).map(getWordWidth)) : 0),
    [labels]
  );
  const labelStyle = useStyle(({ getUnits }) => ({
    width: maxLabelSize
  }));

  const change = size === "standard" ? 10 : 5;

  const switchPosition = useRef(
    new Animated.Value(toggled ? change : -change)
  ).current;

  useEffect(() => {
    Animated.spring(switchPosition, {
      toValue: toggled ? change : -change,
      useNativeDriver: false
    }).start();
  }, [toggled, switchPosition, size]);

  const valueLabel = labels
    ? toggled
      ? labels.true
      : labels.false
    : undefined;

  return (
    <Pressable
      testID={`${testID}-${toggled ? "yes" : "no"}`}
      eventEntityType="Switch"
      eventTargetName="Switch"
      disabled={locked}
      onPress={() => onChange?.(!toggled)}
    >
      <Stack horizontal size="compact" alignItems="center">
        <View style={styles.container}>
          <View
            style={[
              styles.container,
              toggled ? styles.active : styles.inactive,
              locked && styles.locked
            ]}
          >
            <Animated.View
              style={[styles.circleContainer, { left: switchPosition }]}
            >
              <View style={styles.circle}>
                <ColorPlacementProvider color="primary" use="fill">
                  {size === "standard" && (toggled || locked) && (
                    <Icon
                      name={locked ? "lock" : "check"}
                      variant="solid"
                      size="tiny"
                      color={toggled ? "primary" : "disabled"}
                    />
                  )}
                </ColorPlacementProvider>
              </View>
            </Animated.View>
          </View>
        </View>
        {valueLabel && (
          <Text
            weight="bold"
            selectable={false}
            wrap={false}
            style={labelStyle}
          >
            {valueLabel.toUpperCase()}
          </Text>
        )}
      </Stack>
    </Pressable>
  );
}
