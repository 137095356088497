import AsyncStorage from "@react-native-async-storage/async-storage";
import Cookies from "js-cookie";
import { Linking } from "react-native";
import {
  addInitHook,
  alias,
  beforeIdentify,
  beforeScreen,
  beforeTrack,
  collectHooks
} from "../hooks";
import { logger as parentLogger } from "../logger";

const logger = parentLogger.createLogger("utm");

const utmMap = {
  utm_campaign: "name" as const,
  utm_content: "content" as const,
  utm_medium: "medium" as const,
  utm_source: "source" as const,
  utm_term: "term" as const
};

export type RawUtms = Record<keyof typeof utmMap, string>;
type MappedUtms = Record<(typeof utmMap)[keyof typeof utmMap], string>;

function getParamsFromURL(urlString: string) {
  return new URL(urlString, "https://gigsmart.com").searchParams;
}

function maybeGetCookie(key: string): string | undefined {
  try {
    Cookies.get(key);
  } catch {
    return undefined;
  }
}

async function getValue(
  searchParams: URLSearchParams,
  key: string,
  paramKey: string | null = key,
  cookieKey: string | null = key
): Promise<string | undefined | null> {
  const storeKey = `@dekigoto:utm:${key}`;
  const value =
    (paramKey && searchParams.get(paramKey)) ??
    (cookieKey && maybeGetCookie(cookieKey)) ??
    (await AsyncStorage.getItem(storeKey));
  if (value) void AsyncStorage.setItem(storeKey, value);
  return value;
}

export async function getUtmData(
  searchParams: URLSearchParams = new URLSearchParams(),
  defaults: Partial<Record<keyof RawUtms, string>> | null = {}
): Promise<{
  raw: RawUtms;
  mapped: MappedUtms;
}> {
  const raw: RawUtms = await Object.keys(utmMap).reduce(
    async (acc, key) => {
      const value =
        (await getValue(searchParams, key)) ?? defaults?.[key as keyof RawUtms];
      if (!value) return await acc;
      return {
        ...(await acc),
        [key as keyof RawUtms]: value
      };
    },
    Promise.resolve({} as RawUtms)
  );

  const mapped: MappedUtms = Object.keys(raw).reduce<MappedUtms>((acc, key) => {
    const mappedKey = utmMap[key as keyof typeof utmMap] as keyof MappedUtms;
    return {
      ...acc,
      [mappedKey]: raw[key as keyof RawUtms]
    };
  }, {} as MappedUtms);

  return { raw, mapped };
}

export default async () => {
  // Get initial browser URL and listen for URL changes
  let searchParams = getParamsFromURL((await Linking.getInitialURL()) ?? "/");
  const subscription = Linking.addEventListener("url", (event) => {
    searchParams = getParamsFromURL(event.url);
  });
  const anonymousId = searchParams.get("auid");
  const { mapped } = await getUtmData(searchParams);

  // Log UTM data
  Object.entries(mapped).forEach(([key, value]) => {
    if (value) logger.info(`${key}: ${value}`);
  });

  // Collect the hooks to remove later
  const removeHooks = collectHooks([
    // Gather URL information related to UTM parameters
    addInitHook(async () => {
      if (anonymousId) alias(anonymousId);
    }),

    // Append UTM data to user traits
    beforeIdentify(async (userId, traits) => {
      const { raw, mapped: campaign } = await getUtmData(searchParams);
      return [
        userId,
        {
          ...traits,
          ...raw,
          campaign
        }
      ];
    }),

    // Append UTM data to event properties
    beforeTrack(async (event, properties, category) => {
      const { mapped: campaign } = await getUtmData(searchParams);
      return [
        event,
        {
          ...properties,
          campaign
        },
        category
      ];
    }),

    // Append UTM data to screen properties
    beforeScreen(async (event, properties, Component) => {
      const { mapped: campaign } = await getUtmData(searchParams);
      return [
        event,
        {
          ...properties,
          campaign
        },
        Component
      ];
    })
  ]);

  return () => {
    removeHooks();
    subscription.remove();
  };
};
