import React, { useRef, type ReactNode } from "react";
import { Form, type FormHandlersType } from "./form";
import type { FormFieldOptions } from "./form-field";
import { useActor } from "./scene";

type Props = FormFieldOptions<object> & {
  children: ReactNode;
  initialValues?: Record<string, any>;
};
export default function NestedForm({ children, name, initialValues }: Props) {
  const nestedFormRef = useRef<FormHandlersType | null>(null);

  const setValue = useActor(
    "values",
    (nextValue, prevState) => ({
      ...prevState,
      values: prevState.values.set(name, nextValue)
    }),
    [name]
  );

  const setErrors = useActor<Error[] | null>(
    "errors",
    (nextErrors, prevState) => {
      return {
        ...prevState,
        errors: prevState.errors.set(name, nextErrors)
      };
    },
    [name]
  );

  const submit = useActor("submit");

  return (
    <Form
      ref={nestedFormRef}
      onSubmit={submit}
      onChange={({ values }) => {
        setValue(values);
      }}
      onErrors={({ errors }) => {
        setErrors(errors);
      }}
      fieldPrefix={name}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
}
