import { useCallback } from "react";
import { useRelayEnvironment } from "react-relay";
import { type StoreUpdater, commitLocalUpdate } from "relay-runtime";

export const useCommitLocalUpdate = () => {
  const env = useRelayEnvironment();
  return useCallback(
    (updater: StoreUpdater) => commitLocalUpdate(env, updater),
    []
  );
};
