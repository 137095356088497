import { FreshLook } from "@gigsmart/feature-flags";
import {
  type AppNavigationHelpers,
  defaultBackHandler,
  useNavExit
} from "@gigsmart/kaizoku";
import React, { type ReactNode, useCallback, useEffect } from "react";
import { BackHandler, Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import StatusBar from "../../atoms/StatusBar";
import { Column, Row, Text } from "../../quarks";
import {
  ColorPlacementProvider,
  useMatchesViewport,
  useStyles
} from "../../style";
import type { Color } from "../../style/theme/colors";
import NavIcon from "./NavIcon";

interface Props {
  title?: string | ReactNode | (() => JSX.Element);
  leftAccessory?: ReactNode | (() => JSX.Element);
  rightAccessory?: ReactNode | (() => JSX.Element);
  canGoBack?: boolean;
  children?: ReactNode;
  color?: Color;
  rootNavigation?: AppNavigationHelpers;
}

export default function NavBar({
  color = FreshLook.isEnabled() ? "surface" : "primary",
  children,
  title,
  leftAccessory,
  rightAccessory,
  rootNavigation
}: Props) {
  const topInset = Math.max(useSafeAreaInsets().top - 4, 0);

  const nav = useNavExit();
  const isSm = useMatchesViewport(({ size }) => size.small.down);
  const styles = useStyles(
    ({ getUnits, getColor, measurements }) => ({
      container: {
        backgroundColor: getColor(color, "fill"),
        minHeight: measurements.navbarHeight + topInset,
        paddingTop: topInset
      },
      navbar: {
        height: measurements.navbarHeight,
        ...(color === "surface"
          ? {
              borderBottomColor: getColor("background", "fill"),
              borderBottomWidth: 1
            }
          : {})
      },
      left: { marginLeft: getUnits(1), minWidth: isSm ? 100 : 0 },
      right: { marginRight: getUnits(1), minWidth: isSm ? 100 : 0 },
      center: { marginHorizontal: getUnits(2) }
    }),
    [color, isSm, topInset]
  );

  // grab info from NavPortal or current navigationRef
  const showBack = nav.showBack ?? !!rootNavigation?.canGoBack();

  // left/right accessory takes precedence
  if (nav.rightAccessory) rightAccessory = nav.rightAccessory;
  if (nav.leftAccessory) leftAccessory = nav.leftAccessory;
  if (nav.title) title = nav.title;
  if (typeof rightAccessory === "function") rightAccessory = rightAccessory();
  if (typeof leftAccessory === "function") leftAccessory = leftAccessory();
  if (typeof title === "string") {
    title = <Text align="center">{title}</Text>;
  } else if (typeof title === "function") {
    title = title();
  }

  const handleBackPress = useCallback(() => {
    if (nav.onBackPress?.()) return true;
    return defaultBackHandler();
  }, [nav]);

  useEffect(() => {
    if (Platform.OS === "web") return;
    BackHandler.addEventListener("hardwareBackPress", handleBackPress);
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBackPress);
    };
  }, [handleBackPress]);

  // back button replaces leftAccessory on small screens
  if (isSm && showBack) {
    leftAccessory = (
      <NavIcon
        testID="back-button"
        name="chevron-left"
        onPress={handleBackPress}
      />
    );
  }

  return (
    <ColorPlacementProvider color={color}>
      <Column style={styles.container} zIndex={20}>
        <StatusBar />
        <Row style={styles.navbar} alignItems="center">
          <Row
            style={styles.left}
            justifyContent="flex-start"
            alignItems="center"
          >
            {leftAccessory}
          </Row>
          <Column
            fill
            style={styles.center}
            alignItems={isSm ? "center" : "flex-start"}
          >
            {title}
          </Column>
          <Row
            style={styles.right}
            justifyContent="flex-end"
            alignItems="center"
          >
            {rightAccessory}
          </Row>
        </Row>
        {children}
      </Column>
    </ColorPlacementProvider>
  );
}
