import {
  type MapProps,
  Map as RNMapView,
  useMap as useGoogleMap
} from "@vis.gl/react-google-maps";
import React, {
  type ReactNode,
  useRef,
  useImperativeHandle,
  forwardRef
} from "react";
import type { LatLng } from "react-native-maps";
import Column from "../../quarks/Column";
import { latlng } from "../helpers";
import type { GoogleMapViewRefType } from "../helpers";
import MapControls from "./MapControls";

interface Props {
  id: string;
  lite?: boolean;
  children?: ReactNode;
  onMapReady?: MapProps["onTilesLoaded"];
  onZoomChanged?: MapProps["onZoomChanged"];
  center: LatLng;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  onPress?: MapProps["onClick"];
  testID: string;
  topLeft?: ReactNode;
  topCenter?: ReactNode;
  topRight?: ReactNode;
  bottomLeft?: ReactNode;
  bottomCenter?: ReactNode;
  bottomRight?: ReactNode;
  bottomControlPadding?: number;
}

const MapView = forwardRef<GoogleMapViewRefType, Props>(
  (
    {
      lite,
      children,
      onMapReady,
      onZoomChanged,
      center,
      zoom = 16,
      maxZoom = 20,
      minZoom = 4,
      onPress,
      testID,
      topLeft,
      topCenter,
      topRight,
      bottomLeft,
      bottomCenter,
      bottomRight,
      id,
      bottomControlPadding
    }: Props,
    ref
  ) => {
    const map = useGoogleMap(id);
    useImperativeHandle(ref, () => {
      return {
        panTo: (latLng: LatLng) => {
          map?.panTo({ lat: latLng.latitude, lng: latLng.longitude });
        }
      };
    });
    return (
      <Column fill>
        <MapControls
          id={id}
          minZoom={minZoom}
          maxZoom={maxZoom}
          bottomControlPadding={bottomControlPadding}
          {...{
            topLeft,
            topCenter,
            topRight,
            bottomLeft,
            bottomCenter,
            bottomRight
          }}
        />
        <RNMapView
          id={id}
          mapId={process.env.GOOGLE_MAPS_MAP_ID}
          defaultCenter={latlng.toLiteral(center)}
          defaultZoom={zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onClick={onPress}
          onTilesLoaded={onMapReady}
          onZoomChanged={onZoomChanged}
          clickableIcons={false}
          fullscreenControl={false}
          zoomControl={false}
          mapTypeControl={false}
          streetViewControl={false}
          styles={[
            {
              elementType: "labels",
              featureType: "poi.business",
              stylers: [{ visibility: "off" }]
            }
          ]}
          {...(lite && {
            liteMode: true,
            pointerEvents: "none",
            rotateEnabled: false,
            zoomEnabled: false,
            scrollEnabled: false,
            pitchEnabled: false
          })}
        >
          {children}
        </RNMapView>
      </Column>
    );
  }
);

export const useMapRef = () => useRef<typeof RNMapView>(null);

export default MapView;
