import React, { type ReactNode } from "react";

import { Card, ContentArea, FancyHeader } from "../atoms";
import type { IconName } from "../quarks/Icon";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import type { ColorProp } from "../style/theme/colors";

interface Props {
  testID?: string;
  iconName: IconName;
  color?: ColorProp;
  title: string;
  subtitle?: string;
  children: ReactNode;
  Modal?: ReactNode;
  rightAccessory?: ReactNode;
  variant?: ColorProp;
}

export default function TopicPreviewSurface({
  testID,
  iconName,
  color,
  title,
  subtitle,
  children,
  rightAccessory,
  Modal,
  variant
}: Props) {
  return (
    <Card
      testID={testID ?? "browse-section"}
      eventTargetName="Browse Section Card"
    >
      <FancyHeader
        icon={iconName}
        title={title}
        color={color ?? "primary"}
        action={rightAccessory}
        rightSpacing="compact"
        variant={variant}
      />
      <ContentArea fill>
        {!!subtitle && <Text>{subtitle}</Text>}
        {Modal}
        <Spacer />
        {children}
      </ContentArea>
    </Card>
  );
}
