import React from "react";
import type { Props as ButtonProps } from "../../atoms/Button";
import Stack from "../../atoms/Stack";
import Icon from "../../quarks/Icon";
import Pressable from "../../quarks/Pressable";
import Text from "../../quarks/Text";
import { useStyle } from "../../style";

interface Props
  extends Pick<ButtonProps, "label" | "icon" | "onPress" | "size" | "testID"> {
  destructive?: boolean;
  disabled?: boolean;
}

export const actionButtonSpecs = {
  minWidth: { xsmall: 200, small: 200, large: 400 },
  height: { xsmall: 35, small: 35, large: 52 }
};

export default function ActionSheetButton({
  destructive,
  onPress,
  label,
  icon,
  size,
  testID,
  disabled
}: Props) {
  const viewStyle = useStyle(
    () => ({
      minWidth: actionButtonSpecs.minWidth[size ?? "small"],
      height: actionButtonSpecs.height[size ?? "small"],
      alignItems: "center",
      justifyContent: "center"
    }),
    [size]
  );
  return (
    <Pressable
      style={viewStyle}
      onPress={onPress}
      eventEntityType="ActionSheetButton"
      eventTargetName="Action Sheet Button"
      testID={testID}
      disabled={disabled}
    >
      <Stack
        fill
        size="compact"
        horizontal
        justifyContent="center"
        alignItems="center"
      >
        {icon && (
          <Icon
            color={destructive ? "danger" : disabled ? "disabled" : "primary"}
            size="small"
            name={icon}
            variant="solid"
          />
        )}
        <Text
          weight="bold"
          color={destructive ? "danger" : disabled ? "disabled" : "primary"}
        >
          {label}
        </Text>
      </Stack>
    </Pressable>
  );
}
