import React, { type ComponentType } from "react";
import {
  type GraphQLTaggedNode,
  getInlineDataFragment,
  readInlineData
} from "relay-runtime";
import type {
  FragmentContainerInnerComponentProps,
  FragmentContainerProps
} from "./createRelayFragmentContainer";
import type { KeyType } from "./use-relay-fragment";

export function createRelayInlineFragmentContainer<
  F extends KeyType<unknown> = never,
  P extends {} = {}
>(
  fragment: GraphQLTaggedNode,
  Component: ComponentType<FragmentContainerInnerComponentProps<F, P>>
): ComponentType<FragmentContainerProps<F, P>> {
  const { name } = getInlineDataFragment(fragment);

  function InlineFragmentContainer(props: FragmentContainerProps<F, P>) {
    const data = readInlineData<F>(fragment, props.fragmentRef ?? null);
    if (!data) return null;

    return <Component {...data} {...props} result={data} />;
  }

  InlineFragmentContainer.displayName = `RelayInlineFragmentContainer[${name}]`;

  return InlineFragmentContainer;
}
