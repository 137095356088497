import React from "react";
import { Platform } from "react-native";

import { Icon, Row, Spacer, Text } from "../../quarks";
import { responsive, useStyles } from "../../style";
import { NavIcon } from "../Navigation";

interface Props {
  filename?: string;
  uri?: string;
  onClose?: () => void;
  onDownload?: () => void;
  onPrint?: () => void;
}

export default function PDFToolbar({
  uri,
  filename,
  onDownload,
  onClose,
  onPrint
}: Props) {
  const styles = useStyles(({ media, measurements }) => ({
    toolbar: [
      {
        height: measurements.navbarHeight,
        paddingRight: 12,
        paddingLeft: 16
      },
      responsive(media.size.large.up, { paddingLeft: 24, paddingRight: 24 })
    ]
  }));

  return (
    <Row style={styles.toolbar} alignItems="center">
      <Icon name="file-pdf" size="large" variant="solid" />
      <Spacer horizontal size="compact" />
      <Text variant="header" fill numberOfLines={1}>
        {filename}
      </Text>

      {Platform.OS === "web" && (
        <NavIcon
          testID="print"
          eventTargetName="Print PDF"
          name="print"
          onPress={onPrint}
          variant="solid"
        />
      )}
      <NavIcon
        testID="download"
        eventTargetName="Download PDF"
        name="download"
        onPress={onDownload}
        variant="solid"
      />
      <NavIcon
        testID="close"
        eventTargetName="Close PDF"
        name="times"
        onPress={onClose}
        variant="solid"
      />
    </Row>
  );
}
