import React, { type ReactNode } from "react";
import { Column } from "../../quarks";
import { useStyles } from "../../style";
import { useCalendarContext } from "./CalendarContext";
import DayIndicator from "./DayIndicator";
import type { DayView } from "./helpers";

interface Props {
  testID?: string;
  day: DayView;
  displayDayIndicator?: boolean;
  fill?: boolean;
  grow?: boolean;
  children: ReactNode;
}

export default function DayCell({
  day,
  children,
  displayDayIndicator = true,
  ...rest
}: Props) {
  const { isMd } = useCalendarContext();
  const styles = useStyles(({ media }) => ({
    content: { overflow: "hidden", padding: 2 },
    contentMd: { padding: 4 }
  }));
  return (
    <Column {...rest}>
      {displayDayIndicator && <DayIndicator day={day} />}
      <Column style={[styles.content, isMd && styles.contentMd]} fill>
        {children}
      </Column>
    </Column>
  );
}
