import React from "react";
import IconText from "../atoms/IconText";
import InputLabel from "../atoms/InputLabel";
import Stack from "../atoms/Stack";
import Divider from "../quarks/Divider";
import Pressable from "../quarks/Pressable";

interface Props {
  dob: string;
  onDelete?: () => void;
  minimumAge?: number;
}

export default function AgeVerificationRow({
  dob,
  onDelete,
  minimumAge
}: Props) {
  const label = `Age verification${
    minimumAge ? ` - ${minimumAge} years old` : ""
  }`;
  return (
    <Stack size="slim">
      <InputLabel inset={false} label={label} />
      <Stack size="compact">
        <Divider />
        <Stack horizontal justifyContent="space-between" alignItems="center">
          <IconText icon="id-badge" size="small" spacing="compact">
            {dob}
          </IconText>
          {onDelete && (
            <Pressable
              testID="age-verification-delete-btn"
              eventTargetName="Age Verification Delete Button"
              eventEntityType="IconText"
              onPress={() => onDelete?.()}
            >
              <IconText
                icon="times"
                color="danger"
                iconVariant="solid"
                size="small"
                spacing="compact"
                textWeight="semibold"
              >
                Delete
              </IconText>
            </Pressable>
          )}
        </Stack>
        <Divider />
      </Stack>
    </Stack>
  );
}
