import type { ComponentType } from "react";
import getWrappedComponent from "./get-wrapped-component";

interface HasDisplayName {
  displayName?: string;
  name?: string;
}
export default function getOriginalDisplayName(
  component: ComponentType<any>
): string {
  const wrappedComponent = getWrappedComponent(component) as HasDisplayName;
  return wrappedComponent.displayName ?? wrappedComponent.name ?? "Component";
}
