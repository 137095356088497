import React, { type ReactElement } from "react";
import { StyleSheet, View } from "react-native";

import { Button, ContentArea, IconCircle, Stack, Surface } from "../atoms";
import { Icon, type IconName, Pressable, Row, Spacer, Text } from "../quarks";
import { useStyles } from "../style";

export interface Props {
  title: string;
  description: string;
  titleIcon?: IconName;
  BackgroundImage: ReactElement;
  onSelect: (selected: boolean) => void;
  selected?: boolean;
  buttonTitle?: string;
  onButtonPress?: () => void;
  disabled?: boolean;
  testID: string;
  fill?: boolean;
  variant?: "shadow" | "mixed";
}

const RadioButtonCard = ({
  title,
  description,
  titleIcon,
  BackgroundImage,
  onSelect,
  selected,
  buttonTitle,
  onButtonPress,
  disabled,
  testID,
  fill,
  variant = "mixed"
}: Props) => {
  const styles = useStyles(({ getUnits, getColor }) => ({
    fill: { flex: 1 },
    selected: {
      borderWidth: 2,
      borderColor: getColor("primary", "fill"),
      borderRadius: getUnits(1)
    },
    selectedIcon: {
      ...StyleSheet.absoluteFillObject,
      position: "absolute",
      alignItems: "flex-end",
      padding: getUnits(1),
      zIndex: 2
    },
    disabled: { opacity: 0.35 }
  }));

  return (
    <Pressable
      eventEntityType="RadioButtonCard"
      eventTargetName="Radio Button Card"
      onPress={() => onSelect?.(!selected)}
      testID={testID}
      style={[
        fill && styles.fill,
        disabled ? styles.disabled : selected && styles.selected
      ]}
    >
      <Surface
        variant={selected ? "flat" : variant}
        testID={`${testID}-card`}
        fill={fill}
      >
        {!!BackgroundImage && BackgroundImage}
        {selected && !disabled && (
          <View style={styles.selectedIcon}>
            <IconCircle
              icon="check"
              iconVariant="solid"
              iconColor="white"
              size="tiny"
              color="primary"
            />
          </View>
        )}
        <ContentArea>
          <Row>
            <Stack horizontal size="compact">
              {titleIcon && (
                <Icon
                  name={titleIcon}
                  variant="solid"
                  color="primary"
                  size="small"
                />
              )}
              <Text variant="subheader" color="primary">
                {title}
              </Text>
            </Stack>
          </Row>
          <Spacer size="compact" />
          <Text>{description}</Text>
          {!!buttonTitle && (
            <>
              <Spacer />
              <Button
                testID={`${testID}-btn`}
                size="small"
                label={buttonTitle}
                onPress={onButtonPress}
              />
            </>
          )}
        </ContentArea>
      </Surface>
    </Pressable>
  );
};

export default RadioButtonCard;
