import React from "react";
import { View } from "react-native";
import { useStyle } from "../style";

export default function StickyReminderFooter() {
  const style = useStyle(({ getUnits }) => ({
    height: getUnits(21.5)
  }));
  return <View style={style} />;
}
