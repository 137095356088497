import React, { type ComponentProps } from "react";
import ContentArea from "../atoms/ContentArea";
import Surface from "../atoms/Surface";
import { usePlacementColor } from "../style/colorPlacement";

type Props = Pick<ComponentProps<typeof Surface>, "color" | "fill" | "grow"> &
  Pick<
    ComponentProps<typeof ContentArea>,
    "size" | "children" | "alignItems" | "justifyContent" | "gap" | "horizontal"
  > & {
    contentVariant?: ComponentProps<typeof ContentArea>["variant"];
  };

export default function Well({
  children,
  color,
  size,
  alignItems,
  justifyContent,
  gap,
  horizontal,
  contentVariant = "standard",
  ...props
}: Props) {
  const { name } = usePlacementColor();
  return (
    <Surface
      fade
      variant="flat"
      color={color ?? (name === "background" ? "surface" : "neutral")}
      {...props}
    >
      <ContentArea
        variant={contentVariant}
        size={size}
        justifyContent={justifyContent}
        alignItems={alignItems}
        gap={gap}
        horizontal={horizontal}
      >
        {children}
      </ContentArea>
    </Surface>
  );
}
