import { Persistence } from "@gigsmart/atorasu/utils/persistence";
import { v4 } from "uuid";
import type { Fingerprint } from "./device-id-types";

const persistenceKey = Persistence.keep("___device-id___");
let id: string;

export async function getDeviceId(
  prefix?: string | null
): Promise<Fingerprint> {
  if (!id) {
    id = await Persistence.fetch<string>(persistenceKey, () => v4());
  }
  if (prefix) id = `${prefix}:${id}`;
  console.debug({ id });
  return { id, strategy: "PersistedUUID" };
}
