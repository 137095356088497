import React, { useState, type ReactElement } from "react";
import { useActor, useInit, useResponder } from "./scene";

export interface FormSubmitOptions {
  allowInvalid?: boolean;
}

export interface FormSubmitArgs {
  submit: () => void;
  reset: () => void;
  invalid: boolean;
  submitting: boolean;
  dirty: boolean;
}

export function useFormSubmit({
  allowInvalid = false
}: FormSubmitOptions = {}): FormSubmitArgs {
  const [dirty, setInternalDirty] = useState<boolean>(false);
  const [invalid, setInternalInvalid] = useState<boolean>(false);
  const [submitting, setInternalSubmitting] = useState<boolean>(false);

  // The submit callback
  const submitRaw = useActor<undefined>("submit");
  const reset = useActor<undefined>("reset");

  const submit = (e?: any) => {
    e?.preventDefault?.();
    submitRaw();
  };

  // Update the internal dirty value
  useResponder(
    "dirty",
    ({ dirty: nextDirty }) => {
      setInternalDirty(nextDirty);
    },
    []
  );

  // Update the internal invalid value
  useResponder(
    "invalid",
    ({ invalid: nextInvalid }) => {
      setInternalInvalid(!allowInvalid && nextInvalid);
    },
    []
  );

  // Update the internal submitting value
  useResponder(
    "submitting",
    ({ submitting: nextSubmitting }) => {
      setInternalSubmitting(nextSubmitting);
    },
    [submitting]
  );

  // Set the initial values
  useInit((scene) => {
    setInternalInvalid(scene.invalid);
    setInternalSubmitting(scene.submitting);
    setInternalDirty(scene.dirty);
  }, []);

  return { invalid, dirty, submitting, submit, reset };
}

interface Props {
  children: (args: FormSubmitArgs) => ReactElement | null;
}

export const FormSubmit = React.memo<Props>(function FormSubmit({
  children
}: Props) {
  const props = useFormSubmit();
  return children(props);
});
