import ShadowView from "@gigsmart/shadow-view";
import { compact } from "lodash";
import React from "react";
import { Image } from "react-native";
import AbsoluteContainer from "../atoms/AbsoluteContainer";
import { showImageCarousel } from "../organisms/ImageCarousel";
import Pressable from "../quarks/Pressable";
import Row from "../quarks/Row";
import { useStyles } from "../style";

interface Props {
  images: Array<string>;
  max?: number;
  testID?: string;
  eventTargetName: string;
}
export default function ImageStack({
  images,
  max = 10,
  testID = "image-stack",
  eventTargetName
}: Props) {
  const list = compact(
    images.length > max
      ? [`+${images.length - max}`, ...images.slice(0, max - 1)]
      : images
  );
  const imageSize = 46.5;
  const containerWidth = imageSize * list.length;
  const offset = imageSize * 0.75;
  const styles = useStyles(
    ({ getUnits }) => ({
      container: {
        width: containerWidth - offset * (list.length - 1),
        height: imageSize
      },
      img: {
        height: imageSize,
        width: imageSize,
        borderRadius: getUnits(1)
      },
      shadowView: {
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: getUnits(0.5),
        shadowOffset: { width: getUnits(0.5), height: getUnits(0.5) },
        height: imageSize,
        width: imageSize,
        borderRadius: getUnits(1)
      }
    }),
    [containerWidth, offset, list.length, imageSize]
  );

  return (
    <Pressable
      testID={testID}
      eventEntityType="ImageStack"
      eventTargetName={eventTargetName}
      onPress={() => showImageCarousel(images)}
    >
      <Row style={styles.container} alignItems="center" justifyContent="center">
        {list?.length === 1 ? (
          <Image
            resizeMode="cover"
            source={{ uri: list[0] }}
            style={styles.img}
          />
        ) : (
          list.map((image, index) => {
            return (
              <AbsoluteContainer
                key={image}
                zIndex={list.length - index}
                left={imageSize * index - offset * index}
              >
                <ShadowView style={styles.shadowView}>
                  <Image
                    resizeMode="cover"
                    source={{ uri: image }}
                    style={styles.img}
                  />
                </ShadowView>
              </AbsoluteContainer>
            );
          })
        )}
      </Row>
    </Pressable>
  );
}
