import { noop, omit } from "lodash";
import _ from "lodash";
import React, {
  useEffect,
  type ComponentType,
  useMemo,
  useCallback,
  useState,
  type Dispatch
} from "react";
import {
  type GraphQLTaggedNode,
  type LoadQueryOptions,
  type PreloadedQuery,
  type UseQueryLoaderLoadQueryOptions,
  usePreloadedQuery,
  useQueryLoader
} from "react-relay";
import {
  type Environment,
  type OperationType,
  type Variables,
  getRequest
} from "relay-runtime";
import { useRelayOrchestrator } from "../orchestrator";
import { useRelayBatch } from "./relay-batch";
import { RelayQueryRetryBoundary } from "./retry";
import {
  Suspense,
  useRelayQueryIsInFallback
} from "./use-relay-query-is-in-fallback";

export type SuspendedQueryContainerInnerComponentProps<
  Q extends OperationType,
  P extends {} = {},
  V extends Variables = Q["variables"]
> = P & {
  response?: Q["response"];
  variables?: V;
  retry: (options?: UseQueryLoaderLoadQueryOptions) => void;
  setVariables: Dispatch<React.SetStateAction<V>>;
};

export interface OperationWithVariables<V extends Variables>
  extends OperationType {
  readonly variables: V;
  readonly response: unknown;
}

type PreLoadQueryOptions<V extends Variables> = LoadQueryOptions & {
  environment: Environment;
} & (Variables extends Record<string, never>
    ? { variables?: never }
    : { variables: V });

export interface CreateSuspendedQueryContainerOptions<
  P extends Record<string, unknown>,
  V extends Variables
> extends LoadQueryOptions {
  FallbackComponent?:
    | React.ComponentType<Record<string, unknown>>
    | null
    | undefined
    | true;
  ErrorComponent?: ComponentType<{ error: Error }>;
  query: GraphQLTaggedNode | ((props: P) => GraphQLTaggedNode);
  operationName?: string;
  fetchKey?: string | ((props: P) => string);
  onError?: (err: Error) => unknown;
  waterfall?: boolean;
  isBlocking?: boolean;
  batch?: boolean;
  batchKey?: string;
  decorate?: boolean;
  variables: V | ((props: P) => V);
}

export function createSuspendedQueryContainer<
  Q extends OperationWithVariables<V>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  P extends {} = {},
  V extends Variables = Q["variables"]
>(
  Component: React.ComponentType<
    SuspendedQueryContainerInnerComponentProps<Q, P, V>
  >,
  {
    query,
    FallbackComponent = null,
    ErrorComponent,
    variables,
    onError,
    isBlocking = false,
    batch = false,
    batchKey,
    fetchKey,
    waterfall,
    decorate = true,
    operationName,
    ...loadQueryOptions
  }: CreateSuspendedQueryContainerOptions<P, V>
) {
  let preloadedQueryReference: PreloadedQuery<Q> | undefined;

  if (!operationName) {
    operationName = Component.displayName ?? Component.name ?? "<dynamic>";
    try {
      const querySpec = typeof query === "function" ? query({} as any) : query;
      if (querySpec) operationName = getRequest(querySpec).operation.name;
    } catch (err) {}
  }

  const FallbackWrapper =
    FallbackComponent === true
      ? (((
          props: P & {
            error?: Error;
            setVariables: Dispatch<React.SetStateAction<V>>;
          }
        ) => <Component {...props} retry={noop} />) as ComponentType<
          P & {
            error?: Error;
            setVariables: Dispatch<React.SetStateAction<V>>;
          }
        >)
      : FallbackComponent;

  if (typeof FallbackWrapper === "function") {
    FallbackWrapper.displayName = `SuspendedQueryContainerFallbackWrapper(${operationName})`;
  }

  const useVariables: (props: P) => V = (props: P) => {
    const nextVariables =
      typeof variables === "function" ? variables(props) : variables ?? {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => nextVariables, [JSON.stringify(nextVariables)]);
  };

  const useFetchKey: (props: P) => string | undefined = (props: P) => {
    const nextFetchKey =
      typeof fetchKey === "function" ? fetchKey(props) : fetchKey;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => nextFetchKey, [JSON.stringify(nextFetchKey)]);
  };

  // What we fall back to when the query is not ready yet
  const Fallback = (
    props: P & { setVariables: Dispatch<React.SetStateAction<V>> }
  ) =>
    FallbackWrapper === null ? null : (
      <FallbackWrapper key={`suspendedFallback_${operationName}`} {...props} />
    );

  // What we render when the query is ready
  const Renderer = ({
    queryReference,
    queryVariables,
    setVariables,
    error,
    innerProps,
    retry
  }: {
    innerProps: P;
    error?: Error;
    queryReference: PreloadedQuery<Q>;
    queryVariables: V;
    retry: (options?: UseQueryLoaderLoadQueryOptions) => void;
    setVariables: Dispatch<React.SetStateAction<V>>;
  }) => {
    const querySpec = typeof query === "function" ? query(innerProps) : query;
    const response = usePreloadedQuery(querySpec, queryReference);

    return (
      <Component
        {...(omit(innerProps, [
          "error",
          "variables",
          "response",
          "retry",
          "setVariables"
        ]) as P)}
        error={error}
        variables={queryVariables}
        response={response}
        retry={retry}
        setVariables={setVariables}
      />
    );
  };

  // The outer component that loads the query and/or renders the fallback
  const Fetcher = (props: P) => {
    const { useDecorateQuery } = useRelayOrchestrator();
    const [error, setError] = React.useState<Error | undefined>(undefined);
    const querySpec = typeof query === "function" ? query(props) : query;
    const [queryReference, loadQuery, disposeQuery] = useQueryLoader<Q>(
      querySpec,
      preloadedQueryReference
    );
    const { isBlocking: parentIsBlocking } = useRelayQueryIsInFallback();

    // build the query variables
    const variables = useVariables(props);
    const [queryVariables, setQueryVariables] = useState<V>(variables);
    const fetchKey = useFetchKey(props);

    // Update the variables if the parent changes them
    useEffect(() => {
      if (!_.isEqual(queryVariables, variables)) {
        setQueryVariables(variables);
      }
    }, [variables]);

    // fallback
    const fallback = useMemo(
      () => (
        <Fallback setVariables={setQueryVariables} error={error} {...props} />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [error, props]
    );

    const handleError = useCallback((error: Error) => {
      setError(error);
      onError?.(error);
    }, []);

    const { enable: enableBatch, batchKey: contextBatchKey } = useRelayBatch(
      loadQueryOptions.networkCacheConfig?.batch ?? batch
    );

    const load = useCallback(
      (invokedLoadQueryOptions?: UseQueryLoaderLoadQueryOptions) => {
        try {
          const options = {
            fetchPolicy: "store-and-network" as const,
            ...loadQueryOptions,
            ...invokedLoadQueryOptions
          };
          loadQuery(queryVariables, {
            ...options,
            networkCacheConfig: {
              ...loadQueryOptions.networkCacheConfig,
              ...invokedLoadQueryOptions?.networkCacheConfig,
              batch: enableBatch,
              batchKey:
                invokedLoadQueryOptions?.networkCacheConfig?.batchKey ??
                loadQueryOptions?.networkCacheConfig?.batchKey ??
                batchKey ??
                contextBatchKey
            }
          });
        } catch (err) {
          handleError(err);
        }
      },
      [handleError, loadQuery, queryVariables, contextBatchKey, enableBatch]
    );

    if (decorate) {
      const opName = getRequest(querySpec).operation.name;
      useDecorateQuery(opName, load);
    }

    useEffect(() => {
      if (parentIsBlocking && waterfall) return;
      load();
      // return () => disposeQuery();
    }, [load, fetchKey, parentIsBlocking]);
    return queryReference ? (
      <Suspense
        onError={handleError}
        fallback={fallback}
        ErrorComponent={ErrorComponent}
        isBlocking={isBlocking}
      >
        <RelayQueryRetryBoundary>
          <Renderer
            error={error}
            queryReference={queryReference}
            innerProps={props}
            queryVariables={queryVariables}
            setVariables={setQueryVariables}
            retry={load}
          />
        </RelayQueryRetryBoundary>
      </Suspense>
    ) : (
      fallback
    );
  };

  Fetcher.disposeQuery = () => {
    preloadedQueryReference?.dispose();
  };

  Fetcher.Component = ((
    props: P & {
      response?: Q["response"];
    }
  ) => (
    <Component retry={noop} setVariables={noop} {...props} />
  )) as ComponentType<
    P & {
      response?: Q["response"];
    }
  >;

  Fallback.displayName = `SuspendedQueryContainer[${operationName}](FallbackWrapper)`;
  Renderer.displayName = `SuspendedQueryContainer[${operationName}](SQC.Renderer)`;
  Fetcher.Component.displayName = `SuspendedQueryContainer[${operationName}](SQC.Component)`;
  Fetcher.displayName = `SuspendedQueryContainer[${operationName}](SQC.Fetcher)`;

  return Fetcher;
}
