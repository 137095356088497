import React from "react";
import Svg, { Path, type SvgProps } from "react-native-svg";

export default function SadPanda(props: SvgProps) {
  return (
    <Svg width={400} height={400} viewBox="0 0 260 245" {...props}>
      <Path
        fill="#000000"
        d="M101.5 4.9c-12.6 5.7-16.4 22.3-7.8 33.7l2.3 3.1-3.1 5.8c-1.7 3.1-3.9 9.2-5.1 13.4-1.7 6.6-1.9 9.5-1.3 21.2.5 11.5.3 13.8-1 14.9-.8.7-3 4.2-4.8 7.9-4.8 9.6-11.8 22.4-12.9 23.8-.7.9-.7 1.3 0 1.3.6 0 1.3-.4 1.7-.9.3-.5 3-1.5 6.1-2.2 6.9-1.5 17.7-.1 31.1 4.2 21.6 6.9 28.6 11.5 40.5 26.5 4.8 6.2 10.3 12.1 12 13.3 2.3 1.4 2.8 2.1 1.8 2.4-12.1 3.7-18.3 8.6-23.4 18.7-4.3 8.7-6 18.6-5.3 31.1l.5 9.6-12.3.6c-19.4.9-35.5-2.3-52.1-10.3-11.6-5.6-18.9-11.7-22.4-18.6-2-4.1-2.4-6.3-2.3-13.4.1-10.1 2.1-16.5 10-32.3 12.1-24.2 12.2-29.7.1-6.9-5 9.5-11.3 26.1-12.8 34-1.8 8.9-.9 19.5 2.2 25.9 3.2 6.5 11.2 15.8 14.9 17.4 1.6.6 2.9 1.5 2.9 1.9 0 .4-7.8 1-17.2 1.4-16.7.7-33.7 2.1-38.7 3.2-7.1 1.6 6.2 3.7 34.4 5.4 20.9 1.3 159.4 1.3 180 0 16.4-1 32.8-2.7 35.5-3.6 5.1-1.8-10.7-3.8-39.2-5-8.7-.4-15.8-1-15.8-1.5 0-.4 1.6-2.2 3.6-4.1 12.3-11.4 13.3-34.6 2-44.6-4.3-3.7-7.9-5.7-12.3-6.7-1.8-.3-3.3-.8-3.3-.9 0-.1.9-1.9 2-4.1 1.1-2.2 2-5.3 2-7-.1-4.1-4.1-13.5-7.9-18.3-1.7-2.2-3.1-4.2-3.1-4.5 0-.3 1.4-.8 3-1.2 3.4-.7 14-11 14-13.5 0-.8-.5-3.2-1-5.2-1.2-4.2 0-7.4 7.6-19.8 6.3-10.2 8.6-17.2 9.1-28 .4-8.2.2-10.4-1.7-15.5-1.6-4.4-2.3-8.5-2.4-15-.2-10.8-1.4-13.5-7.5-17.5-4-2.7-5.3-3-11.4-2.9-5.6.2-7.1-.2-9-1.9-1.2-1.1-4.7-3.2-7.7-4.6-12.5-5.7-24.1-7-39.7-4.5l-7.7 1.2-2.5-3c-1.4-1.7-4-3.8-5.8-4.7-4.3-2.2-13.7-2.1-18.8.3zm66.4 11.6c21.8 5.2 40.2 23.6 44.2 44.3 2.1 11.2-.3 34.6-3.2 30.2-.8-1.3-1.2-1.1-2.9 1-4 5-7.8 14-9.6 22.8-1.5 7.7-2 8.9-3.6 8.4-2.2-.5-2.4 2-.3 3.8.8.7 1.5 1.9 1.5 2.6 0 1.5-5.2 4.4-11.2 6.3-3.5 1.2-4 1-8.5-2-6.2-4.2-13.1-10-25.6-21.7-12.2-11.3-21.9-18.4-30.6-22.4-5.7-2.7-7.4-3-14-2.6l-7.6.3-1.8-5c-1.3-3.9-1.7-7.3-1.4-15.1.3-8.8.8-10.9 3.5-16.9 2.9-6.1 3.4-6.7 5.5-6 1.2.4 4.8 1 7.9 1.2 4.9.4 6.5.1 10.9-2.3 7.3-4 10.9-10.4 10.9-19.6v-6.7l4.3-.9c6.7-1.5 24.5-1.3 31.6.3z"
      />
      <Path
        fill="#000000"
        d="M174.5 85.5c-3.8 2.5-9.8 5.4-13.3 6.6-11.2 3.7-14.6 8.6-10.1 14.3 3.9 4.9 16.7 9.6 26.7 9.6 4 0 4.4-.2 5.9-3.8 3.4-8.1 2.6-31.3-1-31.2-.7 0-4.3 2.1-8.2 4.5z"
      />
    </Svg>
  );
}
