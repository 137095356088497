import React, { useMemo } from "react";
import type { ReactNode } from "react";
import { View } from "react-native";
import reactStringReplace from "react-string-replace";
import Avatar from "../../atoms/Avatar";
import ContentArea from "../../atoms/ContentArea";
import IconButton from "../../atoms/IconButton";
import Link from "../../atoms/Link";
import MaxWidthContainer from "../../atoms/MaxWidthContainer";
import Stack from "../../atoms/Stack";
import Surface from "../../atoms/Surface";
import Column from "../../quarks/Column";
import Icon, { type IconName } from "../../quarks/Icon";
import Spacer from "../../quarks/Spacer";
import Text from "../../quarks/Text";
import { useMatchesViewport, useStyles, useViewport } from "../../style";
import type { Color } from "../../style/theme/colors";
import UserMessageAttachments from "./UserMessageAttachments";
import type { Attachment } from "./UserMessageAttachments";

interface AuthorIcon {
  name: IconName;
  color: Color;
  fade: boolean;
}

interface Props {
  id?: string | null;
  body?: string | null;
  attachments?: readonly Attachment[] | null;
  authorName?: string | null;
  authorPhotoUrl?: string | null;
  authorIcon?: AuthorIcon | null;
  sentAt?: string | null;
  isSender?: boolean;
  showName?: boolean;
  showDate?: boolean;
  context?: ReactNode;
  onDelete?: () => void;
  deletedAt?: string | null;
}

export default function UserMessage({
  body: rawBody,
  context,
  attachments,
  isSender,
  authorName,
  authorPhotoUrl,
  authorIcon,
  sentAt,
  id,
  showName = true,
  showDate = true,
  onDelete,
  deletedAt
}: Props) {
  const { width } = useViewport();
  const maxWidth = Math.min(475, width - 76);
  const styles = useStyles(
    ({ getUnits }) => ({
      note: {
        paddingLeft: isSender ? 0 : getUnits(12),
        paddingRight: isSender ? getUnits(2) : 0
      },
      avatar: {
        width: getUnits(7)
      }
    }),
    [isSender]
  );
  const isWeb = useMatchesViewport(({ platform }) => platform.web);
  const body = useMemo(() => {
    let newBody: ReactNode[] | string = rawBody ?? "";
    // eslint-disable-next-line no-useless-escape
    const parsedBody = rawBody?.match(/\[([^\[]+)\](\(([^)]*))\)/gim);
    parsedBody?.forEach((item) => {
      const splitItem = item.split("](");
      const link = splitItem?.[1]?.replace(")", "");
      const text = splitItem?.[0]?.replace("[", "");
      newBody = reactStringReplace(newBody, item, () => (
        <Link key={link} openURL={link}>
          {text}
        </Link>
      ));
    });
    return newBody;
  }, [rawBody]);

  const deleteComponent =
    (onDelete || deletedAt) &&
    (deletedAt ? (
      <Icon name="trash-alt" color="primary" variant="solid" />
    ) : onDelete ? (
      <IconButton
        testID={"user-message-delete-button"}
        name="times-circle"
        color="error"
        onPress={onDelete}
      />
    ) : null);

  return (
    <Column
      alignItems={isSender ? "flex-end" : "flex-start"}
      testID={"user-message"}
    >
      <MaxWidthContainer maxWidth={maxWidth}>
        {!isSender && (
          <Stack
            horizontal
            size="slim"
            alignItems="center"
            justifyContent="flex-start"
          >
            {deleteComponent}
            <Stack size="slim">
              {showName && (
                <Text
                  style={styles.note}
                  variant="note"
                  color="neutral"
                  testID={"user-message-author"}
                >
                  {authorName}
                </Text>
              )}
              <Stack horizontal size="compact">
                {showName && (
                  <Avatar
                    uri={authorPhotoUrl}
                    icon={authorIcon?.name}
                    color={authorIcon?.color}
                    fade={authorIcon?.fade}
                    size="small"
                  />
                )}
                {!showName && <View style={styles.avatar} />}
                <Stack size="slim" fill={isWeb}>
                  {context && (
                    <>
                      {context}
                      <Spacer size="slim" />
                    </>
                  )}
                  {attachments && !!attachments?.length && (
                    <UserMessageAttachments attachments={attachments} />
                  )}
                  {body && (
                    <MaxWidthContainer maxWidth={maxWidth}>
                      <Surface variant="flat" color="background">
                        <ContentArea size="compact" variant="compact">
                          <Text testID={"user-message-body"}>{body}</Text>
                        </ContentArea>
                      </Surface>
                    </MaxWidthContainer>
                  )}
                </Stack>
              </Stack>
              {showDate && (
                <Text
                  style={styles.note}
                  variant="note"
                  color="neutral"
                  testID={"user-message-sent-at"}
                >
                  {sentAt}
                </Text>
              )}
            </Stack>
          </Stack>
        )}
        {isSender && (
          <Stack
            horizontal
            size="slim"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Stack size="slim">
              {context && (
                <>
                  {context}
                  <Spacer size="slim" />
                </>
              )}
              {attachments && !!attachments?.length && (
                <UserMessageAttachments attachments={attachments} />
              )}
              {body && (
                <MaxWidthContainer maxWidth={maxWidth}>
                  <Surface variant="flat" color="highlight">
                    <ContentArea size="compact" variant="compact">
                      <Text testID={"user-message-body"}>{body}</Text>
                    </ContentArea>
                  </Surface>
                </MaxWidthContainer>
              )}
              {showDate && (
                <Column alignItems="flex-end">
                  <Text
                    style={styles.note}
                    variant="note"
                    color="neutral"
                    testID={"user-message-sent-at"}
                  >
                    {sentAt}
                  </Text>
                </Column>
              )}
            </Stack>
            {deleteComponent}
          </Stack>
        )}
      </MaxWidthContainer>
    </Column>
  );
}
