import { useEffect } from "react";
import eventStore, { type ToastSelector } from "./eventStore";

export function useBlockToast(selector: ToastSelector) {
  useEffect(() => {
    eventStore.emit("block", selector);
    return () => {
      eventStore.emit("unblock", selector);
    };
  }, [selector]);
}

export function BlockToast(selector: ToastSelector) {
  useBlockToast(selector);
  return null;
}
