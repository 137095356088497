import React, { type ReactNode } from "react";

import { ContentArea, GridNull, IconText, Stack } from "../atoms";
import { Column, Text } from "../quarks";
import type { IconName } from "../quarks/Icon";
import { useStyles } from "../style/styles";
import Collapsible from "./Collapsible";
import Well from "./Well";

interface Props {
  testID: string;
  headerIcon: IconName;
  headerText: string;
  items: ReactNode[];
}

export default function CollapsibleWell({
  testID,
  headerIcon,
  headerText,
  items
}: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      contentContainer: {
        backgroundColor: getColor("surface", "fill")
      }
    }),
    []
  );
  if (!items.length) {
    return <GridNull />;
  }

  return (
    <Well color="black" size="none" contentVariant="none">
      <Collapsible
        startCollapsed
        testID={`${testID}-collapsible-surface`}
        header={
          <IconText icon={headerIcon} size="small" spacing="compact">
            <Text variant="subheader">
              {headerText}
              {" • "}
              {items.length}
            </Text>
          </IconText>
        }
        variant="plain"
        size="compact"
        highlight
      >
        <Column style={styles.contentContainer}>
          <ContentArea>
            <Stack size="compact">{items.map((item) => item)}</Stack>
          </ContentArea>
        </Column>
      </Collapsible>
    </Well>
  );
}
