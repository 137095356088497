import React from "react";
import IconSquareButton from "../atoms/IconSquareButton";
import type { Color } from "../style/theme/colors";
import { showMediaPicker } from "./MediaPicker";

interface Props {
  label?: string;
  onSave?: (uris: string[]) => Promise<void> | void;
  labelColor?: Color;
}

export default function MediaPickerInput({
  onSave,
  label = "Photo",
  labelColor
}: Props) {
  return (
    <IconSquareButton
      testID="media-picker-input"
      label={label}
      icon="camera"
      color="primary"
      labelColor={labelColor}
      onPress={() => showMediaPicker({ onSelect: onSave, multiple: false })}
    />
  );
}
