import { DateTime } from "luxon";
import React, { useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import {
  Button,
  ContentArea,
  InputLabel,
  ModalBackdrop,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack
} from "../atoms";
import MediaPicker from "../organisms/MediaPicker";
import { showModal } from "../organisms/ModalProvider";
import { Column, Icon, Pressable, Text } from "../quarks";
import { useStyles } from "../style";
import MaskedTextInput from "./MaskedTextInput";

interface Props {
  onSave: (
    dob: string,
    issueDate: string,
    expirationDate: string,
    driversLicense: string
  ) => void;
  onClose: () => void;
  dob?: string;
  driversLicense?: string;
  issueDate?: string;
  expirationDate?: string;
  minimumAge?: number;
}

interface FormValue {
  value: string | undefined;
  errors: string | null;
}

export function showAgeVerificationModal(props: Omit<Props, "onClose">) {
  return showModal({
    raw: true,
    transparent: true,
    children: (close) => (
      <AgeVerificationModalContent {...props} onClose={close} />
    )
  });
}

function AgeVerificationModalContent({
  onSave,
  driversLicense: initialDriversLicense,
  dob: initialDob,
  issueDate: initialIssueDate,
  expirationDate: initialExpirationDate,
  minimumAge,
  onClose
}: Props) {
  const [dob, setDob] = useState<FormValue>({
    value: initialDob,
    errors: validateDob(initialDob, minimumAge)
  });
  const [issueDate, setIssueDate] = useState<FormValue>({
    value: initialIssueDate,
    errors: validateIssueDate(initialIssueDate)
  });
  const [expirationDate, setExpirationDate] = useState<FormValue>({
    value: initialExpirationDate,
    errors: validateExpirationDate(initialExpirationDate)
  });
  const [driversLicense, setDriversLicense] = useState<string | undefined>(
    initialDriversLicense
  );
  const [showMediaPicker, setShowMediaPicker] = useState(false);
  const disabled =
    !dob?.value ||
    !driversLicense ||
    !issueDate?.value ||
    !expirationDate?.value ||
    !!dob?.errors ||
    !!issueDate?.errors ||
    !!expirationDate?.errors;
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      wrapper: {
        ...StyleSheet.absoluteFillObject,
        padding: getUnits(5),
        alignItems: "center",
        justifyContent: "center"
      },
      content: {
        width: "100%",
        borderRadius: getUnits(1),
        backgroundColor: getColor("surface", "fill"),
        maxWidth: 440
      },
      closeIconPos: {
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        top: getUnits(2),
        right: getUnits(2)
      },
      image: {
        height: 60,
        width: 60,
        borderRadius: 4,
        resizeMode: "cover"
      },
      imageWrapper: {
        height: 60,
        width: 60
      }
    }),
    []
  );
  if (showMediaPicker) {
    return (
      <MediaPicker
        onClose={() => setShowMediaPicker(false)}
        onSelect={(val) => {
          setDriversLicense(val?.[0]);
          setShowMediaPicker(false);
        }}
      />
    );
  }

  return (
    <>
      <ModalBackdrop />
      <Column fill>
        <View style={styles.wrapper}>
          <View style={styles.content}>
            <ModalHeader title="Age Verification" onClose={onClose} />
            <ModalBody>
              <Stack>
                <Text>
                  Verify and enter the recipient’s date of birth, license issue
                  date, license expiration date and take a photo of the front of
                  their driver’s license.
                </Text>
                <MaskedTextInput
                  testID="dob-input"
                  label="Date of Birth"
                  placeholder="MM/DD/YYYY"
                  mask="date"
                  leftAccessory={
                    <ContentArea size="none" variant="compact">
                      <Icon
                        name="calendar"
                        variant="solid"
                        size="small"
                        color="primary"
                      />
                    </ContentArea>
                  }
                  type="number"
                  value={dob.value}
                  textAlign="left"
                  onChangeText={(value) => {
                    setDob({ value, errors: validateDob(value, minimumAge) });
                  }}
                  errors={dob.errors}
                />
                <MaskedTextInput
                  testID="issue-date-input"
                  label="License Issue Date"
                  placeholder="MM/DD/YYYY"
                  mask="date"
                  leftAccessory={
                    <ContentArea size="none" variant="compact">
                      <Icon
                        name="calendar"
                        variant="solid"
                        size="small"
                        color="primary"
                      />
                    </ContentArea>
                  }
                  type="number"
                  max={6}
                  value={issueDate.value}
                  textAlign="left"
                  onChangeText={(value) => {
                    setIssueDate({ value, errors: validateIssueDate(value) });
                  }}
                  errors={issueDate.errors}
                />
                <MaskedTextInput
                  testID="expiration-date-input"
                  label="License Expiration Date"
                  placeholder="MM/DD/YYYY"
                  mask="date"
                  leftAccessory={
                    <ContentArea size="none" variant="compact">
                      <Icon
                        name="calendar"
                        variant="solid"
                        size="small"
                        color="primary"
                      />
                    </ContentArea>
                  }
                  type="number"
                  value={expirationDate.value}
                  textAlign="left"
                  onChangeText={(value) => {
                    setExpirationDate({
                      value,
                      errors: validateExpirationDate(value)
                    });
                  }}
                  errors={expirationDate.errors}
                />

                {driversLicense ? (
                  <Stack size="compact">
                    <InputLabel
                      inset={false}
                      label="Photo of Driver's License"
                    />
                    <Pressable
                      eventEntityType="IconSquareButton"
                      eventTargetName="Driver's License Photo Button"
                      testID="drivers-license-input"
                      onPress={() => setShowMediaPicker(true)}
                      style={styles.imageWrapper}
                    >
                      <Image
                        style={styles.image}
                        source={{ uri: `file://${driversLicense}` }}
                      />
                    </Pressable>
                  </Stack>
                ) : (
                  <Column alignItems="center">
                    <Button
                      testID="media-picker-input"
                      label="Add Driver's License Photo"
                      icon="plus"
                      onPress={() => setShowMediaPicker(true)}
                      size="small"
                    />
                  </Column>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                label="Save"
                onPress={() => {
                  if (
                    dob.value &&
                    issueDate.value &&
                    expirationDate.value &&
                    driversLicense
                  ) {
                    onSave(
                      dob.value,
                      issueDate.value,
                      expirationDate.value,
                      driversLicense
                    );
                    onClose();
                  }
                }}
                disabled={disabled}
                testID="save-age-verification-btn"
              />
            </ModalFooter>
          </View>
        </View>
      </Column>
    </>
  );
}

function validateDob(value?: string, minimumAge?: number) {
  if (!value) return null;
  if (value?.length !== 8 && value?.length !== 10) return "Invalid date.";
  const dt = DateTime.fromFormat(value, "LL/dd/yyyy");
  if (!dt.isValid) return "Invalid date.";
  if (dt.diffNow("seconds").seconds > 0)
    return "Date of Birth cannot be in the future.";
  if (minimumAge && dt.diffNow("years").years < minimumAge)
    return `Recipient must be at least ${minimumAge} years of age.`;
  return null;
}

function validateIssueDate(value?: string) {
  if (!value) return null;
  if (value?.length !== 8 && value?.length !== 10) return "Invalid date.";
  const dt = DateTime.fromFormat(value, "LL/dd/yyyy");
  if (!dt.isValid) return "Invalid date.";
  if (dt.diffNow("seconds").seconds > 0)
    return "Issue Date cannot be in the future.";
  return null;
}

function validateExpirationDate(value?: string) {
  if (!value) return null;
  if (value?.length !== 8 && value?.length !== 10) return "Invalid date.";
  const dt = DateTime.fromFormat(value, "LL/dd/yyyy");
  if (!dt.isValid) return "Invalid date.";
  if (dt.diffNow("seconds").seconds < 0) return "License cannot be expired.";
  return null;
}
