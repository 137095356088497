import {
  type LinkingOptions,
  type ParamListBase,
  getActionFromState,
  getStateFromPath
} from "@react-navigation/native";
import {
  type History,
  type Location,
  type LocationState,
  createLocation
} from "history";
import { noop } from "lodash";
import { stringify } from "query-string";
import React, { type ReactNode, useMemo } from "react";
import { Router } from "react-router";
import { navigationRef } from "./navigation/delegate";
import { defaultBackHandler } from "./navigation/helpers";
import type { LocationShape } from "./types";

interface Props<T extends ParamListBase> {
  children?: ReactNode;
  linking?: LinkingOptions<T>;
}

const IS_TESTING =
  process.env.IS_TESTING === "true" || process.env.CONFIG_ENV === "e2e";

/** @deprecated */
export default function LegacyRouter<T extends ParamListBase>({
  linking,
  children
}: Props<T>) {
  const history = useMemo(() => createHistory(linking), []);
  return <Router history={history}>{children}</Router>;
}

function createHistory<T extends ParamListBase>(
  linking?: LinkingOptions<T>
): History {
  const push = (rawPath: string | LocationShape, state?: LocationState) => {
    const location = assertQueryParams(rawPath, state);
    const path = location.pathname
      ? `${location.pathname}${location.search ?? ""}`
      : null;

    if (path && navigationRef.isReady()) {
      /// try to handle navigation using react-navigation
      const toState = linking?.getStateFromPath ?? getStateFromPath;
      const state = toState(path, linking?.config as any);
      if (!state) {
        if (IS_TESTING) {
          // ignore if testing
          return;
        }
        throw new Error(`Failed to parse Navigation State: path=${path}`);
      }

      // one more compat hack (force push if Legacy route)
      const action = getActionFromState(state, linking?.config as any);
      action ? navigationRef.dispatch(action) : navigationRef.reset(state);
    }
  };

  const EMPTY_LOCATION = { hash: "", pathname: "", search: "", state: {} };
  const warn: any = () => {
    if (__DEV__) {
      console.warn(new Error("This method should not be used.").stack);
      throw new Error("This method should not be used.");
    }
  };

  return {
    push,
    replace: push,
    action: "PUSH",
    block: warn,
    createHref: warn,
    go: warn,
    goBack: defaultBackHandler,
    goForward: warn,
    length: 0,
    listen: () => noop,
    get location(): Location {
      // console.warn(new Error("This method should not be used."));
      return EMPTY_LOCATION;
    }
  };
}

function assertQueryParams(
  path: string | LocationShape,
  locState?: LocationState
): LocationShape {
  if (typeof path === "string") {
    return createLocation(path, locState);
  }
  if (path.queryParams) {
    path.search = stringify(path.queryParams);
    // biome-ignore lint/performance/noDelete: <explanation>
    delete path.queryParams;
  }
  return path;
}
