import _ from "lodash";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import InputNote from "../atoms/InputNote";
import TextInput from "../molecules/TextInput";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import Text from "../quarks/Text";

interface Props {
  testID: string;
  hours?: string | null;
  minutes?: string | null;
  onChangeHours?: (value: string) => void;
  onChangeMinutes?: (value: string) => void;
  errors?: string | string[] | Error | Error[] | null;
  onBlur?: () => void;
  onFocus?: () => void;
}

export default function HoursMinutesInput({
  testID,
  hours: hourValue,
  minutes: minutesValue,
  onChangeHours,
  onChangeMinutes,
  errors,
  onBlur,
  onFocus
}: Props) {
  const [hours, setHours] = useState(hourValue);
  const [minutes, setMinutes] = useState(minutesValue);
  const handleChangeHours = (nh: string) => {
    const newHours = nh.replace(/[^0-9]+/g, "");
    onChangeHours?.(newHours);
    setHours(newHours);
  };

  const handleChangeMinutes = (nm: string) => {
    const newMinutes = nm.replace(/[^0-9]+/g, "");
    setMinutes(newMinutes);
    onChangeMinutes?.(newMinutes);
  };

  useEffect(() => {
    setHours(hourValue);
  }, [hourValue]);

  useEffect(() => {
    setMinutes(minutesValue);
  }, [minutesValue]);

  const errorMessage = Array.isArray(errors)
    ? _.uniq(_.compact(errors.map(formatError))).join(", ")
    : formatError(errors);
  return (
    <Column gap="compact">
      <Row gap="compact">
        <Row gap="slim" alignItems="center">
          <View style={{ width: 40 }}>
            <TextInput
              testID={`${testID}-hours-input`}
              type="numeric"
              keyboardType="numeric"
              maxLength={2}
              style={{ maxWidth: 88 }}
              value={hours ?? ""}
              placeholder=" - "
              onChangeText={handleChangeHours}
              onBlur={() => onBlur?.()}
              onFocus={() => onFocus?.()}
            />
          </View>
          <Text>Hrs</Text>
        </Row>
        <Row gap="slim" alignItems="center">
          <View style={{ width: 40 }}>
            <TextInput
              testID={`${testID}-minutes-input`}
              maxLength={2}
              type="numeric"
              keyboardType="numeric"
              style={{ maxWidth: 88 }}
              value={minutes ?? ""}
              placeholder=" - "
              onChangeText={handleChangeMinutes}
              onBlur={() => onBlur?.()}
              onFocus={() => onFocus?.()}
            />
          </View>
          <Text>Mins</Text>
        </Row>
      </Row>
      {!!errorMessage && (
        <InputNote
          error
          testID="hours-minutes-input-error"
          note={errorMessage}
        />
      )}
    </Column>
  );
}

function formatError(error?: Error | string | null) {
  if (error) return typeof error === "string" ? error : error.message;
}
