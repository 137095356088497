import React, { type ReactNode } from "react";
import { type StyleProp, StyleSheet, View } from "react-native";
import { IsConstrainedProvider, useContentArea } from "../atoms";
import { type ViewStyle, useMatchesViewport, useStyles } from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";
import type { ThemeMedia } from "../style/theme";

export interface ScreenStyleProps {
  style: StyleProp<ViewStyle>;
  contentContainerStyle: StyleProp<ViewStyle>;
}

export interface ScreenProps {
  testID: string;
  header?: ReactNode;
  footer?: ReactNode;
  constraint?: keyof ThemeMedia["size"] | "none";
  color?: "background" | "surface";
  grow?: boolean;
  inset?: boolean;
}

export function useScreen({
  color = "background",
  constraint = "large",
  inset,
  grow
}: Pick<ScreenProps, "color" | "constraint" | "grow" | "inset">): [
  ScreenStyleProps,
  boolean
] {
  const isLg = useMatchesViewport(({ size }) => size.medium.up);
  const forceInset =
    inset ?? (constraint !== "none" && constraint !== "xlarge" && isLg);

  const [contentStyle, isConstrained] = useContentArea({
    constraint,
    size: "none",
    variant: "none",
    constrainedVariant: "standard",
    color
  });

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      container: { flex: 1, backgroundColor: getColor(color, "fill") },
      inset: { paddingHorizontal: getUnits(4) },
      grow: { flexGrow: 1 }
    }),
    [color, isLg]
  );

  return [
    {
      style: styles.container,
      contentContainerStyle: [
        contentStyle,
        forceInset && styles.inset,
        grow && styles.grow
      ]
    },
    isConstrained || forceInset
  ];
}

type Props = ScreenProps & {
  children: (extra: ScreenStyleProps) => JSX.Element;
};

const extras = {
  dataSet: { component: "ScreenWrapper" }
};

export function ScreenWrapper({
  testID,
  color = "background",
  constraint,
  grow = false,
  inset,
  header,
  footer,
  children
}: Props) {
  const [extra, isConstrained] = useScreen({ color, constraint, grow, inset });
  return (
    <ColorPlacementProvider color={color}>
      <View style={styles.fill} testID={testID} {...extras}>
        <IsConstrainedProvider value={isConstrained}>
          {header}
          {children(extra)}
          {footer}
        </IsConstrainedProvider>
      </View>
    </ColorPlacementProvider>
  );
}

export function createScreenComponent<T extends { testID: string }>(
  renderContent: (props: ScreenStyleProps & T) => JSX.Element
) {
  return function ScreenComponent({
    color = "background",
    constraint,
    header,
    footer,
    grow = false,
    inset,
    ...props
  }: ScreenProps & T) {
    const componentProps: T = props as T;
    return (
      <ScreenWrapper
        color={color}
        constraint={constraint}
        header={header}
        footer={footer}
        grow={grow}
        inset={inset}
        testID={`${componentProps.testID}-wrapper`}
      >
        {(extra) => renderContent({ grow, ...componentProps, ...extra })}
      </ScreenWrapper>
    );
  };
}

const styles = StyleSheet.create({
  fill: { flex: 1 }
});
