import React, { createContext, type ReactNode, useMemo, useState } from "react";
import type { RichEditor } from "react-native-pell-rich-editor";

interface WysiwygContextProp {
  getRef: () => React.RefObject<RichEditor> | null;
  setRef: (editorRef: React.RefObject<RichEditor>) => void;
}

const WysiwygContext = createContext<WysiwygContextProp>({
  getRef: () => null,
  setRef: () => null
});

export { WysiwygContext };

interface WysiwygProviderProps {
  children: ReactNode;
}
export default function WysiwygProvider({ children }: WysiwygProviderProps) {
  const [editorRef, setEditorRef] =
    useState<React.RefObject<RichEditor> | null>(null);

  const data = useMemo(
    () => ({
      getRef: () => editorRef,
      setRef: (ref: React.RefObject<RichEditor>) => setEditorRef(ref)
    }),
    [editorRef]
  );

  return (
    <WysiwygContext.Provider value={data}>{children}</WysiwygContext.Provider>
  );
}
