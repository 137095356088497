import { applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentClass } from "react";
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { logger } from "../logger";
import { useRelayEnvironment } from "./use-relay-environment";

export interface WithEnvironmentProps {
  relay: { environment: Environment };
}

const source = new RecordSource();
const store = new Store(source);
const handlerProvider = null;

let environmentStub: Environment | null = null;
export const createEnvironmentStub = () => {
  if (environmentStub) return environmentStub;
  logger.info("Initializing Relay Environment Stub");
  environmentStub = new Environment({
    handlerProvider,
    network: Network.create(async () => {
      throw new Error("Environment not configured");
    }),
    store
  });
  return environmentStub;
};

export const withRelayEnvironment = (
  WrappedComponent: ComponentClass<WithEnvironmentProps & any>
) =>
  applyHOCProperties({
    displayName: "withRelayEnvironment",
    WrappedComponent,
    HigherOrderComponent: (props) => {
      const environment = useRelayEnvironment();
      return <WrappedComponent {...props} relay={{ environment }} />;
    }
  });
