import type { DependencyList } from "react";
import { type Thunk, useThunk } from "../../utils/thunk";
import { useTheme } from "./ThemeProvider";
import type { Theme } from "./createTheme";

export type ThemeableThunk<T> = Thunk<[Theme], T>;
export function useThemeable<T>(
  thunk: ThemeableThunk<T>,
  deps: DependencyList = []
): T {
  return useThunk(thunk, [useTheme()], deps);
}

export { defaultTheme as default } from "./createTheme";
export { useTheme, ThemeProvider } from "./ThemeProvider";
export { textColorNames, touchableColorNames } from "./colors";
export type { ColorProp, Color } from "./colors";
export { buildMediaSize } from "./media";

export type { Theme, ThemeVariables, ThemeLayout } from "./createTheme";
export type { ThemeMedia, ThemeMediaKey } from "./media";
