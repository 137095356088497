import { useMemo } from "react";

import type { DateTime } from "luxon";

export default function useCalendar(month: DateTime) {
  const key = `${month.year}-${month.month}`;
  const weeks = useMemo(() => {
    const result: DateTime[][] = [];
    const monthStart = month.startOf("month");
    const nextMonth = monthStart.plus({ month: 1 });

    let current = monthStart.startOf("week").plus(-1);

    while (current < nextMonth) {
      const nextWeek = current.plus({ week: 1 });
      const currentWeek: DateTime[] = [];
      while (current < nextWeek) {
        currentWeek.push(current);
        current = current.plus({ day: 1 });
      }
      result.push(currentWeek);
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return weeks;
}
