import React from "react";
import Pressable, { type PressableProps } from "../quarks/Pressable";
import IconCircle, { type Props as IconProps } from "./IconCircle";

interface Props extends IconProps {
  eventEntityType?: string;
  onPress?: () => void;
  testID: string;
  hitSlop?: PressableProps["hitSlop"];
}

export default function IconCircleButton({
  onPress,
  testID,
  eventEntityType = "IconButton",
  hitSlop,
  ...iconProps
}: Props) {
  return (
    <Pressable
      hitSlop={hitSlop}
      onPress={onPress}
      eventTargetName=""
      eventEntityType={eventEntityType}
      testID={testID}
    >
      <IconCircle {...iconProps} />
    </Pressable>
  );
}
