import React, { type ReactNode, useState } from "react";
import { View } from "react-native";
import RNCollapsible from "react-native-collapsible";
import Button from "../atoms/Button";
import Card from "../atoms/Card";
import Surface from "../atoms/Surface";
import Divider from "../quarks/Divider";
import Row from "../quarks/Row";

interface Props {
  children?: ReactNode;
  content?: ReactNode;
  startCollapsed?: boolean;
  onSetCollapsed?: (collapsed: boolean) => void;
  testID: string;
  header: ReactNode;
  variant?: "card" | "surface" | "flat";
  expandLabel?: string;
  collapseLabel?: string;
}

export default function CollapsibleContainer({
  children,
  startCollapsed,
  onSetCollapsed,
  testID,
  variant = "surface",
  content,
  header,
  expandLabel = "Show Details",
  collapseLabel = "Hide Details"
}: Props) {
  const [collapsed, setCollapsed] = useState(startCollapsed ?? false);
  const OuterComponent =
    variant === "surface" ? Surface : variant === "card" ? Card : View;
  return (
    <OuterComponent testID={testID} eventTargetName="Collapsible Container">
      {header}
      {content}
      <RNCollapsible collapsed={collapsed}>{children}</RNCollapsible>
      <Divider />
      {!!children && (
        <Row justifyContent="center" alignItems="center">
          <Button
            testID={`${testID}-expand-collapse-btn`}
            icon={collapsed ? "chevron-down" : "chevron-up"}
            size="small"
            variant="clear"
            label={collapsed ? expandLabel : collapseLabel}
            onPress={() => {
              if (onSetCollapsed) onSetCollapsed(!collapsed);
              setCollapsed(!collapsed);
            }}
          />
        </Row>
      )}
    </OuterComponent>
  );
}
