import React, {
  Children,
  useCallback,
  useState,
  createContext,
  useContext
} from "react";
import type { LayoutChangeEvent } from "react-native";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import { useStyles } from "../style";
import type { OnlyChildren } from "../utils/types";
import { useIsConstrained } from "./Constraint";

interface Props extends OnlyChildren {
  maxColumns: number;
  matchHeight?: boolean;
  adaptToConstraints?: boolean;
}

const InCardDeckContext = createContext(false);
export const useInCardDeckContext = () => useContext(InCardDeckContext);
export const InCardDeckProvider = InCardDeckContext.Provider;

export default function CardDeck({
  children,
  maxColumns,
  matchHeight,
  adaptToConstraints
}: Props) {
  if (typeof matchHeight === "undefined") matchHeight = maxColumns > 1;
  const [deckWidth, setDeckWidth] = useState(0);
  const [minWrapperHeight, setMinWrapperHeight] = useState(0);
  const isConstrained = useIsConstrained();
  const handleDeckLayout = useCallback(
    (e: LayoutChangeEvent) => {
      const w = e.nativeEvent?.layout.width;
      if (w && w !== deckWidth) setDeckWidth(w);
    },
    [deckWidth]
  );
  const handleWrapperLayout = useCallback(
    (e: LayoutChangeEvent) => {
      const h = Math.max(minWrapperHeight, e.nativeEvent?.layout.height ?? 0);
      if (h && h !== minWrapperHeight) setMinWrapperHeight(minWrapperHeight);
    },
    [minWrapperHeight]
  );
  const styles = useStyles(
    ({ getUnits, media }) => {
      const cardMinWidth = media.size.xsmall.maxWidth ?? 0;
      const innerDeck =
        adaptToConstraints && isConstrained
          ? deckWidth
          : deckWidth - getUnits(4);
      const columns = Math.floor(innerDeck / cardMinWidth);
      const wrapperWidth = innerDeck / columns;
      return {
        deck: {
          width: "100%",
          paddingVertical: getUnits(2),
          paddingHorizontal:
            adaptToConstraints && isConstrained ? 0 : getUnits(2)
        },
        wrapper: {
          minHeight: matchHeight ? minWrapperHeight : undefined,
          width:
            columns > 1 ? Math.max(cardMinWidth ?? 0, wrapperWidth) : "100%",
          paddingVertical: getUnits(2),
          paddingHorizontal:
            columns === 1 && adaptToConstraints && isConstrained
              ? 0
              : getUnits(2)
        },
        innerDeck: {
          width:
            columns > 1
              ? Math.min(columns, maxColumns) * wrapperWidth
              : innerDeck
        }
      };
    },
    [deckWidth, minWrapperHeight, maxColumns, matchHeight, adaptToConstraints]
  );
  return (
    <InCardDeckProvider value={maxColumns > 1}>
      <Row
        onLayout={handleDeckLayout}
        style={styles.deck}
        justifyContent="center"
      >
        <Row style={styles.innerDeck} wrap="wrap">
          {Children.toArray(children).map((child, index) => (
            <Column
              key={index}
              style={styles.wrapper}
              onLayout={handleWrapperLayout}
            >
              {child}
            </Column>
          ))}
        </Row>
      </Row>
    </InCardDeckProvider>
  );
}

CardDeck.defaultProps = {
  maxColumns: 1
};
