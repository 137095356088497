import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentClass } from "react";
import {
  useHistory,
  useLocation,
  useQueryParams,
  useRouteMatch
} from "./hooks";

/** @deprecated use navigation */
function useRouter() {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  return { match, location, history, queryParams };
}

export const withRouter: HOCVoid = (WrappedComponent: ComponentClass<any>) =>
  applyHOCProperties({
    displayName: "withRouter",
    WrappedComponent,
    HigherOrderComponent: (props) => {
      const routerProps = useRouter();
      return <WrappedComponent {...props} {...routerProps} />;
    }
  });
