import React, { type ComponentProps } from "react";
import type { ReactNode } from "react";

import Stack from "../atoms/Stack";
import IconRadioButton from "../molecules/IconRadioButton";
import RadioButtonBox from "../molecules/RadioButtonBox";
import RadioButtonCard from "../molecules/RadioButtonCard";
import RadioButtonRow from "../molecules/RadioButtonRow";
import SvgRadioButton from "../molecules/SvgRadioButton";
import type { UnitSize } from "../quarks";
import Divider from "../quarks/Divider";
import { useMatchesViewport } from "../style";
import type { ThemeableMediaThunk } from "../style";

const componentVariants = {
  icon: IconRadioButton,
  svg: SvgRadioButton,
  box: RadioButtonBox,
  standard: RadioButtonRow,
  card: RadioButtonCard
};

type ButtonVariant = keyof typeof componentVariants;
type ButtonVariantProps<TVar extends ButtonVariant> = Omit<
  ComponentProps<(typeof componentVariants)[TVar]>,
  "selected" | "onSelect"
> & {
  selectedChildren?: ReactNode;
};

// FIXME: `buttons` prop should be directly associated with `variant` prop.
// FIXME: the way it is, one could create a `svg` variant with buttons with `standard` expected format.
export interface RadioButtonGroupProps<T> {
  variant?: ButtonVariant;
  horizontal?: boolean | ThemeableMediaThunk;
  buttons: Array<
    { value: T } & (
      | ButtonVariantProps<"box">
      | ButtonVariantProps<"icon">
      | ButtonVariantProps<"standard">
      | ButtonVariantProps<"svg">
      | ButtonVariantProps<"card">
    )
  >;
  spacing?: UnitSize;
  value?: T | null;
  topDvider?: boolean;
  onChange: (value: T) => void;
}

export default function RadioButtonGroup<T>({
  variant = "standard",
  horizontal: horizontalThunk = false,
  topDvider = true,
  spacing,
  buttons,
  value,
  onChange
}: RadioButtonGroupProps<T>) {
  const RadioComponent = componentVariants[variant];
  const matchesHorizontalViewport = useMatchesViewport(
    typeof horizontalThunk === "boolean" ? undefined : horizontalThunk
  );
  const horizontal =
    typeof horizontalThunk === "boolean"
      ? horizontalThunk
      : matchesHorizontalViewport;
  return (
    <>
      {variant === "standard" && topDvider && <Divider />}
      <Stack
        horizontal={
          (variant !== "standard" &&
            variant !== "box" &&
            horizontalThunk === false) ||
          horizontal
        }
        variant={"standard"}
        size={
          spacing ??
          (variant === "box" || variant === "standard" ? "compact" : "standard")
        }
      >
        {buttons.map(({ value: buttonValue, ...rest }) => (
          <RadioComponent
            key={String(buttonValue)}
            onSelect={() => onChange(buttonValue)}
            selected={value === buttonValue}
            {...(rest as any)}
          />
        ))}
      </Stack>
    </>
  );
}
