import memoize from "lodash/memoize";
import { DateTime } from "luxon";
import moment, { type Moment } from "moment-timezone";
import { useEffect, useState } from "react";

import { getNumberSuffix } from "./numeric";

export type DateTimeFormat = DateTime;

type TimeConvertable =
  | string
  | Moment
  | Date
  | DateTime
  | null
  | undefined
  | number;

export type HumanizeFormat =
  | "hm"
  | "dateTime"
  | "dateTimeAlt"
  | "dateTimeDateFirst"
  | "dateTimeShort"
  | "dateTimeFull"
  | "dateTimeCompact"
  | "dateShort"
  | "dateShortAlt"
  | "dateTiny"
  | "dateCompact"
  | "longFormDate"
  | "fullDatePlus"
  | "fullDate"
  | "fullDateShort"
  | "full"
  | "compactFull"
  | "hmz";

export const humanize = (
  date: TimeConvertable,
  fmt: HumanizeFormat = "hm",
  timezone?: string | null
): string => {
  const obj =
    date instanceof DateTime
      ? timezone
        ? moment.tz(date.toJSDate(), timezone)
        : moment(date.toJSDate())
      : timezone
        ? moment.tz(date ?? undefined, timezone)
        : moment(date ?? undefined);
  switch (fmt) {
    case "hm":
      return obj.format("h:mm A");
    case "hmz":
      return obj.format("h:mm A z");
    case "dateTime":
      return obj.format("hh:mmA [on] l");
    case "dateTimeAlt":
      return `${obj.format("M[/]D[/]YY [at] h:mmA")} ${moment
        .tz(obj.toISOString(), getDefaultTimezone())
        .format("zz")}`;
    case "dateTimeDateFirst":
      return obj.format("MMMM D, YYYY, [at] h:mmA");
    case "dateTimeShort":
      return `${obj.format("h:mmA [on] dddd, MMM. D")}`;
    case "dateTimeFull":
      return `${obj.format("h:mmA")} ${moment
        .tz(obj.toISOString(), getDefaultTimezone())
        .format("zz")} on ${humanize(obj, "fullDate")}`;
    case "dateTimeCompact":
      return `${obj.format("h:mmA, MMM. DD, YYYY")}`;
    case "dateShort":
      return obj.format("ll");
    case "dateShortAlt":
      return obj.calendar({
        sameDay: "[Today], MMM Do, YYYY",
        nextDay: "[Tomorrow], MMM Do, YYYY",
        lastDay: "MMM Do, YYYY",
        nextWeek: "MMM Do, YYYY",
        lastWeek: "MMM Do, YYYY",
        sameElse: "MMM Do, YYYY"
      });
    case "dateTiny":
      return obj.format("MMM. Do");
    case "dateCompact":
      return obj.format("M[/]D[/]YY");
    case "longFormDate":
      return obj.format("dddd, MMMM Do");
    case "fullDate":
      return obj.format("dddd, MMMM D, YYYY");
    case "fullDateShort":
      return obj.calendar({
        sameDay: "[Today], MMM Do",
        nextDay: "[Tomorrow], MMM Do",
        nextWeek: "ddd, MMM Do",
        lastDay: "[Yesterday], MMM Do",
        lastWeek: "ddd, MMM Do",
        sameElse: "ddd, MMM Do"
      });
    case "fullDatePlus":
      return obj.calendar({
        sameDay: "[Today], MMMM Do, YYYY",
        nextDay: "[Tomorrow], MMMM Do, YYYY",
        nextWeek: "dddd, MMMM Do, YYYY",
        lastDay: "dddd, MMMM Do, YYYY",
        lastWeek: "dddd, MMMM Do, YYYY",
        sameElse: "dddd, MMMM Do, YYYY"
      });
    case "full":
      return obj.format("dddd, MMMM D, YYYY, [at] h:mmA");
    case "compactFull":
      return obj.format("MMM[.] Do [at] h:mmA");
    default:
      console.error("Unexpected format: '%s'", fmt);
      return "-";
  }
};

export function isToday(date: string) {
  const dateTime = DateTime.fromISO(date);
  return DateTime.local().hasSame(dateTime, "day");
}

export function isTomorrow(date: string) {
  const dateTime = DateTime.fromISO(date);
  return DateTime.local().plus({ days: 1 }).hasSame(dateTime, "day");
}

export function formatToday(
  date: string,
  fmt?: { today?: string; tomorrow?: string; other?: string }
) {
  if (fmt?.today && isToday(date)) return fmt.today;
  if (fmt?.tomorrow && isTomorrow(date)) return fmt.tomorrow;
  return fmt?.other ?? "";
}

export const now = (timezone?: string | null) =>
  getDateTime(undefined, timezone);

export const getDateTime = (
  time: TimeConvertable,
  timezone?: string | null
): DateTime => {
  let dateTime = DateTime.local();
  if (typeof time === "string") dateTime = DateTime.fromISO(time);
  if (typeof time === "number") dateTime = DateTime.fromSeconds(time);
  if (time instanceof DateTime) dateTime = DateTime.local();
  if (time instanceof Date) dateTime = DateTime.fromJSDate(time);
  if (time instanceof moment) dateTime = getDateTime((time as Moment).toDate());
  if (time instanceof DateTime) dateTime = time;
  return timezone ? dateTime.setZone(timezone) : dateTime;
};

export const coerceTimezone = (
  time: TimeConvertable,
  zoneId: string,
  forceDate?: TimeConvertable
) => {
  const { year, month, day } = forceDate
    ? // biome-ignore lint/correctness/noUnsafeOptionalChaining: <explanation>
      getDateTime(forceDate)?.toObject()
    : { year: undefined, month: undefined, day: undefined };
  return moment.tz(
    getDateTime(time)
      .setZone(zoneId, { keepLocalTime: true })
      .set({ year, month, day })
      .toJSDate(),
    zoneId
  );
};

export const addDuration = (
  time: TimeConvertable,
  dur?: any | null,
  zoneId?: string | null
) => {
  let obj = zoneId ? moment.tz(time, zoneId) : moment(time);
  if (dur) obj = obj.add(moment.duration(dur));
  return obj;
};

export const getDuration = (from: TimeConvertable, to: TimeConvertable) => {
  const fromMoment =
    from instanceof moment ? (from as Moment) : moment(from ?? undefined);
  const toMoment =
    to instanceof moment ? (to as Moment) : moment(to ?? undefined);
  return moment.duration(fromMoment.diff(toMoment, "s"), "s");
};

export const getDateStringWithSuffix = (isoStr: string) => {
  const dt = DateTime.fromISO(isoStr);
  const day = dt.day;
  return `${dt.monthLong} ${day}${getNumberSuffix(day)}, ${dt.year}`;
};

export const useCurrentTime = (interval = 1000) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const intervalID = setInterval(() => setCurrentTime(new Date()), interval);
    return () => {
      clearInterval(intervalID);
    };
  }, [interval]);

  return currentTime;
};

export const timezoneName = (date: TimeConvertable, zoneId: string): string => {
  const zoneObj = moment.tz(date ?? Date.now(), zoneId);
  const abbr: string = zoneObj.zoneAbbr();
  if (Number.isNaN(Number.parseInt(abbr))) return zoneObj.format("z");
  return zoneObj.format("z");
};

export const timezoneShort = (
  date?: TimeConvertable | null,
  zoneId?: string | null
) => {
  if (!date || !zoneId) return "";
  const zoneObj = moment.tz(date ?? Date.now(), zoneId);
  const abbr: string = zoneObj.zoneAbbr();
  if (Number.isNaN(Number.parseInt(abbr))) return abbr;
  return zoneObj.format("z");
};

export const getDefaultTimezone = memoize(() => moment.tz.guess());

export function hasElapsed(isoTime?: string | null) {
  if (!isoTime) return false;
  return DateTime.fromISO(isoTime) < DateTime.local();
}

export function isWithinHours(
  isoTime: string | null | undefined,
  hours: number,
  abs?: boolean
) {
  if (!isoTime) return false;
  let diff = DateTime.fromISO(isoTime).diffNow().shiftTo("hours").hours;
  if (abs) diff = Math.abs(diff);
  return diff < hours;
}

export function isWithinTwentyFourHours(isoTime?: string | null) {
  return isWithinHours(isoTime, 24, true);
}

export function daysFromNow(isoTime?: string | null) {
  if (!isoTime) return -1;
  return Math.floor(DateTime.fromISO(isoTime).diffNow().shiftTo("days").days);
}

export function getShiftTime(
  isoStartsAt?: string | null,
  isoEndsAt?: string | null
) {
  const startsAt = moment(isoStartsAt);
  const endsAt = moment(isoEndsAt);
  return `${startsAt.format("MMM D")} from ${startsAt.format(
    "hh:mm:a"
  )} - ${endsAt.format("h:mma zz")}`;
}
