import React, { useState } from "react";
import {
  type NativeSyntheticEvent,
  Platform,
  type TextInputKeyPressEventData,
  View
} from "react-native";
import ContentArea from "../atoms/ContentArea";
import IconCircle from "../atoms/IconCircle";
import Stack from "../atoms/Stack";
import { ActionSheet, TextInput } from "../molecules";
import Column from "../quarks/Column";
import Pressable from "../quarks/Pressable";
import Spacer from "../quarks/Spacer";
import { useStyles } from "../style/styles";
import { showDocumentPicker } from "./FilePicker/DocumentPicker";
import FilePreview from "./FilePreview";
import { showHyperLinkModal } from "./HyperlinkInput";
import {
  type FileItem,
  type MediaPickerFile,
  showMediaPicker
} from "./MediaPicker";

interface Props {
  testID: string;
  messageText: string;
  onChangeMessageText: (messageText: string) => void;
  attachments?: FileItem[];
  onSelectAttachment: (val: MediaPickerFile[]) => void;
  onRemoveAttachment: (id: string) => void;
  onSubmit: () => void;
  disableSubmitButton?: boolean;
}

const IS_TESTING =
  process.env.IS_TESTING === "true" || process.env.CONFIG_ENV === "e2e";

export default function MessageComposer({
  testID,
  messageText,
  onChangeMessageText,
  attachments,
  onSelectAttachment,
  onRemoveAttachment,
  onSubmit,
  disableSubmitButton
}: Props) {
  const [showMessageOptions, setShowMessageOptions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({
    start: 0,
    end: 0
  });

  const styles = useStyles(({ getColor, getUnits }) => ({
    attachmentsContainer: {
      width: "100%",
      backgroundColor: getColor("neutral", "fill", { opacity: 0.08 }),
      borderRadius: getUnits(7)
    }
  }));

  const handleSelectAttachment = async () => {
    setShowMessageOptions(false);
    await showDocumentPicker({
      max: 10,
      onSelect: onSelectAttachment
    });
  };

  const handleSelectImage = async () => {
    if (Platform.OS === "web") {
      return handleSelectAttachment();
    }

    showMediaPicker({
      multiple: true,
      max: 10,
      onSelect: (uris) => {
        const files = uris?.map((uri) => ({
          uri,
          type: "image/jpeg",
          id: uri ?? ""
        }));
        if (files) onSelectAttachment(files);
        setShowMessageOptions(false);
      }
    });
  };

  const handleSelectHyperlink = () => {
    showHyperLinkModal({
      testID: "message-composer-hyperlink",
      onSave: (val) => {
        const { start, end } = cursorPosition;
        const newValue: string =
          messageText.slice(0, start) + val + messageText.slice(end);
        onChangeMessageText(newValue);
      }
    });
    setShowMessageOptions(false);
  };

  const submitButton = (
    <Pressable
      testID={`${testID}-send-message-button`}
      eventEntityType="Pressable"
      eventTargetName="SendMessageButton"
      onPress={onSubmit}
      disabled={disableSubmitButton}
    >
      <IconCircle icon="arrow-up" size="small" color="primary" />
    </Pressable>
  );

  const handleKeyPress =
    Platform.OS === "web"
      ? (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
          // @ts-expect-error: event definition is wrong
          if (e.nativeEvent.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSubmit();
          }
        }
      : undefined;

  return (
    <Stack horizontal size="medium">
      <Column justifyContent="center">
        <ActionSheet
          visible={showMessageOptions}
          onClose={() => setShowMessageOptions(false)}
          position="left"
          orientation="upwards"
          size="large"
          options={[
            {
              icon: "camera",
              label: "Take/Upload Photo",
              testID: `${testID}-message-option-upload-photo`,
              disabled: (attachments ?? [])?.length >= 10,
              onPress: handleSelectImage
            },
            {
              icon: "paperclip",
              label: "Attach a File",
              disabled: (attachments ?? [])?.length >= 10,
              testID: `${testID}-message-option-attach-file`,
              onPress: handleSelectAttachment
            },
            {
              icon: "link",
              label: "Add Hyperlink",
              testID: `${testID}-message-option-add-hyperlink`,
              onPress: handleSelectHyperlink
            }
          ]}
        >
          <Pressable
            testID={`${testID}-show-message-options-icon`}
            eventEntityType="Pressable"
            eventTargetName="ShowMessageOptionsIcon"
            disabled={disableSubmitButton}
            onPress={() => setShowMessageOptions(true)}
          >
            <IconCircle icon="plus" color="primary" size="small" />
          </Pressable>
        </ActionSheet>
      </Column>
      {!!attachments?.length && (
        <Stack horizontal justifyContent="space-between" fill>
          <View
            style={styles.attachmentsContainer}
            testID={`${testID}-attachments`}
          >
            <ContentArea size="none" justifyContent="flex-start">
              <Stack
                horizontal
                alignItems="center"
                justifyContent="space-between"
              >
                <FilePreview
                  selectedItems={attachments}
                  onRemove={onRemoveAttachment}
                />
                {submitButton}
              </Stack>
            </ContentArea>
          </View>
          <Spacer />
        </Stack>
      )}
      {!attachments?.length && (
        <TextInput
          testID={testID}
          fill
          variant="pill"
          placeholder="Type Message..."
          value={messageText}
          onChangeText={onChangeMessageText}
          autoCapitalize="sentences"
          maxLength={1024}
          autoCorrect
          enablesReturnKeyAutomatically
          autoFocus={Platform.OS !== "ios" && !IS_TESTING}
          accessible
          accessibilityLabel="Type Message..."
          onKeyPress={handleKeyPress}
          multiline
          numberOfLines={Math.round(messageText.length / 128) || 1}
          rightAccessory={messageText && submitButton}
          onSelectionChange={(e) => setCursorPosition(e.nativeEvent.selection)}
        />
      )}
    </Stack>
  );
}
