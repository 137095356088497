import React, { type ReactNode } from "react";
import Constraint from "../atoms/Constraint";
import ContentArea from "../atoms/ContentArea";
import IconText from "../atoms/IconText";
import Stack from "../atoms/Stack";
import Surface from "../atoms/Surface";
import SearchInput from "../molecules/SearchInput";
import type { IconName } from "../quarks/Icon";
import Text from "../quarks/Text";
import type { ColorProp } from "../style/theme/colors";

interface Props {
  header: string;
  label?: string;
  icon?: IconName;
  iconColor?: ColorProp;
  searchTerm: string;
  onChange: (val: string) => void;
  placeholder?: string;
  right?: ReactNode;
}

export default function SearchableListHeader({
  header,
  label,
  icon,
  iconColor,
  searchTerm,
  onChange,
  placeholder,
  right
}: Props) {
  return (
    <Constraint size="small">
      <Surface color="background">
        <Surface>
          <ContentArea>
            <Stack size="compact">
              <Text color="primary" weight="bold">
                {header}
              </Text>
              {icon && (
                <IconText
                  icon={icon}
                  color={iconColor}
                  size="tiny"
                  textWeight="bold"
                >
                  {label}
                </IconText>
              )}
            </Stack>
          </ContentArea>
        </Surface>
        <ContentArea size="medium">
          <Stack horizontal>
            <SearchInput
              fill
              testID="search"
              placeholder={placeholder}
              value={searchTerm}
              onChangeText={onChange}
            />
            {right && right}
          </Stack>
        </ContentArea>
      </Surface>
    </Constraint>
  );
}
