import { Instrument } from "@gigsmart/pickle/support/utils/instrumentation-client";
import { compact, uniq } from "lodash";
import React, {
  useCallback,
  useMemo,
  useState,
  type ComponentProps
} from "react";
import Select, {
  components,
  type OptionProps,
  type SingleValue
} from "react-select";
import InputLabel from "../atoms/InputLabel";
import InputNote from "../atoms/InputNote";
import Stack from "../atoms/Stack";
import type { TextInput } from "../molecules";
import { Column, Icon } from "../quarks";
import { useStyles } from "../style";

type PickerOption = { label: string | undefined; value: string | undefined };

interface Props
  extends Omit<
    ComponentProps<typeof TextInput>,
    "onChange" | "onChangeText" | "value"
  > {
  value?: string | null;
  options: PickerOption[] | string[];
  onChange: (value: string | null) => void;
  placeholder?: string;
  androidPrompt?: string | null;
  disabled?: boolean;
}

const Option = (
  props: OptionProps<{ label?: string | undefined; value: string | undefined }>
) => {
  return (
    <Column testID={(props as any).value}>
      <components.Option {...props} />
    </Column>
  );
};

export default function Picker({
  options,
  placeholder = "Pick One",
  disabled,
  onChange,
  label,
  horizontal,
  labelAccessory,
  labelIcon,
  note,
  showErrorMessage = true,
  pointerEvents,
  fill,
  value,
  testID,
  errors = []
}: Props) {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const styles = useStyles(({ getUnits, getColor }) => ({
    label: {
      marginHorizontal: getUnits(2),
      marginBottom: getUnits(1)
    },
    menuPortal: { zIndex: 10001 },
    menu: { zIndex: 10002 },
    defaultFont: {
      fontFamily: "Proxima Nova",
      fontSize: getUnits(3.5),
      cursor: "pointer"
    },
    picker: {
      backgroundColor: getColor("input", "fill"),
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomColor: getColor("#bdc6cf", "fill"),
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      boxShadow: "none",
      fontFamily: "Proxima Nova",
      minHeight: 29,
      fontSize: 14,
      height: 44,
      cursor: "pointer",
      "&:hover": {
        borderBottomColor: getColor("#bdc6cf", "fill")
      }
    },
    labelError: {
      color: getColor("danger", "fill")
    },
    validationError: {
      marginLeft: getUnits(2),
      marginTop: getUnits(1)
    }
  }));

  const normalizedOptions = useMemo(
    () =>
      (options ?? []).map((value) =>
        typeof value === "string" ? { label: value, value } : value
      ),
    [options]
  );

  const selectedOption = useMemo(
    () => normalizedOptions.find((opt) => opt.value === value),
    [normalizedOptions, value]
  );

  const handleChange = useCallback(
    (opt: SingleValue<Partial<PickerOption>> | string | null) => {
      const newOpt =
        typeof opt === "string"
          ? normalizedOptions.find((it) => it.label === opt)?.value
          : opt?.value;

      onChange(newOpt ?? null);
      handleClose();
    },
    [handleClose, normalizedOptions, onChange]
  );

  const errorMessage = Array.isArray(errors)
    ? uniq(compact(errors.map(formatError))).join(", ")
    : formatError(errors);

  const hasError = !!errorMessage;
  const helperText = hasError && showErrorMessage ? errorMessage : note;

  return (
    <Stack
      size="slim"
      pointerEvents={pointerEvents}
      fill={fill}
      {...(horizontal && { horizontal: true, alignItems: "flex-start" })}
    >
      {!!label && (
        <InputLabel
          testID={`${testID}-label`}
          label={label}
          icon={labelIcon}
          error={hasError}
          horizontal={horizontal}
          accessory={labelAccessory}
        />
      )}
      <Column testID={testID}>
        <Select<{ label?: string | undefined; value?: string | undefined }>
          menuPortalTarget={document.body}
          aria-label={testID}
          aria-readonly="false"
          onChange={handleChange}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <Icon
                  name={
                    /* eslint-disable react/prop-types */
                    props.selectProps.menuIsOpen ? "caret-up" : "caret-down"
                  }
                  variant="solid"
                  color="primary"
                  size="small"
                />
              </components.DropdownIndicator>
            ),
            Option: Option as any
          }}
          styles={{
            menuPortal: (baseStyles) => ({
              ...baseStyles,
              ...styles.menuPortal
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              ...styles.picker
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              ...styles.defaultFont
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              ...styles.menu
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              ...styles.defaultFont
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: "none"
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              ...styles.defaultFont
            })
          }}
          options={normalizedOptions}
          placeholder={placeholder}
          isDisabled={disabled}
          isSearchable={false}
          value={selectedOption}
          menuIsOpen={open}
          onMenuClose={handleClose}
          onMenuOpen={handleOpen}
        />
      </Column>
      {!!helperText && <InputNote error={hasError} note={helperText} />}

      {open && (
        <Instrument
          command="pickValue"
          target={testID}
          handler={handleChange}
        />
      )}
    </Stack>
  );
}

function formatError(error?: Error | string | null) {
  if (error) return typeof error === "string" ? error : error.message;
}
