import React, { type ReactComponentElement } from "react";
import { FlatList } from "react-native";

import type GalleryImageTile from "../atoms/GalleryImageTile";
import Column from "../quarks/Column";
import { useStyle } from "../style";

interface Props {
  imageTiles: Array<ReactComponentElement<typeof GalleryImageTile>>;
}

export default function GalleryImageTileContainer({ imageTiles }: Props) {
  const ImageTileWrapper = useStyle(({ getUnits }) => ({
    paddingVertical: getUnits(1),
    paddingRight: getUnits(4)
  }));

  if (!imageTiles.length) return null;

  return (
    <FlatList
      horizontal
      data={imageTiles}
      renderItem={({ item, index }) => (
        <Column
          style={ImageTileWrapper}
          key={`${index}`}
          testID={`documentTile-wrapper-${index}`}
        >
          {item}
        </Column>
      )}
    />
  );
}
