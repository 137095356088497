import React, { useRef, useState } from "react";
import { ScrollView } from "react-native";
import Button from "../atoms/Button";
import ContentArea from "../atoms/ContentArea";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useStyle } from "../style";

export type FilterTabOption = { label: string; count?: number };

interface Props {
  tabs: FilterTabOption[];
  onChange: (index: number) => void;
  value: number;
  contentAreaVariant?: "standard" | "compact";
  fill?: boolean;
}

export default function FilterTabs({
  tabs,
  onChange,
  contentAreaVariant = "standard",
  value,
  fill
}: Props) {
  const style = useStyle(({ getUnits, getColor }) => ({
    backgroundColor: getColor("primary", "fill"),
    borderRadius: getUnits(1),
    paddingHorizontal: getUnits(1),
    marginRight: getUnits(2),
    height: 18,
    paddingTop: 1
  }));
  const widthRef = useRef(0);
  const contentWidthRef = useRef(0);
  const [showSwipeTag, setShowSwipeTag] = useState(false);

  const getCountNode = (count: number | undefined) => {
    if (!count && count !== 0) return null;
    return (
      <Row style={style} justifyContent="center" alignItems="center">
        <Text weight="bold" color="surface">
          {count.toLocaleString()}
        </Text>
      </Row>
    );
  };

  const handleWidthChange = () => {
    if (widthRef.current && contentWidthRef.current) {
      const newValue = widthRef.current < contentWidthRef.current;
      if (newValue !== showSwipeTag) setShowSwipeTag(true);
    }
  };

  return (
    <Column fill={fill}>
      <Spacer size="compact" />
      {showSwipeTag && (
        <Row justifyContent="flex-end">
          <Text variant="note" color="neutral">
            Swipe to View More {">"}
          </Text>
          <Spacer horizontal />
        </Row>
      )}
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        onLayout={({ nativeEvent }) => {
          if (!nativeEvent) return;
          widthRef.current = nativeEvent.layout.width;
          handleWidthChange();
        }}
        onContentSizeChange={(width) => {
          contentWidthRef.current = width;
          handleWidthChange();
        }}
      >
        <ContentArea size="compact" variant={contentAreaVariant}>
          <Stack horizontal size="compact">
            {tabs?.map((tab, index) => (
              <Button
                key={`filter-tab-${tab.label}`}
                testID={`filter-tab-${tab.label}`}
                label={tab.label}
                outline={index !== value}
                size="small"
                onPress={() => onChange(index)}
                leftAccessory={getCountNode(tab.count)}
              />
            ))}
          </Stack>
        </ContentArea>
      </ScrollView>
    </Column>
  );
}
