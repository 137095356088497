import React, { type ComponentProps, type Ref } from "react";
import type { FlatListProps } from "react-native";
import { FlatList, View } from "react-native";
import { IsConstrainedProvider } from "../atoms";
import { useStyles } from "../style";
import type Grid from "./Grid";
import { useGridStyles } from "./Grid";

export type GridListProps<T> = ComponentProps<typeof Grid> &
  Omit<
    FlatListProps<T>,
    | "contentContainerStyle"
    | "data"
    | "keyExtractor"
    | "renderItem"
    | "ListHeaderComponent"
    | "ListFooterComponent"
    | "ListEmptyComponent"
    | "numColumns"
  > & {
    data: T[] | readonly T[] | null;
    header?: JSX.Element | null;
    footer?: JSX.Element | null;
    emptyContent?: JSX.Element | null;
    renderItem: (item: T) => JSX.Element | null;
    keyExtractor: (item: T, index: number) => string;
    grow?: boolean;
    footerFill?: boolean;
    flatListRef?: Ref<FlatList>;
  };

export default function GridList<T>({
  data,
  renderItem,
  spacing = "medium",
  keyExtractor,
  numColumns = { medium: 2, xlarge: 3, xxlarge: 4 },
  size,
  variant,
  constraint,
  emptyContent,
  header,
  footer,
  grow = true,
  footerFill,
  flatListRef,
  ...props
}: GridListProps<T>) {
  const { contentStyle, gridStyles, columns } = useGridStyles(
    { spacing, numColumns, constraint, size, variant },
    false
  );
  const styles = useStyles(
    () => ({
      container: { flex: 1 },
      grow: { flexGrow: 1 },
      footer: { marginTop: "auto", flex: footerFill ? 1 : undefined }
    }),
    []
  );
  return (
    <IsConstrainedProvider value={spacing !== "none"}>
      <FlatList
        ref={flatListRef}
        // Changing numColumns on the fly is not supported
        key={`cols_${columns}`}
        data={data}
        style={styles.container}
        contentContainerStyle={[contentStyle, grow && styles.grow]}
        numColumns={columns}
        keyExtractor={keyExtractor}
        ListFooterComponentStyle={styles.footer}
        ListEmptyComponent={emptyContent}
        ListHeaderComponent={header}
        ListFooterComponent={footer}
        columnWrapperStyle={columns > 1 && gridStyles.itemWrapper}
        renderItem={({ item }) => (
          <View style={gridStyles.item}>{renderItem?.(item)}</View>
        )}
        {...props}
      />
    </IsConstrainedProvider>
  );
}
