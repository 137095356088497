import React from "react";
import { ListRow, Stack } from "../atoms";
import { Switch } from "../molecules";
import { Icon, type IconName, Text } from "../quarks";
import InfoElement from "./InfoElement";

interface Props {
  testID: string;
  label?: string | JSX.Element | null;
  description?: string | JSX.Element | null;
  right?: JSX.Element | null;
  left?: JSX.Element | null;
  onInfo?: () => void;
  icon?: IconName;
  disabled?: boolean;

  // TODO: make it more reusable removing these props
  // Switch props
  locked?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export default function SettingsSwitch({
  label,
  description,
  checked,
  icon,
  right,
  left,
  testID,
  disabled,
  onChange,
  onInfo,
  locked
}: Props) {
  if (typeof label === "string") label = <Text weight="bold">{label}</Text>;
  if (typeof description === "string") {
    description = (
      <Text variant="note" color="neutral">
        {description}
      </Text>
    );
  }
  if (onInfo) {
    label = (
      <Stack horizontal size="compact">
        {label}
        <InfoElement
          testID={`${testID}-info-icon`}
          variant="icon"
          openModal={onInfo}
        />
      </Stack>
    );
  }

  right ??= disabled ? null : (
    <Switch
      testID={`${testID}-switch`}
      toggled={checked}
      onChange={onChange}
      locked={locked}
    />
  );
  left ??= icon ? (
    <Icon
      name={icon}
      align="center"
      size="small"
      color="primary"
      variant="solid"
    />
  ) : undefined;

  return (
    <ListRow
      leftSpacing="large"
      rightSpacing="medium"
      spacing="medium"
      label={label}
      left={left}
      right={right}
      testID={`${testID}-container`}
    >
      {description}
    </ListRow>
  );
}
