import React from "react";
import { Platform, type StyleProp, type TextStyle } from "react-native";
import { createIconSet } from "react-native-vector-icons";
import type {
  FontAwesome6IconProps,
  FontAwesome6IconVariants
} from "react-native-vector-icons/FontAwesome6";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome6Pro";
import glyphmap from "react-native-vector-icons/glyphmaps/FontAwesome6Pro.json";
import meta from "react-native-vector-icons/glyphmaps/FontAwesome6Pro_meta.json";
import AscenIcon from "../atoms/AscenIcon";
import GigSmartIcon from "../atoms/GigSmartIcon";
import OnfleetIcon from "../atoms/OnfleetIcon";
import { usePlacementColor, useStyle, useTheme } from "../style";
import type { Color } from "../style/theme/colors";

export type IconName = keyof typeof glyphmap | "gigsmart" | "onfleet" | "ascen";

export type IconVariant = "light" | "solid" | "regular";

export type IconSize = keyof typeof specs.size;

const specs = {
  size: {
    xtiny: 2,
    tiny: 3,
    small: 3.5,
    medium: 4,
    large: 5,
    default: 6,
    extraLarge: 11,
    huge: 15
  },
  minWidth: {
    xtiny: 14,
    tiny: 18,
    small: 20,
    medium: 24,
    large: 24,
    default: 24,
    extraLarge: 55,
    huge: 75
  }
};

export const BrandIcon = createIconSet(
  glyphmap,
  "FontAwesome6Brands-Regular",
  "FontAwesome6_Pro_Brands.ttf"
);

const brands = new Set<string>(meta.brands);

export const iconNames: IconName[] = [
  ...Object.keys(glyphmap),
  "gigsmart",
  "onfleet",
  "ascen"
] as IconName[];
export interface Props {
  testID?: string;
  name: IconName;
  color?: Color;
  size?: IconSize;
  align?: TextStyle["textAlign"];
  variant?: IconVariant;
  style?: StyleProp<TextStyle>;
  hitSlot?: number;
}

export default function Icon({
  name,
  size = "large",
  variant = "light",
  align = "left",
  color,
  style,
  testID = "icon",
  ...rest
}: Props) {
  const isBrand = brands.has(name);
  const theme = useTheme();
  const { color: placementColor } = usePlacementColor("title");
  const defaultStyle = useStyle(
    ({ getColor, getFontSize }) => ({
      ...getFontSize(specs.size[size]),
      color:
        getColor(color, "fill") ??
        placementColor ??
        getColor("background", "placement"),
      minWidth: align !== "left" ? specs.minWidth[size] : 0,
      textAlign: align
    }),
    [color, size, align, placementColor]
  );

  let IconComponent = FontAwesomeIcon;
  let variantProps: Partial<FontAwesome6IconProps | FontAwesome6IconVariants> =
    {};

  if (Platform.OS !== "ios" && isBrand) {
    IconComponent = BrandIcon;
    variantProps = {};
  } else {
    variantProps = {
      [isBrand ? "brand" : variant]: true
    };
  }

  if (name === "gigsmart") {
    return (
      <GigSmartIcon
        size={theme.getUnits(specs.size[size])}
        color={color}
        {...rest}
      />
    );
  }

  if (name === "onfleet") {
    return (
      <OnfleetIcon
        size={theme.getUnits(specs.size[size])}
        color={color}
        {...rest}
      />
    );
  }

  if (name === "ascen") {
    return (
      <AscenIcon
        size={theme.getUnits(specs.size[size])}
        color={color}
        {...rest}
      />
    );
  }

  return (
    <IconComponent
      name={name}
      testID={testID}
      selectable={false}
      style={[defaultStyle, style]}
      {...variantProps}
      {...rest}
    />
  );
}
