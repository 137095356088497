import { type ValueObject, useFormSubmit, useFormValues } from "@gigsmart/fomu";
import React, { type ReactNode, type ComponentProps } from "react";
import { Button } from "../../atoms";
import { StepFooter } from "./StepFooter";

type Props = Omit<ComponentProps<typeof Button>, "onPress" | "label"> & {
  allowDirty?: boolean;
  showLoading?: boolean;
  label: string | ((values?: ValueObject) => string);
  actions?: ReactNode;
  footerVariant?: "full" | "inline";
};

export function StepSubmit({
  footerVariant,
  disabled,
  allowDirty,
  showLoading,
  label,
  actions,
  ...rest
}: Props) {
  const { invalid, submit, submitting, dirty } = useFormSubmit();
  const { values } = useFormValues();
  let isDisabled = disabled || invalid || submitting;
  if (!allowDirty) isDisabled ||= !dirty;

  return (
    <StepFooter variant={footerVariant}>
      {actions}
      <Button
        {...rest}
        minWidth={200}
        onPress={submit}
        disabled={isDisabled}
        loading={showLoading && submitting}
        label={typeof label === "function" ? label(values) : label}
      />
    </StepFooter>
  );
}
