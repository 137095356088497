import { type JsonMap, useEventer } from "@gigsmart/dekigoto";
import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React, { type ReactNode } from "react";
import { TouchableOpacity } from "react-native";

type KatanaTouchableOpacityProps = ComponentPropsWithDefaults<
  typeof TouchableOpacity
>;

type Props = KatanaTouchableOpacityProps & {
  eventTargetName: string | null;
  eventEntityType?: string;
  eventProperties?: JsonMap;
  testID?: string;
  children?: ReactNode;
};

export default function KatanaTouchableOpacity({
  onPress,
  testID,
  eventTargetName,
  eventEntityType,
  eventProperties,
  ...props
}: Props) {
  const trackPress = useEventer(
    "Pressed",
    eventTargetName,
    eventEntityType ?? "Button"
  );
  const handlePress = (...args: Parameters<NonNullable<typeof onPress>>) => {
    trackPress(eventProperties);
    onPress?.(...args);
  };

  return <TouchableOpacity onPress={handlePress} testID={testID} {...props} />;
}
