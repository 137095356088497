import { isEqual } from "lodash";
import type { DateTime } from "luxon";
import type { FlagSpec } from "./registry";

export interface User {
  emailAddress: string;
  userType: string;
  insertedAt: DateTime;
  organizationId: string;
}

let user: User | null;

export function getFeatureUser(): Partial<User> {
  return user ?? {};
}

export function setFeatureUser(nextUser: User | null) {
  if (isEqual(user, nextUser)) return;
  user = nextUser;
}

export function anyEnabled(flags: FlagSpec[]) {
  return flags.some((flag) => flag.isEnabled());
}

export function allEnabled(flags: FlagSpec[]) {
  return flags.every((flag) => flag.isEnabled());
}
