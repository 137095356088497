import * as Immutable from "immutable";
import React, { useState, type ReactElement } from "react";
import { errorsEqual } from "./helpers";
import { useActor, useInit, useResponder } from "./scene";

export interface FormValueOptions {
  name: string;
}

export interface FormValueArgs<T> {
  value: T | null | undefined;
  valid: boolean;
  errors: Error[] | null | undefined;
  name: string;
}

export function useClearFormValue(name: string) {
  const setValue = useActor(
    "values",
    (nextValue, prevState) => ({
      ...prevState,
      values: prevState.values.set(name, "")
    }),
    [name]
  );

  return setValue;
}

export function useFormValue<T>({ name }: FormValueOptions): FormValueArgs<T> {
  const [value, setInternalValue] = useState<T | null | undefined>(null);
  const [internalErrors, setInternalErrors] = useState<
    Error[] | null | undefined
  >(null);

  useInit(
    ({ values, errors }) => {
      setInternalValue(values.get(name));
      setInternalErrors(errors.get(name));
    },
    [name]
  );

  useResponder(
    "values",
    ({ values: allValues }) => {
      const nextValue = allValues.get(name);
      if (!allValues.has(name)) {
        setInternalValue(undefined);
      } else if (!Immutable.is(value, nextValue)) {
        setInternalValue(nextValue);
      }
    },
    [name]
  );

  useResponder(
    "errors",
    ({ errors: allErrors }) => {
      const nextErrors = allErrors.get(name);
      if (!errorsEqual(internalErrors, nextErrors)) {
        setInternalErrors(nextErrors);
      }
    },
    [name]
  );

  const valid =
    internalErrors === undefined ||
    internalErrors === null ||
    internalErrors.length === 0;

  return {
    name,
    value,
    valid,
    errors: internalErrors
  };
}

type Props = FormValueOptions & {
  children: (arg0: FormValueArgs<any>) => ReactElement | null;
};

export const FormValue = React.memo<Props>(function FormValue({
  children,
  ...formFieldOptions
}: Props) {
  const props = useFormValue(formFieldOptions);
  return children(props);
});
