import React, { type ReactNode } from "react";
import { Divider, Row, Text } from "../quarks";
import ContentArea from "./ContentArea";

interface Props {
  label: string | JSX.Element;
  right?: ReactNode;
  divider?: boolean;
}

export default function ListHeader({ label, right, divider }: Props) {
  return (
    <>
      <ContentArea size="compact" color="divider">
        <Row alignItems="center" gap="standard">
          <Text weight="bold" color="neutral" fill>
            {label}
          </Text>
          {right}
        </Row>
      </ContentArea>
      {divider && <Divider />}
    </>
  );
}
