import React from "react";
import { Row } from "../../quarks";
import { typedMemo } from "../../utils";
import DayCell from "./DayCell";
import {
  type DayRenderer,
  type EventMap,
  type WeeklyView,
  renderWithDivider
} from "./helpers";

interface Props<T> {
  week: WeeklyView;
  events?: EventMap<T> | null;
  displayDayIndicator?: boolean;
  grow?: boolean;
  fill?: boolean;
  renderDay?: DayRenderer<T>;
}

export default typedMemo(
  function WeekRow<T>({
    week,
    events,
    renderDay,
    displayDayIndicator,
    ...rest
  }: Props<T>) {
    return (
      <Row {...rest}>
        {renderWithDivider(week, "y", (it) => {
          const dayEvents = events?.get(it.key);
          return (
            <DayCell
              fill
              testID={`cal-${it.key}`}
              day={it}
              displayDayIndicator={displayDayIndicator}
            >
              {!!dayEvents && renderDay?.(it, dayEvents)}
            </DayCell>
          );
        })}
      </Row>
    );
  },
  (p1, p2) => p1.events === p2.events && p1.week?.[0]?.key === p2.week?.[0]?.key
);
