import React from "react";
import Text from "../quarks/Text";
import { useStyle } from "../style";

interface Props {
  note?: string;
  testID?: string;
  error?: boolean;
  align?: "right" | "left";
}

const InputNote = ({ note, align, testID, error }: Props) => {
  const noteStyle = useStyle(({ getUnits }) => ({
    marginHorizontal: getUnits(2)
  }));

  return (
    <Text
      style={noteStyle}
      variant="note"
      testID={testID ?? "validator-error"}
      align={align}
      color={error ? "danger" : "neutral"}
    >
      {note}
    </Text>
  );
};

export default InputNote;
