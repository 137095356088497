import { isEqual } from "lodash";
/**
 * index.tsx
 * This module provides utility functions and hooks for handling permissions.
 */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  addPermissionListener,
  checkPermission,
  getRawPermissionStatus,
  requestPermission
} from "./commands";
import { logger as baseLogger, hasPermissionFromStatus } from "./helpers";
import type { Permissions } from "./implementation";
import type {
  DetailedPermissionResponse,
  PermissionListener,
  PermissionRequestOptions
} from "./registry";

export type { PermissionRequestOptions };

const Context = createContext<boolean>(true);

const hooksLogger = baseLogger.createLogger("!🪝", "hooks");

export const { Provider: AllowPermissionInvocationProvider } = Context;
const useAllowPermissionInvocation = (trace: string | undefined) => {
  const value = useContext(Context);
  hooksLogger.useDidChange(
    `useAllowPermissionInvocation${trace ? ` (via: ${trace})` : ""}`,
    value
  );
  return value;
};

/**
 * React hook that returns a callback function which, when called, requests a specific permission.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to request.
 * @param {Partial<Permissions[K]["requestOptions"]>} options - An object containing options for the permission request.
 *
 * @returns {Function} - Returns a callback function that requests the permission when called.
 */
export const useRequestPermission = function useRequestPermission<
  K extends keyof Permissions
>(
  permission: K,
  {
    reason,
    required,
    preview,
    allowLimited,
    onDismiss,
    forceAsk,
    trace
  }: Partial<PermissionRequestOptions> = {}
) {
  const logger = useMemo(
    () =>
      trace
        ? hooksLogger.createLogger(permission, `!(via: ${trace})`)
        : hooksLogger.createLogger(permission),
    [permission]
  );
  const allowInvocation = useAllowPermissionInvocation(trace);
  return useCallback(
    logger.wrap(
      (invokeOptions?: Partial<PermissionRequestOptions>) =>
        allowInvocation
          ? requestPermission(permission, {
              reason,
              required,
              preview,
              allowLimited,
              onDismiss,
              forceAsk,
              trace: trace && `${trace}.useCheckPermission`,
              ...invokeOptions
            })
          : getRawPermissionStatus(
              permission,
              trace && `${trace}.useCheckPermission`
            ),
      `useRequestPermission (allowed: ${allowInvocation})`
    ),
    [
      permission,
      required,
      preview,
      allowLimited,
      onDismiss,
      forceAsk,
      allowInvocation,
      trace
    ]
  );
};

/**
 * React hook that returns a callback function which, when called, checks a specific permission.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to check.
 * @param {Partial<Permissions[K]["checkOptions"]>} options - An object containing options for the permission check.
 *
 * @returns {Function} - Returns a callback function that checks the permission when called.
 */
export const useCheckPermission = function useCheckPermission<
  K extends keyof Permissions
>(
  permission: K,
  {
    reason,
    required,
    preview,
    allowLimited,
    onDismiss,
    trace
  }: Partial<PermissionRequestOptions> = {}
) {
  const logger = useMemo(
    () =>
      trace
        ? hooksLogger.createLogger(permission, `!(via: ${trace})`)
        : hooksLogger.createLogger(permission),
    [permission]
  );
  const allowInvocation = useAllowPermissionInvocation(trace);
  return useCallback(
    logger.wrap(
      (invokeOptions?: Partial<PermissionRequestOptions>) =>
        allowInvocation
          ? checkPermission(permission, {
              reason,
              required,
              preview,
              allowLimited,
              onDismiss,
              trace: trace && `${trace}.useCheckPermission`,
              ...invokeOptions
            })
          : getRawPermissionStatus(
              permission,
              trace && `${trace}.useCheckPermission`
            ),
      `useCheckPermission (allowed: ${allowInvocation})`
    ),
    [
      permission,
      required,
      preview,
      allowLimited,
      onDismiss,
      allowInvocation,
      trace
    ]
  );
};

export const usePermissionListener = function usePermissionListener<
  K extends keyof Permissions
>(
  permission: K,
  updatefn: PermissionListener,
  { invokeOnce, trace }: Parameters<typeof addPermissionListener<K>>[2] = {}
) {
  useEffect(() => {
    return addPermissionListener(permission, updatefn, {
      invokeOnce,
      trace: trace && `${trace}.useAddPermissionListener`
    }).remove;
  }, [permission, updatefn, invokeOnce, trace]);
};

/**
 * React hook that returns the status of a specific permission.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to check.
 * @returns {PermissionStatus} - Returns the status of the permission.
 */
export const usePermissionStatus = function usePermissionStatus<
  K extends keyof Permissions
>(permission: K, options: Partial<PermissionRequestOptions> = {}) {
  const logger = useMemo(
    () =>
      options.trace
        ? hooksLogger.createLogger(permission, `!(via: ${options.trace})`)
        : hooksLogger.createLogger(permission),
    [permission]
  );
  const [response, setResponse] = useState<DetailedPermissionResponse>({
    status: "denied"
  });
  const listener = useCallback<PermissionListener>(({ response }) => {
    setResponse((prevResponse) =>
      isEqual(prevResponse, response) ? prevResponse : response
    );
  }, []);
  logger.useDidChange("usePermissionStatus", response);
  usePermissionListener(permission, listener, {
    ...options,
    trace: options.trace && `${options.trace}.usePermissionStatus`
  });
  return response;
};

/**
 * React hook that returns a boolean indicating whether a specific permission has been granted.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to check.
 * @param {Partial<Permissions[K]["checkOptions"]>} options - An object containing options for the permission check.
 *
 * @returns {boolean} - Returns a boolean indicating whether the permission has been granted.
 */
export const useHasPermission = function useHasPermission<
  K extends keyof Permissions
>(
  permission: K,
  options: Partial<PermissionRequestOptions> & { request?: boolean } = {}
) {
  const logger = useMemo(
    () =>
      options.trace
        ? hooksLogger.createLogger(permission, `!(via: ${options.trace})`)
        : hooksLogger.createLogger(permission),
    [permission]
  );
  const has = usePermission(permission, {
    ...options,
    trace: options.trace && `${options.trace}.useHasPermission`
  }).has;
  logger.useDidChange("useHasPermission", has);
  return has;
};

/**
 * React hook that returns an object containing the status of a specific permission and a boolean indicating whether the permission has been granted.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to check.
 * @param {Partial<Permissions[K]["requestOptions"]> & { request: boolean }} options - An object containing options for the permission request and a boolean indicating whether to request the permission.
 *
 * @returns {Object} - Returns an object containing the status of the permission and a boolean indicating whether the permission has been granted.
 */
export const usePermission = function usePermission<
  K extends keyof Permissions
>(
  permission: K,
  options: Partial<PermissionRequestOptions> & {
    init?: "check" | "request" | "none";
  } = {}
) {
  const logger = useMemo(
    () =>
      options.trace
        ? hooksLogger.createLogger(
            permission,
            "usePermission",
            `!(via: ${options.trace})`
          )
        : hooksLogger.createLogger(permission, "usePermission"),
    [permission]
  );
  const { status, dismissed, delayed } = usePermissionStatus(permission, {
    ...options,
    trace: options.trace && `${options.trace}.usePermission`
  });
  const request = useRequestPermission(permission, {
    ...options,
    trace: options.trace && `${options.trace}.usePermission`
  });
  const check = useCheckPermission(permission, {
    ...options,
    trace: options.trace && `${options.trace}.usePermission`
  });

  useEffect(
    logger.wrap(() => {
      ({
        check,
        request,
        none: () => {}
      })[options?.init ?? "check"]();
    }, `useEffect({ init: ${options?.init} })`),
    [check, request, status, options?.init]
  );

  const has = hasPermissionFromStatus({ status });
  const determined = status !== "denied" || dismissed || delayed;

  const value = useMemo(
    () => ({
      has,
      status,
      request,
      check,
      determined,
      dismissed,
      delayed
    }),
    [has, status, request, check, determined]
  );

  logger.useDidChange("value", value);

  return value;
};
