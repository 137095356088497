import React, { useMemo } from "react";

import { ContentArea, Surface } from "../../atoms";
import ListEmpty from "../../molecules/ListEmpty";
import { FancyScroll } from "../../quarks";
import { typedMemo } from "../../utils";
import DayHeader from "./DayHeader";
import { type CalendarVariant, dayView } from "./helpers";

type Props<T> = CalendarVariant<T>;

export default typedMemo(
  function DailyCalendar<T>({ date, events, renderDay, loading }: Props<T>) {
    const view = useMemo(() => dayView(date), [date]);
    const dayEvents = events?.get(view.key);
    const showEmpty = !dayEvents?.length && !!events && !loading;

    return (
      <Surface variant="outline" fill>
        <DayHeader
          label={view.value.toFormat("EEEE")}
          color={view.isToday ? "primary" : "neutral"}
        />
        <FancyScroll testID="daily-calendar-scroll" fill>
          {dayEvents?.length ? (
            <ContentArea constraint="small">
              {renderDay?.(view, dayEvents)}
            </ContentArea>
          ) : showEmpty ? (
            <ContentArea size="large">
              <ListEmpty
                color="primary"
                variant="flat"
                icon="exclamation"
                subtitle="There are no Shifts to display."
              />
            </ContentArea>
          ) : null}
        </FancyScroll>
      </Surface>
    );
  },
  (p1, p2) => p1.events === p2.events && p1.date.day === p2.date.day
);
