import "graphiql/graphiql.css";
import { explorerPlugin } from "@graphiql/plugin-explorer";
import { ToolbarButton } from "@graphiql/react";
import GraphiQL from "graphiql";
import React, {
  useCallback,
  type ComponentProps,
  type FC,
  useState
} from "react";
import { useRequestContext } from "./request-context";

interface Props {
  buttons: Array<
    Omit<ComponentProps<typeof ToolbarButton>, "children"> & {
      IconComponent: FC<import("react").SVGProps<SVGSVGElement>>;
    }
  >;
}

const explorer = explorerPlugin({});

export default function IQL({ buttons }: Props) {
  const [query, setQuery] = useState<string>();
  const { fetcher } = useRequestContext();
  const fetcherWithQuery = useCallback<
    ComponentProps<typeof GraphiQL>["fetcher"]
  >(
    async ({ query, ...params }, options) => {
      const qpsUrl = process.env.QPS_URL;
      if (query.startsWith("qpid:") && qpsUrl) {
        const response = await fetch(`${qpsUrl}/${query}`);
        if (response.ok) query = await response.text();
        setQuery(query);
      }
      const response = await fetcher({ query, ...params }, options);
      return response;
    },
    [fetcher]
  );

  return (
    <div style={{ height: "100vh" }}>
      <GraphiQL
        query={query}
        fetcher={fetcherWithQuery}
        isHeadersEditorEnabled={false}
        plugins={[explorer]}
        inputValueDeprecation={true}
        toolbar={{
          additionalComponent: () => (
            <>
              {buttons.map(({ IconComponent, ...button }, i) => (
                <ToolbarButton key={i} {...button}>
                  <IconComponent
                    className="graphiql-toolbar-icon"
                    aria-hidden="true"
                  />
                </ToolbarButton>
              ))}
            </>
          )
        }}
      >
        <GraphiQL.Logo>GigSmart GraphQL API</GraphiQL.Logo>
      </GraphiQL>
    </div>
  );
}

IQL.defaultProps = {
  buttons: []
};
