import { useInstrument } from "@gigsmart/pickle/support/utils/instrumentation-client";
import React from "react";
import { InputAccessoryView, Platform, View } from "react-native";

import { Row } from "../quarks";
import { useStyles } from "../style";
import Button from "./Button";

export const getAccessoryID = (() => {
  let inc = 0;
  return () => {
    inc = inc + 1;
    return `styled-input-accessory-${inc}`;
  };
})();

interface Props {
  nativeID?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  attachedToKeyboard?: boolean;
  cancelText?: string;
  doneText?: string;
}
const InputAccessory = ({
  doneText = "Done",
  cancelText = "Cancel",
  attachedToKeyboard = true,
  nativeID,
  onCancel,
  onSubmit
}: Props) => {
  const styles = useStyles(({ getColor }) => ({
    container:
      Platform.OS === "ios" && attachedToKeyboard
        ? {
            backgroundColor: "#D5D7DD",
            borderTopColor: "#0001",
            borderTopWidth: 1
          }
        : { backgroundColor: getColor("background", "fill") }
  }));
  const handleSubmit = () => onSubmit?.();
  const handleCancel = () => onCancel?.();

  useInstrument("accessory:confirm", null, handleSubmit);
  useInstrument("accessory:cancel", null, handleCancel);

  const accessory = (
    <Row
      style={styles.container}
      justifyContent="space-between"
      alignItems="center"
    >
      {onCancel ? (
        <Button
          testID="keyboard-cancel"
          variant="clear"
          size="small"
          label={cancelText}
          onPress={handleCancel}
        />
      ) : (
        <View />
      )}
      <Button
        testID="input-done"
        variant="clear"
        onPress={handleSubmit}
        size="small"
        label={doneText}
      />
    </Row>
  );

  return attachedToKeyboard && nativeID ? (
    Platform.OS !== "ios" ? null : (
      <InputAccessoryView nativeID={nativeID}>{accessory}</InputAccessoryView>
    )
  ) : (
    accessory
  );
};

export default InputAccessory;
