import type {
  Action,
  Href,
  Location,
  LocationDescriptorObject,
  LocationState,
  Path,
  TransitionPromptHook,
  UnregisterCallback
} from "history";
import type { ContextRouter, LocationShape } from "./types";

export function formatUrl(location: Location<any>) {
  let url = location.pathname;
  if (location.search !== "") url = url + location.search;
  if (location.hash !== "") url = url + location.hash;
  return url;
}

export const transformParams =
  (fn: (arg0: any) => any = (p) => p) =>
  ({ match: { params } }: ContextRouter) =>
    fn(params || {});

export const routerStubs: ContextRouter<any, any, any, any> = {
  history: {
    createHref: (_location: LocationDescriptorObject): Href => "",
    action: "PUSH",
    block:
      (_prompt?: boolean | string | TransitionPromptHook): UnregisterCallback =>
      () =>
        undefined,
    go: (_n: number) => {},
    goBack: () => {},
    goForward: () => {},
    length: 0,
    listen: (_callback: (_location: Location, _action: Action) => void) => {
      return () => {};
    },
    location: {
      hash: "",
      pathname: "",
      search: "",
      state: undefined
    },
    push: (_path: Path | LocationShape, _state?: LocationState) => undefined,
    replace: (_path: Path | LocationShape, _state?: LocationState) => undefined
  },
  location: {
    hash: "",
    pathname: "",
    search: "",
    state: undefined
  },
  match: {
    isExact: false,
    params: {},
    path: "",
    url: ""
  },
  queryParams: {}
};
