import React, { type ComponentProps } from "react";
import Svg, { Path, G } from "react-native-svg";

const GoogleLogo = (props: ComponentProps<typeof Svg>) => (
  <Svg
    viewBox="0 0 48 49"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    {...props}
  >
    <G fillRule="nonzero">
      <Path
        d="M48 25.048c0-1.664-.135-3.338-.423-4.975H24.481v9.429h13.226a11.334 11.334 0 01-4.894 7.44v6.119h7.89C45.337 38.796 48 32.498 48 25.048z"
        fill="#4285f4"
      />
      <Path
        d="M24.481 48.972c6.604 0 12.174-2.169 16.231-5.911l-7.89-6.119c-2.196 1.494-5.03 2.34-8.332 2.34-6.388 0-11.804-4.31-13.747-10.104H2.6v6.307a24.49 24.49 0 0021.881 13.487z"
        fill="#34a853"
      />
      <Path
        d="M10.734 29.178a14.667 14.667 0 010-9.375v-6.307H2.6a24.507 24.507 0 000 21.989l8.134-6.307z"
        fill="#fbbc04"
      />
      <Path
        d="M24.481 9.69a13.306 13.306 0 019.393 3.67l6.991-6.99A23.533 23.533 0 0024.481 0a24.481 24.481 0 00-21.88 13.496l8.133 6.307C12.668 14 18.094 9.69 24.48 9.69z"
        fill="#ea4335"
      />
    </G>
  </Svg>
);

export default GoogleLogo;
