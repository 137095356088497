import React from "react";
import { Text } from "../quarks";
import { useMatchesViewport } from "../style";
import type { DateTimeConvertable, TimeSizes } from "./date-helpers";
import { humanizeTime } from "./date-helpers";

interface Props
  extends Omit<
    React.ComponentProps<typeof Text>,
    "onPress" | "eventTargetName"
  > {
  startsAt: DateTimeConvertable | null;
  actualStartsAt?: DateTimeConvertable | null;
  endsAt?: DateTimeConvertable | null;
  timezone?: string | null;
  size?: TimeSizes;
  showDayOfWeek?: boolean;
  showDuration?: boolean;
}

export default function Time({
  startsAt,
  endsAt,
  size: sizeOverride,
  timezone,
  actualStartsAt,
  showDayOfWeek,
  showDuration,
  ...textProps
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const isMd = useMatchesViewport((media) => media.size.small.down);
  const size = sizeOverride || (isSm ? "sm" : isMd ? "md" : "lg");
  return (
    <Text {...textProps}>
      {humanizeTime({
        startsAt,
        endsAt,
        timezone,
        actualStartsAt,
        showDayOfWeek,
        showDuration,
        size
      })}
    </Text>
  );
}
