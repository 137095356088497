import type {
  MiddlewareRaw,
  MiddlewareRawNextFn
} from "react-relay-network-modern/es";

export function createRawMiddleware(
  fn: (next: MiddlewareRawNextFn) => MiddlewareRawNextFn
): MiddlewareRaw {
  const f = fn as any;
  f.isRawMiddleware = true;
  return f as MiddlewareRaw;
}
