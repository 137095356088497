import React from "react";
import { Button } from "../atoms";
import showFilePicker from "./FilePicker";
import type { MediaPickerFile } from "./MediaPicker";

interface Props {
  onSelect: (item: MediaPickerFile[]) => void;
  acceptType?: "CSV" | "PDF" | "IMAGE";
  disabled?: boolean;
}

export default function FilePickerButton({
  onSelect,
  disabled,
  acceptType
}: Props) {
  return (
    <Button
      label="Upload Photos & Documents"
      size="small"
      variant="clear"
      outline
      disabled={disabled}
      icon="upload"
      testID="docs-uploader"
      onPress={async () => await showFilePicker(onSelect, acceptType)}
    />
  );
}
