import type { AppMenuItem, ParamListBase } from "@gigsmart/kaizoku";
import React from "react";
import { ContentArea } from "../atoms";
import {
  Divider,
  Icon,
  Pressable,
  Spacer,
  Text,
  getSpaceUnits
} from "../quarks";
import { useStyles } from "../style";

interface Props<ParamList extends ParamListBase = ParamListBase> {
  item: AppMenuItem<ParamList>;
  active?: boolean;
  collapsed?: boolean;
  onPress?: (item: AppMenuItem<ParamList>) => void;
  variant?: "static" | "default";
}

export const SIDENAV_ITEM_SIZE = 44;
export const SIDENAV_ITEM_SIZE_STATIC = 60;

export default function SidenavItem({
  item,
  collapsed,
  active,
  onPress,
  variant = "default"
}: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      backgroundColor: getColor("surface", "fill"),
      borderRadius: getUnits(1),
      alignItems: "center"
    },
    containerDefault: {
      marginHorizontal: getSpaceUnits("compact"),
      flexDirection: "row",
      height: 40
    },
    containerStatic: {
      marginHorizontal: getSpaceUnits("slim"),
      width: SIDENAV_ITEM_SIZE_STATIC,
      paddingVertical: getUnits(1.5)
    },

    hovered: { backgroundColor: getColor("foreground", "fill") },
    pressed: { opacity: 0.3 },
    active: { backgroundColor: getColor("primary", "fill", { opacity: 0.08 }) },

    icon: {
      width: SIDENAV_ITEM_SIZE,
      alignItems: "center",
      justifyContent: "center"
    }
  }));

  if (item === "separator") {
    return (
      <ContentArea variant="none" size="slim">
        <Divider />
      </ContentArea>
    );
  }

  const color = active ? "primary" : "neutral";
  const testID = item.testID ?? `${item.title}-sidenav-item`;

  return (
    <Pressable
      eventEntityType="SidenavItem"
      eventTargetName={`${item.title} Sidenav Item`}
      testID={testID}
      onPress={() => onPress?.(item)}
      style={({ hovered, pressed }) => [
        styles.container,
        variant === "default"
          ? styles.containerDefault
          : styles.containerStatic,
        hovered && styles.hovered,
        pressed && styles.pressed,
        active && styles.active
      ]}
    >
      {typeof item.icon === "string" ? (
        <Icon
          style={styles.icon}
          name={item.icon}
          color={color}
          variant={active ? "solid" : "light"}
          size={variant === "default" ? "medium" : "large"}
          align="center"
        />
      ) : (
        item.icon
      )}
      {variant === "static" && <Spacer size="small" />}
      {!collapsed && (
        <Text
          color={color}
          weight={active ? "semibold" : "normal"}
          variant={variant === "default" ? "body" : "note"}
          align={variant === "default" ? undefined : "center"}
        >
          {item.title}
        </Text>
      )}
    </Pressable>
  );
}

export const isActive = <ParamList extends ParamListBase>(
  item: AppMenuItem<ParamList>,
  currentScreenName?: keyof ParamList
) => item !== "separator" && "to" in item && item.to === currentScreenName;

export const isHighlighted = (item: AppMenuItem) =>
  item !== "separator" && (item.icon === "plus" || item.icon === "search");
