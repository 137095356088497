import {
  type ParamListBase,
  useIsFocused,
  useNavigation
} from "@react-navigation/native";
import { useEffect } from "react";
import { type AppNavigationProp, useGetRouteFromPath } from "./navigation";

interface Props {
  back?: boolean;
  delay?: number;
  replace?: boolean;
  to?: string | null;
  screen?: string | null;
  screenParams?: object;
}

export default function Redirect({
  back,
  to,
  replace,
  delay,
  screen,
  screenParams
}: Props) {
  const navigation = useNavigation<AppNavigationProp<ParamListBase>>();
  const toRoute = useGetRouteFromPath();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) return;

    if (back) {
      navigation.goBack();
      return;
    }

    if (!screen && to) {
      const route = to ? toRoute(to) : null;
      if (!route) {
        console.warn("Route not found: to=%s", to);
        return;
      }

      screen = route.name;
      screenParams = route.params;
    }

    const timeoutId = setTimeout(() => {
      if (!screen) navigation.setParams(screenParams ?? {});
      else if (replace) navigation.replace(screen, screenParams);
      else navigation.push(screen, screenParams);
    }, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  });

  return null;
}

function findRootNavigation<T extends AppNavigationProp<any>>(nav: T): T {
  if (nav.getId() === "RootStack" || !nav.getParent()) return nav;
  return findRootNavigation(nav.getParent() as any);
}
