import React from "react";

import { Collapsible } from "../molecules";
import { Column, Text } from "../quarks";
import SettingsSwitch from "./SettingsSwitch";

interface Props {
  title: string;
  switchTitle: string;
  switchDescription: string;
  toggled: boolean;
  onChange: (value: boolean) => void;
  testID: string;
}

export default function CollapsibleSwitch({
  title,
  switchTitle,
  switchDescription,
  toggled,
  onChange,
  testID
}: Props) {
  return (
    <Collapsible
      startCollapsed
      variant="plain"
      header={
        <Column>
          <Text weight="bold">{title}</Text>
          <Text color="disabled" testID={`description-${testID}`}>
            {!toggled ? "No" : "Yes"}
          </Text>
        </Column>
      }
      testID={testID}
    >
      <SettingsSwitch
        label={switchTitle}
        description={switchDescription}
        checked={toggled}
        onChange={onChange}
        testID={testID && `${testID}-setting`}
      />
    </Collapsible>
  );
}
