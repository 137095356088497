/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Platform, type TextStyle } from "react-native";
import {
  type FontRegistry,
  type FontStyleName,
  type FontWeightName,
  findWeightKeyByValue
} from "./fontHelpers";
import loadFonts, { getFontsStyleElement } from "./loadFonts";

let loadTimeout: any;

const registry: FontRegistry = {};

export interface FontOptions {
  name: string;
  weight?: FontWeightName | "*";
  style?: FontStyleName | "*";
  posixName: string;
  loadFile: () => string;
}

export function registerFont({
  name,
  weight = "*",
  style = "*",
  posixName,
  loadFile
}: FontOptions): void {
  clearTimeout(loadTimeout);
  const weightRegistry = (registry[name] ??= {} as FontRegistry[string]);
  const styleRegistry = (weightRegistry[weight] ??=
    {} as FontRegistry[any][typeof weight]);
  styleRegistry[style] = { posixName, loadFile };
  loadTimeout = setTimeout(loadFonts, 0, registry);
}

export function getFontsStyle() {
  return getFontsStyleElement(registry).outerHTML;
}

export function transformFont(style: TextStyle): TextStyle {
  const {
    fontFamily = "Proxima Nova",
    fontStyle = "normal",
    fontWeight = "normal",
    ...rest
  } = style;

  if (Platform.OS !== "android" || !registry[fontFamily]) return style;
  const weight = findWeightKeyByValue(fontWeight) ?? "normal";
  const spec = registry[fontFamily]?.[weight]?.[fontStyle];
  if (!spec) {
    throw new Error(
      `font not registered: fontFamily: ${fontFamily} fontWeight: ${fontWeight} fontStyle: ${fontStyle}`
    );
  }

  return {
    ...rest,
    fontFamily: spec.posixName
  };
}

// Font Awesome Fonts
registerFont({
  name: "FontAwesome6_Pro_Brands",
  posixName: "FontAwesome6_Pro_Brands-Regular",
  loadFile: () => require("../../fonts/FontAwesome6_Pro_Brands.ttf")
});
registerFont({
  name: "FontAwesome6_Pro_Light",
  posixName: "FontAwesome6_Pro_Light",
  loadFile: () => require("../../fonts/FontAwesome6_Pro_Light.ttf")
});
registerFont({
  name: "FontAwesome6_Pro_Regular",
  posixName: "FontAwesome6_Pro_Regular",
  loadFile: () => require("../../fonts/FontAwesome6_Pro_Regular.ttf")
});
registerFont({
  name: "FontAwesome6_Pro_Solid",
  posixName: "FontAwesome6_Pro_Solid",
  loadFile: () => require("../../fonts/FontAwesome6_Pro_Solid.ttf")
});
registerFont({
  name: "FontAwesome6_Pro_Duotone",
  posixName: "FontAwesome6_Pro_Duotone",
  loadFile: () => require("../../fonts/FontAwesome6_Pro_Duotone.ttf")
});
registerFont({
  name: "FontAwesome6_Pro_Thin",
  posixName: "FontAwesome6_Pro_Thin",
  loadFile: () => require("../../fonts/FontAwesome6_Pro_Thin.ttf")
});
registerFont({
  name: "FontAwesome6_Pro_Sharp_Solid",
  posixName: "FontAwesome6_Pro_Sharp_Solid",
  loadFile: () => require("../../fonts/FontAwesome6_Pro_Sharp_Solid.ttf")
});

// Proxima Nova Fonts
registerFont({
  name: "Proxima Nova",
  weight: "black",
  style: "normal",
  posixName: "ProximaNova-Black",
  loadFile: () => require("../../fonts/ProximaNova-Black.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "black",
  style: "italic",
  posixName: "ProximaNova-BlackIt",
  loadFile: () => require("../../fonts/ProximaNova-BlackIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "bold",
  style: "normal",
  posixName: "ProximaNova-Bold",
  loadFile: () => require("../../fonts/ProximaNova-Bold.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "bold",
  style: "italic",
  posixName: "ProximaNova-BoldIt",
  loadFile: () => require("../../fonts/ProximaNova-BoldIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "heavy",
  style: "normal",
  posixName: "ProximaNova-Extrabold",
  loadFile: () => require("../../fonts/ProximaNova-Extrabold.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "heavy",
  style: "italic",
  posixName: "ProximaNova-ExtraboldIt",
  loadFile: () => require("../../fonts/ProximaNova-ExtraboldIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "light",
  style: "normal",
  posixName: "ProximaNova-Light",
  loadFile: () => require("../../fonts/ProximaNova-Light.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "light",
  style: "italic",
  posixName: "ProximaNova-LightIt",
  loadFile: () => require("../../fonts/ProximaNova-LightIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "extralight",
  style: "normal",
  posixName: "ProximaNova-Light",
  loadFile: () => require("../../fonts/ProximaNova-Light.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "extralight",
  style: "italic",
  posixName: "ProximaNova-LightIt",
  loadFile: () => require("../../fonts/ProximaNova-LightIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "medium",
  style: "normal",
  posixName: "ProximaNova-Medium",
  loadFile: () => require("../../fonts/ProximaNova-Medium.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "medium",
  style: "italic",
  posixName: "ProximaNova-MediumIt",
  loadFile: () => require("../../fonts/ProximaNova-MediumIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "normal",
  style: "normal",
  posixName: "ProximaNova-Regular",
  loadFile: () => require("../../fonts/ProximaNova-Regular.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "normal",
  style: "italic",
  posixName: "ProximaNova-RegularIt",
  loadFile: () => require("../../fonts/ProximaNova-RegularIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "semibold",
  style: "normal",
  posixName: "ProximaNova-Semibold",
  loadFile: () => require("../../fonts/ProximaNova-Semibold.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "semibold",
  style: "italic",
  posixName: "ProximaNova-SemiboldIt",
  loadFile: () => require("../../fonts/ProximaNova-SemiboldIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "thin",
  style: "italic",
  posixName: "ProximaNova-ThinIt",
  loadFile: () => require("../../fonts/ProximaNova-ThinIt.otf")
});
registerFont({
  name: "Proxima Nova",
  weight: "thin",
  style: "normal",
  posixName: "ProximaNova-Thin",
  loadFile: () => require("../../fonts/ProximaNova-Thin.otf")
});

const defaultFonts = {
  serif: "Proxima Nova",
  sansSerif: "Proxima Nova",
  monospace: Platform.OS === "ios" ? "Courier" : "monospace"
};

export type ThemeFonts = typeof defaultFonts;
export default defaultFonts;
