import React, { type ComponentProps } from "react";

import { showModal } from "../ModalProvider";
import PDFViewer from "./PDFViewer";

type Options = Omit<ComponentProps<typeof PDFViewer>, "onClose">;

export const showPDFViewer = (opt: Options) => {
  showModal({
    raw: true,
    transparent: true,
    children: (closeFn) => <PDFViewer {...opt} onClose={closeFn} />
  });
};
