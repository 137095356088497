import { sortBy } from "lodash";
import React, { type ReactNode } from "react";
import Checkbox from "../atoms/Checkbox";
import ContentArea from "../atoms/ContentArea";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Divider from "../quarks/Divider";

interface Props<V> {
  testID?: string;
  onChange?: (values: V[], updatedValue?: V) => void;
  options: Array<{ value: V; Component: ReactNode }>;
  selectedValues: V[];
}

export default function CheckList<V>({
  testID,
  onChange,
  options,
  selectedValues = []
}: Props<V>) {
  const onPress = (selected: boolean, optionValue: V) => {
    if (!onChange) return;

    let newValue = selected
      ? selectedValues?.filter((v) => {
          return v !== optionValue;
        })
      : [...selectedValues, optionValue];
    if (typeof optionValue === "string" || typeof optionValue === "number") {
      newValue = sortBy(newValue);
    }

    onChange(newValue, optionValue);
  };

  return (
    <Column testID={testID}>
      <Divider />
      {options?.map((option, index) => {
        const selected = selectedValues?.includes(option.value);
        return (
          <React.Fragment key={index}>
            <ContentArea>
              <Stack
                horizontal
                alignItems="center"
                justifyContent="space-between"
                key={index}
              >
                {option.Component}
                <Checkbox
                  testID={`${testID}-checkbox-${option.value}`}
                  selected={selected}
                  onChange={() => onPress(selected, option.value)}
                />
              </Stack>
            </ContentArea>
            <Divider />
          </React.Fragment>
        );
      })}
    </Column>
  );
}
