import React, { type ComponentProps, type ReactNode } from "react";
import { View } from "react-native";
import ContentArea from "../atoms/ContentArea";
import Surface from "../atoms/Surface";
import ActionRow from "../molecules/ActionRow";
import Pressable from "../quarks/Pressable";
import { useStyles } from "../style";

interface Props extends ComponentProps<typeof ActionRow> {
  bottomContent: ReactNode;
  eventEntityType: string;
  onPress?: () => void;
}

// TODO: Check this one
export default function ProductInfoOutlineContainer({
  bottomContent,
  eventEntityType,
  testID,
  onPress,
  ...rest
}: Props) {
  const styles = useStyles(
    () => ({
      bottomSurfaceWrapper: { marginTop: -6, zIndex: -1 }
    }),
    []
  );

  return (
    <Pressable
      onPress={onPress}
      eventEntityType={eventEntityType}
      eventTargetName=""
      testID={testID}
    >
      <Surface variant="outline">
        <ActionRow testID={`${testID}-row`} variant="flat" {...rest} />
      </Surface>
      <View style={styles.bottomSurfaceWrapper}>
        <Surface variant="outline">
          <ContentArea size="medium" variant="compact">
            {bottomContent}
          </ContentArea>
        </Surface>
      </View>
    </Pressable>
  );
}
