import React, {
  type ReactNode,
  useMemo,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef
} from "react";
import { View } from "react-native";
import RNCollapsible from "react-native-collapsible";
import Card from "../atoms/Card";
import { useIsConstrained } from "../atoms/Constraint";
import ContentArea from "../atoms/ContentArea";
import Stack from "../atoms/Stack";
import Surface from "../atoms/Surface";
import Column from "../quarks/Column";
import Divider from "../quarks/Divider";
import Icon from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Spacer from "../quarks/Spacer";
import { useMatchesViewport, useStyles } from "../style";
import type { OnlyChildren } from "../utils/types";

const OutlineCompImplOuter = (props: OnlyChildren) => (
  <Surface variant="outline" {...props} />
);

const OutlineCompImplInner = (props: OnlyChildren) => (
  <Surface variant="flat" {...props} />
);

const COMPONENT_MAP = {
  outline: {
    OuterComponent: OutlineCompImplOuter,
    InnerComponent: OutlineCompImplInner
  },
  card: Card,
  surface: Surface,
  box: { OuterComponent: Card, InnerComponent: View },
  plain: { OuterComponent: View, InnerComponent: View }
};

type Props = {
  header: ReactNode | ((collapsed: boolean) => ReactNode);
  headerAlign?: "center" | "flex-start" | "flex-end";
  testID: string;
  variant: keyof typeof COMPONENT_MAP;
  direction?: "top" | "bottom";
  size?: "standard" | "medium" | "compact" | "slim" | "none";
  startCollapsed?: boolean;
  onSetCollapsed?: (collapsed: boolean) => void;
  highlight?: boolean;
  adaptToConstraints?: boolean;
  noPad?: boolean;
  disabled?: boolean;
  renderChildrenCollapsed?: boolean;
} & OnlyChildren;

export type CollapsibleRef = {
  setCollapsed: (isCollapsed: boolean) => void;
};

function CollapsableNode({
  collapsed,
  variant,
  renderChildrenCollapsed,
  children
}: {
  collapsed: boolean;
  renderChildrenCollapsed?: boolean;
  variant: keyof typeof COMPONENT_MAP;
  children: ReactNode;
}) {
  return (
    <RNCollapsible
      collapsed={collapsed}
      renderChildrenCollapsed={renderChildrenCollapsed}
    >
      {variant === "box" && <Divider />}
      {children}
    </RNCollapsible>
  );
}

export default forwardRef<CollapsibleRef, Props>(function Collapsible(
  {
    children,
    header,
    headerAlign,
    variant,
    startCollapsed = false,
    renderChildrenCollapsed = true,
    onSetCollapsed,
    direction = "bottom",
    size,
    highlight,
    testID,
    adaptToConstraints,
    noPad,
    disabled
  },
  ref
) {
  const isAndroid = useMatchesViewport(({ platform }) => platform.android);
  const styles = useStyles(({ getUnits }) => ({
    wrapper: {
      paddingHorizontal: getUnits(4)
    }
  }));
  const isConstrained = useIsConstrained();
  const [collapsed, setCollapsed] = useState(startCollapsed);
  const handlePress = useCallback(() => {
    setCollapsed(!collapsed);
    onSetCollapsed?.(!collapsed);
  }, [collapsed, onSetCollapsed]);

  const { OuterComponent, InnerComponent } = useMemo(() => {
    const Comp = COMPONENT_MAP[variant];
    if ("OuterComponent" in Comp) return Comp;
    return { OuterComponent: Comp, InnerComponent: Comp };
  }, [variant]);

  if (typeof header === "function") {
    header = header(collapsed);
  }

  useImperativeHandle(ref, () => {
    return {
      setCollapsed: async (isCollapsed: boolean) => {
        setCollapsed(isCollapsed);
      }
    };
  });

  const iconNode = (
    <Icon
      name={disabled || collapsed ? "chevron-down" : "chevron-up"}
      variant="solid"
      color={disabled ? "neutral" : "primary"}
      size="small"
    />
  );

  const collapsibleNode = (
    <CollapsableNode
      key="collapsible"
      variant={variant}
      collapsed={disabled || collapsed}
      renderChildrenCollapsed={renderChildrenCollapsed}
    >
      {children}
    </CollapsableNode>
  );

  const innerContent = (
    <Stack
      size="medium"
      horizontal
      justifyContent="space-between"
      alignItems="center"
    >
      {direction === "top" && iconNode}
      <Column fill>{header}</Column>
      {direction === "bottom" && iconNode}
      {noPad && <Spacer />}
    </Stack>
  );

  const content = (
    <OuterComponent
      highlight={highlight}
      testID={`${testID}-outer`}
      eventTargetName="Outer Collapsible"
    >
      {direction === "top" && collapsibleNode}
      <Pressable
        disabled={disabled}
        onPress={handlePress}
        eventEntityType="Collapsable"
        eventTargetName="Collapsible"
        testID={testID ?? "collapsable"}
      >
        <InnerComponent
          hideInnerMargin={collapsed}
          testID={`${testID}-inner`}
          eventTargetName="Inner Collapsible"
          {...(variant === "surface" && collapsed && { variant: "flat" })}
        >
          {noPad ? (
            innerContent
          ) : (
            <ContentArea size={size} alignItems={headerAlign}>
              {innerContent}
            </ContentArea>
          )}
        </InnerComponent>
        {isAndroid && !collapsed && <Spacer size="slim" />}
      </Pressable>
      {direction === "bottom" && collapsibleNode}
    </OuterComponent>
  );

  return adaptToConstraints && !isConstrained ? (
    <Column style={styles.wrapper}>{content}</Column>
  ) : (
    content
  );
});
