import React, { useCallback, useEffect } from "react";
import Animated, {
  LinearTransition,
  SlideInRight,
  SlideInUp,
  SlideOutRight
} from "react-native-reanimated";
import ContentArea from "../../atoms/ContentArea";
import Surface from "../../atoms/Surface";
import MaybeText from "../../molecules/MaybeText";
import Column from "../../quarks/Column";
import Icon from "../../quarks/Icon";
import Pressable from "../../quarks/Pressable";
import Row from "../../quarks/Row";
import Text from "../../quarks/Text";
import {
  type ViewStyle,
  responsive,
  useResponsiveValue,
  useStyle
} from "../../style";
import type { ToastEvent } from "./eventStore";

export interface ToastProps {
  id: number;
  label?: ToastEvent["label"];
  dismissable: ToastEvent["dismissable"];
  message: ToastEvent["message"];
  color?: ToastEvent["color"];
  onPress?: ToastEvent["onPress"];
  onDismiss?: ToastEvent["onDismiss"];
  dismissAfter?: ToastEvent["dismissAfter"];
  testID?: ToastEvent["testID"];
  icon?: ToastEvent["icon"];
  dismiss: () => void;
  exclusive?: ToastEvent["exclusive"];
}

export default function Toast({
  id,
  icon,
  dismissable = true,
  message,
  color,
  onPress,
  onDismiss,
  dismissAfter,
  testID,
  dismiss
}: ToastProps) {
  const isSmallDevice = useResponsiveValue({ medium: false }, true);

  const toastContainerStyle = useStyle<ViewStyle>(({ media }) => [
    { width: "100%", flexShrink: 0 },
    responsive(media.size.medium.up, { width: 300 })
  ]);

  const handleDismiss = useCallback(() => {
    if (!dismissable) return;
    onDismiss?.();
    dismiss();
  }, [dismiss]);

  const handlePress = useCallback(() => {
    onPress?.();
    handleDismiss();
  }, [onPress, handleDismiss]);

  useEffect(() => {
    if (!dismissAfter) return;
    const timeout = setTimeout(dismiss, dismissAfter);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Animated.View
      layout={LinearTransition.delay(250)}
      entering={isSmallDevice ? SlideInUp : SlideInRight}
      exiting={SlideOutRight.duration(250)}
      style={toastContainerStyle}
      testID={testID ?? `toast-${id}-animated`}
    >
      <Surface
        color={color}
        topRadius="slim"
        bottomRadius="slim"
        zIndex={4}
        hideInnerMargin
      >
        <Pressable
          eventEntityType="toast"
          eventTargetName="toast"
          onPress={handlePress}
          testID={testID ?? `toast-${id}`}
        >
          <ContentArea justifyContent="center" size="medium">
            <Row alignItems="center" gap="compact" grow>
              <Column shrink>
                <Icon name={icon ?? "bullhorn"} variant="solid" />
              </Column>
              <Column fill>
                {message !== null &&
                typeof message === "object" &&
                "detail" in message ? (
                  <Column gap="compact" grow>
                    <Text variant="subheader">{message.title}</Text>
                    <MaybeText>{message.detail}</MaybeText>
                  </Column>
                ) : (
                  <MaybeText>{message}</MaybeText>
                )}
              </Column>
            </Row>
          </ContentArea>
        </Pressable>
      </Surface>
    </Animated.View>
  );
}
