import React from "react";
import { StyleSheet } from "react-native";
import { StyledIcon } from "../../icon";

interface Props {
  open?: boolean;
  onPress?: () => void;
}

const styles = StyleSheet.create({
  icon: { width: 20, height: 20, bottom: 1 }
});

const StyledPickerArrow = ({ open, onPress }: Props) => (
  <StyledIcon
    pointerEvents={onPress ? "auto" : "none"}
    style={styles.icon}
    color={open ? "blueTint" : "blue"}
    variant="solid"
    name={open ? "caret-up" : "caret-down"}
    onPress={onPress}
  />
);

export default StyledPickerArrow;
