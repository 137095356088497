import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint
} from "@vis.gl/react-google-maps";
import React from "react";
import { View } from "react-native";
import { useStyle } from "../../style";

interface Props {
  active?: boolean;
  latitude: number;
  longitude: number;
  onPress?: () => void;
  testID: string;
  zIndex?: number;
  callout?: React.ReactNode | null;
}

export default function MapPolylineCircle({
  active,
  latitude,
  longitude,
  onPress,
  testID,
  zIndex
}: Props) {
  const style = useStyle(
    ({ getColor }) => ({
      height: 18,
      width: 18,
      borderRadius: 9,
      borderWidth: 4,
      borderColor: getColor(active ? "primary" : "surface", "fill"),
      backgroundColor: getColor("primary", "fill")
    }),
    [active]
  );
  return (
    <AdvancedMarker
      testID={testID}
      position={{
        lat: latitude ?? 0,
        lng: longitude ?? 0
      }}
      onClick={onPress}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
      zIndex={zIndex}
    >
      <View style={style} />
    </AdvancedMarker>
  );
}
