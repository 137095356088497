import React, {
  type Component,
  type ReactNode,
  createContext,
  useContext,
  useEffect
} from "react";
import {
  type AnimatedRef,
  type SharedValue,
  scrollTo,
  useAnimatedRef,
  useAnimatedScrollHandler,
  useSharedValue
} from "react-native-reanimated";

type Props = {
  children?: ReactNode;
};

type Destructor = () => void;
type ScrollRef = AnimatedRef<any>;
type ContextType = {
  register: (ref: AnimatedRef<any>, id: string) => Destructor;
  syncScroll: (id: string, offset: number) => void;
  offset: SharedValue<number>;
};

const Context = createContext<ContextType | null>(null);

export const useSyncScroll = <T extends Component>(id: string) => {
  const listRef = useAnimatedRef<T>();
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error("useSyncScroll must be used within SyncScrollProvider");
  }

  useEffect(() => {
    return ctx.register(listRef, id);
  }, [id]);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      "worklet";
      const pos = e.contentOffset.y;
      ctx.offset.value = pos;
      ctx.syncScroll(id, pos);
    }
  });

  return { listRef, scrollHandler };
};

export function SyncScrollProvider({ children }: Props) {
  const scrollRefs = useSharedValue<Record<string, ScrollRef | null>>({});
  const offset = useSharedValue(0);
  const register = (scrollRef: AnimatedRef<any>, id: string) => {
    scrollRefs.modify((obj: any) => {
      "worklet";
      obj[id] = scrollRef;
      return obj;
    });
    return () => {
      scrollRefs.modify((obj: any) => {
        "worklet";
        obj[id] = null;
        return obj;
      });
    };
  };
  const syncScroll = (active: string, offset: number) => {
    "worklet";

    const ids = Object.keys(scrollRefs.value);
    for (const id of ids) {
      const ref = scrollRefs.value[id];
      if (ref && id !== active) scrollTo(ref, 0, offset, false);
    }
  };

  return (
    <Context.Provider value={{ register, syncScroll, offset }}>
      {children}
    </Context.Provider>
  );
}
