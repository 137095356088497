import React, { useState } from "react";
import { Button, ModalBody, ModalFooter, Stack } from "../../atoms";
import TextInput from "../../molecules/TextInput";

interface Props {
  onClose?: () => void;
  onSubmit?: (str: string) => void;
}

export default function PromptLink({ onClose, onSubmit }: Props) {
  const [linkText, setLinkText] = useState("");
  const handleSubmit = () => {
    if (linkText) onSubmit?.(linkText);
    onClose?.();
    setLinkText("");
  };

  return (
    <>
      <ModalBody>
        <TextInput
          testID="link-input"
          value={linkText}
          placeholder="Enter the URL"
          onChangeText={setLinkText}
          onSubmitEditing={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <Stack horizontal justifyContent="flex-end">
          <Button
            testID="clear-btn"
            fill
            variant="clear"
            label="Cancel"
            onPress={onClose}
          />
          <Button
            testID="confirm-btn"
            fill
            label="Confirm"
            onPress={handleSubmit}
          />
        </Stack>
      </ModalFooter>
    </>
  );
}
