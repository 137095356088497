import * as Immutable from "immutable";
import type { ErrorMap, Validator, ValueMap, ValueObject } from "./types";

export function mapToObject(map: ValueMap | null | undefined): ValueObject {
  if (!map) return {};
  return map.reduce(
    (acc, value, key) =>
      (key !== undefined
        ? {
            ...acc,
            [key]: value
          }
        : acc) as ValueObject,
    {} as unknown as ValueObject
  );
}

export function objectToMap(obj: ValueObject | ValueMap): ValueMap {
  return obj instanceof Immutable.OrderedMap
    ? Immutable.OrderedMap<string, any>(obj as ValueMap)
    : Immutable.OrderedMap<any>(obj);
}

export function normalizeValidators(
  validators: (Validator<any> | null | undefined) | Array<Validator<any>>
): Array<Validator<any>> {
  if (!validators) return [];
  return Array.isArray(validators) ? validators : [validators];
}

export function errorsEqual(
  errors1: Error[] | null | undefined,
  errors2: Error[] | null | undefined
): boolean {
  if (!errors1 || !errors2) return errors1 === errors2;
  if (errors1?.length !== errors2?.length) return false;
  return (
    errors1 === errors2 ||
    !!errors1?.every((err, idx) => err.message === errors2?.[idx]?.message)
  );
}

export function processFieldErrors(
  fieldName: string,
  value: any,
  validators: Validator<any> | Array<Validator<any>> | null,
  otherValues: ValueMap,
  prefix = ""
) {
  if (validators === null) return null;
  const finalValidators: Array<Validator<any>> = Array.isArray(validators)
    ? validators
    : [validators];
  let errors: Error[] = finalValidators.reduce<Error[]>(
    (subMemo, validator) => [
      ...subMemo,
      ...(validator(fieldName, value, otherValues) ?? [])
    ],
    []
  );
  if (prefix !== "") {
    errors = errors.map(({ message }) => {
      const [originalMessage, ...keysReversed] = `${prefix}: ${message}`
        .split(": ")
        .reverse();
      const newPrefix = keysReversed.reverse().join("");
      return new Error(
        newPrefix !== "" ? `${newPrefix}: ${originalMessage}` : originalMessage
      );
    });
  }
  return errors.length > 0 ? errors : null;
}

export function collectErrors(errors: ErrorMap, prefix = ""): Error[] {
  return errors.reduce<Error[]>(
    (memo, fieldErrors, key): Error[] => [
      ...(memo ?? ([] as Error[])),
      ...(fieldErrors ?? []).map(
        ({ message }) =>
          new Error(`${prefix}${key === undefined ? "" : key}: ${message}`)
      )
    ],
    [] as Error[]
  );
}

export function deferMapUpdate<V>(
  updater: (arg0: Immutable.OrderedMap<string, V>) => void,
  timeout = 100
): (name: string, value: V) => void {
  let collector = Immutable.OrderedMap<string, V>();
  let timeoutId: any;
  return (name: string, value: V) => {
    clearTimeout(timeoutId);
    collector = collector.set(name, value);
    timeoutId = setTimeout(() => {
      updater(collector);
    }, timeout);
  };
}
