import { useCallback, useEffect, useRef, useState } from "react";
import { useRelayOrchestrator } from "../orchestrator";

export interface LoaderFnOpts {
  isLoading: boolean;
  progress: number;
  queriesPending: number;
}

export type LoaderFn = (opts: LoaderFnOpts) => void;

export function useRelayLoader(delay = 0, resetDelayOnFufilled = false) {
  const [{ isLoading, progress, queriesPending }, update] = useState({
    isLoading: false,
    progress: 1,
    queriesPending: 0
  });
  const { __UNSAFE_registerLoader, __UNSAFE_deregisterLoader } =
    useRelayOrchestrator();
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const delayedUpdate = useCallback(
    ({ isLoading, progress, queriesPending }: LoaderFnOpts) => {
      const opts = {
        isLoading,
        progress,
        queriesPending: resetDelayOnFufilled ? queriesPending : 0
      };
      if (timeout.current) clearTimeout(timeout.current);
      if (!delay) {
        update(opts);
        return;
      }
      timeout.current = setTimeout(update, delay, opts);
    },
    [delay, resetDelayOnFufilled]
  );
  useEffect(() => {
    __UNSAFE_registerLoader(delayedUpdate);
    return function cleanup() {
      if (timeout.current) clearTimeout(timeout.current);
      __UNSAFE_deregisterLoader(delayedUpdate);
    };
  }, [
    __UNSAFE_registerLoader,
    __UNSAFE_deregisterLoader,
    delayedUpdate,
    queriesPending
  ]);
  return { isLoading, progress };
}
