import { FlagType } from "@gigsmart/feature-flags/registry";
import ReconnectingWebSocket from "reconnecting-websocket";
import { type Backend, registerBackend } from "../backendRegistry";
import { consoleBackend } from "./console";

const serialize = (object: any, visited: any[] = []): any => {
  if (visited.length > 10) return "[max depth]";
  if (visited.includes(object)) return "[circular]";
  const nextVisited = [...visited, object];
  if (Array.isArray(object)) {
    return object.map((v: any) => serialize(v, nextVisited));
  }
  if (object instanceof Error) {
    return {
      message: object.message,
      stack: object.stack
    };
  }
  switch (typeof object) {
    case "function":
      return object.toString();
    case "object":
      if (object.constructor === Object) {
        return Object.entries(object).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: serialize(v, nextVisited)
          }),
          {}
        );
      }
      break;
    default:
      return object;
  }
};

class RemoteLogger {
  private ready = false;
  private remoteUrl?: string;
  private sessionId?: string;
  private ws?: ReconnectingWebSocket;
  private heartbeatTimeout?: ReturnType<typeof setTimeout>;

  constructor(
    private hostUrl = process.env.REMOTE_LOGGER_BASE_URL ??
      "wss://keiki.ops.gigsmart.com"
  ) {}

  setSessionId = (sessionId: string) => {
    this.teardown();
    if (!this.ws) this.connect();
    this.sessionId = sessionId;
  };

  getRemoteUrl = () => {
    return this.remoteUrl;
  };

  private announceReady() {
    this.ws?.send(JSON.stringify({ requestId: "ready" }));
  }

  private heartbeat = () => {
    if (!this.ws) return;
    this.heartbeatTimeout = setTimeout(this.teardown, 5000);
    this.ws.send(JSON.stringify({ requestId: "heartbeat", response: true }));
  };

  private connect() {
    if (backend.isDisabled() || this.ws) return;
    if (!this.sessionId) return;
    const clientUrl = `${this.hostUrl}/s/${this.sessionId}/c`;
    this.remoteUrl = `${this.hostUrl.replace(/^ws/, "http")}#${this.sessionId}`;
    consoleBackend.log(
      "Remote logger enabled, visit",
      this.remoteUrl,
      "to view logs"
    );
    this.ws = new ReconnectingWebSocket(clientUrl);
    this.ws.onopen = () => this.announceReady();
    this.ws.onmessage = ({ data }) => {
      if (backend.isDisabled()) return this.teardown();
      const { requestId, command } = JSON.parse(String(data));
      this.ws?.send(JSON.stringify({ requestId, ack: true }));
      switch (command) {
        case "/heartbeat":
          if (this.heartbeatTimeout) clearTimeout(this.heartbeatTimeout);
          this.ws?.send(JSON.stringify({ requestId, payload: true }));
          this.ready = true;
          setTimeout(this.heartbeat, 5000);
          break;
        case "/waiting":
          this.ws?.send(JSON.stringify({ requestId }));
          this.announceReady();
          break;
        case "/ready":
          this.ws?.send(JSON.stringify({ requestId }));
          consoleBackend.info("Remote logger connected!");
          this.heartbeat();
          break;
        case "/list":
          this.ws?.send(
            JSON.stringify({
              requestId,
              response: { availableInstruments: [] }
            })
          );
          break;
      }
    };
  }

  private teardown() {
    if (!this.ws) return;
    consoleBackend.info("remote logger disconnected");
    this.ready = false;
    this.remoteUrl = undefined;
    this.ws.onclose = () => null;
    this.ws.close();
    this.ws = undefined;
  }

  private send(level: keyof Backend, message?: any, ...optionalParams: any[]) {
    if (backend.isDisabled()) return this.teardown();
    if (!this.ws) return this.connect();
    if (!this.ready) return;
    try {
      this.ws.send(
        JSON.stringify({
          console: level,
          payload: serialize([message, ...optionalParams])
        })
      );
    } catch (e) {
      if (e.message.includes("NavigationContainer")) return;
    }
  }

  log = (message?: any, ...optionalParams: any[]) => {
    this.send("log", message, ...optionalParams);
  };

  info = (message?: any, ...optionalParams: any[]) => {
    this.send("info", message, ...optionalParams);
  };

  warn = (message?: any, ...optionalParams: any[]) => {
    this.send("warn", message, ...optionalParams);
  };

  error = (message?: any, ...optionalParams: any[]) => {
    this.send("error", message, ...optionalParams);
  };

  debug = (message?: any, ...optionalParams: any[]) => {
    this.send("debug", message, ...optionalParams);
  };

  trace = (message?: any, ...optionalParams: any[]) => {
    this.send("trace", message, ...optionalParams);
  };
}

const remoteLogger = new RemoteLogger();
const backend = registerBackend("remote", remoteLogger, FlagType.REMOTE);

export const remoteLoggingEnabled = backend.isEnabled;
export const { setSessionId: setRemoteSessionId, getRemoteUrl } = remoteLogger;
