import { useEventer } from "@gigsmart/dekigoto";
import type { DeepPartial } from "@gigsmart/type-utils";
import { compact } from "lodash";
import merge from "lodash/merge";
import { useCallback, useDebugValue, useMemo, useState } from "react";
import { type GraphQLTaggedNode, commitMutation } from "react-relay";
import type {
  DeclarativeMutationConfig,
  MutationParameters,
  SelectorStoreUpdater,
  Uploadable
} from "relay-runtime";
import { useRelayEnvironment } from "../environment";
import { useRequestMetadata } from "../helpers";
import { RelayRequestError, useRelayOrchestrator } from "../orchestrator";
import type { PayloadError } from "../payload-error";
import { logger as baseLogger } from "./logger";

export type MutationSpec = MutationParameters;
export type RelayMutationVariables<TOperation extends MutationParameters> =
  TOperation["variables"];
export type RelayMutationResponse<TOperation extends MutationParameters> =
  TOperation["rawResponse"] & TOperation["response"];

export type RNUploadableMap = Record<string, Uploadable | { uri: string }>;

export type RelayMutationUpdater<TOperation extends MutationParameters> =
  SelectorStoreUpdater<TOperation["rawResponse"] & TOperation["response"]>;

export interface RelayMutationSharedOptions<
  TOperation extends MutationParameters
> {
  onCompleted?: (
    response: TOperation["rawResponse"] & TOperation["response"]
  ) => unknown;
  onSuccess?: (
    response: TOperation["rawResponse"] & TOperation["response"]
  ) => unknown;
  onPayloadErrors?: (
    errors: readonly PayloadError[],
    retry: () => void
  ) => unknown | false;
  onError?: (error: RelayRequestError, retry?: () => void) => unknown | false;
  optimisticUpdater?:
    | (SelectorStoreUpdater<TOperation["rawResponse"]> | null)
    | undefined;
  optimisticResponse?:
    | DeepPartial<TOperation["rawResponse"]>
    | ((
        variables: TOperation["variables"]
      ) => DeepPartial<TOperation["rawResponse"]>);
  updater?: (RelayMutationUpdater<TOperation> | null) | undefined;
}

export type UseRelayMutationOptions<TOperation extends MutationParameters> =
  RelayMutationSharedOptions<TOperation> & {
    configs?: DeclarativeMutationConfig[];
    variables?: Partial<TOperation["variables"]>;
    allowWhileLoading?: boolean;
  };

export type RelayMutationCommitOptions<TOperation extends MutationParameters> =
  RelayMutationSharedOptions<TOperation> & {
    uploadables?: RNUploadableMap;
  };

export type UseMutationCommitFunctionType<
  TOperation extends MutationParameters
> = (
  variables?: TOperation["variables"],
  options?: RelayMutationCommitOptions<TOperation>
) => void;

type UseMutationReturnType<TOperation extends MutationParameters> = [
  UseMutationCommitFunctionType<TOperation>,
  {
    loading: boolean;
    response: (TOperation["rawResponse"] & TOperation["response"]) | null;
    errors: PayloadError[] | null | undefined;
  }
];

export function useRelayMutation<TOperation extends MutationParameters = never>(
  mutation: GraphQLTaggedNode,
  {
    onError: mutationOnError,
    onPayloadErrors: mutationOnPayloadErrors,
    onCompleted: mutationOnCompleted,
    onSuccess: mutationOnSuccess,
    optimisticResponse: mutationOptimisticResponse,
    optimisticUpdater,
    updater,
    configs,
    variables: baseVariables = {},
    allowWhileLoading = false
  }: UseRelayMutationOptions<TOperation> = {}
): UseMutationReturnType<TOperation> {
  const metadata = useRequestMetadata(mutation);
  const logger = useMemo(
    () => baseLogger.createLogger(`!(${metadata.operation.name})`),
    [metadata.operation.name]
  );
  useDebugValue(metadata.operation.name);
  const trackInvokeMutation = useEventer(
    "Invoke",
    metadata.operation.name,
    "Mutation"
  );
  const {
    onMutationError: orchestratorOnError,
    onMutationCompleted: orchestratorOnCompleted,
    payloadErrorTransformer
  } = useRelayOrchestrator();
  const environment = useRelayEnvironment();
  const [errors, setErrors] = useState<PayloadError[] | null | undefined>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<
    (TOperation["rawResponse"] & TOperation["response"]) | null
  >(null);
  const commit = useCallback<UseMutationCommitFunctionType<TOperation>>(
    (variables: TOperation["variables"] = baseVariables, options = {}) => {
      const {
        uploadables,
        onError: invokedOnError,
        onPayloadErrors: invokedOnPayloadErrors,
        onCompleted: invokedOnCompleted,
        onSuccess: invokedOnSuccess,
        optimisticResponse: invokedOptimisticResponse,
        optimisticUpdater: invokedOptimisticUpdater,
        updater: invokedUpdater
      } = options;
      let loading = false;
      const retry = () => commit(variables, options);
      const finalOptimisticResponse =
        invokedOptimisticResponse ?? mutationOptimisticResponse;
      if (!loading || allowWhileLoading) {
        loading = true;
        setLoading(true);
        const trackComplete = trackInvokeMutation({
          request: metadata.params as any,
          variables
        });
        logger.wrap(commitMutation)(environment, {
          mutation,
          variables: merge({}, baseVariables, variables),
          uploadables: uploadables as any,
          optimisticResponse:
            typeof finalOptimisticResponse === "function"
              ? finalOptimisticResponse(variables)
              : finalOptimisticResponse,
          optimisticUpdater: invokedOptimisticUpdater ?? optimisticUpdater,
          updater: invokedUpdater ?? updater,
          configs,
          onCompleted: (
            payloadResponse: TOperation["rawResponse"] & TOperation["response"],
            payloadErrors: readonly PayloadError[] | null | undefined
          ) => {
            loading = false;
            setLoading(false);
            setErrors(payloadErrors ? [...payloadErrors] : payloadErrors);
            setResponse(payloadResponse);
            trackComplete();

            const onCompletedCallbacks = [
              invokedOnCompleted,
              mutationOnCompleted,
              orchestratorOnCompleted,
              (response: unknown) => logger.info("onCompleted", response)
            ];

            const onSuccessCallbacks = [
              invokedOnSuccess,
              mutationOnSuccess,
              (response: unknown) => logger.info("onSuccess", response)
            ];

            const onPayloadErrorsCallbacks = [
              invokedOnPayloadErrors,
              mutationOnPayloadErrors,
              (errors: unknown) => logger.error("onPayloadErrors", errors)
            ];

            const onErrorCallbacks = [
              orchestratorOnError,
              mutationOnError,
              invokedOnError,
              (error: unknown) => logger.error("onError", error)
            ];

            if (!payloadErrors) {
              onSuccessCallbacks.forEach((cb) => cb?.(payloadResponse));
            }

            onCompletedCallbacks.forEach((cb) => cb?.(payloadResponse));
            if (payloadErrors) {
              payloadErrors = payloadErrors.map(payloadErrorTransformer);
              invokeCallbacks(
                onErrorCallbacks,
                new RelayRequestError(
                  metadata.operation,
                  variables,
                  payloadResponse,
                  payloadErrors
                ),
                retry
              );
              invokeCallbacks(onPayloadErrorsCallbacks, payloadErrors, retry);
            }
          },
          onError: (error: Error) => {
            const onErrorCallbacks = [
              orchestratorOnError,
              mutationOnError,
              invokedOnError,
              (error: unknown) => logger.error("onError", error)
            ];
            invokeCallbacks(
              onErrorCallbacks,
              new RelayRequestError(
                metadata.operation,
                variables,
                undefined,
                undefined,
                error
              ),
              retry
            );
            loading = false;
            setLoading(false);
          }
        });
      }
    },
    [
      JSON.stringify(baseVariables),
      JSON.stringify(mutationOptimisticResponse),
      allowWhileLoading,
      trackInvokeMutation,
      metadata.params,
      metadata.operation,
      environment,
      mutation
    ]
  );
  // if (requestError) throw requestError;
  return [commit, { loading, response, errors }];
}

export function useRelayMutationPromise<TOperation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  {
    onError,
    onSuccess,
    ...mutationOptions
  }: UseRelayMutationOptions<TOperation> = {}
) {
  const [commit, data] = useRelayMutation<TOperation>(
    mutation,
    mutationOptions
  );
  const commitPromise = useCallback(
    async (
      variables?: TOperation["variables"],
      options?: Pick<
        RelayMutationCommitOptions<TOperation>,
        | "uploadables"
        | "updater"
        | "optimisticUpdater"
        | "optimisticResponse"
        | "onSuccess"
        | "onError"
        | "onCompleted"
      >
    ) => {
      return await new Promise<
        TOperation["rawResponse"] & TOperation["response"]
      >((resolve, reject) => {
        commit(variables, {
          ...options,
          onSuccess: (res) => {
            resolve(res);
            onSuccess?.(res);
            options?.onSuccess?.(res);
          },
          onError: (err) => {
            reject(err);
            onError?.(err);
            options?.onError?.(err);
          }
        });
      });
    },
    [commit, onError, onSuccess]
  );

  return [commitPromise, data] as const;
}

function invokeCallbacks<P extends unknown[]>(
  callbacks: Array<((...args: P) => any | false) | undefined>,
  ...args: P
) {
  return compact(callbacks).reduce(
    // eslint-disable-next-line n/no-callback-literal
    (acc, cb) => (!acc || cb(...args) === false ? false : acc),
    true
  );
}
