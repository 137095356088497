import React, {
  createContext,
  useContext,
  forwardRef,
  type ComponentProps
} from "react";
import type { View } from "react-native";
import Column from "../quarks/Column";
import { type BreakpointName, useStyle, useTheme } from "../style";
import type { ThemeMedia } from "../style/theme/media";
import { useViewport } from "../style/viewport";

type Props = Pick<
  ComponentProps<typeof Column>,
  | "fill"
  | "grow"
  | "testID"
  | "gap"
  | "rowGap"
  | "columnGap"
  | "zIndex"
  | "onLayout"
  | "children"
  | "alignSelf"
> & {
  size: BreakpointName | "none";
};

const IsConstrainedContext = createContext(false);

export const IsConstrainedProvider = IsConstrainedContext.Provider;
export const useIsConstrained = () => useContext(IsConstrainedContext);

export const useConstraint = (size?: keyof ThemeMedia["size"] | "none") => {
  const isConstrained = useIsConstrained();
  const { media } = useTheme();
  const { width } = useViewport();

  const maxWidth =
    size && size !== "none" ? media.size[size].maxWidth : undefined;
  const style = useStyle(
    { width: "100%", maxWidth, marginHorizontal: "auto" },
    [maxWidth]
  );

  return size
    ? ([style, isConstrained || (!!maxWidth && width > maxWidth)] as const)
    : ([undefined, isConstrained] as const);
};

const Constraint = forwardRef<View, Props>(
  ({ size, children, ...rest }, ref) => {
    const [style, isConstrained] = useConstraint(size);
    return (
      <Column {...rest} style={style} ref={ref}>
        <IsConstrainedProvider value={isConstrained}>
          {children}
        </IsConstrainedProvider>
      </Column>
    );
  }
);

export default Constraint;
