import React from "react";
import type { ComponentProps, ReactNode } from "react";
import { ContentArea, RadioSquare, Tag } from "../atoms";
import type { IconName } from "../quarks";
import InputRow from "./InputRow";
import InputRowAccessory from "./InputRowAccessory";

export interface Props extends ComponentProps<typeof RadioSquare> {
  title: ComponentProps<typeof InputRow>["title"];
  note?: ComponentProps<typeof InputRow>["note"];
  onTooltipPress?: ComponentProps<typeof InputRow>["onTooltipPress"];
  onEditPress?: ComponentProps<typeof InputRow>["onEditPress"];
  icon?: IconName;
  iconSquare?: IconName;
  avatar?: string;
  disabledLabel?: string;
  children?: ReactNode;
  selectedChildren?: ReactNode;
}

export default function RadioButtonRow({
  testID,
  title,
  note,
  onTooltipPress,
  onEditPress,
  icon,
  iconSquare,
  avatar,
  selected,
  onSelect,
  disabled,
  disabledLabel,
  children,
  ...rest
}: Props) {
  children ??=
    disabled && disabledLabel ? (
      <ContentArea size="compact">
        <Tag label={disabledLabel} />
      </ContentArea>
    ) : null;

  return (
    <InputRow
      testID={testID}
      title={title}
      note={note}
      disabled={disabled}
      onPress={() => onSelect?.(!selected)}
      left={
        <InputRowAccessory
          icon={icon}
          iconSquare={iconSquare}
          avatar={avatar}
        />
      }
      onTooltipPress={onTooltipPress}
      onEditPress={onEditPress}
      right={
        <RadioSquare
          testID={`${testID}-radio`}
          selected={selected}
          onSelect={onSelect}
          disabled={disabled}
          {...rest}
        />
      }
      selectedChildren={selected && rest.selectedChildren}
    >
      {children}
    </InputRow>
  );
}
