import React, { type ReactNode } from "react";
import { CheckboxSquare, RadioSquare, Stack, Surface } from "../atoms";
import { Column } from "../quarks";
import { useStyles } from "../style";

interface Props {
  selected: boolean;
  multiple?: boolean;
  children?: ReactNode;
  wrap?: boolean;
  onChange?: (selected: boolean) => void;
  selectable?: boolean;
}

export default function SelectableListItem({
  wrap = true,
  selectable = true,
  multiple,
  selected,
  onChange,
  children
}: Props) {
  const styles = useStyles(() => ({
    square: { height: 52 }
  }));
  return (
    <Surface variant="outline">
      <Stack horizontal variant="divider" alignItems="stretch">
        {wrap ? (
          <Column alignSelf="center" fill>
            {children}
          </Column>
        ) : (
          children
        )}
        {!selectable ? (
          <Column style={styles.square} />
        ) : multiple ? (
          <CheckboxSquare
            testID="checkbox-entry"
            selected={selected}
            onChange={onChange}
          />
        ) : (
          <RadioSquare
            testID="radio-entry"
            eventTargetName="Radio"
            selected={selected}
            onSelect={() => onChange?.(true)}
          />
        )}
      </Stack>
    </Surface>
  );
}
