import { Platform } from "react-native";
import Rox, { type RoxFetcherResult } from "rox-browser";
export { default } from "rox-browser";
export type { RoxFetcherResult } from "rox-browser";

export const setup = async (
  configurationFetchedHandler: (fetcherResult: RoxFetcherResult) => void
) =>
  await Rox.setup(process.env.ROLLOUT_IO_APP_KEY ?? "", {
    version: Platform.OS,
    platform: Platform.OS,
    configurationFetchedHandler
  });
