import React from "react";
import type { ComponentProps } from "react";
import RadioSquare from "../atoms/RadioSquare";
import Stack from "../atoms/Stack";
import type { IconName } from "../quarks/Icon";
import InputRow from "./InputRow";
import InputRowAccessory from "./InputRowAccessory";

interface Props<T> {
  testID: string;
  title: ComponentProps<typeof InputRow>["title"];
  note?: ComponentProps<typeof InputRow>["note"];
  onTooltipPress?: ComponentProps<typeof InputRow>["onTooltipPress"];
  onEditPress?: ComponentProps<typeof InputRow>["onEditPress"];
  icon?: IconName;
  iconSquare?: IconName;
  avatar?: string;
  options?: Array<{ value: T; testID: string }>;
  value?: T;
  onChange: (val: T) => void;
}

export default function MultiSelectRow<T>({
  testID,
  title,
  note,
  onTooltipPress,
  onEditPress,
  icon,
  iconSquare,
  avatar,
  value,
  onChange,
  options
}: Props<T>) {
  return (
    <InputRow
      testID={testID}
      title={title}
      note={note}
      left={
        <InputRowAccessory
          avatar={avatar}
          icon={icon}
          iconSquare={iconSquare}
        />
      }
      onTooltipPress={onTooltipPress}
      onEditPress={onEditPress}
      right={
        <Stack horizontal variant="divider">
          {options?.map((option) => (
            <RadioSquare
              key={option.testID}
              testID={option.testID}
              eventTargetName="Multi Select Row Radio Button"
              selected={value === option.value}
              onSelect={() => onChange(option.value)}
            />
          ))}
        </Stack>
      }
    />
  );
}
