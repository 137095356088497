import React, { type ReactNode, type ComponentProps } from "react";

import Column from "../quarks/Column";

interface Props extends ComponentProps<typeof Column> {
  minWidth: number | undefined;
  children: ReactNode;
}

export default function MinWidthContainer({
  minWidth,
  children,
  ...rest
}: Props) {
  return (
    <Column {...rest} style={{ minWidth }}>
      {children}
    </Column>
  );
}
