import React, { useState } from "react";
import { Image } from "react-native";
import Column from "../quarks/Column";
import Icon from "../quarks/Icon";
import { useStyles } from "../style";

interface Props {
  uri: string;
  testID: string;
}

export default function ThumbnailImage({ uri, testID }: Props) {
  const [hasError, setHasError] = useState(false);
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      fallback: {
        backgroundColor: getColor("neutral", "fill", {
          opacity: 0.08
        }),
        height: 60,
        width: 60,
        borderRadius: 4,
        justifyContent: "center",
        alignItems: "center"
      },
      photo: {
        height: 60,
        width: 60,
        borderRadius: getUnits(1)
      }
    }),
    []
  );
  return hasError ? (
    <Column
      testID={testID}
      style={styles.fallback}
      justifyContent="center"
      alignItems="center"
    >
      <Icon name="image" color="primary" variant="solid" />
    </Column>
  ) : (
    <Image
      source={{ uri }}
      style={styles.photo}
      onError={() => setHasError(true)}
    />
  );
}
