import * as Sentry from "@sentry/react";
const buildNumber = process.env.PACKAGE_BUILD_NUMBER ?? "";

export const registerNavigationContainer = (nav: unknown) => {};

const urlTargets = [
  String(process.env.GRAPHQL_BASE_URL)
    .replace(/\/graphql$/, "/.*")
    .replace(/(https?:\/\/)api\./, "$1.*.")
];

const config: Sentry.BrowserOptions = {
  release: buildNumber,
  integrations: [
    Sentry.inboundFiltersIntegration({
      denyUrls: [/https?:\/\/(.*\.)?rollout\.io/i]
    }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.httpClientIntegration({ failedRequestTargets: urlTargets }),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration(),
    Sentry.reportingObserverIntegration(),
    Sentry.sessionTimingIntegration(),
    Sentry.linkedErrorsIntegration()
  ],
  tracePropagationTargets: urlTargets,
  _metadata: {
    sdk: {
      version: Sentry.SDK_VERSION
    }
  }
};

export default config;
