import {
  type DebounceSettings,
  type ThrottleSettings,
  debounce,
  throttle
} from "lodash";
import {
  type DependencyList,
  type Dispatch,
  type EffectCallback,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import useLatestCallback from "use-latest-callback";

export { useLatestCallback };

export const useDebouncedCallback = <T extends (...args: any[]) => any>(
  fn: T,
  ms = 450,
  options?: DebounceSettings
) => {
  const fnRef = useLatestCallback(fn);
  const debounced = useMemo(() => debounce(fnRef, ms, options), [fnRef]);
  useEffect(() => {
    return () => debounced.cancel();
  }, [debounced]);
  return debounced as unknown as T;
};

export const useThrottledCallback = <T extends (...args: any[]) => any>(
  fn: T,
  ms = 450,
  options?: ThrottleSettings
) => {
  const fnRef = useLatestCallback(fn);
  const debounced = useMemo(() => throttle(fnRef, ms, options), [fnRef]);
  useEffect(() => {
    return () => debounced.cancel();
  }, [debounced]);
  return debounced as unknown as T;
};

export function usePromisedValue<T>(
  fn: () => Promise<T>,
  defaultValue: T,
  deps: DependencyList = []
): T {
  const [value, setValue] = useState<T>(defaultValue);
  useEffect(() => {
    void fn().then(setValue);
  }, deps);
  return value;
}

type EffectOptions = {
  delay?: number;
  runOnMount?: boolean;
};

export const useDebouncedEffect = (
  fn: EffectCallback,
  deps?: DependencyList,
  options?: (EffectOptions & DebounceSettings) | number
) => {
  if (typeof options === "undefined") options = {};
  else if (typeof options === "number") options = { delay: options };

  const { delay = 300, runOnMount = false, ...rest } = options;
  const firstRun = useRef(!runOnMount);
  const debouncedFn = useDebouncedCallback(fn, delay, rest);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    return debouncedFn();
  }, deps);
};

export function useDebouncedState<T>(
  initialState: T | (() => T),
  delay?: number,
  options?: DebounceSettings
): [T, Dispatch<SetStateAction<T>>];
export function useDebouncedState<T = undefined>(): [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>
];
export function useDebouncedState<T = undefined>(
  initialState?: T,
  delay = 300,
  options?: DebounceSettings
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [value, setValue] = useState<T | undefined>(initialState);
  const setDebouncedValue = useDebouncedCallback(setValue, delay, options);
  return [value, setDebouncedValue];
}

export function useThrottledState<T>(
  initialState: T | (() => T),
  delay?: number,
  options?: ThrottleSettings
): [T, Dispatch<SetStateAction<T>>];
export function useThrottledState<T = undefined>(): [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>
];
export function useThrottledState<T = undefined>(
  initialState?: T,
  delay = 300,
  options?: ThrottleSettings
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [value, setValue] = useState<T | undefined>(initialState);
  const setDebouncedValue = useThrottledCallback(setValue, delay, options);
  return [value, setDebouncedValue];
}
