import {
  type ErrorSeverity,
  addAliasHook,
  addErrorHook,
  addIdentifyHook,
  addInitHook,
  addReportDeviceIdHook,
  addResetHook,
  addScreenHook,
  addTrackHook,
  collectHooks
} from "../hooks";
import { logger } from "../logger";

const c: Record<ErrorSeverity, typeof console.debug> = {
  fatal: logger.error,
  critical: logger.error,
  error: logger.error,
  warning: logger.warn,
  log: logger.log,
  info: logger.info,
  debug: logger.info
};

export default () => {
  return collectHooks([
    addReportDeviceIdHook((...args) =>
      c.info("[dekigoto:console:reportDeviceId]", ...args)
    ),
    addInitHook((...args) => c.info("[dekigoto:console:init]", ...args)),
    addResetHook((...args) => c.info("[dekigoto:console:reset]", ...args)),
    addIdentifyHook((...args) =>
      c.info("[dekigoto:console:identify]", ...args)
    ),
    addAliasHook((...args) => c.info("[dekigoto:console:alias]", ...args)),
    addScreenHook((...args) => c.info("[dekigoto:console:screen]", ...args)),
    addTrackHook((...args) => c.info("[dekigoto:console:track]", ...args)),
    addErrorHook((error, severity = "error") => {
      c[severity]("[dekigoto:console:error]", error, "\n", error.stack);
    })
  ]);
};
