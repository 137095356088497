import React from "react";
import { Pressable } from "react-native";
import { Icon, Text, getSpaceUnits } from "../../quarks";
import { useStyles } from "../../style";

type Props = {
  cellWidth: number;
  label: string | null;
  sort?: "asc" | "desc" | null;
  onPress?: () => void;
};

export function CellHeader({ cellWidth, label, sort, onPress }: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      flexDirection: "row",
      backgroundColor: getColor("foreground", "fill"),
      paddingHorizontal: getSpaceUnits("standard"),
      paddingVertical: 6,
      height: 44,
      gap: getSpaceUnits("slim"),
      alignItems: "center"
    }
  }));

  return (
    <Pressable
      style={[styles.container, { width: cellWidth }]}
      onPress={onPress}
      disabled={!onPress}
    >
      <Text
        color={sort ? "primary" : "neutral"}
        weight="bold"
        numberOfLines={2}
      >
        {label?.toUpperCase()}
      </Text>
      {!!sort && (
        <Icon
          align="center"
          size="small"
          variant="solid"
          color="primary"
          name={sort === "asc" ? "caret-up" : "caret-down"}
        />
      )}
    </Pressable>
  );
}
