import type { DateTime } from "luxon";
import React from "react";
import { View } from "react-native";
import { Column, Row, Text } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import { getPeriodDates } from "./helpers";
import type { PeriodType } from "./types";

interface Props {
  date: DateTime;
  period: Exclude<PeriodType, "month">;
}

export default function SectionListCalendarHeaderDayContainer({
  date,
  period
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      weekDayColumn: {
        paddingVertical: isSm ? undefined : getUnits(2),
        minHeight: 50,
        ...(isSm &&
          period === "week" && {
            minWidth: getUnits(19),
            maxWidth: getUnits(19)
          })
      },
      circleContainer: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: getColor("primary", "fill"),
        alignItems: "center",
        justifyContent: "center"
      }
    }),
    [isSm]
  );

  const { day, date: num, isToday } = getPeriodDates("day", date)?.[0] || {};

  return (
    <Column
      justifyContent="center"
      alignItems="center"
      fill={1}
      style={styles.weekDayColumn}
    >
      <Row gap="slim">
        <Column justifyContent="center">
          <Text variant="subheader" color="primary">
            {day}
          </Text>
        </Column>
        <Column justifyContent="center">
          <View style={isToday && styles.circleContainer}>
            <Text weight="bold" color={isToday ? "white" : "primary"}>
              {num}
            </Text>
          </View>
        </Column>
      </Row>
    </Column>
  );
}
