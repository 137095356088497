import { DateTime } from "luxon";
import React, { type ReactNode, Fragment } from "react";
import { Divider } from "../../quarks";
import type { PeriodDay, PeriodType } from "./types";

export type EventMap<T> = Map<string, T[]>;

export type DayRenderer<T> = (day: PeriodDay, items: T[]) => ReactNode;

export const DAYS_OF_WEEK = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];

export function calendarKey(value: DateTime) {
  return value?.toFormat("yyyy-MM-dd");
}

function isToday(date: DateTime) {
  return date.hasSame(DateTime.local(), "day");
}

export function getPeriodDates(
  period: PeriodType,
  date: DateTime
): PeriodDay[] | undefined {
  if (period === "day") {
    const day = date.toFormat("ccc").toUpperCase();
    const num = date.toFormat("dd");

    return [
      {
        day,
        date: num,
        actualDate: date,
        isToday: isToday(date)
      }
    ];
  }

  if (period === "week") {
    const startOfWeek = date.startOf("week").minus({ days: 1 });
    const today = DateTime.local().day;

    const weekDays = Array.from({ length: 7 }, (_, i) => {
      const day = startOfWeek.plus({ days: i });
      return {
        day: day.toFormat("ccc").toUpperCase(),
        date: day.toFormat("d"),
        isToday: isToday(day),
        actualDate: day
      };
    });

    return weekDays;
  }
}

export function getMonthDates(date: DateTime): PeriodDay[][] {
  const firstDayOfMonth = date.startOf("month");
  const startDateFirstWeek = firstDayOfMonth.minus({
    days: firstDayOfMonth.weekday % 7
  });
  const today = DateTime.local().day;
  const currentMonth = date.month;

  const weeks: PeriodDay[][] = [];
  let currentDate = startDateFirstWeek;

  for (let week = 0; week < 6; week++) {
    const weekDays: PeriodDay[] = [];

    for (let day = 0; day < 7; day++) {
      weekDays.push({
        key: calendarKey(currentDate),
        day: currentDate.toFormat("ccc").toUpperCase(),
        date: currentDate.toFormat("d"),
        actualDate: currentDate,
        isToday: isToday(currentDate),
        isSameMonth: currentDate.month === currentMonth
      });

      currentDate = currentDate.plus({ days: 1 });
    }

    weeks.push(weekDays);

    if (currentDate.month !== currentMonth && week >= 4) {
      const nextWeekHasCurrentMonth =
        currentDate.plus({ days: 6 }).month === currentMonth;
      if (!nextWeekHasCurrentMonth) {
        break;
      }
    }
  }

  return weeks;
}

export function renderWithDivider<T>(
  arr: T[] | readonly T[],
  dir: "x" | "y",
  renderFn: (it: T) => JSX.Element
) {
  return arr.map((it, idx) => {
    return (
      <Fragment key={idx}>
        {idx !== 0 && <Divider dir={dir} color="background" />}
        {renderFn(it)}
      </Fragment>
    );
  });
}

export function createEventMap<T>(obj: Record<string, T[]>): EventMap<T> {
  return new Map(Object.entries(obj));
}
