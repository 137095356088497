import React, { type ReactNode } from "react";
import { createPropsPortal } from "./create-props-portal";

export interface ChildrenProps {
  children: ReactNode;
}

export interface ExitProps {
  children?: ReactNode;
}

export function createComponentPortal() {
  const {
    Realm,
    Entrance,
    Exit: PropsExit
  } = createPropsPortal<ChildrenProps>({ children: null });

  const Exit = ({ children: defaultChildren }: ExitProps) => (
    <PropsExit>
      {({ children }) => children ?? defaultChildren ?? null}
    </PropsExit>
  );

  return {
    Realm,
    Entrance,
    Exit
  };
}
