import {
  DisableRudderStackFirehoseEvents,
  DisableRudderStackScreen
} from "@gigsmart/feature-flags";
import { getOriginalDisplayName } from "@gigsmart/hoc-utils";
import { type ApiObject, RudderAnalytics } from "@rudderstack/analytics-js";
import { noop } from "lodash";
import {
  addAliasHook,
  addIdentifyHook,
  addReportDeviceIdHook,
  addResetHook,
  addScreenHook,
  addTrackHook,
  collectHooks
} from "../../hooks";
import { getConfig } from "./config";

const rudderanalytics = new RudderAnalytics();

let load = async () => {
  const { WRITE_KEY, DATA_PLANE_URL } = await getConfig();
  if (!WRITE_KEY || !DATA_PLANE_URL) {
    return Promise.reject(new Error("Missing RudderStack config"));
  }
  const domain = window.location.host.endsWith("gigsmart.com")
    ? "gigsmart.com"
    : window.location.host;
  rudderanalytics.load(WRITE_KEY, DATA_PLANE_URL, {
    useBeacon: true,
    storage: {
      type: "localStorage",
      migrate: true,
      entries: {
        initialReferringDomain: { type: "cookieStorage" },
        initialReferrer: { type: "cookieStorage" },
        anonymousId: { type: "cookieStorage" }
      },
      cookie: {
        domain,
        secure: true,
        samesite: "Strict"
      }
    }
  });
  const res = new Promise<void>((resolve) => rudderanalytics.ready(resolve));
  load = () => res;
  return await res;
};

export default async () => {
  if (process.env.IS_TESTING === "true") return noop;

  await load();

  return collectHooks([
    addTrackHook(async (event, properties, category) => {
      if (
        category !== "conversion" &&
        DisableRudderStackFirehoseEvents.isEnabled() &&
        !properties.track
      ) {
        return;
      }
      rudderanalytics.track(event, properties as ApiObject);
    }),

    addReportDeviceIdHook(async (deviceId) => {
      rudderanalytics?.setAnonymousId(deviceId);
    }),

    addScreenHook(async (name, properties, Component) => {
      if (DisableRudderStackScreen.isEnabled()) return;
      const componentDisplayName =
        Component && getOriginalDisplayName(Component);
      if (typeof componentDisplayName !== "undefined") {
        properties.name = componentDisplayName;
      }
      rudderanalytics?.page("screen", name, properties as ApiObject);
    }),

    addAliasHook(async (prevUserId, userId) => {
      rudderanalytics?.alias(userId, prevUserId ?? undefined);
    }),

    addIdentifyHook(async (userId, traits) => {
      if (!userId) return;
      rudderanalytics?.alias(userId);
      // @ts-expect-error ???
      rudderanalytics?.identify(userId, traits);
    }),

    addResetHook(() => rudderanalytics.reset())
  ]);
};
