import React, { type ComponentProps, useMemo, type ReactNode } from "react";
import {
  type RecursiveArray,
  type RegisteredStyle,
  Text,
  type TextStyle
} from "react-native";
import { type BreakpointStylesFn, useStyles } from "../style";

const defaults: BreakpointStylesFn = ({ font }) => ({
  text: {
    ...font.body("regular"),
    lineHeight: font.size.bodyCopy * font.lineHeight.body
  },
  paragraph: {
    marginBottom: 5,
    marginTop: 5
  },
  italic: {
    fontStyle: "italic"
  },
  bold: {
    ...font.body("bold")
  },
  semibold: {
    ...font.body("semibold")
  },
  center: {
    textAlign: "center"
  },
  underline: {
    textDecorationLine: "underline"
  },
  strikeThrough: {
    textDecorationLine: "line-through"
  },
  underlineAndStrikeThrough: {
    textDecorationLine: "underline line-through"
  },
  largePhoneText: {
    "@phone": {
      fontSize: font.size.large
    }
  },
  boldPhoneText: {
    "@phone": {
      fontWeight: font.weight.bold
    }
  }
});

export type Props = ComponentProps<typeof Text> & {
  children: ReactNode;
  color?: string;
  fontSize?: number;
  paragraph?: boolean;
  center?: boolean;
  bold?: boolean;
  semibold?: boolean;
  italic?: boolean;
  strikeThrough?: boolean;
  underline?: boolean;
  noMargin?: boolean;
  largePhoneText?: boolean;
  boldPhoneText?: boolean;
};

export default (
  overrides: BreakpointStylesFn = () => ({}),
  defaultProps: Partial<Props> = {}
) => {
  const TextElement = ({
    style,
    children,
    color,
    paragraph,
    center,
    bold,
    italic,
    underline,
    strikeThrough,
    fontSize,
    testID,
    noMargin,
    selectable,
    largePhoneText,
    boldPhoneText,
    semibold,
    ...props
  }: Props) => {
    const { styles, theme } = useStyles((theme) => ({
      ...defaults(theme),
      ...overrides(theme)
    }));
    const memoizedStyles = useMemo(() => {
      const preparedStyles: RecursiveArray<
        TextStyle | undefined | null | false | RegisteredStyle<TextStyle>
      > = [
        styles.text,
        typeof color === "string" ? { color: theme.color.getColor(color) } : {}
      ];
      if (paragraph) preparedStyles.push(styles.paragraph);
      if (center) preparedStyles.push(styles.center);
      if (bold) preparedStyles.push(styles.bold);
      if (semibold) preparedStyles.push(styles.semibold);
      if (italic) preparedStyles.push(styles.italic);
      if (underline) preparedStyles.push(styles.underline);
      if (strikeThrough) preparedStyles.push(styles.strikeThrough);
      if (underline && strikeThrough) {
        preparedStyles.push(styles.underlineAndStrikeThrough);
      }
      if (largePhoneText) preparedStyles.push(styles.largePhoneText);
      if (boldPhoneText) preparedStyles.push(styles.boldPhoneText);
      if (typeof fontSize !== "undefined") {
        preparedStyles.push({
          fontSize: fontSize ?? 14,
          lineHeight: fontSize * theme.font.lineHeight.body
        });
      }
      if (noMargin) {
        preparedStyles.push({
          margin: 0,
          marginVertical: 0,
          marginHorizontal: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          marginBottom: 0
        });
      }
      preparedStyles.push(style);
      return preparedStyles;
    }, [
      styles.text,
      styles.paragraph,
      styles.center,
      styles.bold,
      styles.semibold,
      styles.italic,
      styles.underline,
      styles.strikeThrough,
      styles.largePhoneText,
      styles.boldPhoneText,
      styles.underlineAndStrikeThrough,
      color,
      theme.color,
      theme.font.lineHeight.body,
      paragraph,
      center,
      bold,
      semibold,
      italic,
      underline,
      strikeThrough,
      largePhoneText,
      boldPhoneText,
      fontSize,
      noMargin,
      style
    ]);

    return (
      <Text
        selectable={selectable}
        allowFontScaling={false}
        testID={testID}
        {...props}
        style={memoizedStyles}
      >
        {children}
      </Text>
    );
  };

  TextElement.defaultProps = {
    color: "black",
    ...defaultProps
  };

  return TextElement;
};
