import React from "react";
import { View } from "react-native";
import { Surface } from "../atoms";
import {
  Icon,
  type IconName,
  type IconSize,
  Row,
  Text,
  type TextVariant,
  type UnitSize,
  getSpaceUnits
} from "../quarks";
import { useStyles } from "../style";

type Props = {
  icon?: IconName;
  label: string;
  verticalPadding?: UnitSize;
  horizontalPadding?: UnitSize;
  textVariant?: TextVariant;
  iconSize?: IconSize;
};

export default function FadedContainer({
  label,
  icon,
  verticalPadding = "slim",
  horizontalPadding = "compact",
  textVariant = "noteSm",
  iconSize = "xtiny"
}: Props) {
  const styles = useStyles(
    ({ getUnits }) => ({
      container: {
        position: "relative",
        width: "100%",
        paddingLeft: getSpaceUnits(horizontalPadding),
        paddingRight: getUnits(1),
        paddingVertical: getSpaceUnits(verticalPadding)
      },
      textContainer: {
        width: "100%",
        paddingRight: getUnits(2),
        overflow: "hidden"
      },
      text: {
        whiteSpace: "nowrap",
        display: "flex"
      },
      fadeOverlay: {
        position: "absolute",
        top: 0,
        right: getUnits(1),
        bottom: 0,
        width: getUnits(2),
        background: `linear-gradient(90deg,
          rgba(255, 255, 255, 0) 0%,
          rgb(255, 255, 255) 77%
        )`,
        borderTopRightRadius: getUnits(2),
        borderBottomRightRadius: getUnits(2)
      }
    }),
    []
  );

  return (
    <Surface variant="flat">
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Row gap="slim" alignItems="center">
            {icon && <Icon size={iconSize} name={icon} />}
            <Text weight="bold" style={styles.text} variant={textVariant}>
              {label}
            </Text>
          </Row>
        </View>
        <View style={styles.fadeOverlay} pointerEvents="none" />
      </View>
    </Surface>
  );
}
