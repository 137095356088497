import React from "react";

import Row from "../quarks/Row";
import { useStyle } from "../style/styles";
import type { OnlyChildren } from "../utils/types";

type Props = OnlyChildren;

export default function ScreenHeaderRow({ children }: Props) {
  const rowStyle = useStyle(({ getUnits }) => ({
    paddingBottom: getUnits(8),
    width: "100%"
  }));
  return (
    <Row justifyContent="space-between" style={rowStyle}>
      {children}
    </Row>
  );
}
