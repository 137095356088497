import { Platform } from "react-native";
import createStyleableTextElement from "./create-styleable-text-element";

export const StyledHeader1 = createStyleableTextElement(({ color, font }) => ({
  text: {
    ...font.header("semibold"),
    fontSize: font.size.heading1,
    lineHeight: font.size.heading1 * font.lineHeight.header,
    marginVertical: font.size.largest * 0.5,
    textAlign: "center",
    color: color.blue
  }
}));

export const StyledHeader2 = createStyleableTextElement(({ color, font }) => ({
  text: {
    ...font.header("semibold"),
    fontSize: font.size.extraLarge,
    lineHeight: font.size.extraLarge * font.lineHeight.header,
    marginVertical: font.size.extraLarge * 0.5,
    textAlign: Platform.OS === "web" ? "left" : "center",
    color: color.blue
  }
}));

export const StyledHeader3 = createStyleableTextElement(({ color, font }) => ({
  text: {
    ...font.header("semibold"),
    fontSize: font.size.large,
    lineHeight: font.size.large * font.lineHeight.header,
    color: color.black,
    marginVertical: font.size.large * 0.5,
    textAlign: Platform.OS === "web" ? "left" : "center"
  }
}));

export const StyledHeader4 = createStyleableTextElement(({ color, font }) => ({
  text: {
    ...font.header("semibold"),
    fontSize: font.size.medium,
    lineHeight: font.size.medium * font.lineHeight.header,
    marginVertical: font.size.medium * 0.5,
    textAlign: Platform.OS === "web" ? "left" : "center",
    color: color.blue
  }
}));

export const StyledHeaderXL = createStyleableTextElement(({ color, font }) => ({
  text: {
    ...font.header("semibold"),
    fontSize: font.size.title,
    lineHeight: font.size.title * font.lineHeight.header,
    marginVertical: font.size.title * 0.5,
    textAlign: Platform.OS === "web" ? "left" : "center",
    color: color.blue
  }
}));
