import type { ReactNode } from "react";
import type { IconName } from "../../quarks";
import type { Color } from "../../style/theme/colors";
import createEventStore from "../../utils/createEventStore";

type ToastDetail = ReactNode;

export interface ToastEvent {
  label?: string;
  message: ToastDetail | { title: string; detail: ToastDetail };
  sticky?: boolean;
  dismissable?: boolean;
  onPress?: () => void;
  onDismiss?: () => void;
  dismissAfter?: number;
  testID?: string;
  color: Color;
  quick?: boolean;
  delayMs?: number;
  icon?: IconName;
  exclusive?: boolean;
}

export type ToastSelector =
  | { label: string }
  | { all: boolean }
  | { id: number };

export default createEventStore<{
  toast: ToastEvent;
  dismiss: ToastSelector;
  block: ToastSelector;
  unblock: ToastSelector;
}>();
