import React, { type ReactNode } from "react";
import { View } from "react-native";
import { type TextStyle, useStyle } from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";
import type { Color } from "../style/theme/colors";

type SIZE = "small" | "medium" | "large";
type VARIANTS = "well";

const sizeLookup = {
  small: 5,
  medium: 8,
  large: 10
};
interface Props {
  children?: ReactNode;
  color?: Color;
  variant?: VARIANTS;
  size?: SIZE;
  textAlign?: TextStyle["textAlign"];
  testID?: string;
}

export default function CardHeader({
  children,
  color = "neutral",
  variant,
  size = "small",
  textAlign = "left",
  testID = "card-header"
}: Props) {
  const viewStyle = useStyle(
    ({ getUnits, getColor }) => ({
      backgroundColor: getColor(color, "fill", {
        opacity:
          variant === "well"
            ? color !== "surface"
              ? 0.08
              : undefined
            : undefined
      }),
      borderTopLeftRadius: getUnits(1),
      borderTopRightRadius: getUnits(1),
      paddingVertical: getUnits(1),
      paddingHorizontal: getUnits(4),
      justifyContent: "center",
      minHeight: getUnits(sizeLookup[size]),
      textAlign
    }),
    [color, variant, size]
  );

  return (
    <ColorPlacementProvider color={variant === "well" ? "surface" : color}>
      <View style={viewStyle} testID={testID}>
        {children}
      </View>
    </ColorPlacementProvider>
  );
}
