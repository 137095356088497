import React, { type ReactComponentElement } from "react";
import type Bubble from "../atoms/Bubble";
import type SelectableBubble from "../atoms/SelectableBubble";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import { useStyle } from "../style";

interface Props {
  Bubbles: Array<
    | ReactComponentElement<typeof SelectableBubble>
    | ReactComponentElement<typeof Bubble>
  >;
}

export default function BubbleContainer({ Bubbles }: Props) {
  const BubbleWrapper = useStyle(({ getUnits }) => ({
    paddingVertical: getUnits(1),
    paddingRight: getUnits(2)
  }));

  return (
    <Row wrap="wrap">
      {Bubbles.map((Bubble, index) => (
        <Column
          style={BubbleWrapper}
          key={`${index}`}
          testID={`Bubble-wrapper-${index}`}
        >
          {Bubble}
        </Column>
      ))}
    </Row>
  );
}
