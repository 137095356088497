import React, { type ComponentProps } from "react";
import Text from "../quarks/Text";

export default function MaybeText({
  children,
  ...props
}: ComponentProps<typeof Text>) {
  if (!["string", "number"].includes(typeof children)) return <>{children}</>;
  return <Text {...props}>{children}</Text>;
}
