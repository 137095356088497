import { Persistence } from "@gigsmart/atorasu";
import type { RelayResponse } from "@gigsmart/relay";

const key = "auth";

export const getAuthToken = async () => {
  return await Persistence.load<string>(key).catch(() => null);
};

export const setAuthToken = async (token: string | null | undefined) => {
  await Persistence.save(key, token);
};

export const refreshAuthToken = async (_: any, res: RelayResponse) => {
  // TODO: check headers type
  if ((res?.headers as any).get) {
    return (res.headers as any).get("next-valid-token");
  }
  return res.headers?.["next-valid-token"];
};

export const clearAuthToken = async () => await setAuthToken(null);

export const isUnauthError = (res?: RelayResponse | null) =>
  !!res?.errors && res.errors.some((err) => err.message === "unauthenticated");
