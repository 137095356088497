import React, { type ReactNode } from "react";
import { Platform, View } from "react-native";
import { FastImage } from "../image";
import {
  type ImageStyleProp,
  type TextStyleProp,
  type ViewStyleProp,
  useStyles
} from "../style";
import StyledText from "../text/styled-text";
import StyledIcon, { type IconName, type IconVariant } from "./styled-icon";

interface Props {
  iconName?: IconName;
  testID?: string;
  iconColor: string;
  labelColor?: string;
  label: ReactNode;
  containerStyle?: ViewStyleProp;
  selectable?: boolean;
  iconStyle?: TextStyleProp;
  iconBadgeText?: string;
  iconBadgeContainerStyle?: ViewStyleProp;
  labelStyle?: TextStyleProp;
  variant?: IconVariant;
  fontSize?: number;
  labelNumberOfLines?: number;
  imageUrl?: string;
  imageId?: string;
}

export default function IconDescription({
  iconName,
  iconColor,
  containerStyle,
  labelColor,
  label,
  selectable,
  iconStyle,
  testID,
  iconBadgeText,
  iconBadgeContainerStyle,
  labelStyle,
  variant,
  fontSize,
  labelNumberOfLines,
  imageUrl,
  imageId
}: Props) {
  const { styles, theme } = useStyles(
    ({ color, font }) => ({
      row: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 2
      },
      icon: {
        fontSize: font.size.small,
        color: color.getColor(iconColor),
        minWidth: 14,
        textAlign: "center"
      },
      label: {
        ...(typeof labelColor === "string"
          ? { color: color.getColor(labelColor) }
          : {}),
        paddingLeft: 4
      },
      badgeContainer: {
        borderRadius: 9,
        height: 18,
        minWidth: 18,
        justifyContent: "center",
        backgroundColor: color.orange,
        position: "absolute"
      },
      badge: {
        color: color.white,
        fontSize: font.size.smallest,
        textAlign: "center",
        marginHorizontal: 3,
        top: Platform.OS === "ios" ? 1 : 0
      },
      imageStyles: {
        height: 35,
        borderRadius: 50,
        width: 35
      }
    }),
    [iconColor, labelColor]
  );

  let styledLabel;
  if (typeof label === "string") {
    styledLabel = (
      <StyledText
        fontSize={fontSize ?? theme.font.size.large}
        style={[
          styles.label,
          labelStyle,
          typeof labelColor === "string" && {
            color: theme.color.getColor(labelColor)
          }
        ]}
        selectable={selectable}
        numberOfLines={labelNumberOfLines}
      >
        {label}
      </StyledText>
    );
  }

  return (
    <View style={[styles.row, containerStyle]} testID={testID}>
      {imageUrl ? (
        <FastImage
          imageId={imageId}
          source={{ uri: imageUrl }}
          style={styles.imageStyles as ImageStyleProp}
        />
      ) : iconName ? (
        <StyledIcon
          name={iconName}
          style={[
            styles.icon,
            iconStyle,
            typeof iconColor === "string" && {
              color: theme.color.getColor(iconColor)
            }
          ]}
          variant={variant}
        />
      ) : null}

      {Boolean(iconBadgeText) && (
        <View style={[styles.badgeContainer, iconBadgeContainerStyle]}>
          <StyledText
            style={styles.badge}
            testID={testID ? `${testID}-label` : undefined}
          >
            {iconBadgeText}
          </StyledText>
        </View>
      )}
      {typeof label === "string" && (styledLabel ?? label)}
    </View>
  );
}

IconDescription.defaultProps = {
  iconColor: "blue"
};
