import React, { type ComponentProps } from "react";
import ContentArea from "../../atoms/ContentArea";
import LoadingView from "../../atoms/LoadingView";
import ListEmpty from "../../molecules/ListEmpty";
import ListEmptySearch from "../../molecules/ListEmptySearch";
import Spacer from "../../quarks/Spacer";

interface Props extends ComponentProps<typeof ListEmpty> {
  loading?: boolean;
  searchTerm?: string | null;
}

export function TableEmptyView({
  loading,
  searchTerm,
  ...listEmptyProps
}: Props) {
  const empty = loading ? (
    <LoadingView />
  ) : searchTerm ? (
    <ContentArea>
      <ListEmptySearch searchTerm={searchTerm} />
    </ContentArea>
  ) : (
    <ListEmpty {...listEmptyProps} />
  );
  return (
    <>
      <Spacer size="xlarge" />
      {empty}
    </>
  );
}
