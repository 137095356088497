import { noop } from "lodash";
import React from "react";
import { Linking } from "react-native";
import { showEmbeddedBrowser } from "./EmbeddedBrowser";
import InfoElement, { type Props as InfoElementProps } from "./InfoElement";
import { useModalContext } from "./ModalContext";

type Props = InfoElementProps & {
  title?: string;
  eventContext?: string | null;
  url: string;
};
export default function WebInfoModal({
  testID,
  eventContext = null,
  title,
  url,
  ...props
}: Props) {
  const { onRequestClose } = useModalContext();
  const openModal = async () =>
    onRequestClose === noop
      ? showEmbeddedBrowser({ title, url })
      : await Linking.openURL(url);

  return (
    <InfoElement
      {...props}
      testID={testID}
      title={title}
      openModal={openModal}
    />
  );
}

WebInfoModal.defaultProps = {
  variant: "icon"
};
