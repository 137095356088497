import { EventContext } from "@gigsmart/dekigoto";
import { FreshLook } from "@gigsmart/feature-flags";
import React, { type ReactNode } from "react";
import { Surface } from "../atoms";
import { ScrollView, useSafeAreaInsets } from "../quarks";
import { useStyles } from "../style";

interface Props {
  children?: ReactNode;
}

export default function AppMenu({ children }: Props) {
  const inset = useSafeAreaInsets();
  const styles = useStyles(({ getColor }) => ({
    container: FreshLook.isEnabled()
      ? {
          borderRightColor: getColor("background", "fill"),
          borderRightWidth: 1
        }
      : {}
  }));

  return (
    <EventContext name="Menu" noScreen>
      <Surface
        fill
        color={FreshLook.isEnabled() ? "surface" : "primary"}
        variant="flat"
        topRadius="none"
        bottomRadius="none"
      >
        <ScrollView
          testID="menu-scroller"
          style={styles.container}
          contentContainerStyle={{ paddingBottom: inset.bottom }}
        >
          {children}
        </ScrollView>
      </Surface>
    </EventContext>
  );
}
