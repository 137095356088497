import { getDeviceId } from "@gigsmart/isomorphic-shared/app/device-id.web";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { Buffer } from "buffer/";
import type GraphiQL from "graphiql";
import { useAsyncMemo } from "libraries/atorasu";
import React, {
  createContext,
  type ReactNode,
  useState,
  type ComponentProps,
  useContext,
  useMemo
} from "react";

type Capabilities = Record<string, boolean>;
type Token = string | null;

export interface GraphQLParams {
  query: string;
  operationName: string;
  variables?: any;
}

interface ContextShape {
  fetcher: ComponentProps<typeof GraphiQL>["fetcher"];
  setCapabilities: (capabilities: Capabilities) => void;
  capabilities: Capabilities;
  setToken: (token: Token) => void;
  token: Token;
}

const Context = createContext<ContextShape>({
  fetcher: async () => await Promise.reject(new Error("Not configured")),
  setCapabilities: () => {
    throw new Error("Not Implemented");
  },
  capabilities: {},
  setToken: () => {
    throw new Error("Not Implemented");
  },
  token: null
});

function useLocalStorageState<T>(key: string, defaultValue: T) {
  const storeItem = localStorage.getItem(`~${key}`);
  const initialValue =
    storeItem !== null ? (JSON.parse(storeItem) as T) : defaultValue;
  const [value, setValue] = useState<T>(initialValue);
  return [
    value,
    (nextValue: T) => {
      setValue(nextValue);
      localStorage.setItem(`~${key}`, JSON.stringify(nextValue));
    }
  ] as const;
}

export function useRequestContext() {
  return useContext(Context);
}

export function Provider({ children }: { children: ReactNode }) {
  const [token, setToken] = useLocalStorageState<Token>("token", null);
  const [capabilities, setCapabilities] = useLocalStorageState<Capabilities>(
    "capabilities",
    {}
  );
  const headers = useAsyncMemo<Record<string, string>>(
    async () => ({
      "Content-Type": "application/json",
      "GigSmart-Device-Id": `${(await getDeviceId()).id}-playground`,
      "GigSmart-Capabilities": Buffer.from(
        JSON.stringify(capabilities)
      ).toString("base64"),
      ...(token ? { Authorization: `Bearer ${token}` } : {})
    }),
    [token, capabilities]
  );

  const fetcher = useMemo<ContextShape["fetcher"]>(() => {
    const url = process.env?.GRAPHQL_BASE_URL ?? "";
    const subscriptionUrl = process.env?.GRAPHQL_BASE_SOCKET_URL ?? "";

    return createGraphiQLFetcher({
      url,
      subscriptionUrl,
      headers,
      wsConnectionParams: headers,
      enableIncrementalDelivery: true
    });
  }, [headers]);

  return (
    <Context.Provider
      value={{
        fetcher,
        setCapabilities,
        capabilities,
        setToken,
        token
      }}
    >
      {children}
    </Context.Provider>
  );
}
