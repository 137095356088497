import type { SetImplFn } from "../registry";

interface WebPermissionSpec {
  name: PermissionName | "camera";
  deviceAction: () => Promise<void>;
}

export default function defaultImpl({
  name,
  deviceAction
}: WebPermissionSpec): Parameters<SetImplFn>[0] {
  const check = async () => {
    const { state } = await navigator.permissions.query({ name: name as any });
    if (state === "prompt") return "denied";
    return state;
  };

  return {
    check,
    request: async () => {
      await deviceAction();
      return check();
    },
    openSettings: false
  };
}
