import React from "react";
import { View } from "react-native";
import Text from "../quarks/Text";
import { useStyles } from "../style";

interface Props {
  label: string;
  testID?: string;
  multiline?: boolean;
  error?: boolean;
}

const InputTag = ({ label, testID = "input-tag", multiline, error }: Props) => {
  const styles = useStyles(({ getUnits, getColor }) => ({
    container: {
      borderRadius: getUnits(1),
      padding: getUnits(1),
      marginRight: getUnits(2),
      backgroundColor: getColor("surface", "fill", { opacity: 0.55 })
    },
    containerError: { marginRight: 0 },
    containerMultiline: {
      position: "absolute",
      right: getUnits(2),
      bottom: getUnits(2),
      marginRight: 0
    }
  }));

  return (
    <View
      style={[
        styles.container,
        multiline && styles.containerMultiline,
        error && styles.containerError
      ]}
    >
      <Text variant="note" color="neutral" testID={testID}>
        {label}
      </Text>
    </View>
  );
};

export default InputTag;
