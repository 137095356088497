import React, { Component, type ReactNode, useContext } from "react";
import { RRNLError } from "react-relay-network-modern/es";
import {
  type RelayOrchestratorProp,
  RelayRequestError,
  relayOrchestratorStubs
} from "../orchestrator";
import { withRelayOrchestrator } from "../orchestrator/with-relay-orchestrator";

interface Props {
  children: NonNullable<ReactNode> | null;
  ErrorComponent?: React.ComponentType<{ error: Error }> | null | false;
  onError?: (err: Error) => unknown;
  relay: RelayOrchestratorProp;
}

interface State {
  error: Error | null;
}

function shouldIgnoreError(error: any) {
  return !(error instanceof RRNLError || error instanceof RelayRequestError);
}

let RelayErrorPage: Props["ErrorComponent"] = null;
export const getDefaultErrorPage = () => RelayErrorPage;
export const setDefaultErrorPage = (_: NonNullable<Props["ErrorComponent"]>) =>
  (RelayErrorPage = _);

const IsErrorFallback = React.createContext<boolean>(false);

export const useIsErrorFallback = () => {
  return useContext(IsErrorFallback);
};

@withRelayOrchestrator
export default class RelayErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    ...relayOrchestratorStubs,
    ErrorComponent: null
  };

  static getDerivedStateFromError(error: Error) {
    return { error: shouldIgnoreError(error) ? null : error };
  }

  state: State = { error: null };

  componentDidCatch(error: any, _info: unknown) {
    const { relay, onError = relay.onQueryError } = this.props;
    if (shouldIgnoreError(error)) throw error;
    if (onError) onError(error);
  }

  render() {
    const { children, ErrorComponent = RelayErrorPage } = this.props;
    const { error } = this.state;
    return error && ErrorComponent ? (
      <ErrorComponent error={error} />
    ) : (
      <IsErrorFallback.Provider value={!!error}>
        {children}
      </IsErrorFallback.Provider>
    );
  }
}
