import React from "react";
import { Column, Text } from "../../quarks";
import { useStyles } from "../../style";

interface Props {
  color?: "danger";
  value?: number | string;
}

export default function NavBadge({ value, color = "danger" }: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      borderRadius: 9,
      height: 18,
      minWidth: 18,
      paddingHorizontal: 3,
      backgroundColor: getColor(color, "fill"),
      position: "absolute",
      top: 8,
      right: 2,
      zIndex: 1
    },
    text: {
      color: getColor(color, "placement"),
      fontWeight: "600",
      fontSize: 10,
      lineHeight: 12,
      textAlign: "center"
    }
  }));

  if (!value) return null;
  if (typeof value === "number" && value > 99) value = "+99";
  return (
    <Column
      style={styles.container}
      pointerEvents="none"
      justifyContent="center"
    >
      <Text numberOfLines={1} style={styles.text}>
        {value}
      </Text>
    </Column>
  );
}
