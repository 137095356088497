import React, { type ReactNode } from "react";
import { ContentArea, Stack } from "../atoms";
import Surface from "../atoms/Surface";
import RadioButtonRow from "../molecules/RadioButtonRow";
import { Spacer } from "../quarks";
import RadioGroup, { type RadioGroupProps } from "./RadioGroup";

interface Props<T extends object>
  extends Omit<RadioGroupProps<T>, "Wrapper" | "renderItem"> {
  Header?: ReactNode;
  Footer?: ReactNode;
  renderItem: (data: T) => ReactNode;
  variant?: "shadow" | "outline";
  testID: string;
}

export default function RadioCards<T extends object>({
  Header,
  Footer,
  renderItem,
  variant = "shadow",
  testID,
  ...props
}: Props<T>) {
  return (
    <ContentArea>
      <Stack>
        {Header}
        <RadioGroup
          renderItem={({ data, selected, onSelect }) => (
            <>
              <Surface variant={variant}>
                <RadioButtonRow
                  testID={testID}
                  eventTargetName=""
                  title={renderItem(data)}
                  selected={selected}
                  onSelect={onSelect}
                />
              </Surface>
              <Spacer />
            </>
          )}
          {...props}
        />
        {Footer}
      </Stack>
    </ContentArea>
  );
}
