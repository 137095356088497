import React, { type ReactNode } from "react";
import { Column, getSpaceUnits } from "../quarks";
import {
  type ResponsiveValue,
  type UseTransformFlexProps,
  useResponsiveValue,
  useStyles
} from "../style";

import { useGridGap } from "./GridContainer";
import Stack from "./Stack";

interface Props extends Omit<UseTransformFlexProps, "style"> {
  fillSm?: number | boolean;
  fillMd?: number | boolean;
  fillLg?: number | boolean;
  children?: ReactNode;
  fullWidth?: boolean;
  horizontal?: boolean;
  reorder?: ResponsiveValue<number[]>;
}

export default function GridCol({
  fill,
  fillSm,
  fillMd,
  fillLg,
  reorder,
  children,
  horizontal,
  fullWidth = true,
  ...rest
}: Props) {
  const gap = useGridGap();
  const styles = useStyles(
    () => ({
      col: { padding: getSpaceUnits(gap) / 2 },
      fullWidth: { width: "100%" }
    }),
    [gap]
  );

  const reorderVal = useResponsiveValue(reorder);
  fill = useResponsiveValue({
    xsmall: fill,
    small: fillSm,
    medium: fillMd,
    xlarge: fillLg
  });

  if (fill) fullWidth = false;

  return (
    <Column
      style={[styles.col, fullWidth && styles.fullWidth]}
      fill={fill}
      {...rest}
    >
      <Stack horizontal={horizontal} size={gap} reorder={reorderVal}>
        {children}
      </Stack>
    </Column>
  );
}
