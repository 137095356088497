import { useEventer } from "@gigsmart/dekigoto";
import React, { type ComponentType, type ReactNode, useCallback } from "react";
import { Platform, TouchableOpacity } from "react-native";
import { useHistory } from "./hooks";
import type { LocationShape } from "./types";

interface Props {
  testID: string;
  component?: ComponentType<any>;
  onPress?: () => void;
  replace?: boolean;
  to: string | LocationShape;
  children?: ReactNode;
}

const toHref = (location: string | LocationShape) => {
  if (typeof location === "string") return location;
  let href = location.pathname ?? "";
  if (location.search !== undefined && location.search !== "") {
    href += location.search;
  }
  return href;
};

export default function Link({
  testID,
  onPress,
  replace,
  to,
  component: PressComp = TouchableOpacity,
  children
}: Props) {
  const history = useHistory();
  const trackPress = useEventer("pressed", testID, "Link");

  const handlePress = useCallback(() => {
    trackPress();
    onPress?.();
    history[replace ? "replace" : "push"](to as any);
  }, [onPress, trackPress]);

  return (
    <PressComp
      {...Platform.select({
        web: { accessibilityRole: "link", href: toHref(to) }
      })}
      onPress={handlePress}
    >
      {children}
    </PressComp>
  );
}
