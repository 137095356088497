import React from "react";
import ContentArea from "../atoms/ContentArea";
import IconButton from "../atoms/IconButton";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";

interface Props {
  onDismiss: () => void;
  testID: string;
}

export default function DismissableCardHeader({ onDismiss, testID }: Props) {
  return (
    <Row justifyContent="flex-end">
      <ContentArea size="compact">
        <Spacer size="compact" />
        <IconButton
          testID={`${testID}-dismiss-btn`}
          name="times"
          variant="solid"
          size="medium"
          onPress={onDismiss}
        />
      </ContentArea>
    </Row>
  );
}
