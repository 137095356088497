import React from "react";
import { Image, Pressable, TouchableOpacity, View } from "react-native";
import IconCircle from "../atoms/IconCircle";
import { useStyles } from "../style";

interface Props {
  id?: string;
  variant?: "standard" | "rounded";
  uri: string;
  size: number;
  isSelected?: boolean;
  onSelect?: (uri: string) => void;
  onRemove?: (id: string) => void;
}

export default function GalleryImageTile({
  id,
  variant = "standard",
  size,
  isSelected,
  uri,
  onSelect,
  onRemove
}: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      container: {
        height: size,
        width: size
      },
      image: {
        backgroundColor: getColor("black", "fill", { opacity: 0.2 })
      },
      selectedImage: {
        borderColor: getColor("primary", "fill", { opacity: 0.6 }),
        borderWidth: 4,
        borderRadius: variant === "rounded" ? size / 6 : 0
      },
      icon: { position: "absolute", top: 4, right: 4 }
    }),
    [size]
  );
  return (
    <TouchableOpacity style={styles.container} onPress={() => onSelect?.(uri)}>
      <Image
        source={{ uri, height: size, width: size }}
        style={[styles.image, isSelected && styles.selectedImage]}
      />
      {onRemove && id ? (
        <Pressable style={styles.icon} onPress={() => onRemove(id)}>
          <IconCircle icon="times" size="micro" color="primary" />
        </Pressable>
      ) : isSelected ? (
        <View style={styles.icon}>
          <IconCircle icon="check" size="tiny" color="primary" />
        </View>
      ) : null}
    </TouchableOpacity>
  );
}
