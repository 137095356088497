import { useMemo } from "react";
import { useTheme } from "./theme";
import { type BreakpointName, breakpointNames } from "./theme/media";
import { useViewport } from "./viewport";

type RawValue = string | number | boolean;
type AnyValue = RawValue | RawValue[];

export type ResponsiveValue<T extends AnyValue> =
  | T
  | { [key in BreakpointName]?: T };

function isDefined<T extends ResponsiveValue<any>>(
  val: T | null | undefined
): val is T {
  return val !== undefined && val !== null;
}

export function useResponsiveValue<T extends AnyValue>(
  value?: ResponsiveValue<T> | null
): T | undefined;
export function useResponsiveValue<T extends AnyValue>(
  value: ResponsiveValue<T> | null,
  fallback: T
): T;
export function useResponsiveValue<T extends AnyValue>(
  value?: ResponsiveValue<T> | null,
  fallback?: T | undefined
): T | undefined {
  const theme = useTheme();
  const viewport = useViewport();

  return useMemo(() => {
    if (!isDefined(value)) return fallback;
    if (Array.isArray(value) || typeof value !== "object") return value as T;

    let idx = breakpointNames.findIndex((name) => {
      const maxWidth = theme.media.size[name].maxWidth;
      return !!maxWidth && viewport.width < maxWidth;
    });
    if (idx < 0) idx = breakpointNames.length;

    for (; idx >= 0; idx--) {
      const name = breakpointNames[idx];
      if (name && isDefined(value[name])) return value[name];
    }

    return fallback;
  }, [viewport.width, JSON.stringify(value)]);
}
