import React from "react";
import { Row } from "../../quarks";
import { typedMemo } from "../../utils";
import MonthlyCalendarDayCell from "./MonthlyCalendarDayCell";
import { type DayRenderer, type EventMap, renderWithDivider } from "./helpers";
import type { PeriodDay } from "./types";

interface Props<T> {
  week: PeriodDay[];
  events?: EventMap<T> | null;
  grow?: boolean;
  fill?: boolean;
  renderDay?: DayRenderer<T>;
  onPressAddNewCalendarItem: (day: PeriodDay) => void;
}

export default typedMemo(
  function MonthlyCalendarWeekRow<T>({
    week,
    events,
    renderDay,
    onPressAddNewCalendarItem,
    ...rest
  }: Props<T>) {
    return (
      <Row {...rest} style={{ minHeight: 92 }}>
        {renderWithDivider(week, "y", (it) => {
          const dayEvents = events?.get(it?.key ?? "");

          return (
            <MonthlyCalendarDayCell
              fill
              testID={`cal-${it?.key ?? ""}`}
              day={it}
              onPressAddNewCalendarItem={onPressAddNewCalendarItem}
            >
              {!!dayEvents && renderDay?.(it, dayEvents)}
            </MonthlyCalendarDayCell>
          );
        })}
      </Row>
    );
  },
  (p1, p2) => p1.events === p2.events && p1.week?.[0]?.key === p2.week?.[0]?.key
);
