import React from "react";
import { type RefetchFnDynamic, useRefetchableFragment } from "react-relay";
import {
  type GraphQLTaggedNode,
  type OperationType,
  getFragment
} from "relay-runtime";
import type { FragmentContainerProps } from "./createRelayFragmentContainer";
import type { KeyType } from "./use-relay-fragment";

export function createRelayRefetchableFragmentContainer<
  F extends KeyType<unknown> = never,
  Q extends OperationType = never,
  P extends {} = {}
>(
  fragment: GraphQLTaggedNode,
  Component: React.ComponentType<
    P &
      NonNullable<F[" $data"]> & {
        result: F[" $data"];
        refetch: RefetchFnDynamic<Q, F>;
      }
  >
) {
  const { name } = getFragment(fragment);

  function FragmentContainer(props: FragmentContainerProps<F, P>) {
    const [data, refetch] = useRefetchableFragment<Q, F>(
      fragment,
      props.fragmentRef ?? null
    );
    if (!data) return null;
    return <Component {...data} {...props} result={data} refetch={refetch} />;
  }

  FragmentContainer.displayName = `RelayRefetchableFragmentContainer[${name}]`;

  return FragmentContainer;
}
