import type { PartialNull } from "@gigsmart/type-utils";
import React, {
  type ComponentProps,
  type ComponentType,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { BaseStepperMethods } from "./Stepper.base";
import {
  Step,
  type StepProps,
  getStepsFromChildren
} from "./Stepper.components";
import type {
  IStepper,
  StepDescriptor,
  StepperLayoutProps,
  StepperMethods,
  StepperState
} from "./Stepper.types";

const Context = React.createContext<IStepper<unknown> | null>(null);
const Current = React.createContext<StepDescriptor<unknown> | null>(null);

const stepperStub: IStepper<unknown> = {
  index: 0,
  steps: [],
  data: {},
  hasPreviousStep: false,
  hasNextStep: true,
  setData: () => {},
  prevStep: () => {},
  nextStep: () => {},
  gotoStep: () => {},
  setOptions: () => {}
};
const currentStepStub: StepDescriptor<unknown> = {
  hidden: true,
  index: -1,
  normalizedIndex: 1,
  key: "unknown",
  options: {},
  overrides: {},
  render: () => null
};

export function useStepperV2<TData = unknown>(): IStepper<TData> {
  const stepper = useContext(Context);
  if (!stepper) {
    console.warn("Warning: Not in a Stepper");
    return stepperStub as IStepper<TData>;
  }
  return stepper as IStepper<TData>;
}

export function useCurrentStep<T = unknown>() {
  let current = useContext(Current);
  if (!current) {
    console.warn("Warning: No current step");
    current = currentStepStub;
  }
  return current as StepDescriptor<T>;
}

type StepperProps<T, TData, TLayout extends StepperLayoutProps<T>> = {
  layout: ComponentType<TLayout>;
  initialData: TData;
  methods?: StepperMethods<T, TData>;
  children?: ReactNode;
  initialStepName?: string;

  /**
   * Just like FLatList's extraData. It's a marker prop that allows you to re-render the stepper.
   */
  extraData?: any;
  onSubmit?: (stepper: IStepper<TData>) => void;
  onExit?: (stepper: IStepper<TData>) => void;
} & Omit<TLayout, "steps" | "current" | "children">;

function Stepper<T, TData, TLayout extends StepperLayoutProps<T>>({
  initialData,
  initialStepName,
  layout: LayoutComponent,
  children,
  methods = BaseStepperMethods(),
  extraData,
  onSubmit,
  onExit,
  ...layoutProps
}: StepperProps<T, TData, TLayout>) {
  const [state, setState] = useState<StepperState<T, TData>>();
  const steps = getStepsFromChildren<T, TData>(children);
  const stepsKey = steps.map((it) => it.key).join("|");

  const setCurrentOptions = useCallback(
    <TInner extends T>(options: PartialNull<TInner>, index = state?.index) => {
      if (!state || typeof index !== "number") return;
      setState({
        ...state,
        overrides: { ...state.overrides, [index]: options }
      });
    },
    [state]
  );

  let accumIndex = 0;
  const descriptors = steps.reduce(
    (accum, { name, options, index, component, hidden: hiddenProp }) => {
      const StepComp = component;
      const setOptions = (options: PartialNull<T>) =>
        setCurrentOptions(options, index);

      const hidden = !!hiddenProp?.(state?.data ?? initialData);
      if (!hidden) accumIndex++;

      accum[name] = {
        options,
        overrides: state?.overrides?.[index] ?? {},
        index,
        normalizedIndex: accumIndex,
        key: name,
        hidden,
        render: () => <StepComp stepper={stepper} setOptions={setOptions} />
      };
      return accum;
    },
    {} as Record<string, StepDescriptor<T>>
  );

  // Refresh the state only when the steps change
  useEffect(() => {
    if (!state) {
      /// initial state
      setState(methods.getInitialState(steps, initialData, initialStepName));
    } else {
      /// update state from steps change
      setState(methods.updateSteps(steps, state));
    }
  }, [stepsKey, extraData]);

  const stepper = useMemo(() => {
    if (!state) return stepperStub as IStepper<TData>; // still being loaded

    const hasNextStep = state.index < state.steps.length - 1;
    const hasPreviousStep = state.index > 0;
    const withData = (data?: Partial<TData>) => ({
      ...state,
      data: { ...state.data, ...data }
    });

    const stepper: IStepper<TData> = {
      ...state,
      hasNextStep,
      hasPreviousStep,
      setOptions: setCurrentOptions as any,
      setData: (data) => setState(withData(data)),
      prevStep: (data) => setState(methods.prevStep(withData(data))),
      nextStep: (data) => setState(methods.nextStep(withData(data))),
      gotoStep: (step, data) => setState(methods.gotoStep(step, withData(data)))
    };

    return stepper;
  }, [state]);

  const visibleSteps = useMemo(
    () => state?.steps.filter((s) => !descriptors[s.name]?.hidden) ?? [],
    [state, descriptors]
  );

  const step = state?.steps[state.index];
  const current = step?.name ? descriptors?.[step.name] : null;

  useEffect(() => {
    if (!state) return;

    if (current?.hidden === true) {
      console.warn("Warning: Stepper got in a weird state.");
      return;
    }

    const curIndex = state?.index ?? 0;

    if (curIndex >= state.steps.length) onSubmit?.(stepper);
    else if (curIndex < 0) onExit?.(stepper);
  }, [state?.index]);

  /// waiting stepper to be ready
  if (!step || !current) return null;

  return (
    <Context.Provider value={stepper as IStepper<unknown>}>
      <LayoutComponent
        {...(layoutProps as unknown as TLayout)}
        current={current}
        steps={visibleSteps}
      >
        <Current.Provider value={current}>
          {current.hidden ? null : current.render()}
        </Current.Provider>
      </LayoutComponent>
    </Context.Provider>
  );
}

export type TypedStepper<
  TLayout extends ComponentType<any>,
  TData
> = ComponentProps<TLayout> extends StepperLayoutProps<infer TOptions>
  ? {
      Stepper: ComponentType<
        StepperProps<TOptions, TData, ComponentProps<TLayout>>
      >;
      Step: ComponentType<StepProps<TOptions, TData>>;
    }
  : never;

export function createStepper<TLayout extends ComponentType<any>, TData>() {
  return { Stepper, Step } as TypedStepper<TLayout, TData>;
}
