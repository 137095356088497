import {
  type NavigationHelpers,
  type NavigationProp,
  type ParamListBase,
  useNavigation
} from "@react-navigation/native";

const cache = new Map<string, NavigationHelpers<ParamListBase>>();

export const registerNavigation = (
  id: string,
  nav: NavigationHelpers<ParamListBase>
) => {
  cache.set(id, nav);
  return () => {
    cache.delete(id);
  };
};

export const useNavigationById = <T = NavigationProp<ParamListBase>>(
  id: string
) => {
  const fallback = useNavigation<T>();
  return (cache.get(id) as T) ?? fallback;
};
