import React from "react";
import ContentArea from "../atoms/ContentArea";
import IconText from "../atoms/IconText";
import InputLabel from "../atoms/InputLabel";
import Stack from "../atoms/Stack";
import Divider from "../quarks/Divider";
import Pressable from "../quarks/Pressable";
import type { IBarcode } from "./BarcodeScanner";

interface Props {
  barcodes?: IBarcode[];
  label?: string;
  onDelete?: (index: number) => void;
}

export default function BarcodesList({
  barcodes,
  label = "Barcodes",
  onDelete
}: Props) {
  return (
    <Stack size="slim">
      <InputLabel inset={false} label={label} />
      <>
        {barcodes?.length !== 0 && <Divider />}
        <Stack variant="divider">
          {barcodes?.map((barcode, index) => (
            <ContentArea size="compact" variant="none" key={index}>
              <Stack
                horizontal
                justifyContent="space-between"
                alignItems="center"
              >
                <IconText
                  size="small"
                  icon="rectangle-barcode"
                  iconVariant="solid"
                  spacing="compact"
                >
                  {barcode?.data}
                </IconText>
                {onDelete && (
                  <Pressable
                    testID={`barcode-delete-btn-${index}`}
                    eventTargetName="Barcode Delete Button"
                    eventEntityType="IconText"
                    onPress={() => onDelete?.(index)}
                  >
                    <IconText
                      icon="times"
                      color="danger"
                      iconVariant="solid"
                      size="small"
                      spacing="compact"
                      textWeight="semibold"
                    >
                      Delete
                    </IconText>
                  </Pressable>
                )}
              </Stack>
            </ContentArea>
          ))}
        </Stack>
        {barcodes?.length !== 0 && <Divider />}
      </>
    </Stack>
  );
}
