import { sortBy } from "lodash";
import React, { useState } from "react";
import { FlatList } from "react-native";
import BoxedScrollContent from "../atoms/BoxedScrollContent";
import Bubble from "../atoms/Bubble";
import Button from "../atoms/Button";
import IconCircle from "../atoms/IconCircle";
import IconText from "../atoms/IconText";
import ModalBody from "../atoms/ModalBody";
import ModalFooter from "../atoms/ModalFooter";
import ModalHeader from "../atoms/ModalHeader";
import SelectableBubble from "../atoms/SelectableBubble";
import Stack from "../atoms/Stack";
import BubbleContainer from "../molecules/BubbleContainer";
import Column from "../quarks/Column";
import type { IconName } from "../quarks/Icon";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import ModalContainer from "./ModalContainer";

export interface FieldItem {
  id: string;
  label: string;
}

interface Props {
  title: string;
  subtitle?: string;
  buttonLabel?: string | ((length: number) => string);
  visible: boolean;
  icon?: IconName;
  onClose: () => void;
  onSave: (selectedFields: FieldItem[]) => void;
  availableFields: FieldItem[];
  selectedFields: FieldItem[];
}

export default function BubbleSelectionModal({
  title,
  subtitle,
  buttonLabel,
  visible,
  onClose,
  onSave,
  icon,
  availableFields: initialAvailableFields,
  selectedFields: initialSelectedFields
}: Props) {
  const [selectedFields, setSelectedFields] = useState(initialSelectedFields);
  const [availableFields, setAvailableFields] = useState(
    initialAvailableFields
  );

  return (
    <ModalContainer
      eventContext="QualificationSelectionModal"
      visible={visible}
      variant={(size) => (size === "small" ? "full" : "none")}
      onRequestClose={onClose}
      fixedHeight
    >
      <ModalHeader title={title} subTitle={subtitle} subTitleItalic={false} />
      <ModalBody constraint="xsmall">
        <Stack fill>
          <SelectedFields
            selectedFields={selectedFields}
            onPop={(field) => {
              setSelectedFields(
                selectedFields.filter((item) => item.id !== field.id)
              );
              setAvailableFields([...availableFields, field]);
            }}
          />
          <AvailableFields
            availableFields={availableFields}
            onPop={(field) => {
              setAvailableFields(
                availableFields.filter((item) => item.id !== field.id)
              );
              setSelectedFields([...selectedFields, field]);
            }}
            iconName={icon}
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          testID="save-qualifications-button"
          label={
            typeof buttonLabel === "function"
              ? buttonLabel(selectedFields?.length)
              : buttonLabel ?? `Save Selected ${title}`
          }
          disabled={selectedFields.length === 0}
          onPress={() => {
            onSave?.(selectedFields);
          }}
        />
      </ModalFooter>
    </ModalContainer>
  );
}

interface SelectedFieldsProps {
  selectedFields: FieldItem[];
  onPop?: (val: FieldItem) => void;
}

function SelectedFields({ onPop, selectedFields }: SelectedFieldsProps) {
  const [{ containerWidth, contentWidth }, setLayout] = useState(() => ({
    containerWidth: 0,
    contentWidth: 0
  }));

  return (
    <Column>
      <BoxedScrollContent>
        <Stack size="compact">
          <Row justifyContent="space-between">
            <Text color="neutral">{selectedFields.length} SELECTED</Text>
            {containerWidth < contentWidth && (
              <IconText
                icon="chevron-right"
                color="neutral"
                iconPlacement="right"
                iconSize="tiny"
              >
                Scroll To View
              </IconText>
            )}
          </Row>
          {selectedFields.length === 0 && (
            <Bubble
              testID="no-options-bubble"
              label="No Options Selected"
              variant="solid"
              color="neutral"
            />
          )}
          {selectedFields.length > 0 && (
            <FlatList
              data={selectedFields}
              renderItem={({ item: { id, label } }) => (
                <SelectableBubble
                  eventTargetName="Bubble Selection Modal Add Button"
                  testID={`selected-${label}-bubble`}
                  label={label}
                  selected={true}
                  icon="times"
                  iconPlacement="right"
                  onIconPress={() => onPop?.({ id, label })}
                />
              )}
              ItemSeparatorComponent={() => (
                <Spacer horizontal size="compact" />
              )}
              onLayout={(e) =>
                e?.nativeEvent?.layout &&
                setLayout((l) => ({
                  ...l,
                  containerWidth: e.nativeEvent.layout.width
                }))
              }
              onContentSizeChange={(width) => {
                setLayout((l) => ({ ...l, contentWidth: width }));
              }}
              horizontal
            />
          )}
        </Stack>
      </BoxedScrollContent>
    </Column>
  );
}

interface AvailableFieldsProps {
  availableFields: FieldItem[];
  onPop?: (val: FieldItem) => void;
  iconName?: IconName;
}
function AvailableFields({
  availableFields,
  onPop,
  iconName
}: AvailableFieldsProps) {
  return (
    <BoxedScrollContent
      justify={availableFields.length === 0 ? "center" : undefined}
    >
      {availableFields.length === 0 && (
        <Stack fill alignItems="center" justifyContent="center">
          {iconName && (
            <IconCircle
              icon={iconName}
              size="medium"
              iconColor="primary"
              iconVariant="solid"
              color="primary"
              variant="well"
            />
          )}
          <Text color="primary" weight="bold" align="center">
            You've selected all available options
          </Text>
          <Text align="center">
            There are no additional options available to select.
          </Text>
        </Stack>
      )}
      {availableFields.length > 0 && (
        <BubbleContainer
          Bubbles={sortBy(availableFields, (field) => field.label).map(
            ({ label, id }) => (
              <SelectableBubble
                eventTargetName="Bubble Selection Modal Remove Button"
                key={`unselected-${label}-bubble`}
                testID={`unselected-${label}-bubble`}
                selected={false}
                label={label}
                onChange={() => {
                  onPop?.({ id, label });
                }}
              />
            )
          )}
        />
      )}
    </BoxedScrollContent>
  );
}
