import React, { type MutableRefObject } from "react";
import type { ScrollView as RNScrollView } from "react-native";

import { ScrollView, type ScrollViewProps } from "../quarks";
import { createScreenComponent } from "./ScreenWrapper";

type Props = ScrollViewProps & {
  scrollRef?: MutableRefObject<RNScrollView | null>;
  testID: string;
};

const ScreenScroll = createScreenComponent(({ scrollRef, ...props }: Props) => {
  return <ScrollView innerRef={scrollRef} {...props} />;
});

export default ScreenScroll;
