import type { AppMenuItem, ParamListBase } from "@gigsmart/kaizoku";
import React, { type ReactNode, useState } from "react";
import { View } from "react-native";
import { IconCircleButton, Stack } from "../atoms";
import SidenavItem, {
  isActive,
  SIDENAV_ITEM_SIZE,
  SIDENAV_ITEM_SIZE_STATIC
} from "../molecules/SidenavItem";
import { Column, FancyScroll, Row, Spacer } from "../quarks";
import {
  ColorPlacementProvider,
  useMatchesViewport,
  useStyles
} from "../style";

interface Props<ParamList extends ParamListBase = ParamListBase> {
  active?: keyof ParamList;
  items?: Array<AppMenuItem<ParamList> | "separator">;
  onPressItem?: (item: AppMenuItem<ParamList>) => void;
  children?: ReactNode;
}

export default function AppSidenav({
  active,
  items,
  onPressItem,
  children
}: Props) {
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const isLg = useMatchesViewport((media) => media.size.xlarge.up);
  const [collapsedState, setCollapsed] = useState<boolean>();

  const isStatic = !isLg;
  const isCollapsed = !isStatic && (collapsedState ?? !isLg);
  const styles = useStyles(({ getColor }) => ({
    sidenav: {
      backgroundColor: getColor("surface", "fill"),
      borderRightColor: "#eee",
      borderRightWidth: 1,
      width: 156,
      zIndex: 10
    },
    collapsed: { width: SIDENAV_ITEM_SIZE + 16 },
    static: { width: SIDENAV_ITEM_SIZE_STATIC + 8 },

    overscroll: { height: 40 },
    collapseBtn: {
      position: "absolute",
      right: 8,
      bottom: 8
    }
  }));

  const renderSidenavItem = (item: AppMenuItem, idx: number) => (
    <SidenavItem
      key={item === "separator" ? `sep_${idx}` : item.testID ?? item.title}
      item={item}
      collapsed={isCollapsed}
      variant={isStatic ? "static" : "default"}
      active={isActive(item, active)}
      onPress={onPressItem}
    />
  );

  const showSidebar = isMd && (items?.length ?? 0) > 0;
  return (
    <Row fill reverse>
      <Column fill>{children}</Column>
      {showSidebar && (
        <ColorPlacementProvider color="surface">
          <Column
            style={[
              styles.sidenav,
              isStatic ? styles.static : isCollapsed && styles.collapsed
            ]}
          >
            <FancyScroll fill testID="sidenav-scroller">
              <Spacer size="compact" />
              <Stack size="slim">{items?.map(renderSidenavItem)}</Stack>
              <Spacer size="xlarge" />
            </FancyScroll>
            {!isStatic && (
              <View style={styles.collapseBtn}>
                <IconCircleButton
                  variant="well"
                  size="tiny"
                  color="primary"
                  testID="sidenav-collapse-toggle"
                  icon={isCollapsed ? "chevron-right" : "chevron-left"}
                  onPress={() => setCollapsed(!isCollapsed)}
                />
              </View>
            )}
          </Column>
        </ColorPlacementProvider>
      )}
    </Row>
  );
}
