import fontColorContrast from "font-color-contrast";
import React from "react";
import { View } from "react-native";
import {
  type UseTransformFlexProps,
  usePlacementColor,
  useStyle
} from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  dir?: "x" | "y";
  fullWidth?: boolean;
  color?: Color;
  alignSelf?: UseTransformFlexProps["alignSelf"];

  /** @deprecated use dir="x" */
  horizontal?: boolean;
  /** @deprecated use dir="y" */
  vertical?: boolean;
}

export default function Divider({
  horizontal,
  vertical,
  color,
  dir = "x",
  fullWidth,
  alignSelf
}: Props) {
  const { color: placementColor } = usePlacementColor();
  const opacity = placementColor
    ? fontColorContrast(placementColor, 0.6) === "#ffffff"
      ? 16 / 255
      : 48 / 255
    : 1;
  if (vertical) dir = "y";
  else if (horizontal) dir = "x";
  const viewStyle = useStyle(
    ({ getColor }) => ({
      alignSelf,
      backgroundColor: color
        ? getColor(color, "fill")
        : getColor(placementColor, "fill", { opacity }),
      [dir === "x" ? "height" : "width"]: 1,
      [dir === "x" ? "width" : "height"]: fullWidth ? "100%" : undefined
    }),
    [dir, fullWidth, alignSelf]
  );
  return <View style={viewStyle} />;
}
