import type { DateTime } from "luxon";
import React from "react";
import { ContentArea } from "../../atoms";
import { Column, Icon, Pressable, Row } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import type { PeriodType } from "./types";

interface Props<T> {
  item: T;
  date: DateTime;
  isCollapsed: boolean;
  key: React.Key;
  period: Exclude<PeriodType, "month">;
  onPressAddNewCalendarItem: (item: T, date: DateTime) => void;
  renderCalendarItem?: (
    item: T,
    date: DateTime,
    isCollapsed: boolean
  ) => JSX.Element | null;
  variant: "standard" | "collapsible";
}

export default function SectionListCalendarCellContainer<T>({
  item,
  date,
  isCollapsed,
  key,
  period,
  onPressAddNewCalendarItem,
  renderCalendarItem,
  variant
}: Props<T>) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      dayItem: {
        flex: 1,
        ...(isSm && {
          minWidth: getUnits(19)
        })
      },
      collapsedDayItem: {
        minHeight: getUnits(8)
      }
    }),
    [isSm]
  );

  return (
    <Column
      key={key}
      style={[styles.dayItem, isCollapsed && styles.collapsedDayItem]}
      fill={1}
    >
      {period === "week" ? (
        <ContentArea
          size="compact"
          variant="compact"
          fill={1}
          justifyContent="space-between"
        >
          {renderCalendarItem?.(item, date, isCollapsed)}
          {!isCollapsed && (
            <Row alignItems="center" justifyContent="center">
              <Pressable
                testID={`add-item-day-${date.day}`}
                eventTargetName="Add Day Item Button"
                eventEntityType="Add Button"
                onPress={() => onPressAddNewCalendarItem(item, date)}
              >
                <Icon
                  name="plus"
                  size="medium"
                  variant="solid"
                  color="neutral"
                />
              </Pressable>
            </Row>
          )}
        </ContentArea>
      ) : (
        <ContentArea
          size="compact"
          variant="standard"
          fill={1}
          justifyContent="space-between"
        >
          <Row fill={1} justifyContent="center">
            <Column fill={0.9} justifyContent="center" alignItems="center">
              {renderCalendarItem?.(item, date, isCollapsed)}
            </Column>
            <Column fill={0.1}>
              {!isCollapsed && (
                <Row fill={1} justifyContent="flex-end">
                  <Pressable
                    testID={`add-item-day-${date.day}`}
                    eventTargetName="Add Day Item Button"
                    eventEntityType="Add Button"
                    style={{ justifyContent: "center" }}
                    onPress={() => onPressAddNewCalendarItem(item, date)}
                  >
                    <Icon
                      name="plus"
                      size="medium"
                      variant="solid"
                      color="neutral"
                      align="center"
                    />
                  </Pressable>
                </Row>
              )}
            </Column>
          </Row>
        </ContentArea>
      )}
    </Column>
  );
}
