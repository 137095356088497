import React from "react";
import { ContentArea } from "../../atoms";
import { Column } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";

interface Props<T> {
  item: T;
  isCollapsed: boolean;
  variant: "standard" | "collapsible";
  renderListItem: (item: T, isCollapsed: boolean) => JSX.Element | null;
  index: number;
}

export default function SectionListItemContainer<T>({
  item,
  isCollapsed,
  variant = "standard",
  renderListItem,
  index
}: Props<T>) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);

  const computeWidth = () => {
    if (variant === "collapsible") {
      return isSm ? 27 : 43;
    }
    return isSm ? 35 : 52;
  };

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      listItem: {
        marginTop: index === 0 ? 4 : undefined,
        minWidth: getUnits(computeWidth()),
        maxWidth: getUnits(computeWidth()),
        backgroundColor: getColor("foreground", "fill"),
        shadowColor: getColor("black", "fill"),
        zIndex: 1
      },
      collapsedListItemOverlay: {
        backgroundColor: getColor("background", "fill", { opacity: 0.08 })
      }
    }),
    [variant, isSm, index]
  );

  return (
    <Column
      style={[styles.listItem, isCollapsed && styles.collapsedListItemOverlay]}
      fill={1}
    >
      <ContentArea size="compact" variant="compact">
        {renderListItem(item, isCollapsed)}
      </ContentArea>
    </Column>
  );
}
