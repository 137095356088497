import React, { useRef, useState } from "react";
import { Dimensions } from "react-native";
import Explosion from "react-native-confetti-cannon";
import createEventStore from "../utils/createEventStore";

declare global {
  interface Window {
    tada: () => void;
  }
}

const cannonEventStore = createEventStore<{
  explode: undefined;
}>();

export const explode = () => {
  cannonEventStore.emit("explode", undefined);
};

export const useCannon = () => {
  return explode;
};

export default function Cannon() {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [showConfetti, setShowConfetti] = useState(false);
  cannonEventStore.useEventListener(
    "explode",
    () => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(setShowConfetti, 2000, false);
      setShowConfetti(true);
    },
    [showConfetti]
  );
  if (!showConfetti) return null;
  return (
    <>
      <Explosion
        count={50}
        origin={{
          x: Math.round(Dimensions.get("window").width / 2),
          y: Math.round(Dimensions.get("window").height)
        }}
        fadeOut
        explosionSpeed={100}
        fallSpeed={1750}
      />
      <Explosion
        count={50}
        origin={{
          x: Math.round(Dimensions.get("window").width / 2),
          y: Math.round(Dimensions.get("window").height)
        }}
        fadeOut
        explosionSpeed={100}
        fallSpeed={1400}
      />
    </>
  );
}

window.tada = explode;
