import backgroundLocation from "../configs/backgroundLocation";
import defaultImpl from "./defaultImpl.web";

export default backgroundLocation.register(
  defaultImpl({
    name: "geolocation",
    deviceAction: () => {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          () => resolve(),
          () => resolve()
        );
      });
    }
  })
);
