import React, { type ReactNode } from "react";
import { ScrollView } from "react-native";
import { type FlexStyle, type ViewStyle, useStyle } from "../style";

interface Props {
  children?: ReactNode;
  testID?: string;
  justify?: FlexStyle["justifyContent"];
  maxHeight?: number;
}

const BoxedScrollContent = ({
  children,
  testID,
  justify,
  maxHeight
}: Props) => {
  const style = useStyle<ViewStyle>(({ colors, getUnits }) => ({
    borderWidth: 1,
    borderColor: colors.background.fill,
    borderRadius: getUnits(1),
    padding: getUnits(2),
    flexGrow: 1,
    maxHeight
  }));

  const justifyStyles = useStyle(() => ({
    flexGrow: 1,
    justifyContent: justify
  }));

  return (
    <ScrollView
      contentContainerStyle={justify ? justifyStyles : {}}
      style={style}
      showsVerticalScrollIndicator
      testID={testID}
    >
      {children}
    </ScrollView>
  );
};

export default BoxedScrollContent;
