import { AdvancedMarker } from "@vis.gl/react-google-maps";
import React from "react";
import type { LatLng } from "react-native-maps";
import { latlng } from "../helpers";
import { MarkerPin } from "./MarkerPin";

interface Props {
  center: LatLng;
  draggable?: boolean;
  onDragEnd?: (coords: LatLng) => void;
  onDragStart?: (coords: LatLng) => void;
  onDrag?: (coords: LatLng) => void;
  children?: React.ReactNode;
}

export default function GoogleMapMarker({
  center,
  draggable,
  onDragEnd,
  children
}: Props) {
  const handleMapEvent = (handler?: (e: LatLng) => void) => {
    if (!handler) return;
    return (e: google.maps.MapMouseEvent) =>
      e.latLng && handler(latlng(e.latLng.toJSON()));
  };
  return (
    <AdvancedMarker
      position={latlng.toLiteral(center)}
      draggable={draggable}
      onDragEnd={handleMapEvent(onDragEnd)}
    >
      {children ?? <MarkerPin />}
    </AdvancedMarker>
  );
}
