import { useMap } from "@vis.gl/react-google-maps";
import React from "react";
import { IconButton, Stack, Surface } from "../../atoms";

type Props = {
  id: string;
  minZoom: number;
  maxZoom: number;
};

export default function MapZoomControl({ id, minZoom, maxZoom }: Props) {
  const map = useMap(id);

  const handleZoomIn = () => {
    let newZoom = (map?.getZoom() ?? 0) + 1;
    newZoom = newZoom > maxZoom ? maxZoom : newZoom;
    map?.setZoom(newZoom);
  };

  const handleZoomOut = () => {
    let newZoom = (map?.getZoom() ?? 0) - 1;
    newZoom = newZoom < minZoom ? minZoom : newZoom;
    map?.setZoom(newZoom);
  };

  return (
    <Surface variant="outline">
      <Stack variant="divider">
        <IconButton
          contentAreaSize="compact"
          contentAreaVariant="medium"
          variant="solid"
          size="tiny"
          testID="zoom-in-btn"
          name="plus"
          onPress={handleZoomIn}
        />
        <IconButton
          contentAreaSize="compact"
          contentAreaVariant="medium"
          variant="solid"
          size="tiny"
          testID="zoom-out-btn"
          name="minus"
          onPress={handleZoomOut}
        />
      </Stack>
    </Surface>
  );
}
