import { useFragment } from "react-relay";
import type { FragmentRefs, FragmentType } from "relay-runtime";

export type KeyType<TData = unknown> = Readonly<{
  " $data"?: TData | undefined;
  " $fragmentSpreads": FragmentType;
}>;

export interface FragmentReference {
  readonly " $data"?: unknown;
  readonly " $fragmentSpreads": FragmentRefs<string>;
}

export const useRelayFragment = useFragment;
