import React, { type ReactNode } from "react";

import type { DekigotoError } from "@gigsmart/dekigoto";
import { DeveloperDebug } from "@gigsmart/feature-flags";
import { RelayRequestError } from "@gigsmart/relay";
import {
  Button,
  Constraint,
  ContentArea,
  FooterSpacer,
  SadPanda,
  Stack
} from "../atoms";
import { ScreenScroll } from "../layout";
import { Well } from "../molecules";
import { Spacer, Text } from "../quarks";
import { useStyles } from "../style";

const CANNED_PROPS = {
  message:
    "We are sorry for the inconvenience. Our team has been automatically informed of the issue. In the meantime you can reload the app.",
  title: "You've Encountered an Error"
};

type ErrorPageProps = { onReload?: () => void; footer?: ReactNode } & (
  | { error: Error | DekigotoError }
  | {
      title: string;
      message: ReactNode;
      errorId?: string;
      errorStack?: string;
      errorPayload?: unknown;
    }
);

export default function ErrorPage({
  footer,
  onReload,
  ...props
}: ErrorPageProps) {
  const styles = useStyles(() => ({
    panda: { alignSelf: "center", marginBottom: 2 }
  }));

  const { title, message, errorId, errorStack, errorPayload } =
    "error" in props
      ? {
          errorId: "id" in props.error ? props.error.id : "",
          title: props.error.name,
          message: props.error.message,
          errorStack: props.error.stack,
          errorPayload:
            props.error instanceof RelayRequestError
              ? { data: props.error.data, errors: props.error.payloadErrors }
              : undefined
        }
      : props;

  const isDebug =
    process.env.CONFIG_ENV !== "prod" || DeveloperDebug.isEnabled();
  const showStackTrace = isDebug && !!errorStack;

  const btnAction = !!onReload && (
    <Constraint size="xsmall">
      <Button
        size="small"
        color="danger"
        testID="reload-btn"
        label="Reload App"
        onPress={onReload}
      />
    </Constraint>
  );

  return (
    <ScreenScroll testID="panda-screen" constraint="small" color="surface" grow>
      <ContentArea size="xlarge" grow justifyContent="center">
        <SadPanda style={styles.panda} height={220} />
        {!!errorId && (
          <Text align="center" weight="bold">
            #{errorId}
          </Text>
        )}
        <Spacer size="large" />
        <Stack alignItems="center" justifyContent="center" size="compact">
          <Text align="center" variant="titleLg" color="primary">
            {isDebug ? title : CANNED_PROPS.title}
          </Text>
          <Text align="center">{isDebug ? message : CANNED_PROPS.message}</Text>
        </Stack>
        {!!btnAction && <Spacer size="large" />}
        {showStackTrace ? (
          <Well size="none">
            <Stack variant="divider">
              <ContentArea variant="none">
                <Text>{errorStack}</Text>
              </ContentArea>
              {!!errorPayload && (
                <ContentArea variant="none">
                  <Text>{JSON.stringify(errorPayload)}</Text>
                </ContentArea>
              )}
            </Stack>
            {!!btnAction && <Spacer />}
            {btnAction}
          </Well>
        ) : (
          btnAction
        )}
      </ContentArea>
      {footer}
      <FooterSpacer />
    </ScreenScroll>
  );
}
