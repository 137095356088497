import React, { type ReactComponentElement, useState } from "react";
import { FlatList } from "react-native";

import BoxedScrollContent from "../atoms/BoxedScrollContent";
import Bubble from "../atoms/Bubble";
import IconText from "../atoms/IconText";
import type SelectableBubble from "../atoms/SelectableBubble";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";

interface Props {
  bubbles: Array<
    | ReactComponentElement<typeof SelectableBubble>
    | ReactComponentElement<typeof Bubble>
  >;
}

export default function ScrollableBubbleContainer({ bubbles }: Props) {
  const [{ containerWidth, contentWidth }, setLayouts] = useState({
    containerWidth: 0,
    contentWidth: 0
  });

  const bubblesCount = bubbles.length ?? 0;

  return (
    <Column testID="scrollable-bubble-container">
      <BoxedScrollContent>
        <Stack size="compact">
          <Row justifyContent="space-between">
            <Text color="neutral" testID="selected-count-text">
              {bubblesCount} SELECTED
            </Text>
            {containerWidth < contentWidth && (
              <IconText
                icon="chevron-right"
                color="neutral"
                iconPlacement="right"
                iconSize="tiny"
                testID="scroll-to-view-text"
              >
                Scroll To View
              </IconText>
            )}
          </Row>
          {bubblesCount === 0 && (
            <Bubble
              testID="no-options-bubble"
              label="No Options Selected"
              variant="solid"
              color="neutral"
            />
          )}
          {bubblesCount > 0 && (
            <FlatList
              data={bubbles}
              renderItem={({ item }) => item}
              ItemSeparatorComponent={() => (
                <Spacer horizontal size="compact" />
              )}
              onLayout={(e) => {
                if (!e.nativeEvent) return;
                setLayouts({
                  contentWidth,
                  containerWidth: e.nativeEvent.layout.width
                });
              }}
              onContentSizeChange={(width) => {
                setLayouts({
                  containerWidth,
                  contentWidth: width
                });
              }}
              horizontal
            />
          )}
        </Stack>
      </BoxedScrollContent>
    </Column>
  );
}
