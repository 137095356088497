import React, { useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "../../atoms";
import { StepFooter, StepHeader } from "../../organisms";
import { type StepperLayoutProps, useStepperV2 } from "../Stepper";

type Props = StepperLayoutProps<HeaderProps & FooterProps>;

export function ModalStep({ current }: Props) {
  return (
    <StepHeader.Realm>
      <StepFooter.Provider>
        <StepHeader.Exit>
          <Header {...current.options} />
        </StepHeader.Exit>
        <ModalBody>{current.render()}</ModalBody>
        <Footer {...current.options} />
      </StepFooter.Provider>
    </StepHeader.Realm>
  );
}

type HeaderProps = {
  title?: string;
};
const Header = ({ title }: HeaderProps) => {
  return <ModalHeader title={title ?? ""} />;
};

type FooterProps = {
  submitLabel?: string;
  showBackButton?: boolean;
  onSubmit?: () => unknown;
  onBackPressed?: () => unknown;
};
const Footer = ({
  submitLabel,
  showBackButton = true,
  onSubmit,
  onBackPressed
}: FooterProps) => {
  const stepper = useStepperV2();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await Promise.resolve(onSubmit?.());
      if (res !== false) stepper.nextStep();
    } finally {
      setLoading(false);
    }
  };

  const handleBackPressed = () => {
    const res = onBackPressed?.();
    if (res !== false) stepper.prevStep();
  };

  return (
    <ModalFooter horizontal>
      {showBackButton && stepper.hasPreviousStep && (
        <Button
          fill
          disabled={loading}
          testID="back-button"
          label="Back"
          icon="chevron-left"
          outline
          onPress={handleBackPressed}
        />
      )}
      <StepFooter.Exit>
        <Button
          fill
          testID="continue-button"
          label={submitLabel ?? stepper.hasNextStep ? "Continue" : "Submit"}
          onPress={handleSubmit}
          loading={loading}
        />
      </StepFooter.Exit>
    </ModalFooter>
  );
};
