import React, { type ComponentProps } from "react";
import { View } from "react-native";
import { type ViewStyle, useStyle } from "../style/styles";
import theme from "../style/theme";

export const spacerUnitSize = {
  xlarge: 10,
  large: 5,
  standard: 4,
  medium: 3,
  compact: 2,
  slim: 1,
  small: 0.5,
  none: 0
};

export type UnitSize = keyof typeof spacerUnitSize;

export const getSpaceUnits = (size: UnitSize = "standard") =>
  spacerUnitSize[size] * theme.measurements.units;

export type Props = ComponentProps<typeof View> & {
  horizontal?: boolean;
  size?: UnitSize;
  /**
   * @deprecated use `horizontal true/false` instead
   */
  direction?: ViewStyle["flexDirection"];
};

export default function Spacer({
  direction,
  horizontal,
  size = "standard"
}: Props) {
  const style = useStyle(
    ({ getUnits }) => {
      const attrName =
        horizontal ?? direction?.startsWith("row")
          ? "paddingLeft"
          : "paddingTop";
      return { [attrName]: getUnits(spacerUnitSize[size]) };
    },
    [horizontal, direction, size]
  );

  return <View style={style} pointerEvents="none" />;
}
