import { Normalizer } from "@gigsmart/fomu";

const amexGroups = [4, 6, 5];
const defaultGroups = [4, 4, 4, 4];

export function isAmex(cc?: string) {
  return !!cc && /^(34|37)/.test(cc);
}

export function ccDigits(value?: string) {
  const digits = Normalizer.digits(value);
  const maxLength = isAmex(digits) ? 15 : 16;
  return digits.substr(0, maxLength);
}

export function ccFormat(value?: string) {
  if (!value) return "";
  // keep only numbers and wildcard characters
  const groupSizes = isAmex(value) ? amexGroups : defaultGroups;
  const v = value?.replace(/[^0-9*•]/g, "") ?? "";

  const parts = [];
  let lenScanned = 0;
  for (const groupSize of groupSizes) {
    parts.push(v.substr(lenScanned, groupSize));
    lenScanned += groupSize;
    if (v.length <= lenScanned) break;
  }

  return parts.join(" ");
}
