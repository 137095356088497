import {
  type EventerProps,
  eventerStubs,
  withEventers
} from "@gigsmart/dekigoto";
import { isEqual } from "lodash";
import React, {
  Component,
  type ComponentProps,
  type ComponentType
} from "react";
import { Keyboard, TouchableOpacity, View } from "react-native";
import StyledIcon, { type IconVariant } from "../icon/styled-icon";
import StyledText from "../text/styled-text";
import Hoverable from "./hoverable";

import {
  type StylesProps,
  type TextStyleProp,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";

type Props<I extends ComponentType<any> = typeof StyledIcon> = StylesProps &
  ComponentProps<typeof TouchableOpacity> &
  EventerProps<"pressed"> & {
    buttonStyle?: ViewStyleProp;
    color?: string;
    containerViewStyle?: ViewStyleProp;
    disabled?: boolean;
    title?: string;
    onPress?: () => void;
    onIconPress?: () => void;
    onPressDisabled?: () => void;
    primary?: boolean;
    secondary?: boolean;
    textColor?: string;
    textStyle?: TextStyleProp;
    IconComponent?: I;
    icon?: string | ComponentProps<I>;
    iconPosition: "left" | "right";
    iconVariant?: IconVariant;
    iconStyle?: TextStyleProp;
    variant: "normal" | "compact";
    edgeIcon?: boolean;
    testID?: string;
    borderless?: true;
  };

@withStyles(({ color, units, font }) => ({
  button: {
    borderRadius: units(1),
    marginHorizontal: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 400,
    width: "100%"
  },
  normalVariant: {
    padding: units(4)
  },
  compactVariant: {
    padding: units(2)
  },
  buttonContainer: {
    backgroundColor: color.transparent,
    marginHorizontal: 0,
    marginVertical: 5,
    flexDirection: "row",
    justifyContent: "center"
  },
  baseText: {
    marginHorizontal: 10,
    textAlign: "center"
  },
  bold: {
    ...font.body("bold")
  },
  disabled: {
    backgroundColor: color.neutralDark,
    borderColor: color.neutralDark,
    borderWidth: 1
  },
  disabledText: {
    color: color.white
  },
  disabledSecondary: {
    borderColor: color.neutralDark,
    borderWidth: 1
  },
  disabledSecondaryText: {
    color: color.neutralDark
  },
  edgeIcon: {
    position: "absolute",
    left: 12
  },
  primary: {
    backgroundColor: color.orange,
    borderColor: color.orange,
    borderWidth: 1
  },
  hoverPrimary: {
    backgroundColor: color.darken(color.orange, 0.1),
    borderColor: color.darken(color.orange, 0.1),
    borderWidth: 1
  },
  primaryText: {
    color: color.white
  },
  secondary: {
    backgroundColor: color.transparent,
    borderColor: color.blue,
    borderWidth: 1
  },
  hoverSecondary: {
    backgroundColor: color.withOpacity(color.blue, 0.1),
    borderColor: color.blue,
    borderWidth: 1
  },
  secondaryText: {
    color: color.blue
  },
  borderless: {
    borderWidth: 0
  }
}))
@withEventers<"pressed", Props>("Button", ["pressed"], "title")
export default class StyledButton<
  I extends ComponentType<any> = typeof StyledIcon
> extends Component<Props<I>> {
  static defaultProps = {
    primary: true,
    eventers: eventerStubs<"pressed">(["pressed"]),
    iconPosition: "left",
    variant: "normal",
    edgeIcon: false,
    ...stylesStubs
  };

  shouldComponentUpdate(nextProps: Props<I>) {
    return (
      // Shallow Checks
      nextProps.color !== this.props.color ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.title !== this.props.title ||
      nextProps.primary !== this.props.primary ||
      nextProps.secondary !== this.props.secondary ||
      nextProps.textColor !== this.props.textColor ||
      nextProps.iconPosition !== this.props.iconPosition ||
      nextProps.onPress !== this.props.onPress || // Deep Checks
      !isEqual(nextProps.buttonStyle, this.props.buttonStyle) ||
      !isEqual(nextProps.containerViewStyle, this.props.containerViewStyle) ||
      !isEqual(nextProps.style, this.props.style) ||
      !isEqual(nextProps.textStyle, this.props.textStyle)
    );
  }

  render() {
    const {
      styles,
      theme,
      style,
      buttonStyle: buttonStyleProp,
      textStyle: textStyleProp,
      containerViewStyle,
      color,
      textColor,
      title,
      disabled,
      IconComponent,
      icon,
      iconPosition,
      edgeIcon,
      testID,
      secondary,
      primary,
      borderless,
      onPress,
      variant,
      onIconPress,
      ...props
    } = this.props;

    // Button label styles
    const textStyle = [
      styles.baseText,
      !secondary && styles.bold,
      primary && !secondary && styles.primaryText,
      secondary && styles.secondaryText,
      typeof textColor === "string" && {
        color: theme.color.getColor(textColor)
      },
      textStyleProp,
      disabled &&
        (secondary ? styles.disabledSecondaryText : styles.disabledText)
    ];

    // Button container styles (generator)
    const backgroundColor =
      typeof color === "string" ? theme.color.getColor(color) : undefined;
    const buttonStyle = (hover: boolean) => [
      styles.button,
      primary && !secondary && (hover ? styles.hoverPrimary : styles.primary),
      secondary && (hover ? styles.hoverSecondary : styles.secondary),
      typeof backgroundColor !== "undefined" && {
        backgroundColor,
        borderColor: backgroundColor
      },
      buttonStyleProp,
      disabled && (secondary ? styles.disabledSecondary : styles.disabled),
      styles[`${variant}Variant`],
      borderless && styles.borderless,
      style
    ];

    return (
      <View style={[styles.buttonContainer, containerViewStyle]}>
        <Hoverable>
          {(hover) => (
            <TouchableOpacity
              testID={testID ?? "styled-button"}
              style={buttonStyle(hover)}
              onPress={this._handlePress}
              {...props}
            >
              {iconPosition === "left" && this._renderIcon()}
              {title && <StyledText style={textStyle}>{title}</StyledText>}
              {iconPosition === "right" && this._renderIcon()}
            </TouchableOpacity>
          )}
        </Hoverable>
      </View>
    );
  }

  _renderIcon = () => {
    const {
      styles,
      edgeIcon,
      icon,
      disabled,
      secondary,
      iconPosition,
      iconStyle,
      iconVariant,
      onIconPress,
      IconComponent = StyledIcon
    } = this.props;

    if (typeof icon === "undefined") return null;

    const iconProps: Partial<ComponentProps<I>> = {};
    if (IconComponent === StyledIcon) {
      const secondaryColor = disabled ? "neutralDark" : "blue";
      const finalIconVariant: IconVariant =
        (icon as ComponentProps<I>).variant ??
        iconVariant ??
        (secondary ? "light" : "solid");
      Object.assign(iconProps, {
        onPress: onIconPress,
        variant: finalIconVariant,
        color: secondary ? secondaryColor : "white"
      });
    }

    return (
      <IconComponent
        style={[
          styles.iconComponent,
          iconPosition === "left" && edgeIcon && styles.edgeIcon,
          iconStyle
        ]}
        {...(typeof icon === "string" ? { name: icon as any } : icon)}
        {...iconProps}
      />
    );
  };

  _handlePress = () => {
    const { disabled, eventers, onPress, onPressDisabled } = this.props;

    if (disabled) {
      onPressDisabled?.();
    } else {
      Keyboard.dismiss();
      eventers.pressed();
      if (onPress) onPress();
    }
  };
}
