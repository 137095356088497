import { DekigotoError } from "@gigsmart/dekigoto";
import { RelayPreferWebsockets } from "@gigsmart/feature-flags";
import arrayToSentence from "array-to-sentence";
import { noop } from "lodash";
import remove from "lodash/remove";
import { fetchQuery as relayFetchQuery } from "react-relay";
import {
  type PayloadData,
  RelayNetworkLayerRequest as RelayNetworkRequest,
  type RelayRequestAny
} from "react-relay-network-modern/es";
import {
  type CacheConfig,
  Environment,
  type FetchQueryFetchPolicy,
  type GraphQLResponse,
  type GraphQLResponseWithData,
  type GraphQLResponseWithoutData,
  type GraphQLTaggedNode,
  type IEnvironment,
  Network,
  Observable,
  type OperationType,
  RecordSource,
  type RequestParameters,
  Store,
  type Variables
} from "relay-runtime";
import type { LoaderFn } from "../loader";
import type { PayloadError } from "../payload-error";
import type { Subscriber, UploadableMap } from "../types";
import { type RelayOrchestratorProp, defaultOptions } from "./context";
import { logger, networkLogger } from "./logger";
import { generateGraphqlWsNetwork } from "./protocol/graphql-ws";
import { generateHttpNetwork } from "./protocol/http";
import type { ProtocolOptions } from "./protocol/types";
import type { RelayOrchestratorOptions } from "./types";

export { RelayNetworkLayerRequest as RelayNetworkRequest } from "react-relay-network-modern/es";

interface Operation {
  name: string;
  kind: string;
}
export class RelayRequestError extends DekigotoError {
  constructor(
    public readonly operation: Operation,
    public readonly variables: Variables,
    public readonly data?: PayloadData,
    public readonly payloadErrors?: readonly PayloadError[],
    public readonly cause?: Error
  ) {
    const errorSentence = payloadErrors
      ? arrayToSentence(payloadErrors.map(({ message }) => message))
      : "Unknown Error";
    super(cause ?? errorSentence);
    // biome-ignore lint/correctness/noConstructorReturn: <explanation>
    if (cause instanceof RelayRequestError) return cause;
    this.name = "RelayRequestError";
  }

  static nonCriticalCodes = new Set([
    "UNAUTHORIZED",
    "INVALID",
    "NOT_FOUND",
    "OTP_INVALID"
  ]);

  get criticalErrors() {
    const nonCriticalErrors = this.nonCriticalErrors;
    return this.payloadErrors?.filter(
      (payloadError) => !nonCriticalErrors?.includes(payloadError)
    );
  }

  get nonCriticalErrors() {
    return this.payloadErrors?.filter((payloadError) => {
      return (
        !payloadError.code ||
        !!payloadError.code.match(/^E\d+/) ||
        RelayRequestError.nonCriticalCodes.has(payloadError.code)
      );
    });
  }

  isCritical() {
    return !!this.cause || !!this.criticalErrors?.length;
  }
}

export type FetchQueryFn = <TOperation extends OperationType>(
  query: GraphQLTaggedNode,
  variables: TOperation["variables"]
) => Promise<TOperation["response"]>;

export async function createRelayOrchestrator(
  options: RelayOrchestratorOptions
): Promise<RelayOrchestratorProp> {
  const loaders: LoaderFn[] = [];
  const requestIds: symbol[] = [];
  let recordSource: RecordSource = new RecordSource({});
  let defaultToken: string | null | undefined = "";

  const {
    graphqlUrl,
    graphqlSocket,
    setTokenFn: initialSetTokenFn,
    getTokenFn: initialGetTokenFn,
    beforeReset,
    afterReset,
    onQueryCompleted = defaultOptions.onQueryCompleted,
    onQueryError = defaultOptions.onQueryError,
    onMutationCompleted = defaultOptions.onMutationCompleted,
    onMutationError = defaultOptions.onQueryError,
    onSubscriptionNext = defaultOptions.onSubscriptionNext,
    persistStore,
    restoreStore,
    onRequestError,
    onRequest,
    onResponse,
    reportErrorCodes,
    onSubscriptionError,
    enableSubscriptions = true,
    queryCacheExpirationTime,
    useDecorateQuery = noop,
    errorCodeReporter = (
      error: PayloadError,
      request: { request: RequestParameters; variables: Variables }
    ) => {
      if (request instanceof RelayNetworkRequest) {
        logger.warn(
          `${error.code}: ${error.message} in ${
            request.operation.name
          } at \`${error.path?.join(".")}\``
        );
      }
    },
    payloadErrorTransformer = (error) => {
      const { field, message } = error;
      if (!field || !message) return error;
      error.message = `${field}: ${message}`;
      return error;
    },
    __UNSAFE_environment,
    ...otherOptions
  } = options;

  const setTokenFn = async (nextToken: string | null | undefined) => {
    initialSetTokenFn
      ? await initialSetTokenFn(nextToken)
      : (defaultToken = nextToken);
  };

  // Register a loader
  const __UNSAFE_registerLoader = (loader: LoaderFn) => {
    loaders.push(loader);
    _updateLoader(loader);
  };

  // Deregister a loader
  const __UNSAFE_deregisterLoader = (loader: LoaderFn) => {
    remove(loaders, (registered) => registered === loader);
  };

  // Register a subscriber
  const __UNSAFE_registerSubscriber = (subscriber: Subscriber) => {
    boundRegisterSubscriber?.(subscriber);
  };
  let boundRegisterSubscriber: typeof __UNSAFE_registerSubscriber | undefined;

  // Deregister a subscriber
  const __UNSAFE_deregisterSubscriber = (subscriber: Subscriber) => {
    boundDeregisterSubscriber?.(subscriber);
  };
  let boundDeregisterSubscriber:
    | typeof __UNSAFE_deregisterSubscriber
    | undefined;

  // Handle Loading
  const _handleLoading = (id: symbol) => {
    requestIds.push(id);
    _updateLoaders();
  };

  const _handleLoaded = (id: symbol) => {
    remove(requestIds, (tracked) => id === tracked);
    _updateLoaders();
  };

  const _updateLoaders = () => {
    loaders.forEach(_updateLoader);
  };

  const _updateLoader = (loader: LoaderFn) => {
    const isLoading = requestIds.length > 0;
    const progress = 1 / (requestIds.length + 1);
    loader({ isLoading, progress, queriesPending: requestIds.length });
  };

  // Fetch a query using the current environment
  const fetchQuery = async <TOperation extends OperationType>(
    query: GraphQLTaggedNode,
    variables: TOperation["variables"],
    {
      fetchPolicy,
      networkCacheConfig
    }: {
      fetchPolicy?: FetchQueryFetchPolicy;
      networkCacheConfig?: CacheConfig;
    } = {}
  ): Promise<TOperation["response"] | undefined> =>
    // eslint-disable-next-line @typescript-eslint/return-await
    await relayFetchQuery(environment, query, variables, {
      fetchPolicy,
      networkCacheConfig
    }).toPromise();

  function handleStartLoad(operationName: string) {
    const isSilent =
      operationName.endsWith("SilentMutation") ||
      operationName.endsWith("SilentQuery");
    const id = Symbol(operationName);
    setTimeout(() => !isSilent && _handleLoading(id), 5);
    return id;
  }

  function handleEndLoad(id: symbol) {
    setTimeout(() => _handleLoaded(id), 5);
  }

  function handleRequest(req: RelayRequestAny) {
    onRequest?.(req, orchestrator);
  }

  // Initialize the environment
  const disposeExistingNetwork: () => void = () => {};
  const _initEnvironment = async (blank?: boolean): Promise<IEnvironment> => {
    disposeExistingNetwork();
    if (__UNSAFE_environment) return __UNSAFE_environment;
    const data = !blank && restoreStore ? await restoreStore() : {};
    recordSource = new RecordSource(data);
    const store = new Store(recordSource, { queryCacheExpirationTime });
    const protocolOptions: ProtocolOptions = {
      graphqlUrl,
      graphqlSocket,
      getTokenFn: initialGetTokenFn ?? (() => defaultToken ?? ""),
      onRequest: handleRequest,
      ...otherOptions
    };
    const { network, registerSubscriber, deregisterSubscriber } =
      RelayPreferWebsockets.isEnabled()
        ? await generateGraphqlWsNetwork(protocolOptions)
        : await generateHttpNetwork(protocolOptions);
    boundRegisterSubscriber = registerSubscriber;
    boundDeregisterSubscriber = deregisterSubscriber;

    const fetchWithRetry = (
      request: RequestParameters,
      variables: Variables,
      cacheConfig: CacheConfig,
      uploadables?: UploadableMap | null,
      attempts = 0
    ): Observable<GraphQLResponse> =>
      network
        .execute(request, variables, cacheConfig, uploadables)
        .catch((error) => {
          if (!(error instanceof TypeError)) {
            networkLogger.error(error);
            throw error;
          }
          logger.warn(
            `[${request.name}]`,
            "protocol request failed, retrying in 5s",
            error
          );
          return Observable.from(
            new Promise((resolve) => setTimeout(resolve, 5_000))
              .then(() => {
                return fetchWithRetry(
                  request,
                  variables,
                  cacheConfig,
                  uploadables,
                  attempts + 1
                ).toPromise();
              })
              .then((response) => {
                if (!response) throw new Error("Failed to retry request");
                return response;
              })
          );
        });

    const fetchFn = (
      request: RequestParameters,
      variables: Variables,
      cacheConfig: CacheConfig,
      uploadables?: UploadableMap | null
    ): Observable<GraphQLResponse> => {
      const id = handleStartLoad(request.name);
      return fetchWithRetry(request, variables, cacheConfig, uploadables)
        .do({
          next: (response) => {
            if ("errors" in response && response?.errors?.length) {
              const error = new RelayRequestError(
                { name: request.name, kind: request.operationKind },
                variables,
                response.data,
                response.errors
              );
              if (error.isCritical()) onRequestError?.(error);

              response.errors?.forEach((error: PayloadError) => {
                if (
                  reportErrorCodes === "*" ||
                  reportErrorCodes?.includes(error?.code ?? "")
                ) {
                  errorCodeReporter(error, { request, variables });
                }
              });
            }
            onResponse?.(
              {
                data: "data" in response ? response.data : undefined,
                errors: "errors" in response ? response.errors : undefined
              } as GraphQLResponseWithData & GraphQLResponseWithoutData,
              orchestrator
            );
          }
        })
        .catch<GraphQLResponse>((error) => {
          const shouldIgnore =
            error.message === "Failed to fetch" || error.name === "AbortError";
          const requestError = new RelayRequestError(
            { name: request.name, kind: request.operationKind },
            variables,
            undefined,
            undefined
          );

          if (!shouldIgnore && onRequestError) onRequestError(requestError);
          throw error;
        })
        .finally(() => handleEndLoad(id));
    };

    const networkWithMiddleware = Network.create(
      fetchFn,
      enableSubscriptions
        ? (request, variables, cacheConfig) =>
            network.execute(request, variables, cacheConfig).catch((error) => {
              onSubscriptionError?.(error);
              throw error;
            })
        : undefined
    );

    return new Environment({
      network: networkWithMiddleware,
      store,
      log: ({ name, ...data }) => networkLogger.info(`[${name}]`, data)
    });
  };

  // Handle a reset of the relay environment
  const reset = async (newToken: string | null | undefined = null) => {
    if (beforeReset) await beforeReset();
    recordSource.clear();
    await setTokenFn(newToken);
    environment = await _initEnvironment(true);
    if (afterReset) await afterReset();
    return environment;
  };

  let environment = await _initEnvironment();

  const orchestrator: RelayOrchestratorProp = {
    __UNSAFE_registerLoader,
    __UNSAFE_deregisterLoader,
    __UNSAFE_registerSubscriber,
    __UNSAFE_deregisterSubscriber,
    get environment(): IEnvironment {
      return environment;
    },
    payloadErrorTransformer,
    reset,
    onQueryCompleted,
    onQueryError,
    onMutationCompleted,
    onMutationError,
    onSubscriptionNext,
    fetchQuery,
    useDecorateQuery,
    enableSubscriptions,
    clone: async () => await createRelayOrchestrator(options)
  };

  return orchestrator;
}
