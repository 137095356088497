import React, { createContext, type ReactNode, useState, useMemo } from "react";

type FormContextType = [
  Record<string, any>,
  (key: string, values: Record<string, any>) => void,
  (key: string) => void
];

export const FormContext = createContext<FormContextType>([
  {},
  () => null,
  () => null
]);

interface Props {
  children: ReactNode;
}

export default function FomuContextProvider({ children }: Props) {
  const [contextValues, setContextValues] = useState<Record<string, any>>({});
  const contextState: FormContextType = useMemo(() => {
    const updateValues = (key: string, values: Record<string, any>) => {
      setContextValues({ ...contextValues, [key]: values });
    };
    const removeKey = (key: string) => {
      const { [key]: _keyValues, ...rest } = contextValues;
      setContextValues(rest);
    };
    return [contextValues, updateValues, removeKey];
  }, [contextValues, setContextValues]);
  return (
    <FormContext.Provider value={contextState}>{children}</FormContext.Provider>
  );
}
