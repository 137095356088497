import "./FancyScroll.css";

import type { UnsubscribeFunction } from "@gigsmart/relay";
import React, {
  useImperativeHandle,
  useRef,
  type ReactNode,
  type RefObject
} from "react";
import type SimpleBarCore from "simplebar-core";
import SimpleBar from "simplebar-react";
import { useThrottledCallback } from "../utils";
import Column from "./Column";

type Props = {
  testID: string;
  fill?: boolean;
  children?: ReactNode;
  scrollRef?: RefObject<FancyScrollRef | null>;
  horizontal?: boolean;

  // native specific props
  nestedScrollEnabled?: boolean;
  bounces?: boolean;
};

type OnScrollListener = (e: {
  scrollLeft: number;
  scrollTop: number;
  scrollWidth: number;
  contentWidth: number;
  contentHeight: number;
}) => void;

export type FancyScrollRef = {
  scrollTo(x?: number, y?: number): void;
  addOnScrollListener(listener: OnScrollListener): UnsubscribeFunction;
};

const getScrollData = (el?: HTMLElement | null) => {
  if (!el) return null;
  return {
    scrollLeft: el.scrollLeft,
    scrollTop: el.scrollTop,
    scrollWidth: el.scrollWidth,
    contentWidth: el.clientWidth,
    contentHeight: el.clientHeight
  };
};

export default function FancyScroll({
  scrollRef,
  testID,
  children,
  fill
}: Props) {
  const innerRef = useRef<SimpleBarCore | null>(null);
  const listenersRef = useRef<OnScrollListener[]>([]);
  const bar = innerRef.current;

  const handleScroll = useThrottledCallback(() => {
    const data = getScrollData(bar?.contentWrapperEl);
    if (data) for (const fn of listenersRef.current) fn(data);
  });

  useImperativeHandle(scrollRef, () => ({
    scrollTo(x, y) {
      if (!bar?.contentWrapperEl) return;
      bar.contentWrapperEl.scrollTo({ left: x, top: y, behavior: "smooth" });
    },
    addOnScrollListener(listener) {
      const data = getScrollData(bar?.contentWrapperEl);
      listenersRef.current.push(listener);

      if (data) listener(data);
      return () => {
        const idx = listenersRef.current.indexOf(listener);
        if (idx !== -1) listenersRef.current.splice(idx, 1);
      };
    }
  }));

  return (
    <Column fill={fill}>
      <SimpleBar
        ref={innerRef}
        style={{ height: "100%" }}
        scrollableNodeProps={{ "data-testid": testID }}
        onScrollCapture={handleScroll}
      >
        {children}
      </SimpleBar>
    </Column>
  );
}
