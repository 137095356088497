import { createLogger } from "@gigsmart/roga";
import type { DetailedPermissionResponse } from "./registry";

/**
 * Checks if a specific permission status indicates that the permission has been granted.
 *
 * @param {PermissionStatus} status - The status of the permission to check.
 *
 * @returns {boolean} - Returns a boolean indicating whether the permission status indicates that the permission has been granted.
 */
export const hasPermissionFromStatus = ({
  status
}: DetailedPermissionResponse) => {
  switch (status) {
    case "limited":
    case "granted":
      return true;
    default:
      return false;
  }
};

export const logger = createLogger("🚥", "Permissions");
