import React from "react";
import { View } from "react-native";
import { Icon } from "../../quarks";
import { useStyles } from "../../style";
import type { Color } from "../../style/theme/colors";

interface Props {
  color?: Color;
}

export default function ParkingPin({ color = "danger" }: Props) {
  const styles = useStyles(() => ({
    pin: {
      position: "absolute",
      top: "50%",
      left: "50%",
      backgroundColor: "white",
      width: 10,
      height: 16,
      marginLeft: -5,
      marginTop: -7,
      borderRadius: 5
    }
  }));

  return (
    <>
      <View style={styles.pin} />
      <Icon
        name="square-parking"
        color={color}
        variant="solid"
        size="default"
      />
    </>
  );
}
