import React, { type ComponentProps, type ReactNode } from "react";
import {
  type DimensionValue,
  Platform,
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  type StyleProp,
  View
} from "react-native";
import { type ViewStyle, useStyles, useTheme } from "../style";
import type { ThemeMedia } from "../style/theme/media";

interface Props extends ComponentProps<typeof RNKeyboardAvoidingView> {
  size?: keyof ThemeMedia["size"];
  position?: "absolute";
  zIndex?: number;
  children: ReactNode;
  alignItems: ViewStyle["alignItems"];
  left?: DimensionValue;
  right?: DimensionValue;
  top?: DimensionValue;
  bottom?: DimensionValue;
  style?: StyleProp<ViewStyle>;
  enabled?: boolean;
}

export default function KeyboardAvoidingView({
  size,
  zIndex = 2,
  children,
  alignItems,
  left,
  right,
  top,
  bottom,
  style,
  position,
  enabled,
  ...keyboardAvoidingViewProps
}: Props) {
  const { media } = useTheme();
  const { maxWidth } = size ? media.size[size] : { maxWidth: undefined };
  const styles = useStyles(
    () => ({
      outer: {
        position,
        zIndex,
        left,
        right,
        bottom,
        top,
        alignItems,
        width: "100%"
      },
      inner: {
        width: "100%",
        maxWidth
      }
    }),
    [alignItems, left, right, top, bottom]
  );

  return (
    <RNKeyboardAvoidingView
      style={[style, styles.outer]}
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      enabled={enabled}
      {...keyboardAvoidingViewProps}
    >
      <View style={styles.inner}>{children}</View>
    </RNKeyboardAvoidingView>
  );
}

KeyboardAvoidingView.defaultProps = {
  alignItems: "center",
  left: 0,
  right: 0
};
