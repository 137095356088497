import React, { Children, type ReactNode, type ComponentProps } from "react";
import { ContentArea, List, Stack } from "../atoms";
import { Column, Icon, type IconName, Spacer, Text } from "../quarks";

interface Props {
  icon: IconName;
  disabled?: boolean;
  title: string;
  children: ReactNode;
  listVariant?: ComponentProps<typeof List>["variant"];
}

export default function StackedList({
  icon,
  disabled,
  title,
  listVariant,
  children
}: Props) {
  const childrenArray = Children.toArray(children);
  return (
    <ContentArea size="compact">
      <Stack size="compact">
        <Stack horizontal size="compact">
          <Icon
            variant="solid"
            size="small"
            align="center"
            name={icon}
            color={disabled ? "disabled" : "black"}
          />
          <Text weight="bold" color={disabled ? "disabled" : "black"}>
            {title}
          </Text>
        </Stack>
        <Stack horizontal>
          <Spacer horizontal size="medium" />
          <List variant={listVariant} disabled={disabled} size="slim" fill>
            {childrenArray?.map((item, index) => {
              if (typeof item === "string") {
                return (
                  <Text
                    testID={`stacked-list-row-item-${title}-${index}`}
                    key={index}
                    color={disabled ? "disabled" : "black"}
                  >
                    {item}
                  </Text>
                );
              }
              return (
                <Column
                  testID={`stacked-list-row-item-${title}-${index}`}
                  key={index}
                >
                  {item}
                </Column>
              );
            })}
          </List>
        </Stack>
      </Stack>
    </ContentArea>
  );
}
