import React, { type ComponentProps } from "react";
import type { ReactNode } from "react";
import ContentArea from "../atoms/ContentArea";
import FixedWidthContainer from "../atoms/FixedWidthContainer";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Icon, { type IconName } from "../quarks/Icon";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useMatchesViewport } from "../style";

interface Props {
  title: string;
  icon: IconName;
  description?: string;
  children?: ReactNode;
  alignItems?: ComponentProps<typeof Column>["alignItems"];
}

export default function ResponsiveStepperHeader({
  title,
  description,
  icon,
  children,
  alignItems = "center"
}: Props) {
  const isMdDwn = useMatchesViewport(({ size }) => size.medium.down);
  return isMdDwn ? (
    <ContentArea>
      <Stack>
        <Stack size="large">
          <Text
            color="primary"
            weight="bold"
            variant="header"
            testID="step-title"
          >
            {title}
          </Text>
          {description && <Text>{description}</Text>}
        </Stack>
        {children}
      </Stack>
    </ContentArea>
  ) : (
    <ContentArea size="xlarge" variant="xlarge" fill>
      <Stack>
        <Stack horizontal alignItems="center">
          <FixedWidthContainer width={32}>
            <Icon name={icon} size="default" color="primary" variant="solid" />
          </FixedWidthContainer>
          <Text variant="titleLg" color="primary" weight="semibold">
            {title}
          </Text>
        </Stack>
        {description && (
          <Stack size="compact" horizontal>
            <Spacer horizontal size="xlarge" />
            <Text color="primary" weight="bold" variant="header">
              {description}
            </Text>
          </Stack>
        )}
      </Stack>
      <Spacer />
      <Stack size="compact" horizontal fill>
        <Spacer horizontal size="xlarge" />
        <Column
          fill
          alignItems={alignItems === "flex-start" ? undefined : alignItems}
        >
          {children}
        </Column>
      </Stack>
      <Spacer />
    </ContentArea>
  );
}
