import { definePermission } from "../registry";

export default definePermission({
  name: "Calendar Access",
  icon: "calendar-edit",
  defaults: {
    preview: false,
    reason:
      "We need the ability to access to your calendar to provide you with the best experience."
  }
});
