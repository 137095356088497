import type { ValuesType } from "@gigsmart/type-utils";
import type { BRAND_COLORS } from "./color";
import * as theme from "./theme";
export type ThemeColorValues = ValuesType<typeof BRAND_COLORS>;
export interface SchemeInternal {
  foreground: ThemeColorValues;
  background: ThemeColorValues;
  opacity?: number;
  border?: ThemeColorValues;
}

export interface SchemeShape {
  active: SchemeInternal;
  inactive: SchemeInternal;
  disabled: SchemeInternal;
  [k: string]: SchemeInternal;
}
export const fallbackScheme: SchemeShape = {
  active: {
    foreground: theme.color.white,
    background: theme.color.blue
  },
  inactive: {
    foreground: theme.color.blue,
    background: theme.color.white
  },
  disabled: {
    foreground: theme.color.white,
    background: theme.color.neutralDark,
    opacity: 0.2
  }
};

export const getScheme = (
  variant: keyof SchemeShape = "inactive",
  scheme: SchemeShape = fallbackScheme
) => scheme[variant];
