import React from "react";

import { IconText } from "../atoms";
import { type IconName, Pressable, Text } from "../quarks";
import { useStyles } from "../style";
import { showEmbeddedBrowser } from "./EmbeddedBrowser";

interface Props {
  icon?: IconName;
  title?: string;
  linkText: string;
  url: string;
  testID: string;
}

export default function ExternalLink({
  title,
  linkText,
  url,
  icon,
  testID
}: Props) {
  const styles = useStyles(() => ({
    iconWrapper: { alignSelf: "center" }
  }));
  const handlePress = () => {
    showEmbeddedBrowser({ url, title: title ?? linkText });
  };

  return (
    <Pressable
      testID={testID}
      style={styles.iconWrapper}
      eventEntityType="ExternalLink"
      eventTargetName="External Link"
      onPress={handlePress}
    >
      {icon ? (
        <IconText
          color="info"
          icon={icon}
          iconVariant="light"
          iconAlign="center"
          size="small"
        >
          {linkText}
        </IconText>
      ) : (
        <Text color="info" align="center">
          {linkText}
        </Text>
      )}
    </Pressable>
  );
}
