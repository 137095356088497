import React from "react";
import {
  Icon,
  type IconName,
  type IconSize,
  type IconVariant
} from "../../quarks";
import NavAction from "./NavAction";
import NavBadge from "./NavBadge";

interface Props {
  testID: string;
  name: IconName;
  badge?: number | string;
  onPress?: () => void;
  size?: IconSize;
  variant?: IconVariant;
  eventTargetName?: string;
}

export default function NavIcon({
  testID,
  name,
  badge,
  size = "large",
  variant = "solid",
  onPress,
  eventTargetName
}: Props) {
  return (
    <NavAction
      testID={testID}
      onPress={onPress}
      eventTargetName={eventTargetName}
    >
      <Icon name={name} size={size} variant={variant} />
      {!!badge && <NavBadge value={badge} />}
    </NavAction>
  );
}
