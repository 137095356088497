import React, { type ComponentProps } from "react";
import Text from "../quarks/Text";

interface Props extends Omit<ComponentProps<typeof Text>, "children"> {
  value?: boolean;
  onLabel: ComponentProps<typeof Text>["children"];
  offLabel: ComponentProps<typeof Text>["children"];
}

const TextToggle = ({ value = true, onLabel, offLabel, ...props }: Props) => (
  <Text {...props}>{value ? onLabel : offLabel}</Text>
);

export default TextToggle;
