import React from "react";

interface Props {
  width?: number;
  height?: number;
  xml: string;
}

export default function SvgContainer({ width, height, xml }: Props) {
  const base64data = btoa(unescape(encodeURIComponent(xml)));
  return (
    <img
      src={`data:image/svg+xml;base64,${base64data}`}
      width={width ?? 36}
      height={height ?? 36}
    />
  );
}
