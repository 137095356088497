import React, { useEffect } from "react";
import { Route } from "react-router";
import { useHistory } from "../hooks";
import { navigationRef } from "./delegate";

function BackToNav() {
  const history = useHistory();
  useEffect(() => {
    if (history.action === "POP") {
      if (navigationRef.isReady() && navigationRef.canGoBack()) {
        navigationRef.goBack();
      } else history.replace("/");
    }
  }, []);

  return null;
}

export function BackToNavRoute() {
  return <Route component={BackToNav} />;
}
