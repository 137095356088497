import React from "react";
import Main from "./main";
import { Provider } from "./request-context";

export default function App() {
  return (
    <Provider>
      <Main />
    </Provider>
  );
}
