import React, { type ComponentProps } from "react";
import { StyleSheet } from "react-native";
import Svg, {
  Defs,
  Rect,
  LinearGradient as SvgLinearGradient,
  Stop
} from "react-native-svg";

import { Column } from "../quarks";
import { useStyle } from "../style";

interface Point {
  x: number;
  y: number;
}

export interface Props extends ComponentProps<typeof Column> {
  start: Point;
  end: Point;
  locations: number[];
  colors: string[];
  opacities?: number[];
}

export default function LinearGradient({
  children,
  start,
  end,
  locations,
  opacities,
  colors,
  ...props
}: Props) {
  const bgStyle = useStyle(() => ({
    ...StyleSheet.absoluteFillObject,
    zIndex: -1
  }));
  return (
    <Column {...props}>
      <Svg
        style={bgStyle}
        height="100%"
        width="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <Defs>
          <SvgLinearGradient
            id="linear-gradient"
            x1={start.x * 100}
            y1={start.y * 100}
            x2={end.x * 100}
            y2={end.y * 100}
            gradientUnits="userSpaceOnUse"
          >
            {colors.map((_, idx) => (
              <Stop
                key={`${idx}`}
                offset={locations[idx]}
                stopColor={colors[idx]}
                stopOpacity={opacities ? opacities[idx] : 1}
              />
            ))}
          </SvgLinearGradient>
        </Defs>

        <Rect
          fill="url(#linear-gradient)"
          x={0}
          y={0}
          width={100}
          height={100}
        />
      </Svg>
      {children}
    </Column>
  );
}
