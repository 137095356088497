import React, { type ReactNode } from "react";
import { showModal } from "../../organisms";
import { type TypedStepper, createStepper } from "../Stepper";
import { ModalStep } from "./ModalStep";

type ShowModalFactoryArgs<TData> = {
  Step: TypedStepper<TData, typeof ModalStep>["Step"];
};

type StepperModalProps<TData> = {
  title: string;
  testID: string;
  initialData: TData;
  onClose: () => void;
  onSubmit?: (values: TData) => void;
};

export function createStepperModal<TData>(
  factory: (args: ShowModalFactoryArgs<TData>) => ReactNode
) {
  const { Step, Stepper } = createStepper<TData, typeof ModalStep>();

  function StepperModal({
    onClose,
    onSubmit,
    title,
    ...rest
  }: StepperModalProps<TData>) {
    return (
      <Stepper
        {...rest}
        defaultOptions={{
          title
        }}
        layout={ModalStep}
        onSubmit={async (stepper) => {
          try {
            await Promise.resolve(onSubmit?.(stepper.data));
            onClose();
          } catch (err) {
            console.warn(err);
          }
        }}
      >
        {factory({ Step })}
      </Stepper>
    );
  }

  // showModal function
  const showStepperModal = (
    modalProps: Omit<StepperModalProps<TData>, "onClose">
  ) => {
    showModal({
      eventContext: null,
      useModalBody: false,
      useModalFooter: false,
      children: (closeFn) => <StepperModal onClose={closeFn} {...modalProps} />
    });
  };

  return showStepperModal;
}
