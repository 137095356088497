import { useEventer } from "@gigsmart/dekigoto";
import React, { type RefAttributes, type Ref, memo, forwardRef } from "react";
import {
  type PressableProps,
  Pressable as RNPressable,
  type View
} from "react-native";

interface Props extends PressableProps, RefAttributes<View> {
  eventTargetName: string | null;
  eventEntityType: string;
  testID: string;
}

export function usePressable<P extends unknown[]>({
  eventEntityType,
  eventTargetName,
  disabled,
  onPress
}: {
  eventEntityType: string;
  eventTargetName: string | null;
  disabled?: boolean | null;
  onPress?: ((...args: P) => void) | null;
}) {
  const trackPress = useEventer("Pressed", eventTargetName, eventEntityType);
  if (!onPress || disabled) return;
  return (...args: P) => {
    trackPress();
    onPress?.(...args);
  };
}

function Pressable(
  { disabled, eventTargetName, eventEntityType, onPress, ...props }: Props,
  forwardedRef: Ref<View>
) {
  const handlePress = usePressable<Parameters<NonNullable<typeof onPress>>>({
    eventEntityType,
    eventTargetName,
    disabled,
    onPress
  });

  return (
    <RNPressable
      ref={forwardedRef}
      onPress={handlePress}
      disabled={disabled}
      {...props}
    />
  );
}

const MemoedPressable = memo(forwardRef(Pressable));
MemoedPressable.displayName = "Pressable";

export type { Props as PressableProps };

export default MemoedPressable;
