import type { ComponentType } from "react";
export type Wrappable = ComponentType<any> | HasWrappedComponent;

export interface HasWrappedComponent {
  WrappedComponent: Wrappable;
}
export default function getWrappedComponent(
  component: any
): ComponentType<any> {
  return Object.prototype.hasOwnProperty.call(component, "WrappedComponent")
    ? ((component as HasWrappedComponent)
        .WrappedComponent as ComponentType<any>)
    : (component as ComponentType<any>);
}
