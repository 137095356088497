import { canUseDOM } from "fbjs/lib/ExecutionEnvironment";
import { useState } from "react";

const HOVER_THRESHOLD_MS = 1000;

let isEnabled = false;
let lastTouchTimestamp = 0;

const enableHover = () => {
  if (isEnabled || Date.now() - lastTouchTimestamp < HOVER_THRESHOLD_MS) return;
  isEnabled = true;
};

const disableHover = () => {
  lastTouchTimestamp = Date.now();
  if (isEnabled) isEnabled = false;
};

if (canUseDOM) {
  window.document.addEventListener("touchstart", disableHover, true);
  window.document.addEventListener("touchmove", disableHover, true);
  window.document.addEventListener("mousemove", enableHover, true);
}

export default (): [
  boolean,
  {
    onMouseEnter: () => unknown;
    onMouseLeave: () => unknown;
    onResponderGrant: () => unknown;
    onResponderRelease: () => unknown;
    onPressIn: () => unknown;
    onPressOut: () => unknown;
  }
] => {
  const [hover, setHover] = useState(false);
  const [showHover, setShowHover] = useState(true);

  const onMouseEnter = () => isEnabled && !hover && setHover(true);
  const onMouseLeave = () => isEnabled && hover && setHover(false);
  // prevent hover showing while responder
  const handleGrant = () => setShowHover(false);
  const handleRelease = () => setShowHover(true);

  return [
    showHover && hover,
    {
      onMouseEnter,
      onMouseLeave,
      // prevent hover showing while responder
      onResponderGrant: handleGrant,
      onResponderRelease: handleRelease,
      // if child is Touchable
      onPressIn: handleGrant,
      onPressOut: handleRelease
    }
  ];
};
