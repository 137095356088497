import React, { Component } from "react";
import { Animated, View } from "react-native";

import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  withStyles
} from "../style";
import { StyledHeader1 } from "./styled-header";

type Props = StylesProps & {
  style?: ViewStyleProp;
  textA: string;
  textB: string;
  duration: number;
  animated: boolean;
};

interface State {
  duration: number;
  side: number;
  progress: Animated.Value;
  opacity: Animated.Value;
}
@withStyles(({ font }) => ({
  container: {
    height: 80
  },
  textWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: 80,
    alignItems: "center",
    justifyContent: "center"
  },
  containerStatic: {
    flexDirection: "row",
    justifyContent: "center"
  },
  textWrapperStatic: {
    position: "relative",
    paddingHorizontal: 8
  },
  text: {
    textAlign: "center",
    fontSize: font.size.heading1,
    lineHeight: font.size.heading1 * font.lineHeight.header
  }
}))
export default class FadingText extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    duration: 2550,
    animated: true
  };

  currentSide = new Animated.Value(0);

  componentDidMount() {
    const { duration, animated } = this.props;
    if (!animated) return;

    Animated.loop(
      Animated.sequence([
        Animated.timing(this.currentSide, {
          toValue: 1,
          duration,
          useNativeDriver: false
        }),
        Animated.timing(this.currentSide, {
          toValue: 0,
          duration,
          useNativeDriver: false
        })
      ])
    ).start();
  }

  render() {
    const { styles, style, textA, textB, animated } = this.props;

    const sideAOpacity = this.currentSide.interpolate({
      inputRange: [0, 0.55, 1],
      outputRange: [0, 0, 1]
    });
    const sideBOpacity = this.currentSide.interpolate({
      inputRange: [0, 0.45, 1],
      outputRange: [1, 0, 0]
    });

    return (
      <View
        style={[styles.container, !animated && styles.containerStatic, style]}
      >
        <Animated.View
          style={[
            styles.textWrapper,
            animated ? { opacity: sideAOpacity } : styles.textWrapperStatic
          ]}
        >
          <StyledHeader1 style={styles.text} color="blue">
            {textA}
          </StyledHeader1>
        </Animated.View>
        <Animated.View
          style={[
            styles.textWrapper,
            animated ? { opacity: sideBOpacity } : styles.textWrapperStatic
          ]}
        >
          <StyledHeader1 style={styles.text} color="blue">
            {textB}
          </StyledHeader1>
        </Animated.View>
      </View>
    );
  }
}
