import React, { type ComponentProps, type ReactComponentElement } from "react";
import { View } from "react-native";
import type Bubble from "../atoms/Bubble";
import type Button from "../atoms/Button";
import ContentArea from "../atoms/ContentArea";
import type SelectableBubble from "../atoms/SelectableBubble";
import Stack from "../atoms/Stack";
import Surface from "../atoms/Surface";
import ActionRow from "../molecules/ActionRow";
import BubbleContainer from "../molecules/BubbleContainer";
import Column from "../quarks/Column";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useStyle } from "../style";

interface Props
  extends Pick<ComponentProps<typeof ActionRow>, "icon" | "title" | "note"> {
  testID: string;
  placeholder: string;
  action: ReactComponentElement<typeof Button>;
  bubbles: Array<
    | ReactComponentElement<typeof SelectableBubble>
    | ReactComponentElement<typeof Bubble>
  >;
}

export default function BubbleSelectionCard({
  testID,
  placeholder,
  action,
  bubbles,
  ...actionRowProps
}: Props) {
  const bottomWrapper = useStyle(
    () => ({
      marginTop: -8,
      zIndex: -1
    }),
    []
  );
  return (
    <Column testID={testID}>
      <ActionRow
        {...actionRowProps}
        testID={`${testID}-action-row`}
        variant="outline"
        right={action}
        rightSpacing="large"
      />
      <View style={bottomWrapper}>
        <Surface variant="outline">
          <ContentArea>
            <Spacer size="compact" />
            <Stack>
              <Text testID={`${testID}-placeholder`}>{placeholder}</Text>
              {bubbles.length > 0 && <BubbleContainer Bubbles={bubbles} />}
            </Stack>
          </ContentArea>
        </Surface>
      </View>
    </Column>
  );
}
