import React, { type ComponentProps } from "react";
import { Platform } from "react-native";

import { FlexRow } from "../flex";
import { type TextStyleProp, type ViewStyleProp, useStyles } from "../style";
import KatanaText from "../text/katana-text";

import StyledIcon, { type IconName, type IconVariant } from "./styled-icon";

type Props = ComponentProps<typeof KatanaText> & {
  style?: ViewStyleProp;
  textStyle?: TextStyleProp;
  iconStyle?: TextStyleProp;
  iconText?: string;
  iconName?: IconName;
  iconVariant?: IconVariant;
  iconSize?: number;
  iconPos?: "left" | "right";
  tintColor?: string;
  alignItems?: "baseline" | "center" | "flex-start";
  justifyContent?: "center" | "flex-start" | "flex-end";
  iconMarginUnits?: number;
  spacing?: number;
  iconColor?: string;
  iconTestID?: string;
};

const IconText = ({
  style,
  textStyle,
  iconText,
  iconStyle,
  iconSize = 14,
  iconVariant = "solid" as const,
  iconColor,
  iconName,
  iconPos = "left",
  tintColor,
  alignItems = "flex-start",
  justifyContent,
  spacing = 2,
  iconTestID,
  ...textProps
}: Props) => {
  const { styles, theme } = useStyles(
    ({ units, font }) => {
      const lineHeight = iconSize + 4;
      const top = Platform.OS === "ios" ? 0 : iconSize >= 14 ? -1 : -0.5;
      return {
        text: { lineHeight },
        icon: {
          position: Platform.select({ web: "relative" }),
          verticalAlign: "middle",
          top,
          lineHeight
        },
        iconLeft: { marginRight: units(spacing) },
        iconRight: { marginLeft: units(spacing) },
        iconText: { ...font.body("bold") }
      };
    },
    [spacing, iconSize]
  );

  const iconStyles = [
    styles.icon,
    !!iconText && styles.iconText,
    iconPos === "left" ? styles.iconLeft : styles.iconRight,
    { color: theme.color.getColor(iconColor ?? tintColor ?? "black") },
    iconStyle
  ];

  const textStyles = [
    styles.text,
    !!tintColor && { color: theme.color.getColor(tintColor) },
    textStyle
  ];

  const iconNode = iconName ? (
    <StyledIcon
      style={iconStyles}
      name={iconName}
      size={iconSize}
      variant={iconVariant}
      color={iconColor}
      testID={iconTestID}
    />
  ) : iconText ? (
    <KatanaText style={iconStyles} testID={iconTestID}>
      {iconText}
    </KatanaText>
  ) : null;

  return (
    <FlexRow
      alignItems={alignItems}
      justifyContent={justifyContent}
      style={style}
    >
      {iconPos === "left" && iconNode}
      <KatanaText style={textStyles} {...textProps} />
      {iconPos === "right" && iconNode}
    </FlexRow>
  );
};

export default IconText;
