import { createPropsPortal } from "@gigsmart/aperture";
import { useIsFocused } from "@react-navigation/native";
import React, { type ReactNode } from "react";

export interface NavPortalProps {
  title?: string | ReactNode | (() => JSX.Element);
  leftAccessory?: ReactNode | (() => JSX.Element);
  rightAccessory?: ReactNode | (() => JSX.Element);

  hideProfile?: boolean;
  showBack?: boolean;
  onBackPress?: () => boolean | void;
  disableToasts?: boolean;
}

const Portal = createPropsPortal<NavPortalProps>({});

export const NavPortalEntrance = (props: NavPortalProps) => {
  const isFocused = useIsFocused();
  return isFocused ? <Portal.Entrance {...props} /> : null;
};
export const NavPortalExit = Portal.Exit;
export const NavPortalRealm = Portal.Realm;
export const NavRealm = Portal.Realm;
export const useNavExit = Portal.useExit;
