import { useEventer } from "@gigsmart/dekigoto";
import { useHistory } from "@gigsmart/kaizoku";
import React, { type ComponentProps, type ReactNode } from "react";
import { Platform } from "react-native";
import { showEmbeddedBrowser } from "../organisms/EmbeddedBrowser";
import Hoverable from "../quarks/Hoverable";
import Text from "../quarks/Text";
import { useStyle } from "../style";
import type { Color } from "../style/theme/colors";

type Props = ComponentProps<typeof Text> & {
  color?: Color;
  openURL?: string;
  children?: ReactNode;
  target?: string;
  onPress?: () => unknown;
  external?: boolean;
};

export default function Link({
  openURL,
  color = "link",
  external = true,
  onPress,
  children,
  testID,
  target,
  ...rest
}: Props) {
  const history = useHistory();
  const webProps = Platform.OS === "web" &&
    (external || !!target) && {
      href: external ? addHttpsIfMissing(openURL) : openURL,
      hrefAttrs: { target: target ?? (external ? "blank" : undefined) }
    };
  const hoverStyle = useStyle(
    {
      textDecorationLine: "underline"
    },
    []
  );
  const trackPress = useEventer("Pressed", testID ?? "Unknown", "Link");
  const handlePress = () => {
    trackPress();
    onPress?.();

    if (openURL && !webProps) {
      if (external) {
        showEmbeddedBrowser({ url: addHttpsIfMissing(openURL) });
      } else {
        history.push(openURL);
      }
    }
  };

  return (
    <Hoverable>
      {(hover) => (
        <Text
          {...webProps}
          accessibilityRole="link"
          onPress={handlePress}
          style={hover && hoverStyle}
          color="info"
          {...rest}
        >
          {children}
        </Text>
      )}
    </Hoverable>
  );
}

function addHttpsIfMissing(url: string | undefined) {
  if (!url) return "";
  if (!/^https?:\/\//i.test(url)) return `https://${url}`;
  return url;
}
