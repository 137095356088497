import "./PDFViewer.css";

/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable no-restricted-properties */

import {
  AnnotationMode,
  GlobalWorkerOptions,
  type PDFDocumentProxy,
  type PDFPageProxy,
  version as PDF_VERSION,
  type PageViewport,
  PixelsPerInch,
  getDocument,
  renderTextLayer
} from "pdfjs-dist";
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useMemo
} from "react";
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  type ImageURISource,
  StyleSheet,
  View,
  type ViewabilityConfigCallbackPair
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { IsConstrainedProvider, LinearGradient, Surface } from "../../atoms";
import { Column, Divider, Row, Spacer, Text } from "../../quarks";
import {
  ColorPlacementProvider,
  responsive,
  useStyles,
  useTheme
} from "../../style";
import NavIcon from "../Navigation/NavIcon";
import PDFToolbar from "./PDFToolbar";

GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${PDF_VERSION}/build/pdf.worker.min.js`;

interface PageInfo {
  page: PDFPageProxy;
  viewport: PageViewport;
  pageNumber: number;
}

interface Props {
  filename?: string;
  source: Pick<ImageURISource, "uri">;
  onClose: () => void;
}

const RATIO = 1.3333;
const SCALE_ARR = [
  0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.3, 1.5, 1.7, 2, 2.3,
  2.6, 2.9, 3.4, 4
];

const roundScale = (num: number) => Math.round(num * 1000) / 1000;
const computeScaleIndex = (idx: number) =>
  roundScale((SCALE_ARR[idx] ?? 1) * RATIO);
const getScaleIndex = (scale: number) => {
  const localScale = roundScale(scale / RATIO);
  return SCALE_ARR.findIndex((it) => it >= localScale);
};

export default function PDFViewer({ filename, source, onClose }: Props) {
  const { top: topInset, bottom: bottomInset } = useSafeAreaInsets();

  const listRef = useRef<FlatList<PageInfo> | null>(null);
  const [pdf, setPdf] = useState<PDFDocumentProxy>();
  const [data, setData] = useState<PageInfo[]>();
  const [scale, setScale] = useState<number>();
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  const setPage = (toAdd: number) => {
    const nextIndex = current - 1 + toAdd;
    if (!data || nextIndex < 0 || nextIndex >= data.length) return;
    listRef.current?.scrollToIndex({ animated: false, index: nextIndex });
  };

  const offsetScale = useCallback(
    async (toAdd: number | "fit") => {
      let newScale = scale ?? 1 * RATIO;
      if (toAdd === "fit") {
        const page = await pdf?.getPage(1);
        if (page) {
          const { height, width } = page.getViewport({ scale: 1 });
          const dim = Dimensions.get("window");
          newScale = roundScale((dim.height - topPadding - 24) / height);
          if (newScale * width > dim.width - 24) {
            /// scaled pdf is bigger than window width
            newScale = roundScale((dim.width - 24) / width);
          }
        }
      } else if (scale && typeof toAdd === "number") {
        const nextIndex = toAdd + getScaleIndex(scale);
        if (nextIndex >= 0 && nextIndex < SCALE_ARR.length) {
          newScale = computeScaleIndex(nextIndex);
        }
      }

      if (newScale !== scale) setScale(newScale);
    },
    [scale]
  );

  const viewability = useMemo<ViewabilityConfigCallbackPair>(
    () => ({
      viewabilityConfig: {
        viewAreaCoveragePercentThreshold: 55
      },
      onViewableItemsChanged: (e) => {
        const idx = e.viewableItems[0]?.index;
        if (typeof idx === "number") setCurrent(idx + 1);
      }
    }),
    []
  );

  const theme = useTheme();
  const topPadding =
    Math.floor(theme.measurements.navbarHeight * 1.3) + topInset;

  const styles = useStyles(
    ({ getColor, measurements, media }) => ({
      wrapper: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 10,
        backgroundColor: getColor("black", "fill", { opacity: 0.8 })
      },
      document: { overflow: "auto" as any },
      list: {
        marginHorizontal: "auto",
        paddingTop: topPadding,
        paddingBottom: 88 + bottomInset
      },
      toolbar: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: topPadding,
        paddingTop: topInset
      },
      toolbarContent: [
        {
          height: measurements.navbarHeight,
          paddingRight: 12,
          paddingLeft: 16
        },
        responsive(media.size.large.up, { paddingLeft: 24, paddingRight: 24 })
      ],
      bottombar: {
        position: "absolute",
        bottom: 12,
        left: "50%",
        transform: [{ translateX: "-50%" as any }]
      },
      loading: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: getColor("black", "fill", { opacity: 0.4 }),
        zIndex: 99999
      }
    }),
    [topPadding, bottomInset]
  );

  const handleError = useCallback((err: Error) => {
    console.warn("Error:", err);
  }, []);

  const handleDownload = useCallback(() => {
    if (!source.uri) return;

    const a = window.document.createElement("a");
    // eslint-disable-next-line no-restricted-properties
    a.style.display = "none";
    a.href = source.uri;
    a.target = "_blank";
    a.download = filename ?? "document.pdf";

    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
  }, [filename, source.uri]);

  const handlePrint = useCallback(async () => {
    if (!pdf || !data) return;
    setLoading(true);
    await printPdf(data).catch((err) => console.warn(err));

    setTimeout(printCleanup, 20);
    setLoading(false);
    // clean up
  }, [data]);

  useEffect(() => {
    if (!source?.uri) return;

    setData(undefined);
    const task = getDocument({
      url: source.uri,
      cMapUrl: `https://unpkg.com/pdfjs-dist@${PDF_VERSION}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${PDF_VERSION}/standard_fonts`
    });

    void task.promise.then(setPdf).catch(handleError);

    return () => {
      void task.destroy();
    };
  }, [source?.uri]);

  useEffect(() => {
    if (!pdf) return;

    if (!scale) {
      void offsetScale("fit");
      return;
    }

    const loadData = async () => {
      const data = await Promise.all(
        Array.from({ length: pdf.numPages }, async (_, index) => {
          const pageNumber = index + 1;
          const page = await pdf.getPage(pageNumber);
          const viewport = page.getViewport({ scale });
          return { page, viewport, pageNumber };
        })
      );

      setData(data);
    };

    void loadData();
  }, [pdf, scale]);

  return (
    <Column style={styles.wrapper}>
      {!!pdf && (
        <FlatList
          {...viewability}
          ref={listRef}
          data={data}
          renderItem={({ item }) => <PDFPage {...item} />}
          keyExtractor={(item) => `${item.pageNumber}`}
          ItemSeparatorComponent={Separator}
          style={styles.document}
          contentContainerStyle={styles.list}
          getItemLayout={(data, index) => {
            const { viewport } = data?.[index] ?? {};
            const length = (viewport?.height ?? 0) + 12;
            return { index, length, offset: length * index };
          }}
        />
      )}
      <ColorPlacementProvider color="primary">
        <LinearGradient
          locations={[0, 1]}
          opacities={[0, 0.8]}
          style={styles.toolbar}
          colors={["#000000", "#000000"]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
        >
          <PDFToolbar
            filename={filename}
            uri={source.uri}
            onClose={onClose}
            onDownload={handleDownload}
            onPrint={handlePrint}
          />
        </LinearGradient>
      </ColorPlacementProvider>
      {!!data?.length && (
        <Column style={styles.bottombar}>
          <IsConstrainedProvider value>
            <Surface color="black">
              <Row alignItems="center">
                <NavIcon
                  testID="prev"
                  name="chevron-left"
                  variant="regular"
                  onPress={() => setPage(-1)}
                />
                <Text variant="header">
                  Page {current}/{data.length}
                </Text>
                <NavIcon
                  testID="next"
                  name="chevron-right"
                  variant="regular"
                  onPress={() => setPage(1)}
                />
                <Spacer horizontal size="compact" />
                <Divider dir="y" alignSelf="stretch" />
                <Spacer horizontal size="compact" />
                <NavIcon
                  eventTargetName="Zoom Out"
                  testID="zoom-out"
                  name="minus"
                  variant="regular"
                  onPress={() => void offsetScale(-1)}
                />
                <NavIcon
                  eventTargetName="Fill Page"
                  testID="zoom-fill"
                  name="arrows"
                  variant="regular"
                  onPress={() => void offsetScale("fit")}
                />
                <NavIcon
                  eventTargetName="Zoom In"
                  testID="zoom-in"
                  name="plus"
                  variant="regular"
                  onPress={() => void offsetScale(1)}
                />
                <Spacer horizontal size="compact" />
              </Row>
            </Surface>
          </IsConstrainedProvider>
        </Column>
      )}
      {(loading || !pdf) && (
        <Column
          style={styles.loading}
          alignItems="center"
          justifyContent="center"
        >
          <ActivityIndicator size="large" />
        </Column>
      )}
    </Column>
  );
}

const Separator = () => <Spacer size="medium" />;

interface PDFPageProps {
  page: PDFPageProxy;
  viewport: PageViewport;
}

const PDFPage = memo(function PDFPage({ page, viewport }: PDFPageProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);

  const renderPage = useCallback(() => {
    page.cleanup();

    const canvas = canvasRef.current;
    const canvasContext = canvas?.getContext("2d", { alpha: false });
    if (!canvas || !canvasContext) return;

    canvas.width = viewport.width;
    canvas.height = viewport.height;
    canvas.style.width = `${Math.floor(viewport.width)}px`;
    canvas.style.height = `${Math.floor(viewport.height)}px`;
    canvas.style.alignSelf = "center";

    return page.render({
      viewport,
      canvasContext,
      background: "#ffffff"
    });
  }, [viewport]);

  const renderPageText = useCallback(() => {
    const txt = textRef.current;
    if (!txt) return;

    txt.innerHTML = "";
    txt.style.width = `${Math.floor(viewport.width)}px`;
    txt.style.height = `${Math.floor(viewport.height)}px`;
    txt.style.alignSelf = "center";
    txt.style.setProperty("--scale-factor", `${viewport.scale}`);

    return renderTextLayer({
      container: txt,
      textContentSource: page.streamTextContent(),
      viewport
    });
  }, [viewport]);

  useLayoutEffect(() => {
    const tasks = [renderPage(), renderPageText()];
    return () => {
      tasks.forEach((it) => it?.cancel());
    };
  }, [page, viewport]);

  return (
    <View
      style={{
        backgroundColor: "#fff",
        width: Math.floor(viewport.width),
        height: Math.floor(viewport.height)
      }}
    >
      <canvas ref={canvasRef} />
      <div ref={textRef} className="textLayer" />
    </View>
  );
});

let printEl: HTMLIFrameElement | null;
function printCleanup() {
  printEl?.remove();
  printEl = null;
}

async function printPdf(pages: PageInfo[], printResolution = 200) {
  const canvas = document.createElement("canvas");
  const content = document.createElement("div");
  content.id = "printContainer";

  const styles = document.createElement("style");
  const { width = 0, height = 0 } =
    pages[0]?.page.getViewport({ scale: 1 }) ?? {};
  styles.textContent = printCss(width, height);

  if (!printEl) {
    printEl = document.createElement("iframe");
    printEl.style.display = "none";
    document.body.appendChild(printEl);
  }

  const body = printEl.contentDocument?.body;
  if (body) {
    body.innerHTML = "";
    body.appendChild(styles);
    body.appendChild(content);

    for (const info of pages) {
      await printPage(info, content, canvas, printResolution);
    }
    printEl.contentWindow?.print();
  }
}

async function printPage(
  { page }: PageInfo,
  content: HTMLDivElement,
  scratchCanvas: HTMLCanvasElement,
  printResolution: number
) {
  // The size of the canvas in pixels for printing.
  const PRINT_UNITS = printResolution / PixelsPerInch.PDF;
  const size = page.getViewport({ scale: 1 });
  scratchCanvas.width = Math.floor(size.width * PRINT_UNITS);
  scratchCanvas.height = Math.floor(size.height * PRINT_UNITS);

  const ctx = scratchCanvas.getContext("2d");
  if (!ctx) throw new Error("invalid pdf");

  ctx.save();
  ctx.fillStyle = "rgb(255, 255, 255)";
  ctx.fillRect(0, 0, scratchCanvas.width, scratchCanvas.height);
  ctx.restore();

  await page.render({
    canvasContext: ctx,
    transform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
    viewport: page.getViewport({ scale: 1, rotation: size.rotation }),
    intent: "print",
    annotationMode: AnnotationMode.ENABLE_STORAGE
  }).promise;

  const img = document.createElement("img");
  const wrapper = document.createElement("div");
  scratchCanvas.toBlob((blob) => blob && (img.src = URL.createObjectURL(blob)));

  wrapper.append(img);
  wrapper.className = "printedPage";
  content.append(wrapper);

  await new Promise<void>((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = reject;
  });
}

const printCss = (w: number, h: number) => `
@page { size: ${w}pt ${h}pt; }
html,
body {
  height: 100%;
  width: 100%;
}
body {
  background: rgba(0, 0, 0, 0) none;
}
#printContainer { height: 100%; }
#printContainer .printedPage {
  page-break-after: always;
  page-break-inside: avoid;

  /* The wrapper always cover the whole page. */
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#printContainer > .printedPage :is(canvas, img) {
  /* The intrinsic canvas / image size will make sure that we fit the page. */
  max-width: 100%;
  max-height: 100%;

  direction: ltr;
  display: block;
}
`;
