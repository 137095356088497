import React from "react";
import { View } from "react-native";

import { Pressable, Text } from "../../quarks";
import { useStyles } from "../../style";

interface Props {
  testID: string;
  label: string;
  isPinned?: boolean;
  isSelected: boolean;
  isSameMonth: boolean;
  disabled?: boolean | undefined;
  onPress?: () => void;
}

export default function Day({
  label,
  isPinned,
  isSelected,
  isSameMonth,
  disabled,
  onPress,
  testID
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      margin: 3,
      width: 36,
      height: 36,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 18,
      borderWidth: 1,
      borderColor: "transparent"
    },
    disabled: { opacity: 0.2 },
    selected: {
      borderColor: getColor("primary", "fill"),
      backgroundColor: getColor("primary", "fill"),
      fontWeight: "600"
    },
    pinned: {
      backgroundColor: getColor("primary", "fill", {
        opacity: 0.08
      }),
      fontWeight: "600"
    },
    labelPinned: {
      fontWeight: "700",
      color: getColor("primary", "fill")
    },
    labelSelected: {
      color: getColor("white", "fill"),
      fontWeight: "700"
    }
  }));

  if (!isSameMonth) return <View style={styles.container} />;

  return (
    <Pressable
      testID={testID}
      eventEntityType="calendar-day"
      eventTargetName="Calendar Day"
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.container,
        isSelected
          ? styles.selected
          : isPinned
            ? styles.pinned
            : (disabled || !isSameMonth) && styles.disabled
      ]}
    >
      <Text
        align="center"
        style={
          isSelected
            ? styles.labelSelected
            : isPinned
              ? styles.labelPinned
              : undefined
        }
      >
        {label}
      </Text>
    </Pressable>
  );
}
