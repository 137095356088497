import type { AppMenuItem } from "@gigsmart/kaizoku";
import React from "react";
import { ContentArea, Stack } from "../atoms";
import { Divider, Icon, Pressable, Text } from "../quarks";
import { useStyles } from "../style";

interface Props {
  item: AppMenuItem;
  onPress?: (item: AppMenuItem) => void;
}

export default function DrawerItem({ item, onPress }: Props) {
  const styles = useStyles(({ getUnits }) => ({
    container: {
      height: getUnits(14),
      paddingHorizontal: getUnits(4)
    }
  }));

  if (typeof item === "string") {
    // Divider
    return (
      <ContentArea variant="none">
        <Divider />
      </ContentArea>
    );
  }

  const icon = item.icon;
  return (
    <Pressable
      testID={item.testID ?? `${item.title}-menu-item`}
      eventEntityType="DrawerItem"
      eventTargetName={`${item.title} Drawer Item`}
      onPress={() => onPress?.(item)}
    >
      <Stack style={styles.container} horizontal alignItems="center">
        {typeof icon === "string" ? (
          <Icon name={icon} size="large" variant="solid" align="center" />
        ) : (
          icon
        )}
        <Text fill variant="title">
          {item.title}
        </Text>
      </Stack>
    </Pressable>
  );
}
