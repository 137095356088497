import React, { type ComponentProps, type ReactNode } from "react";
import { Platform, View } from "react-native";

import useStyles from "./use-styles";

type Props = ComponentProps<typeof View> & {
  enabled?: boolean;
  children: ReactNode;
};

const GrayscaleContainer = ({ style, enabled = true, ...viewProps }: Props) => {
  const { styles } = useStyles(() => ({
    grayscale: Platform.select({
      web: {
        WebkitFilter: "grayscale(100%)",
        filter: "grayscale(100%)"
      } as any
    })
  }));

  return <View style={[enabled && styles.grayscale, style]} {...viewProps} />;
};

export default GrayscaleContainer;
