import { currency } from "@gigsmart/isomorphic-shared/iso";
import { compact, uniq } from "lodash";
import React, { type ComponentProps, useCallback, useRef } from "react";
import {
  Button,
  ContentArea,
  InputContainer,
  InputLabel,
  InputNote
} from "../atoms";
import MaskedTextInput from "../molecules/MaskedTextInput";
import type TextInput from "../molecules/TextInput";
import { Column, Icon, Row, Spacer, Text } from "../quarks";
import { useStyles } from "../style";

interface Props extends Omit<ComponentProps<typeof TextInput>, "min" | "max"> {
  initialValue?: string;
  incrementSize: number;
  inputReadOnly?: boolean;
  min?: number | string;
  /**
   * Maximum value allowed for the input. It automatically sets the value if it exceeds the maximum value.
   */
  max?: number | string;
}

/**
 * IncrementingMoneyInput is used to edit money inputs by having an increment and decrement button.
 */
export default function IncrementingMoneyInput({
  value,
  testID,
  incrementSize = 0.5,
  label,
  labelAccessory,
  labelIcon,
  errors,
  onChangeText,
  min,
  max,
  note,
  initialValue,
  inputReadOnly = true
}: Props) {
  const styles = useStyles(() => ({
    row: { maxWidth: 180, width: "100%" }
  }));
  const minNum = currency.toFloat(min ?? 0);
  const maxNum = currency.toFloat(max ?? 500);
  const valueNum = currency.toFloat(value);

  const errorMessage = Array.isArray(errors)
    ? uniq(compact(errors.map(formatError))).join(", ")
    : formatError(errors);

  const interval = useRef<ReturnType<typeof setInterval>>();
  const helperText = errorMessage ?? note;
  const update = useCallback(
    (dir: 1 | -1, start = valueNum) => {
      let newValue = start + dir * incrementSize;

      if (newValue < minNum) {
        /// clamp to min value
        newValue = minNum;
      } else if (newValue > maxNum) {
        // clamp to max value
        newValue = maxNum;
      } else {
        // round to the nearest increment size
        const rest = newValue % incrementSize;
        if (rest) newValue += dir === 1 ? -rest : incrementSize - rest;

        // check if the OLD->NEW value intercepts the initialValue, so we can snap to it
        const initialValueNum = currency.toFloat(initialValue);
        if (initialValueNum) {
          const intercepts =
            dir === 1
              ? initialValueNum > start && initialValueNum < newValue
              : dir === -1
                ? initialValueNum < start && initialValueNum > newValue
                : false;
          if (intercepts) newValue = initialValueNum;
        }
      }

      onChangeText?.(newValue.toFixed(2));

      return newValue;
    },
    [valueNum, minNum, maxNum, incrementSize, initialValue, onChangeText]
  );

  const updateUntilRelease = useCallback(
    (dir: 1 | -1) => {
      let lastValue = valueNum;
      interval.current = setInterval(() => {
        lastValue = update(dir, lastValue);
      }, 100);
    },
    [update]
  );

  const stopUpdate = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = undefined;
    }
  }, []);

  return (
    <ContentArea alignItems="center">
      <InputLabel
        testID={testID && `${testID}-label`}
        label={label}
        icon={labelIcon}
        accessory={labelAccessory}
        error={!!errorMessage}
      />
      <Spacer size="compact" />
      <Row gap="compact" alignItems="center" style={styles.row}>
        <Button
          testID="decrement-money-input-btn"
          outline
          icon="minus"
          size="small"
          onPress={() => update(-1)}
          onLongPress={() => updateUntilRelease(-1)}
          onPressOut={() => stopUpdate()}
          disabled={valueNum <= minNum}
        />
        {inputReadOnly ? (
          <Column fill>
            <InputContainer>
              <Text>{value}</Text>
            </InputContainer>
          </Column>
        ) : (
          <MaskedTextInput
            fill
            testID={testID}
            mask="money:3"
            type="number"
            placeholder="0.00"
            value={value}
            onChangeText={onChangeText}
            leftAccessory={
              <Icon
                size="small"
                color="primary"
                variant="solid"
                name="dollar-sign"
              />
            }
          />
        )}
        <Button
          testID="increment-money-input-btn"
          outline
          icon="plus"
          size="small"
          onPress={() => update(1)}
          onLongPress={() => updateUntilRelease(1)}
          onPressOut={() => stopUpdate()}
          disabled={valueNum >= maxNum}
        />
      </Row>
      {!!helperText && (
        <InputNote
          error={!!errorMessage}
          testID="validator-error"
          note={helperText}
        />
      )}
    </ContentArea>
  );
}

// TODO: create error handlers
function formatError(error?: Error | string | null) {
  if (error) return typeof error === "string" ? error : error.message;
}
