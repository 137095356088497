import {
  NavPortalEntrance,
  defaultBackHandler,
  defaultCanGoBack,
  useNavExit
} from "@gigsmart/kaizoku";
import React, { type ReactNode, useCallback } from "react";
import { useIsConstrained } from "../atoms/Constraint";
import ContentArea from "../atoms/ContentArea";
import IconText from "../atoms/IconText";
import Stack from "../atoms/Stack";
import NavAction from "../organisms/Navigation/NavAction";
import Column from "../quarks/Column";
import Spacer, { type UnitSize } from "../quarks/Spacer";
import { useMatchesViewport } from "../style";

interface Props {
  extraSpace?: boolean | UnitSize;
  hiddenSpace?: boolean | UnitSize;
  showBackButton?: boolean;
  onBackPress?: () => void;
  leftAccessory?: ReactNode | null;
  rightAccessory?: ReactNode | null;
  contentVariant?: UnitSize;
}

export default function ScreenTop({
  leftAccessory,
  rightAccessory,
  onBackPress,
  showBackButton,
  extraSpace = true,
  hiddenSpace,
  contentVariant
}: Props) {
  const { onBackPress: navOnBackPress, showBack } = useNavExit();
  const isConstrained = useIsConstrained();
  if (!onBackPress) onBackPress = navOnBackPress;
  if (extraSpace === true) extraSpace = "compact";
  if (hiddenSpace === true) hiddenSpace = "standard";

  const spacing = !!extraSpace && <Spacer size={extraSpace} />;
  const shouldShowBackButton = showBack ?? showBackButton ?? defaultCanGoBack();
  const isMd = useMatchesViewport((media) => media.size.medium.up);

  // FIXME: It mimics NavBar back behavior (from NavPortalEntrance) - they should be combined
  const handleBackAction = useCallback(() => {
    if (onBackPress?.()) return true;
    return defaultBackHandler();
  }, [onBackPress]);

  return (
    <>
      {/* TODO: should be controlled by screen options */}
      <NavPortalEntrance
        showBack={showBackButton}
        onBackPress={onBackPress}
        leftAccessory={isMd ? undefined : leftAccessory}
        rightAccessory={isMd ? undefined : rightAccessory}
      />
      {isMd ? (
        <>
          {spacing}
          <ContentArea
            size="none"
            variant={contentVariant ?? (isConstrained ? "none" : "standard")}
          >
            <Stack horizontal alignItems="center">
              {shouldShowBackButton && (
                <NavAction testID="back-button" onPress={handleBackAction}>
                  <IconText
                    icon="arrow-left"
                    iconVariant="light"
                    iconSize="large"
                    spacing="compact"
                    size="medium"
                    color="primary"
                  >
                    Back
                  </IconText>
                </NavAction>
              )}
              {leftAccessory}
              <Column fill />
              <Column>{rightAccessory}</Column>
            </Stack>
          </ContentArea>
          {spacing}
        </>
      ) : hiddenSpace ? (
        <Spacer size={hiddenSpace} />
      ) : null}
    </>
  );
}
