import "@gigsmart/feature-flags/features";
import { flagStates } from "@gigsmart/feature-flags/registry";
import { Form, FormSubmit, FormToggle } from "@gigsmart/fomu";
import React, { useCallback } from "react";
import { useRequestContext } from "./request-context";
interface Props {
  onComplete: () => void;
}

const allCapabilities = () => Object.keys(flagStates());

export default function SetCapabilities({ onComplete }: Props) {
  const { setCapabilities, capabilities } = useRequestContext();
  const handleSubmit = useCallback(
    ({ values }: any) => {
      setCapabilities(values);
      onComplete();
    },
    [onComplete, setCapabilities]
  );
  return (
    <form>
      <Form initialValues={capabilities} onSubmit={handleSubmit}>
        {allCapabilities().map((name) => (
          <FormToggle name={name} key={name}>
            {({ value, toggle }) => (
              <div>
                <input
                  type="checkbox"
                  id={name}
                  name={name}
                  onClick={toggle}
                  checked={value}
                />
                <label htmlFor={name}>{name}</label>
              </div>
            )}
          </FormToggle>
        ))}
        <br />
        <FormSubmit>
          {({ submit }) => (
            <input type="submit" title="save" onClick={submit} />
          )}
        </FormSubmit>
      </Form>
    </form>
  );
}
