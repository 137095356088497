import React, { type ReactNode } from "react";
import { View } from "react-native";
import { ContentArea } from "../../atoms";
import { Text } from "../../quarks";
import { useStyles } from "../../style";

interface Props {
  label?: string;
  fill?: boolean;
  children?: ReactNode;
  color?: "neutral" | "primary";
}

export default function MonthlyCalendarDayHeader({
  fill,
  label = "-",
  color = "neutral",
  children
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.1,
      shadowRadius: 2,
      elevation: 3,
      backgroundColor: getColor("foreground", "fill"),
      flex: 1
    }
  }));

  return (
    <View style={styles.container}>
      <ContentArea justifyContent="center" alignItems="center">
        <Text weight="bold" color={color} align="center" numberOfLines={1}>
          {label.toUpperCase()}
        </Text>
        {children}
      </ContentArea>
    </View>
  );
}
