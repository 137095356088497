import React, { type ReactNode } from "react";
import { Avatar, IconSquare } from "../atoms";
import { Column, Icon, type IconName } from "../quarks";
import { useStyle } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  icon?: IconName;
  iconSquare?: IconName;
  avatar?: string;
  iconColor?: Color;
  children?: ReactNode;
}
export default function InputRowAccessory({
  icon,
  iconSquare,
  avatar,
  children,
  iconColor = "primary"
}: Props) {
  const squareStyle = useStyle(({ getUnits }) => ({
    marginLeft: !icon && !!iconSquare ? -getUnits(2) : 0,
    paddingRight: getUnits(4),
    minHeight: 52 // InputRow minHeight
  }));

  const content = icon ? (
    <Icon
      name={icon}
      align="center"
      size="medium"
      color={iconColor}
      variant="solid"
    />
  ) : iconSquare ? (
    <IconSquare
      icon={iconSquare}
      size="medium"
      color={iconColor}
      variant="well"
    />
  ) : avatar ? (
    <Avatar uri={avatar} size="medium" />
  ) : (
    children
  );

  return content ? (
    <Column alignSelf="center" justifyContent="center" style={squareStyle}>
      {content}
    </Column>
  ) : null;
}
