import React, { type ReactElement } from "react";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Icon, { type IconName, type IconVariant } from "../quarks/Icon";
import Text from "../quarks/Text";
import { usePlacementColor, useStyles } from "../style";
import type { Color } from "../style/theme/colors";
import type { OnlyChildren } from "../utils/types";

type SharedProps = OnlyChildren<ReactElement<typeof Text> | false | string>;

type Props = {
  icon: IconName;
  iconVariant?: IconVariant;
  color?: Color;
} & SharedProps;

export default function HighlightedStatement({
  children,
  color,
  icon,
  iconVariant
}: Props) {
  const { name: backgroundColor } = usePlacementColor();
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      container: {
        borderRadius: getUnits(1),
        borderWidth: 2,
        borderColor: getColor(color, "fill"),
        padding: getUnits(4),
        marginTop: getUnits(3)
      },
      iconContainer: {
        position: "absolute",
        top: getUnits(-3),
        width: getUnits(10),
        backgroundColor: getColor(backgroundColor ?? "surface", "fill")
      },
      icon: {
        fontSize: 24
      }
    }),
    [color]
  );

  return (
    <Column style={styles.container}>
      <Column
        alignSelf="center"
        alignItems="center"
        style={styles.iconContainer}
      >
        <Icon
          name={icon}
          color={color}
          variant={iconVariant}
          style={styles.icon}
        />
      </Column>
      {typeof children === "string" ? (
        <Text align="center">{children}</Text>
      ) : (
        <Stack size="compact">{children}</Stack>
      )}
    </Column>
  );
}
