import type { TypeForStyleKey } from "../type-helpers";

export default function flex(
  grow = 0,
  shrink = 1,
  basis: TypeForStyleKey<"flexBasis"> = "auto"
) {
  return {
    flexGrow: grow,
    flexShrink: shrink,
    flexBasis: basis
  };
}
