import { Platform } from "react-native";
import { windowInsets } from "../../quarks/SafeArea";

export const sizes = ["xsmall", "small", "medium", "large", "xlarge"] as const;

const defaultMeasurements = {
  units: 4,
  inputLabelSize: 3.5,
  navbarHeight: Platform.select({
    ios: 48,
    android: 52,
    default: 56
  }),
  /** @deprecated use useSafeAreaInsets hook. */
  get statusBarHeight() {
    return windowInsets.top;
  },
  /** @deprecated use useSafeAreaInsets hook. */
  get bottomSpace() {
    return windowInsets.bottom;
  }
};

export type Size = (typeof sizes)[number];
export type ThemeMeasurements = typeof defaultMeasurements;
export type UnitsProp = number;
export default defaultMeasurements;
