import type { GraphQLTaggedNode, RefetchFnDynamic } from "react-relay";
import type { KeyType } from "react-relay/relay-hooks/helpers";
import { getFragment, getRefetchMetadata, getSelector } from "relay-runtime";

import type { RelayOrchestratorProp } from "../orchestrator";
import type { QuerySpec } from "../types";

export async function refetchOnly<Q extends QuerySpec>(
  refetch: RefetchFnDynamic<Q, KeyType>,
  fetchQuery: RelayOrchestratorProp["fetchQuery"],
  parentFragmentRef: unknown | unknown[],
  refetchableFragmentInput: GraphQLTaggedNode,
  fragmentDataId?: string,
  vars: Q["variables"] = {}
) {
  const fragment = getFragment(refetchableFragmentInput);
  const fragmentSelector: any = getSelector(fragment, parentFragmentRef);
  const { refetchableRequest } = getRefetchMetadata(
    fragment,
    "useInfiniteList"
  );

  // From: https://github.com/facebook/relay/blob/b0e52fbfb3a3671b7fceb44ec35747248560bd5c/packages/react-relay/relay-hooks/useRefetchableFragmentNode.js#L418
  let parentVariables: any = {};
  let fragmentVariables: any = {};
  if (fragmentSelector?.kind === "PluralReaderSelector") {
    parentVariables = fragmentSelector.selectors[0]?.owner.variables ?? {};
    fragmentVariables = fragmentSelector.selectors[0]?.variables ?? {};
  } else if (fragmentSelector) {
    parentVariables = fragmentSelector.owner.variables;
    fragmentVariables = fragmentSelector.variables;
  }

  await fetchQuery<Q>(
    refetchableRequest,
    {
      ...parentVariables,
      ...fragmentVariables,
      ...vars,
      id: fragmentDataId
    },
    { fetchPolicy: "network-only" }
  ).then(() => {
    refetch(vars, { fetchPolicy: "store-only" });
  });
}
