import React from "react";
import { View } from "react-native";
import Stack from "../atoms/Stack";
import Tag from "../atoms/Tag";
import ThumbnailImage from "../atoms/ThumbnailImage";
import Column from "../quarks/Column";
import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useStyles } from "../style/styles";
import DateTime from "./DateTime";

const OUTER_DIAMETER = 23;
const INNER_DIAMETER = 11;
const BAR_HEIGHT = 34;
const PHOTO_HEIGHT = 60;
const BAR_OUTER_WIDTH = 11;
const BAR_INNER_WIDTH = 3;
const borderWidth = (BAR_OUTER_WIDTH - BAR_INNER_WIDTH) / 2;

export interface TimesheetEntry {
  label?: string;
  timestamp?: string;
  tag?: string;
  disabled?: boolean;
  active?: boolean;
  transitionedBy?: string;
  testID?: string;
  icon?: IconName;
  iconPosition?: "left" | "right";
  onIconPress?: () => void;
  imageUri?: string;
  note?: string;
}

interface Props {
  entries: TimesheetEntry[];
}

export default function Timesheet({ entries }: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    dot: {
      borderRadius: OUTER_DIAMETER / 2,
      borderWidth: (OUTER_DIAMETER - INNER_DIAMETER) / 2,
      height: OUTER_DIAMETER,
      width: OUTER_DIAMETER,
      zIndex: 2,
      backgroundColor: getColor("highlight", "fill"),
      borderColor: getColor("primary", "fill")
    },
    dotDisabled: {
      backgroundColor: getColor("surface", "placement", { opacity: 0.6 }),
      borderColor: getColor("neutral", "fill")
    },
    bar: {
      marginTop: -2,
      marginBottom: -2,
      borderColor: getColor("divider", "fill"),
      backgroundColor: getColor("divider", "fill"),
      borderLeftWidth: borderWidth,
      borderRightWidth: borderWidth,
      height: BAR_HEIGHT + 4,
      width: BAR_OUTER_WIDTH,
      zIndex: 1
    },
    barPhoto: {
      height: BAR_HEIGHT + 4 + PHOTO_HEIGHT
    },
    barActive: {
      backgroundColor: getColor("primary", "fill")
    },
    text: {
      paddingTop: getUnits(1)
    }
  }));

  return (
    <Column>
      {entries.map((entry, index) => (
        <Row justifyContent="space-between" key={`timesheet-entry=${index}`}>
          <Stack horizontal>
            <Column alignItems="center">
              <View
                style={[styles.dot, entry?.disabled && styles.dotDisabled]}
              />
              {index !== entries?.length - 1 && (
                <View
                  style={[
                    styles.bar,
                    entry?.active && styles.barActive,
                    !!entry?.imageUri && styles.barPhoto
                  ]}
                />
              )}
            </Column>
            <Stack size="slim">
              <Stack size="compact" horizontal alignItems="center">
                {!!entry?.icon && entry.iconPosition !== "right" && (
                  <Icon
                    name={entry?.icon}
                    color={entry?.disabled ? "neutral" : undefined}
                    style={styles.text}
                    size="small"
                    variant="solid"
                  />
                )}
                <Text
                  weight="bold"
                  color={entry?.disabled ? "neutral" : undefined}
                  style={styles.text}
                  testID={`${entry?.testID}-label`}
                >
                  {entry?.label}
                </Text>
                {!!entry?.icon && entry.iconPosition === "right" && (
                  <Pressable
                    onPress={entry.onIconPress}
                    testID={`entry-${index}`}
                    eventEntityType={`timesheet-entry-${index}`}
                    eventTargetName={`timesheet-entry-${index}`}
                    hitSlop={10}
                  >
                    <Icon
                      name={entry?.icon}
                      color={entry?.disabled ? "neutral" : undefined}
                      style={styles.text}
                      size="small"
                      variant="solid"
                    />
                  </Pressable>
                )}
              </Stack>
              <DateTime
                startsAt={entry?.timestamp}
                variant="note"
                color="neutral"
                weight="bold"
              />
              {!!entry?.note && (
                <Text variant="note">
                  {entry?.note}
                  {!!entry?.transitionedBy && (
                    <Text color="neutral">
                      {" "}
                      {/* {"\u2022 "} */}
                      {entry?.transitionedBy}
                    </Text>
                  )}
                </Text>
              )}
              {!!entry?.imageUri && (
                <ThumbnailImage
                  uri={entry?.imageUri}
                  testID={`timesheet-image-${index}`}
                />
              )}
            </Stack>
            {!!entry?.tag && <Tag label={entry?.tag} />}
          </Stack>
        </Row>
      ))}
    </Column>
  );
}
