import React from "react";
import { View } from "react-native";
import { Column, Row, Text } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";

interface Props {
  day: string;
  date: string;
  isToday: boolean;
}

export default function SectionListCalendarHeaderWeekContainer({
  day,
  date,
  isToday
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      container: {
        paddingHorizontal: 0,
        paddingVertical: 6,
        minHeight: 50,
        borderRightColor: getColor("divider", "fill"),
        borderRightWidth: 1
      },
      circleContainer: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: getColor("primary", "fill"),
        alignItems: "center",
        justifyContent: "center"
      }
    }),
    [isSm]
  );

  const renderCircledDate = (num: string) => {
    return (
      <View style={styles.circleContainer}>
        <Text weight="bold" color="white">
          {num}
        </Text>
      </View>
    );
  };

  return (
    <Row style={styles.container}>
      <Column
        fill={1}
        key={`${day}-${date}`}
        justifyContent="center"
        alignItems="center"
      >
        {isSm ? (
          <Column gap={isToday ? "none" : "slim"} alignItems="center">
            <Text
              variant="note"
              weight="bold"
              color={isToday ? "primary" : "neutral"}
            >
              {day.charAt(0)}
            </Text>
            {isToday ? (
              renderCircledDate(date)
            ) : (
              <Text weight="bold" color="neutral">
                {date}
              </Text>
            )}
          </Column>
        ) : (
          <Row gap="slim">
            <Column justifyContent="center">
              <Text variant="subheader" color={isToday ? "primary" : "neutral"}>
                {day}
                {!isToday && <Text> {date}</Text>}
              </Text>
            </Column>
            {isToday && renderCircledDate(date)}
          </Row>
        )}
      </Column>
    </Row>
  );
}
