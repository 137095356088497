import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { Platform, View } from "react-native";
import { Column, Divider, ScrollView } from "../../quarks";
import { Row } from "../../quarks";
import { useMatchesViewport } from "../../style";
import { typedMemo } from "../../utils";
import MonthlyCalendarDayHeader from "./MonthlyCalendarDayHeader";
import MonthlyCalendarWeekRow from "./MonthlyCalendarWeekRow";
import {
  DAYS_OF_WEEK,
  type DayRenderer,
  type EventMap,
  getMonthDates,
  renderWithDivider
} from "./helpers";
import type { PeriodDay } from "./types";

interface Props<T> {
  loading?: boolean;
  date: DateTime;
  events?: EventMap<T> | null;
  renderDay?: DayRenderer<T>;
  onPressAddNewCalendarItem: (day: PeriodDay) => void;
}

export default typedMemo(
  function MonthlyCalendar<T>({
    date = DateTime.local(),
    events,
    renderDay,
    onPressAddNewCalendarItem
  }: Props<T>) {
    const isSm = useMatchesViewport((media) => media.size.xsmall.down);
    const isMd = useMatchesViewport(({ size }) => size.medium.down);
    const monthDates = useMemo(
      () => getMonthDates(date ?? DateTime.local()),
      [date]
    );

    if (isSm) {
      return (
        <Column fill>
          <Row>
            {renderWithDivider(DAYS_OF_WEEK, "y", (it) => (
              <MonthlyCalendarDayHeader
                fill
                label={isSm ? it[0] : isMd ? it.substring(0, 3) : it}
              />
            ))}
          </Row>
          <View style={{ flex: 1 }}>
            <ScrollView
              testID="monthly-calendar"
              style={{
                flex: 1,
                marginBottom: Platform.OS === "ios" ? -20 : 0
              }}
              contentContainerStyle={{
                flexGrow: 1,
                minHeight: "100%"
              }}
              alwaysBounceVertical={false}
            >
              <Column fill>
                {renderWithDivider(monthDates, "x", (week) => (
                  <MonthlyCalendarWeekRow
                    fill
                    week={week}
                    events={events}
                    renderDay={renderDay}
                    onPressAddNewCalendarItem={onPressAddNewCalendarItem}
                  />
                ))}
                <Divider />
              </Column>
            </ScrollView>
          </View>
        </Column>
      );
    }

    return (
      <Column fill>
        <Row>
          {renderWithDivider(DAYS_OF_WEEK, "y", (it) => (
            <MonthlyCalendarDayHeader
              fill
              label={isSm ? it[0] : isMd ? it.substring(0, 3) : it}
            />
          ))}
        </Row>
        <Column fill>
          {renderWithDivider(monthDates, "x", (week) => (
            <MonthlyCalendarWeekRow
              fill
              week={week}
              events={events}
              renderDay={renderDay}
              onPressAddNewCalendarItem={onPressAddNewCalendarItem}
            />
          ))}
        </Column>
      </Column>
    );
  },
  (p1, p2) => p1.date.month === p2.date.month && p1.events === p2.events
);
