export interface Font {
  name: string;
  file: any;
  weight?: number;
  format?: string;
  style?: string;
  stretch?: string;
}

try {
  const fonts: Font[] = [
    {
      name: "FontAwesome6_Pro_Solid",
      file: require("../fonts/FontAwesome6_Pro_Solid.ttf")
    },
    {
      name: "FontAwesome6_Pro_Brands",
      file: require("../fonts/FontAwesome6_Pro_Brands.ttf")
    },
    {
      name: "FontAwesome6_Pro_Regular",
      file: require("../fonts/FontAwesome6_Pro_Regular.ttf")
    },
    {
      name: "FontAwesome6_Pro_Light",
      file: require("../fonts/FontAwesome6_Pro_Light.ttf")
    },
    {
      name: "FontAwesome6_Pro_Duotone",
      file: require("../fonts/FontAwesome6_Pro_Duotone.ttf")
    },
    {
      name: "FontAwesome6_Pro_Thin",
      file: require("../fonts/FontAwesome6_Pro_Thin.ttf")
    },
    {
      name: "FontAwesome6_Pro_Sharp_Solid",
      file: require("../fonts/FontAwesome6_Pro_Sharp_Solid.ttf")
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-SuperItalic-Web.woff"),
      format: "woff",
      weight: 900,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Super-Web.woff"),
      format: "woff",
      weight: 900
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-BlackItalic-Web.woff"),
      format: "woff",
      weight: 800,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Black-Web.woff"),
      format: "woff",
      weight: 800
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-BoldItalic-Web.woff"),
      format: "woff",
      weight: 700,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Bold-Web.woff"),
      format: "woff",
      weight: 700
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-SemiboldItalic-Web.woff"),
      format: "woff",
      weight: 600,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Semibold-Web.woff"),
      format: "woff",
      weight: 600
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Medium-Web.woff"),
      format: "woff",
      weight: 500,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Medium-Web.woff"),
      format: "woff",
      weight: 500
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-RegularItalic-Web.woff"),
      format: "woff",
      weight: 400,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Regular-Web.woff"),
      format: "woff",
      weight: 400
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-LightItalic-Web.woff"),
      format: "woff",
      weight: 300,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Light-Web.woff"),
      format: "woff",
      weight: 300
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-ExtralightItalic-Web.woff"),
      format: "woff",
      weight: 200,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Extralight-Web.woff"),
      format: "woff",
      weight: 200
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-ThinItalic-Web.woff"),
      format: "woff",
      weight: 100,
      style: "italic"
    },
    {
      name: "Produkt Web",
      file: require("../fonts/Produkt-Thin-Web.woff"),
      format: "woff",
      weight: 100
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-Black.otf"),
      format: "woff",
      weight: 800
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-BoldIt.otf"),
      format: "woff",
      weight: 700,
      style: "italic"
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-Bold.otf"),
      format: "woff",
      weight: 700
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-SemiboldItalic.otf"),
      format: "woff",
      weight: 500,
      style: "italic"
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-Semibold.otf"),
      format: "woff",
      weight: 500
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-RegItalic.otf"),
      format: "woff",
      weight: 400,
      style: "italic"
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-Regular.otf"),
      format: "woff",
      weight: 400
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-LightItalic.otf"),
      format: "woff",
      weight: 300,
      style: "italic"
    },
    {
      name: "Proxima Nova",

      file: require("../fonts/ProximaNova-Light.otf"),
      format: "woff",
      weight: 300
    }
  ];

  const iconFontStyles = fonts
    .map(({ name, file, weight, format, style, stretch }) => {
      let fontUrl: string = (file.default as string) || file;
      if (fontUrl.startsWith("./")) fontUrl = fontUrl.slice(1);

      return `@font-face {
            src: url(${fontUrl})${
              typeof format === "string" ? ` format('${format}')` : ""
            };
            font-family: "${name}";
            ${typeof weight === "number" ? `font-weight: ${weight};` : ""}
            ${typeof style === "string" ? `font-style: ${style};` : ""}
            ${typeof stretch === "string" ? `font-stretch: ${stretch};` : ""}
          }`;
    })
    .join("\n\n");

  // Create stylesheet
  const style = document.createElement("style");
  style.type = "text/css";

  if (style.sheet) {
    style.sheet.insertRule(iconFontStyles);
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  void document.head?.appendChild(style);
} catch (_e) {}
