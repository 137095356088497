import React from "react";
import { Checkbox, ContentArea, Surface } from "../atoms";
import { Column, Row, Text } from "../quarks";
import { useStyles } from "../style";

interface Props {
  title: string;
  selected: boolean;
  disabled?: boolean;
  onChange?: (selected: boolean) => void;
}

export default function SelectableListHeader({
  title,
  selected,
  disabled,
  onChange
}: Props) {
  const styles = useStyles(() => ({
    // +1 to account for entries' inside border
    row: { paddingRight: 1 },
    opt: { width: 52 }
  }));

  return (
    <Surface variant="flat" color="background">
      <Row style={styles.row} alignItems="center">
        <ContentArea fill size="compact">
          <Text weight="bold">{title}</Text>
        </ContentArea>
        <Column style={styles.opt} alignItems="center">
          <Checkbox
            testID="checkbox-entry"
            selected={selected}
            disabled={disabled}
            onChange={onChange}
          />
        </Column>
      </Row>
    </Surface>
  );
}
