import { applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { Component, type ComponentType } from "react";
import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import { getFeatureUser } from "./helpers";
import Rox, { type RoxFetcherResult, setup } from "./impl";
import { getRoxFlags, loadOverrides } from "./registry";

interface State {
  ready: boolean;
}

interface Options {
  onError?: (e: Error) => void;
  onReady?: () => Promise<void> | void;
}

let firstRun = true;

export const withFeatureFlags =
  ({ onError, onReady }: Options = {}) =>
  (WrappedComponent: ComponentType<any>): any =>
    applyHOCProperties({
      displayName: "withFeatureFlags",
      WrappedComponent,
      HigherOrderComponent: class extends Component<{}, State> {
        unmounted = false;
        state = { ready: !firstRun };
        readyTimeout?: ReturnType<typeof setTimeout>;

        _handleConfiguration = ({ hasChanges }: RoxFetcherResult) => {
          if (hasChanges && process.env.IS_TESTING !== "true") {
            this._makeUnready();
          }
        };

        _makeUnready = () => {
          this.setState({ ready: false });
        };

        _makeReady = async () => {
          if (!this.state.ready && !this.unmounted) {
            if (this.readyTimeout) {
              clearTimeout(this.readyTimeout);
              this.readyTimeout = undefined;
            }

            try {
              await loadOverrides();
              await onReady?.();
            } catch (error) {
              onError?.(error);
              console.warn(error);
            }

            this.setState({ ready: true });
          }
        };

        async componentDidMount() {
          if (process.env.IS_TESTING === "true") return;
          // When entering the split view, the app gets unmounted -> mounted
          // without losing JS context
          if (firstRun) {
            Rox.register("mobile", getRoxFlags());
            firstRun = false;
          }
          try {
            Rox.setCustomNumberProperty(
              "jsBuildNumber",
              Number(process.env.PACKAGE_BUILD_NUMBER ?? "0")
            );
            if (Platform.OS !== "web") {
              Rox.setCustomNumberProperty(
                "nativeBuildNumber",
                Number(DeviceInfo.getBuildNumber())
              );
              Rox.setCustomStringProperty("bundleId", DeviceInfo.getBundleId());
            }
            Rox.setCustomStringProperty(
              "userType",
              () => getFeatureUser()?.userType ?? ""
            );
            Rox.setCustomStringProperty(
              "emailAddress",
              () => getFeatureUser()?.emailAddress ?? ""
            );
            Rox.setCustomStringProperty("organizationId", () => {
              const organizationId = getFeatureUser()?.organizationId ?? "";
              return organizationId;
            });
            Rox.setCustomNumberProperty(
              "createdAt",
              () => getFeatureUser()?.insertedAt?.toSeconds() ?? 0
            );
            await setup(this._handleConfiguration);
            this.readyTimeout = setTimeout(() => void this._makeReady(), 500);
          } catch (error) {
            console.warn(error);
            onError?.(error);
            this.readyTimeout = setTimeout(() => void this._makeReady(), 500);
          }
        }

        componentWillUnmount() {
          this.unmounted = true;
        }

        componentDidUpdate() {
          void this._makeReady();
        }

        render() {
          return this.state.ready ? <WrappedComponent {...this.props} /> : null;
        }
      }
    });
