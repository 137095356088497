import React, { type PropsWithChildren, type ReactNode } from "react";
import { ContentArea } from "../../atoms";
import type { StepperLayoutProps } from "../../layout/Stepper";
import {
  Column,
  Icon,
  type IconName,
  Row,
  Spacer,
  Text,
  type UnitSize
} from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import {
  StepWithIndicators,
  type StepWithIndicatorsProps
} from "./StepWithIndicators";

export type StepWithTitleProps = StepWithIndicatorsProps & {
  icon: IconName;
  title: string;
  description?: string;
};

type Props = StepperLayoutProps<StepWithTitleProps> & { header?: JSX.Element };

export function StepWithTitle({ children, steps, current, ...props }: Props) {
  const { icon, title, description } = current.options;
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const titleSuffix = isMd && (
    <Row gap="compact">
      <Text color="primary">•</Text>
      <Text color="primary">
        Step&nbsp;{current.normalizedIndex}/{steps.length}
      </Text>
    </Row>
  );

  return (
    <StepWithIndicators steps={steps as any} current={current} {...props}>
      <StepWithTitleLayout
        icon={icon}
        title={title}
        description={description}
        titleSuffix={titleSuffix}
      >
        {children}
      </StepWithTitleLayout>
    </StepWithIndicators>
  );
}

type StepWithTitleLayoutProps = Omit<StepWithTitleProps, "testID"> & {
  titleSuffix?: ReactNode;
  gap?: UnitSize;
};

export function StepWithTitleLayout({
  gap,
  icon,
  title,
  titleSuffix,
  description,
  children
}: PropsWithChildren<StepWithTitleLayoutProps>) {
  const isLg = useMatchesViewport(({ size }) => size.large.up);
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const styles = useStyles(({ getFontSize }) => ({
    icon: { ...getFontSize(6, 1), width: 34 },
    iconSpacer: { width: 34 }
  }));

  return (
    <ContentArea
      {...(isLg
        ? { size: "xlarge", variant: "xlarge" }
        : isMd
          ? { size: "large", variant: "xlarge", constraint: "small" }
          : {
              size: "standard",
              constraint: "xsmall",
              constrainedVariant: "none"
            })}
    >
      <Row gap="standard">
        {!!isLg && (
          <Icon
            style={styles.icon}
            name={icon}
            align="center"
            variant="solid"
            color="primary"
          />
        )}
        <Row gap="compact" alignItems="center" fill>
          <Text
            variant={isLg ? "titleLg" : "title"}
            color="primary"
            weight="semibold"
          >
            {title}
          </Text>
          {titleSuffix}
        </Row>
      </Row>
      <Row gap="standard">
        {!!isLg && <Column style={styles.iconSpacer} />}
        <Column fill>
          {!!description && (
            <>
              <Spacer size={isLg ? "standard" : "medium"} />
              <Text variant={isLg ? "bodyLg" : "body"}>{description}</Text>
            </>
          )}
          <Spacer size={isLg ? "xlarge" : "large"} />
          <Column gap={gap}>{children}</Column>
        </Column>
      </Row>
    </ContentArea>
  );
}
