export const digits = (value?: string | null) => {
  if (!value) return "";
  return value.replace(/[^0-9]/g, "");
};

export const currencyDigits = (value?: string | null) => {
  const d = digits(value);
  if (d === "0") return "0";
  return d.replace(/^0+/, "");
};

export const clearLeadingZeros = (value?: string | null | undefined) => {
  if (!value) return "";
  const newValue = value.replace(/^0+/g, "");
  if (newValue[0] === ".") return `0${newValue}`;
  return newValue;
};
