import memoize from "lodash/memoize";
import pick from "lodash/pick";
/* eslint-disable react/prop-types */
import { type ChangeEvent, forwardRef } from "react";

import { StyleSheet, type TextInputProps } from "react-native";
import { unstable_createElement } from "react-native-web";
import TextMask, { type maskArray } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { type TextStyleProp, theme } from "../../style";
import { isAmex } from "../credit-card-utils";
import type { ComputeMaskFn, ValidMask } from "./types";

interface Props extends TextInputProps {
  mask?: ValidMask;
  style?: TextStyleProp;
  normalize?: (str: string) => string;
  format?: (str: string) => string;
}

interface MaskProps {
  mask: maskArray | ((value?: string | null) => maskArray);
}

const MaskedTextInput = forwardRef(
  ({ mask: maskName, normalize, format, ...props }: Props, ref) => {
    if (format && props.value) props.value = format(props.value);
    return unstable_createElement<any>(TextMask, {
      ref,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = normalize ? normalize(e.target.value) : e.target.value;
        props.onChange?.(e as any);
        props.onChangeText?.(e.target.value);
      },
      onKeyDown: (e: KeyboardEvent) => {
        if (e.key === "Enter" && props.onSubmitEditing)
          props.onSubmitEditing(e as any);
      },
      guide: true,
      ...mapInputProps(props),
      ...computeMaskProps(maskName, props)
    });
  }
);

export default MaskedTextInput;

//
//

const readMask = (str: string) => {
  const dict: Record<string, string | RegExp> = {
    "#": /\d/
  };
  return str.split("").map((d) => dict[d] || d);
};

const getMoneyMask = memoize((integerLimit?: number) =>
  createNumberMask({
    prefix: "",
    suffix: "",
    allowDecimal: true,
    allowLeadingZeroes: false,
    allowNegative: false,
    includeThousandsSeparator: false,
    decimalLimit: 2,
    integerLimit
  })
);

const getDecimalMask = memoize((integerLimit?: number, decimalLimit?: number) =>
  createNumberMask({
    prefix: "",
    suffix: "",
    allowDecimal: true,
    allowLeadingZeroes: false,
    allowNegative: false,
    includeThousandsSeparator: false,
    decimalLimit,
    integerLimit
  })
);

const masks = {
  timeMask2: readMask("##"),
  timeMask3: readMask("#:##"),
  timeMask4: readMask("##:##"),
  dateMask: readMask("##/##/####"),
  phoneNumberMask: readMask("(###) ###-####"),
  ccAmex: readMask("#### ###### #####"),
  cc: readMask("#### #### #### ####"),
  oneDecimal: readMask("##.#")
};

const computeMaskProps: ComputeMaskFn<MaskProps> = (
  mask,
  { value, onChangeText, onBlur }
) => {
  let integerLimit: string;

  switch (mask) {
    case "time":
      return {
        guide: false,
        mask: (inputValue) => {
          const digits =
            inputValue
              ?.replace(/[^0-9]/g, "")
              .trim()
              .slice(0, 4) ?? "";
          return digits.length < 3
            ? masks.timeMask2
            : digits.length < 4
              ? masks.timeMask3
              : masks.timeMask4;
        },
        onBlur: (e: any) => {
          if (value && onChangeText) {
            const [h, m] = value.split(":");
            let fmtValue = value;
            if (h?.length === 1) fmtValue = `0${fmtValue}`;
            if (!m) fmtValue = `${fmtValue}:00`;
            if (fmtValue !== value) onChangeText(fmtValue);
          }
          onBlur?.(e);
        }
      };
    case "date":
      return { mask: masks.dateMask };
    case "phoneNumber":
      return { mask: masks.phoneNumberMask };
    case "creditCard":
      return {
        mask: isAmex(value) ? masks.ccAmex : masks.cc
      };
    case "oneDecimal":
      return {
        mask: getDecimalMask(3, 1),
        onBlur: (e: any) => {
          if (value && !Number.isNaN(Number(value))) {
            const fmtValue = Number(value).toFixed(1);
            if (fmtValue !== value) onChangeText?.(fmtValue);
          }
          onBlur?.(e);
        }
      };
    case "money":
    case "money:3":
    case "money:4":
      [, integerLimit = "4"] = mask.split(":");
      return {
        mask: getMoneyMask(
          integerLimit ? Number.parseInt(integerLimit, 10) : undefined
        ),
        onBlur: (e: any) => {
          if (value && !Number.isNaN(Number(value))) {
            const fmtValue = Number(value).toFixed(2);
            if (fmtValue !== value) onChangeText?.(fmtValue);
          }
          onBlur?.(e);
        }
      };
  }
};

const styles = StyleSheet.create({
  textinput: {
    MozAppearance: "textfield",
    WebkitAppearance: "none",
    backgroundColor: theme.color.transparent,
    border: "0 solid black",
    borderRadius: 0,
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
    resize: "none"
  }
});

const forwardPropsList = [
  "accessibilityLabel",
  "accessibilityLiveRegion",
  "accessibilityRole",
  "accessibilityState",
  "accessibilityValue",
  "accessible",
  "autoFocus",
  "defaultValue",
  "maxLength",
  "placeholder",
  "pointerEvents",
  "testID",
  "value",
  // events
  "onBlur"
];

const mapInputProps = ({
  placeholderTextColor,
  style,
  keyboardType,
  editable,
  ...props
}: TextInputProps) => {
  let type;
  switch (keyboardType) {
    case "email-address":
      type = "email";
      break;
    case "number-pad":
    case "numeric":
      type = "number";
      break;
    case "phone-pad":
      type = "tel";
      break;
    // case "search":
    case "web-search":
      type = "search";
      break;
    case "url":
      type = "url";
      break;
    default:
      type = "text";
  }

  return {
    ...pick(props, forwardPropsList),
    style: [styles.textinput, style, { placeholderTextColor }],
    type,
    readOnly: !editable
  };
};
