import React, { type ComponentProps, type ReactNode } from "react";

import ContentRow from "../atoms/ContentRow";
import IconText from "../atoms/IconText";
import Column from "../quarks/Column";
import Text from "../quarks/Text";

interface Props {
  left?: ReactNode;
  right?: ReactNode;
  label?: string;
  children?: ReactNode;
  icon?: ComponentProps<typeof IconText>["icon"];
  iconColor?: ComponentProps<typeof IconText>["iconColor"];
  iconSpacing?: "standard" | "medium" | "compact" | "slim";
  variant?: ComponentProps<typeof ContentRow>["variant"];
}

const SectionHeader = ({
  left,
  right,
  label,
  variant,
  icon,
  iconColor,
  iconSpacing,
  children
}: Props) => (
  <ContentRow size="header" variant={variant} alignItems="center">
    {left}
    <Column fill>
      {!label ? null : icon ? (
        <IconText
          icon={icon}
          iconColor={iconColor}
          size="small"
          spacing={iconSpacing}
          color="primary"
          textVariant="subheader"
          textWeight="semibold"
        >
          {label}
        </IconText>
      ) : (
        <Text variant="subheader" color="primary" weight="medium">
          {label}
        </Text>
      )}
      {children}
    </Column>
    {right}
  </ContentRow>
);

export default SectionHeader;
