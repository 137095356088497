import * as Immutable from "immutable";
import {
  type ActFn,
  type InitFn,
  type RespondFn,
  createScene
} from "./create-scene";
import { mapToObject } from "./helpers";
import type { ErrorMap, ValueMap } from "./types";

export interface FormSceneType {
  initialValues: ValueMap;
  values: ValueMap;
  sensitives: Immutable.OrderedMap<string, Immutable.Set<string>>;
  errors: ErrorMap;
  submitting: boolean;
  invalid: boolean;
  dirty: boolean;
}

export type FormEvents =
  | "values"
  | "errors"
  | "submitting"
  | "submit"
  | "dirty"
  | "invalid"
  | "reset";

export type FormRespondFn = RespondFn<FormEvents, FormSceneType>;
export type FormInitFn = InitFn<FormEvents, FormSceneType>;
export type FormActFn = ActFn<FormEvents, FormSceneType>;

export const defaultScene = {
  initialValues: Immutable.OrderedMap<string, any>(),
  values: Immutable.OrderedMap<string, any>(),
  errors: Immutable.OrderedMap<string, Error[] | null | undefined>(),
  sensitives: Immutable.OrderedMap<string, Immutable.Set<string>>(),
  submitting: false,
  invalid: true,
  dirty: false
};

export const {
  Director,
  Responder,
  Initer,
  Actor,
  useActor,
  useResponder,
  useInit
} = createScene<FormEvents, FormSceneType>(
  "Form",
  {
    initialValues: Immutable.OrderedMap<string, any>(),
    values: Immutable.OrderedMap<string, any>(),
    sensitives: Immutable.OrderedMap<string, Immutable.Set<string>>(),
    errors: Immutable.OrderedMap<string, Error[] | null>(),
    submitting: false,
    invalid: false,
    dirty: false
  },
  (scene: FormSceneType) => {
    if (!scene.initialValues || scene.initialValues.isEmpty()) {
      scene.initialValues = scene.values;
    }
    return scene;
  },
  (label, eventName, caller, scene) =>
    console.debug(`["Form.${label}]`, eventName, label, caller, {
      initialValues: mapToObject(scene.initialValues),
      values: mapToObject(scene.values),
      sensitives: mapToObject(scene.sensitives),
      errors: scene.errors.reduce(
        (acc, v, k) => ({ ...acc, [k]: v?.map((e) => e.message) }),
        {}
      ),
      submitting: scene.submitting,
      invalid: scene.invalid,
      dirty: scene.dirty
    })
);
