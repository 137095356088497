import { AppState, type AppStateStatus } from "react-native";
import { addInitHook, collectHooks, track } from "../hooks";

export default async () => {
  // Track Application Opens
  let currentAppState: AppStateStatus;

  // Handle Next State
  const handleNextState = (nextAppState: AppStateStatus) => {
    if (nextAppState !== currentAppState) {
      const event = eventMapping[nextAppState];
      if (event) void track(event);
    }
    currentAppState = nextAppState;
  };

  return collectHooks([
    AppState.addEventListener("change", handleNextState).remove,
    addWindowListener(),
    addInitHook(async () => {
      const currentAppState = AppState.currentState;
      handleNextState(currentAppState);
    })
  ]);
};

const addWindowListener = () => {
  if (window?.addEventListener) {
    window.addEventListener("beforeunload", handleBeforeUnload);
  }
  return () => {
    if (window?.removeEventListener) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  };
};

const handleBeforeUnload = () => {
  const event = eventMapping.background as string;
  if (event) void track(event);
};

const eventMapping: Record<AppStateStatus, string | null> = {
  active: "Application Opened",
  background: null,
  inactive: null,
  unknown: null,
  extension: null
};
