const alphabet = "26T198340PX75JACKVERYMINDBUSHWOLFGQZ";
const randomStr = (str = alphabet, size = 8) => {
  let id = "";
  let count = size;
  while (count--) id += str[(Math.random() * str.length) | 0] as string;
  return id;
};

export class DekigotoError<E extends Error = Error> extends Error {
  id?: string;
  public readonly cause?: E;

  constructor(cause: E | string) {
    if (typeof cause !== "string") {
      super(cause.message, { cause });
    } else {
      super(cause);
    }
    this.name = "DekigotoError";
    this.id = randomStr();
    if (cause instanceof DekigotoError) {
      // biome-ignore lint/correctness/noConstructorReturn: need to return the original error
      return cause;
    }
  }
}
