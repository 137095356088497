import React from "react";
import { create } from "zustand";
import {
  Button,
  ContentArea,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../atoms";
import { showModal } from "../../organisms";
import { KeyboardAvoidingView } from "../../quarks";

interface WrapperProps {
  children: React.ReactNode;
  filterCount: number;
  appliedFilters?: Record<string, unknown>;
  onResetFilters: () => void;
  submitLabel: string;
  onSubmit: (filters: Record<string, unknown>) => void;
}

interface FilterProps extends WrapperProps {
  onClose: () => void;
}

type Store = {
  filtersCount: number;
  initialFilters: Record<string, unknown>;
  setFiltersCount: (count: number) => void;
  setInitialFilters: (filters: Record<string, unknown>) => void;
};

const useStore = create<Store>()((set) => ({
  filtersCount: 0,
  initialFilters: {},
  setFiltersCount: (count: number) => set(() => ({ filtersCount: count })),
  setInitialFilters: (filters: Record<string, unknown>) =>
    set(() => ({ initialFilters: filters }))
}));

function FilterModal({
  children,
  onResetFilters,
  submitLabel,
  onSubmit,
  onClose
}: FilterProps) {
  const { filtersCount, initialFilters } = useStore();

  return (
    <>
      <ModalHeader
        title="Filters"
        icon="filter"
        titleAction={
          filtersCount > 0 && (
            <Button
              testID="reset-filters-btn"
              outline
              size="small"
              icon="undo"
              label="Reset Filters"
              onPress={onResetFilters}
            />
          )
        }
        onClose={() => {
          onSubmit(initialFilters);
          onClose();
        }}
      />
      <ModalBody scrollable enableResetScrollPosition={false}>
        <KeyboardAvoidingView>
          <ContentArea>{children}</ContentArea>
        </KeyboardAvoidingView>
      </ModalBody>
      <ModalFooter>
        <Button
          onPress={() => {
            onClose();
          }}
          label={submitLabel}
          disabled={filtersCount === 0}
          testID="filter-cta-btn"
        />
      </ModalFooter>
    </>
  );
}

export default function MobileFilter(props: WrapperProps) {
  const { setFiltersCount, setInitialFilters } = useStore();

  React.useEffect(() => {
    setFiltersCount(props.filterCount);
  }, [props.filterCount]);

  return (
    <Button
      testID="filter-drawer-button-mobile"
      size="small"
      outline
      label="Filters"
      variant="clear"
      icon="filter"
      onPress={() => {
        setInitialFilters(props?.appliedFilters ?? {});
        showModal({
          testID: "filter-modal",
          eventContext: "Filter Modal",
          useModalBody: false,
          children: (close) => <FilterModal {...props} onClose={close} />,
          variant: "full-shadow",
          fixedHeight: true
        });
      }}
    />
  );
}
