import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType, type ReactElement } from "react";
import type { DeclarativeMutationConfig, GraphQLTaggedNode } from "react-relay";
import type {
  OperationType,
  RecordSourceSelectorProxy,
  SelectorStoreUpdater
} from "relay-runtime";
import { useRelaySubscription } from "./use-relay-subscription";

interface WithRelaySubscriptionOptions<TOperation extends OperationType> {
  onCompleted?: () => void;
  onError?: (error: Error) => void;
  onNext?: (response: TOperation["response"] | null | undefined) => void;
  withUpdater?: (
    props: Record<string, any>,
    store: RecordSourceSelectorProxy<TOperation["response"]>
  ) => unknown;
  withConfigs?: (props: Record<string, unknown>) => DeclarativeMutationConfig[];
  withSubscribe?: (props: Record<string, unknown>) => boolean;
  waitUntilReady?: boolean;
  fallback?: ReactElement | null | undefined;
}

export const withRelaySubscription =
  <TOperation extends OperationType>(
    subscription: GraphQLTaggedNode,
    rawVariables:
      | Object
      | ((compProps: object) => TOperation["variables"]) = {},
    {
      withUpdater,
      withSubscribe,
      withConfigs,
      waitUntilReady = true,
      fallback = null,
      ...decoratorOptions
    }: WithRelaySubscriptionOptions<TOperation> = {}
  ): HOCVoid =>
  (WrappedComponent: ComponentType<any>) =>
    applyHOCProperties({
      displayName: "withRelaySubscription",
      WrappedComponent,
      HigherOrderComponent: (props: any) => {
        const variables =
          typeof rawVariables === "function"
            ? rawVariables(props)
            : rawVariables;
        const updater:
          | SelectorStoreUpdater<TOperation["response"]>
          | undefined = withUpdater
          ? (store: RecordSourceSelectorProxy<TOperation["response"]>) =>
              withUpdater(variables, store)
          : undefined;
        const { ready } = useRelaySubscription<any>(subscription, variables, {
          updater,
          configs: withConfigs ? withConfigs(props) : undefined,
          subscribe: withSubscribe?.(props),
          ...decoratorOptions
        });
        return ready ?? !waitUntilReady ? (
          <WrappedComponent {...props} />
        ) : (
          fallback
        );
      }
    });
