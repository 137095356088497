import React from "react";
import { RRule } from "rrule";
import type { Weekday } from "rrule";
import { Button, Stack } from "../atoms";
import { Column } from "../quarks";
import { useMatchesViewport, useStyle } from "../style";

export interface Day {
  short: "S" | "M" | "T" | "W" | "T" | "F" | "S";
  medium: "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";
  day:
    | "Sunday"
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday";
  rrule: Weekday;
}

interface Props {
  value?: Weekday[] | null;
  onChange?: (value: Weekday[]) => void;
  testID?: string;
}

const days: Day[] = [
  { short: "S", medium: "Sun", day: "Sunday", rrule: RRule.SU },
  { short: "M", medium: "Mon", day: "Monday", rrule: RRule.MO },
  { short: "T", medium: "Tue", day: "Tuesday", rrule: RRule.TU },
  { short: "W", medium: "Wed", day: "Wednesday", rrule: RRule.WE },
  { short: "T", medium: "Thu", day: "Thursday", rrule: RRule.TH },
  { short: "F", medium: "Fri", day: "Friday", rrule: RRule.FR },
  { short: "S", medium: "Sat", day: "Saturday", rrule: RRule.SA }
];

export default function WeekDayPicker({ value, onChange, testID }: Props) {
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const style = useStyle(() => ({
    maxWidth: 580,
    width: "100%",
    marginHorizontal: "auto"
  }));
  return (
    <Column style={style}>
      <Stack horizontal size="compact">
        {days.map((day) => (
          <Button
            key={`${testID ?? "weekday"}-${day.day}`}
            testID={`${testID ?? "weekday"}-${day.day}`}
            label={day[isMd ? "medium" : "short"]}
            outline={!value?.includes(day.rrule)}
            onPress={() => {
              if (value?.includes(day.rrule)) {
                onChange?.(value.filter((item) => item !== day.rrule));
              } else {
                onChange?.([...(value ?? []), day.rrule]);
              }
            }}
            size={isMd ? undefined : "small"}
            fill
          />
        ))}
      </Stack>
    </Column>
  );
}
