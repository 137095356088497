import React, {
  Children,
  isValidElement,
  type ReactNode,
  type ComponentProps
} from "react";
import { View } from "react-native";
import Divider from "../quarks/Divider";
import Spacer from "../quarks/Spacer";
import {
  type Color,
  type ThemeableMediaThunk,
  type UseTransformFlexProps,
  useMatchesViewport,
  useTransformFlexProps
} from "../style";

export interface Props extends Omit<UseTransformFlexProps, "direction"> {
  horizontal?: boolean | ThemeableMediaThunk;
  size?: ComponentProps<typeof Spacer>["size"];
  children?: ReactNode;
  variant?: "standard" | "divider";
  dividerColor?: Color;
  testID?: string;
  reverse?: boolean;
  reorder?: number[];
}

export default function Stack({
  horizontal,
  size = "standard",
  children,
  variant = "standard",
  testID,
  reverse,
  reorder,
  dividerColor,
  ...props
}: Props) {
  const matchesViewport = useMatchesViewport(
    typeof horizontal === "boolean" ? undefined : horizontal
  );
  const matchesHorizontal =
    typeof horizontal === "boolean" ? horizontal : matchesViewport;
  const direction = `${matchesHorizontal ? "row" : "column"}${
    reverse ? "-reverse" : ""
  }` as const;
  const transformedProps = useTransformFlexProps({ direction, ...props });
  const validChildren = sortChildren(
    Children.toArray(children),
    reorder
  ).filter((child) => isValidElement(child) && !!child);

  if (!validChildren.length) {
    // do not render empty stacks
    return null;
  }

  return (
    <View {...transformedProps}>
      {validChildren.flatMap((child, index) => [
        index > 0 &&
          (variant === "standard" ? (
            <Spacer key={index} size={size} horizontal={matchesHorizontal} />
          ) : (
            <Divider
              key={index}
              color={dividerColor}
              dir={matchesHorizontal ? "y" : "x"}
            />
          )),
        child
      ])}
    </View>
  );
}

function sortChildren<T>(arr: T[], reorder?: number[] | null) {
  if (!reorder?.length) return arr;
  const copy: Array<T | undefined> = [...arr];
  const newArr: typeof copy = [];
  for (const idx of reorder) {
    const elem = copy[idx];
    if (elem !== undefined) {
      copy[idx as number] = undefined;
      newArr.push(elem);
    }
  }
  for (const item of copy) {
    if (item !== undefined) newArr.push(item);
  }
  return newArr;
}
