import { latLngEquals, useMap } from "@vis.gl/react-google-maps";
import {
  type Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import React from "react";
import type { LatLng } from "react-native-maps";
import { latlng } from "../helpers";

const METERS_PER_MILE = 1609.34;

type CircleRef = Ref<google.maps.Circle | null>;

type Props = {
  center: LatLng;
  radius: number; // in miles
  editable?: boolean;
  draggable?: boolean;
  onChangeCenter?: (center: LatLng) => void;
  strokeColor?: string;
  strokeWeight?: number;
  fillColor?: string;
  fillOpacity?: number;
};

function useCircle({
  center,
  radius,
  onChangeCenter,
  strokeColor = "#0c4cb3",
  strokeWeight = 1,
  fillColor = "rgba(0, 59, 92, 0.4)",
  fillOpacity = 0.3,
  ...circleProps
}: Props) {
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const c = new google.maps.Circle({
      ...circleProps,
      center: latlng.toLiteral(center),
      radius: radius * METERS_PER_MILE,
      map,
      strokeColor,
      strokeOpacity: 1,
      strokeWeight,
      fillColor,
      fillOpacity
    });

    setCircle(c);
    return () => {
      c.setMap(null);
      setCircle(null);
    };
  }, [map]);

  useEffect(() => {
    if (!circle) return;
    const pos = latlng.toLiteral(center);
    if (!latLngEquals(pos, circle.getCenter())) circle.setCenter(pos);
  }, [center?.latitude, center?.longitude]);

  useEffect(() => {
    if (!circle) return;
    circle.setOptions({
      ...circleProps,
      radius: radius * METERS_PER_MILE
    });
  }, [radius, circleProps]);

  useEffect(() => {
    if (!circle) return;

    const gme = google.maps.event;
    const changeCenter = () => {
      const c = circle.getCenter();
      if (c) onChangeCenter?.(latlng(c.toJSON()));
    };

    gme.addListener(circle, "dragend", changeCenter);
    return () => gme.clearInstanceListeners(circle);
  }, [onChangeCenter, circle]);

  return circle;
}

/**
 * Component to render a circle on a map
 */
export default forwardRef(function Circle(props: Props, ref: CircleRef) {
  const circle = useCircle(props);
  useImperativeHandle(ref, () => circle, [circle]);
  return null;
});
