import React, { type ReactElement, type ReactNode } from "react";
import { Text } from "../quarks";
import type { Color } from "../style/theme/colors";
import ListRow from "./ListRow";
import type Sticker from "./Sticker";

interface Props {
  name: string;
  description?: string;
  descriptionColor?: Color;
  sticker?: ReactElement<typeof Sticker>;
  right?: ReactNode;
  testID?: string;
}

const GroupRow = ({
  sticker,
  name,
  description,
  descriptionColor = "neutral",
  right,
  testID
}: Props) => (
  <ListRow label={name} left={sticker} right={right} testID={testID}>
    {!!description && (
      <Text variant="note" color={descriptionColor}>
        {description}
      </Text>
    )}
  </ListRow>
);

export default GroupRow;
