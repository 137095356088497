import React, { type ReactElement } from "react";
import type { ViewStyle } from "react-native";
import Icon, { type IconName } from "../quarks/Icon";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { ColorPlacementProvider } from "../style/colorPlacement";
import { useStyles } from "../style/styles";
import type { Color } from "../style/theme/colors";
import SvgXml from "./SvgXml";

type FATag = { iconType?: "FONT_AWESOME"; icon?: IconName };
type ComponentTag = { iconType: "ELEMENT"; icon: ReactElement };
type SVGTag = { iconType: "SVG"; icon: string };

type Props = {
  label: string;
  variant?: "clear" | "outline" | "solid";
  color?: Color;
  iconColor?: Color;
  testID?: string;
  alignSelf?: ViewStyle["alignSelf"];
} & (ComponentTag | FATag | SVGTag);

export default function Tag({
  label,
  color = "neutral",
  iconColor,
  variant = "solid",
  testID,
  alignSelf = "flex-start",
  ...rest
}: Props) {
  const hasIcon = "icon" in rest && !!rest.icon;
  const styles = useStyles(
    ({ getColor, getUnits }) => {
      const mainColor = getColor(color, "fill", { fallthrough: false });
      const textColor =
        variant === "solid" ? getColor(color, "placement") : mainColor;

      let viewStyle: ViewStyle = {};
      if (variant === "solid") {
        viewStyle = { backgroundColor: mainColor };
      } else if (variant === "clear") {
        viewStyle = {
          backgroundColor: getColor(color, "fill", {
            fallthrough: false,
            opacity: 0.08
          })
        };
      } else if (variant === "outline") {
        viewStyle = {
          backgroundColor: "transparent",
          borderWidth: 1,
          borderColor: mainColor
        };
      }

      return {
        view: {
          ...viewStyle,
          height: getUnits(5),
          paddingHorizontal: getUnits(2),
          borderRadius: getUnits(1)
        },
        text: {
          color: textColor,
          marginLeft: hasIcon ? getUnits(1) : undefined,
          fontSize: getUnits(3),
          fontWeight: "600"
        }
      };
    },
    [color, hasIcon, variant]
  );
  return (
    <ColorPlacementProvider
      color={color}
      use={variant === "clear" || variant === "outline" ? "fill" : "placement"}
    >
      <Row
        alignItems="center"
        justifyContent="center"
        style={styles.view}
        testID={testID}
        alignSelf={alignSelf}
      >
        {hasIcon ? (
          (rest.iconType ?? "FONT_AWESOME") === "FONT_AWESOME" &&
          typeof rest.icon === "string" ? (
            <Icon
              name={rest.icon as IconName}
              size="tiny"
              variant="solid"
              color={iconColor}
            />
          ) : rest.iconType === "SVG" ? (
            <SvgXml xml={rest.icon as string} height={12} width={12} />
          ) : (
            rest.icon
          )
        ) : null}
        <Text style={styles.text} selectable={false} testID={`${testID}-label`}>
          {label}
        </Text>
      </Row>
    </ColorPlacementProvider>
  );
}
