import React, { type ReactNode } from "react";
import Constraint from "../atoms/Constraint";

interface Props {
  children?: ReactNode;
  testID?: string;
}

/** @deprecated Screen should cover it */
export default function StandaloneScreenHeader({ testID, children }: Props) {
  return (
    <Constraint testID={testID} size="xlarge">
      {children}
    </Constraint>
  );
}
