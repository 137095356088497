import React, { type ReactNode, type ComponentProps } from "react";

import Column from "../quarks/Column";

interface Props extends ComponentProps<typeof Column> {
  bottom?: number;
  top?: number;
  children: ReactNode;
}

export default function MaxWidthContainer({
  bottom,
  top,
  children,
  ...rest
}: Props) {
  return (
    <Column {...rest} style={{ marginBottom: bottom, marginTop: top }}>
      {children}
    </Column>
  );
}
