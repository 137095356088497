// See: https://relay.dev/docs/en/api-reference#usepreloadedquery

import { useCallback } from "react";
import {
  type PreloadedQuery,
  type UseQueryLoaderLoadQueryOptions,
  useQueryLoader
} from "react-relay";
import type { useQueryLoaderHookType } from "react-relay/relay-hooks/useQueryLoader";

import type {
  GraphQLTaggedNode,
  OperationType,
  PreloadableConcreteRequest,
  VariablesOf
} from "relay-runtime";
import { useRelayBatch } from "./relay-batch";
export type { useQueryLoaderHookType as useRelayQueryLoaderHookType } from "react-relay/relay-hooks/useQueryLoader";
export {
  usePreloadedQuery as useRelayPreloadedQuery,
  loadQuery as loadRelayQuery
} from "react-relay";
export type { PreloadedQuery as RelayPreloadedQuery };

export function useRelayQueryLoader<TQuery extends OperationType>(
  preloadableRequest: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>,
  initialQueryReference?: PreloadedQuery<TQuery> | null
): useQueryLoaderHookType<TQuery> {
  const [preloadedQuery, originalLoadQuery, dispose] = useQueryLoader<TQuery>(
    preloadableRequest,
    initialQueryReference
  );
  const { enable: batch, batchKey } = useRelayBatch(false);
  const loadQuery = useCallback(
    (
      variables: VariablesOf<TQuery>,
      options: UseQueryLoaderLoadQueryOptions = {}
    ) => {
      originalLoadQuery(variables, {
        ...options,
        networkCacheConfig: {
          ...options?.networkCacheConfig,
          batch,
          batchKey
        }
      });
    },
    [batch, batchKey, originalLoadQuery]
  );

  return [preloadedQuery, loadQuery, dispose];
}
