import throttle from "lodash/throttle";
import React, {
  useContext,
  Component,
  createContext,
  type ReactNode
} from "react";
import { Dimensions, type NativeEventSubscription } from "react-native";
import metric from "./metric";
import { type BreakpointType, sortedBreakpoints } from "./style-helpers";
import { breakpoint as breakpointUnit } from "./unit";

export interface ViewportType {
  breakpoint: BreakpointType;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface Props {
  children: ReactNode;
}

const reversedBreakpoints: typeof sortedBreakpoints = [
  ...sortedBreakpoints
].reverse();

export const ViewportContext = createContext<ViewportType>({
  breakpoint: "@none",
  isMobile: false,
  isTablet: false,
  isDesktop: false
});

export const currentBreakpoint = (
  windowWidth: number = metric.deviceWidth()
): ViewportType => {
  const breakpoint =
    reversedBreakpoints.find((key) => windowWidth >= breakpointUnit[key]) ??
    "@none";

  const isMobile = windowWidth < breakpointUnit["@tablet"];
  const isTablet = !isMobile && windowWidth < breakpointUnit["@desktop"];
  const isDesktop = !isMobile && !isTablet;
  return { breakpoint, isMobile, isTablet, isDesktop };
};

export function useViewport() {
  return useContext(ViewportContext);
}

/** @deprecated */
export default class ViewportProvider extends Component<Props, ViewportType> {
  dimensionsListener?: NativeEventSubscription;
  state: ViewportType = currentBreakpoint();

  componentDidMount() {
    this.dimensionsListener = Dimensions.addEventListener(
      "change",
      this.updateDimensions
    );
  }

  componentWillUnmount() {
    this.dimensionsListener?.remove();
  }

  updateDimensions = throttle(() => {
    const { breakpoint } = this.state;
    const current = currentBreakpoint();
    if (current.breakpoint !== breakpoint) {
      this.setState(current);
    }
  }, 200);

  render() {
    const { children } = this.props;
    return (
      <ViewportContext.Provider value={this.state}>
        {children}
      </ViewportContext.Provider>
    );
  }
}
