import React, { type ComponentProps, type ReactNode } from "react";
import type { TextStyle } from "react-native";

import Icon, {
  type IconName,
  type IconSize,
  type IconVariant
} from "../quarks/Icon";
import Text from "../quarks/Text";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";
import Stack from "./Stack";

const textSizes = {
  tiny: 3,
  small: 3.5,
  medium: 4,
  large: 5
};

interface Props {
  testID?: string;
  icon: IconName;
  children: ReactNode;
  size?: keyof typeof textSizes;
  iconSize?: IconSize;
  iconAlign?: TextStyle["textAlign"];
  iconPlacement?: "left" | "right";
  spacing?: "standard" | "medium" | "compact" | "slim";
  color?: Color;
  iconColor?: Color;
  iconVariant?: IconVariant;
  fill?: boolean | number;
  textWeight?: ComponentProps<typeof Text>["weight"];
  textVariant?: ComponentProps<typeof Text>["variant"];
  numberOfLines?: number;
}

export default function IconText({
  icon,
  iconPlacement = "left",
  children,
  iconVariant = "solid",
  size = "medium",
  iconSize,
  iconAlign = "left",
  color,
  iconColor,
  spacing = "slim",
  testID,
  fill,
  textWeight,
  textVariant,
  numberOfLines
}: Props) {
  const flex = fill === true ? 1 : fill || undefined;
  const styles = useStyles(
    ({ getFontSize }) => ({
      text: { flex, ...getFontSize(textSizes[size]) }
    }),
    [size, flex]
  );

  const iconNode = icon ? (
    <Icon
      name={icon}
      size={iconSize ?? size}
      variant={iconVariant}
      color={iconColor ?? color}
      align={iconAlign}
    />
  ) : null;

  return (
    <Stack
      fill={flex}
      size={spacing}
      horizontal
      alignItems="center"
      testID={testID}
    >
      {iconPlacement === "left" && iconNode}
      <Text
        color={color}
        style={styles.text}
        testID={`${testID}-text`}
        weight={textWeight}
        variant={textVariant}
        wrap
        numberOfLines={numberOfLines}
      >
        {children}
      </Text>
      {iconPlacement === "right" && iconNode}
    </Stack>
  );
}
