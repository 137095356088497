import React from "react";
import { useStyles } from "../style";
import IconText from "./icon-text";
import type { IconName } from "./styled-icon";

interface Props {
  tintColor?: string;
  iconName?: IconName;
  text: string;
}

const IconHeader = ({ text, ...props }: Props) => {
  const { styles } = useStyles(({ font }) => ({
    text: {
      ...font.body("semibold"),
      fontSize: font.size.large
    }
  }));
  return (
    <IconText iconSize={16} textStyle={styles.text} {...props}>
      {text}
    </IconText>
  );
};

export default IconHeader;
