import React from "react";
import { FlatList, type FlatListProps } from "react-native";

import { createScreenComponent } from "./ScreenWrapper";

type Props<TItem> = FlatListProps<TItem> & {
  testID: string;
};

const ScreenList = createScreenComponent(<T,>(props: Props<T>) => (
  <FlatList {...props} />
));

export default ScreenList;
