import { init } from "../hooks";
import { logger } from "../logger";
import ConsoleIntegration from "./console";
import crashlytics from "./crashlytics";
import featureFlags from "./featureFlags";
import firebase from "./firebase";
import install from "./install";
import lifecycle from "./lifecycle";
import listenlayer from "./listenlayer";
import mixpanel from "./mixpanel";
import rudderstack from "./rudderstack";
import sentry from "./sentry";
import utm from "./utm";

type MaybePromise<T> = T | Promise<T>;

const integrations = (<K extends string>(
  o: Record<K, () => MaybePromise<() => MaybePromise<void>>>
) => o)({
  rudderstack,
  crashlytics,
  firebase,
  lifecycle,
  install,
  sentry,
  featureFlags,
  listenlayer,
  console: ConsoleIntegration,
  mixpanel,
  utm
});

export type Integration = keyof typeof integrations;

const loaded: Partial<Record<Integration, () => MaybePromise<void>>> = {};

export async function integrate(
  plugins: Partial<Record<Integration, boolean>>
) {
  const [loadables, unloadables] = Object.keys(plugins).reduce<
    [Integration[], Integration[]]
  >(
    ([l, ul], name) =>
      plugins[name as Integration]
        ? [[...l, name as Integration], ul]
        : [l, [...ul, name as Integration]],
    [[], []]
  );
  await Promise.all([load(loadables, false), unload(unloadables)]);
  init();
}

export async function load(
  plugins: Integration | Integration[],
  doInit = true
) {
  if (!Array.isArray(plugins)) plugins = [plugins];
  for (const plugin of plugins) {
    if (typeof loaded[plugin as Integration] === "undefined") {
      try {
        logger.info("[dekigoto:integrations:loading]", plugin);
        loaded[plugin] = await integrations[plugin]();
        logger.info("[dekigoto:integrations:loaded]", plugin);
      } catch (e) {
        logger.error("[dekigoto:integrations:load_error]", plugin, e);
      }
    }
  }
  if (doInit) init();
}

export async function unload(plugins: Integration | Integration[]) {
  if (!Array.isArray(plugins)) plugins = [plugins];
  for (const plugin of plugins) {
    if (typeof loaded[plugin as Integration] === "undefined") {
      logger.info("[dekigoto:integrations:unload]", plugin);
      const unloader = loaded[plugin as Integration];
      if (unloader) {
        try {
          await unloader();
          loaded[plugin as Integration] = undefined;
        } catch (e) {
          logger.error("[dekigoto:integrations:unload_error]", plugin, e);
        }
      }
    }
  }
}
