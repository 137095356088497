import React, { type ReactNode } from "react";
import { type DimensionValue, View } from "react-native";
import { type ViewStyle, useStyles, useTheme } from "../style";
import type { ThemeMedia } from "../style/theme/media";

interface Props {
  size?: keyof ThemeMedia["size"];
  zIndex?: number;
  children: ReactNode;
  alignItems: ViewStyle["alignItems"];
  left?: DimensionValue;
  right?: DimensionValue;
  top?: DimensionValue;
  bottom?: DimensionValue;
  style?: ViewStyle;
}

export default function AbsoluteContainer({
  size,
  zIndex = 2,
  children,
  alignItems,
  left,
  right,
  top,
  bottom,
  style
}: Props) {
  const { media } = useTheme();
  const { maxWidth } = size ? media.size[size] : { maxWidth: undefined };
  const styles = useStyles(
    () => ({
      outer: {
        position: "absolute",
        zIndex,
        left,
        right,
        bottom,
        top,
        alignItems
      },
      inner: {
        width: "100%",
        maxWidth
      }
    }),
    [alignItems, left, right, top, bottom]
  );

  return (
    <View style={[style, styles.outer]}>
      <View style={styles.inner}>{children}</View>
    </View>
  );
}

AbsoluteContainer.defaultProps = {
  alignItems: "center",
  left: 0,
  right: 0
};
