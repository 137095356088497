import React, {
  type ComponentProps,
  type ReactElement,
  type ReactNode
} from "react";
import { Button, Tag } from "../atoms";
import { ActionRow } from "../molecules";
import { type IconName, Text } from "../quarks";
import type { TextProps } from "../quarks/Text";
import type { Color } from "../style/theme/colors";
import InfoModal from "./InfoModal";

interface Props
  extends Pick<
    ComponentProps<typeof ActionRow>,
    "testID" | "variant" | "eventEntityType" | "icon" | "iconSquareVariant"
  > {
  name: ReactNode;
  note?: string | ReactElement<TextProps>;
  infoModal?: ComponentProps<typeof InfoModal>;
  color?: Color;
  callToActionLabel?: string;
  callToActionIcon?: IconName;
  callToActionOnPress?: () => void;
  tagColor?: Color;
  tagText?: string;
  onPress?: () => void;
  leftSpacing?: "medium" | "small";
}

/**
 * @deprecated use <ActionRow /> instead
 */
export default function ProductInfoRow({
  name,
  infoModal,
  leftSpacing = "medium",
  callToActionLabel = "Start",
  callToActionIcon,
  callToActionOnPress,
  color,
  tagColor,
  tagText,
  note,
  testID,
  ...props
}: Props) {
  const right = infoModal ? (
    <InfoModal {...infoModal} />
  ) : callToActionOnPress ? (
    <Button
      testID={`${testID}-call-to-action-btn`}
      icon={callToActionIcon}
      label={callToActionLabel}
      onPress={callToActionOnPress}
      size="small"
      outline
    />
  ) : tagText && tagColor ? (
    <Tag color={tagColor} label={tagText} />
  ) : null;

  if (typeof note === "string") {
    note = <Text variant="note">{note}</Text>;
  }

  return (
    <ActionRow
      testID={testID}
      iconColor={color}
      title={name}
      right={right}
      leftSpacing={leftSpacing}
      {...props}
    >
      {note}
    </ActionRow>
  );
}
