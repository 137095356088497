import { commitMutation } from "react-relay";
import type {
  IEnvironment,
  MutationConfig,
  MutationParameters
} from "relay-runtime";
export * from "./use-relay-mutation";
export * from "./use-commit-local-update";
export * from "./with-relay-mutation";

export { commitMutation };

export async function promiseMutation<TOperation extends MutationParameters>(
  environment: IEnvironment,
  { onCompleted, onError, ...options }: MutationConfig<TOperation>
) {
  return await new Promise((resolve, reject) => {
    commitMutation<TOperation>(environment, {
      ...options,
      onCompleted: (response, errors) => {
        errors ? reject(new Error("Error")) : resolve(response);
        onCompleted?.(response, errors);
      },
      onError: (error: Error) => {
        reject(error);
        onError?.(error);
      }
    });
  });
}
