import React from "react";
import type { ComponentProps } from "react";
import { CheckboxSquare } from "../atoms";
import type { IconName } from "../quarks";
import type { Color } from "../style/theme/colors";
import InputRow from "./InputRow";
import InputRowAccessory from "./InputRowAccessory";

export interface Props extends ComponentProps<typeof CheckboxSquare> {
  title: ComponentProps<typeof InputRow>["title"];
  note?: ComponentProps<typeof InputRow>["note"];
  onTooltipPress?: ComponentProps<typeof InputRow>["onTooltipPress"];
  onEditPress?: ComponentProps<typeof InputRow>["onEditPress"];
  icon?: IconName;
  iconSquare?: IconName;
  iconColor?: Color;
  avatar?: string;
  expand?: boolean;
  errors?: Error[] | null;
}

export default function CheckboxRow({
  testID = "checkbox-row",
  title,
  note,
  onTooltipPress,
  onEditPress,
  icon,
  iconSquare,
  avatar,
  expand,
  iconColor,
  selected,
  onChange,
  ...rest
}: Props) {
  return (
    <InputRow
      testID={testID}
      title={title}
      note={note}
      left={
        <InputRowAccessory
          icon={icon}
          iconSquare={iconSquare}
          avatar={avatar}
          iconColor={iconColor}
        />
      }
      onTooltipPress={onTooltipPress}
      onEditPress={onEditPress}
      onPress={() => onChange?.(!selected)}
      right={
        <CheckboxSquare
          testID={`${testID}-checkbox`}
          onChange={onChange}
          selected={selected}
          {...rest}
        />
      }
      expand={expand}
    />
  );
}
