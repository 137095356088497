import { debounce } from "lodash";
import {
  type AuthMiddlewareOpts,
  type MiddlewareRaw,
  type MiddlewareSync,
  authMiddleware,
  urlMiddleware
} from "react-relay-network-modern/es";
import { RecordSource } from "relay-runtime";
import type { RelayMiddleware } from "../../../types";
import type { RelayOrchestratorOptions } from "../../types";
import type { ProtocolOptions } from "../types";
import batchMiddleware from "./middleware/batcher";
import { etagCacheMiddleware } from "./middleware/etag-cache-middleware";
import sessionPinningMiddleware from "./middleware/session-pinning-middleware";

export async function generateMiddleware(
  options: ProtocolOptions
): Promise<Array<RelayMiddleware | MiddlewareSync | MiddlewareRaw | null>> {
  const {
    graphqlUrl,
    setTokenFn,
    getTokenFn,
    refreshTokenFn,
    EXPERIMENTAL_sessionPinning: sessionPinning,
    EXPERIMENTAL_etagCaching: etagCaching,
    requestHeaders = {}
  } = options;

  const recordSource: RecordSource = new RecordSource({});

  const authOpts: AuthMiddlewareOpts = {
    allowEmptyToken: true,
    token: async () => (await getTokenFn?.()) ?? "",
    tokenRefreshPromise:
      refreshTokenFn &&
      (async (req, res) => {
        const nextToken: string = (await refreshTokenFn(req, res)) ?? "";
        await setTokenFn?.(nextToken);
        return nextToken;
      })
  };
  return [
    urlMiddleware({
      url: async (req) =>
        `${await Promise.resolve(graphqlUrl ?? "")}/${req.operation.name}`
    }),
    batchMiddleware({
      batchUrl: async (batchKey) =>
        `${await Promise.resolve(graphqlUrl ?? "")}/${batchKey}Batch`,
      batchTimeout: 60,
      headers: requestHeaders,
      auth: authOpts
    }),
    generateHeadersMiddleware(requestHeaders),
    authMiddleware(authOpts),
    (next) => async (req) => {
      req.fetchOpts.credentials = "omit";
      return await next(req);
    },
    etagCaching ? etagCacheMiddleware() : null,
    sessionPinning ? sessionPinningMiddleware() : null
  ];
}

// Generate the persistence middleware
function generatePersistenceMiddleware(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  persistStoreFn: (arg0: RecordSource) => any,
  recordSource: RecordSource
): RelayMiddleware {
  const persistStore = debounce(persistStoreFn, 1000);
  return (next) => async (req) => {
    const res = await next(req);
    if (persistStore) persistStore(recordSource);
    return res;
  };
}

const generateHeadersMiddleware =
  (
    requestHeaders: RelayOrchestratorOptions["requestHeaders"]
  ): RelayMiddleware =>
  (next) =>
  async (req) => {
    Object.assign(
      req.fetchOpts.headers,
      typeof requestHeaders === "function"
        ? await requestHeaders()
        : requestHeaders
    );
    // Always assume a json response
    req.fetchOpts.headers.Accept = "application/json";
    return await next(req);
  };
