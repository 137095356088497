import React from "react";
import { ContentArea, Surface } from "../../atoms";
import { SearchInput } from "../../molecules";
import { Row } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";

interface Props {
  searchPlaceholder: string;
  searchTerm: string;
  onSearch: (text: string) => void;
}

export default function SectionListSearchContainer({
  searchPlaceholder,
  searchTerm,
  onSearch
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      searchContainer: {
        maxWidth: isSm ? getUnits(35) : getUnits(52)
      }
    }),
    [isSm]
  );

  return (
    <Row style={styles.searchContainer}>
      <Surface
        color="foreground"
        hideInnerMargin
        topRadius="none"
        bottomRadius="none"
        fill={1}
      >
        <ContentArea size="compact" variant="compact">
          <SearchInput
            testID="section-list-search"
            placeholder={searchPlaceholder}
            value={searchTerm}
            onChangeText={onSearch}
            size="small"
          />
        </ContentArea>
      </Surface>
    </Row>
  );
}
