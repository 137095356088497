import colors from "@gigsmart/atorasu/style/theme/colors";
import {
  DefaultTheme,
  type LinkingOptions,
  NavigationContainer,
  type NavigationContainerRef,
  type NavigationState,
  type ParamListBase
} from "@react-navigation/native";
import React, {
  type ReactNode,
  type Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Router from "../Router";
import { NavRealm } from "./NavPortal";
import { RouteWatcher } from "./RouteWatcher";
import { navigationRef } from "./delegate";

interface Props<T extends ParamListBase> {
  appName?: string;
  linking?: LinkingOptions<T>;
  children?: ReactNode;
}

let navState: NavigationState | undefined;

export function NavProvider<T extends ParamListBase>({
  linking,
  children,
  appName
}: Props<T>) {
  const [ready, setReady] = useState(false);
  const navTheme = useMemo(
    () => ({
      colors: {
        ...DefaultTheme.colors,
        background: colors.background.fill,
        card: colors.surface.fill,
        border: colors.divider.fill,
        primary: colors.primary.fill,
        text: colors.textPrimary.fill
      },
      dark: false
    }),
    []
  );

  const stateRef = useRef<NavigationState>();
  const handleStateChange = useCallback((state?: NavigationState) => {
    stateRef.current = state;
    if (!ready) setReady(true);
  }, []);

  // store current navigation state in memory so the app can recover in unmount refreshing
  useEffect(() => {
    return () => {
      navState = stateRef.current;
    };
  }, []);

  return (
    <Router linking={linking}>
      <NavRealm>
        <NavigationContainer<T>
          linking={linking}
          ref={navigationRef as Ref<NavigationContainerRef<T>>}
          theme={navTheme}
          initialState={navState}
          onStateChange={handleStateChange}
          documentTitle={{
            enabled: true,
            formatter: (opt, route) =>
              `${
                opt?.title ?? opt?.eventContext ?? route?.name ?? appName
              } - GigSmart`
          }}
        >
          {children}
          {RouteWatcher.enabled() && ready && <RouteWatcher />}
        </NavigationContainer>
      </NavRealm>
    </Router>
  );
}
