import atob from "atob";
import React, { type CSSProperties, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";
import { StyleSheet, View } from "react-native";
import { Button, ContentArea, ModalBody, ModalFooter } from "../atoms";
import { Column, Row, Spacer, Text } from "../quarks";
import { useStyles } from "../style";
import { showModal } from "./ModalProvider";

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
    value: function (
      this: HTMLCanvasElement,
      callback: (blob: Blob) => void,
      type: string,
      quality: number
    ) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        const binStr = atob(this.toDataURL(type, quality).split(",")[1]!);
        const len = binStr.length;
        const arr = new Uint8Array(len) as any;

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        callback(new Blob([arr], { type: type || "image/png" } as any));
      });
    }
  });
}

interface Props {
  onClose: () => void;
  onSelect?: (uris: Blob[]) => Promise<void>;
  title?: string;
  preview?: "square" | "circle";

  // native specific
  multiple?: boolean;
  max?: number;
}

export default function MediaPicker({ preview, onSelect, onClose }: Props) {
  const editorRef = useRef<AvatarEditor | null>(null);
  const styles = useStyles(({ getColor }) => ({
    container: {
      aspectRatio: 1,
      width: "100%",
      position: "relative"
    },
    input: {
      borderRadius: 4,
      borderWidth: 1,
      borderColor: "#7b7b7b",
      borderStyle: "dashed",
      width: "100%",
      height: "100%",
      display: "flex",
      boxSizing: "border-box"
    },
    inputActive: {
      borderWidth: 3,
      borderColor: getColor("info", "fill"),
      backgroundColor: getColor("info", "fill", { opacity: 0.08 })
    }
  }));

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<File | string>();
  const [size, setSize] = useState(400);
  const [scale, setScale] = useState(1);

  const handleSave = async () => {
    const editor = editorRef.current;
    if (!editor) return;

    try {
      setLoading(true);
      const blob = await resizeCanvas(editor.getImage());
      if (blob && onSelect) await onSelect?.([blob]);
      onClose(); // autoclose?
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setValue(undefined);
    setScale(1);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: { "image/*": [] },
    onDrop: (files) => setValue(files[0])
  });

  return (
    <>
      <ModalBody constraint="none">
        <View
          style={styles.container}
          onLayout={(e) => setSize(e.nativeEvent.layout.width)}
        >
          {value ? (
            <AvatarEditor
              ref={editorRef}
              style={{ width: size, height: size }}
              image={value}
              border={[12, 12]}
              backgroundColor="#fff"
              scale={scale}
              width={size}
              height={size}
              borderRadius={preview === "circle" ? size / 2 : 4}
            />
          ) : (
            <div
              {...getRootProps()}
              style={
                StyleSheet.flatten([
                  styles.input,
                  isDragActive && styles.inputActive
                ]) as CSSProperties
              }
            >
              <ContentArea fill alignItems="center" justifyContent="center">
                <Text align="center">
                  Drop an image here, or click to select a file to upload.
                </Text>
              </ContentArea>
              <input
                {...getInputProps()}
                data-testid="document-picker-input-file"
              />
            </div>
          )}
        </View>
        {!!value && <Spacer size="slim" />}
        {!!value && (
          <Row alignItems="center">
            <Button
              label=""
              testID="zoom-in"
              icon="minus"
              size="small"
              variant="clear"
              onPress={() => setScale((v) => Math.max(0.2, v - 0.05))}
            />
            <Text> Zoom: {(scale * 100).toFixed(0)}% </Text>
            <Button
              label=""
              testID="zoom-on"
              icon="plus"
              size="small"
              variant="clear"
              onPress={() => setScale((v) => Math.min(3, v + 0.05))}
            />
            <Column fill />
            <Button
              testID="clear-btn"
              variant="clear"
              size="small"
              label="CLEAR"
              onPress={handleReset}
              disabled={loading}
            />
          </Row>
        )}
      </ModalBody>
      <ModalFooter constraint="none">
        {!!value && (
          <Button
            testID="save-btn"
            label="Save"
            onPress={handleSave}
            loading={loading}
          />
        )}
      </ModalFooter>
    </>
  );
}

export function showMediaPicker({ title, ...props }: Omit<Props, "onClose">) {
  return showModal({
    title: title ?? "",
    eventContext: "Media Picker",
    useModalBody: false,
    children: (close) => <MediaPicker {...props} onClose={close} />
  });
}

async function resizeCanvas(
  image: HTMLCanvasElement,
  maxw = 1024,
  maxh = 1024
) {
  let { width, height } = image;

  if (width > height) {
    if (width > maxw) {
      height = Math.round((height * maxw) / width);
      width = maxw;
    }
  } else if (height > maxh) {
    width = Math.round((width * maxh) / height);
    height = maxh;
  }

  const needsRezing = width !== image.width || height !== image.height;
  let ctx: CanvasRenderingContext2D | null = null;

  if (needsRezing) {
    const resized = document.createElement("canvas");
    resized.width = width;
    resized.height = height;

    ctx = resized.getContext("2d");
    if (ctx) ctx.drawImage(image, 0, 0, width, height);
  } else {
    ctx = image.getContext("2d");
  }

  return new Promise<Blob | null>((resolve) => {
    if (ctx) {
      ctx.canvas.toBlob((blob) => resolve(blob), "image/png", 0.8);
    } else {
      resolve(null);
    }
  });
}
