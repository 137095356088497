import ShadowView from "@gigsmart/shadow-view";
import React from "react";
import { ContentArea, Stack } from "../atoms";
import { useIsConstrained } from "../atoms/Constraint";
import { Icon, type IconName, Text } from "../quarks";
import { useStyle } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  color: Color;
  icon: IconName;
  label: string;
}

export default function SurfaceHeader({ color, icon, label }: Props) {
  const isConstrained = useIsConstrained();
  const style = useStyle(
    ({ getUnits, getColor }) => ({
      width: "100%",
      shadowColor: "#000000",
      shadowOpacity: 0.15,
      shadowRadius: 4,
      backgroundColor: getColor("surface", "fill"),
      shadowOffset: {
        width: 0,
        height: 2
      },
      ...(isConstrained
        ? {
            borderTopRightRadius: getUnits(1),
            borderTopLeftRadius: getUnits(1)
          }
        : {})
    }),
    [isConstrained]
  );

  return (
    <ShadowView style={style}>
      <ContentArea size="medium">
        <Stack horizontal alignItems="center" size="compact">
          <Icon size="small" name={icon} variant="solid" color={color} />
          <Text variant="subheader" color={color}>
            {label}
          </Text>
        </Stack>
      </ContentArea>
    </ShadowView>
  );
}
