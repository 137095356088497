import { Form, FormField, FormValues, Validator } from "@gigsmart/fomu";
import React, { type ReactNode, useMemo, useState } from "react";
import ContentArea from "../atoms/ContentArea";
import Stack from "../atoms/Stack";
import ListEmptySearch from "../molecules/ListEmptySearch";
import MultiSelectRow from "../molecules/MultiSelectRow";
import TextInput from "../molecules/TextInput";
import Icon, { type IconName } from "../quarks/Icon";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import HighlightedReminder from "./HighlightedReminder";
import MultiSelectHeader from "./MultiSelectHeader";

export interface Option {
  id?: string;
  label?: string;
  value?: boolean | null;
  onValueChange?: (v: boolean | null | undefined) => void;
}

interface Props {
  testID: string;
  header?: string | ReactNode;
  note: string;
  searchHeader: string;
  searchPlaceholder?: string;
  selectionRowHeader: string;
  selectionRowHeaderIcon: IconName;
  options: Option[];
  reminder?: string;
  onCheckOption?: (value: Option, values: Option[]) => void;
  onEdit?: (value: Option) => void;
  enableSearch?: boolean;
  autoChecking?: boolean;
  showEdit?: (option: Option) => boolean;
}
export default function MultiSelectionRowGroup({
  testID,
  header,
  note,
  searchHeader,
  searchPlaceholder,
  selectionRowHeader,
  selectionRowHeaderIcon,
  options = [],
  reminder,
  onCheckOption,
  onEdit,
  enableSearch = true,
  autoChecking = true,
  showEdit
}: Props) {
  const initialValues = useMemo(
    () =>
      options.reduce<any>((obj, it) => {
        if (it.id) obj[it.id] = it.value;
        return obj;
      }, {}),
    []
  );
  const [search, setSearch] = useState("");
  const filteredOptions = useMemo(
    () =>
      options.filter((item) =>
        item?.label?.toLowerCase().includes(search.toLowerCase())
      ),
    [options, search]
  );
  return (
    <Form initialValues={initialValues} onSubmit={() => {}}>
      {header && typeof header !== "string" && header}
      <Stack>
        {header && typeof header === "string" && (
          <Text variant="header" color="primary">
            {header}
          </Text>
        )}
        <Text>{note}</Text>
        <>
          <Stack size="compact">
            <Text weight="bold">{searchHeader}</Text>
            {enableSearch && (
              <TextInput
                testID={`${testID}-search`}
                placeholder={searchPlaceholder}
                leftAccessory={
                  <ContentArea variant="compact">
                    <Icon
                      name="search"
                      color="neutral"
                      size="small"
                      variant="solid"
                    />
                  </ContentArea>
                }
                value={search}
                onChangeText={setSearch}
                maxLength={50}
              />
            )}
            <Spacer size={enableSearch ? "compact" : "none"} />
          </Stack>
          {filteredOptions.length > 0 && (
            <>
              <MultiSelectHeader
                testID={`${testID}-selection-row-header`}
                title={selectionRowHeader}
                icon={selectionRowHeaderIcon}
                options={["NO", "YES"]}
                iconVariant="solid"
              />
              <FormValues>
                {({ values }) => (
                  <Stack size="compact">
                    {filteredOptions.map((option, index) => (
                      <FormField
                        key={`option-${index}`}
                        name={option?.id ?? ""}
                        validates={Validator.presence()}
                      >
                        {({ value, setValue }) => (
                          <MultiSelectRow
                            testID={`${testID}-option-${index}`}
                            title={option.label}
                            onChange={(v) => {
                              if (v !== value) {
                                if (autoChecking) setValue(v);
                                onCheckOption?.(
                                  {
                                    ...option,
                                    value: !!v,
                                    onValueChange: setValue
                                  },
                                  options.map((otherOption) => ({
                                    ...otherOption,
                                    value:
                                      otherOption?.id === option?.id
                                        ? !!v
                                        : values?.[otherOption?.id ?? ""]
                                  }))
                                );
                              }
                            }}
                            options={[
                              {
                                testID: `${testID}-option-${index}-no`,
                                value: false
                              },
                              {
                                testID: `${testID}-option-${index}-yes`,
                                value: true
                              }
                            ]}
                            value={value}
                            onEditPress={
                              !!value &&
                              !!onEdit &&
                              (showEdit ? showEdit({ ...option }) : true)
                                ? () => onEdit({ ...option })
                                : undefined
                            }
                          />
                        )}
                      </FormField>
                    ))}
                  </Stack>
                )}
              </FormValues>
            </>
          )}
        </>
        {filteredOptions.length === 0 && (
          <ListEmptySearch searchTerm={search} />
        )}
        {!!reminder && filteredOptions.length > 0 && (
          <HighlightedReminder icon="circle-info" header={reminder} />
        )}
      </Stack>
    </Form>
  );
}
