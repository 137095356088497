import React, { type ComponentProps, type ComponentType } from "react";
import { View } from "react-native";

import { ContentArea, IconText, Stack } from "../atoms";
import { Column, type IconName, Spacer, Text } from "../quarks";
import { useStyles } from "../style";
import InfoModal from "./InfoModal";

interface Props {
  title: string;
  icon: IconName;
  description: string;
  ImageComponent: ComponentType<any>;
  infoModal: ComponentProps<typeof InfoModal>;
}

export default function OpportunityInfoRow({
  title,
  icon,
  description,
  ImageComponent,
  infoModal
}: Props) {
  const styles = useStyles(
    ({ getUnits, colors }) => ({
      container: {
        borderWidth: 1,
        borderRadius: getUnits(1),
        borderColor: colors.background.fill
      },
      image: {
        width: 76,
        height: 76
      },
      centerBody: {
        paddingLeft: getUnits(1)
      }
    }),
    []
  );

  return (
    <View style={styles.container}>
      <ContentArea size="compact" variant="compact">
        <Stack horizontal fill={1} size="compact">
          <Column>
            <ImageComponent style={styles.image} />
          </Column>
          <Column fill={1} style={styles.centerBody}>
            <Stack size="slim">
              <IconText
                size="tiny"
                icon={icon}
                color="primary"
                spacing="compact"
              >
                <Text weight="bold">{title}</Text>
              </IconText>
              <Text variant="note" wrap numberOfLines={4}>
                {description}
              </Text>
            </Stack>
          </Column>
          <Spacer />
          <Column justifyContent="center">
            <InfoModal {...infoModal} />
          </Column>
          <Spacer />
        </Stack>
      </ContentArea>
    </View>
  );
}
