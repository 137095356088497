import React, { type ComponentProps } from "react";
import { Pressable } from "../quarks";
import Bubble from "./Bubble";

interface Props extends Omit<ComponentProps<typeof Bubble>, "variant"> {
  testID: string;
  eventTargetName: string | null;
  selected: boolean;
  onChange?: (value: boolean) => void;
}

const SelectableBubble = ({
  testID,
  eventTargetName,
  selected,
  onChange,
  ...props
}: Props) => {
  return (
    <Pressable
      testID={testID}
      eventEntityType="Bubble"
      eventTargetName={eventTargetName}
      onPress={() => onChange?.(selected)}
    >
      <Bubble
        {...props}
        testID={`${testID}-bubble`}
        variant={selected ? "solid" : "outline"}
      />
    </Pressable>
  );
};

export default SelectableBubble;
