import ShadowView from "@gigsmart/shadow-view";
import React, { type ReactNode } from "react";
import { View } from "react-native";

import { Stack } from "../atoms";
import { Icon, Pressable, Text } from "../quarks";
import { responsive, useMatchesViewport, useStyles } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  title: string | JSX.Element;
  subtitle?: string;
  subtitleColor?: Color;
  svg: ReactNode;
  selected?: boolean;
  onSelect: () => void;
  testID: string;
  variant?: "standard" | "small";
  shadow?: boolean;
  disabled?: boolean;
  onPressDisabled?: () => void;
}

export default function SvgRadioButton({
  selected,
  onSelect,
  title,
  subtitle,
  subtitleColor = "primary",
  testID,
  svg,
  variant = "standard",
  shadow,
  disabled,
  onPressDisabled
}: Props) {
  const styles = useStyles(
    ({ getUnits, getColor, media }) => ({
      fill: {
        flex: 1
      },
      container: {
        backgroundColor: getColor("surface", "fill"),
        borderRadius: getUnits(1),
        borderColor: getColor("divider", "fill"),
        borderWidth: 1,
        flex: 1,
        alignItems: "center",
        paddingTop: getUnits(4),
        paddingBottom: variant === "standard" ? getUnits(4) : getUnits(2),
        paddingHorizontal: variant === "standard" ? getUnits(3) : getUnits(2)
      },
      selected: {
        borderColor: getColor("primary", "fill"),
        borderWidth: 2,
        paddingTop: getUnits(4) - 1,
        paddingBottom:
          variant === "standard" ? getUnits(4) - 1 : getUnits(2) - 1,
        paddingHorizontal:
          variant === "standard" ? getUnits(3) - 1 : getUnits(2) - 1
      },
      icon: {
        position: "absolute",
        top: 8,
        right: 8,
        fontSize: 24
      },
      shadow: {
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: getUnits(0.5),
        shadowOffset: {
          width: 0,
          height: getUnits(0.5)
        }
      },
      svg: [
        {
          alignItems: "center",
          width: "50%",
          maxWidth: getUnits(30),
          aspectRatio: 1
        },
        responsive(media.size.medium.down, {
          maxWidth: getUnits(13)
        })
      ],
      disabled: {
        opacity: 0.35
      }
    }),
    [variant]
  );
  const isSmallPhone = useMatchesViewport(({ size }) => size.xsmall.down);
  const WrapperComponent = shadow ? ShadowView : View;
  return (
    <Pressable
      eventEntityType="SvgRadioButton"
      eventTargetName="SVG Radio Button"
      onPress={disabled ? onPressDisabled : onSelect}
      testID={testID}
      style={styles.fill}
    >
      <WrapperComponent
        style={[
          styles.container,
          selected && styles.selected,
          shadow && styles.shadow,
          disabled && styles.disabled
        ]}
      >
        {selected && (
          <Icon
            name="circle-check"
            variant="solid"
            color="primary"
            style={styles.icon}
          />
        )}
        <Stack
          alignItems="center"
          fill={1}
          size={variant === "standard" ? "standard" : "slim"}
          justifyContent="space-between"
        >
          {variant === "standard" ? <View style={styles.svg}>{svg}</View> : svg}
          {typeof title !== "string" ? (
            title
          ) : (
            <Text
              color={variant === "standard" ? "primary" : undefined}
              weight={variant === "standard" ? "bold" : undefined}
              align="center"
              variant={
                variant === "standard" || !isSmallPhone ? undefined : "note"
              }
            >
              {title}
            </Text>
          )}
          {subtitle && (
            <Text variant="note" color={subtitleColor} align="center">
              {subtitle}
            </Text>
          )}
        </Stack>
      </WrapperComponent>
    </Pressable>
  );
}
