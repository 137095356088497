import React from "react";
import type { ButtonSize } from "../atoms/Button";
import Button from "../atoms/Button";
import { Column, Row } from "../quarks";
import { type Color, useStyles } from "../style";

// same as button specs
const specs = {
  size: { large: 12, small: 8, xsmall: 6.5 }
};

type Props<T extends { value: any; label: string }> = {
  size?: ButtonSize;
  color?: Color;
  options?: T[] | null;
  value?: T["value"] | null;
  onChangeValue?: (value: T["value"]) => void;
  keyExtractor?: (item: T) => string;

  alignSelf?: "flex-start" | "center" | "flex-end" | "stretch";
};

export default function ButtonGroupSelect<
  T extends { value: any; label: string }
>({
  keyExtractor,
  color = "primary",
  size = "small",
  value,
  onChangeValue,
  options,
  ...props
}: Props<T>) {
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      wrapper: {
        height: getUnits(specs.size[size]),
        borderRadius: getUnits(1),
        borderWidth: 1,
        borderColor: getColor(color, "fill")
      },
      // account for border
      buttons: { marginTop: -1, marginLeft: -1 }
    }),
    [size]
  );

  if (!options?.length) return null;
  return (
    <Column style={styles.wrapper} testID="button-group-select" {...props}>
      <Row style={styles.buttons}>
        {options.map((opt, index) => {
          const key = keyExtractor?.(opt) ?? index;
          const active = opt.value === value;
          return (
            <Button
              grow
              testID="button-group-select-button"
              key={key}
              size={size}
              label={opt.label}
              variant={active ? "solid" : "clear"}
              onPress={() => onChangeValue?.(opt.value)}
              numberOfLines={1}
            />
          );
        })}
      </Row>
    </Column>
  );
}
