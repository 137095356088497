import { DateTime, type Duration } from "luxon";
import React, { type ComponentProps } from "react";
import { Text, TimeTicker } from "../quarks";

type TimeRemainingOptions = {
  duration: Duration;
  showSeconds?: boolean;
  showMinutes?: boolean;
  showLargestOnly?: boolean;
  size?: "sm" | "md" | "lg";
  fallback?: string;
};

type Props = ComponentProps<typeof Text> &
  Omit<TimeRemainingOptions, "duration"> & {
    date?: string | null;
  };

export default function TimeRemaining({
  date,
  showLargestOnly,
  showSeconds,
  showMinutes,
  size = "md",
  ...textProps
}: Props) {
  if (!date) return null;
  return (
    <TimeTicker>
      {(now) => {
        const duration = DateTime.fromISO(date).diff(DateTime.fromJSDate(now));
        const timeRemainingString = renderTimeRemainingString({
          duration,
          showSeconds,
          showLargestOnly,
          size,
          showMinutes
        });
        return <Text {...textProps}>{timeRemainingString?.trim()}</Text>;
      }}
    </TimeTicker>
  );
}

const format = {
  days: {
    sm: { singular: "d", plural: "d" },
    md: { singular: " day", plural: " days" },
    lg: { singular: " day", plural: " days" }
  },
  hours: {
    sm: { singular: "h", plural: "h" },
    md: { singular: " hr", plural: " hrs" },
    lg: { singular: " hour", plural: " hours" }
  },
  minutes: {
    sm: { singular: "m", plural: "m" },
    md: { singular: " min", plural: " mins" },
    lg: { singular: " minute", plural: " minutes" }
  },
  seconds: {
    sm: { singular: "s", plural: "s" },
    md: { singular: " sec", plural: " secs" },
    lg: { singular: " second", plural: " seconds" }
  }
};

const getValue = (
  value: number | undefined,
  size: "sm" | "md" | "lg",
  unit: keyof typeof format
) => {
  value = value ? Math.floor(value) : 0;
  if (value < 1) return "";

  const key = value === 1 ? "singular" : "plural";
  return `${value}${format[unit][size][key]}`;
};

export function renderTimeRemainingString({
  duration,
  showLargestOnly,
  showSeconds,
  showMinutes,
  size = "md",
  fallback = "Less than 1 minute"
}: TimeRemainingOptions) {
  let timeRemainingString = "";
  if (!duration.isValid) return "";

  const shiftTo = showLargestOnly
    ? (["hours", "minutes"] as const)
    : (["days", "hours", "minutes", "seconds"] as const);

  const { days, hours, minutes, seconds } = duration
    .shiftTo(...shiftTo)
    .toObject();

  if (showLargestOnly) {
    timeRemainingString =
      getValue(hours, size, "hours") || getValue(minutes, size, "minutes");
  } else {
    timeRemainingString = [
      getValue(days, size, "days"),
      getValue(hours, size, "hours"),
      (showMinutes || showSeconds) && getValue(minutes, size, "minutes"),
      showSeconds && getValue(seconds, size, "seconds")
    ]
      .filter(Boolean)
      .join(" ");
  }

  return timeRemainingString.trim() || fallback;
}
