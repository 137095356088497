import React, { type ComponentProps } from "react";

import TextInput from "../../molecules/TextInput";
import Editor from "./Editor";

interface Props extends ComponentProps<typeof TextInput> {
  nativeLinkPrompt?: boolean;
}

export default function WysiwygTextInput({
  nativeLinkPrompt,
  ...props
}: Props) {
  return (
    <TextInput
      {...props}
      multiline
      numberOfLines={9}
      renderInputComponent={(inputProps) => (
        <Editor nativeLinkPrompt={nativeLinkPrompt} {...inputProps} />
      )}
    />
  );
}
