import { FlagType } from "@gigsmart/feature-flags/registry";
import { kebabCase } from "lodash";
import { useEffect, useRef } from "react";
import { LogAllFeature } from "./LogAllFeature";
import { methods } from "./backendRegistry";
import { registerFlag } from "./flagDomain";

const envLog = String(process.env.LOG).split(",");

export const createLogger = (
  emoji: string,
  domain: string,
  flagType = FlagType.REMOTE
) => {
  const key = kebabCase(domain);
  const isPinned =
    envLog.includes(key) ||
    (envLog.includes("*") && !envLog.includes(`-${key}`));
  const flag = registerFlag(`${key}-logs`, {
    type: isPinned ? FlagType.PINNED : flagType,
    inherits: LogAllFeature
  });

  function patchMethod<
    F extends (message?: any, ...optionalParams: any[]) => void
  >(fn: F, ...prefix: string[]) {
    return (message: unknown, ...args: unknown[]) => {
      if (flag.isDisabled()) return;

      // Helper to check if a value is a multiline string
      const isMultilineString = (value: unknown): value is string =>
        typeof value === "string" && value.includes("\n");

      // Helper to process multiline arguments
      const processMultilineArgs = (items: unknown[]): unknown[][] => {
        if (!items.some((item) => isMultilineString(item))) return [items];

        const maxLines = items.reduce<number>((max, item) => {
          if (isMultilineString(item)) {
            return Math.max(max, item.split("\n").length);
          }
          return max;
        }, 0);

        return Array.from({ length: maxLines }, (_, i) =>
          items.map((item) =>
            isMultilineString(item) ? item.split("\n")[i] || "" : item
          )
        );
      };

      // Process all arguments including message
      const allArgs = processMultilineArgs([message, ...args]);

      // Call fn for each line of output
      allArgs.forEach((lineArgs) => fn(...prefix, ...lineArgs));
    };
  }

  const buildLogger = (...prefix: string[]) => {
    const debug = patchMethod(methods.debug, ...prefix);

    function inspect<V>(value: V, label: string, ...labels: any[]): V {
      if (flag.isDisabled()) return value;
      Promise.resolve(value).then((v) => {
        debug(label, ...labels, v);
      });

      return value;
    }

    let wrapCounter = 0;
    const wrap = <F extends (...args: any[]) => any>(
      fn: F,
      name?: string
    ): F => {
      const fnName = name ?? ("name" in fn ? fn.name : name) ?? "anonymous";
      return ((...args: Parameters<F>) => {
        if (flag.isDisabled()) return fn(...args);
        const wrapId = wrapCounter++;
        let logArgs = args.flatMap((v) => [v, ","]);
        logArgs.pop();
        logArgs = ["(", ...logArgs, ")"];
        debug(`invoke::${fnName}`, ...logArgs, "📳", `(${wrapId})`);
        const start = Date.now();
        return inspect(
          fn(...args),
          fnName,
          ...logArgs,
          "->",
          "🏁",
          `(${wrapId})`,
          `[${Date.now() - start}ms]`
        );
      }) as F;
    };

    function useDidChange<T>(label: string, value: T) {
      const prevValue = useRef<T>(value);
      useEffect(() => {
        inspect(
          value,
          label,
          "did change",
          ...(prevValue === value ? [] : ["\n    from:", prevValue.current]),
          "\n    to: "
        );
        prevValue.current = value;
      }, [label, value]);
    }

    return {
      useDidChange,
      isEnabled: flag.isEnabled,
      isDisabled: flag.isDisabled,
      inspect,
      createLogger: (...subprefix: string[]) =>
        buildLogger(
          ...prefix,
          ...subprefix.map((v) =>
            v.startsWith("!") ? v.replace(/^\!/, "") : `[${v}]`
          )
        ),
      log: process.env.DEVMOJI
        ? patchMethod(methods.log, process.env.DEVMOJI, ...prefix)
        : patchMethod(methods.log, ...prefix),
      info: patchMethod(methods.info, ...prefix),
      warn: patchMethod(methods.warn, ...prefix),
      error: patchMethod(methods.error, ...prefix),
      debug,
      trace: patchMethod(methods.trace, ...prefix),
      wrap
    };
  };

  return buildLogger(emoji, `[${domain}]`);
};
