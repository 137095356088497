import { NestedForm } from "@gigsmart/fomu";
import React, { Fragment } from "react";
import { type ListRenderItem, Pressable } from "react-native";
import Animated from "react-native-reanimated";
import Skeleton, { SkeletonBone } from "../../atoms/Skeleton";
import { Column, Divider, Row } from "../../quarks";
import type { Color } from "../../style";
import { CellBody } from "./CellBody";
import { useSyncScroll } from "./SyncScroll";
import { TableHeader } from "./TableHeader";
import type {
  GetCellHeightFn,
  NormalizedTableCol,
  TableKey,
  TableSortBy
} from "./TableView.helpers";

type Props<T> = {
  id: string;
  columns: NormalizedTableCol<T>[];
  data?: T[] | null;
  sortBy: TableSortBy<T>;
  onSort?: (key: TableKey<T>, dir: "asc" | "desc") => void;
  getCellHeight: GetCellHeightFn;
  keyExtractor?: (item: T, idx: number) => string;
  onEndReached?: () => void;
  loading?: boolean;
  listLength?: number;
  onRowPress?: (item: T) => void;
  headerVariant?: "default" | "shadow";
  headerBackgroundColor?: Color;
  scrollEnabled?: boolean;
  formNameExtractor?: (item: T) => string;
  formNamePrefix?: string;
};

export function SimpleTable<T>({
  id,
  columns,
  data,
  sortBy,
  onSort,
  getCellHeight,
  keyExtractor,
  onEndReached,
  listLength,
  loading,
  onRowPress,
  headerVariant,
  scrollEnabled,
  headerBackgroundColor,
  formNameExtractor,
  formNamePrefix
}: Props<T>) {
  const renderCell = (
    col: NormalizedTableCol<T>,
    colIndex: number,
    item: T,
    cellHeight: number,
    keyPrefix: string
  ) => {
    const { renderCell, CellComponent, attr } = col;
    const content = renderCell ? (
      renderCell(item, keyPrefix)
    ) : CellComponent ? (
      <CellComponent data={item} />
    ) : attr ? (
      String(item[attr as keyof typeof item] ?? "—")
    ) : (
      "—"
    );

    return (
      <CellBody
        key={`${keyPrefix}.${colIndex}`}
        cellHeight={cellHeight}
        action={col.action}
        cellAlign={col.cellAlign}
        cellWidth={col.cellWidth}
        verticalPadding={col.paddingVertical}
        horizontalPadding={col.paddingHorizontal}
      >
        {content}
      </CellBody>
    );
  };

  const renderItem: ListRenderItem<T> = ({ item, index }) => {
    const { cellHeight, onLayout } = getCellHeight(index);
    const keyPrefix = `${keyExtractor?.(item, index) ?? index}`;
    const node = formNameExtractor ? (
      <NestedForm
        name={
          formNamePrefix
            ? `${formNamePrefix}.${formNameExtractor(item)}`
            : formNameExtractor(item)
        }
      >
        <Row onLayout={onLayout}>
          {columns.map((col, colIndex) =>
            renderCell(col, colIndex, item, cellHeight, keyPrefix)
          )}
        </Row>
      </NestedForm>
    ) : (
      <Row onLayout={onLayout}>
        {columns.map((col, colIndex) =>
          renderCell(col, colIndex, item, cellHeight, keyPrefix)
        )}
      </Row>
    );

    return onRowPress ? (
      <Pressable onPress={() => onRowPress(item)}>{node}</Pressable>
    ) : (
      node
    );
  };

  const renderSkeletonFooter = (n: number) => {
    if (!loading) return null;
    return (
      <Skeleton loading>
        {Array.from({ length: n }, (_, rowIndex) => {
          const { cellHeight } = getCellHeight(rowIndex);
          return (
            <Fragment key={rowIndex}>
              <Separator />
              <Row>
                {columns.map((col, colIndex) => (
                  <CellBody
                    key={`${rowIndex}.${colIndex}`}
                    cellWidth={col.cellWidth}
                    cellHeight={cellHeight || 52}
                    action={col.action}
                    verticalPadding={col.paddingVertical}
                    horizontalPadding={col.paddingHorizontal}
                  >
                    <SkeletonBone lines={col.action ? 2 : 1} />
                  </CellBody>
                ))}
              </Row>
            </Fragment>
          );
        })}
      </Skeleton>
    );
  };

  const { listRef, scrollHandler } = useSyncScroll<Animated.FlatList<T>>(id);
  return (
    <Column fill>
      <TableHeader
        variant={headerVariant}
        columns={columns}
        sortBy={sortBy}
        onSort={onSort}
        backgroundColor={headerBackgroundColor}
      />
      <Animated.FlatList
        scrollEnabled={scrollEnabled}
        style={{ flex: 1 }}
        ref={listRef}
        renderItem={renderItem}
        data={data}
        ListFooterComponent={renderSkeletonFooter(
          listLength ? listLength - (data?.length ?? 0) : data?.length ? 4 : 10
        )}
        ItemSeparatorComponent={Separator}
        keyExtractor={keyExtractor}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        onEndReached={onEndReached}
        bounces={false}
        scrollEventThrottle={16}
        onScroll={scrollHandler}
      />
    </Column>
  );
}

const Separator = () => <Divider />;
