import { sortBy } from "lodash";
import type {
  MiddlewareNextFn,
  RelayRequestAny
} from "react-relay-network-modern/es";
import type { RelayResponse } from "../../../../types";

export function etagCacheMiddleware() {
  const responseCache = new Map<
    string,
    { etag: string; response: RelayResponse; insertedAt: number }
  >();

  const garbageCollect = (maxLength = 100) => {
    while (responseCache.size > maxLength) {
      const oldestEntry = sortBy(
        Array.from(responseCache.entries()),
        ([, { insertedAt }]) => insertedAt
      )[0];
      if (oldestEntry) {
        const [key] = oldestEntry;
        responseCache.delete(key);
      }
    }
  };

  return (next: MiddlewareNextFn) => async (req: RelayRequestAny) => {
    const body = req.getBody();
    if (typeof body !== "string") {
      return await next(req);
    }
    const cache = responseCache.get(body);
    if (cache) req.fetchOpts.headers["If-None-Match"] = cache.etag;
    const response = await next(req);
    const etag = response._res.headers.get("etag");
    if (cache) {
      if (response.status === 304) {
        if (etag !== cache.etag) throw new Error("Etag mismatch");
        return cache.response;
      }
    } else if (etag) {
      responseCache.set(body, {
        etag,
        response,
        insertedAt: Date.now()
      });
    }
    garbageCollect(250);
    return response;
  };
}
