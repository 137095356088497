import React, {
  type ComponentProps,
  type ReactNode,
  useMemo,
  useState
} from "react";
import {
  Dimensions,
  FlatList,
  Image,
  Platform,
  Modal as RNModal,
  View
} from "react-native";
import AbsoluteContainer from "../atoms/AbsoluteContainer";
import ContentArea from "../atoms/ContentArea";
import IconButton from "../atoms/IconButton";
import IconCircle from "../atoms/IconCircle";
import ModalBody from "../atoms/ModalBody";
import Pressable from "../quarks/Pressable";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import { useStyles } from "../style/";
import Modal from "./ModalContainer";

interface Props {
  sources: string[];
  thumbSize?: number;
  onEndReached?: ComponentProps<typeof FlatList>["onEndReached"];
  thumbResizeMode?: ComponentProps<typeof Image>["resizeMode"];
}

const mobile = Platform.OS === "android" || Platform.OS === "ios";

export default function Carousel({
  sources,
  thumbSize,
  onEndReached,
  thumbResizeMode = "contain"
}: Props) {
  const { width: screenWidth } = Dimensions.get("window");
  const [initalListIndex, setInitialListIndex] = useState<number>();

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      thumbContainer: {
        width: thumbSize ? getUnits(thumbSize) : getUnits(15),
        height: thumbSize ? getUnits(thumbSize) : getUnits(15),
        backgroundColor: getColor("background", "fill"),
        borderRadius: getUnits(1),
        overflow: "hidden"
      },
      thumbImage: {
        width: "100%",
        height: "100%"
      },
      mobileSlideImage: {
        height: "100%",
        width: screenWidth
      },
      webModalImage: { height: 500, width: "100%" },
      mobileModal: { backgroundColor: getColor("background", "fill") }
    }),
    []
  );

  const thumbnails = useMemo(
    () => (
      <FlatList
        bounces={false}
        alwaysBounceHorizontal={false}
        overScrollMode="never"
        data={sources}
        renderItem={({ item, index }) => (
          <Pressable
            testID={`carousel-thumb-${index}`}
            eventEntityType="CarouselThumb"
            eventTargetName="Carousel Thumb"
            onPress={() => {
              setInitialListIndex(index);
            }}
          >
            <View style={styles.thumbContainer}>
              <Image
                resizeMode={thumbResizeMode}
                source={{ uri: item }}
                style={styles.thumbImage}
              />
            </View>
          </Pressable>
        )}
        keyExtractor={(item, index) => `thumb-${item}-${index}`}
        onEndReached={onEndReached}
        ItemSeparatorComponent={() => <Spacer horizontal />}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    ),
    [
      sources,
      onEndReached,
      styles.thumbContainer,
      styles.thumbImage,
      thumbResizeMode
    ]
  );

  const slider = useMemo(
    () => (
      <FlatList
        bounces={false}
        alwaysBounceHorizontal={false}
        initialScrollIndex={initalListIndex}
        data={sources}
        renderItem={({ item }) => (
          <>
            <Image
              resizeMode="contain"
              source={{ uri: item }}
              style={styles.mobileSlideImage}
            />
          </>
        )}
        keyExtractor={(item, index) => `thumb-${item}-${index}`}
        onEndReached={onEndReached}
        horizontal
        showsHorizontalScrollIndicator={false}
        getItemLayout={(data, index) => ({
          length: screenWidth,
          offset: screenWidth * index,
          index
        })}
      />
    ),
    [
      initalListIndex,
      onEndReached,
      screenWidth,
      sources,
      styles.mobileSlideImage
    ]
  );

  const MobileModal = useMemo(
    () =>
      ({ children }: { children?: ReactNode }) => (
        <RNModal
          style={styles.mobileModal}
          visible={initalListIndex !== undefined}
        >
          {dismiss(() => setInitialListIndex(undefined))}
          {children}
        </RNModal>
      ),
    [initalListIndex, styles.mobileModal]
  );

  const WebModal = ({ children }: { children: ReactNode }) => (
    <Modal
      eventContext="CarouselWebModal"
      visible={initalListIndex !== undefined}
      onRequestClose={() => setInitialListIndex(undefined)}
      animationType="slide"
    >
      {dismiss(() => setInitialListIndex(undefined))}
      <ModalBody>{children}</ModalBody>
    </Modal>
  );

  return (
    <>
      {thumbnails}
      {mobile ? (
        <MobileModal>{slider}</MobileModal>
      ) : (
        <WebModal>
          {initalListIndex !== undefined && (
            <ContentArea fill>
              <Image
                resizeMode="contain"
                source={{ uri: sources[initalListIndex] }}
                style={styles.webModalImage}
              />
            </ContentArea>
          )}
        </WebModal>
      )}
    </>
  );
}

const dismiss = (action: () => void) => (
  <AbsoluteContainer top={20} right={20}>
    <Row justifyContent="flex-end">
      {mobile ? (
        <Pressable
          eventEntityType="DismissCarousel"
          onPress={action}
          eventTargetName="Dismiss Carousel Button"
          testID="dismiss-carousel-btn"
        >
          <IconCircle icon="times" color="primary" size="medium" />
        </Pressable>
      ) : (
        <IconButton
          testID="dismiss-action"
          name="times"
          color="primary"
          size="large"
          onPress={action}
        />
      )}
    </Row>
  </AbsoluteContainer>
);
