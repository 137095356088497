import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import type { Class } from "@gigsmart/type-utils";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  Component,
  type ReactNode,
  type DependencyList,
  type ComponentClass,
  type FunctionComponent
} from "react";

export interface SceneWithActorProps<EventName, SceneType> {
  act: ActFn<EventName, SceneType>;
}

export type ActFn<EventName, SceneType> = (
  eventName: EventName,
  nextState?: (scene: SceneType) => SceneType
) => void;

export type RespondFn<EventName, SceneType> = (
  scene: SceneType,
  act: ActFn<EventName, SceneType>
) => void;

export type InitFn<EventName, SceneType> = (
  scene: SceneType,
  act: ActFn<EventName, SceneType>
) => void;

export interface ContextShape<E extends string, T extends object> {
  addResponder: (eventName: E, respondFn: RespondFn<E, T>) => void;
  removeResponder: (eventName: E, respondFn: RespondFn<E, T>) => void;
  act: ActFn<E, T>;
  getScene: () => T;
}

interface DirectorProps<EventName extends string, SceneType extends object> {
  children: ReactNode;
  debug?: boolean;
  initialScene?: SceneType;
  onInit?: InitFn<EventName, SceneType>;
}

export interface ActorInterface<
  EventName extends string,
  SceneType extends object
> extends Component<{ act: ActFn<EventName, SceneType> }> {
  act: (eventType: EventName, fn: (scene: SceneType) => SceneType) => void;
}

export interface DirectorInterface<
  EventName extends string,
  SceneType extends object
> extends Component<DirectorProps<EventName, SceneType>> {
  act: (eventType: EventName, fn?: (scene: SceneType) => SceneType) => void;
  scene: SceneType;
}

export interface ResponderProps<
  EventName extends string,
  SceneType extends object
> {
  eventType: EventName;
  onResponse: RespondFn<EventName, SceneType>;
}

export interface IniterProps<
  EventName extends string,
  SceneType extends object
> {
  onInit: InitFn<EventName, SceneType>;
}

interface CreateSceneType<EventName extends string, SceneType extends object> {
  Director: Class<DirectorInterface<EventName, SceneType>>;
  useScene: () => ContextShape<EventName, SceneType>;
  useActor: <InputType>(
    eventType: EventName,
    action?: (input: InputType | undefined, scene: SceneType) => SceneType,
    deps?: DependencyList
  ) => (input?: InputType) => void;
  useResponder: (
    eventType: EventName,
    onResponse: RespondFn<EventName, SceneType>,
    deps?: DependencyList
  ) => void;
  useInit: (
    initFn: (scene: SceneType, act: ActFn<EventName, SceneType>) => void,
    deps?: DependencyList
  ) => void;
  Responder: FunctionComponent<ResponderProps<EventName, SceneType>>;
  Initer: FunctionComponent<IniterProps<EventName, SceneType>>;
  withActor: HOCVoid;
  Actor: Class<ActorInterface<EventName, SceneType>>;
  withActorStubs: { act: ActFn<EventName, SceneType> };
}

export function createScene<EventName extends string, SceneType extends object>(
  prefix: string,
  defaultInitialScene: SceneType,
  initialize?: (scene: SceneType) => SceneType,
  onDebug = (
    label: "act" | "respond",
    eventName: EventName,
    caller: (...args: any[]) => any,
    scene: SceneType
  ) => console.debug(`[${prefix}.${label}]`, eventName, label, caller, scene)
): CreateSceneType<EventName, SceneType> {
  const Context = createContext<ContextShape<EventName, SceneType>>({
    addResponder: () => {
      throw new Error("Not in a director");
    },
    removeResponder: () => {
      throw new Error("Not in a director");
    },
    act: () => {
      throw new Error("Not in a director");
    },
    getScene: () => {
      throw new Error("Not in a director");
    }
  });

  function useScene() {
    return useContext(Context);
  }

  function useResponder(
    eventType: EventName,
    onResponse: RespondFn<EventName, SceneType>,
    deps: DependencyList = []
  ): void {
    const { addResponder, removeResponder } = useScene();
    const onResponseCallback = useCallback(onResponse, deps);
    useEffect(() => {
      addResponder(eventType, onResponseCallback);
      return () => {
        removeResponder(eventType, onResponseCallback);
      };
    }, [addResponder, eventType, removeResponder, onResponseCallback]);
  }

  function useActor<InputType>(
    eventType: EventName,
    action: (input: InputType | undefined, scene: SceneType) => SceneType = (
      input: InputType | undefined,
      scene: SceneType
    ) => scene,
    deps: DependencyList = []
  ): (input?: InputType) => void {
    const { act } = useScene();
    const actionCallback = useCallback(action, deps);
    return useCallback<(input?: InputType) => void>(
      (input?: InputType) => {
        act(
          eventType,
          (scene: SceneType): SceneType => actionCallback(input, scene)
        );
      },
      [act, actionCallback, eventType, ...deps]
    );
  }

  function useInit(
    onInit: InitFn<EventName, SceneType>,
    deps: DependencyList = []
  ) {
    const { getScene, act } = useScene();
    const initFn = useCallback(onInit, [...deps]);
    useEffect(() => {
      initFn(getScene(), act);
    }, [getScene, initFn, ...deps]);
  }

  const Initer = React.memo<IniterProps<EventName, SceneType>>(({ onInit }) => {
    useInit(onInit);
    return null;
  });

  const Responder = React.memo<ResponderProps<EventName, SceneType>>(
    ({ eventType, onResponse }: ResponderProps<EventName, SceneType>) => {
      useResponder(eventType, onResponse, [onResponse]);
      return null;
    }
  );
  Responder.displayName = `${prefix}Responder`;
  interface WithActorProps {
    act: ActFn<EventName, SceneType>;
  }

  const withActor: HOCVoid = (
    WrappedComponent: ComponentClass<WithActorProps>
  ) =>
    applyHOCProperties({
      displayName: "withActor",
      WrappedComponent,
      HigherOrderComponent: (props, ref) => {
        const { act } = useScene();
        return <WrappedComponent {...props} act={act} ref={ref} />;
      }
    });

  const withActorStubs: WithActorProps = { act: (_e: EventName) => undefined };

  @withActor
  class Actor extends Component<WithActorProps> {
    act = this.props.act;

    render() {
      return null;
    }
  }

  class Director
    extends Component<DirectorProps<EventName, SceneType>>
    implements DirectorInterface<EventName, SceneType>
  {
    static displayName = `${prefix}Director`;
    responders = new Map<EventName, Array<RespondFn<EventName, SceneType>>>();
    scene: SceneType = this.props.initialScene ?? defaultInitialScene;

    componentDidMount() {
      if (initialize) {
        this.scene = initialize(this.scene);
      }
      if (this.props.onInit) {
        this.props.onInit(this.scene, this.act);
      }
    }

    addResponder = (
      type: EventName,
      responder: RespondFn<EventName, SceneType>
    ) => {
      this.getRespondersByType(type).push(responder);
    };

    removeResponder = (
      type: EventName,
      responder: RespondFn<EventName, SceneType>
    ) => {
      const responders = this.getRespondersByType(type).filter(
        (r) => r !== responder
      );
      this.responders.set(type, responders);
    };

    debug = async (
      label: "act" | "respond",
      eventName: EventName,
      caller: (...args: any[]) => any,
      scene: SceneType
    ) => {
      if (!this.props.debug) return;
      onDebug(label, eventName, caller, scene);
    };

    act = (
      eventType: EventName,
      fn: (scene: SceneType) => SceneType = (scene) => scene
    ) => {
      const nextSceneType = fn(this.scene);
      this.debug("act", eventType, fn, nextSceneType);
      this.scene = nextSceneType;

      // get a new copy to avoid not calling some responders when
      // the component unmounts in between calls
      const responders = this.getRespondersByType(eventType).slice();
      responders.forEach((responder: RespondFn<EventName, SceneType>) => {
        this.debug("respond", eventType, responder, nextSceneType);
        responder(nextSceneType, this.act);
      });
    };

    getRespondersByType = (type: EventName) => {
      let responders = this.responders.get(type);
      if (!responders) {
        responders = [];
        this.responders.set(type, responders);
      }
      return responders;
    };

    getScene = () => this.scene;

    render() {
      return (
        <Context.Provider
          value={{
            addResponder: this.addResponder,
            removeResponder: this.removeResponder,
            act: this.act,
            getScene: this.getScene
          }}
        >
          {this.props.children}
        </Context.Provider>
      );
    }
  }

  return {
    Director,
    Responder,
    Actor,
    Initer,
    useScene,
    useActor,
    useResponder,
    useInit,
    withActor,
    withActorStubs
  };
}
