import { Platform } from "react-native";

// TODO: compute real scrollbar width based on a scrollable element
export const getScrollbarWidth = () => {
  if (Platform.OS === "web") return 15;
  return 0;
  // const scrollDiv = document.createElement("div");
  // scrollDiv.className = "scrollbar-measure";
  // document.body.appendChild(scrollDiv);

  // // Get the scrollbar width
  // const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  // document.body.removeChild(scrollDiv);

  // return scrollbarWidth;
};
