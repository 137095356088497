import type { ComponentType } from "react";

interface testComponent {
  displayName?: string;
  name?: string;
}
export default function getComponentDisplayName(
  prefix: string,
  component: ComponentType<any> | testComponent
): string {
  const displayName = component.displayName ?? component.name ?? "Component";
  return `${prefix}(${displayName})`;
}
