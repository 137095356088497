import * as toast from "./actions";
export * from "./BlockToast";

export { default as ToastProvider } from "./ToastProvider";

export { toast };

declare global {
  interface Window {
    toast: typeof toast;
  }
}

if (__DEV__) {
  window.toast = toast;
}
