import camera from "../configs/camera";
import defaultImpl from "./defaultImpl.web";

export default camera.register(
  defaultImpl({
    name: "camera",
    deviceAction: () => {
      return new Promise((resolve) => {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(() => resolve())
          .catch(() => resolve());
      });
    }
  })
);
