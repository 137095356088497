import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";
import { RelayOrchestrationProvider } from "./relay-orchestration-provider";
import type { RelayOrchestratorOptions } from "./types";

export type { Environment } from "react-relay";

export const providesRelayOrchestration =
  (options: RelayOrchestratorOptions): HOCVoid =>
  (WrappedComponent: ComponentType<any>) =>
    applyHOCProperties({
      displayName: "providesRelayOrchestration",
      WrappedComponent,
      HigherOrderComponent: (props: any) => (
        <RelayOrchestrationProvider {...options}>
          <WrappedComponent {...props} />
        </RelayOrchestrationProvider>
      )
    });
