import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";
import type { RelayOrchestratorProps } from "./context";
import { useRelayOrchestrator } from "./use-relay-orchestrator";

/** @deprecated Use hooks instead of HOCs */
export const withRelayOrchestrator: HOCVoid = (
  WrappedComponent: ComponentType<RelayOrchestratorProps & any>
) =>
  applyHOCProperties({
    displayName: "withRelayOrchestrator",
    WrappedComponent,
    HigherOrderComponent: (props) => {
      const relay = useRelayOrchestrator();
      return <WrappedComponent {...props} relay={relay} />;
    }
  });
