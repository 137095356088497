import { compact } from "lodash";
import React, { type ComponentProps } from "react";
import AbsoluteContainer from "../atoms/AbsoluteContainer";
import Avatar, {
  getAvatarSize,
  type Props as AvatarProps
} from "../atoms/Avatar";
import Row from "../quarks/Row";
import { useStyles } from "../style";

type Sizes = Pick<ComponentProps<typeof Avatar>, "size">["size"];
interface Props {
  avatars: Array<string | AvatarProps>;
  size?: Exclude<Sizes, undefined>;
  max?: number;
  testID?: string;
}
export default function AvatarStack({
  avatars,
  size = "small",
  max = 3,
  testID = "avatar-stack"
}: Props) {
  const list = compact(
    avatars.length > max
      ? [`+${avatars.length - max}`, ...avatars.slice(0, max - 1)]
      : avatars
  );
  const avatarSize = getAvatarSize({ size, variant: "default" });
  const containerWidth = avatarSize * list.length;
  const offset = avatarSize * 0.3;
  const styles = useStyles(
    () => ({
      container: {
        width: containerWidth - offset * (list.length - 1),
        height: 28
      }
    }),
    [containerWidth, offset, list.length]
  );
  return (
    <Row
      testID={testID}
      style={styles.container}
      alignItems="center"
      justifyContent="center"
    >
      {list.map((avatar, index) => {
        return (
          <AbsoluteContainer
            key={typeof avatar === "string" ? avatar : avatar.icon}
            zIndex={list.length - index}
            left={avatarSize * index - offset * index}
          >
            <Avatar
              size={size}
              {...(typeof avatar === "string" && isUrl(avatar as string)
                ? { uri: avatar as string }
                : typeof avatar === "object" && avatar.icon
                  ? {
                      icon: avatar.icon,
                      color: avatar.color,
                      fade: avatar.fade,
                      iconSize: avatar.iconSize
                    }
                  : {
                      initials:
                        typeof avatar === "string"
                          ? (avatar as string)?.toUpperCase()
                          : "",
                      fade: false,
                      color: "primary"
                    })}
            />
          </AbsoluteContainer>
        );
      })}
    </Row>
  );
}

function isUrl(input: string) {
  try {
    const url = new URL(input);
    return !!url.host;
  } catch (error) {
    return false;
  }
}
