import React, { type ComponentProps, type ReactNode } from "react";
import type { FlexAlignType } from "react-native";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import {
  type FlexStyle,
  type FontWeightName,
  type Size,
  useStyles
} from "../style";
import type Stack from "./Stack";

interface Props {
  fill?: FlexStyle["flex"] | boolean;
  alignSelf?: FlexStyle["alignSelf"];
  label?: string | ReactNode;
  weight?: FontWeightName;
  numberOfLines?: number;
  children?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  spacing?: Size | "none";
  leftSpacing?: Size | "none";
  rightSpacing?: Size | "none";
  verticalSpacing?: Size | "none";
  alignItems?: FlexAlignType;
  shrink?: boolean;
  leftAlign?: FlexAlignType;
  rightAlign?: FlexAlignType;
  stackSize?: ComponentProps<typeof Stack>["size"];
  testID?: string;
}

const spacingUnits = {
  xlarge: 5,
  large: 4,
  medium: 3,
  small: 2,
  xsmall: 1,
  none: 0
};

const ListRow = ({
  label,
  numberOfLines,
  children,
  left,
  right,
  spacing = "large",
  leftSpacing = "medium",
  rightSpacing = "medium",
  verticalSpacing = "small",
  stackSize = "slim",
  alignItems = "center",
  shrink,
  leftAlign,
  rightAlign,
  weight = "normal",
  ...rest
}: Props) => {
  const styles = useStyles(
    ({ getUnits }) => ({
      container: {
        paddingLeft: getUnits(spacingUnits[leftSpacing]),
        paddingRight: getUnits(spacingUnits[rightSpacing]),
        paddingVertical: getUnits(spacingUnits[verticalSpacing]),
        minHeight: shrink ? undefined : getUnits(13)
      },
      leftContent: { marginRight: getUnits(spacingUnits[spacing]) },
      rightContent: { marginLeft: getUnits(spacingUnits[spacing]) }
    }),
    [spacing, leftSpacing, rightSpacing, verticalSpacing, shrink]
  );

  return (
    <Row style={styles.container} alignItems={alignItems} {...rest}>
      {!!left && (
        <Column style={styles.leftContent} alignSelf={leftAlign}>
          {typeof left === "string" ? (
            <Text testID="list-row-left-txt" weight={weight}>
              {left}
            </Text>
          ) : (
            left
          )}
        </Column>
      )}
      <Column fill gap={stackSize}>
        {typeof label === "string" ? (
          <Text
            numberOfLines={numberOfLines}
            testID="list-row-label-txt"
            weight={weight}
          >
            {label}
          </Text>
        ) : (
          label
        )}
        {children}
      </Column>
      {!!right && (
        <Column style={styles.rightContent} alignSelf={rightAlign}>
          {typeof right === "string" ? (
            <Text testID="list-row-right-txt" weight={weight}>
              {right}
            </Text>
          ) : (
            right
          )}
        </Column>
      )}
    </Row>
  );
};

export default ListRow;
