import type { Location, LocationState } from "history";
import { type ParseOptions, type ParsedQuery, parse } from "query-string";
import { useMemo } from "react";
import {
  useHistory as useRRHistory,
  useLocation as useRRLocation,
  useParams as useRRParams,
  useRouteMatch as useRRRouteMatch
} from "react-router";
import type { RouterHistory } from "./types";

/** @deprecated use navigation */
export const useRouteParams = useRRParams;
/** @deprecated use navigation */
export const useRouteMatch = useRRRouteMatch;
/** @deprecated use navigation */
export const useLocation = useRRLocation;

export type HistoryType = ReturnType<typeof useHistory>;

/** @deprecated use navigation */
export function useQueryParams(
  options: { parseBooleans: true; parseNumbers: true } & ParseOptions
): ParsedQuery<string | boolean | number>;
/** @deprecated use navigation */
export function useQueryParams(
  options: { parseBooleans: true } & ParseOptions
): ParsedQuery<string | boolean>;
/** @deprecated use navigation */
export function useQueryParams(
  options: { parseNumbers: true } & ParseOptions
): ParsedQuery<string | number>;
/** @deprecated use navigation */
export function useQueryParams<T = ParsedQuery>(): T;
/** @deprecated use navigation */
export function useQueryParams(options?: ParseOptions): ParsedQuery {
  const location = useLocation();
  return useMemo(() => {
    return parse(location.search, options);
  }, [location.search]);
}

/** @deprecated use navigation */
export function getQueryParams<T = ParsedQuery, S = LocationState>(
  location: Location<S>,
  options?: ParseOptions
): T {
  return parse(location.search, options) as unknown as T;
}

/** @deprecated use navigation */
export function useHistory<S = {} | null | undefined>(): RouterHistory<S> {
  return useRRHistory<S>() as RouterHistory<S>;
}
