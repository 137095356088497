import React from "react";
import { Platform, View } from "react-native";
import { Icon } from "../../quarks";
import { useStyles } from "../../style";

export const MarkerPin = () => {
  const styles = useStyles(() => ({
    marker: {
      width: 44,
      height: 44,
      alignItems: "center",
      justifyContent: "center"
    },
    circle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      backgroundColor: "white",
      width: 10,
      height: 10,
      marginLeft: -5,
      marginTop: -7,
      borderRadius: 5
    }
  }));

  return (
    <View style={Platform.OS !== "web" && styles.marker}>
      <View style={styles.circle} />
      <Icon name="location-dot" color="danger" variant="solid" size="default" />
    </View>
  );
};
