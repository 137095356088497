import React, { type ComponentProps, type ReactNode } from "react";
import { Platform, Text } from "react-native";

import {
  type TextStyleProp,
  type WeightEnum,
  createStyles,
  theme
} from "../style";

// Aux
const headerText = (
  size: number,
  color: string = theme.color.blue,
  weight: WeightEnum = "semibold"
) => ({
  ...theme.font.withSize(size, theme.font.lineHeight.header),
  ...theme.font.header(weight),
  marginVertical: size * 0.5,
  textAlign: Platform.OS === "web" ? "left" : "center",
  color
});

const styles = createStyles(({ font, color }) => ({
  base: {
    ...font.withSize(font.size.bodyCopy),
    ...font.body("regular")
  },
  bold: { ...font.body("bold") },
  semibold: { ...font.body("semibold") },
  helper: {
    ...font.withSize(font.size.helperText),
    color: color.neutralDark
  },
  paragraph: {
    marginBottom: 5,
    marginTop: 5
  },

  header1: headerText(font.size.heading1),
  header2: headerText(font.size.extraLarge),
  header3: headerText(font.size.large, color.black),
  header4: headerText(font.size.medium),
  headerXL: headerText(font.size.title),

  h2: {
    ...font.body("semibold"),
    ...font.withSize(18, font.lineHeight.header),
    color: color.blue
  },
  h3: {
    ...font.body("bold"),
    ...font.withSize(font.size.large),
    color: color.blue
  },
  h4: {
    ...font.body("bold"),
    ...font.withSize(font.size.medium, font.lineHeight.header),
    color: color.blue
  },
  link: {
    color: color.spiceBlue
  },
  inputLabel: {
    ...font.body("bold"),
    paddingTop: 5,
    color: color.blue,
    marginBottom: 3
  },

  rowTitle: {
    ...font.body("bold")
  },
  rowDescription: {
    ...font.withSize(font.size.small),
    color: color.neutralDark
  },
  sectionTitle: {
    ...font.withSize(font.size.large),
    ...font.body("semibold"),
    color: color.blue
  },
  descriptionItem: {
    ...font.withSize(font.size.large),
    ...font.body("bold"),
    color: color.black
  }
}));

export type TextVariant = keyof typeof styles;

export const textVariants: TextVariant[] = Object.keys(styles) as any;

type Props = ComponentProps<typeof Text> & {
  variant?: TextVariant;
  style?: TextStyleProp;
  color?: string;
  children?: ReactNode | null;
  inherit?: boolean;
};

const KatanaText = ({ style, variant, color, inherit, ...props }: Props) => {
  const textStyles = [];
  if (!inherit) textStyles.push(styles.base);
  if (variant && styles[variant]) textStyles.push(styles[variant]);
  if (color) textStyles.push({ color: theme.color.getColor(color) });
  if (style) textStyles.push(style as any);

  return <Text {...props} allowFontScaling={false} style={textStyles} />;
};

export default KatanaText;
