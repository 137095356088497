import React, { type ReactNode } from "react";
import ContentArea from "../atoms/ContentArea";
import Stack from "../atoms/Stack";
import Surface from "../atoms/Surface";
import Pressable from "../quarks/Pressable";
import Radio from "../quarks/Radio";
import Text from "../quarks/Text";

interface Props {
  title: string | ReactNode;
  description: string;
  onSelect: (selected: boolean) => void;
  selected?: boolean;
  disabled?: boolean;
  testID: string;
  variant?: "outline" | "flat";
}

export default function RadioButtonBox({
  title,
  description,
  testID,
  selected,
  disabled,
  onSelect,
  variant = "outline"
}: Props) {
  return (
    <Pressable
      eventEntityType="RadioButtonBox"
      eventTargetName="Radio Button Box"
      onPress={() => !disabled && onSelect?.(!selected)}
      testID={testID}
    >
      <Surface variant={variant}>
        <ContentArea fill>
          <Stack
            horizontal
            fill
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack size="compact" fill>
              <Text weight="semibold">{title}</Text>
              <Text>{description}</Text>
            </Stack>
            <Radio
              testID={`${testID}-radio`}
              eventTargetName="Radio Button"
              selected={selected}
              onSelect={() => onSelect?.(!selected)}
              disabled={disabled}
            />
          </Stack>
        </ContentArea>
      </Surface>
    </Pressable>
  );
}
