import { noop } from "lodash";
import React, { useContext } from "react";

export type ModalVariant =
  | "prompt"
  | "full"
  | "full-shadow"
  | "full-border"
  | "shadow"
  | "border"
  | "none";

export type ModalSize = "small" | "medium" | "large";

export const Context = React.createContext<{
  size: ModalSize;
  onRequestClose?: () => void;
  dismissable: boolean;
  variant: ModalVariant;
  fixedHeight: boolean | number;
}>({
  size: "small",
  onRequestClose: noop,
  variant: "none",
  fixedHeight: false,
  dismissable: true
});

const ModalDebugContext = React.createContext({
  currentModalIndex: 0,
  modalQueueLength: 0
});

export const ModalDebugContextProvider = ModalDebugContext.Provider;

export const useModalContext = () => useContext(Context);
export const useModalDebug = () => React.useContext(ModalDebugContext);
