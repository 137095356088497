import React, { useState, useEffect } from "react";
import { type ImageProps, Image as RNImage } from "react-native";
import { useDiscriminatedStyle, useStyle } from "../style";

interface Props extends ImageProps {
  height: number;
  source: ImageProps["source"];
  variant?: "standard" | "circle" | "square";
  testID?: ImageProps["testID"];
}

export default function Image({
  height,
  variant = "standard",
  source,
  borderRadius,
  ...imageProps
}: Props) {
  // Fetch the aspect ratio of the original image
  const [aspectRatio, setAspectRatio] = useState(4 / 3);
  useEffect(() => {
    if (
      variant === "standard" &&
      typeof source === "object" &&
      "uri" in source &&
      source.uri
    ) {
      RNImage.getSize(source.uri, (srcWidth, srcHeight) => {
        setAspectRatio(srcWidth / srcHeight);
      });
    }
  }, [source, variant]);

  // Define variants
  const variantStyle = useDiscriminatedStyle(
    variant,
    ({ getUnits }) => ({
      circle: {
        borderRadius: height / 2,
        width: height
      },
      square: {
        borderRadius: getUnits(borderRadius ?? 1),
        width: height
      },
      standard: {
        borderRadius: getUnits(borderRadius ?? 1),
        width: Math.floor(height * aspectRatio)
      }
    }),
    [height, aspectRatio, borderRadius]
  );

  const defaultStyle = useStyle({ height }, [height]);

  return (
    <RNImage
      style={[defaultStyle, variantStyle]}
      source={source}
      {...imageProps}
    />
  );
}
