import React from "react";
import { View } from "react-native";

import ContentArea from "../atoms/ContentArea";
import Column from "../quarks/Column";
import Icon, { type IconName, type IconVariant } from "../quarks/Icon";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useStyles } from "../style";

interface Props {
  title?: string;
  icon?: IconName;
  iconVariant?: IconVariant;
  options?: string[];
  testID: string;
}
export default function MultiSelectHeader({
  title,
  icon,
  iconVariant,
  options,
  testID
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    iconContainer: {
      width: 20,
      justifyContent: "center",
      alignItems: "center"
    },
    icon: {
      alignSelf: "center"
    },
    title: {
      paddingRight: getUnits(2)
    },
    optionContainer: {
      width: 52,
      alignSelf: "flex-end",
      justifyContent: "flex-end"
    }
  }));
  return (
    <View testID={`${testID}-multi-select-header`}>
      <ContentArea variant="none" size="compact">
        <Row justifyContent="flex-end" fill={1}>
          {icon && (
            <>
              <Column
                style={styles.iconContainer}
                testID={`${testID}-multi-select-header-icon`}
              >
                <Icon
                  style={styles.icon}
                  size="small"
                  name={icon}
                  color="primary"
                  variant={iconVariant}
                />
              </Column>
              <Spacer horizontal size="compact" />
            </>
          )}
          {!!title && (
            <Text
              variant="subheader"
              color="primary"
              testID={`${testID}-multi-select-header-title`}
              numberOfLines={1}
              fill={1}
              wrap
              style={styles.title}
            >
              {title}
            </Text>
          )}
          {options?.map((option, index) => (
            <Column key={index} style={styles.optionContainer}>
              <Text
                testID={`${testID}-multi-select-header-option-${index}`}
                align="center"
                weight="bold"
              >
                {option}
              </Text>
            </Column>
          ))}
        </Row>
      </ContentArea>
    </View>
  );
}
