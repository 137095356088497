import { type DependencyList, useMemo } from "react";
import {
  type ImageStyle,
  StyleSheet,
  type TextStyle,
  type ViewStyle
} from "react-native";
import { type Thunk, dethunk } from "../utils/thunk";

export function useStylesheet<
  T extends StyleSheet.NamedStyles<T> | StyleSheet.NamedStyles<any>
>(styles: Thunk<[], T | StyleSheet.NamedStyles<T>>, deps: DependencyList = []) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => StyleSheet.create<T>(dethunk(styles) as any), deps);
}

export function useStylesheetStyle<
  S extends ViewStyle | TextStyle | ImageStyle
>(style: Thunk<[], S>, deps: DependencyList = []) {
  // eslint-disable-next-line no-restricted-properties
  return useStylesheet(() => ({ style: dethunk(style) }), deps).style;
}
