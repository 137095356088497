import type { ReactNode } from "react";
import { Alert, Platform } from "react-native";
import { showModal } from "../organisms/ModalProvider";
import type { IconName } from "../quarks";

export interface PromptAction<T> {
  testID: string;
  label: string;
  value: T;
  type?: "default" | "cancel" | "destructive";
  outline?: boolean;
}

export interface PromptOptions<T> {
  title: string;
  subTitle?: string;
  headerIcon?: IconName;
  horizontalActions?: boolean;
  actions: Array<PromptAction<T>>;
  dismissable?: boolean;
  children?: ReactNode;
}

export async function confirmPrompt<T>({
  title,
  subTitle,
  headerIcon,
  horizontalActions = true,
  actions,
  children,
  dismissable = true
}: PromptOptions<T>) {
  return await new Promise<T | null>((resolve) => {
    // Use Modals when the prompt has special needs (headerIcon or vertical actions)
    const shouldUseModal =
      Platform.OS === "web" || !!(headerIcon || !horizontalActions || children);

    if (!shouldUseModal) {
      Alert.alert(
        title,
        subTitle,
        actions.map((it) => ({
          style: it.type,
          text: it.label,
          onPress: () => resolve(it.value)
        })),
        { cancelable: dismissable, onDismiss: () => resolve(null) }
      );
      return;
    }

    const handleSelect = (value: T | null) => {
      modal?.dispose();
      modal = undefined;
      setTimeout(() => resolve(value), 200);
    };
    let modal: { dispose: () => void } | undefined = showModal({
      variant: "prompt",
      eventContext: null,
      headerIcon,
      title,
      subTitle,
      horizontalActions,
      subTitleItalic: false,
      onRequestClose: () => handleSelect(null),
      dismissable,
      children,
      actions: [...actions].reverse().map((it) => ({
        testID: it.testID,
        label: it.label,
        autoClose: false,
        outline: it.outline ?? (it.type === "cancel" || !it.type),
        color: it.type === "destructive" ? "danger" : "primary",
        fill: horizontalActions,
        onPress: () => handleSelect(it.value)
      }))
    });
  });
}
