import React from "react";
import type { ComponentProps, ReactNode } from "react";
import Checkbox from "../atoms/Checkbox";
import ContentArea from "../atoms/ContentArea";
import Stack from "../atoms/Stack";
import Surface from "../atoms/Surface";
import Column from "../quarks/Column";
import Text from "../quarks/Text";

interface Props extends ComponentProps<typeof Checkbox> {
  number?: number;
  label?: string | ReactNode | undefined;
}

export default function CheckboxSurface({
  number,
  label,
  ...checkboxProps
}: Props) {
  return (
    <Surface variant="outline">
      <ContentArea>
        <Stack horizontal>
          {number && (
            <Column>
              <Text weight="bold">{number}.</Text>
            </Column>
          )}
          <Stack horizontal alignItems="center" fill>
            <Column fill>
              <Text>{label}</Text>
            </Column>
            <Checkbox {...checkboxProps} />
          </Stack>
        </Stack>
      </ContentArea>
    </Surface>
  );
}
