import React, { Children, type ReactElement } from "react";

import useHover from "./useHover";

interface Props {
  children: (isHover: boolean) => ReactElement;
}

const Hoverable = ({ children }: Props) => {
  const [isHover, hoverProps] = useHover();
  const child = children(isHover);
  return React.cloneElement(Children.only(child), hoverProps);
};

export default Hoverable;
