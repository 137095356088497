import { applyHOCProperties } from "@gigsmart/hoc-utils";
import * as React from "react";
import {
  type Disposable,
  type GraphQLTaggedNode,
  type RefetchOptions,
  createRefetchContainer
} from "react-relay";
import type { Environment, OperationType } from "relay-runtime";
import { createEnvironmentStub } from "../environment";

export const relayRefetchStubs: RefetchProps = {
  relay: {
    get environment() {
      return createEnvironmentStub();
    },
    refetch: () => ({
      dispose: () => undefined
    })
  }
};

export interface RefetchProps {
  relay: {
    environment: Environment;
    refetch: <TOperation extends OperationType>(
      refetchVariables:
        | TOperation["variables"]
        | ((
            fragmentVariables: Record<string, unknown>
          ) => TOperation["variables"]),
      renderVariables: Object | null | undefined,
      callback: (error: Error | null | undefined) => unknown,
      options?: RefetchOptions
    ) => Disposable;
  };
}

export const withRelayRefetch =
  (
    fragmentSpec: Record<string, GraphQLTaggedNode>,
    refetchQuery: GraphQLTaggedNode
  ) =>
  (WrappedComponent: React.ComponentType<any>) => {
    const RefetchContainer = createRefetchContainer(
      WrappedComponent,
      fragmentSpec,
      refetchQuery
    );
    return applyHOCProperties({
      displayName: "withRelayRefetch",
      WrappedComponent,
      HigherOrderComponent: (props: any) => <RefetchContainer {...props} />
    });
  };
