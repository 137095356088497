import React from "react";
import type { ComponentProps } from "react";
import Pressable from "../quarks/Pressable";
import Radio from "../quarks/Radio";
import { useStyles } from "../style";

type Props = ComponentProps<typeof Radio>;

export default function RadioSquare({
  selected,
  onSelect,
  testID,
  disabled,
  ...props
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      minHeight: 52,
      width: 52,
      alignItems: "center",
      justifyContent: "center"
    },
    selected: {
      backgroundColor: getColor("highlight", "fill", { opacity: 0.08 })
    }
  }));
  return (
    <Pressable
      testID={`${testID}`}
      eventEntityType="RadioSquare"
      eventTargetName="Radio Square Pressable"
      onPress={() => onSelect?.(!selected)}
      style={[styles.container, selected && styles.selected]}
      disabled={disabled}
    >
      <Radio
        testID={testID}
        selected={selected}
        onSelect={onSelect}
        disabled={disabled}
        {...props}
      />
    </Pressable>
  );
}
