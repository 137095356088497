import type { ValuesType } from "@gigsmart/type-utils";
import Color from "color";
import memoize from "lodash/memoize";

export const BRAND_COLORS = {
  // Basic colors
  black: "#000000",
  white: "#ffffff",
  transparent: "transparent",

  // Official Brand Guideline Colors
  red: "#B22222", // Red
  blue: "#003b5c", // Dark Midnight Blue
  blueTint: "#2c5697", // B'Dazzled Blue
  spiceBlue: "#3EAFE0", // Aero
  orange: "#d54405", // Sinopia
  spiceOrange: "#ffB549", // Pastel Orange
  spiceOrangeDark: "#F69E1E", // Pastel Orange
  neutralDark: "#8a8c8e", // Taupe Gray
  neutralDark2: "#666660",
  neutralMedium: "#bdc6cf", // Lavender Gray
  neutralMediumGray: "#d9d9d9", // Fog Gray
  neutralLight: "#e7e7e7", // Platinum
  neutralLightGray: "#f5f5f5", // White Smoke
  green: "#24925c" // Sea Green
};

type BrandColorEnum = ValuesType<typeof BRAND_COLORS>;

const _getColorInstance = memoize((colorHex: BrandColorEnum) => {
  const validColors = Object.values(BRAND_COLORS);
  const isValid = validColors.includes(colorHex);
  if (!isValid) {
    console.warn(
      'Invalid color "%s", use one of %s',
      colorHex,
      validColors.join(",")
    );
  }

  return new Color(colorHex);
});

const colorHelpers = {
  getColor(colorStr: string): string {
    if (
      typeof colorStr === "string" &&
      Object.prototype.hasOwnProperty.call(BRAND_COLORS, colorStr)
    ) {
      return (BRAND_COLORS as any)[colorStr];
    }
    return colorStr;
  },
  withOpacity(colorStr: string, opacity: number) {
    return _getColorInstance(colorStr).alpha(opacity).string();
  },
  darken(colorStr: string, ratio: number) {
    return _getColorInstance(colorStr).darken(ratio).string();
  },
  lighten(colorStr: string, ratio: number) {
    return _getColorInstance(colorStr).lighten(ratio).string();
  }
};

export default {
  ...colorHelpers,
  ...BRAND_COLORS,
  shadow: BRAND_COLORS.neutralDark,
  pageBackground: BRAND_COLORS.neutralLightGray,
  cardBackground: BRAND_COLORS.white,
  inactiveButton: BRAND_COLORS.neutralLight,
  inactiveText: BRAND_COLORS.neutralDark,

  error: BRAND_COLORS.orange,
  warning: BRAND_COLORS.spiceOrange,
  information: BRAND_COLORS.green,
  success: BRAND_COLORS.blueTint
};
