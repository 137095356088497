import React, { type ReactNode, useContext } from "react";
import { StyleSheet } from "react-native";
import Animated, {
  useAnimatedStyle,
  withRepeat,
  withSequence,
  withTiming
} from "react-native-reanimated";
import type { ViewStyle } from "../style";

const Context = React.createContext<ViewStyle | null>(null);

type Props = {
  loading?: boolean;
  children?: ReactNode;
};

export default function Skeleton({ loading, children }: Props) {
  const style = useAnimatedStyle(() => ({
    opacity: withRepeat(
      withSequence(
        withTiming(1, { duration: 200 }),
        withTiming(0.4, { duration: 800 }),
        withTiming(1, { duration: 1300 })
      ),
      -1
    )
  }));

  // TODO: use children to compute and figure out what kind of bones to show
  return (
    <Context.Provider value={loading ? style : null}>
      {children}
    </Context.Provider>
  );
}

type BoneProps = {
  lines?: number;
};

export function SkeletonBone({ lines = 1 }: BoneProps) {
  const animStyle = useContext(Context);
  const extras: ViewStyle = {};
  if (lines > 1) extras.height = 10 * lines;
  return <Animated.View style={[styles.bone, extras, animStyle]} />;
}

const styles = StyleSheet.create({
  bone: {
    height: 14,
    width: "100%",
    borderRadius: 3,
    backgroundColor: "#e7e7e7"
  }
});
