import React, {
  Children,
  type ComponentProps,
  isValidElement,
  type ReactNode
} from "react";
import { View } from "react-native";
import { ContentArea, useContentArea } from "../atoms";
import {
  type ResponsiveValue,
  type Size,
  useResponsiveValue,
  useStyles
} from "../style";

interface Props
  extends Pick<
    ComponentProps<typeof ContentArea>,
    "fill" | "constraint" | "size" | "variant"
  > {
  numColumns?: ResponsiveValue<number>;
  children?: ReactNode;
  spacing?: Size | "none";
}

const spacingmap: Record<Size, number> = {
  xlarge: 8,
  large: 5,
  medium: 4,
  small: 2,
  xsmall: 1
};

export function useGridStyles(
  {
    spacing = "medium",
    numColumns = 1,
    constraint = "xxlarge",
    size = "none",
    variant = "none"
  }: Pick<Props, "spacing" | "constraint" | "numColumns" | "variant" | "size">,
  wrap = true
) {
  const columns = useResponsiveValue(numColumns) || 1;
  const [contentStyle, isConstrained] = useContentArea({
    size,
    constraint,
    variant,
    constrainedVariant: "none"
  });
  const gridStyles = useStyles(
    ({ getUnits }) => {
      const pad = spacing === "none" ? 0 : getUnits(spacingmap[spacing]);
      return {
        container: {
          paddingHorizontal: pad / 2,
          marginLeft: -pad / 2,
          marginRight: -pad / 2,
          ...(wrap && { flexDirection: "row", flexWrap: "wrap" })
        },
        item: {
          width: `${100 / columns}%`,
          paddingLeft: columns === 1 ? pad : pad / 2,
          paddingRight: columns === 1 ? pad : pad / 2,
          marginBottom: pad
        },
        itemWrapper: columns === 1 ? {} : { paddingHorizontal: pad / 2 }
      };
    },
    [spacing, columns]
  );

  return {
    columns,
    contentStyle,
    gridStyles,
    isConstrained
  };
}

/** @deprecated Use GridContainer `atom` instead */
export default function Grid({ children, ...props }: Props) {
  const { gridStyles } = useGridStyles(props, true);
  const validChildren = Children.toArray(children).filter(
    (child) => isValidElement(child) && !!child
  );

  return (
    <ContentArea {...props}>
      <View style={gridStyles.container}>
        {validChildren.map((el, idx) => (
          <View key={idx} style={gridStyles.item}>
            {el}
          </View>
        ))}
      </View>
    </ContentArea>
  );
}
