import React from "react";
import type { ComponentProps, ReactNode } from "react";
import { RadioSquare } from "../atoms";
import type { IconName } from "../quarks";
import InputRow from "./InputRow";
import InputRowAccessory from "./InputRowAccessory";

export interface Props extends ComponentProps<typeof RadioSquare> {
  title: ComponentProps<typeof InputRow>["title"];
  note?: ComponentProps<typeof InputRow>["note"];
  onTooltipPress?: ComponentProps<typeof InputRow>["onTooltipPress"];
  onEditPress?: ComponentProps<typeof InputRow>["onEditPress"];
  icon?: IconName;
  iconSquare?: IconName;
  avatar?: string;
  selectedChildren?: ReactNode;
  isRowSelectable?: boolean;
}

export default function RadioButtonRow({
  testID,
  title,
  note,
  onTooltipPress,
  onEditPress,
  icon,
  iconSquare,
  avatar,
  selected,
  onSelect,
  isRowSelectable = true,
  ...rest
}: Props) {
  return (
    <InputRow
      testID={testID}
      title={title}
      note={note}
      onPress={isRowSelectable ? () => onSelect?.(!selected) : undefined}
      left={
        <InputRowAccessory
          icon={icon}
          iconSquare={iconSquare}
          avatar={avatar}
        />
      }
      onTooltipPress={onTooltipPress}
      onEditPress={onEditPress}
      right={
        <RadioSquare
          testID={`${testID}-radio`}
          selected={selected}
          onSelect={onSelect}
          {...rest}
        />
      }
      selectedChildren={selected && rest.selectedChildren}
    />
  );
}
