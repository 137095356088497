import { useEffect } from "react";

interface Props {
  title?: string;
  url: string;
  onDone?: () => void;
}

export function showEmbeddedBrowser({ url, onDone }: Props) {
  const win = window.open(url, "_blank");
  win?.focus();
  onDone?.();
}

// Open the url in a new tab
export default ({ visible, ...props }: Props & { visible: boolean }) => {
  if (!visible) return null;
  useEffect(() => {
    showEmbeddedBrowser(props);
  }, []);
  return null;
};
