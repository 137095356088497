import React from "react";
import { View } from "react-native";
import IconCircle from "../atoms/IconCircle";
import Pressable from "../quarks/Pressable";
import { useStyle } from "../style";

interface Props {
  onPress: () => void;
}

export default function FloatingDeleteButton({ onPress }: Props) {
  const style = useStyle(({ getUnits }) => ({
    right: getUnits(4),
    position: "absolute",
    top: getUnits(16)
  }));
  return (
    <View style={style}>
      <Pressable
        testID="close-media-picker"
        onPress={onPress}
        eventTargetName="Close Media Picker Button"
        eventEntityType="IconCircle"
      >
        <IconCircle size="small" color="danger" icon="trash" />
      </Pressable>
    </View>
  );
}
