import React from "react";
import type { ComponentProps, ReactNode } from "react";
import type Stack from "../atoms/Stack";
import { Column, Row } from "../quarks";
import Icon from "../quarks/Icon";
import type { IconName } from "../quarks/Icon";
import Text from "../quarks/Text";

interface Props {
  rows: Array<{
    icon: IconName;
    text: string | ReactNode;
    iconColor?: ComponentProps<typeof Icon>["color"];
    iconVariant?: ComponentProps<typeof Icon>["variant"];
    testID?: string;
  }>;
  size?: ComponentProps<typeof Stack>["size"];
  rowSize?: ComponentProps<typeof Stack>["size"];
  iconSize?: ComponentProps<typeof Icon>["size"];
  iconColor?: ComponentProps<typeof Icon>["color"];
  iconVariant?: ComponentProps<typeof Icon>["variant"];
}

export default function IconTextStack({
  rows,
  size = "standard",
  rowSize = "compact",
  iconSize,
  iconColor = "primary",
  iconVariant = "solid"
}: Props) {
  return (
    <Column gap={size}>
      {rows?.map(
        (
          {
            icon,
            text,
            iconColor: overrideIconColor,
            iconVariant: overrideIconVariant,
            testID
          },
          index
        ) => (
          <Row alignItems="center" gap={rowSize} key={index} testID={testID}>
            <Icon
              align="center"
              name={icon}
              size={iconSize}
              variant={overrideIconVariant ?? iconVariant}
              color={overrideIconColor ?? iconColor}
            />
            {typeof text === "string" ? (
              <Text numberOfLines={2}>{text}</Text>
            ) : (
              text
            )}
          </Row>
        )
      )}
    </Column>
  );
}
