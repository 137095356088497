import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";
import { type GraphQLTaggedNode, createFragmentContainer } from "react-relay";

interface Options<P extends object, T extends string> {
  getFragmentRefs?: (props: P) => Partial<Record<T, any>>;
}

export const withRelayFragments =
  <P extends object, T extends string>(
    fragmentSpec: Record<T, GraphQLTaggedNode>,
    { getFragmentRefs = () => ({}) }: Options<P, T> = {}
  ): HOCVoid =>
  (WrappedComponent: ComponentType<P>) => {
    const FragmentContainer = createFragmentContainer(
      WrappedComponent,
      fragmentSpec
    );
    return applyHOCProperties({
      displayName: "withRelayFragments",
      WrappedComponent,
      HigherOrderComponent: (props) => {
        return <FragmentContainer {...props} {...getFragmentRefs(props)} />;
      }
    });
  };
