import React, { type ReactNode } from "react";
import { View } from "react-native";
import Row from "../quarks/Row";
import { useStyles } from "../style";

interface Props {
  children: ReactNode;
  hasLeftAccessory?: boolean;
  hasRightAccessory?: boolean;
}

export const INPUT_HEIGHT = 44;

const PillInputContainer = ({
  children,
  hasLeftAccessory,
  hasRightAccessory
}: Props) => {
  const styles = useStyles(({ getUnits, getColor }) => ({
    container: {
      marginBottom: getUnits(1),
      backgroundColor: getColor("foreground", "fill"),
      borderRadius: INPUT_HEIGHT / 2,
      paddingHorizontal: getUnits(4),
      ...(hasRightAccessory && { paddingRight: getUnits(1) }),
      ...(hasLeftAccessory && { paddingLeft: getUnits(1) })
    },
    content: {
      minHeight: INPUT_HEIGHT
    }
  }));

  return (
    <View style={[styles.container]}>
      <Row style={styles.content} alignItems="center">
        {children}
      </Row>
    </View>
  );
};

export default PillInputContainer;
