import { createLogger } from "@gigsmart/roga";
import * as Immutable from "immutable";
import { isEqual, noop, omit, once } from "lodash";
import React, {
  type ComponentProps,
  type ReactNode,
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback
} from "react";
import { Modal, Platform, type ViewProps } from "react-native";
import Button from "../atoms/Button";
import ModalBody from "../atoms/ModalBody";
import ModalFooter from "../atoms/ModalFooter";
import ModalHeader from "../atoms/ModalHeader";
import Stack from "../atoms/Stack";
import type { IconName } from "../quarks/Icon";
import type { Color } from "../style/theme/colors";
import createEventStore from "../utils/createEventStore";
import { type Thunk, dethunk } from "../utils/thunk";
import ModalContainer from "./ModalContainer";
import {
  ModalDebugContextProvider,
  type ModalSize,
  type ModalVariant
} from "./ModalContext";

export type ModalShowFn = () => ModalHandler;
export type ModalDisposeFn = () => void;
export type ModalCloseFn = () => void;
export type ModalReadyFn = () => void;
export type ModalActionFn = (close: ModalCloseFn) => void;
export type ModalHandler = { dispose: ModalDisposeFn };

export interface ModalAction
  extends Omit<ComponentProps<typeof Button>, "onPress"> {
  onPress?: ModalActionFn;
  autoClose?: boolean;
}

export interface RawModalProps
  extends Omit<ComponentProps<typeof Modal>, "children" | "onRequestClose"> {
  raw: true;
  exclusive?: boolean;
  children?: Thunk<[ModalCloseFn], ReactNode>;
  onInit?: (setSpec: (spec: Partial<AnyModal>) => void) => void;
  onRequestClose?: () => void;
  dismissable?: boolean;
  alertIfNotShown?: boolean;
  __UNSAFE_persist?: boolean;
}

export interface ModalProps {
  raw?: false;
  exclusive?: boolean;
  variant?: ModalVariant | ((size: ModalSize) => ModalVariant);
  title?: string;
  subTitle?: string | JSX.Element;
  subTitleItalic?: boolean;
  headerColor?: Color;
  headerSpacing?: "compact" | "standard";
  headerIcon?: IconName;
  testID?: string;
  eventContext: string | null;
  children?: Thunk<[ModalCloseFn], ReactNode>;
  onRequestClose?: () => void;
  visible?: boolean;
  actions?: ModalAction[];
  horizontalActions?: boolean;
  style?: ViewProps["style"];
  fixedHeight?: number | boolean;
  useModalBody?: boolean;
  dismissable?: boolean;
  onInit?: (setSpec: (spec: Partial<AnyModal>) => void) => void;
  onClose?: () => void;
  onDismiss?: () => void;
  useModalFooter?: boolean;
  scrollable?: boolean;
  alertIfNotShown?: boolean;
  __UNSAFE_persist?: boolean;
}

export type AnyModal = ModalProps | RawModalProps;

export const createModalProvider = () => {
  const eventStore = createEventStore<{
    show: AnyModal;
    dismissAll: never;
  }>();

  // Show the modal and return the close function
  const showModal = <T extends AnyModal>(props: T) => {
    baseLogger.info("showModal", props);
    const dispose = eventStore.emit("show", props);
    return { dispose };
  };

  // useModal with auto unmounting behavior
  const useModal = <T extends AnyModal>(props: T) => {
    const logger = useMemo(() => baseLogger.createLogger("useModal"), []);
    const [visible, setVisible] = useState(!!props.visible);

    const close = useCallback(() => {
      setVisible(false);
    }, []);

    const show = useCallback(() => {
      setVisible(true);
      return { close };
    }, []);

    const onRequestClose = useCallback(() => {
      logger.info("onRequestClose");
      props.onRequestClose?.();
      close();
    }, [props.onRequestClose, close]);

    // Show the modal with its ref accessible
    const initModal = useCallback(
      once((spec: AnyModal) => {
        modalRef.current = showModal({
          ...spec,
          onInit: (c) => (updater.current = c),
          __UNSAFE_persist: true
        } as AnyModal);
      }),
      []
    );
    const modalRef = useRef<ModalHandler>();
    const updater = useRef<(spec: AnyModal) => void>(initModal);

    // Update the content of the current modal when anything changes
    useEffect(() => {
      const nextProps = {
        ...props,
        onRequestClose,
        visible
      } as AnyModal;
      if (!modalRef.current && !visible) return;
      logger.info("update props", nextProps, visible);
      updater.current?.(nextProps);
    }, [
      ...Object.keys(props)
        .sort()
        .map((k) => props[k as keyof typeof props]),
      onRequestClose,
      visible,
      updater
    ]);

    useEffect(() => modalRef.current?.dispose, []);

    useEffect(() => {
      logger.info("update visible from props", visible, "->", props.visible);
      setVisible(!!props.visible);
    }, [props.visible]);

    return { show, close };
  };

  type QueueObj = AnyModal & {
    close: ModalCloseFn;
    dispose: () => void;
    reInit: () => void;
    queueIndex: number;
    alertTimeout?: ReturnType<typeof setTimeout>;
  };

  function ModalProvider() {
    const logger = useMemo(() => baseLogger.createLogger("queue"), []);
    const i = useRef(0);
    const [dismissed, setDismissed] = useState(true);
    const [modalQueue, setModalQueue] = useState(
      Immutable.OrderedMap<number, QueueObj>()
    );
    const allModalQueue = useRef(new Map<number, QueueObj>());
    const topModalInQueue = useMemo(
      () => modalQueue.last() ?? null,
      [modalQueue]
    );
    const [currentModalIndex, setCurrentModalIndex] = useState<number | null>(
      null
    );
    const currentModal = useMemo(() => {
      const modal = allModalQueue.current.get(currentModalIndex ?? -1);
      if (!modal) return null;
      const activeModal = modalQueue.get(modal.queueIndex);
      return {
        ...modal,
        visible: (activeModal?.visible ?? !!activeModal) !== false
      };
    }, [modalQueue, currentModalIndex]);
    const { currentIsVisible, expectedModal } = logger.inspect(
      getStatus(currentModal, topModalInQueue),
      "[current state]"
    );
    const close = currentModal?.close ?? noop;
    const visible = !dismissed && currentIsVisible !== false;
    logger.debug("modalQueue", [...modalQueue]);

    // Respond to showing a new Modal
    eventStore.useEventListener("show", ({ onInit, ...props }) => {
      const currentIndex = i.current++;

      const dispose = once(() => {
        logger.info("dispose");
        onInit?.(noop);
        allModalQueue.current.delete(currentIndex);
        setModalQueue((queue) => queue.delete(currentIndex));
      });

      const reInit = once(() => {
        if (!props.__UNSAFE_persist) return;
        const spec = {
          onInit,
          ...(omit(allModalQueue.current.get(currentIndex) as QueueObj, [
            "close",
            "dispose",
            "reInit",
            "queueIndex"
          ]) as AnyModal)
        };
        logger.info("reInit", spec);
        dispose();
        showModal(spec);
      });

      const close = () => {
        if (props.__UNSAFE_persist) {
          setModalQueue((queue) => {
            if (!queue.get(currentIndex)) return queue;
            logger.info("remove", currentIndex, props);
            return queue.delete(currentIndex);
          });
        } else {
          dispose();
        }
      };

      const upsert = logger.wrap(function upsert(spec: Partial<AnyModal>) {
        const prevSpec = allModalQueue.current.get(currentIndex);
        const nextSpec = {
          alertTimeout: spec.alertIfNotShown
            ? setTimeout(() => {
                new Error(
                  `Modal not shown: ${spec.testID}\`, current modal is: \`${currentModal?.testID}\``
                );
              }, 250)
            : undefined,
          ...prevSpec,
          ...spec,
          queueIndex: currentIndex,
          close,
          dispose,
          reInit
        } as QueueObj;
        if (!isEqual(prevSpec, nextSpec)) {
          logger.info("update", currentIndex, nextSpec);
          allModalQueue.current.set(currentIndex, nextSpec);
        }
        if (nextSpec.visible === false) return close();
        setModalQueue((queue) => {
          if (isEqual(queue.get(currentIndex), nextSpec) || !nextSpec) {
            return queue;
          }
          logger.info("update active queue", currentIndex, nextSpec);
          return queue.set(currentIndex, nextSpec);
        });
      });

      logger.info("init", currentIndex, props);
      upsert(props);

      onInit?.((spec: Partial<AnyModal>) => {
        upsert(spec);
      });

      return dispose;
    });

    // Respond to Dismiss all
    eventStore.useEventListener("dismissAll", () => {
      modalQueue.forEach((modal) => modal.close());
    });

    const syncCurrentModalTimeout = useRef<ReturnType<typeof setTimeout>>();

    const clearSyncCurrentModalTimeout = useCallback(
      logger.wrap(function clearSyncCurrentModalTimeout() {
        if (!syncCurrentModalTimeout.current) return;
        clearTimeout(syncCurrentModalTimeout.current);
      }),
      []
    );

    const syncCurrentModal = useCallback(
      logger.wrap(function syncCurrentModal(
        currentModal: QueueObj | null,
        desiredExpectedModal: QueueObj | null
      ) {
        clearSyncCurrentModalTimeout();
        const {
          hasCurrentModal,
          matchesExpectedQueueIndex,
          expectedIsVisible,
          expectedModal
        } = logger.inspect(
          getStatus(currentModal, desiredExpectedModal),
          "syncCurrentModal status"
        );

        const canShow =
          matchesExpectedQueueIndex && expectedIsVisible !== false;
        const nextDismissed = !canShow;
        if (dismissed !== nextDismissed) setDismissed(nextDismissed);
        if (!matchesExpectedQueueIndex) {
          setCurrentModalIndex(expectedModal?.queueIndex ?? null);
          if (hasCurrentModal)
            logger.debug("🫡", "dismiss current modal", currentModal);
          if (hasCurrentModal) currentModal?.onDismiss?.();
          if (expectedModal)
            logger.debug("🥏", "set new current modal", expectedModal);
        }
      }),
      [clearSyncCurrentModalTimeout]
    );

    const handleGracefulDismiss = useCallback(
      logger.wrap(function handleGracefulDismiss(
        currentModal: QueueObj | null,
        expectedModal: QueueObj | null
      ) {
        clearSyncCurrentModalTimeout();
        logger.wrap(setDismissed)(true);
        if (Platform.OS !== "android") return;
        syncCurrentModalTimeout.current = setTimeout(
          syncCurrentModal,
          250,
          currentModal,
          expectedModal
        );
      }),
      [syncCurrentModal, clearSyncCurrentModalTimeout]
    );

    const handleOnDismiss = useCallback(
      logger.wrap(function handleOnDismiss() {
        syncCurrentModal(currentModal, expectedModal);
      }),
      [currentModal, expectedModal]
    );

    const onClose =
      currentModal && "onClose" in currentModal ? currentModal.onClose : null;
    const onRequestClose = currentModal?.onRequestClose;
    const dismissable = currentModal?.dismissable;

    const handleClose = useCallback(
      logger.wrap(function handleClose() {
        close();
        onClose?.();
      }),
      [close, onClose]
    );

    const handleRequestClose = useCallback(
      logger.wrap(function handleRequestClose() {
        if (dismissable === false) return;
        onRequestClose?.();
        handleClose();
      }),
      [onRequestClose, handleClose, dismissable]
    );

    useEffect(() => {
      if (currentModal?.alertTimeout) clearTimeout(currentModal.alertTimeout);
      const { hasCurrentModal, matchesExpectedQueueIndex } = getStatus(
        currentModal,
        expectedModal
      );

      if (hasCurrentModal && !matchesExpectedQueueIndex) {
        /* if the current modal is the expected modal, then set dismissed */
        logger.wrap(handleGracefulDismiss, "handleGracefulDismiss from effect")(
          currentModal,
          expectedModal
        );
      } else {
        syncCurrentModal(currentModal, expectedModal);
      }
    }, [currentModal, expectedModal, handleGracefulDismiss, syncCurrentModal]);

    useEffect(() => {
      logger.debug("mount");
      return () => {
        logger.debug("unmount", [...allModalQueue.current.entries()]);
        allModalQueue.current.forEach((modal) => modal.reInit());
        clearSyncCurrentModalTimeout();
      };
    }, []);

    logger.useDidChange("currentModalIndex", currentModalIndex);

    logger.useDidChange(
      "active modal",
      useMemo(
        () => ({
          currentModal,
          currentIsVisible,
          dismissed,
          visible
        }),
        [currentModal, currentIsVisible, dismissed, visible]
      )
    );

    const contextValue = useMemo(
      () =>
        logger.inspect(
          {
            currentModalIndex: currentModalIndex ?? -1,
            modalQueueLength: modalQueue.size
          },
          "contextValue"
        ),
      [currentModalIndex, modalQueue.size]
    );

    if (currentModal && "raw" in currentModal) {
      const { raw, children, onRequestClose, dismissable, ...rawModalProps } =
        currentModal;
      return (
        <ModalDebugContextProvider
          value={{
            currentModalIndex: currentModal.queueIndex,
            modalQueueLength: modalQueue.size
          }}
        >
          <Modal
            {...rawModalProps}
            visible={visible}
            onDismiss={handleOnDismiss}
            onRequestClose={handleRequestClose}
          >
            {dethunk(children, close)}
          </Modal>
        </ModalDebugContextProvider>
      );
    }

    const {
      variant,
      title,
      subTitle,
      subTitleItalic,
      headerColor,
      headerIcon,
      testID,
      children,
      eventContext,
      actions,
      horizontalActions,
      style,
      fixedHeight = false,
      useModalBody = true,
      useModalFooter = false,
      scrollable
    } = currentModal ?? {};

    return (
      <ModalDebugContextProvider value={contextValue}>
        <ModalContainer
          testID={testID && `${testID}-modal`}
          eventContext={eventContext ?? "empty-modal"}
          visible={visible}
          onRequestClose={handleRequestClose}
          onDismiss={handleOnDismiss}
          style={style}
          fixedHeight={fixedHeight}
          variant={variant}
          dismissable={dismissable}
        >
          {currentModal && (
            <>
              {typeof title === "string" && (
                <ModalHeader
                  title={title}
                  subTitle={subTitle}
                  subTitleItalic={subTitleItalic}
                  titleColor={headerColor}
                  icon={headerIcon}
                />
              )}
              {useModalBody ? (
                <ModalBody scrollable={scrollable}>
                  {dethunk(children, close)}
                </ModalBody>
              ) : (
                dethunk(children, close)
              )}
              {useModalFooter && <ModalFooter />}
              {!!actions?.length && (
                <ModalFooter>
                  <Stack
                    horizontal={horizontalActions}
                    justifyContent="center"
                    size="compact"
                  >
                    {actions.map(
                      ({ onPress, autoClose = true, color, ...props }, i) => (
                        <Button
                          key={i}
                          {...props}
                          onPress={() => {
                            onPress?.(handleClose);
                            if (autoClose) handleClose();
                          }}
                          color={
                            color ??
                            (i === actions.length - 1 ? "primary" : "clear")
                          }
                        />
                      )
                    )}
                  </Stack>
                </ModalFooter>
              )}
            </>
          )}
        </ModalContainer>
      </ModalDebugContextProvider>
    );
  }

  function getStatus(
    currentModal: QueueObj | null,
    expectedModal: QueueObj | null
  ) {
    const hasCurrentModal = !!currentModal;
    const currentIsVisible =
      (currentModal?.visible ?? !!currentModal) !== false;
    if (currentModal?.exclusive && currentIsVisible) {
      expectedModal = currentModal;
    }
    const expectedIsVisible =
      (expectedModal?.visible ?? !!expectedModal) !== false;
    const matchesExpectedQueueIndex =
      currentModal?.queueIndex === expectedModal?.queueIndex;
    return {
      currentModal,
      currentModalExclusive: !!currentModal?.exclusive,
      expectedModal,
      hasCurrentModal,
      matchesExpectedQueueIndex,
      expectedIsVisible,
      currentIsVisible
    };
  }

  const baseLogger = createLogger("📑", "ModalProvider");

  return { useModal, showModal, ModalProvider };
};
export const { useModal, showModal, ModalProvider } = createModalProvider();
