import React, { useRef, useState } from "react";
import { Image, StyleSheet, View, useWindowDimensions } from "react-native";
import Carousel, {
  type ICarouselInstance
} from "react-native-reanimated-carousel";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ModalBody, ModalHeader } from "../atoms";
import IconCircleButton from "../atoms/IconCircleButton";
import { useStyles } from "../style";
import { showModal } from "./ModalProvider";

export function showImageCarousel(images: string[]) {
  return showModal({
    variant: (size) => (size === "large" ? "none" : "full"),
    eventContext: "Image Carousel",
    useModalBody: false,
    children: (closeFn) => <ImageCarousel onClose={closeFn} images={images} />
  });
}

interface Props {
  images: string[];
  onClose: () => void;
}

function ImageCarousel({ images }: Props) {
  const ref = useRef<ICarouselInstance>(null);
  const dim = useWindowDimensions();
  const { top, bottom } = useSafeAreaInsets();
  const [rawWidth, setWidth] = useState(() => Math.min(dim.width, 400));

  const width = rawWidth - 32; // exclude padding
  const height = dim.height - 72 - top - bottom - 32; // exclude padding + inset + header

  const hasMultipleImages = images.length > 1;
  const styles = useStyles(({ getUnits }) => ({
    prevBtn: {
      position: "absolute",
      left: getUnits(4),
      top: "50%",
      zIndex: 2
    },
    nextBtn: {
      position: "absolute",
      right: getUnits(4),
      top: "50%",
      zIndex: 2
    }
  }));
  return (
    <>
      <ModalHeader />
      <ModalBody
        constraint="none"
        onLayout={(e) => setWidth((w) => e.nativeEvent?.layout.width ?? w)}
      >
        <Carousel
          ref={ref}
          width={width}
          style={{ width, height }}
          data={images}
          loop={hasMultipleImages}
          scrollAnimationDuration={1000}
          renderItem={({ item }) => (
            <Image
              resizeMode="contain"
              source={{ uri: item }}
              style={StyleSheet.absoluteFill}
            />
          )}
        />
        {hasMultipleImages && (
          <View style={styles.prevBtn}>
            <IconCircleButton
              iconVariant="solid"
              onPress={() => ref?.current?.prev()}
              testID="prev-image-btn"
              icon="chevron-left"
              size="small"
              color="primary"
            />
          </View>
        )}
        {hasMultipleImages && (
          <View style={styles.nextBtn}>
            <IconCircleButton
              iconVariant="solid"
              onPress={() => ref?.current?.next()}
              testID="next-image-btn"
              icon="chevron-right"
              size="small"
              color="primary"
            />
          </View>
        )}
      </ModalBody>
    </>
  );
}
