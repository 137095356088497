import { Form, FormField, FormSubmit, Validator } from "@gigsmart/fomu";
import type { PayloadError } from "@gigsmart/relay";
import type { ExecutionResult } from "graphql";
import React, { useCallback, useState } from "react";
import { useRequestContext } from "./request-context";

interface Props {
  onComplete: () => void;
}

const query = `
  mutation AuthenticateUser($input: AuthenticateUserInput!) {
    authenticateUser(input: $input) {
      token
    }
  }
`;

export default function SetToken({ onComplete }: Props) {
  const { setToken, fetcher } = useRequestContext();
  const [errors, setErrors] = useState<PayloadError[] | null>(null);
  const requiresOtp = errors?.some(({ code }) => code === "OTP_INVALID");
  const handleSubmit = useCallback(
    async ({ values }: any) => {
      let result = await fetcher({
        query,
        operationName: "AuthenticateUser",
        variables: { input: values }
      });
      for await (const r of result as AsyncIterable<ExecutionResult>) {
        result = r;
      }
      if ("data" in result) {
        const { data, errors } = result as any;
        setErrors(null);
        if (data?.authenticateUser?.token) {
          setToken(data?.authenticateUser?.token);
          onComplete();
        } else if (errors) {
          setErrors(errors);
        }
      }
    },
    [onComplete, setToken, fetcher]
  );
  return (
    <form>
      <Form initialValues={{ userType: "WORKER" }} onSubmit={handleSubmit}>
        {errors?.map(({ message, code }, i) => (
          <div style={{ color: "red" }} key={i}>
            {code ?? "INVALID"}: {message}
          </div>
        ))}
        <FormField name="userType" validates={Validator.presence()}>
          {({ value, setValue, name }) => (
            <div>
              <label htmlFor={name}>User Type</label>
              <br />
              <select value={value} onChange={(e) => setValue(e.target.value)}>
                <option value="WORKER">Worker</option>
                <option value="REQUESTER">Requester</option>
                <option value="ADMIN">Admin</option>
              </select>
            </div>
          )}
        </FormField>
        <FormField name="identifier" validates={Validator.presence()}>
          {({ value, setValue, name }) => (
            <div>
              <label htmlFor={name}>Email Address</label>
              <br />
              <input
                type="text"
                id={name}
                name={name}
                style={{ width: 300 }}
                onChange={(e) => setValue(e.target.value)}
                value={value ?? ""}
              />
            </div>
          )}
        </FormField>
        <FormField name="credential" validates={Validator.presence()}>
          {({ value, setValue, name }) => (
            <div>
              <label htmlFor={name}>Password</label>
              <br />
              <input
                type="password"
                id={name}
                name={name}
                style={{ width: 300 }}
                onChange={(e) => setValue(e.target.value)}
                value={value ?? ""}
              />
            </div>
          )}
        </FormField>
        {requiresOtp && (
          <FormField name="oneTimePassword" validates={Validator.presence()}>
            {({ value, setValue, name }) => (
              <div>
                <label htmlFor={name}>Two Factor Token</label>
                <br />
                <input
                  type="text"
                  id={name}
                  name={name}
                  style={{ width: 300 }}
                  onChange={(e) => setValue(e.target.value)}
                  value={value ?? ""}
                />
              </div>
            )}
          </FormField>
        )}
        <br />
        <FormSubmit>
          {({ submit, invalid }) => (
            <input
              type="submit"
              disabled={invalid}
              title="save"
              onClick={submit}
            />
          )}
        </FormSubmit>
      </Form>
    </form>
  );
}
