import React from "react";
import Svg, { Path } from "react-native-svg";
import { useTheme } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  size?: number;
  color?: Color;
}

const OnfleetIcon = ({ size = 24, color = "purple" }: Props) => {
  const theme = useTheme();
  const fill = theme.getColor(color, "fill");

  return (
    <Svg
      data-name="Layer 1"
      width={size}
      height={size}
      viewBox="0 0 128 60"
      fill={fill}
    >
      <Path
        d="M73.04 37.704c1.8 1.7 4.5 4 8 6.8 3.5 2.7 8.1 5.8 13.6 8.2 2.8 1.1 5.8 2 9 2.1 1.6 0 3.2 0 4.7-.5.8-.1 1.5-.5 2.2-.8.7-.4 1.4-.7 2.1-1.2 1.3-.9 2.6-2 3.6-3.4s1.9-2.9 2.7-4.6c1.4-3.4 2.3-7.2 2.5-11.2s-.3-8.1-1.6-12c-1.3-3.8-3.4-7.3-6.4-9.5-2.9-2.1-6.6-2.9-10.6-2.3-2 .4-4.1.9-6.1 1.8-1 .4-2 .9-3.1 1.4-1 .6-2 1.1-3 1.7-4 2.4-7.9 5.4-11.6 8.6-1.8 1.7-3.7 3.3-5.4 5.1-.9.9-1.8 1.7-2.6 2.7l-2.7 2.8c-3.5 3.7-7.5 7.6-11.5 11-4.1 3.5-8.4 6.7-13.2 9.5-4.7 2.7-10 5-15.9 5.8-3 .4-6.1.3-9.1-.4s-5.9-2.1-8.3-4-4.3-4.2-5.7-6.6c-1.4-2.4-2.5-4.9-3.2-7.5-.7-2.5-1.1-5.1-1.3-7.6s-.2-5 .1-7.4c.5-4.8 1.8-9.4 4-13.6 1.1-2.1 2.4-4 4-5.7 1.5-1.7 3.3-3.2 5.3-4.3s4.1-1.9 6.2-2.2c2.1-.4 4.2-.3 6.2-.1 3.9.5 7.3 1.9 10.2 3.4 5.8 3.2 10 6.9 13.3 10.1 3.2 3.2 5.5 6 7.1 7.9l2.3 2.9-2.7-2.5c-1.8-1.7-4.5-4-8-6.7s-8.1-5.8-13.6-8.2c-2.8-1.1-5.8-2-9-2.1-3.1-.1-6.3.6-8.9 2.5-1.3.9-2.6 2-3.6 3.4s-2 2.9-2.7 4.6c-1.5 3.4-2.4 7.3-2.5 11.3-.2 4 .3 8.1 1.6 12 1.3 3.8 3.4 7.3 6.4 9.4 2.9 2.1 6.6 2.9 10.6 2.2.5 0 1-.2 1.5-.3s1-.2 1.5-.4l1.5-.5 1.5-.6c.5-.2 1-.4 1.5-.7l1.5-.7c1-.6 2-1.1 3-1.7 4-2.4 7.9-5.4 11.6-8.6 3.7-3.3 7.1-6.6 10.8-10.5l2.7-2.8c.9-1 1.9-1.9 2.8-2.8 1.9-1.9 3.9-3.7 5.9-5.4 4.1-3.5 8.4-6.7 13.2-9.5 4.7-2.7 10-5 15.9-5.8 3-.4 6.1-.2 9.1.5s5.9 2.2 8.2 4.1c2.4 1.9 4.2 4.2 5.7 6.6 1.4 2.4 2.5 4.9 3.2 7.5.7 2.5 1.1 5.1 1.3 7.6.2 2.5.2 4.9-.1 7.4-.5 4.8-1.8 9.5-3.9 13.6-1.1 2.1-2.4 4-4 5.7-1.5 1.7-3.3 3.2-5.4 4.3-1 .6-2 1-3.1 1.4-1.1.3-2.1.7-3.2.8-2.1.4-4.2.3-6.2.1-3.9-.6-7.3-2-10.2-3.5-5.8-3.2-10-7-13.3-10.2-3.2-3.2-5.5-6-7.1-7.9l-2.3-2.9c.3-.1 3 2.4 3 2.4z"
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default OnfleetIcon;
