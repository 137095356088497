import { type DependencyList, useMemo } from "react";
import type { StyleProp } from "react-native";
import {
  type BreakpointStylesFn,
  type BreakpointedStyleSheets,
  type Theme,
  breakpointStyleSheets,
  createStyles,
  hasBreakpoints
} from "./style-helpers";
import * as theme from "./theme";
import type { StylesType } from "./type-helpers";
import { useViewport } from "./viewport";

interface UseStylesReturnType<T extends string> {
  styles: Record<T, StyleProp<StylesType>>;
  theme: Theme;
}

export default function useStyles<T extends string>(
  callback: BreakpointStylesFn<T>,
  deps?: DependencyList
): UseStylesReturnType<T> {
  const { breakpoint } = useViewport();
  const styles = useMemo(() => {
    if (hasBreakpoints(callback)) {
      const stylesheets: BreakpointedStyleSheets<T> =
        breakpointStyleSheets<T>(callback);
      return stylesheets[breakpoint];
    }
    return createStyles<T>(callback as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoint, ...(deps ?? [])]);
  return { styles, theme };
}
