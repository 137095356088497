/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { EventContext } from "@gigsmart/dekigoto";
import React, { type ReactNode } from "react";
import {
  Platform,
  Modal as RNModal,
  type ModalProps as RNModalProps,
  StyleSheet,
  View,
  type ViewProps
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ModalBackdrop from "../atoms/ModalBackdrop";
import Column from "../quarks/Column";
import {
  ColorPlacementProvider,
  useStyles,
  useTheme,
  useViewport
} from "../style";
import { useKeyboardState } from "../utils/keyboard";
import { Context } from "./ModalContext";
import type { ModalSize, ModalVariant } from "./ModalContext";
import { ToastProvider } from "./Toast";

interface Props extends Pick<RNModalProps, "animationType"> {
  testID?: string;
  variant?: ModalVariant | ((size: ModalSize) => ModalVariant);
  eventContext: string | null;
  visible: boolean;
  dismissable?: boolean;
  onRequestClose?: () => void;
  children?: ReactNode;
  fixedHeight?: boolean | number;
  style?: ViewProps["style"];
  onDismiss?: () => void;
}

export default function ModalContainer({
  testID = "modal-container",
  variant: getVariant = "none",
  eventContext,
  visible,
  onRequestClose,
  onDismiss,
  dismissable = true,
  fixedHeight = false,
  animationType = "fade",
  children,
  style
}: Props) {
  const { media, getUnits } = useTheme();
  const { width } = useViewport();
  const bottomInset = useSafeAreaInsets().bottom;

  const footerHeight = 56; // TODO: get this from the footer
  const { bottom } = useKeyboardState({
    enabled: visible,
    extraSpace: bottomInset + footerHeight
  });

  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const lg = media.size.small.maxWidth!;
  const md = 440;

  const size = width > lg ? "large" : width > md ? "medium" : "small";
  const variant =
    typeof getVariant === "string" ? getVariant : getVariant(size);
  const maxWidth =
    variant === "prompt" ? 690 : size === "small" ? md : md + getUnits(10);
  const isFull = variant === "full" || variant === "full-shadow";

  // override weird cases
  if (isFull) fixedHeight = false;

  const styles = useStyles(
    (theme) => ({
      contentWrapper: { flex: 1 },
      wrapper: {
        ...StyleSheet.absoluteFillObject,
        padding: theme.getUnits(5),
        alignItems: "center",
        justifyContent: "center"
      },
      toast: Platform.select({
        default: {},
        ios: { paddingTop: theme.measurements.statusBarHeight }
      }),
      content: {
        width: "100%",
        borderRadius: isFull ? 0 : theme.getUnits(1),
        backgroundColor: theme.getColor("surface", "fill"),
        maxWidth,
        ...(fixedHeight && {
          height: typeof fixedHeight === "number" ? fixedHeight : 590
        }),
        ...(isFull && {
          flex: 1,
          height: "100%",
          maxWidth: undefined
        }),
        ...(getVariant === "full-border" && {
          flex: 1,
          height: Platform.select({
            ios: "90%",
            default: "100%"
          }),
          maxWidth: undefined,
          marginVertical: getUnits(4),
          width: Platform.select({
            default: "100%",
            web: "calc(100% - 16px)"
          }) as any
        })
      }
    }),
    [fixedHeight, isFull, maxWidth, size, getVariant]
  );

  const content = <Column style={[styles.content, style]}>{children}</Column>;

  return (
    <RNModal
      visible={visible}
      onRequestClose={onRequestClose}
      onDismiss={onDismiss}
      animationType={animationType}
      transparent
    >
      <ToastProvider disabled={!visible} />
      <Context.Provider
        value={{
          size,
          onRequestClose,
          dismissable,
          fixedHeight,
          variant
        }}
      >
        <EventContext name={eventContext}>
          <ModalBackdrop />
          <ColorPlacementProvider color="surface">
            <View testID={testID} style={styles.contentWrapper}>
              {isFull ? (
                content
              ) : (
                <View
                  style={[styles.wrapper, { bottom }]}
                  pointerEvents="box-none"
                >
                  {content}
                </View>
              )}
            </View>
          </ColorPlacementProvider>
        </EventContext>
      </Context.Provider>
    </RNModal>
  );
}
