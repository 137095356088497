import { useCallback, useContext } from "react";
import Context from "./context";
import { track } from "./hooks";
import type { JsonMap } from "./hooks";

export function useEventer(
  action: string,
  targetName: string | null,
  entityType: string,
  contextNameOverride?: string
): (properties?: JsonMap) => () => void {
  const { name: contextName, disabled } = useContext(Context);
  return useCallback(
    (properties?: JsonMap) => {
      if (targetName === null || disabled) return () => {};
      return track(
        {
          contextName: contextNameOverride ?? contextName,
          action,
          targetName: targetName ?? "Unknown",
          entityType
        },
        properties,
        action
      );
    },
    [action, contextName, contextNameOverride, disabled, entityType, targetName]
  );
}
