import { Platform } from "react-native";
import { definePermission } from "../registry";

export default definePermission({
  name: "Push Notifications",
  icon: "bell",
  restrictRequired: Platform.OS === "ios",
  defaults: {
    preview: true,
    reason:
      "Enable notifications to receive updates on opportunities in your area and communicate with businesses. This helps ensure you never miss an opportunity."
  }
});
