import { definePermission } from "../registry";

export default definePermission({
  name: "Precise Location",
  icon: "map-location",
  defaults: {
    preview: true,
    reason:
      "We need your precise location to provide you with the best experience"
  }
});
