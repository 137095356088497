import React from "react";
import { StatusBar as RNStatusBar } from "react-native";
import { usePlacementColor } from "../style";

export default function StatusBar() {
  const {
    spec: { fill } = {}
  } = usePlacementColor();
  return <RNStatusBar backgroundColor={fill} />;
}
