import React from "react";
import { FlatList, Image, Platform, TouchableOpacity } from "react-native";
import { InputLabel, Stack } from "../atoms";
import PhotoPreview from "../molecules/PhotoPreview";
import Spacer from "../quarks/Spacer";
import { useStyles } from "../style";

import { showModal } from "./ModalProvider";

interface Props {
  photos: string[];
  onDelete?: (index: number) => void;
  label?: string;
}

export default function PhotoCarousel({
  photos,
  onDelete,
  label = "Photos"
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    photo: {
      height: 60,
      width: 60,
      borderRadius: getUnits(1)
    },
    flex: {
      flex: 1
    }
  }));
  const handlePhotoPress = (val: string, index: number) => {
    showModal({
      children: (close) => (
        <PhotoPreview
          photo={val}
          onClose={close}
          onDelete={onDelete ? () => onDelete(index) : undefined}
        />
      ),
      raw: true,
      animationType: "slide"
    });
  };
  return (
    <Stack size="slim">
      <InputLabel inset={false} label={label} />
      <FlatList
        data={photos}
        horizontal
        keyExtractor={(photo) => photo}
        renderItem={({ item, index }) => {
          const photo =
            Platform.OS === "android"
              ? item?.includes("file://")
                ? item
                : `file://${item}`
              : item?.replace("file://", "");
          return (
            <TouchableOpacity
              onPress={() => handlePhotoPress(item, index)}
              style={styles.photo}
            >
              <Image source={{ uri: photo }} style={styles.photo} />
            </TouchableOpacity>
          );
        }}
        ItemSeparatorComponent={() => <Spacer horizontal size="compact" />}
        scrollEnabled
        showsHorizontalScrollIndicator={false}
        style={styles.flex}
      />
    </Stack>
  );
}
