import React, { type ReactNode } from "react";
import type { BreakpointType } from "./style-helpers";
import { useViewport } from "./viewport";

interface Props {
  renderableBreakpoints: BreakpointType[];
  children: ReactNode;
}

export default function ViewportRenderer({
  renderableBreakpoints,
  children
}: Props) {
  const { breakpoint } = useViewport();
  return renderableBreakpoints.includes(breakpoint) ? <>{children}</> : null;
}
