import React, { useState, type ReactElement } from "react";

import { collectErrors, mapToObject } from "./helpers";
import { useInit, useResponder } from "./scene";
import type { ValueObject } from "./types";

export interface FormValuesArgs {
  values: ValueObject | undefined;
  valid: boolean;
  errors: Error[] | null | undefined;
}

export function useFormValues(): FormValuesArgs {
  const [values, setInternalValues] = useState<ValueObject>();
  const [internalErrors, setInternalErrors] = useState<
    Error[] | null | undefined
  >(null);

  useInit(({ values, errors }) => {
    setInternalValues(mapToObject(values));
    setInternalErrors(collectErrors(errors));
  }, []);

  useResponder(
    "values",
    ({ values: allValues }) => {
      setInternalValues(mapToObject(allValues));
    },
    []
  );

  useResponder(
    "errors",
    ({ errors: allErrors }) => {
      setInternalErrors(collectErrors(allErrors));
    },
    []
  );

  const valid =
    internalErrors === undefined ||
    internalErrors === null ||
    internalErrors.length === 0;

  return {
    values,
    valid,
    errors: internalErrors
  };
}

interface Props {
  children: (arg0: FormValuesArgs) => ReactElement | null;
}

export const FormValues = React.memo<Props>(function FormValues({
  children
}: Props) {
  const props = useFormValues();
  return children(props);
});
