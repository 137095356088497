import React from "react";
import { Image } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Column from "../quarks/Column";
import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useMatchesViewport, useStyles } from "../style";

interface MenuItem {
  label: string;
  icon?: IconName;
  uri?: string;
  isFocused?: boolean;
  highlighted?: boolean;
  onPress?: () => void;
}

interface Props {
  menuItems: MenuItem[];
}

/** @deprecated */
export default function BottomNav({ menuItems }: Props) {
  const bottomInset = useSafeAreaInsets().bottom;
  const styles = useStyles(({ colors }) => ({
    container: {
      backgroundColor: colors.surface.fill,
      zIndex: 2,
      paddingBottom: bottomInset / 2
    }
  }));

  const mobileOnly = useMatchesViewport(({ size }) => size.small.down);
  if (!mobileOnly) return null;

  return (
    <Row justifyContent="space-around" style={styles.container}>
      {menuItems.map((item, index) => (
        <BottomNavItem key={`bottom-nav-route-${index}`} {...item} />
      ))}
    </Row>
  );
}

/** @deprecated */
function BottomNavItem({
  label,
  icon,
  uri,
  isFocused,
  highlighted,
  onPress
}: MenuItem) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    pressableContainer: { flex: 1 },
    container: {
      height: 52,
      position: "relative"
    },
    title: {
      marginTop: getUnits(1)
    },
    iconWrapper: {
      height: 24,
      position: "relative"
    },
    highlightedIconWrapper: {
      position: "absolute",
      bottom: 0
    },
    img: { width: 24, height: 24, borderRadius: 12 },
    focusedBottomNavItem: {
      backgroundColor: getColor("primary", "fill", { opacity: 0.08 })
    }
  }));
  const color = isFocused ? "primary" : "neutral";
  return (
    <Pressable
      testID={`bottom-nav-route-item-${label
        ?.toLocaleLowerCase()
        ?.replaceAll(" ", "-")}`}
      onPress={onPress}
      eventTargetName="Bottom Nav Route Button"
      eventEntityType="BottomNavRoute"
      style={({ pressed, hovered }) => [
        (pressed || hovered || isFocused) && styles.focusedBottomNavItem,
        styles.pressableContainer
      ]}
    >
      <Column
        alignItems="center"
        justifyContent="center"
        style={styles.container}
      >
        <Column alignItems="center" style={styles.iconWrapper}>
          {!highlighted && icon && (
            <Icon
              name={icon}
              size="large"
              variant={isFocused ? "regular" : undefined}
              color={color}
            />
          )}
          {!highlighted && uri && <Image source={{ uri }} style={styles.img} />}
          {highlighted && (
            <Column alignItems="center" style={styles.highlightedIconWrapper}>
              {icon && <HighlightedIcon name={icon} />}
            </Column>
          )}
        </Column>
        <Text
          color={color}
          selectable={false}
          variant="note"
          style={styles.title}
        >
          {label}
        </Text>
      </Column>
    </Pressable>
  );
}

const HighlightedIcon = ({ name }: { name: IconName }) => {
  const styles = useStyles(({ colors }) => ({
    wrapper: {
      backgroundColor: colors.primary.fill,
      width: 48,
      height: 48,
      borderRadius: 24
    }
  }));
  return (
    <Column alignItems="center" justifyContent="center" style={styles.wrapper}>
      <Icon name={name} color="surface" variant="regular" />
    </Column>
  );
};
