import { createPropsPortal } from "@gigsmart/aperture";
import React, { type ReactNode } from "react";
import { useMatchesViewport } from "../../style";
import type { OnlyChildren } from "../../utils/types";

export interface ActionSheetPortalProps {
  actionSheet?: () => ReactNode | null;
}

const { Entrance, Exit, Realm } = createPropsPortal<ActionSheetPortalProps>({
  actionSheet: undefined
});

export { Entrance as ActionSheetPortalEntrance };

type Props = OnlyChildren<ReactNode>;

export default function ActionSheetPortal({ children }: Props) {
  const isMobile = useMatchesViewport(({ platform }) => platform.mobile);
  if (isMobile) return <>{children}</>;
  return (
    <Realm>
      <Exit>
        {({ actionSheet }) => {
          return (
            <>
              {children}
              {actionSheet?.()}
            </>
          );
        }}
      </Exit>
    </Realm>
  );
}
