import type { ValuesType } from "@gigsmart/type-utils";
import { Dimensions, Platform } from "react-native";
import type { Thunk } from "../../utils/thunk";

export interface MediaOptions {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  minScale?: number;
  maxScale?: number;
  minResolution?: number;
  maxResolution?: number;
  minFontScale?: number;
  maxFontScale?: number;
  orientation?: "portrait" | "landscape";
  variant?: "browser" | "native";
  platform?: typeof Platform.OS | Array<typeof Platform.OS>;
}

class Media implements MediaOptions {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  minScale?: number;
  maxScale?: number;
  minResolution?: number;
  maxResolution?: number;
  minFontScale?: number;
  maxFontScale?: number;
  orientation?: "portrait" | "landscape";
  variant?: "browser" | "native";
  platform?: typeof Platform.OS | Array<typeof Platform.OS>;

  constructor(mediaOptions: MediaOptions) {
    Object.assign(this, mediaOptions);
  }

  get up(): MediaOptions {
    return Object.keys(this)
      .filter((key) => !key.startsWith("max"))
      .reduce(
        (acc, key) => ({ ...acc, [key]: this[key as keyof MediaOptions] }),
        {}
      );
  }

  get down(): MediaOptions {
    return Object.keys(this)
      .filter((key) => !key.startsWith("min"))
      .reduce(
        (acc, key) => ({ ...acc, [key]: this[key as keyof MediaOptions] }),
        {}
      );
  }
}

function buildMediaRecord<T extends NamedMedia<T>>(
  media: T
): Record<keyof T, Media> {
  return media;
}

const deviceWidth =
  Platform.OS === "web" ? 400 : Math.max(Dimensions.get("screen").width, 450);

const defaultBreakpoints = [360, deviceWidth, 768, 960, 1200, 1480, 1940];

export const breakpointNames = [
  "mini",
  "xsmall",
  "small",
  "medium",
  "large",
  "xlarge",
  "xxlarge"
] as const;

export const buildMediaSize = (breakpoints = defaultBreakpoints) => {
  let minWidth = 0;
  const obj = breakpointNames.reduce(
    (obj, size, index) => {
      const maxWidth = breakpoints[index] ?? defaultBreakpoints[index];
      if (!maxWidth) throw new Error("Invalid breakpoints");

      // size.down should be < maxBreakpoint and size.up should be >= minBreakpoint
      // size.down is not inclusive! (small.down ==>> < 768px - LESS THAn 768px - 768px is Tablet size)
      obj[size] = new Media({ minWidth, maxWidth });
      minWidth = maxWidth;
      return obj;
    },
    {} as unknown as Record<(typeof breakpointNames)[number], Media>
  );

  return buildMediaRecord(obj);
};

const defaultMedia = {
  size: buildMediaSize(),
  platform: buildMediaRecord({
    ios: new Media({ platform: "ios" }),
    android: new Media({ platform: "android" }),
    mobile: new Media({ platform: ["ios", "android"] }),
    web: new Media({ variant: "browser" }),
    desktop: new Media({ platform: ["macos", "windows"] })
  }),
  orientation: buildMediaRecord({
    portrait: new Media({ orientation: "portrait" }),
    landscape: new Media({ orientation: "landscape" })
  })
};

export type NamedMedia<T> = { [P in keyof T]: Media };

export const flattenMedia = (media: MediaOptions | MediaOptions[]) =>
  Array.isArray(media)
    ? media.reduce<MediaOptions>((acc, item) => ({ ...acc, ...item }), {})
    : media;

export type { Media };

export type BreakpointName = ValuesType<typeof breakpointNames>;
export type ThemeMedia = typeof defaultMedia;
export type ThemeMediaKey = keyof ThemeMedia;
export type ThemeableMediaThunk = Thunk<
  [ThemeMedia],
  MediaOptions | MediaOptions[]
>;
export default defaultMedia;
