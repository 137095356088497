/// <reference types="@react-native-firebase/crashlytics" />

export const log = (_message: string) => undefined;
export const setUserId = (_id: string) => undefined;
export const crash = () => undefined;
export const recordError = (_error: Error) => undefined;
export const setBoolValue = (_key: string, _value: boolean) => undefined;
export const setNumberValue = (_key: string, _value: number) => undefined;
export const setStringValue = (_key: string, _value: string) => undefined;
export const setUserName = (_username: string) => undefined;
export const enableCrashlyticsCollection = () => undefined;
export const setAttribute = (name: string, value: string) => undefined;
