import React, {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useState
} from "react";
import {
  Dimensions,
  type LayoutChangeEvent,
  Platform,
  type ScaledSize,
  StyleSheet,
  View
} from "react-native";
import { useThrottledCallback } from "../utils/hooks";
import { useThunk } from "../utils/thunk";
import { useTheme } from "./theme/ThemeProvider";
import {
  type MediaOptions,
  type ThemeableMediaThunk,
  flattenMedia
} from "./theme/media";

export type ViewportState = ReturnType<typeof Dimensions.get> & {
  platform: typeof Platform.OS;
  orientation: "portrait" | "landscape";
  variant: "browser" | "native";
  resolution: number;
};

const ViewportContext = createContext<ScaledSize>(Dimensions.get("window"));

function checkMinKey(
  key: string,
  value: number,
  viewport: ViewportState
): boolean {
  const viewportKey = key
    .split("min")[1]
    ?.toLowerCase?.() as keyof ViewportState;
  return +viewport[viewportKey] >= value;
}

function checkMaxKey(
  key: string,
  value: number,
  viewport: ViewportState
): boolean {
  const viewportKey = key.split("max")[1]?.toLowerCase() as keyof ViewportState;
  return +viewport[viewportKey] < value;
}

function checkExactKey<T extends unknown | unknown[]>(
  key: string,
  value: T,
  viewport: ViewportState
): boolean {
  if (Array.isArray(value)) {
    return value.some((item) => checkExactKey(key, item, viewport));
  }
  return viewport[key as keyof ViewportState] === value;
}

export function matchesViewport(
  media: MediaOptions | MediaOptions[],
  viewport: ViewportState
): boolean {
  const mediaOptions = flattenMedia(media);
  return Object.keys(mediaOptions).every((key: string) => {
    const value = mediaOptions[key as keyof MediaOptions];
    if (typeof value === "undefined") return true;
    if (key.startsWith("min") && typeof value === "number") {
      return checkMinKey(key, value, viewport);
    }
    if (key.startsWith("max") && typeof value === "number") {
      return checkMaxKey(key, value, viewport);
    }
    return checkExactKey(key, value, viewport);
  });
}

export function useMatchesViewport(media?: ThemeableMediaThunk): boolean {
  const mediaOptions = useThunk(media, [useTheme().media]);
  const viewport = useViewport();
  if (!mediaOptions) return false;
  return matchesViewport(mediaOptions, viewport);
}

const styles = StyleSheet.create({
  container: StyleSheet.absoluteFillObject
});

interface ViewportProps {
  testID?: string;
  children: ReactNode;
}
export function Viewport({ testID, children }: ViewportProps) {
  const [dimensions, setDimensions] = useState<ScaledSize>(() =>
    Dimensions.get("window")
  );
  const handleLayout = useThrottledCallback((e: LayoutChangeEvent) => {
    if (!e.nativeEvent) return; // sanity check (@see GOT-3217)
    const { width, height } = e.nativeEvent.layout;
    setDimensions((dim) => ({ ...dim, width, height }));
  });

  return (
    <View testID={testID} style={styles.container} onLayout={handleLayout}>
      <ViewportContext.Provider value={dimensions}>
        {children}
      </ViewportContext.Provider>
    </View>
  );
}

export function useViewport(): ViewportState {
  const context = useContext(ViewportContext);
  return useMemo(() => getViewport(context), [context]);
}

export function getViewport(windowDimensions: ScaledSize): ViewportState {
  const { height, width, scale } = windowDimensions;
  return {
    ...windowDimensions,
    resolution: 160 * scale,
    orientation: height >= width ? "portrait" : "landscape",
    platform: Platform.OS,
    variant: Platform.OS === "web" ? "browser" : "native"
  };
}
