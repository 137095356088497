import { isFunction } from "lodash";
import {
  type AuthMiddlewareOpts,
  type FetchOpts,
  type Middleware,
  type MiddlewareNextFn,
  RRNLError,
  RelayNetworkLayerRequest,
  RelayNetworkLayerRequestBatch,
  type RelayNetworkLayerResponse
} from "react-relay-network-modern/es";

type Headers = Record<string, string>;

export interface BatchMiddlewareOpts {
  batchUrl?:
    | string
    | Promise<string>
    | ((
        batchKey: string,
        requestList: RequestWrapper[]
      ) => string | Promise<string>);
  batchTimeout?: number;
  allowMutations?: boolean;
  method?: "POST" | "GET";
  headers?:
    | Headers
    | Promise<Headers>
    | ((req: RelayNetworkLayerRequestBatch) => Headers | Promise<Headers>);
  // Avaliable request modes in fetch options. For details see https://fetch.spec.whatwg.org/#requests
  credentials?: FetchOpts["credentials"];
  mode?: FetchOpts["mode"];
  cache?: FetchOpts["cache"];
  redirect?: FetchOpts["redirect"];
  auth?: AuthMiddlewareOpts;
}

export interface RequestWrapper {
  req: RelayNetworkLayerRequest;
  completeOk: (res: Object) => void;
  completeErr: (e: Error) => void;
  done: boolean;
  duplicates: RequestWrapper[];
}

interface Batcher {
  bodySize: number;
  requestList: RequestWrapper[];
  acceptRequests: boolean;
}

export class RRNLBatchMiddlewareError extends RRNLError {
  constructor(msg: string) {
    super(msg);
    this.name = "RRNLBatchMiddlewareError";
  }
}

export default function batchMiddleware(
  options?: BatchMiddlewareOpts
): Middleware {
  const opts = options ?? {};
  const batchTimeout = opts.batchTimeout ?? 0; // 0 is the same as nextTick in nodeJS
  const allowMutations = opts.allowMutations ?? false;
  const batchUrl = opts.batchUrl ?? "/graphql/batch";
  const batchers: BatchOpts["batchers"] = {};
  let tokenRefreshInProgress: Promise<string> | null = null;

  const fetchOpts: Partial<FetchOpts> = {};
  if (opts.method) fetchOpts.method = opts.method;
  if (opts.credentials) fetchOpts.credentials = opts.credentials;
  if (opts.mode) fetchOpts.mode = opts.mode;
  if (opts.cache) fetchOpts.cache = opts.cache;
  if (opts.redirect) fetchOpts.redirect = opts.redirect;
  if (opts.headers) fetchOpts.headersOrThunk = opts.headers;

  interface BatchOpts {
    batchTimeout: number;
    batchUrl:
      | string
      | Promise<string>
      | ((
          batchKey: string,
          requestList: RequestWrapper[]
        ) => string | Promise<string>);
    batchers: Record<string, Batcher>;
    fetchOpts: Partial<FetchOpts>;
    cacheMaxAge?: number;
    auth?: AuthMiddlewareOpts;
    batchKey: string;
  }

  async function passThroughBatch(
    req: RelayNetworkLayerRequest,
    next: MiddlewareNextFn,
    { ...opts }: BatchOpts
  ) {
    const { batchers = {}, batchKey: defaultBatchKey } = opts;
    const batchKey = req.cacheConfig.batchKey ?? defaultBatchKey;

    const bodyLength = (req.getBody() as string).length;
    if (!bodyLength) {
      return await next(req);
    }

    if (!batchers[batchKey]?.acceptRequests) {
      batchers[batchKey] = prepareNewBatcher(next, { ...opts, batchKey });
    }

    // +1 accounts for tailing comma after joining
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    batchers[batchKey]!.bodySize += bodyLength + 1;

    // queue request
    return await new Promise<RelayNetworkLayerResponse>((resolve, reject) => {
      const { requestList = [] } = batchers[batchKey] ?? {};

      const requestWrapper: RequestWrapper = {
        req,
        completeOk: (res) => {
          requestWrapper.done = true;
          resolve(res as RelayNetworkLayerResponse);
          requestWrapper.duplicates.forEach((r) => r.completeOk(res));
        },
        completeErr: (err) => {
          requestWrapper.done = true;
          reject(err);
          requestWrapper.duplicates.forEach((r) => r.completeErr(err));
        },
        done: false,
        duplicates: []
      };

      const duplicateIndex = requestList.findIndex(
        (wrapper) => req.getBody() === wrapper.req.getBody()
      );

      if (duplicateIndex !== -1) {
        /*
        I've run into a scenario with Relay Classic where if you have 2 components
        that make the exact same query, Relay will dedup the queries and reuse
        the request ids but still make 2 requests. The batch code then loses track
        of all the duplicate requests being made and never resolves or rejects
        the duplicate requests
        https://github.com/nodkz/react-relay-network-layer/pull/52
      */
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        requestList[duplicateIndex]?.duplicates.push(requestWrapper);
      } else {
        requestList.push(requestWrapper);
      }
    });
  }

  function prepareNewBatcher(next: MiddlewareNextFn, opts: BatchOpts): Batcher {
    const batcher: Batcher = {
      bodySize: 2, // account for '[]'
      requestList: [],
      acceptRequests: true
    };

    setTimeout(() => {
      batcher.acceptRequests = false;
      sendRequests(batcher.requestList, next, opts)
        .then(() => finalizeUncompleted(batcher.requestList))
        .catch((e) => {
          if (e && e.name === "AbortError") {
            finalizeCanceled(batcher.requestList, e);
          } else {
            finalizeUncompleted(batcher.requestList);
          }
        });
    }, opts.batchTimeout);

    return batcher;
  }

  async function sendRequests(
    requestList: RequestWrapper[],
    next: MiddlewareNextFn,
    opts: BatchOpts
  ) {
    if (requestList.length === 1) {
      // SEND AS SINGLE QUERY
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const wrapper = requestList[0]!;

      const res = await next(wrapper.req);
      wrapper.completeOk(res);
      wrapper.duplicates.forEach((r) => r.completeOk(res));
      return res;
    }
    if (requestList.length > 1) {
      // SEND AS BATCHED QUERY

      const batchRequest = new RelayNetworkLayerRequestBatch(
        requestList.map((wrapper) => wrapper.req)
      );
      const url = await (isFunction(opts.batchUrl)
        ? opts.batchUrl(opts.batchKey, requestList)
        : opts.batchUrl);
      batchRequest.setFetchOption("url", url);

      const { headersOrThunk, ...fetchOpts } = opts.fetchOpts;
      batchRequest.setFetchOptions(fetchOpts);

      let headers: FetchOpts["headers"] = {};

      if (headersOrThunk) {
        headers = await (isFunction(headersOrThunk)
          ? headersOrThunk(batchRequest)
          : headersOrThunk);
      }

      if (opts.auth) {
        const header = opts.auth?.header ?? "Authorization";
        const token =
          typeof opts.auth.token === "function"
            ? await opts.auth.token(batchRequest)
            : await opts.auth.token;
        if (
          !token &&
          opts.auth.tokenRefreshPromise &&
          !opts.auth.allowEmptyToken
        ) {
          throw new RRNLBatchMiddlewareError("Empty token");
        }

        if (token) {
          headers[header] = `${opts.auth?.prefix ?? "Bearer "}${token}`;
        }
      }

      batchRequest.setFetchOption("headers", headers);

      try {
        const batchResponse = await next(batchRequest);
        if (!batchResponse || !Array.isArray(batchResponse.json)) {
          throw new RRNLBatchMiddlewareError(
            "Wrong response from server. Did your server support batch request?"
          );
        }

        batchResponse.json.forEach((payload: any, index) => {
          if (!payload) return;
          const request = requestList[index];
          if (request) {
            const res = createSingleResponse(batchResponse, payload);
            request.completeOk(res);
          }
        });

        return batchResponse;
      } catch (e: any) {
        if (e && opts.auth && opts.auth.tokenRefreshPromise) {
          if (e.message === "Empty token" || (e.res && e.res.status === 401)) {
            if (typeof opts.auth.tokenRefreshPromise === "function") {
              if (!tokenRefreshInProgress) {
                tokenRefreshInProgress = Promise.resolve(
                  opts.auth.tokenRefreshPromise(batchRequest, e.res)
                )
                  .then((newToken) => {
                    tokenRefreshInProgress = null;
                    return newToken;
                  })
                  .catch((err) => {
                    tokenRefreshInProgress = null;
                    throw err;
                  });
              }

              return await tokenRefreshInProgress.then(async (newToken) => {
                if (!newToken && !opts.auth?.allowEmptyToken) {
                  throw new RRNLBatchMiddlewareError("Empty token");
                }

                const header = opts.auth?.header ?? "Authorization";
                const newReq = batchRequest.clone();
                if (newToken) {
                  newReq.fetchOpts.headers[header] = `${
                    opts.auth?.prefix ?? "Bearer "
                  }${newToken}`;
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                  delete newReq.fetchOpts.headers[header];
                }

                return await next(newReq); // re-run query with new token
              });
            }
          }
        }

        requestList.forEach((request) => request.completeErr(e as Error));
      }
    }

    await Promise.resolve();
  }

  // check that server returns responses for all requests
  function finalizeCanceled(requestList: RequestWrapper[], error: Error) {
    requestList.forEach((request) => request.completeErr(error));
  }

  // check that server returns responses for all requests
  function finalizeUncompleted(requestList: RequestWrapper[]) {
    requestList.forEach((request, index) => {
      if (!request.done) {
        request.completeErr(
          new RRNLBatchMiddlewareError(
            `Server does not return response for request at index ${index}.\n` +
              `Response should have an array with ${requestList.length} item(s).`
          )
        );
      }
    });
  }

  function createSingleResponse(
    batchResponse: RelayNetworkLayerResponse,
    json: any
  ): RelayNetworkLayerResponse {
    // Fallback for graphql-graphene and apollo-server batch responses
    const data = json.payload || json;
    const res = batchResponse.clone();
    res.processJsonData(data);
    return res;
  }

  return (next) => async (req) => {
    // do not batch mutations unless allowMutations = true
    if (req.isMutation() && !allowMutations) {
      return await next(req);
    }

    if (!(req instanceof RelayNetworkLayerRequest)) {
      throw new RRNLBatchMiddlewareError(
        "Relay batch middleware accepts only simple RelayRequest. Did you add batchMiddleware twice?"
      );
    }

    // req with FormData can not be batched
    if (req.isFormData()) {
      return await next(req);
    }

    // skip batching if request explicitly opts out
    if (!req.cacheConfig.batch) {
      return await next(req);
    }

    return await passThroughBatch(req, next, {
      batchTimeout,
      batchUrl,
      batchers,
      fetchOpts,
      auth: opts.auth,
      batchKey: "Main"
    });
  };
}
