import React from "react";
import { View } from "react-native";
import { Stack } from "../../atoms";
import { Column, Row, Text } from "../../quarks";
import { useStyles } from "../../style";
import useStepper from "./useStepper";

interface Props {
  layout: "horizontal" | "vertical";
}

export default function StepIndicator({ layout }: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      mainContainer: { marginRight: 57 },
      inactive: {
        backgroundColor: getColor(
          layout === "horizontal" ? "inactiveStep" : "neutral",
          "fill"
        )
      },
      active: {
        backgroundColor: getColor("primary", "fill")
      },
      tabletIndicator: {
        height: getUnits(2),
        flex: 1
      }
    }),
    [layout]
  );

  const { activeStepNum, steps } = useStepper<unknown, {}>();
  const stepLabels = steps.map((step) => step.stepIndicatorLabel ?? "");

  if (layout === "horizontal") {
    return (
      <Stack size="small" horizontal>
        {stepLabels.map((_, idx) => (
          <View
            key={`mobile-stepper-indicator-${idx}`}
            style={[
              styles.tabletIndicator,
              idx < activeStepNum ? styles.active : styles.inactive
            ]}
          />
        ))}
      </Stack>
    );
  }

  return (
    <Column style={styles.mainContainer}>
      {stepLabels.map((item, index) => (
        <StepIndicatorLabel
          key={index}
          label={item}
          num={index + 1}
          active={index < activeStepNum}
          first={index === 0}
        />
      ))}
    </Column>
  );
}

type StepIndicatorLabelProps = {
  num: number;
  label: string;
  active?: boolean;
  first?: boolean;
};
export function StepIndicatorLabel({
  num,
  first,
  label,
  active
}: StepIndicatorLabelProps) {
  const styles = useStyles(({ getUnits, getColor }) => ({
    circle: {
      width: getUnits(6),
      height: getUnits(6),
      borderRadius: getUnits(3),
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: getColor("neutral", "fill")
    },
    connector: {
      zIndex: -1,
      height: getUnits(10),
      width: getUnits(2),
      marginHorizontal: getUnits(2),
      marginVertical: -2,
      backgroundColor: getColor("neutral", "fill")
    },
    active: { backgroundColor: getColor("primary", "fill") }
  }));

  return (
    <>
      {!first && <View style={[styles.connector, active && styles.active]} />}
      <Row alignItems="center" gap="compact">
        <View style={[styles.circle, active && styles.active]}>
          <Text weight="bold" color="white">
            {num}
          </Text>
        </View>
        <Text weight="bold" color="primary">
          {label}
        </Text>
      </Row>
    </>
  );
}
