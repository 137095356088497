import React from "react";
import Button from "../atoms/Button";
import Stack from "../atoms/Stack";
import TooltipButton from "../atoms/TooltipButton";

interface Props {
  label: string;
  onButtonPress: () => void;
  onTooltipPress: () => void;
  testID?: string;
}

const ButtonWithTooltip = ({
  label,
  onButtonPress,
  onTooltipPress,
  testID
}: Props) => {
  return (
    <Stack
      horizontal
      alignItems="center"
      justifyContent="center"
      size="medium"
      testID={testID}
    >
      <Button
        outline
        size="small"
        onPress={onButtonPress}
        label={label}
        testID={`${testID}-button`}
      />
      <TooltipButton
        onPress={onTooltipPress}
        variant="solid"
        testID={`${testID}-tooltip`}
      />
    </Stack>
  );
};

export default ButtonWithTooltip;
