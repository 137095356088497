import React from "react";
import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";
import { ColorPlacementProvider, useStyles } from "../style";
import type { Color } from "../style/theme/colors";

export interface Props {
  disabled?: boolean;
  color?: Color;
  testID: string;
  label: string;
  icon?: IconName;
  iconPlacement?: "left" | "right";
  onPress?: () => void;
  onPressDisabled?: () => void;
}

export default function InlineButton({
  testID,
  disabled,
  color = "primary",
  label,
  icon,
  iconPlacement = "left",
  onPress,
  onPressDisabled
}: Props) {
  if (disabled) color = "disabled";

  const styles = useStyles(
    ({ getUnits, getFontSize }) => {
      return {
        container: {
          backgroundColor: "transparent",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          minHeight: getUnits(8),
          borderRadius: getUnits(1),
          borderColor: "transparent",
          borderWidth: 1
        },
        containerTransparent: {},
        containerHovered: { opacity: 0.95 },
        containerPressed: { opacity: 0.8 },
        label: {
          ...getFontSize(3.5),
          textAlign: "center",
          marginHorizontal: getUnits(2)
        }
      };
    },
    [color]
  );

  const iconNode = !!icon && (
    <Icon size={"small"} name={icon} variant="solid" />
  );

  return (
    <ColorPlacementProvider color={color} use={"fill"}>
      <Pressable
        testID={testID}
        eventEntityType="Button"
        eventTargetName="Inline Button"
        disabled={onPressDisabled ? false : disabled}
        onPress={disabled ? onPressDisabled : onPress}
        style={({ pressed, hovered }) => [
          styles.container,
          hovered && styles.containerHovered,
          pressed && styles.containerPressed
        ]}
      >
        {iconPlacement === "left" && iconNode}
        <Text
          selectable={false}
          style={styles.label}
          weight="bold"
          testID={testID && `${testID}-label`}
        >
          {label}
        </Text>
        {iconPlacement === "right" && iconNode}
      </Pressable>
    </ColorPlacementProvider>
  );
}
