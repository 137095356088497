import React, { type ReactNode } from "react";
import { View } from "react-native";

import { Column, Row, Spacer } from "../quarks";
import { useStyles } from "../style";

interface Props {
  children?: ReactNode;
  testID?: string;
  variant?: "xlarge" | "large" | "standard" | "medium" | "compact" | "slim";
  color?:
    | "foreground"
    | "background"
    | "surface"
    | "divider"
    | "primary"
    | "emphasized";
}

export default function QuoteContent({
  children,
  testID,
  variant = "compact",
  color = "background"
}: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    bar: {
      width: getUnits(2),
      backgroundColor: getColor(color, "fill")
    }
  }));

  return (
    <Row testID={testID} alignItems="stretch">
      <View style={styles.bar} />
      <Spacer horizontal size={variant} />
      <Column fill>{children}</Column>
    </Row>
  );
}
