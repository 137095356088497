import React, { type ReactNode } from "react";
import type { IconName } from "../quarks/Icon";
import type { Color } from "../style/theme/colors";
import InfoElement, { type Props as InfoElementProps } from "./InfoElement";
import type { ModalVariant } from "./ModalContext";
import { type ModalAction, showModal } from "./ModalProvider";

interface ModalProps {
  testID: string;
  children: ReactNode;
  title: string;
  subTitle?: string;
  eventContext?: string | null;
  headerIcon?: IconName;
  headerColor?: Color;
  closePrompt?: string;
  callToActionPrompt?: string;
  onCallToAction?: () => void;
  modalVariant?: ModalVariant;
  scrollable?: boolean;
}

type Props = ModalProps & InfoElementProps;

function getModalActions({
  testID,
  closePrompt = "Got It",
  callToActionPrompt,
  onCallToAction
}: ModalProps): ModalAction[] {
  return onCallToAction && callToActionPrompt
    ? [
        {
          testID: `${testID}-call-to-action-btn`,
          label: callToActionPrompt,
          onPress: onCallToAction,
          color: "primary"
        }
      ]
    : [
        {
          testID: `${testID}-close-btn`,
          label: closePrompt,
          color: "primary"
        }
      ];
}

export function showInfoModal(props: ModalProps) {
  const {
    testID,
    children,
    eventContext = null,
    title,
    subTitle,
    headerIcon,
    headerColor,
    modalVariant,
    scrollable
  } = props;
  showModal({
    title,
    subTitle,
    headerIcon,
    headerColor,
    testID: testID && `${testID}-modal`,
    actions: getModalActions(props),
    eventContext,
    variant: modalVariant,
    scrollable,
    children
  });
}
export default function InfoModal(props: Props) {
  return <InfoElement {...props} openModal={() => showInfoModal(props)} />;
}

InfoModal.defaultProps = {
  variant: "icon"
};
