import React from "react";
import { View } from "react-native";
import { type ViewStyleProp, useStyles } from "../style";
import StyledText from "../text/styled-text";
import StyledIcon, { type IconName } from "./styled-icon";

interface Props {
  fontColor?: string;
  iconColor?: string;
  icon: IconName;
  text: string | string[];
  style?: ViewStyleProp;
}

export default function IconParagraph({
  fontColor,
  iconColor,
  icon,
  text,
  style
}: Props) {
  const { styles, theme } = useStyles(() => ({
    container: {
      flexDirection: "row",
      paddingLeft: 20,
      paddingRight: 20
    },
    icon: {
      fontSize: 40
    },
    iconContainer: {
      paddingTop: 20,
      width: 60
    },
    paragraph: {
      marginBottom: 15
    },
    paragraphContainer: {
      flex: 1,
      paddingBottom: 20,
      paddingTop: 20
    }
  }));
  let textElement = null;
  const textStyle = [styles.paragraph, { color: fontColor }];
  if (Array.isArray(text)) {
    textElement = text.map((item, index) => (
      <StyledText
        fontSize={theme.font.size.large}
        key={`paragraph_${index}`}
        style={textStyle}
      >
        {item}
      </StyledText>
    ));
  } else {
    textElement = (
      <StyledText fontSize={theme.font.size.large} style={textStyle}>
        {text}
      </StyledText>
    );
  }

  return (
    <View style={[styles.container, style]}>
      <View style={styles.iconContainer}>
        <StyledIcon name={icon} style={[styles.icon, { color: iconColor }]} />
      </View>
      <View style={styles.paragraphContainer}>{textElement}</View>
    </View>
  );
}
