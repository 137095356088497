interface PaymentMethod {
  last4?: string;
  bankName?: string;
  brand?: string;
}
export function formatPaymentMethod(paymentMethod: PaymentMethod) {
  const paymentMethodInstitution =
    paymentMethod?.bankName ?? paymentMethod?.brand ?? "NOT FOUND";
  let paymentLine = `${paymentMethodInstitution} *${
    paymentMethod?.last4 ?? "NOT FOUND"
  }`;

  if (paymentLine.includes("NOT FOUND")) {
    paymentLine = "Not Available";
  }

  return paymentLine;
}
