import React from "react";
import Column from "../quarks/Column";
import Icon, { type IconName, type IconVariant } from "../quarks/Icon";
import { ColorPlacementProvider } from "../style/colorPlacement";
import { useDiscriminatedStyle, useStyle } from "../style/styles";
import type { Color } from "../style/theme/colors";

interface Props {
  testID?: string;
  size: "tiny" | "small" | "medium" | "large" | "extraLarge";
  icon: IconName;
  iconColor?: Color;
  color: Color;
  iconVariant?: IconVariant;
  variant?: "standard" | "well";
}

export default function IconSquare({
  size,
  icon,
  iconColor,
  iconVariant = "solid",
  variant = "standard",
  color,
  testID = "icon-square"
}: Props) {
  const sizeStyle = useDiscriminatedStyle(
    size,
    {
      tiny: {
        width: 24,
        height: 24,
        borderRadius: 4
      },
      small: {
        width: 28,
        height: 28,
        borderRadius: 4
      },
      medium: {
        width: 36,
        height: 36,
        borderRadius: 4
      },
      large: {
        width: 46,
        height: 46,
        borderRadius: 4
      },
      extraLarge: {
        width: 160,
        height: 160,
        borderRadius: 4
      }
    },
    [size]
  );
  const iconStyle = useDiscriminatedStyle(
    size,
    {
      tiny: { fontSize: 12, lineHeight: 12 * 1.2 },
      small: { fontSize: 14, lineHeight: 14 * 1.2 },
      medium: { fontSize: 20, lineHeight: 20 * 1.2 },
      large: { fontSize: 28, lineHeight: 28 * 1.2 },
      extraLarge: { fontSize: 80, lineHeight: 80 * 1.2 }
    },
    [size]
  );
  const columnStyle = useStyle(
    ({ getColor }) => ({
      backgroundColor: getColor(color, "fill", {
        opacity: variant === "well" ? 0.08 : undefined
      })
    }),
    [color, variant]
  );
  return (
    <ColorPlacementProvider color={color}>
      <Column
        testID={testID}
        justifyContent="center"
        alignItems="center"
        style={[sizeStyle, columnStyle]}
      >
        <Icon
          name={icon}
          color={iconColor ?? variant === "well" ? color : iconColor}
          style={iconStyle}
          variant={iconVariant}
        />
      </Column>
    </ColorPlacementProvider>
  );
}
