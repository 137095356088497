/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type RefObject } from "react";
import {
  type RichEditor,
  RichToolbar,
  actions
} from "react-native-pell-rich-editor";

import { Icon, type IconName } from "../../quarks";
import { useStyles, useTheme } from "../../style";
import type { Color } from "../../style/theme/colors";

const genIcon = (icon: IconName) => {
  return ({ tintColor }: { tintColor: Color }) => (
    <Icon name={icon} color={tintColor} size="medium" variant="solid" />
  );
};

const iconmap = {
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  [actions.setBold!]: genIcon("bold"),
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  [actions.setItalic!]: genIcon("italic"),
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  [actions.setUnderline!]: genIcon("underline"),
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  [actions.insertBulletsList!]: genIcon("list-ul"),
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  [actions.insertOrderedList!]: genIcon("list-ol"),
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  [actions.insertLink!]: genIcon("link")
};

const availableActions = [
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  actions.setBold!,
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  actions.setItalic!,
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  actions.setUnderline!,
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  actions.insertBulletsList!,
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  actions.insertOrderedList!,
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  actions.insertLink!
];

interface Props {
  editorRef?: RefObject<RichEditor>;
  onInsertLink?: () => void;
}

const Toolbar = ({ editorRef, onInsertLink }: Props) => {
  const editor = editorRef?.current;
  const theme = useTheme();
  const styles = useStyles(({ getUnits, getColor }) => ({
    toolbar: {
      backgroundColor: getColor("foreground", "fill"),
      flexDirection: "row",
      alignItems: "center",
      borderTopColor: "#00000012",
      borderTopWidth: 1,
      paddingHorizontal: getUnits(2)
    },
    button: {
      width: getUnits(8),
      height: getUnits(8),
      marginRight: getUnits(1),
      borderRadius: getUnits(1),
      justifyContent: "center",
      alignItems: "center"
    },
    selectedButton: {
      backgroundColor: getColor("primary", "fill")
    }
  }));

  if (!editor) return null;

  return (
    <RichToolbar
      actions={availableActions}
      iconMap={iconmap}
      iconTint={theme.getColor("textPrimary", "fill")}
      selectedIconTint={theme.getColor("textPrimary", "placement")}
      style={styles.toolbar}
      getEditor={() => editor}
      selectedButtonStyle={[styles.button, styles.selectedButton]}
      unselectedButtonStyle={styles.button}
      onInsertLink={onInsertLink}
    />
  );
};

export default Toolbar;
