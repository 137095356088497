import React, { type LegacyRef, type ReactNode, useRef } from "react";
import { StyleSheet } from "react-native";
import RNMapView, { type MapViewProps } from "react-native-maps";

interface Props {
  testID?: string;
  lite?: boolean;
  children?: ReactNode;
  mapRef?: LegacyRef<RNMapView>;
  onMapReady?: MapViewProps["onMapReady"];
  onMapLoaded?: MapViewProps["onMapLoaded"];
  initialCamera?: MapViewProps["initialCamera"];
  showPointsOfInterest?: boolean;
  showBuildings?: boolean;
  options?: {
    fullscreenControl?: boolean;
    mapTypeControl?: boolean;
    streetViewControl?: boolean;
  };
  style?: MapViewProps["style"];
  customMapStyle?: MapViewProps["customMapStyle"];
  maxZoomLevel?: MapViewProps["maxZoomLevel"];
  minZoomLevel?: MapViewProps["minZoomLevel"];
  onPress?: MapViewProps["onPress"];
  scrollEnabled?: MapViewProps["scrollEnabled"];
}

const MapView = ({ testID, mapRef, lite, children, ...mapProps }: Props) => (
  <RNMapView
    ref={mapRef}
    testID={testID}
    style={StyleSheet.absoluteFill}
    provider="google"
    toolbarEnabled={false}
    showsMyLocationButton={false}
    showsCompass={false}
    maxZoomLevel={20}
    {...(lite && {
      liteMode: true,
      pointerEvents: "none",
      rotateEnabled: false,
      zoomEnabled: false,
      scrollEnabled: false,
      pitchEnabled: false
    })}
    {...mapProps}
  >
    {children}
  </RNMapView>
);

export const useMapRef = () => useRef<RNMapView>(null);

export default MapView;
