import { type DependencyList, useMemo } from "react";
import type { ImageStyle, StyleProp, TextStyle, ViewStyle } from "react-native";
import {
  type ExtractStyleType,
  type NamedResponsiveStyles,
  Responsive,
  type ResponsiveStyleType
} from "./responsive";
import { useStylesheetStyle } from "./stylesheet";
import { type ThemeableThunk, useThemeable } from "./theme";
import { transformFont } from "./theme/fonts";
import { type ViewportState, matchesViewport, useViewport } from "./viewport";

export type { ViewStyle, TextStyle, ImageStyle, StyleProp };

function maybeTransformFont<T extends ViewStyle | TextStyle | ImageStyle>(
  style: T
): T {
  return "fontFamily" in style || "fontWeight" in style || "fontStyle" in style
    ? (transformFont(style) as T)
    : style;
}

function processStyle<T extends ViewStyle | TextStyle | ImageStyle>(
  style: ResponsiveStyleType<T>,
  viewport: ViewportState
): T {
  if (Array.isArray(style)) {
    return style
      .map((item) => processStyle(item, viewport))
      .reduce((acc, item) => ({ ...acc, ...item }));
  }
  if (style instanceof Responsive) {
    const doesMatch = matchesViewport(style.media, viewport);
    const shouldReturnStyle =
      (doesMatch && !style.inverted) || (!doesMatch && style.inverted);
    // eslint-disable-next-line no-restricted-properties
    if (shouldReturnStyle) return style.style;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as T;
  }

  return maybeTransformFont(style);
}

export function forViewport<
  T extends NamedResponsiveStyles<T> | NamedResponsiveStyles<any>
>(styles: T | NamedResponsiveStyles<T>, viewport: ViewportState) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const result = {} as { [P in keyof T]: ExtractStyleType<T[P]> };
  for (const key in styles) {
    result[key] = processStyle(styles[key], viewport) as any;
  }
  return result;
}

export function useStyles<
  T extends NamedResponsiveStyles<T> | NamedResponsiveStyles<any>
>(
  styles: ThemeableThunk<T | NamedResponsiveStyles<T>>,
  deps: DependencyList = []
) {
  const themedStyles = useThemeable(styles, deps);
  const viewport = useViewport();
  return useMemo(
    () => forViewport(themedStyles, viewport),
    [themedStyles, viewport]
  );
}

export function useStyle<S extends ViewStyle | TextStyle | ImageStyle>(
  style: ThemeableThunk<ResponsiveStyleType<S>>,
  deps?: DependencyList
) {
  const themedStyle = useThemeable(style, deps);
  const viewport = useViewport();
  return useStylesheetStyle(
    () => processStyle(themedStyle, viewport),
    [themedStyle, viewport]
  );
}

export function useDiscriminatedStyle<
  T extends NamedResponsiveStyles<T> | NamedResponsiveStyles<any>
>(
  discriminator: keyof T,
  styles: ThemeableThunk<T | NamedResponsiveStyles<T>>,
  deps?: DependencyList
) {
  return useStyles(styles, deps)[discriminator];
}
