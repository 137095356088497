// eslint-disable-next-line no-restricted-imports
import { windowInsets } from "@gigsmart/atorasu/quarks/SafeArea";
import { Dimensions } from "react-native";

export default {
  deviceWidth: () => Dimensions.get("window").width,
  deviceHeight: () => Dimensions.get("window").height,

  /** @deprecated use useSafeAreaInsets hook. */
  bottomSpace: () => windowInsets.bottom,
  /** @deprecated use useSafeAreaInsets hook. */
  statusBarHeight: () => windowInsets.top
};
