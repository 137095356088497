import React from "react";
import { Platform } from "react-native";
import { type theme, useStyles } from "../style";
import StyledIcon, { type IconName } from "./styled-icon";

interface Props {
  name: IconName;
  size?: keyof typeof theme.font.size;
  color?: string;
  position?: "left" | "right";
}

export default function IconInputAccessory({
  name,
  color = "neutralDark",
  position = "left",
  size = "medium"
}: Props) {
  const { styles, theme } = useStyles(
    ({ units }) => ({
      icon: {
        textAlign: "center",
        paddingHorizontal: units(1),
        width: Platform.OS === "web" ? 16 : 24,
        [position === "left" ? "marginRight" : "marginLeft"]: -4
      }
    }),
    [position]
  );
  return (
    <StyledIcon
      pointerEvents="none"
      style={styles.icon}
      name={name}
      color={color}
      variant="solid"
      size={theme.font.size[size]}
    />
  );
}
