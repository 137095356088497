import { take } from "lodash";
import React, { type ReactNode, useState } from "react";
import ContentArea from "../atoms/ContentArea";
import IconButton from "../atoms/IconButton";
import Column from "../quarks/Column";
import Divider from "../quarks/Divider";
import type { IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Row from "../quarks/Row";
import Text from "../quarks/Text";

interface Item {
  title: string;
  content: ReactNode;
}

interface Props<T> {
  items: Array<Item & T>;
  iconName: IconName;
  testID: string;
  expandText?: string;
  collapseText?: string;
  placeholder?: ReactNode;
  startCollapsed?: boolean;
  allowCollapse?: boolean;
  onPress?: (param: Item & T) => void;
}

export default function CollapsibleList<T>({
  items,
  expandText,
  collapseText,
  iconName,
  placeholder,
  startCollapsed = true,
  allowCollapse = true,
  onPress,
  testID
}: Props<T>) {
  const [collapsed, setCollapsed] = useState(startCollapsed);

  if (items.length === 0) {
    if (placeholder)
      return typeof placeholder === "string" ? (
        <Text testID={`${testID}-placeholder`}>{placeholder}</Text>
      ) : (
        <>{placeholder}</>
      );
    return null;
  }

  return (
    <>
      {take(items, collapsed ? 1 : items.length).map(
        (item, index, original) => (
          <Column
            key={`${testID}-item-${index}`}
            testID={`${testID}-item-${index}`}
          >
            <ContentArea size="compact" variant="none">
              <Row justifyContent="space-between">
                <Text weight="bold" numberOfLines={1}>
                  {item.title}
                </Text>
                {onPress && (
                  <IconButton
                    testID={`${testID}-action-${index}`}
                    eventEntityType="CollapsibleList"
                    name={iconName}
                    onPress={() => onPress(item)}
                    size="tiny"
                    variant="solid"
                    color="primary"
                  />
                )}
              </Row>
              {item.content}
            </ContentArea>
            {index + 1 < original.length && <Divider />}
          </Column>
        )
      )}
      {items.length > 1 && allowCollapse && (
        <Pressable
          testID={`${testID}-expand-or-collapse`}
          eventEntityType="CollapsibleList"
          eventTargetName="Collapsible List"
          onPress={() => setCollapsed(!collapsed)}
        >
          <Text
            variant="note"
            color="primary"
            testID={`${testID}-expand-or-collapse-text`}
          >
            {collapsed
              ? expandText ?? `Show ${items.length - 1} More`
              : collapseText ?? "Show Less"}
          </Text>
        </Pressable>
      )}
    </>
  );
}
