import React from "react";
import { Icon, type IconName, type IconVariant, Pressable } from "../quarks";
import { ColorPlacementProvider, useStyle } from "../style";
import type { Color } from "../style/theme/colors";
import InputLabel from "./InputLabel";
import Stack from "./Stack";

interface Props {
  testID: string;
  icon: IconName;
  iconColor?: Color;
  color: Color;
  iconVariant?: IconVariant;
  label?: string;
  labelColor?: Color;
  onPress: () => void;
}

const IconSquareButton = ({
  color = "neutral",
  testID,
  icon,
  iconColor = "primary",
  iconVariant = "solid",
  label,
  labelColor = "primary",
  onPress
}: Props) => {
  const styles = useStyle(
    ({ getColor }) => ({
      backgroundColor: getColor(color, "fill", {
        opacity: 0.08
      }),
      height: 60,
      width: 60,
      borderRadius: 4,
      justifyContent: "center",
      alignItems: "center"
    }),
    [color]
  );
  const wellContent = (
    <ColorPlacementProvider color={color}>
      <Pressable
        eventEntityType="IconSquareButton"
        eventTargetName="Icon Square Button"
        testID={testID}
        onPress={onPress}
        style={styles}
      >
        <Icon name={icon} color={iconColor} variant={iconVariant} />
      </Pressable>
    </ColorPlacementProvider>
  );

  return label ? (
    <Stack size="slim">
      <InputLabel inset={false} label={label} color={labelColor} />
      {wellContent}
    </Stack>
  ) : (
    <>{wellContent}</>
  );
};

export default IconSquareButton;
