import React, { type ReactNode } from "react";
import {
  SectionList as RNSectionList,
  type SectionListProps,
  View
} from "react-native";
import Constraint, { IsConstrainedProvider } from "../atoms/Constraint";
import {
  type ViewStyle,
  responsive,
  useStyle,
  useTheme,
  useViewport
} from "../style";
import type { Color } from "../style/theme/colors";
import type { ThemeMedia } from "../style/theme/media";

type Props<T> = Pick<
  SectionListProps<T>,
  | "renderItem"
  | "sections"
  | "stickySectionHeadersEnabled"
  | "ListEmptyComponent"
  | "ListHeaderComponent"
  | "ListFooterComponent"
  | "renderSectionHeader"
  | "SectionSeparatorComponent"
  | "ItemSeparatorComponent"
  | "renderSectionFooter"
  | "onEndReached"
  | "onEndReachedThreshold"
  | "testID"
> & {
  useSmallContentContainer?: boolean;
};

export default function SectionList<T>({
  useSmallContentContainer,
  ...props
}: Props<T>) {
  const { media } = useTheme();
  const { width } = useViewport();
  const { minWidth } = media.size.medium;
  const style = useStyle(() => ({
    width: "100%",
    maxWidth: media.size.xlarge.maxWidth,
    marginHorizontal: "auto"
  }));
  const contentContainerStyle = useStyle<ViewStyle>(
    ({ colors, media, getUnits }) => [
      {
        backgroundColor: colors.background.fill
      },
      responsive(media.size.medium.up, {
        paddingBottom: getUnits(13),
        paddingTop: getUnits(13),
        paddingHorizontal: getUnits(15)
      }),
      responsive(media.size.small.down, {
        paddingBottom: getUnits(8)
      })
    ]
  );

  const smallContentContainerStyle = useStyle<ViewStyle>(
    ({ colors, getUnits }) => [
      {
        backgroundColor: colors.background.fill,
        paddingHorizontal: getUnits(4)
      }
    ]
  );

  return (
    <IsConstrainedProvider value={!!minWidth && width > minWidth}>
      <RNSectionList
        style={style}
        contentContainerStyle={
          useSmallContentContainer
            ? smallContentContainerStyle
            : contentContainerStyle
        }
        stickySectionHeadersEnabled={false}
        {...props}
      />
    </IsConstrainedProvider>
  );
}

interface SectionContainerProps {
  size?: keyof ThemeMedia["size"];
  children?: ReactNode;
  variant?: "item" | "header" | "footer";
  color?: Color;
}

export function SectionContainer({
  variant = "item",
  size = "xsmall",
  children,
  color = "textPrimary"
}: SectionContainerProps) {
  const style = useStyle(
    ({ getColor, getUnits }) => ({
      paddingHorizontal: getUnits(4),
      borderTopLeftRadius: variant === "header" ? getUnits(1) : undefined,
      borderTopRightRadius: variant === "header" ? getUnits(1) : undefined,
      borderBottomLeftRadius: variant === "footer" ? getUnits(1) : undefined,
      borderBottomRightRadius: variant === "footer" ? getUnits(1) : undefined,
      paddingTop:
        variant === "header"
          ? getUnits(4)
          : variant === "footer" && !children
            ? getUnits(0)
            : getUnits(2),
      paddingBottom:
        variant === "footer" && !!children ? getUnits(4) : getUnits(2),
      marginBottom: variant === "footer" ? getUnits(4) : 0,
      backgroundColor: getColor(color, "fill", { opacity: 0.08 })
    }),
    [variant, color]
  );
  return (
    <Constraint size={size}>
      <View style={style}>{children}</View>
    </Constraint>
  );
}
