import React, { type ComponentProps, type ReactNode } from "react";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import { type UnitSize, getSpaceUnits } from "../quarks/Spacer";
import {
  type BreakpointName,
  type ViewStyleWithoutProps,
  useStyle
} from "../style";
import { IsConstrainedProvider, useConstraint } from "./Constraint";

interface ContentAreaOptions {
  size?: UnitSize;
  variant?: UnitSize;
  constrainedVariant?: UnitSize;
  constraint?: BreakpointName | "none";
  color?:
    | "foreground"
    | "background"
    | "surface"
    | "divider"
    | "primary"
    | "clear";
  topRadius?: UnitSize | "auto";
  bottomRadius?: UnitSize | "auto";
  horizontal?: boolean;
}

interface Props extends ContentAreaOptions, ComponentProps<typeof Column> {
  children: ReactNode;
}

export default function ContentArea({
  size,
  variant,
  constrainedVariant,
  constraint,
  color,
  topRadius,
  bottomRadius,
  horizontal,
  ...props
}: Props) {
  const Component = horizontal ? Row : Column;
  const [style, isConstrained] = useContentArea({
    size,
    variant,
    constraint,
    constrainedVariant,
    color,
    topRadius,
    bottomRadius
  });

  return (
    <IsConstrainedProvider value={isConstrained}>
      <Component {...props} style={style} />
    </IsConstrainedProvider>
  );
}

export const useContentArea = ({
  size = "standard",
  variant = "standard",
  constrainedVariant,
  constraint,
  color,
  topRadius,
  bottomRadius
}: ContentAreaOptions = {}) => {
  const [style, isConstrained] = useConstraint(constraint);
  const viewStyle = useStyle(
    ({ getColor }) => ({
      paddingVertical: getSpaceUnits(size),
      paddingHorizontal:
        isConstrained && constrainedVariant
          ? getSpaceUnits(constrainedVariant)
          : getSpaceUnits(variant),
      ...(color && { backgroundColor: getColor(color, "fill") }),
      ...computeContentRadius(topRadius, bottomRadius)
    }),
    [variant, size, color, constrainedVariant, isConstrained]
  );

  const contentStyle = style ? [viewStyle, style] : viewStyle;
  return [contentStyle, isConstrained] as [ViewStyleWithoutProps, boolean];
};

const contentRadiusValue = (size: UnitSize | "auto", isRounded = false) =>
  getSpaceUnits(size === "auto" ? (isRounded ? "slim" : "none") : size);

export const computeContentRadius = (
  topRadius: UnitSize | "auto" = "none",
  bottomRadius: UnitSize | "auto" = "none",
  isRounded = false
) => {
  const topRadiusPx = contentRadiusValue(topRadius, isRounded);
  const bottomRadiusPx = contentRadiusValue(bottomRadius, isRounded);
  return {
    borderTopLeftRadius: topRadiusPx,
    borderTopRightRadius: topRadiusPx,
    borderBottomLeftRadius: bottomRadiusPx,
    borderBottomRightRadius: bottomRadiusPx
  };
};
