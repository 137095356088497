import React from "react";
import DocumentTile from "../../atoms/DocumentTile";
import MaxWidthContainer from "../../atoms/MaxWidthContainer";
import Stack from "../../atoms/Stack";
import Surface from "../../atoms/Surface";
import ImageStack from "../../molecules/ImageStack";
import Column from "../../quarks/Column";
import Pressable from "../../quarks/Pressable";
import { useStyle } from "../../style";
import { showPDFViewer } from "../PDFViewer";

export interface Attachment {
  id?: string;
  filename?: string | null;
  url?: string;
  mimeType?: string;
}

interface Props {
  attachments: readonly Attachment[];
}

export default function UserMessageAttachments({ attachments }: Props) {
  const style = useStyle({ width: "100%" });
  const handleAttachmentPress = (attachment: Attachment) => {
    if (!attachment) return;
    showPDFViewer({
      source: { uri: attachment.url ?? "" },
      filename: attachment.filename ?? "Document.pdf"
    });
  };

  if (!attachments?.length) return null;
  const files = attachments.filter(
    ({ mimeType }) => mimeType === "application/pdf"
  );
  const photos = attachments.filter(
    ({ mimeType }) => mimeType !== "application/pdf"
  );

  return (
    <Stack size="slim" style={style}>
      {photos && (
        <Column alignItems="flex-end">
          <ImageStack
            eventTargetName="UserMessageAttachments"
            images={photos?.map(({ url }) => url ?? "") ?? []}
            // thumbSize={46.5}
            // thumbResizeMode="cover"
          />
        </Column>
      )}
      {files.map(({ url, filename, mimeType }, index: number) => (
        <MaxWidthContainer key={url} maxWidth={180}>
          <Column>
            <Pressable
              testID="select-message-attachment"
              eventEntityType="Pressable"
              eventTargetName="SelectMessageAttachment"
              onPress={() =>
                handleAttachmentPress?.({ url, filename, mimeType })
              }
            >
              <Surface variant="flat" color="background">
                <DocumentTile
                  name={filename ?? `Attachment ${index + 1}`}
                  type="PDF"
                  icon="file-pdf"
                />
              </Surface>
            </Pressable>
          </Column>
        </MaxWidthContainer>
      ))}
    </Stack>
  );
}
