import type { AppMenuItem } from "@gigsmart/kaizoku";
import React from "react";
import { View } from "react-native";
import { Icon, Pressable, Spacer, Text, getSpaceUnits } from "../quarks";
import { useStyles } from "../style";

interface Props {
  item: AppMenuItem;
  active?: boolean;
  highlighted?: boolean;
  onPress?: (item: AppMenuItem) => void;
}

export default function BottomNavItem({
  item,
  active,
  highlighted,
  onPress
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: { flex: 1, alignItems: "center" },
    inner: {
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: getColor("surface", "fill"),
      borderRadius: getSpaceUnits("slim"),
      margin: getSpaceUnits("slim"),
      height: 48,
      maxWidth: 82,
      width: "100%"
    },
    iconPlaceholder: { height: 24 },
    hovered: { backgroundColor: getColor("foreground", "fill") },
    active: { backgroundColor: getColor("primary", "fill", { opacity: 0.12 }) },
    pressed: { opacity: 0.3 },
    highlightedIcon: {
      width: 48,
      height: 48,
      borderRadius: 24,
      backgroundColor: getColor("primary", "fill"),
      position: "absolute",
      left: "50%",
      marginLeft: -24,
      top: -18,
      alignItems: "center",
      justifyContent: "center"
    }
  }));

  if (item === "separator") {
    return null;
  }

  const color = active ? "primary" : "neutral";
  const testID = item.testID ?? `${item.title}-sidenav-item`;
  return (
    <Pressable
      eventEntityType="BottomNavItem"
      eventTargetName={`${item.title} Bottom Nav Item`}
      testID={testID}
      onPress={() => onPress?.(item)}
      style={styles.container}
    >
      {({ pressed, hovered }) => (
        <>
          <View
            style={[
              styles.inner,
              hovered && styles.hovered,
              pressed && styles.pressed,
              active && styles.active
            ]}
          >
            {highlighted ? (
              <View style={styles.iconPlaceholder} />
            ) : typeof item.icon === "string" ? (
              <Icon
                name={item.icon}
                color={color}
                variant={active ? "solid" : "light"}
              />
            ) : (
              item.icon
            )}
            <Spacer size="small" />
            <Text
              align="center"
              variant="note"
              color={color}
              weight={active ? "semibold" : "normal"}
            >
              {item.title}
            </Text>
          </View>
          {highlighted && (
            <View style={[styles.highlightedIcon, pressed && styles.pressed]}>
              {typeof item.icon === "string" && (
                <Icon name={item.icon} color="surface" variant="regular" />
              )}
            </View>
          )}
        </>
      )}
    </Pressable>
  );
}
