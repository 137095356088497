import React, { type ComponentProps } from "react";
import { ListRow } from "../atoms";
import { Column, Divider, Spacer, Text } from "../quarks";
import ActionRow from "./ActionRow";

type Props = ComponentProps<typeof ActionRow>;

export default function ExpandedActionRow({
  title,
  note,
  noteColor,
  color = "primary",
  numberOfLines,
  right,
  children,
  alignItems = "flex-start",
  ...props
}: Props) {
  if (typeof title === "string") {
    title = (
      <Text variant="subheader" color={color} numberOfLines={numberOfLines}>
        {title}
      </Text>
    );
  }

  return (
    <ActionRow {...props} alignItems={alignItems} color={color}>
      <ListRow
        shrink
        leftSpacing="none"
        rightSpacing="none"
        verticalSpacing="none"
        spacing="medium"
        stackSize="small"
        label={title}
        right={right}
      >
        {!!note && (
          <Text variant="note" color={noteColor}>
            {note}
          </Text>
        )}
      </ListRow>
      <Column>
        <Spacer size="compact" />
        <Divider />
        <Spacer size="compact" />
        {children}
      </Column>
    </ActionRow>
  );
}
