import notifications from "../configs/notification";
import defaultImpl from "./defaultImpl.web";

export default notifications.register(
  defaultImpl({
    name: "notifications",
    deviceAction: () => {
      return new Promise((resolve) => {
        Notification.requestPermission()
          .then(() => resolve())
          .catch(() => resolve());
      });
    }
  })
);
