import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";
import { type Eventers, useEventers } from "./use-eventers";

interface IHasTestID {
  testID?: string;
}

export function eventerStubs<T extends string>(keys: T[]): Eventers<T> {
  return keys.reduce<Partial<Eventers<T>>>(
    (acc, key) => ({ ...acc, [key]: () => undefined }),
    {}
  ) as Eventers<T>;
}

export interface EventerProps<T extends string> {
  eventers: Eventers<T>;
}

export const withEventers =
  <A extends string, OuterProps extends IHasTestID>(
    entityType: string,
    actions: A[],
    targetNameProp:
      | ((props: OuterProps) => string)
      | keyof OuterProps = "testID" as keyof OuterProps,
    contextNameOverride?: string
  ): HOCVoid =>
  (WrappedComponent: ComponentType<EventerProps<A>>) => {
    return applyHOCProperties({
      displayName: "withEventers",
      WrappedComponent,
      HigherOrderComponent: (props: OuterProps, ref) => {
        const targetName =
          (typeof targetNameProp === "function"
            ? targetNameProp(props)
            : props[targetNameProp]) ??
          props.testID ??
          "Unknown";
        const eventers = useEventers<A>(
          String(targetName),
          entityType,
          actions,
          contextNameOverride
        );
        return <WrappedComponent ref={ref} {...props} eventers={eventers} />;
      }
    });
  };
