import React from "react";
import type { ComponentProps } from "react";
import Pressable from "../quarks/Pressable";
import { useStyles } from "../style";
import Checkbox from "./Checkbox";

type Props = ComponentProps<typeof Checkbox>;

export default function CheckboxSquare({
  selected,
  onChange,
  testID,
  eventTargetName = "Checkbox Square",
  ...props
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      minHeight: 52,
      width: 52,
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "stretch"
    },
    selected: {
      backgroundColor: getColor("highlight", "fill", { opacity: 0.08 })
    }
  }));
  return (
    <Pressable
      testID={`${testID}-square`}
      eventEntityType="CheckboxSquare"
      eventTargetName={eventTargetName}
      onPress={() => onChange?.(!selected)}
      style={[styles.container, selected && styles.selected]}
      disabled={props.disabled}
    >
      <Checkbox testID={testID} selected={selected} {...props} />
    </Pressable>
  );
}
