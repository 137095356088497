import type { Thunk } from "../../utils/thunk";

type HexColor = `#${string}`;
type RGBAColor =
  | `rgba(${number},${number},${number},${number})`
  | `rgba(${number}, ${number}, ${number}, ${number})`
  | `rgba(${number} ${number} ${number} / ${number}.${number})`;
type RGBColor =
  | `rgb(${number},${number},${number})`
  | `rgb(${number}, ${number}, ${number})`
  | `rgb(${number} ${number} ${number})`;
type NativeColors = "transparent" | RGBColor | RGBAColor | HexColor;

export interface ColorSpec<P = ColorProp> {
  // The fill of the element, this can be applied to boxes or when text is applied the color directly
  fill: P | NativeColors;

  // The placement of elements on a previously filled element, this applies to text, icons, etc that are placed on "filled" views/buttons/etc
  placement?: P | NativeColors;
}

export type NamedColors<T> = { [P in keyof T]: ColorSpec<P> };

const layoutColors = (<T extends NamedColors<T>>(
  colors: NamedColors<T>
): NamedColors<T> => colors)({
  // Layout
  background: { fill: "#e7e7e7", placement: "#000000" },
  surface: { fill: "#ffffff", placement: "#000000" },
  foreground: { fill: "#f5f5f5", placement: "#000000" },
  black: { fill: "#000000", placement: "#ffffff" },
  white: { fill: "#ffffff", placement: "#000000" }
});

const elementColors = (<T extends NamedColors<T>>(
  colors: NamedColors<T>
): NamedColors<T> => colors)({
  primary: { fill: "#003b5c", placement: "#ffffff" },
  danger: { fill: "#dc4405", placement: "#ffffff" },
  disabled: { fill: "#8a8c8e", placement: "#ffffff" },
  error: { fill: "#b40000", placement: "#ffffff" },
  warning: { fill: "#ffb549", placement: "#ffffff" },
  success: { fill: "#24925c", placement: "#ffffff" },
  emphasized: { fill: "#00af83", placement: "#ffffff" },
  info: { fill: "#3eafe0", placement: "#ffffff" },
  neutral: { fill: "#8a8c8e", placement: "#ffffff" },
  purple: { fill: "#866ec5", placement: "#ffffff" },
  orange: { fill: "#ff7f32", placement: "#ffffff" }
});

const touchableColors = (<T extends NamedColors<T>>(
  colors: NamedColors<T>
): NamedColors<T> => colors)({
  ...elementColors,
  clear: { fill: "transparent", placement: "#003b5c" }
});

const textColors = (<T extends NamedColors<T>>(
  colors: NamedColors<T>
): NamedColors<T> => colors)({
  ...elementColors,
  textPrimary: { fill: "#000000", placement: "#ffffff" }
});

const specializedColors = (<T extends NamedColors<T>>(
  colors: NamedColors<T>
): NamedColors<T> => colors)({
  input: { fill: "#f5f5f5", placement: "#8a8c8e" },
  divider: { fill: "#e7e7e7" },
  star: { fill: "#ffb549" },
  link: { fill: "#3eafe0", placement: "#ffffff" },
  highlight: { fill: "#2c5696", placement: "#ffffff" },
  requestInfo: { fill: "#f36464", placement: "#ffffff" },
  inactiveStep: { fill: "#bec6cf" }
});

const defaultColors = (<T extends NamedColors<T>>(
  colors: NamedColors<T>
): NamedColors<T> => colors)({
  ...layoutColors,
  ...elementColors,
  ...touchableColors,
  ...textColors,
  ...specializedColors
});

export const textColorNames = Object.keys(textColors);
export const touchableColorNames = Object.keys(touchableColors);
export type ThemeColors = typeof defaultColors;
export type Color = ColorSpec["fill"];
export type ColorProp = keyof ThemeColors;
export type ThemeableColorThunk = Thunk<[ThemeColors], ColorSpec>;
export default defaultColors as ThemeColors;
export const colorNames = Object.keys(defaultColors);
