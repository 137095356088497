import React from "react";

import { ContentArea, IconText } from "../atoms";
import { Divider, type IconName, Spacer } from "../quarks";
import type { Color } from "../style/theme/colors";

interface Props {
  variant?: "none" | "compact" | "standard";
  size?: "none" | "compact" | "standard";
  icon: IconName;
  title?: string;
  color?: Color;
}

export default function TitledGroupDivider({
  icon,
  title = "",
  color = "disabled",
  variant = "compact",
  size = "compact"
}: Props) {
  return (
    <ContentArea variant={variant} size={size}>
      <IconText
        icon={icon}
        color={color}
        textWeight="semibold"
        spacing="compact"
        size="small"
      >
        {title}
      </IconText>
      <Spacer size="compact" />
      <Divider color="disabled" />
    </ContentArea>
  );
}
