import { RelayNetworkLayer } from "react-relay-network-modern/es";
import { logger as baseLogger } from "../../logger";
import { generateGraphqlWsSubscriber } from "../graphql-ws";
import type { ProtocolOptions } from "../types";
import { generateMiddleware } from "./generate-middleware";

const logger = baseLogger.createLogger("HTTP");

export async function generateHttpNetwork(options: ProtocolOptions) {
  const { handler, registerSubscriber, deregisterSubscriber } =
    await generateGraphqlWsSubscriber(options);

  const network = new RelayNetworkLayer(await generateMiddleware(options), {
    noThrow: true,
    subscribeFn: handler as any
  });

  return { network, registerSubscriber, deregisterSubscriber };
}
