import React, { type ReactNode } from "react";
import { Row, Text } from "../../quarks";
import { useStyle } from "../../style";

interface Props {
  label?: string;
  fill?: boolean;
  children?: ReactNode;
  color?: "neutral" | "primary";
}

export default function DayHeader({
  fill,
  label = "-",
  color = "neutral",
  children
}: Props) {
  const style = useStyle(({ getColor }) => ({
    height: 32,
    backgroundColor: getColor("foreground", "fill")
  }));
  return (
    <Row style={style} fill={fill} alignItems="center" justifyContent="center">
      <Text weight="bold" color={color} align="center" numberOfLines={1}>
        {label.toUpperCase()}
      </Text>
      {children}
    </Row>
  );
}
