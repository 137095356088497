interface Values {
  signee: string;
  signature: string;
}

interface Props {
  initialValues?: Partial<Values>;
  onClose: (values?: Values) => void;
}

export function showSignatureCanvas(_props: Props) {
  return null;
}
