import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import ApiPlayground from "./app";

const elem = document.getElementById("react-root");
if (elem) {
  Modal.setAppElement(elem);
  ReactDOM.render(<ApiPlayground />, elem);
}
