import type { ComponentProps } from "react";
import type { View } from "react-native";
import { type UnitSize, spacerUnitSize } from "../quarks/Spacer";
import { type StyleProp, type ViewStyle, useStyle } from "./styles";

export type FlexStyle = Pick<
  ViewStyle,
  | "justifyContent"
  | "alignItems"
  | "alignSelf"
  | "alignContent"
  | "flex"
  | "display"
  | "flexDirection"
  | "flexWrap"
  | "flexGrow"
  | "flexShrink"
  | "flexBasis"
  | "zIndex"
  | "gap"
  | "rowGap"
  | "columnGap"
>;

export type ViewStyleWithoutProps = Omit<ViewStyle, keyof FlexStyle>;

export interface ViewPropsWithoutFlex
  extends Omit<ComponentProps<typeof View>, "style" | "direction"> {
  style?: StyleProp<ViewStyleWithoutProps>;
  contentContainerStyle?: StyleProp<ViewStyleWithoutProps>; /// scrollview specific
}

export interface UseTransformFlexProps
  extends ViewPropsWithoutFlex,
    Pick<
      FlexStyle,
      "justifyContent" | "alignItems" | "alignSelf" | "alignContent" | "display"
    > {
  fill?: FlexStyle["flex"] | boolean;
  direction?: FlexStyle["flexDirection"];
  wrap?: FlexStyle["flexWrap"];
  grow?: FlexStyle["flexGrow"] | boolean;
  shrink?: FlexStyle["flexShrink"] | boolean;
  basis?: FlexStyle["flexBasis"];
  zIndex?: FlexStyle["zIndex"];
  gap?: FlexStyle["gap"] | UnitSize;
  columnGap?: FlexStyle["columnGap"] | UnitSize;
  rowGap?: FlexStyle["rowGap"] | UnitSize;
}

export function useTransformFlexProps(
  {
    direction,
    fill,
    justifyContent,
    alignItems,
    alignSelf,
    alignContent,
    wrap,
    grow,
    shrink,
    basis,
    display = "flex",
    zIndex,
    gap,
    columnGap,
    rowGap,
    ...rest
  }: UseTransformFlexProps,
  styleProp: "style" | "contentContainerStyle" = "style"
) {
  return {
    ...rest,
    [styleProp]: [
      rest[styleProp],
      useStyle(
        ({ getUnits }) => ({
          display,
          flexDirection: direction,
          flex: fill === true ? 1 : fill || undefined,
          justifyContent,
          alignItems,
          alignSelf,
          alignContent,
          flexWrap: wrap,
          flexGrow: grow === true ? 1 : grow || undefined,
          flexShrink: shrink === true ? 1 : shrink || undefined,
          flexBasis: basis,
          zIndex,
          gap: typeof gap === "string" ? getUnits(spacerUnitSize[gap]) : gap,
          rowGap:
            typeof rowGap === "string"
              ? getUnits(spacerUnitSize[rowGap])
              : rowGap,
          columnGap:
            typeof columnGap === "string"
              ? getUnits(spacerUnitSize[columnGap])
              : columnGap
        }),
        [
          display,
          fill,
          justifyContent,
          alignItems,
          alignSelf,
          alignContent,
          wrap,
          shrink,
          grow,
          basis,
          direction,
          zIndex,
          gap,
          rowGap,
          columnGap
        ]
      )
    ]
  };
}
