import React, { type ReactNode, useEffect } from "react";
import { Platform, type ViewProps } from "react-native";
import { useModalContext } from "../organisms/ModalContext";
import { Column, ScrollView, type UnitSize, useScrollRef } from "../quarks";
import Constraint from "./Constraint";
import ContentArea from "./ContentArea";
import Surface from "./Surface";

interface Props {
  children?: ReactNode;
  scrollable?: boolean;
  grow?: boolean;
  extraScrollHeight?: number;
  constraint?: "xsmall" | "large" | "none";
  enableResetScrollPosition?: boolean;
  zIndex?: number;
  onLayout?: ViewProps["onLayout"];
  gap?: UnitSize;
}

const ModalBody = ({
  zIndex,
  children,
  scrollable,
  extraScrollHeight,
  constraint,
  enableResetScrollPosition = true,
  gap,
  grow,
  onLayout
}: Props) => {
  const { size, variant, fixedHeight } = useModalContext();
  const isShadow = variant === "shadow" || variant === "full-shadow";
  const isFull =
    variant === "full" ||
    variant === "full-shadow" ||
    variant === "full-border";
  const contentVariant = isShadow && size === "small" ? "none" : "standard";
  const contentFill = !!fixedHeight || isFull || undefined;
  const scrollRef = useScrollRef();

  const inner = (
    <ContentArea
      testID="modal-body"
      variant={contentVariant}
      constrainedVariant="none"
      fill={contentFill}
      size="none"
      zIndex={zIndex}
      onLayout={onLayout}
    >
      {variant === "border" ? (
        <Surface fill={contentFill} variant="outline" gap={gap}>
          {children}
        </Surface>
      ) : isFull ? (
        <Column fill={contentFill} gap={gap}>
          {children}
        </Column>
      ) : (
        <Constraint
          fill={contentFill}
          size={!constraint ? "xsmall" : constraint}
          gap={gap}
        >
          {children}
        </Constraint>
      )}
    </ContentArea>
  );

  useEffect(() => {
    if (!scrollable) return;
    if (enableResetScrollPosition) {
      scrollRef.current?.scrollTo({ y: 0, animated: false });
    }
  }, [children]);

  return scrollable ? (
    <ScrollView
      grow={grow}
      innerRef={scrollRef}
      showsVerticalScrollIndicator={Platform.OS === "web"}
      testID="modal-body-scroller"
      extraScrollHeight={extraScrollHeight}
      enableAutomaticScroll={false}
    >
      {inner}
    </ScrollView>
  ) : (
    inner
  );
};

export default ModalBody;
