import React, { type ReactNode, type ReactText } from "react";
import type { IconName } from "../quarks/Icon";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";
import IconText from "./IconText";

import { INPUT_HEIGHT } from "./InputContainer";

interface Props {
  testID?: string;
  color?: Color;
  icon?: IconName;
  label?: string | ReactNode;
  error?: boolean;
  required?: boolean;
  horizontal?: boolean;
  inset?: boolean;
  accessory?: ReactNode;
}

const InputLabel = ({
  testID,
  color,
  icon,
  label,
  error,
  required,
  horizontal,
  inset = true,
  accessory
}: Props) => {
  const styles = useStyles(
    ({ getUnits, getFontSize, measurements }) => ({
      container: horizontal
        ? { minHeight: INPUT_HEIGHT }
        : { marginHorizontal: inset ? getUnits(2) : 0 },
      label: { ...getFontSize(measurements.inputLabelSize) }
    }),
    [horizontal, inset]
  );

  const textNode =
    typeof label === "string"
      ? `${label}${required ? " *" : ""}`
      : label || null;
  const textWeight = horizontal ? "normal" : "bold";
  const textColor =
    color ?? (error ? "danger" : horizontal ? "textPrimary" : "primary");
  return (
    <Row
      style={styles.container}
      alignItems={horizontal ? "center" : undefined}
      fill={horizontal ? 1 : undefined}
    >
      {icon && textNode ? (
        <IconText
          testID={testID}
          size="small"
          icon={icon}
          textWeight={textWeight}
          spacing="compact"
          color={textColor}
        >
          {textNode as ReactText}
        </IconText>
      ) : (
        <Text
          testID={testID}
          style={styles.label}
          weight={textWeight}
          color={textColor}
        >
          {textNode}
        </Text>
      )}
      {!!accessory && <Spacer size="slim" horizontal />}
      {accessory}
    </Row>
  );
};

export default InputLabel;
