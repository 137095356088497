import "./font-loader";
import { Platform } from "react-native";
import { BASE_UNIT } from "./unit";

export type WeightEnum = "regular" | "semibold" | "bold";

// 10px
const SMALLEST = BASE_UNIT * 2.5;

// 12px
const SMALL = BASE_UNIT * 3;

// 14px
const REGULAR = BASE_UNIT * 3.5;

// 16px
const LARGE = BASE_UNIT * 4;

// 20px
const EXTRA_LARGE = BASE_UNIT * 5;

// 24px
const LARGEST = BASE_UNIT * 6;

const fontWeight = {
  regular: "400" as const,
  semibold: "500" as const,
  bold: "700" as const
};

const processFontFamily = (type: "header" | "body") => {
  const androidVariant: { [weight in WeightEnum]: string } = {
    header: {
      regular: "Produkt-Regular",
      semibold: "Produkt-Medium",
      bold: "Produkt-Bold"
    },
    body: {
      regular: "ProximaNova-Regular",
      semibold: "ProximaNova-Semibold",
      bold: "ProximaNova-Bold"
    }
  }[type];

  return (weight: WeightEnum) => {
    if (Platform.OS === "android") {
      return { fontFamily: androidVariant[weight] };
    }
    return { fontFamily: font.family[type], fontWeight: fontWeight[weight] };
  };
};

const fontHelpers = {
  inputMultiline: (
    fontSize: number,
    options: { lineHeight?: number; min?: number; max?: number } = {}
  ) => {
    // line height doesnt work very well with inputs on react-native
    const lineHeight = fontSize; // (options.lineHeight || 1) * fontSize;
    const minHeight = lineHeight * (options.min ?? 3);
    const maxHeight = lineHeight * (options.max ?? 15);
    return { fontSize, minHeight, maxHeight };
  },

  withSize: (fontSize: number, lineHeight?: number) => ({
    fontSize,
    lineHeight: fontSize * (lineHeight ?? font.lineHeight.body)
  }),

  header: processFontFamily("header"),
  body: processFontFamily("body")
};

const font = {
  size: {
    smallest: SMALLEST,
    small: SMALL,
    medium: REGULAR,
    large: LARGE,
    extraLarge: EXTRA_LARGE,
    largest: LARGEST,
    helperText: SMALL,
    bodyCopy: REGULAR,
    heading1: LARGEST,
    heading2: EXTRA_LARGE,
    title: EXTRA_LARGE,
    subtitle: LARGE
  },
  family: {
    body: Platform.select({
      ios: "Proxima Nova",
      android: "ProximaNova-Regular",
      web: "Proxima Nova"
    }),
    header: Platform.select({
      ios: "Produkt",
      android: "Produkt-Bold",
      web: "Produkt Web"
    })
  },
  lineHeight: {
    body: 1.2,
    header: 1.4
  },
  weight: fontWeight
};

export default { ...fontHelpers, ...font };
