import React from "react";
import Column from "../quarks/Column";
import type { IconName } from "../quarks/Icon";
import Pressable, { type PressableProps } from "../quarks/Pressable";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useStyles } from "../style";
import AbsoluteContainer from "./AbsoluteContainer";
import IconCircle from "./IconCircle";

interface Props {
  testID: string;
  icon: IconName;
  label: string;
  selected?: boolean;
  onPress?: () => void;
  eventEntityType?: string;
  hitSlop?: PressableProps["hitSlop"];
}

export default function PressableTile({
  icon,
  label,
  selected,
  onPress,
  testID,
  eventEntityType = "PressableTile",
  hitSlop
}: Props) {
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      wrapper: {
        height: 112,
        paddingTop: getUnits(4),
        paddingHorizontal: getUnits(2),
        paddingBottom: getUnits(2),
        borderWidth: selected ? 2 : 1,
        borderColor: getColor(selected ? "primary" : "divider", "fill"),
        borderRadius: getUnits(1)
      },
      labelWrapper: {
        height: getUnits(7),
        marginTop: getUnits(1),
        paddingHorizontal: getUnits(2)
      }
    }),
    [selected]
  );
  return (
    <Pressable
      hitSlop={hitSlop}
      onPress={onPress}
      eventEntityType={eventEntityType}
      testID={testID}
      eventTargetName=""
    >
      <Column alignItems="center" style={styles.wrapper}>
        {selected && (
          <AbsoluteContainer top={8} right={8}>
            <Row testID={`${testID}-selected`} justifyContent="flex-end">
              <IconCircle
                icon="check"
                color="primary"
                iconColor="white"
                size="tiny"
              />
            </Row>
          </AbsoluteContainer>
        )}
        <IconCircle
          icon={icon}
          variant="well"
          color="primary"
          iconColor="primary"
          size="medium"
        />
        <Row
          alignItems="center"
          justifyContent="center"
          style={styles.labelWrapper}
        >
          <Text variant="note" color="black" align="center" numberOfLines={2}>
            {label}
          </Text>
        </Row>
      </Column>
    </Pressable>
  );
}
