import eventStore, { type ToastEvent, type ToastSelector } from "./eventStore";

const calculateDismissTiming = ({
  dismissAfter,
  sticky,
  message,
  quick,
  delayMs = 0
}: Partial<ToastEvent>) => {
  if (sticky) return undefined;
  if (typeof dismissAfter === "number") return dismissAfter + delayMs;
  return (
    delayMs +
    (message !== null && typeof message === "object" && "detail" in message
      ? 10500
      : quick
        ? 3500
        : 7000)
  );
};

type ToastOptions = Omit<ToastEvent, "message">;

export function dismiss(options: ToastSelector) {
  eventStore.emit("dismiss", options);
}

export function toast(
  message: ToastEvent["message"],
  {
    sticky,
    onPress,
    onDismiss,
    color = "neutral",
    label = "generic",
    dismissAfter,
    dismissable = true,
    testID,
    quick,
    icon = "bell",
    delayMs,
    exclusive
  }: Partial<ToastOptions> = {}
) {
  const dismiss = eventStore.emit("toast", {
    message,
    dismissable,
    dismissAfter: calculateDismissTiming({
      dismissAfter,
      sticky,
      message,
      quick,
      delayMs
    }),
    icon,
    onPress,
    onDismiss,
    color,
    label,
    testID,
    exclusive
  });
  return { dismiss };
}

export function info(
  message: ToastEvent["message"],
  options: Omit<ToastOptions, "color"> = {}
) {
  return toast(message, {
    label: "info",
    icon: "circle-info",
    color: "highlight",
    ...options
  });
}

export function success(
  message: ToastEvent["message"],
  options: Omit<ToastOptions, "color"> = {}
) {
  return toast(message, {
    label: "success",
    color: "success",
    icon: "check",
    quick: process.env.IS_TESTING !== "true",
    ...options
  });
}

export function error(
  message: ToastEvent["message"],
  options: Omit<ToastOptions, "color"> = {}
) {
  return toast(message, {
    label: "error",
    color: "error",
    icon: "circle-exclamation",
    ...options
  });
}

export function warning(
  message: ToastEvent["message"],
  options: Omit<ToastOptions, "color"> = {}
) {
  return toast(message, {
    label: "warning",
    color: "warning",
    icon: "triangle-exclamation",
    ...options
  });
}

export function notice(
  message: ToastEvent["message"],
  options: Omit<ToastOptions, "color"> = {}
) {
  return toast(message, {
    label: "notice",
    color: "neutral",
    icon: "flag",
    ...options
  });
}

export function network(
  message: ToastEvent["message"],
  options: Omit<ToastOptions, "color"> = {}
) {
  return toast(message, {
    label: "network",
    color: "requestInfo",
    icon: "wifi",
    ...options
  });
}
