import React from "react";
import Column from "../quarks/Column";
import Icon, { type IconName } from "../quarks/Icon";
import { type Size, useDiscriminatedStyle, useStyle } from "../style";
import type { Color } from "../style/theme/colors";
import GigSmartIcon from "./GigSmartIcon";

type StickerSize = Exclude<Size, "small" | "medium" | "xlarge"> | "xxsmall";

interface Props {
  icon: IconName;
  color?: Color;
  size?: StickerSize;
}

const Sticker = ({ size = "xsmall", icon, color = "danger" }: Props) => {
  const computedSize = icon === "gigsmart" ? "xsmall" : size;

  const sizeStyle = useDiscriminatedStyle(
    computedSize,
    {
      xxsmall: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 1
      },
      xsmall: {
        width: 25,
        height: 25,
        borderRadius: 12.5,
        borderWidth: 1
      },
      large: {
        width: 120,
        height: 120,
        borderRadius: 60,
        borderWidth: 4
      }
    },
    [computedSize]
  );

  const iconStyle = useDiscriminatedStyle(
    computedSize,
    {
      xxsmall: { fontSize: 10, lineHeight: 10 },
      xsmall: { fontSize: 12, lineHeight: 12 },
      large: { fontSize: 60, lineHeight: 60 }
    },
    [computedSize]
  );

  const containerStyle = useStyle(
    ({ getUnits, getColor }) => ({
      backgroundColor: getColor("surface", "fill"),
      borderColor: getColor(color, "fill"),
      shadowColor: "black",
      shadowOpacity: 0.15,
      shadowRadius: getUnits(1),
      shadowOffset: { width: 0, height: getUnits(0.5) }
    }),
    [color]
  );

  return (
    <Column
      style={[sizeStyle, containerStyle]}
      alignItems="center"
      justifyContent="center"
    >
      {icon === "gigsmart" ? (
        <GigSmartIcon size={16.5} color={color} />
      ) : (
        <Icon style={iconStyle} name={icon} variant="solid" color={color} />
      )}
    </Column>
  );
};

export default Sticker;
