import { Slider } from "@miblanchard/react-native-slider";
import React from "react";
import IconButton from "../atoms/IconButton";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useStyles } from "../style";

interface Props {
  max: number;
  min: number;
  onPressPlay: () => void;
  onPressPause: () => void;
  onValueChange: (value: number) => void;
  displayValue?: string;
  playing?: boolean;
  value?: number;
}

export default function Playbar({
  max = 1,
  min = 0,
  displayValue,
  playing,
  onPressPause,
  onPressPlay,
  onValueChange,
  value
}: Props) {
  const styles = useStyles(() => ({
    container: {
      flex: 1,
      height: 24
    },
    thumb: {
      height: 16,
      width: 16
    },
    track: {
      height: 8
    }
  }));
  return (
    <>
      <Row gap="medium" alignItems="center">
        <IconButton
          testID="playbar-play-btn"
          name={playing ? "pause" : "play"}
          size="small"
          variant="solid"
          onPress={() => (playing ? onPressPause() : onPressPlay())}
        />
        <Slider
          containerStyle={styles.container}
          minimumValue={min}
          maximumValue={max}
          step={1}
          thumbTintColor="#003b5c"
          thumbStyle={styles.thumb}
          trackStyle={styles.track}
          minimumTrackTintColor="#003b5c"
          maximumTrackTintColor="#e7e7e7"
          value={value}
          onSlidingComplete={(values) => {
            onValueChange(values?.[0] ?? 0);
          }}
          animateTransitions
          animationType="timing"
          animationConfig={
            {
              duration: 1000,
              useNativeDriver: true
            } as any
          }
        />
        {displayValue && <Text>{displayValue}</Text>}
      </Row>
    </>
  );
}
