import React, { type ReactNode, memo } from "react";
import { View, type ViewProps } from "react-native";
import Stack from "../../atoms/Stack";
import Pressable from "../../quarks/Pressable";
import Text from "../../quarks/Text";
import { useStyles } from "../../style";

interface Props {
  label: ReactNode;
  active?: boolean;
  onPress?: () => void;
  testID: string;
  onLayout?: ViewProps["onLayout"];
  maxWidth?: number | string;
  showBadge?: boolean;
}

export const TAB_HEIGHT = 46;

const TabItem = ({
  label,
  active,
  onLayout,
  maxWidth,
  testID,
  showBadge,
  ...props
}: Props) => {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      wrapper: {
        flex: 1,
        flexGrow: 1,
        backgroundColor: "#fff",
        borderColor: getColor("neutral", "fill"),
        borderBottomWidth: 1,
        minWidth: 110,
        maxWidth
      },
      container: {
        height: TAB_HEIGHT,
        paddingTop: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: getUnits(3)
      },
      indicator: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        height: 3,
        backgroundColor: getColor("primary", "fill")
      },
      circle: {
        width: getUnits(2),
        height: getUnits(2),
        borderRadius: getUnits(2) / 2,
        backgroundColor: getColor("success", "fill")
      }
    }),
    [maxWidth]
  );
  return (
    <View testID={testID} style={styles.wrapper} onLayout={onLayout}>
      <Pressable
        testID={testID}
        style={styles.container}
        eventTargetName={`${testID}-TabButton`}
        eventEntityType="TabButton"
        accessible={false}
        {...props}
      >
        <Stack horizontal alignItems="center" size="compact">
          {typeof label === "string" ? (
            <Text
              testID={testID}
              weight="bold"
              color={active ? "primary" : "disabled"}
              align="center"
            >
              {label}
            </Text>
          ) : (
            label
          )}
          {showBadge && <View style={styles.circle} />}
        </Stack>
      </Pressable>
      {active && <View style={styles.indicator} />}
    </View>
  );
};

export default memo(
  TabItem,
  (p1, p2) =>
    p1.label === p2.label &&
    p1.active === p2.active &&
    p1.maxWidth === p2.maxWidth &&
    p1.showBadge === p2.showBadge
);
