import React, { type ReactNode } from "react";
import { Platform, View } from "react-native";
import { Button, ContentArea, IconText, Stack } from "../../atoms";
import { Picker } from "../../organisms";
import { Column, Divider, Icon, Pressable, Row, Text } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import type { PickerOption, SortDirection } from "./types";

interface Props {
  viewSelectionOptions: PickerOption[];
  selectedView: string;
  onViewSelectionChange: (item: string) => void;
  customControls: ReactNode;
  sortDirection: SortDirection;
  onChangeSortDirection: (direction: SortDirection) => void;
  sortOptions: PickerOption[];
  selectedSortOption: string;
  onSortOptionChange: (item: string) => void;
}

export default function SectionListFilter({
  viewSelectionOptions,
  selectedView,
  onViewSelectionChange,
  customControls,
  sortDirection,
  onChangeSortDirection,
  sortOptions,
  selectedSortOption,
  onSortOptionChange
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      container: {
        backgroundColor: getColor("surface", "fill"),
        maxHeight: isSm ? getUnits(39.5) : getUnits(34.5),
        width: isSm ? getUnits(35) : getUnits(52),
        // minWidth: isSm ? getUnits(35) : getUnits(52),
        zIndex: 2,
        borderRightWidth: 1,
        borderRightColor: getColor("divider", "fill")
      },
      emptyControlsRow: { minHeight: 32 },
      controlsRow: {
        backgroundColor: getColor("highlight", "fill", { opacity: 0.08 })
      },
      demoteLayer: {
        zIndex: -1
      },
      pickerLabel: {
        maxWidth: 98,
        paddingTop: Platform.OS === "ios" ? getUnits(2) : undefined
      }
    }),
    [isSm]
  );

  const renderSortControls = () => (
    <Stack horizontal variant="divider" fill={1}>
      <Pressable
        onPress={() =>
          onChangeSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
        }
        eventEntityType="CalendarViewSort"
        eventTargetName="Calendar View Sort"
        testID="calendar-sort-button"
      >
        <ContentArea size="compact" variant="compact">
          <Icon
            name={
              sortDirection === "ASC"
                ? "sort-amount-up-alt"
                : "sort-amount-down-alt"
            }
            size="small"
            variant="solid"
          />
        </ContentArea>
      </Pressable>
      <Row fill={1} justifyContent="center" alignItems="center">
        <Picker
          testID="sort-selection-picker"
          value={selectedSortOption}
          onChange={(option) => onSortOptionChange(option ?? "")}
          eventTargetName="Calendar Period Picker"
          options={sortOptions}
          customControl={({ onPress }) => (
            <Button
              testID="sort-selection-picker-control"
              label="Sort"
              onPress={onPress}
              icon="sort"
              variant="clear"
              size="small"
            />
          )}
        />
      </Row>
    </Stack>
  );

  const renderDivider = () => (
    <View style={styles.demoteLayer}>
      <Divider />
    </View>
  );

  return isSm ? (
    <View style={styles.container}>
      <ContentArea variant="compact" size="compact" zIndex={2}>
        <Row justifyContent="center">
          <Picker
            testID="view-selection-picker"
            value={selectedView}
            onChange={(view) => onViewSelectionChange(view ?? "")}
            eventTargetName="Calendar Period Picker"
            options={viewSelectionOptions}
            customControl={({ isOpen, label, value, onPress }) => (
              <Pressable
                onPress={onPress}
                testID="view-selection-picker-control"
                eventTargetName="View Selection Picker Control"
                eventEntityType="CalendarViewSelection"
              >
                <IconText
                  icon={isOpen ? "chevron-up" : "chevron-down"}
                  size="small"
                  iconVariant="solid"
                  color="primary"
                  iconPlacement="right"
                  spacing="compact"
                  testID="view-selection-picker"
                >
                  <View style={styles.pickerLabel}>
                    <Text
                      weight="bold"
                      numberOfLines={1}
                      align="center"
                      color="primary"
                    >
                      {label ?? "-"}
                    </Text>
                  </View>
                </IconText>
              </Pressable>
            )}
          />
        </Row>
      </ContentArea>
      {renderDivider()}
      <Row
        style={[styles.controlsRow, styles.emptyControlsRow]}
        alignItems="flex-start"
      >
        <Column fill={1} />
        {/* Uncomment this when we are ready to turn on custom controls */}
        {/* <ContentArea variant="compact" size="compact">
          {customControls}
        </ContentArea> */}
      </Row>
      <Divider color="divider" />
      <Row style={styles.controlsRow} justifyContent="flex-end">
        <Divider dir="y" color="divider" />
        {renderSortControls()}
      </Row>
    </View>
  ) : (
    <View style={styles.container}>
      <ContentArea variant="compact" size="compact" zIndex={2}>
        <Row>
          <Picker
            testID="view-selection-picker"
            value={selectedView}
            onChange={(view) => onViewSelectionChange(view ?? "")}
            eventTargetName="Calendar Period Picker"
            options={viewSelectionOptions}
            customControl={({ isOpen, label, onPress }) => (
              <Button
                size="small"
                outline
                label={label ?? "-"}
                testID="view-selection-picker"
                icon={isOpen ? "chevron-up" : "chevron-down"}
                iconPlacement="right"
                onPress={onPress}
              />
            )}
          />
        </Row>
      </ContentArea>
      <Divider />
      <Row style={styles.controlsRow}>
        {/* Uncomment this when we are ready to turn on custom controls */}
        {/* <ContentArea size="compact" variant="compact">
          {customControls}
        </ContentArea> */}
        <Divider dir="y" color="divider" />
        {renderSortControls()}
        <Divider color="divider" />
      </Row>
      <Divider />
    </View>
  );
}
