import type { ValuesType } from "@gigsmart/type-utils";
import themeColor from "../color";
import type { TypeForStyleKey } from "../type-helpers";

export const BorderStyleValue = {
  NONE: "none",
  HIDDEN: "hidden",
  DOTTED: "dotted",
  DASHED: "dashed",
  SOLID: "solid",
  DOUBLE: "double",
  GROOVE: "groove",
  RIDGE: "ridge",
  INSET: "inset",
  OUTSET: "outset"
};

type BorderStyles = [
  TypeForStyleKey<"width">,
  ValuesType<typeof BorderStyleValue>,
  TypeForStyleKey<"color">
];

interface AllBordersArgs {
  top?: BorderStyles | null | undefined;
  right?: BorderStyles | null | undefined;
  bottom?: BorderStyles | null | undefined;
  left?: BorderStyles | null | undefined;
}

export const BorderSide = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal"
};

export function borderTop(
  width: TypeForStyleKey<"width"> = 1,
  style: ValuesType<typeof BorderStyleValue> = BorderStyleValue.SOLID,
  color: TypeForStyleKey<"color"> = themeColor.black
) {
  return {
    borderTopWidth: width,
    borderTopStyle: style,
    borderTopColor: color
  };
}
export function borderRight(
  width: TypeForStyleKey<"width"> = 1,
  style: ValuesType<typeof BorderStyleValue> = BorderStyleValue.SOLID,
  color: TypeForStyleKey<"color"> = themeColor.black
) {
  return {
    borderRightWidth: width,
    borderRightStyle: style,
    borderRightColor: color
  };
}
export function borderLeft(
  width: TypeForStyleKey<"width"> = 1,
  style: ValuesType<typeof BorderStyleValue> = BorderStyleValue.SOLID,
  color: TypeForStyleKey<"color"> = themeColor.black
) {
  return {
    borderLeftWidth: width,
    borderLeftStyle: style,
    borderLeftColor: color
  };
}
export function borderBottom(
  width: TypeForStyleKey<"width"> = 1,
  style: ValuesType<typeof BorderStyleValue> = BorderStyleValue.SOLID,
  color: TypeForStyleKey<"color"> = themeColor.black
) {
  return {
    borderBottomWidth: width,
    borderBottomStyle: style,
    borderBottomColor: color
  };
}

export function borderVertical(
  width: TypeForStyleKey<"width"> = 1,
  style: ValuesType<typeof BorderStyleValue> = BorderStyleValue.SOLID,
  color: TypeForStyleKey<"color"> = themeColor.black
) {
  return {
    borderVerticalWidth: width,
    borderVerticalStyle: style,
    borderVerticalColor: color
  };
}
export function borderHorizontal(
  width: TypeForStyleKey<"width"> = 1,
  style: ValuesType<typeof BorderStyleValue> = BorderStyleValue.SOLID,
  color: TypeForStyleKey<"color"> = themeColor.black
) {
  return {
    borderHorizontalWidth: width,
    borderHorizontalStyle: style,
    borderHorizontalColor: color
  };
}

export function border(
  width: TypeForStyleKey<"width"> = 1,
  style: ValuesType<typeof BorderStyleValue> = BorderStyleValue.SOLID,
  color: TypeForStyleKey<"color"> = themeColor.black
) {
  return {
    borderWidth: width,
    borderStyle: style,
    borderColor: color
  };
}

export function allBorders({ top, right, bottom, left }: AllBordersArgs) {
  let styles = {};

  if (top) styles = { ...styles, ...borderTop(top[0], top[1], top[2]) };
  if (right) {
    styles = { ...styles, ...borderRight(right[0], right[1], right[2]) };
  }
  if (bottom) {
    styles = { ...styles, ...borderBottom(bottom[0], bottom[1], bottom[2]) };
  }
  if (left) styles = { ...styles, ...borderLeft(left[0], left[1], left[2]) };

  return styles;
}

export function someBorders(
  sides: Array<ValuesType<typeof BorderSide>>,
  ...borderStyles: BorderStyles
) {
  let styles = {};

  for (let side = 0, l = sides.length; side < l; side++) {
    switch (sides[side]) {
      case BorderSide.VERTICAL:
        styles = {
          ...styles,
          ...borderVertical(borderStyles[0], borderStyles[1], borderStyles[2])
        };
        break;
      case BorderSide.HORIZONTAL:
        styles = {
          ...styles,
          ...borderHorizontal(borderStyles[0], borderStyles[1], borderStyles[2])
        };
        break;
      case BorderSide.BOTTOM:
        styles = {
          ...styles,
          ...borderBottom(borderStyles[0], borderStyles[1], borderStyles[2])
        };
        break;
      case BorderSide.LEFT:
        styles = {
          ...styles,
          ...borderLeft(borderStyles[0], borderStyles[1], borderStyles[2])
        };
        break;
      case BorderSide.RIGHT:
        styles = {
          ...styles,
          ...borderRight(borderStyles[0], borderStyles[1], borderStyles[2])
        };
        break;
      default:
        styles = {
          ...styles,
          ...borderTop(borderStyles[0], borderStyles[1], borderStyles[2])
        };
    }
  }

  return { ...styles };
}
