import React from "react";
import Stack from "../atoms/Stack";
import RadioButtonCard, {
  type Props as RadioButtonCardProps
} from "../molecules/RadioButtonCard";
import Column from "../quarks/Column";

export interface Props<T> {
  buttons: Array<
    { value: T } & Omit<RadioButtonCardProps, "selected" | "onSelect">
  >;
  value?: T | null;
  onChange: (value: T) => void;
  horizontal?: boolean;
}

export default function RadioButtonCardGroup<T>({
  buttons,
  value,
  onChange,
  horizontal
}: Props<T>) {
  return (
    <Stack horizontal={horizontal}>
      {buttons.map(({ value: buttonValue, ...buttonProps }) => (
        <Column key={`${buttonValue}`} fill={horizontal ? true : undefined}>
          <RadioButtonCard
            onSelect={() =>
              buttonProps.disabled ? null : onChange(buttonValue)
            }
            selected={value === buttonValue}
            {...(buttonProps as any)}
          />
        </Column>
      ))}
    </Stack>
  );
}
