import React, { type ComponentType } from "react";
import {
  type GraphQLTaggedNode,
  type OperationType,
  type Variables,
  getFragment
} from "relay-runtime";
import {
  type FragmentContainerInnerComponentProps,
  type KeyType,
  createRelayFragmentContainer
} from "../fragment";
import {
  type RelaySubscriptionOptions,
  useRelaySubscription
} from "../subscription";

export interface CreateSubscribedFragmentContainerOptions<
  F extends KeyType<unknown>,
  S extends OperationType,
  P extends {},
  V extends Variables
> extends Pick<
    RelaySubscriptionOptions<S>,
    "updater" | "onNext" | "onError" | "configs"
  > {
  fragment: GraphQLTaggedNode;
  subscription: GraphQLTaggedNode;
  getSubscriptionFragmentRef: (props: S["response"]) => F | null | undefined;
  subscribe?: (props: FragmentContainerInnerComponentProps<F, P>) => boolean;
  subscriptionVariables:
    | V
    | ((props: FragmentContainerInnerComponentProps<F, P>) => V);
  FallbackComponent?: Parameters<typeof createRelayFragmentContainer<F, P>>[2];
}

export function createRelaySubscribedFragmentContainer<
  F extends KeyType<unknown>,
  S extends OperationType = never,
  P extends {} = {},
  V extends Variables = S["variables"]
>(
  Component: ComponentType<FragmentContainerInnerComponentProps<F, P>>,
  {
    fragment,
    subscription,
    subscribe,
    subscriptionVariables,
    FallbackComponent,
    ...opts
  }: CreateSubscribedFragmentContainerOptions<F, S, P, V>
) {
  const { name } = getFragment(fragment);
  const SubscribedFragmentContainer = createRelayFragmentContainer<
    F,
    P & { subscribe?: boolean }
  >(
    fragment,
    function SubscribedFragmentContainer(props) {
      useRelaySubscription<S>(
        subscription,
        typeof subscriptionVariables === "function"
          ? subscriptionVariables(props)
          : subscriptionVariables,
        {
          subscribe:
            typeof props.subscribe !== "undefined"
              ? props.subscribe
              : subscribe?.(props) ?? true,
          ...opts
        }
      );
      return <Component {...props} />;
    },
    FallbackComponent ?? undefined
  );

  SubscribedFragmentContainer.displayName = `RelaySubscribedFragmentContainer[${name}]`;

  return SubscribedFragmentContainer;
}
