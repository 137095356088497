import React, { type ComponentType } from "react";
import { useFragment } from "react-relay";
import { type GraphQLTaggedNode, getFragment } from "relay-runtime";
import type { KeyType } from "./use-relay-fragment";

type OmittedFields = " $fragmentSpreads" | " $fragmentType";

export type FragmentContainerProps<
  F extends KeyType<unknown>,
  P extends {} = {}
> = P & { fragmentRef: F | null | undefined };

export type FragmentContainerInnerComponentProps<
  F extends KeyType<unknown>,
  P extends {} = {}
> = P & { result?: NonNullable<F[" $data"]> } & Omit<
    NonNullable<F[" $data"]>,
    OmittedFields
  >;

export function createRelayFragmentContainer<
  F extends KeyType<unknown> = never,
  P extends {} = {}
>(
  fragment: GraphQLTaggedNode,
  Component: ComponentType<FragmentContainerInnerComponentProps<F, P>>,
  FallbackComponent?: ComponentType<P> | true | null
): ComponentType<FragmentContainerProps<F, P>> {
  const { name } = getFragment(fragment);

  function FragmentContainer(props: FragmentContainerProps<F, P>) {
    const data = useFragment<F>(fragment, props.fragmentRef ?? null);
    if (FallbackComponent === true) FallbackComponent = Component;
    if (!data) {
      return FallbackComponent ? <FallbackComponent {...props} /> : null;
    }

    return <Component result={data} {...data} {...props} />;
  }

  FragmentContainer.displayName = `RelayFragmentContainer[${name}]`;

  return FragmentContainer;
}
