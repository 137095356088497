import React, { type ComponentProps, type ReactNode } from "react";
import ContentArea from "../atoms/ContentArea";
import IconCircle from "../atoms/IconCircle";
import Stack from "../atoms/Stack";
import type { IconName } from "../quarks/Icon";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import type { Color } from "../style/theme/colors";

interface Props extends Pick<ComponentProps<typeof ContentArea>, "constraint"> {
  title: string;
  body?: ReactNode | string;
  icon?: IconName;
  color?: Color;
  iconColor?: Color;
  variant?: "standard" | "well";
}

/** @deprecated use ListEmpty */
export default function ListEmptyView({
  title,
  body,
  color,
  icon,
  iconColor,
  variant = "well",
  constraint
}: Props) {
  return (
    <ContentArea
      size="none"
      fill
      alignItems="center"
      justifyContent="space-between"
      constraint={constraint}
    >
      <Stack alignItems="center">
        <Spacer />
        {!!icon && !!color && (
          <IconCircle
            color={color}
            icon={icon}
            iconColor={iconColor}
            size="medium"
            variant={variant}
          />
        )}
        <Text color="primary" weight="bold" align="center">
          {title}
        </Text>
        {typeof body === "string" ? <Text align="center">{body}</Text> : body}
      </Stack>
    </ContentArea>
  );
}
