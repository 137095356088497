/** @deprecated use hooks */

import type { HOCVoid } from "@gigsmart/hoc-utils";
import {
  type WithSafeAreaInsetsProps,
  withSafeAreaInsets as rnWithSafeAreaInsets
} from "react-native-safe-area-context";

export type { WithSafeAreaInsetsProps };
export const withSafeAreaInsets: HOCVoid = rnWithSafeAreaInsets;
export const safeAreaStub: WithSafeAreaInsetsProps["insets"] = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0
};
