import React, { type ReactNode, type ComponentProps } from "react";

import Column from "../quarks/Column";

interface Props extends ComponentProps<typeof Column> {
  maxWidth?: number;
  children: ReactNode;
}

export default function MaxWidthContainer({
  maxWidth,
  children,
  ...rest
}: Props) {
  return (
    <Column {...rest} style={{ maxWidth }}>
      {children}
    </Column>
  );
}
