import { compact } from "lodash";
import { type QueryOptions, useQuery } from "relay-hooks";
import type { GraphQLTaggedNode, OperationType } from "relay-runtime";
import { useRequestMetadata } from "../helpers";
import { useRelayOrchestrator } from "../orchestrator";
import { useRegisterRelayRetry } from "./retry";

type FetchQueryResultOptions = QueryOptions;

export function useFetchQueryResult<Q extends OperationType>(
  query: GraphQLTaggedNode,
  {
    variables = {},
    fetchKey: originalFetchKey,
    fetchPolicy = "store-and-network",
    ...options
  }: FetchQueryResultOptions &
    (Q["variables"] extends Record<string, never>
      ? { variables?: Q["variables"] }
      : { variables: Q["variables"] }) = { variables: {} }
): [
  Q["response"] | undefined,
  { retry: () => void; isLoading: boolean; error?: Error | null }
] {
  const request = useRequestMetadata(query);
  const fetchKey = compact([
    originalFetchKey,
    request.operation.name,
    JSON.stringify(variables)
  ]).join(":");
  const { data, error, retry, isLoading } = useQuery<Q>(query, variables, {
    fetchKey,
    fetchPolicy,
    ...options
  });
  const { useDecorateQuery } = useRelayOrchestrator();

  useRegisterRelayRetry(retry);
  useDecorateQuery(request.operation.name, retry);

  return [data ?? undefined, { retry, isLoading, error }];
}
