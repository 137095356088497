import React, { Component, type ComponentProps } from "react";
import { type StylesProps, stylesStubs, withStyles } from "../style";
import KatanaText from "../text/katana-text";
import StyledText from "../text/styled-text";

type Props = ComponentProps<typeof StyledText> &
  StylesProps & {
    maxLength: number;
  };

interface State {
  isCollapsed: boolean;
}
@withStyles(({ color }) => ({
  showMoreText: {
    color: color.neutralDark
  }
}))
export default class CollapsibleText extends Component<Props, State> {
  static defaultProps = {
    ...stylesStubs,
    color: "black",
    maxLength: 125
  };

  state = { isCollapsed: true };

  toggle = () => {
    const { isCollapsed } = this.state;
    this.setState({ isCollapsed: !isCollapsed });
  };

  render() {
    const { styles, theme, maxLength, children, style, ...textProps } =
      this.props;
    const { isCollapsed } = this.state;

    if (typeof children !== "string" || children.length <= maxLength) {
      return <StyledText {...textProps}>{children}</StyledText>;
    }

    const splitIdx = children.indexOf(" ", maxLength);
    const text = isCollapsed
      ? children.substring(0, splitIdx <= 0 ? maxLength : splitIdx)
      : children;

    return (
      <KatanaText style={style}>
        {text}
        {isCollapsed && "..."}
        <KatanaText style={styles.showMoreText} onPress={this.toggle}>
          {isCollapsed ? " Show more" : " Show less"}
        </KatanaText>
      </KatanaText>
    );
  }
}
