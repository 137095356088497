import React, { type ComponentProps } from "react";
import Icon, { type Props as IconProps } from "../quarks/Icon";
import Pressable, { type PressableProps } from "../quarks/Pressable";
import ContentArea from "./ContentArea";

interface Props extends IconProps {
  eventEntityType?: string;
  onPress?: () => void;
  testID: string;
  hitSlop?: PressableProps["hitSlop"];
  disabled?: boolean;
  contentAreaSize?: ComponentProps<typeof ContentArea>["size"];
  contentAreaVariant?: ComponentProps<typeof ContentArea>["variant"];
}

export default function IconButton({
  onPress,
  testID,
  eventEntityType = "IconButton",
  hitSlop = 16,
  disabled,
  contentAreaSize = "none",
  contentAreaVariant = "none",
  ...iconProps
}: Props) {
  return (
    <Pressable
      hitSlop={hitSlop}
      onPress={onPress}
      eventTargetName=""
      eventEntityType={eventEntityType}
      testID={testID}
      disabled={disabled}
    >
      <ContentArea size={contentAreaSize} variant={contentAreaVariant}>
        <Icon {...iconProps} />
      </ContentArea>
    </Pressable>
  );
}
