import AsyncStorage from "@react-native-async-storage/async-storage";
import { track } from "./hooks";
import { generateConversionName } from "./naming";

interface Options {
  once?: boolean;
  category?: string;
}

const setValue = "SET";
const getOnce = async (key: string) =>
  await AsyncStorage.getItem(key).catch(() => null);
const hasBeenTracked = async (key: string) => (await getOnce(key)) === setValue;
const setBeenTracked = async (key: string) => {
  await AsyncStorage.setItem(key, setValue).catch(() => null);
};

export const createConversion = (
  name: string,
  { once, category = "conversion" }: Options = {}
) => ({
  track: async (properties: {} = {}) => {
    const eventName = generateConversionName({ name });
    const onceKey = `@dekigoto:${eventName}`;

    if (once && (await hasBeenTracked(onceKey))) return;
    const callback = track(eventName, properties, category);
    if (once) setBeenTracked(onceKey).catch(console.warn);
    return callback;
  }
});
