import React, { type ReactNode, useCallback, useState } from "react";
import { type LayoutChangeEvent, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useStyles } from "../style";
import type { ThemeMedia } from "../style/theme";
import { useKeyboardState } from "../utils/keyboard";
import Constraint from "./Constraint";

interface Props {
  inline?: boolean;
  bgColor?: "transparent" | "background" | "surface";
  bgSolid?: boolean;
  children?: ReactNode;
  placeholder?: boolean;
  onContentSizeChanged?: (size: number) => void;
  size?: keyof ThemeMedia["size"];
}

export default function FooterContent({
  inline,
  children,
  bgColor = "background",
  bgSolid = false,
  placeholder = true,
  size,
  onContentSizeChanged
}: Props) {
  const { bottom, contentSize, onLayout } = useStickyFooter({
    enabled: !inline,
    onContentSizeChanged
  });
  const bottomInset = useSafeAreaInsets().bottom;
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      container: {
        ...(!inline && {
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: getColor(bgColor, "fill", {
            opacity: bgSolid ? 1 : 0.9
          })
        }),
        paddingTop: getUnits(3),
        paddingHorizontal: getUnits(4),
        paddingBottom: Math.max(bottomInset, getUnits(4))
      }
    }),
    [inline, bgColor, bgSolid]
  );

  return (
    <>
      {!inline && placeholder && <View style={{ height: contentSize }} />}
      <View style={[styles.container, { bottom }]} onLayout={onLayout}>
        {size ? <Constraint size={size}>{children}</Constraint> : children}
      </View>
    </>
  );
}

type StickyKeyboardOptions = {
  enabled?: boolean;
  extraSpace?: number;
  onContentSizeChanged?: (size: number) => void;
};

export function useStickyFooter({
  enabled = true,
  extraSpace,
  onContentSizeChanged
}: StickyKeyboardOptions = {}) {
  const [contentSize, setContentSize] = useState(0);
  const { bottom } = useKeyboardState({ enabled, extraSpace });

  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    if (!e.nativeEvent) return;
    const newSize = Math.floor(e.nativeEvent.layout.height);
    if (contentSize !== newSize) {
      setContentSize(newSize);
      onContentSizeChanged?.(newSize);
    }
  }, []);

  return { bottom, contentSize, onLayout: handleLayout };
}
