import currencies from "currency-format";

export interface CurrencyHumanizeOptions<
  TValue extends number | string | null | undefined
> {
  currency?: TValue extends string ? never : string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  integer?: boolean;
  short?: boolean;
  floor?: boolean;
}

export function toISOString(rawAmount: string | number, currencyCode = "USD") {
  const { fractionSize } = currencies[currencyCode] ?? { fractionSize: 2 };
  const amount =
    typeof rawAmount === "string"
      ? Number.parseFloat(rawAmount || "0")
      : rawAmount;
  return `${amount.toFixed(fractionSize)} ${currencyCode}`;
}

export function humanize<T extends number | string | null | undefined>(
  iso4217: T,
  {
    currency,
    integer,
    short,
    floor,
    ...formatterOptions
  }: CurrencyHumanizeOptions<T> = {}
): string {
  const value = iso4217 && iso4217 !== "" ? iso4217 ?? 0 : 0;
  let amount: number;
  let currencyCode: string;
  if (typeof value === "number") {
    amount = value;
    currencyCode = currency ?? "USD";
  } else {
    const [amt, cc] = (value as string).split(" ");
    amount = Number(amt);
    currencyCode = cc === "USN" ? "USD" : cc ?? "USD";
  }

  amount = Number(amount.toFixed(2));
  if (floor) {
    amount = Math.floor(amount);
  }
  if (integer) {
    formatterOptions.minimumFractionDigits = 0;
    formatterOptions.maximumFractionDigits = 0;
  }

  let suffix = "";
  if (short && amount >= 1000) {
    // prevent unwanted rounding up (5250 USD -> $5.2K)
    amount = Math.floor(amount / 100) / 10;
    suffix = "k";
    formatterOptions.maximumFractionDigits = 1;
  }

  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode ?? "USD",
    ...formatterOptions
  });
  return `${formatter.format(amount)}${suffix}`;
}

type Rate = "hr";

export function humanizeRate<T extends number | string | null | undefined>(
  iso4217: T,
  rate: Rate = "hr",
  options?: CurrencyHumanizeOptions<T>
): string {
  return `${humanize<T>(iso4217, options)}/${rate}`;
}

export function toFloat(iso4217?: string | number | null, defaultValue = 0.0) {
  if (!iso4217) return defaultValue;
  if (typeof iso4217 === "number") return iso4217;
  const [amt] = iso4217.split(" ");
  return Number.parseFloat(amt ?? "0") * 1.0;
}

export function calculateFee(
  amount: number | string,
  fee?: number,
  min?: string
) {
  const total = (Number(amount) ?? 0) * (fee ?? 0);
  return Math.max(total, toFloat(min)).toFixed(2);
}

export function roundDown(value: number, decimals: number) {
  return Number(
    Math.floor(value + ("e" as any as number) + decimals) +
      ("e-" as any as number) +
      decimals
  );
}

export function humanizePercentage(rate?: number | null) {
  if (!rate) return "0%";
  return `${rate * 100}%`;
}

export function deHumanized(value: string | undefined | null) {
  return value && Number(String(value).replace(/[^0-9.]/g, ""));
}
