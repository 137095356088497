import React from "react";
import IconText from "../atoms/IconText";
import Pressable from "../quarks/Pressable";

interface Props {
  label?: string;
  testID: string;
  onPress?: () => void;
  size?: "sm" | "md";
}

const sizeSpec = {
  sm: { size: "tiny" },
  md: { size: "small", spacing: "compact" }
} as const;

export default function ViewButton({
  size = "sm",
  label = "View",
  testID,
  onPress
}: Props) {
  const node = (
    <IconText
      testID={testID}
      color="primary"
      icon="chevron-right"
      iconPlacement="right"
      textWeight="bold"
      {...sizeSpec[size]}
    >
      {label}
    </IconText>
  );

  return !onPress ? (
    node
  ) : (
    <Pressable
      testID={`${testID}-pressable`}
      eventEntityType="View Button"
      eventTargetName="View Button"
      onPress={onPress}
    >
      {node}
    </Pressable>
  );
}
