import React, {
  Children,
  Fragment,
  type ComponentType,
  type ReactNode
} from "react";
import type { StepConfig, StepConfigProps } from "./Stepper.types";

export const Step: ComponentType<StepConfigProps<any, any>> = () => null;

export const getStepsFromChildren = <T, TData>(
  children: ReactNode,
  prefix = ""
) => {
  const acc: StepConfig<T, TData>[] = [];
  const addStep = (child: ReactNode) => {
    if (!React.isValidElement(child)) return;
    if (child.type === Step) {
      const { name } = child.props ?? {};
      acc.push({
        ...child.props,
        key: [prefix, name].filter(Boolean).join("."),
        name,
        index: acc.length
      });
    } else if (child.type === Fragment) {
      if (Array.isArray(child.props.children)) {
        (child.props as any).children?.forEach(addStep);
      } else {
        addStep(child.props.children);
      }
    } else {
      throw new Error(
        "Invalid Step component: Valid types are `Step`, `Fragment`"
      );
    }
  };
  for (const child of Children.toArray(children)) addStep(child);
  return acc;
};
