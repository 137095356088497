import type { DeepPartial } from "@gigsmart/type-utils";
import React, {
  createContext,
  type ReactNode,
  useContext,
  useMemo
} from "react";
import {
  type Theme,
  type ThemeVariables,
  createTheme,
  defaultTheme
} from "./createTheme";

interface Props {
  theme?: DeepPartial<ThemeVariables>;
  children?: ReactNode;
}

const ThemeContext = createContext<Theme>(defaultTheme);

export function ThemeProvider({ theme: overrides, children }: Props) {
  const base = useTheme();
  const theme = useMemo(() => createTheme(base, overrides), []);
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
}

export function useTheme(): Theme {
  return useContext(ThemeContext);
}
