import { FlagType } from "@gigsmart/feature-flags/registry";
import { Platform } from "react-native";
import { registerBackend } from "../backendRegistry";
import * as logger from "../logger";

const nativeConsole = global.console;

let isDev = false;

if (typeof __DEV__ !== "undefined") {
  isDev = __DEV__;
}

export const patchConsole = () => {
  global.console = {
    ...nativeConsole,
    ...logger,
    error: (message, ...args: any[]) => {
      if (
        typeof message === "string" &&
        /Support for defaultProps/.test(message)
      ) {
        return;
      }
      logger.error(message, ...args);
    }
  };
  logger.info("console.* methods are now being delegated to @gigsmart/roga");
};

export const consoleBackend = registerBackend(
  "console",
  {
    log: nativeConsole.log,
    info: nativeConsole.info,
    warn: nativeConsole.warn,
    error: nativeConsole.error,
    debug: nativeConsole.debug,
    trace: nativeConsole.trace
  },
  isDev
    ? FlagType.PINNED
    : Platform.OS === "web"
      ? FlagType.DEBUG
      : FlagType.DISABLED
);
