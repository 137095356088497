import { definePermission } from "../registry";

export default definePermission({
  name: "Photo Gallery Access",
  icon: "images",
  defaults: {
    preview: false,
    allowLimited: true,
    reason:
      "We need the ability to access your photo gallery so that you can select and upload images."
  }
});
