import React from "react";
import Button from "../atoms/Button";
import ButtonTag from "../atoms/ButtonTag";
import Stack from "../atoms/Stack";

export interface ButtonTab {
  label: string;
  value: string;
  count?: number;
}

interface Props {
  tabs: ButtonTab[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export default function ButtonTabs({ tabs, activeTab, setActiveTab }: Props) {
  return (
    <Stack horizontal size="compact">
      {tabs.map(({ label, value, count }) => {
        return (
          <Button
            key={value}
            testID={`${value}-btn`}
            label={label}
            outline={activeTab !== value}
            size="small"
            leftAccessory={
              count || count === 0 ? (
                <ButtonTag value={count} color="primary" />
              ) : undefined
            }
            onPress={() => setActiveTab(value)}
          />
        );
      })}
    </Stack>
  );
}
