import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import type { TableCol } from "../../layout";
import { Column, Row } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import SectionListCalendarCellContainer from "./SectionListCalendarCellContainer";
import SectionListCalendarHeaderDayContainer from "./SectionListCalendarHeaderDayContainer";
import SectionListCalendarHeaderWeekContainer from "./SectionListCalendarHeaderWeekContainer";
import SectionListCollapsibleContainer from "./SectionListCollapsibleContainer";
import SectionListEmptyItemContainer from "./SectionListEmptyItemContainer";
import SectionListItemContainer from "./SectionListItemContainer";
import SectionListSearchContainer from "./SectionListSearchContainer";
import { getPeriodDates } from "./helpers";

interface Props<T> {
  period: "day" | "week";
  date: DateTime;
  renderListItem: (item: T, isCollapsed: boolean) => JSX.Element | null;
  renderCalendarItem?: (
    item: T,
    date: DateTime,
    isCollapsed: boolean
  ) => JSX.Element | null;
  renderFooter?: () => JSX.Element | null;
  onPressAddNewCalendarItem: (item: T, date: DateTime) => void;
  cellHeight?: number;
  variant: "standard" | "collapsible";
  searchPlaceholder: string;
  searchTerm: string;
  onSearch: (text: string) => void;
  emptyContent?: JSX.Element | null;
  isEmpty?: boolean;
  tableWidth: number;
  dataCount: number;
}

export function useSectionListColumns<T>({
  period,
  date,
  renderListItem,
  renderCalendarItem,
  onPressAddNewCalendarItem,
  cellHeight,
  variant,
  searchPlaceholder,
  searchTerm,
  onSearch,
  emptyContent,
  isEmpty,
  tableWidth,
  renderFooter,
  dataCount
}: Props<T>) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const [collapsedItems, setCollapsedItems] = useState<Set<string>>(new Set());
  const weekDays = getPeriodDates("week", date);
  const columnWidth = tableWidth / (period === "week" ? 7 : 1);

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      collapsedRow: {
        height: getUnits(16),
        backgroundColor: getColor("black", "fill", { opacity: 0.08 })
      },
      expandedRow: {
        height: isEmpty ? "100%" : cellHeight
      },
      calendarItem: {
        width: "100%",
        borderRightWidth: 1,
        borderRightColor: getColor("divider", "fill")
      },
      collapsedCalendarCell: {
        height: getUnits(16),
        backgroundColor: getColor("input", "fill")
      },
      footerListItem: {
        backgroundColor: getColor("foreground", "fill"),
        borderBottomWidth: 1,
        borderBottomColor: getColor("divider", "fill"),
        borderTopWidth: 1,
        borderTopColor: getColor("divider", "fill")
      },
      footerCalendarCell: {
        borderRightWidth: 1,
        borderRightColor: getColor("divider", "fill"),
        borderBottomWidth: 1,
        borderBottomColor: getColor("divider", "fill"),
        borderTopWidth: 1,
        borderTopColor: getColor("divider", "fill"),
        height: 132,
        width: columnWidth
      }
    }),
    [variant, isSm]
  );

  const calendarDays = useMemo(
    () => getPeriodDates(period, date),
    [period, date]
  );

  const toggleItem = (itemKey: string) => {
    setCollapsedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(itemKey)) {
        newSet.delete(itemKey);
      } else {
        newSet.add(itemKey);
      }
      return newSet;
    });
  };

  const { left, columns } = useMemo(
    () => ({
      left: [
        {
          header: (
            <SectionListSearchContainer
              searchPlaceholder={searchPlaceholder}
              searchTerm={searchTerm}
              onSearch={onSearch}
            />
          ),
          sortable: false,
          minWidth: isSm ? 140 : 208,
          maxWidth: isSm ? 140 : 208,
          paddingVertical: "none",
          paddingHorizontal: "none",
          renderCell: (item: T, key: string) => {
            const isCollapsed = collapsedItems.has(key);
            const index = Number(key);
            const hasFooter = renderFooter && index === dataCount - 1;
            return isEmpty ? (
              <SectionListEmptyItemContainer index={index}>
                {emptyContent}
              </SectionListEmptyItemContainer>
            ) : (
              <>
                <Row
                  style={[
                    isCollapsed ? styles.collapsedRow : styles.expandedRow
                  ]}
                >
                  {variant === "collapsible" && (
                    <SectionListCollapsibleContainer
                      itemKey={key}
                      isCollapsed={isCollapsed}
                      onToggleCollapse={toggleItem}
                    />
                  )}
                  <SectionListItemContainer
                    item={item}
                    isCollapsed={isCollapsed}
                    renderListItem={renderListItem}
                    variant={variant}
                    index={index}
                  />
                </Row>
                {hasFooter && (
                  <Column style={styles.footerListItem}>
                    {renderFooter()}
                  </Column>
                )}
              </>
            );
          }
        }
      ] as TableCol<T>[],
      columns: Array.from({ length: period === "week" ? 7 : 1 }, (_, index) => {
        return {
          header:
            period === "day" ? (
              <SectionListCalendarHeaderDayContainer
                date={date}
                period={period}
              />
            ) : (
              <SectionListCalendarHeaderWeekContainer
                day={weekDays?.[index]?.day ?? ""}
                date={weekDays?.[index]?.date ?? ""}
                isToday={weekDays?.[index]?.isToday ?? false}
              />
            ),
          sortable: false,
          renderCell: (item: T, key: string) => {
            const isCollapsed = collapsedItems.has(key);
            const index = Number(key);
            const hasFooter = renderFooter && index === dataCount - 1;
            return (
              <>
                <Row
                  style={[
                    styles.calendarItem,
                    isCollapsed
                      ? styles.collapsedCalendarCell
                      : styles.expandedRow
                  ]}
                >
                  {isEmpty ? (
                    <Column />
                  ) : (
                    <SectionListCalendarCellContainer
                      item={item}
                      date={
                        calendarDays?.[index]?.actualDate ?? DateTime.local()
                      }
                      isCollapsed={isCollapsed}
                      key={key}
                      period={period}
                      onPressAddNewCalendarItem={onPressAddNewCalendarItem}
                      renderCalendarItem={renderCalendarItem}
                      variant={variant}
                    />
                  )}
                </Row>
                {hasFooter && <Column style={styles.footerCalendarCell} />}
              </>
            );
          },
          ...(isSm && {
            minWidth: 76
          }),
          maxWidth: columnWidth,
          grow: period === "day" ? 7 : 1,
          paddingVertical: "none",
          paddingHorizontal: "none"
        };
      }) as TableCol<T>[]
    }),
    [period, calendarDays, isSm, collapsedItems, styles, variant, weekDays]
  );

  return {
    left,
    columns
  };
}
