import React, { type RefObject, type ReactNode } from "react";
import { View } from "react-native";
import type RNMapView from "react-native-maps";
import { IsConstrainedProvider, Stack } from "../../atoms";
import { getSpaceUnits } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import MapZoomControl from "./MapZoomControl";

interface Props {
  topLeft?: ReactNode;
  topCenter?: ReactNode;
  topRight?: ReactNode;
  bottomLeft?: ReactNode;
  bottomCenter?: ReactNode;
  bottomRight?: ReactNode;
  id: string;
  minZoom: number;
  maxZoom: number;
  mapRef?: RefObject<RNMapView>;
  bottomControlPadding?: number;
}

export default function MapControls({
  topLeft,
  topCenter,
  topRight,
  bottomLeft,
  bottomCenter,
  bottomRight,
  id,
  minZoom,
  maxZoom,
  mapRef,
  bottomControlPadding = 0
}: Props) {
  const isLg = useMatchesViewport((media) => media.size.large.up);
  const styles = useStyles(
    () => ({
      row: {
        flexDirection: "row",
        position: "absolute",
        left: 0,
        right: 0,
        zIndex: 100,
        gap: getSpaceUnits("standard"),
        padding: getSpaceUnits("standard"),
        justifyContent: "space-between"
      },
      top: {
        alignItems: "flex-start",
        top: 0
      },
      bottom: {
        alignItems: "flex-end",
        bottom: bottomControlPadding
      },
      left: { flexShrink: 1, alignItems: "flex-start" },
      right: { flexShrink: 1, alignItems: "flex-end" },
      center: { flexShrink: 1 },

      leftRightLg: { flexGrow: 1, width: "15%" }
    }),
    [bottomControlPadding]
  );

  return (
    <IsConstrainedProvider value>
      <View style={[styles.row, styles.top]} pointerEvents="box-none">
        <View
          pointerEvents="box-none"
          style={[styles.left, isLg && styles.leftRightLg]}
        >
          {topLeft}
        </View>
        <View pointerEvents="box-none" style={styles.center}>
          {topCenter}
        </View>
        <View
          pointerEvents="box-none"
          style={[styles.right, isLg && styles.leftRightLg]}
        >
          {topRight}
        </View>
      </View>
      <View style={[styles.row, styles.bottom]} pointerEvents="box-none">
        <View
          pointerEvents="box-none"
          style={[styles.left, isLg && styles.leftRightLg]}
        >
          {bottomLeft}
        </View>
        <View pointerEvents="box-none" style={styles.center}>
          {bottomCenter}
        </View>
        {isLg && (
          <View
            pointerEvents="box-none"
            style={[styles.right, isLg && styles.leftRightLg]}
          >
            {bottomRight}
          </View>
        )}
        {isLg && (
          <MapZoomControl
            id={id}
            mapRef={mapRef}
            minZoom={minZoom}
            maxZoom={maxZoom}
          />
        )}
        {!isLg && (
          <Stack>
            <View style={styles.right}>
              <MapZoomControl
                id={id}
                mapRef={mapRef}
                minZoom={minZoom}
                maxZoom={maxZoom}
              />
            </View>
            <View
              pointerEvents="box-none"
              style={[styles.right, isLg && styles.leftRightLg]}
            >
              {bottomRight}
            </View>
          </Stack>
        )}
      </View>
    </IsConstrainedProvider>
  );
}
