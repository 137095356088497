import React, { type ReactNode } from "react";
import Column from "../quarks/Column";
import Icon, { type IconName } from "../quarks/Icon";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { type UseTransformFlexProps, useStyle } from "../style";

const spec = {
  sizes: {
    standard: 13,
    header: 11,
    tableHeader: 9
  },
  verticalSizes: {
    standard: 4,
    medium: 3,
    compact: 2,
    slim: 1,
    none: 0
  },
  variants: {
    none: 0,
    standard: 4,
    large: 8
  }
};

const alignmentsToFlex = {
  top: "flex-start",
  middle: "center"
} as const;

interface Props extends UseTransformFlexProps {
  icon?: IconName;
  alignIcon?: "top" | "middle";
  size?: keyof typeof spec.sizes;
  verticalSize?: keyof typeof spec.verticalSizes;
  variant?: keyof typeof spec.variants;
  fillWidth?: boolean;
  children: ReactNode;
}

export default function ContentRow({
  icon,
  alignIcon = "top",
  size = "standard",
  verticalSize = "slim",
  variant = "standard",
  children,
  ...flexProps
}: Props) {
  const viewStyle = useStyle(
    ({ getUnits }) => ({
      minHeight: getUnits(13),
      paddingRight: getUnits(spec.variants[variant]) + (icon ? getUnits(3) : 0),
      paddingLeft: getUnits(spec.variants[variant]) - (icon ? getUnits(2) : 0),
      paddingVertical: getUnits(spec.verticalSizes[verticalSize]),
      width: flexProps?.fill && icon ? undefined : "100%"
    }),
    [verticalSize, variant, size, icon]
  );

  const iconContainerStyle = useStyle(({ getUnits }) => ({
    width: getUnits(10)
  }));

  if (typeof children === "string") children = <Text>{children}</Text>;

  return (
    <Row style={viewStyle} {...flexProps}>
      {icon && (
        <Column
          alignItems="center"
          alignSelf={alignmentsToFlex[alignIcon]}
          style={iconContainerStyle}
        >
          <Icon variant="solid" name={icon} size="small" color="primary" />
        </Column>
      )}
      {children}
    </Row>
  );
}

ContentRow.defaultProps = {
  alignIcon: "middle"
};
