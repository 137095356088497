import React, { useRef, useEffect } from "react";
import { Animated } from "react-native";
import { useStyles, useTheme } from "../style";
import type { Color } from "../style/theme/colors";
import Pressable from "./Pressable";

interface Props {
  color?: Color;
  strokeSize?: number;
  size?: number;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
  testID: string;
  eventTargetName: string | null;
  disabled?: boolean;
}

export default function Radio({
  color = "primary",
  strokeSize = 2,
  size = 5,
  selected = false,
  onSelect,
  testID,
  disabled,
  eventTargetName
}: Props) {
  const { getUnits } = useTheme();
  const sizePx = getUnits(size);
  const innerCircleSize = sizePx - strokeSize * 4;
  const sizeAnim = useRef(
    new Animated.Value(selected ? innerCircleSize : 0)
  ).current;
  const styles = useStyles(
    ({ getColor }) => {
      const computedColor = getColor(color, "fill");
      return {
        wrapper: {
          backgroundColor: "#fff",
          width: sizePx,
          height: sizePx,
          borderRadius: sizePx / 2,
          borderWidth: strokeSize,
          padding: strokeSize,
          borderColor: computedColor,
          alignItems: "center",
          justifyContent: "center"
        },
        dot: { backgroundColor: computedColor },
        disabled: { opacity: 0.3 }
      };
    },
    [sizePx, innerCircleSize, strokeSize]
  );

  useEffect(() => {
    Animated.timing(sizeAnim, {
      toValue: selected ? innerCircleSize : 0,
      useNativeDriver: false,
      duration: 100
    }).start();
  }, [selected, sizeAnim, innerCircleSize]);

  return (
    <Pressable
      style={[styles.wrapper, disabled && styles.disabled]}
      eventEntityType="Radio"
      eventTargetName={eventTargetName}
      onPress={() => onSelect?.(!selected)}
      hitSlop={{ top: 24, right: 24, bottom: 24, left: 24 }}
      testID={testID}
      disabled={disabled}
    >
      <Animated.View
        testID={selected ? "radio-selected-circle" : "radio-deselected-circle"}
        style={[
          styles.dot,
          {
            width: sizeAnim,
            height: sizeAnim,
            borderRadius: Animated.divide(sizeAnim, 2)
          }
        ]}
      />
    </Pressable>
  );
}
