import type { ComponentPropsWithDefaults } from "@gigsmart/type-utils";
import React, {
  useCallback,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef
} from "react";
import { Platform, TextInput } from "react-native";
import type { TextStyleProp } from "../../style";

export type Props = ComponentPropsWithDefaults<typeof TextInput> & {
  style?: TextStyleProp;
  normalize?: (arg0: string) => string;
  format?: (arg0: string) => string;
  legacyImplementation?: boolean;
  mask?: string; // unused prop
};

export interface BaseTextInputHandlerType {
  focus: () => void;
  isFocused: () => boolean;
  blur: () => void;
}

const passthrough = (fn: ((str: string) => string) | undefined, str: string) =>
  fn ? fn(str) : str;

const BaseTextInput = forwardRef(
  (
    {
      value = "",
      onChangeText,
      normalize,
      format,
      legacyImplementation,
      ...inputProps
    }: Props,
    ref
  ) => {
    const inputRef = useRef<TextInput>(null);
    const lastCompValue = useRef<string | null | undefined>("");
    const nativeBindValue =
      !!legacyImplementation ||
      Platform.OS === "web" ||
      !!normalize ||
      !!format;

    const handleTextChange = useCallback(
      (newValue: string) => {
        lastCompValue.current = newValue;
        onChangeText?.(passthrough(normalize, newValue));
      },
      [normalize, onChangeText]
    );

    useEffect(() => {
      if (nativeBindValue) return;

      const fmtValue = passthrough(format, value);
      const shouldUpdateNative = fmtValue !== lastCompValue.current;
      if (shouldUpdateNative && inputRef.current) {
        if (typeof inputRef.current.setNativeProps !== "undefined") {
          inputRef.current.setNativeProps({ text: fmtValue });
        }
        lastCompValue.current = fmtValue;
      }
    }, [format, nativeBindValue, value]);

    useImperativeHandle(ref, () => ({
      focus: () => inputRef?.current?.focus(),
      isFocused: () => inputRef?.current?.isFocused(),
      blur: () => inputRef?.current?.blur()
    }));

    return (
      <TextInput
        ref={inputRef}
        allowFontScaling={false}
        onChangeText={handleTextChange}
        value={nativeBindValue ? passthrough(format, value ?? "") : undefined}
        {...inputProps}
      />
    );
  }
);

export default BaseTextInput;
