import type { AppMenuItem, ParamListBase } from "@gigsmart/kaizoku";
import React from "react";
import { View } from "react-native";
import { BottomNavItem } from "../molecules";
import { isActive, isHighlighted } from "../molecules/SidenavItem";
import { getSpaceUnits, useSafeAreaInsets } from "../quarks";
import { useMatchesViewport, useStyles } from "../style";

interface Props<ParamList extends ParamListBase = ParamListBase> {
  items?: Array<AppMenuItem<ParamList>>;
  onPressItem?: (item: AppMenuItem<ParamList>) => void;
  active?: keyof ParamList;
}

export default function AppBottomTabs({ items, onPressItem, active }: Props) {
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const { bottom } = useSafeAreaInsets();
  const styles = useStyles(
    ({ getColor }) => ({
      container: {
        backgroundColor: getColor("surface", "fill"),
        borderTopColor: "#eee",
        borderTopWidth: 1,
        paddingBottom: Math.max(bottom - 12, 0),
        paddingHorizontal: getSpaceUnits("slim"),
        zIndex: 10
      },
      bottomTab: {
        width: "100%",
        maxWidth: 480,
        alignSelf: "center",
        flexDirection: "row"
      }
    }),
    [bottom]
  );

  const renderBottomItem = (item: AppMenuItem, idx: number) => (
    <BottomNavItem
      key={item === "separator" ? `sep_${idx}` : item.testID ?? item.title}
      item={item}
      active={isActive(item, active)}
      highlighted={isHighlighted(item)}
      onPress={onPressItem}
    />
  );

  return isMd ? null : (
    <View style={styles.container} testID="app-bottom-tabs">
      <View style={styles.bottomTab}>{items?.map(renderBottomItem)}</View>
    </View>
  );
}
