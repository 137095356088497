import * as Immutable from "immutable";
import React, {
  useContext,
  useCallback,
  useLayoutEffect,
  type ReactNode,
  type ComponentType,
  type FunctionComponent,
  useMemo,
  type PropsWithChildren,
  useState
} from "react";
import { hash } from "./helpers";

let idInc = 1000;

export function createPropsPortal<PortalProps extends object>(
  defaultProps: PortalProps
): {
  Entrance: FunctionComponent<PortalProps>;
  Exit: FunctionComponent<{ children: (props: PortalProps) => ReactNode }>;
  Realm: ComponentType<{ children: ReactNode }>;
  useEntrance: (props: PortalProps, active?: boolean) => void;
  useExit: () => PortalProps;
  usePortal: (id?: number) => {
    inRealm: boolean;
    update: (props: PortalProps) => void;
    remove: () => void;
    id: string;
  };
} {
  type Updater = (id: string, props: PortalProps) => void;
  type Remover = (id: string) => void;
  type Registry = Immutable.OrderedMap<string, PortalProps>;
  interface ContextShape {
    readonly inRealm: boolean;
    readonly update: Updater;
    readonly remove: Remover;
    readonly props: PortalProps;
  }

  const PortalContext = React.createContext<ContextShape>({
    update: () => {
      console.warn(new Error("Entrance is outside a realm"));
    },
    remove: () => {
      console.warn(new Error("Entrance is outside a realm"));
    },
    props: { ...defaultProps },
    inRealm: false
  });

  /*
  The portal realm provides a place in the component tree where portals can
  be created with both exits an entrances. Exits created outside the realm will
  only render the default props, entrances rendered outside the realm will throw
  an error
  */
  function PortalRealm({ children }: PropsWithChildren) {
    const [registry, setRegistry] = useState<Registry>(() =>
      Immutable.OrderedMap<string, PortalProps>()
    );
    const handleUpdate: Updater = useCallback(
      (id, props) => setRegistry((registry) => registry.set(id, props)),
      []
    );
    const handleRemove: Remover = useCallback(
      (id) => setRegistry((registry) => registry.remove(id)),
      []
    );

    const value = useMemo((): ContextShape => {
      // Combine all the props, thus allowing for single prop overrides
      const combinedProps = { ...defaultProps };
      registry.forEach((props) => {
        Object.entries(props ?? {}).forEach(([key, value]) => {
          if (typeof value !== "undefined") {
            Object.assign(combinedProps, { [key]: value });
          }
        });
      });

      return {
        inRealm: true,
        props: combinedProps,
        remove: handleRemove,
        update: handleUpdate
      };
    }, [registry]);

    return (
      <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
    );
  }

  const usePortal = (customId?: number) => {
    const id = useMemo(() => {
      const id = customId ?? idInc++;
      return id.toString(36);
    }, [customId]);
    const {
      update: contextUpdate,
      remove: contextRemove,
      inRealm
    } = useContext(PortalContext);

    const remove = useCallback(() => contextRemove(id), [contextRemove, id]);

    const update = useCallback(
      (props: PortalProps) => contextUpdate(id, props),
      [contextUpdate, id]
    );

    return { update, remove, id, inRealm };
  };

  /*
  The portal entrance is where props can be passed to be rendered in the
  portal's exit.
  */
  const useEntrance = (props: PortalProps, active = true) => {
    const { update, remove } = usePortal();
    useLayoutEffect(() => {
      if (!active) return;
      return update(props);
    }, [hash(props), update]);
    useLayoutEffect(() => {
      return remove;
    }, [remove]);
  };

  const PortalEntrance = (props: PortalProps) => {
    useEntrance(props);
    return null;
  };

  PortalEntrance.defaultProps = {
    ...defaultProps
  };

  /*
  The portal exit renders the props passed by the deepest nested portal
  entrance.
  */
  interface ExitProps {
    children: (arg0: PortalProps) => ReactNode;
  }

  const useExit = () => {
    const { props } = useContext(PortalContext);
    return props;
  };

  const PortalExit: FunctionComponent<ExitProps> = ({
    children
  }: ExitProps) => {
    return <>{children(useExit())}</>;
  };

  return {
    Entrance: PortalEntrance,
    Exit: PortalExit,
    Realm: PortalRealm,
    usePortal,
    useEntrance,
    useExit
  };
}
