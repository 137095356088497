import React, {
  type ReactNode,
  type ReactText,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { type LayoutChangeEvent, View } from "react-native";
import { Pressable, Text } from "../quarks";
import type { TextProps } from "../quarks/Text";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";
import type { ChildrenProp } from "../utils/types";

type Props = TextProps & {
  numberOfLines: number;
  children?: ChildrenProp<ReactNode | ReactText | null | undefined>;
  collapsibleTextColor?: Color;
  collapsibleTextVariant?: TextProps["variant"];
  expandText?: string;
  collapseText?: string;
  testID: string;
};

export default function CollapsibleText({
  numberOfLines,
  children,
  collapsibleTextColor = "neutral",
  collapsibleTextVariant,
  expandText = "Show More",
  collapseText = "Show Less",
  variant,
  testID,
  ...rest
}: Props) {
  const expandedLayoutHeight = useRef(0);
  const collapsedLayoutHeight = useRef(0);
  const collapsedLayoutWidth = useRef<undefined | number>(undefined);
  const [canExpand, setCanExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const styles = useStyles(() => {
    return {
      fullText: {
        position: "absolute",
        opacity: 0,
        top: 0,
        left: 0,
        width: collapsedLayoutWidth.current
      }
    };
  }, [collapsedLayoutWidth.current]);
  const handleExpandedLayout = useCallback((e: LayoutChangeEvent) => {
    if (!e.nativeEvent) return;
    expandedLayoutHeight.current = e.nativeEvent.layout.height;
    if (
      collapsedLayoutHeight.current < expandedLayoutHeight.current &&
      collapsedLayoutHeight.current > 0
    ) {
      setCanExpand(true);
    }
  }, []);

  const handleCollapsedLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!e.nativeEvent) return;
      const { width, height } = e.nativeEvent.layout;
      collapsedLayoutHeight.current = height;
      collapsedLayoutWidth.current = width;
      if (collapsedLayoutHeight.current < expandedLayoutHeight.current) {
        setCanExpand(true);
      } else {
        if (!expanded) setCanExpand(false);
      }
    },
    [expanded]
  );

  useEffect(() => {
    if (canExpand && expanded && !numberOfLines) {
      setCanExpand(false);
      setExpanded(false);
    }
  }, [canExpand, expanded, numberOfLines]);

  return (
    <View>
      <View style={styles.fullText} onLayout={handleExpandedLayout}>
        <Text numberOfLines={undefined} variant={variant} {...rest}>
          {children}
        </Text>
      </View>
      <View onLayout={handleCollapsedLayout}>
        <Text
          numberOfLines={expanded ? undefined : numberOfLines}
          variant={variant}
          {...rest}
        >
          {children}
        </Text>
      </View>
      {canExpand && !!numberOfLines && (
        <Pressable
          testID={`${testID}-expand-collapse-btn`}
          eventEntityType="collapisble-text"
          eventTargetName="Collapsible Text"
          onPress={() => setExpanded(!expanded)}
        >
          <Text
            {...rest}
            italic={false}
            color={collapsibleTextColor}
            variant={collapsibleTextVariant ?? variant}
          >
            {expanded ? collapseText : expandText}
          </Text>
        </Pressable>
      )}
    </View>
  );
}
