import type { DateTime } from "luxon";
import React from "react";

import { ContentArea } from "../../atoms";
import { Icon, Pressable, Row, Text } from "../../quarks";

interface Props {
  testID: string;
  min?: DateTime;
  max?: DateTime;
  month: DateTime;
  onSetMonth: (delta: number) => void;
}

export default function MonthSelector({
  testID,
  min,
  max,
  month,
  onSetMonth
}: Props) {
  const isPrevMonthDisabled = !!min && min >= month.startOf("month");
  const isNextMonthDisabled = !!max && max < month.endOf("month");
  const handlePrevMonthPress = () => onSetMonth(-1);
  const handleNextMonthPress = () => onSetMonth(1);

  return (
    <ContentArea variant="none" size="compact" constraint="mini">
      <Row justifyContent="space-evenly" alignItems="center">
        <Pressable
          testID={`${testID}-month-prev`}
          eventEntityType="date-picker-month-prev"
          eventTargetName="Date Picker Prev Month Button"
          disabled={isPrevMonthDisabled}
          onPress={handlePrevMonthPress}
        >
          <Icon
            name="chevron-left"
            color={isPrevMonthDisabled ? "neutral" : "primary"}
          />
        </Pressable>
        <Text variant="header" weight="bold" color="primary" align="center">
          {month.toFormat("MMMM yyyy")}
        </Text>
        <Pressable
          testID={`${testID}-month-next`}
          eventEntityType="date-picker-month-next"
          eventTargetName="Date Picker Next Month Button"
          disabled={isNextMonthDisabled}
          onPress={handleNextMonthPress}
        >
          <Icon
            name="chevron-right"
            color={isNextMonthDisabled ? "neutral" : "primary"}
          />
        </Pressable>
      </Row>
    </ContentArea>
  );
}
