import {
  type DependencyList,
  useDebugValue,
  useEffect,
  useMemo,
  useState
} from "react";
import { type GraphQLTaggedNode, requestSubscription } from "react-relay";
import {
  type DeclarativeMutationConfig,
  type OperationType,
  type SelectorStoreUpdater,
  getRequest
} from "relay-runtime";
import { useRelayOrchestrator } from "../orchestrator";
import { logger as baseLogger } from "./logger";

const emptyDeps = Object.freeze([]);

export type SubscriptionSpec<TOperation extends OperationType> = TOperation;
export type SubscriptionVariables<TOperation extends OperationType> =
  TOperation["variables"];
export type SubscriptionResponse<TOperation extends OperationType> =
  TOperation["response"];

export interface RelaySubscriptionOptions<TOperation extends OperationType> {
  onCompleted?: () => void;
  onError?: (error: Error) => void;
  onNext?: (response: TOperation["response"] | null | undefined) => void;
  updater?: SelectorStoreUpdater<TOperation["response"]>;
  configs?: DeclarativeMutationConfig[];
  subscribe?: boolean;
}

export function useRelaySubscription<TOperation extends OperationType>(
  subscription: GraphQLTaggedNode,
  variables: TOperation["variables"],
  {
    subscribe = true,
    onNext,
    ...options
  }: RelaySubscriptionOptions<TOperation> = {},
  deps: DependencyList = emptyDeps
): { ready: boolean | null | undefined; name: string } {
  const [completedAt, setCompletedAt] = useState<Date | null>(null);
  const name = useMemo(() => {
    const req = getRequest(subscription);
    const selections = req.operation.selections;
    const firstSelection = selections[0];
    const operationName = req.operation.name;
    const subscriptionName = (firstSelection as any)?.name;
    return operationName ?? subscriptionName;
  }, [subscription]);
  const logger = useMemo(() => baseLogger.createLogger(`!(${name})`), [name]);
  useDebugValue(name);
  const [ready, setReady] = useState<boolean | null | undefined>(null);
  const {
    __UNSAFE_registerSubscriber,
    __UNSAFE_deregisterSubscriber,
    onSubscriptionNext,
    environment,
    enableSubscriptions
  } = useRelayOrchestrator();

  // Track the subscriber
  useEffect(() => {
    __UNSAFE_registerSubscriber(setReady);
    return () => {
      __UNSAFE_deregisterSubscriber(setReady);
    };
  }, [__UNSAFE_registerSubscriber, __UNSAFE_deregisterSubscriber]);

  // Invoke the subscription
  const variablesJSON = JSON.stringify(variables);
  const optionsJSON = JSON.stringify(options);
  useEffect(() => {
    if (!enableSubscriptions || !subscribe) return;
    logger.info("subscribed", "\n", variables);
    const activeSubscription = requestSubscription(environment, {
      subscription,
      variables,
      onCompleted: () => {
        logger.info("onCompleted", "\n", variables);
        setCompletedAt(new Date());
        options.onCompleted?.();
      },
      onError: (err) => {
        logger.error("onError", "\n", {
          variables,
          error: err
        });
      },
      onNext: async (response) => {
        logger.info("onNext", "\n", {
          variables,
          response
        });

        await onSubscriptionNext?.(response, { subscription, variables });
        onNext?.(response);
      },
      ...options
    });

    return () => {
      activeSubscription.dispose();
      logger.info("unsubscribed", "\n", variables);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    environment,
    enableSubscriptions,
    subscribe,
    subscription,
    variablesJSON,
    optionsJSON,
    completedAt,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...deps
  ]);
  return { ready, name };
}
