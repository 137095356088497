import React, { useEffect, useRef, useState } from "react";
import { type LayoutChangeEvent, View } from "react-native";
import YoutubePlayer from "react-native-youtube-iframe";

import { Text } from "../../quarks";
import { useStyles } from "../../style";
import VideoComponent from "./VideoComponent";

interface Props {
  source: "youtube" | "url";
  sourceUrl?: string;
  videoId?: string;
  ratio?: keyof typeof RATIO;
}

const RATIO = {
  "16:9": 9 / 16,
  "4:3": 3 / 4
};

export default function VideoPlayer({
  ratio: r = "16:9",
  source,
  sourceUrl,
  videoId
}: Props) {
  const width = useRef(0);
  const [height, setHeight] = useState(0);

  const styles = useStyles(({ getColor }) => ({
    unsupported: {
      borderWidth: 1,
      borderColor: getColor("danger", "fill"),
      justifyContent: "center",
      minHeight: 40
    },
    unsupportedLabel: {
      color: getColor("danger", "fill"),
      textAlign: "center"
    },
    urlVideo: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    }
  }));

  const updateHeight = () => {
    if (!width.current) return;
    setHeight(width.current * RATIO[r]);
  };

  const handleLayout = (e: LayoutChangeEvent) => {
    if (!e.nativeEvent) return;
    width.current = e.nativeEvent.layout.width;
    updateHeight();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateHeight(), [r]);

  const content =
    source === "youtube" && videoId ? (
      <YoutubePlayer key={`yt:${videoId}`} height={height} videoId={videoId} />
    ) : source === "url" && sourceUrl ? (
      <VideoComponent style={styles.urlVideo} source={{ uri: sourceUrl }} />
    ) : null;

  return (
    <View
      onLayout={handleLayout}
      style={[!content && styles.unsupported, { height }]}
    >
      {content ?? (
        <Text style={styles.unsupportedLabel}>Unsupported video format</Text>
      )}
    </View>
  );
}
