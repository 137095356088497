import {
  type ParamListBase,
  type StackActionHelpers,
  type StackNavigationState,
  StackRouter,
  type StackRouterOptions,
  createNavigatorFactory,
  type getPathFromState,
  useNavigationBuilder
} from "@react-navigation/native";
import type { NativeStackNavigatorProps } from "@react-navigation/native-stack/lib/typescript/src/types";
import React, { useLayoutEffect, type ComponentType } from "react";
import { Platform } from "react-native";
import { registerNavigation } from "./cache";
import { useGetPathFromState } from "./helpers";
import type {
  AppLayoutProps,
  AppNavigationEventMap,
  AppNavigationOptions
} from "./types";

const RootStackRouter =
  (toPath: typeof getPathFromState) => (options: StackRouterOptions) => {
    const base = StackRouter(options);
    const router: typeof base = {
      ...base,
      getStateForAction: (state, action, options) => {
        switch (action.type) {
          case "PUSH": {
            const params: any = action.payload.params;
            const nextState = base.getStateForAction(state, action, options);

            if (
              Platform.OS === "web" &&
              nextState &&
              params?.target === "_blank"
            ) {
              const path = toPath(nextState);
              const url = `${window.location.origin}${path}`;
              window.open(url, "_blank");
              return null;
            }

            return nextState;
          }
          default:
            return base.getStateForAction(state, action, options);
        }
      }
    };

    return router;
  };

type Props = NativeStackNavigatorProps &
  Omit<AppLayoutProps, "state" | "descriptors" | "navigation"> & {
    LayoutComponent: ComponentType<AppLayoutProps>;
  };

function RootStackNavigator({
  id,
  initialRouteName,
  children,
  screenListeners,
  screenOptions,
  LayoutComponent,
  ...rest
}: Props) {
  const toPath = useGetPathFromState();
  const { state, descriptors, navigation, NavigationContent } =
    useNavigationBuilder<
      StackNavigationState<ParamListBase>,
      StackRouterOptions,
      StackActionHelpers<ParamListBase>,
      AppNavigationOptions,
      AppNavigationEventMap
    >(RootStackRouter(toPath as typeof getPathFromState), {
      id,
      initialRouteName,
      children,
      screenListeners,
      screenOptions,
      defaultScreenOptions: { headerShown: false }
    });

  const curRoute = state.routes[state.index];
  const { render, options } = (curRoute && descriptors[curRoute.key]) || {};

  useLayoutEffect(() => {
    if (!id) return;
    return registerNavigation(id, navigation);
  }, []);

  return (
    <NavigationContent>
      {options?.layoutShown === false ? (
        render?.()
      ) : (
        <LayoutComponent
          {...rest}
          state={state}
          navigation={navigation}
          descriptors={descriptors}
        />
      )}
    </NavigationContent>
  );
}

export const createRootStackNavigator = createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  AppNavigationOptions,
  AppNavigationEventMap,
  typeof RootStackNavigator
>(RootStackNavigator);
