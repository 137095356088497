import React, { type ComponentProps, type ReactElement } from "react";
import { View } from "react-native";
import type { InfoModal } from "../organisms";
import { Text } from "../quarks";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";
import ListRow from "./ListRow";
import Sticker from "./Sticker";

interface Props {
  color: Color;
  label: string;
  icon: ComponentProps<typeof Sticker>["icon"];
  right?: ReactElement<typeof InfoModal>;
  bordered?: boolean;
}

const StickerHeader = ({ color, label, icon, bordered, right }: Props) => {
  const styles = useStyles(
    ({ getColor }) => ({
      container: {
        backgroundColor: getColor(color, "fill", { opacity: 0.08 })
      },
      bordered: {
        borderWidth: 1,
        borderColor: getColor("divider", "fill"),
        marginBottom: -1
      }
    }),
    [color]
  );
  return (
    <View style={[styles.container, bordered && styles.bordered]}>
      <ListRow
        spacing="small"
        left={<Sticker color={color} icon={icon} />}
        right={right}
        label={
          <Text weight="bold" color={color}>
            {label}
          </Text>
        }
      />
    </View>
  );
};

export default StickerHeader;
