import React, { type ReactNode } from "react";
import { View } from "react-native";

import { useStyles } from "../../style";
import type { Color } from "../../style/theme/colors";

const sizeSpecs = {
  xs: {
    paddingHorizontal: 4,
    flexDirection: "row",
    alignItems: "center",
    height: 20
  },
  sm: { padding: 4 },
  md: { padding: 8 },
  lg: { borderLeftWidth: 8 }
} as const;

interface Props {
  color: Color;
  dim?: boolean;
  size?: keyof typeof sizeSpecs;
  children?: ReactNode;
}

export default function CalendarEvent({
  size = "xs",
  color,
  dim,
  children
}: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      container: {
        borderRadius: 4,
        backgroundColor: getColor(color, "fill", { opacity: 0.08 }),
        opacity: dim ? 0.5 : 1,
        borderColor: getColor(color, "fill"),
        ...sizeSpecs[size]
      }
    }),
    [color, dim, size]
  );

  return <View style={styles.container}>{children}</View>;
}
