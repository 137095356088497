import React from "react";
import Stack from "../../atoms/Stack";
import Text from "../../quarks/Text";

interface Props {
  id?: string | null;
  body?: string | null;
  sentAt?: string | null;
}

export default function SystemMessage({ id, body, sentAt }: Props) {
  return (
    <Stack alignItems="center" testID={"user-message"} size="slim">
      <Text color="primary" weight="bold">
        {body}
      </Text>
      <Text variant="note" color="neutral" testID={"user-message-sent-at"}>
        {sentAt}
      </Text>
    </Stack>
  );
}
