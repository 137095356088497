import md5 from "md5";
import {
  type ConcreteBatch,
  type RelayNetworkLayerRequest,
  RelayNetworkLayerRequestBatch
} from "react-relay-network-modern/es";
import { createRawMiddleware } from "../middleware";

function rebuildRequest(
  req: RelayNetworkLayerRequest,
  operation: ConcreteBatch
) {
  const nextReq = req.clone();
  nextReq.operation = operation;
  if (operation.id) nextReq.id = operation.id;
  nextReq.fetchOpts.body = nextReq.prepareBody();
  return nextReq;
}

export default createRawMiddleware((next) => async (req) => {
  if (req instanceof RelayNetworkLayerRequestBatch || !req.operation.text) {
    return await next(req);
  }
  const id: string | null =
    req.operation.id ??
    req.operation.cacheID ??
    (req.operation.text && md5(req.operation.text));
  const response = await next(
    rebuildRequest(req, {
      ...req.operation,
      id,
      text: null,
      metadata: {
        ...req.operation.metadata,
        originalText: req.operation.text
      }
    })
  );
  switch (response.status) {
    case 428:
      return await next(rebuildRequest(req, { ...req.operation, id }));
    default:
      return response;
  }
});
