import React from "react";
import { Modal, Pressable as RNPressable, View } from "react-native";
import ContentArea from "../../atoms/ContentArea";
import Stack from "../../atoms/Stack";
import Column from "../../quarks/Column";
import Pressable from "../../quarks/Pressable";
import Spacer from "../../quarks/Spacer";
import Text from "../../quarks/Text";
import { useStyles } from "../../style";
import type { ActionSheetProps as Props } from "./types";

export default function MobileActionSheet({
  visible,
  onClose,
  autoClose,
  options
}: Props) {
  const styles = useStyles(({ getColor, getUnits }) => ({
    overlay: {
      flex: 1,
      backgroundColor: getColor("#000000C0", "fill", { opacity: 0.5 })
    },
    outerContainer: {
      flex: 1,
      justifyContent: "flex-end",
      padding: getUnits(4)
    },
    buttonContainer: {
      backgroundColor: getColor("surface", "fill"),
      borderRadius: getUnits(2)
    },
    pressed: { opacity: 0.8 }
  }));
  return (
    <Modal
      visible={visible}
      animationType="none"
      transparent
      onRequestClose={onClose}
    >
      <RNPressable onPress={onClose} style={styles.overlay}>
        <View style={styles.outerContainer}>
          <View style={styles.buttonContainer}>
            <Stack variant="divider">
              {options.map((option) => (
                <Pressable
                  key={option.label}
                  onPress={() => {
                    option.onPress?.();
                    if (autoClose && onClose) onClose();
                  }}
                  eventEntityType="ActionSheetButton"
                  eventTargetName="Action Sheet Button"
                  testID={option.testID}
                  style={({ pressed }) => pressed && styles.pressed}
                  disabled={option.disabled}
                >
                  <Column alignItems="center" justifyContent="center">
                    <ContentArea>
                      <Text
                        variant="header"
                        color={
                          option.destructive
                            ? "danger"
                            : option.disabled
                              ? "disabled"
                              : "primary"
                        }
                        weight="normal"
                      >
                        {option.label}
                      </Text>
                    </ContentArea>
                  </Column>
                </Pressable>
              ))}
            </Stack>
          </View>
          <Spacer />
          <Column
            style={styles.buttonContainer}
            justifyContent="center"
            alignItems="center"
          >
            <Pressable
              testID="close-btn"
              onPress={onClose}
              eventEntityType="ActionSheetButton"
              eventTargetName="Action Sheet Close Button"
              style={({ pressed }) => pressed && styles.pressed}
            >
              <ContentArea>
                <Text variant="header" color="primary" weight="bold">
                  Cancel
                </Text>
              </ContentArea>
            </Pressable>
          </Column>
        </View>
      </RNPressable>
    </Modal>
  );
}
