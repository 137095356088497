import React, { forwardRef, type ReactNode } from "react";
import { View } from "react-native";
import { type ViewStyle, useStyle } from "../style";

interface Props {
  children: ReactNode;
  style?: ViewStyle;
}

const FullWidthContainer = forwardRef<View, Props>(
  ({ style, children }, ref) => {
    const fullWidthStyle = useStyle(() => ({ width: "100%" }));
    return (
      <View ref={ref} style={[style, fullWidthStyle]}>
        {children}
      </View>
    );
  }
);

export default FullWidthContainer;
