import React from "react";

import Svg, { Path, RadialGradient, Stop, Text } from "react-native-svg";
import AtlasText from "../quarks/Text";
import type { Color } from "../style/theme/colors";

const uuidGenerator = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; // Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = ((d + r) % 16) | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = ((d2 + r) % 16) | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const roundPoint = (number: number): number => Math.round(number * 10) / 10;

const createCurves = (x1: number, y1: number, x2: number, y2: number) =>
  ` C${roundPoint((x2 + x1) / 2)},${y1} ` +
  `${roundPoint((x2 + x1) / 2)},${y2} ${x2},${y2}`;

interface Props {
  data: number[];
  height: number;
  width: number;
  labels: string[];
  color?: Color;
}

export default function FunnelChart({
  data,
  height,
  width,
  labels,
  color = "#003b5c"
}: Props) {
  if (data.length !== labels.length) {
    return (
      <AtlasText color="error">
        Your data and labels length must match
      </AtlasText>
    );
  }
  if (data.length === 0) {
    return (
      <AtlasText color="error">You must provide data to the Chart</AtlasText>
    );
  }
  const uuid = uuidGenerator();
  const chartWidth = width;
  const chartHeight = height * 0.7;
  const halfHeight = chartHeight / 2;
  const coords = generateSegments(data, chartHeight, chartWidth);

  const paths = [];
  for (let index = 0; index < coords.length - 1; index++) {
    const path = createCurves(
      coords[index]?.[0] ?? 0,
      coords[index]?.[1] ?? 0,
      coords[index + 1]?.[0] ?? 0,
      coords[index + 1]?.[1] ?? 0
    );
    paths.push(path);
  }
  const segmentLength = chartWidth / data.length;
  const segmentHalf = segmentLength / 2;

  return (
    <Svg height={height} width={width}>
      <RadialGradient
        id={`Gradient-${uuid}`}
        gradientUnits="userSpaceOnUse"
        cx={-(width / 4)}
        cy={halfHeight}
        r={width / 2}
      >
        <Stop offset="0%" stopColor={color} stopOpacity="50%" />
        <Stop offset="100%" stopColor={color} stopOpacity="100%" />
      </RadialGradient>
      <Path fill={`url(#Gradient-${uuid})`} d={`M 0 0 ${paths.join(" ")} Z`} />
      {data.map((d, index) => {
        const yMod = chartHeight * 0.25;
        return (
          <React.Fragment key={index}>
            {![0, data.length].includes(index) && (
              <Path
                d={`M ${(chartWidth / data.length) * index} 0 L ${
                  (chartWidth / data.length) * index
                } ${chartHeight}`}
                fill="none"
                stroke="#000"
                strokeWidth="1"
              />
            )}
            <Text
              id={`data[${labels[index]}]`}
              x={segmentLength * index + segmentHalf}
              y={chartHeight + yMod}
              fontFamily="Verdana"
              fontSize="10"
              fontWeight="bold"
              textAnchor="middle"
            >
              {d.toLocaleString()}
            </Text>
            <Text
              id={`label[${labels[index]}]`}
              x={segmentLength * index + segmentHalf}
              y={chartHeight + 1.5 * yMod}
              fontFamily="Verdana"
              fontSize="10"
              fontWeight="bold"
              textAnchor="middle"
            >
              {labels[index]}
            </Text>
          </React.Fragment>
        );
      })}
    </Svg>
  );
}

/*
  This function generates the coordinates used to draw the path of the bounded chart area.
*/
export const generateSegments = (
  data: number[],
  chartHeight: number,
  chartWidth: number
) => {
  const halfHeight = chartHeight / 2;
  /*
      A funnel segment is draw in a clockwise direction.
      Path 1-2 is drawn,
      then connected with a straight vertical line 2-3,
      then a line 3-4 is draw (going in backwards direction)
      then path is closed (connected with the starting point 1).
      1---------->2
      ^           |
      |           v
      4<----------3
  */
  const coords = [[0, 0]]; // Chart will always start at point 1 (top left) which is 0,0

  for (let index = 1; index < data.length; index++) {
    const segmentLength = chartWidth / data.length;
    const x = segmentLength * index;
    const y = halfHeight * (1 - Number(data[index]) / Number(data[0]));
    coords.push([x, y]);
  }

  // These are the right side bounding points
  coords.push([
    chartWidth,
    halfHeight * (1 - Number(data[data.length - 1]) / Number(data[0]))
  ]);
  coords.push([
    chartWidth,
    halfHeight * (1 + Number(data[data.length - 1]) / Number(data[0]))
  ]);

  // Reverse through your data
  for (let index = data.length - 1; index >= 0; index--) {
    const segmentLength = chartWidth / data.length;
    const x = segmentLength * index;
    const y = halfHeight * (1 + Number(data[index]) / Number(data[0]));
    coords.push([x, y]);
  }

  return coords;
};
