import React, { type ReactNode } from "react";
import type { IconName } from "../quarks";
import FancyHeader from "./FancyHeader";
import LoadingView from "./LoadingView";
import Surface from "./Surface";

type Props = {
  icon: IconName;
  title?: string | ReactNode;
  note?: string | ReactNode;
};

export default function LoadingCard({ icon, title, note }: Props) {
  return (
    <Surface>
      <FancyHeader icon={icon} title={title} note={note} />
      <LoadingView />
    </Surface>
  );
}
