import React, { type ReactNode, useEffect, useRef } from "react";
import { Animated, Platform, View } from "react-native";
import Row from "../quarks/Row";
import { useStyles } from "../style";

interface Props {
  focused?: boolean;
  error?: boolean;
  editable?: boolean;
  children?: ReactNode;
}

export const INPUT_HEIGHT = 44;

const InputContainer = ({
  focused,
  editable = true,
  error,
  children
}: Props) => {
  // @see https://github.com/facebook/react-native/issues/6278
  const lineScale = useRef(new Animated.Value(focused ? 1 : 0.005)).current;
  const isFirstRef = useRef(true);

  const styles = useStyles(({ getUnits, getColor }) => ({
    container: {
      marginBottom: getUnits(1),
      backgroundColor: getColor("foreground", "fill"),
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      paddingHorizontal: getUnits(2)
    },
    content: {
      minHeight: INPUT_HEIGHT
    },
    containerFocused: {
      backgroundColor: getColor("background", "fill")
    },
    underline: {
      borderBottomColor: "#bdc6cf", // color.neutralMedium,
      borderBottomWidth: 1,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0
    },
    underlineError: {
      borderBottomColor: getColor("danger", "fill")
    },
    underlineActive: {
      borderBottomColor: getColor("highlight", "fill"),
      borderBottomWidth: 2
    },
    disabled: { opacity: 0.5 }
  }));

  useEffect(() => {
    if (!isFirstRef.current && editable) {
      Animated.timing(lineScale, {
        toValue: focused ? 1 : 0.005,
        useNativeDriver: Platform.OS !== "web",
        duration: 250
      }).start();
    }

    isFirstRef.current = false;
  }, [focused, editable, lineScale]);

  return (
    <View
      style={[
        styles.container,
        !editable && styles.disabled,
        editable && focused && styles.containerFocused
      ]}
    >
      <Row style={styles.content} alignItems="center">
        {children}
      </Row>
      <View style={[styles.underline, error && styles.underlineError]} />
      <Animated.View
        style={[
          styles.underline,
          editable && styles.underlineActive, // error && styles.underlineError,
          editable && { transform: [{ scaleX: lineScale }], opacity: lineScale }
        ]}
      />
    </View>
  );
};

export default InputContainer;
