import type { DateTime } from "luxon";
import React from "react";
import Stack from "../../atoms/Stack";
import Column from "../../quarks/Column";
import { useMatchesViewport } from "../../style";
import DaysSelector, {
  type SelectableDay,
  isSelectableDayArray
} from "./DaysSelector";
import MonthSelector from "./MonthSelector";
import useCurrentMonth from "./useCurrentMonth";

interface Props {
  testID: string;
  onChange?: (value: DateTime[]) => void;
  value?: DateTime[] | SelectableDay[] | null;
  min?: DateTime;
  max?: DateTime;
  pinnedDays?: DateTime[];
  multiple?: boolean;
  onMonthChange?: (number: number) => void;
  constrained?: boolean;
  timezone?: string | null | undefined;
}

export default function CalendarPicker({
  testID,
  min,
  max,
  value,
  pinnedDays,
  multiple,
  onChange,
  onMonthChange,
  constrained,
  timezone
}: Props) {
  const [month, onSetMonth] = useCurrentMonth(
    isSelectableDayArray(value) ? value?.[0]?.value : value?.[0],
    min,
    timezone
  );
  const isMobile = useMatchesViewport((media) => media.platform.mobile);
  return (
    <Stack testID={testID} size="large" fill={!isMobile}>
      <Column alignItems="center" fill={!isMobile}>
        <MonthSelector
          testID={testID}
          min={min}
          max={max}
          month={month}
          onSetMonth={(val) => {
            onSetMonth(val);
            onMonthChange?.(val);
          }}
        />
      </Column>
      <DaysSelector
        testID={testID}
        min={min}
        max={max}
        month={month}
        selectedDays={value}
        onChange={
          onChange
            ? (days) => {
                onChange?.(days);
              }
            : undefined
        }
        multiple={multiple}
        pinnedDays={pinnedDays}
        constrained={constrained}
      />
    </Stack>
  );
}
