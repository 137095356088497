import { useIsFocused, useNavigation } from "@gigsmart/kaizoku";
import { useCallback, useEffect, useRef } from "react";
import { confirmPrompt } from "./prompt";

const defaultOptions = {
  title: "Are you sure you want to go back?",
  subTitle: "Your information will not be saved.",
  yesLabel: "Yes, Go Back",
  noLabel: "No, Cancel"
};

export async function defaultBackPrompt() {
  return await blockPrompt(defaultOptions);
}

export async function defaultCancelPrompt() {
  return await blockPrompt({
    title: "Are you sure you want to Cancel?",
    subTitle: "Your information will not be saved.",
    yesLabel: "Yes",
    noLabel: "No"
  });
}

async function blockPrompt(options: Partial<typeof defaultOptions>) {
  const opt = { ...defaultOptions, ...options };
  return await confirmPrompt({
    title: opt.title,
    subTitle: opt.subTitle,
    actions: [
      { label: opt.yesLabel, testID: "prompt-say-yes", value: true },
      { label: opt.noLabel, testID: "prompt-say-yes", value: false }
    ]
  });
}

interface HistoryBlockOptions {
  enabled?: boolean;
  onConfirm?: () => boolean | void;
  getBlockOptions?: () => Partial<typeof defaultOptions> | undefined;
}

export function useHistoryBlock({
  enabled = true,
  onConfirm,
  getBlockOptions
}: HistoryBlockOptions) {
  const isFocused = useIsFocused();
  const shouldBlock = isFocused && enabled;
  const isBlockedRef = useRef(shouldBlock);
  const nav = useNavigation();

  useEffect(() => {
    const isLocked = (isBlockedRef.current = shouldBlock);
    if (!isLocked) return;

    return nav.addListener("beforeRemove", async (e) => {
      const promptOptions = getBlockOptions
        ? getBlockOptions()
        : defaultOptions;
      const payload = e.data.action.payload as
        | Record<string, unknown>
        | undefined;
      const shouldSkip =
        !promptOptions || !isBlockedRef.current || payload?.safeExit === true;
      if (shouldSkip) return;

      e.preventDefault();
      const res = await blockPrompt(promptOptions);
      if (res && !onConfirm?.()) nav.dispatch(e.data.action);
    });
  }, [onConfirm, nav, shouldBlock]);

  const unblock = useCallback(() => {
    isBlockedRef.current = false;
  }, []);

  return unblock;
}

type Props = HistoryBlockOptions;

export function HistoryBlock({ enabled, onConfirm, getBlockOptions }: Props) {
  useHistoryBlock({ enabled, onConfirm, getBlockOptions });
  return null;
}
