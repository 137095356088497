import React, { type ReactNode } from "react";
import { View } from "react-native";
import { Constraint } from "../atoms";
import ContentArea from "../atoms/ContentArea";
import type { UnitSize } from "../quarks";
import { useStyles } from "../style";
import type { ThemeMedia } from "../style/theme/media";

interface Props {
  size?: keyof ThemeMedia["size"];
  zIndex?: number;
  children: ReactNode;
  gap?: UnitSize;
}

export default function AbsoluteScreenFooter({
  size = "xsmall",
  zIndex = 2,
  gap,
  children
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      outer: {
        position: "absolute",
        backgroundColor: getColor("background", "fill", { opacity: 0.75 }),
        bottom: 0,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: getUnits(0.5),
        shadowOffset: {
          width: 0,
          height: -getUnits(0.5)
        },
        zIndex
      }
    }),
    []
  );

  return (
    <View style={styles.outer}>
      <Constraint size={size}>
        <ContentArea gap={gap}>{children}</ContentArea>
      </Constraint>
    </View>
  );
}
