import { isEqual } from "lodash";
import React, { type ComponentProps } from "react";
import { StyleSheet } from "react-native";
import Markdown from "react-native-markdown-text";
import type { TextStyleProp } from "../style";
import * as theme from "../style/theme";

type Props = ComponentProps<typeof Markdown> & {
  style?: TextStyleProp;
  paragraphStyle?: TextStyleProp;
  noMargin?: boolean;
};

function MarkdownText({
  style,
  paragraphStyle,
  rules,
  children,
  whitelist,
  noMargin
}: Props) {
  const styles = {
    view: {},
    blockQuoteSection: {
      flexDirection: "row"
    },
    blockQuoteSectionBar: {
      width: theme.units(1),
      height: null,
      backgroundColor: theme.color.neutralLight,
      marginRight: 15
    },
    codeBlock: {
      fontFamily: "Courier",
      fontWeight: "500"
    },
    del: {
      containerBackgroundColor: theme.color.black
    },
    em: {
      fontStyle: "italic"
    },
    heading: {
      fontWeight: "200"
    },
    heading1: {
      fontSize: theme.font.size.heading1
    },
    heading2: {
      fontSize: theme.font.size.heading2
    },
    heading3: {
      fontSize: theme.font.size.large
    },
    heading4: {
      fontSize: theme.font.size.medium
    },
    heading5: {
      fontSize: theme.font.size.medium
    },
    heading6: {
      fontSize: theme.font.size.medium
    },
    hr: {
      backgroundColor: theme.color.neutralLight,
      height: 1
    },
    image: {
      width: 320,
      height: 320
    },
    inlineCode: {
      backgroundColor: theme.color.neutralLightGray,
      borderColor: theme.color.neutralLight,
      borderRadius: 3,
      borderWidth: 1,
      fontFamily: "Courier",
      fontWeight: "bold"
    },
    link: {
      textDecorationLine: "none",
      color: theme.color.spiceBlue,
      wordWrap: "inherit"
    },
    list: {},
    listItem: {
      flexDirection: "row"
    },
    listItemBullet: {
      fontSize: 20,
      lineHeight: 20,
      marginTop: 6
    },
    listItemNumber: {
      fontWeight: "bold"
    },
    mailTo: {
      textDecorationLine: "none",
      color: theme.color.spiceBlue
    },
    paragraph: {
      marginVertical: noMargin === true ? 0 : 10,
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      ...StyleSheet.flatten(paragraphStyle)
    },
    listItemText: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      color: theme.color.black
    },
    strong: {
      ...theme.font.body("bold")
    },
    table: {
      borderWidth: 1,
      borderColor: theme.color.black,
      borderRadius: 3
    },
    tableHeader: {
      backgroundColor: theme.color.black,
      flexDirection: "row",
      justifyContent: "space-around"
    },
    tableHeaderCell: {
      color: theme.color.white,
      fontWeight: "bold",
      padding: 5
    },
    tableRow: {
      borderBottomWidth: 1,
      borderColor: theme.color.black,
      flexDirection: "row",
      justifyContent: "space-around"
    },
    tableRowLast: {
      borderColor: "transparent"
    },
    tableRowCell: {
      padding: 5
    },
    text: {
      ...StyleSheet.flatten(style)
    },
    u: {
      borderColor: theme.color.black,
      borderBottomWidth: 1
    },
    video: {
      width: 300,
      height: 300
    }
  };

  return <Markdown {...{ rules, styles, whitelist }}>{children}</Markdown>;
}

export default React.memo<Props>(MarkdownText, isEqual);
