import React, { type ReactNode } from "react";
import { Pressable } from "../../quarks";
import { useStyles } from "../../style";

interface Props {
  testID: string;
  onPress?: () => void;
  children?: ReactNode;
  eventTargetName?: string;
}

export default function NavAction({
  testID,
  eventTargetName,
  onPress,
  children
}: Props) {
  const styles = useStyles(
    ({ measurements }) => ({
      container: {
        minWidth: 44,
        paddingHorizontal: 8,
        height: measurements.navbarHeight,
        alignItems: "center",
        justifyContent: "center"
      }
    }),
    []
  );
  return (
    <Pressable
      style={styles.container}
      testID={testID}
      onPress={onPress}
      eventTargetName={eventTargetName ?? "Nav Action Pressed"}
      eventEntityType="NavAction"
    >
      {children}
    </Pressable>
  );
}
