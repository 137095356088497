import React from "react";
import { View } from "react-native";
import { useStyle } from "../style";
import type { OnlyChildren } from "../utils/types";

type Props = OnlyChildren & {
  enabled?: boolean;
};

export default function AtlasGrayscale({ enabled, children }: Props) {
  const viewStyle = useStyle({
    WebkitFilter: "grayscale(100%)",
    filter: "grayscale(100%)"
  } as any);
  return enabled ? <View style={viewStyle}>{children}</View> : <>{children}</>;
}
