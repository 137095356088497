import React from "react";
import Svg, { G, Path } from "react-native-svg";
import { useTheme } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  size?: number;
  color?: Color;
}

const AscenIcon = ({ size = 24, color = "black" }: Props) => {
  const theme = useTheme();
  const fill = theme.getColor(color, "fill");

  return (
    <Svg
      id="Layer_2"
      viewBox="0 0 388.79 78.2"
      width={size * 3}
      height={size}
      fill={fill}
    >
      <G id="Layer_1-2">
        <Path d="M144.42 72.4h-.54c-.97 1.06-2.27 2.03-3.78 2.9-2.59 1.45-6.37 2.9-11.88 2.9-12.53 0-19.98-6.86-19.98-14.97 0-9.65 7.56-16.41 22.68-16.41h12.42v-.97c0-5.41-3.67-9.17-9.72-9.17s-9.18 3.48-9.72 6.28h-13.5c1.19-8.11 8.53-15.93 23.22-15.93s23.22 8.3 23.22 18.83v31.38H145.5l-1.08-4.83zm-1.08-16.41H132c-7.02 0-10.26 2.41-10.26 6.28s3.13 6.28 8.64 6.28c8.21 0 12.96-4.25 12.96-11.1V56zM176.61 62.27c0 2.41 3.35 6.28 10.8 6.28s10.26-2.32 10.26-5.31-1.51-4.63-7.56-5.5l-8.64-1.25c-11.34-1.64-17.82-5.79-17.82-14s8.1-15.45 23.22-15.45c16.09 0 23.22 10.14 23.22 15.93h-12.96c0-2.99-3.78-6.28-10.26-6.28s-9.72 2.8-9.72 5.79c0 2.32 2.48 3.86 7.56 4.54l9.18 1.26c11.34 1.54 17.28 6.18 17.28 14.97 0 8.11-7.67 14.97-23.76 14.97-20.52 0-23.76-12.55-23.76-15.93h12.96zM270.35 58.89c-1.73 7.72-8.53 19.31-25.92 19.31-16.2 0-28.08-10.62-28.08-25.59s11.88-25.59 28.08-25.59c17.39 0 24.19 11.59 25.92 18.83h-13.5c-1.19-2.8-4.32-7.72-12.42-7.72s-14.58 5.79-14.58 14.48 6.48 14.48 14.58 14.48 11.23-4.83 12.42-8.21h13.5zM328.99 62.75C327.8 66.61 321 78.2 303.61 78.2c-16.2 0-28.08-10.62-28.08-25.59s11.88-25.59 28.08-25.59c15.12 0 27 10.62 27 24.62 0 1.45-.22 2.61-.32 3.48l-.22 1.35h-41.04c1.19 6.86 6.91 11.59 14.58 11.59 6.37 0 10.26-3.28 11.34-5.31h14.04zm-11.88-14.48c-1.19-6.28-5.83-11.1-13.5-11.1-8.21 0-12.96 4.83-14.58 11.1h28.08zM338.03 27.99h12.42l1.08 4.83h.54c.97-1.06 2.27-2.03 3.67-2.9 2.48-1.45 6.26-2.9 11.45-2.9 12.42 0 21.6 8.21 21.6 20.76v29.45h-13.5V48.75c0-6.28-4.86-10.62-11.88-10.62s-11.88 4.34-11.88 10.62v28.48h-13.5V27.99zM.76 77.47h22.36L58.95 1.9c.09-.18.18-.37.29-.56C59.6.71 60.2.25 60.9.05l.17-.05H37.23c-.63 0-1.21.36-1.48.93L.07 76.39c-.24.5.13 1.08.68 1.08z" />
        <Path d="M95.15 76.39L72.28 28.05c-.27-.57-.85-.93-1.48-.93H46.96l.17.05c.7.2 1.3.66 1.66 1.29.11.19.2.37.29.56l23.04 48.46h22.36c.55 0 .92-.58.68-1.08z" />
      </G>
    </Svg>
  );
};

export default AscenIcon;
