import React, { createContext, type ReactNode, useContext } from "react";
import { Text as RNText, type TextProps } from "react-native";
import {
  type StyleProp,
  type TextStyle,
  usePlacementColor,
  useStyle
} from "../style";
import type { Theme } from "../style/theme";
import type { Color } from "../style/theme/colors";
import { type FontWeightName, fontWeights } from "../style/theme/fontHelpers";
import type { TestIDProps } from "../utils/types";
import { usePressable } from "./Pressable";

const getTextVariants = ({ getUnits, getFontSize, colors }: Theme) => ({
  embedded: {
    // embedded adds no default style
  },
  body: {
    // default font style
    ...getFontSize(),
    fontWeight: fontWeights.normal
  },
  bodyLg: {
    ...getFontSize(4)
  },
  hero: {
    fontSize: getUnits(10),
    fontWeight: fontWeights.semibold
  },
  title: {
    ...getFontSize(5),
    fontWeight: fontWeights.bold
  },
  titleLg: {
    ...getFontSize(6, 1),
    fontWeight: fontWeights.bold
  },
  header: {
    ...getFontSize(4),
    fontWeight: fontWeights.semibold
  },
  subheader: {
    fontSize: getUnits(3.5),
    fontWeight: fontWeights.bold
  },
  note: getFontSize(3),
  noteSm: getFontSize(2.5)
});

export type TextVariant = keyof ReturnType<typeof getTextVariants>;

export interface CommonTextProps extends TestIDProps {
  style?: StyleProp<TextStyle>;
  selectable?: boolean;
  wrap?: boolean;
  color?: Color;
  testID?: string;
  typography?: "sansSerif" | "serif" | "monospace";
  variant?: Exclude<TextVariant, "custom" | "nested">;
  align?: TextStyle["textAlign"];
  weight?: FontWeightName;
  italic?: boolean;
  strikethrough?: boolean;
  numberOfLines?: number;
  children?: ReactNode;
  fill?: boolean | number;
  // turn into a link
  accessibilityRole?: TextProps["accessibilityRole"];
  href?: string;
  hrefAttr?: { target?: "_blank" };
}

interface PressableTextProps extends CommonTextProps {
  onPress: () => void;
  eventEntityType?: string;
  eventTargetName: string | null;
}

type Props = CommonTextProps | PressableTextProps;

export type { Props as TextProps };

const EmbeddedTextContext = createContext(false);
const useTextEmbedded = () => useContext(EmbeddedTextContext);

export default function Text({
  testID,
  style,
  variant,
  color,
  align,
  wrap,
  typography = "sansSerif",
  weight,
  italic,
  strikethrough,
  fill,
  ...textProps
}: Props) {
  const handlePress = usePressable({
    onPress: "onPress" in textProps ? textProps.onPress : undefined,
    eventEntityType:
      "eventEntityType" in textProps
        ? textProps.eventEntityType ?? "Text"
        : "Text",
    eventTargetName:
      "eventTargetName" in textProps ? textProps.eventTargetName : null
  });
  const { color: placementColor } = usePlacementColor(variant);
  const embedded = useTextEmbedded();
  const textStyle = useStyle(
    (theme) => {
      const variantStyle =
        getTextVariants(theme)[variant ?? (embedded ? "embedded" : "body")];
      const textColor = color
        ? theme.getColor(color, "fill")
        : embedded
          ? undefined
          : placementColor ??
            theme.getColor("background", "placement", { fallthrough: false });
      return {
        ...variantStyle,
        color: textColor,
        textAlign: align,
        fontFamily: theme.fonts[typography],
        flex: fill === true ? 1 : fill || undefined,
        ...(wrap && { flexShrink: 1 }),
        ...(weight && { fontWeight: fontWeights[weight] }),
        ...(italic && { fontStyle: "italic" }),
        ...(strikethrough && { textDecorationLine: "line-through" })
      };
    },
    [
      embedded,
      placementColor,
      align,
      weight,
      wrap,
      italic,
      color,
      variant,
      fill
    ]
  );

  return (
    <EmbeddedTextContext.Provider value>
      <RNText
        testID={testID}
        style={[textStyle, style]}
        onPress={handlePress}
        {...textProps}
      />
    </EmbeddedTextContext.Provider>
  );
}
