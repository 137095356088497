import { Instrument } from "@gigsmart/pickle/support/utils/instrumentation-client";
import { compact, omit, uniq } from "lodash";
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
  type ComponentProps
} from "react";
import Select, {
  components,
  type OptionProps,
  type SingleValue
} from "react-select";
import InputLabel from "../atoms/InputLabel";
import InputNote from "../atoms/InputNote";
import type { TextInput } from "../molecules";
import { Column, Icon, Row } from "../quarks";
import { useStyles } from "../style";

type PickerOption = { label: string | undefined; value: string | undefined };

interface CustomControlProps {
  isOpen: boolean;
  label: string | null;
  value: string | null;
  onPress: () => void;
}
interface Props
  extends Omit<
    ComponentProps<typeof TextInput>,
    "onChange" | "onChangeText" | "value"
  > {
  value?: string | null;
  options: PickerOption[] | string[];
  onChange: (value: string | null) => void;
  placeholder?: string;
  androidPrompt?: string | null;
  disabled?: boolean;
  inputType?: "text" | "textInput";
  customControl?: (props: CustomControlProps) => JSX.Element;
}

const Option = (
  props: OptionProps<{ label?: string | undefined; value: string | undefined }>
) => {
  return (
    <Column testID={(props as any).value}>
      <components.Option {...props} />
    </Column>
  );
};

export default function Picker({
  options,
  placeholder = "Pick One",
  disabled,
  onChange,
  label,
  horizontal,
  labelAccessory,
  labelIcon,
  note,
  showErrorMessage = true,
  pointerEvents,
  fill,
  value,
  testID,
  inputType = "textInput",
  errors = [],
  customControl,
  containerStyle
}: Props) {
  const [open, setOpen] = useState(false);
  const controlRef = useRef<HTMLDivElement | null>(null);

  const setRefs = useCallback((element: HTMLDivElement | null) => {
    controlRef.current = element;
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        controlRef.current &&
        !controlRef.current.contains(event.target as Node) &&
        open
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const styles = useStyles(({ getUnits, getColor }) => ({
    label: {
      marginHorizontal: getUnits(2),
      marginBottom: getUnits(1)
    },
    menuPortal: { zIndex: 10001 },
    menu: { zIndex: 10002 },
    defaultFont: {
      fontFamily: "Proxima Nova",
      fontSize: getUnits(3.5),
      cursor: "pointer"
    },
    picker: {
      backgroundColor: getColor("input", "fill"),
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomColor: getColor("#bdc6cf", "fill"),
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      boxShadow: "none",
      fontFamily: "Proxima Nova",
      minHeight: 29,
      fontSize: 14,
      height: 44,
      cursor: "pointer",
      "&:hover": {
        borderBottomColor: getColor("#bdc6cf", "fill")
      }
    },
    textPicker: {
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      minHeight: "unset" as any,
      height: "auto",
      padding: 0,
      "&:hover": {
        border: "none"
      }
    },
    textValue: {
      fontWeight: "bold",
      color: getColor("primary", "fill"),
      padding: 0
    },
    labelError: {
      color: getColor("danger", "fill")
    },
    validationError: {
      marginLeft: getUnits(2),
      marginTop: getUnits(1)
    },
    valueContainer: { paddingRight: 0 },
    indicatorsContainer: { paddingLeft: 4 }
  }));

  const normalizedOptions = useMemo(
    () =>
      (options ?? []).map((value) =>
        typeof value === "string" ? { label: value, value } : value
      ),
    [options]
  );

  const selectedOption = useMemo(
    () => normalizedOptions.find((opt) => opt.value === value),
    [normalizedOptions, value]
  );

  const handleChange = useCallback(
    (opt: SingleValue<Partial<PickerOption>> | string | null) => {
      const newOpt =
        typeof opt === "string"
          ? normalizedOptions.find((it) => it.label === opt)?.value
          : opt?.value;

      onChange(newOpt ?? null);
      handleClose();
    },
    [handleClose, normalizedOptions, onChange]
  );

  const errorMessage = Array.isArray(errors)
    ? uniq(compact(errors.map(formatError))).join(", ")
    : formatError(errors);

  const hasError = !!errorMessage;
  const helperText = hasError && showErrorMessage ? errorMessage : note;
  const Wrapper = horizontal ? Row : Column;

  return (
    <Wrapper
      gap="slim"
      pointerEvents={pointerEvents}
      fill={fill}
      style={containerStyle}
    >
      {!!label && (
        <InputLabel
          testID={`${testID}-label`}
          label={label}
          icon={labelIcon}
          error={hasError}
          horizontal={horizontal}
          accessory={labelAccessory}
        />
      )}
      <Column testID={testID}>
        <Select<{ label?: string | undefined; value?: string | undefined }>
          {...(!customControl && { menuPortalTarget: document.body })}
          aria-label={testID}
          aria-readonly="false"
          onChange={handleChange}
          menuIsOpen={open}
          onMenuOpen={handleOpen}
          onMenuClose={handleClose}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <Icon
                  name={
                    props.selectProps.menuIsOpen ? "caret-up" : "caret-down"
                  }
                  variant="solid"
                  color="primary"
                  size="small"
                />
              </components.DropdownIndicator>
            ),
            Option: Option as any,
            Control: (props) => {
              if (customControl) {
                const { innerRef, innerProps, ...restProps } = props;
                const sanitizedProps = omit(restProps, [
                  "clearValue",
                  "getStyles",
                  "getClassNames",
                  "getValue",
                  "hasValue",
                  "isMulti",
                  "isRtl",
                  "selectOption",
                  "selectProps",
                  "setValue",
                  "isDisabled",
                  "isFocused",
                  "menuIsOpen"
                ]);

                return (
                  <div
                    ref={(element) => {
                      setRefs(element);
                      if (typeof innerRef === "function") {
                        innerRef(element);
                      } else if (innerRef) {
                        (
                          innerRef as React.MutableRefObject<HTMLDivElement | null>
                        ).current = element;
                      }
                    }}
                    {...innerProps}
                    {...sanitizedProps}
                    onTouchEnd={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {customControl?.({
                      isOpen: open,
                      label: selectedOption?.label ?? "",
                      value: selectedOption?.value ?? "",
                      onPress: () => setOpen(!open)
                    })}
                  </div>
                );
              }

              return <components.Control {...props} />;
            }
          }}
          styles={{
            menuPortal: (baseStyles) => ({
              ...baseStyles,
              ...styles.menuPortal
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              ...styles.picker,
              ...(inputType === "text" && styles.textPicker)
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              ...(inputType === "text" && {
                display: "inline-flex",
                width: "auto"
              })
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              ...(inputType === "text" && {
                padding: 0
              })
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              ...(inputType === "text" && {
                padding: 0,
                marginLeft: 4
              })
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              ...(inputType === "text" && {
                padding: 0
              })
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              ...styles.defaultFont,
              ...(inputType === "text" && styles.textValue)
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              ...styles.defaultFont,
              ...(inputType === "text" && styles.textValue)
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              ...styles.menu,
              minWidth: "300px"
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              ...styles.defaultFont
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: "none"
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              ...styles.defaultFont
            })
          }}
          options={normalizedOptions}
          placeholder={placeholder}
          isDisabled={disabled}
          isSearchable={false}
          value={selectedOption}
        />
      </Column>
      {!!helperText && <InputNote error={hasError} note={helperText} />}
      {open && (
        <Instrument
          command="pickValue"
          target={testID}
          handler={handleChange}
        />
      )}
    </Wrapper>
  );
}

function formatError(error?: Error | string | null) {
  if (error) return typeof error === "string" ? error : error.message;
}
