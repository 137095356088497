import React, { type ReactNode } from "react";
import { View } from "react-native";
import { Pressable, Row } from "../quarks";
import Icon, { type IconName } from "../quarks/Icon";
import Text from "../quarks/Text";
import { type ViewStyle, useStyles } from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";
import type { Color } from "../style/theme/colors";

interface Props {
  variant?: "default" | "solid" | "outline" | "well";
  color?: Color;
  label?: string;
  children?: ReactNode;
  icon?: IconName;
  highlightLabel?: string;
  highlightColor?: Color;
  iconPlacement?: "left" | "right";
  onIconPress?: () => void;
  testID: string;
}

export default function Bubble({
  variant = "default",
  color = "primary",
  label,
  children,
  icon,
  iconPlacement = "left",
  highlightLabel,
  highlightColor = "info",
  onIconPress,
  testID
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => {
      const variantStyle: ViewStyle =
        variant === "default"
          ? { backgroundColor: getColor("input", "fill") }
          : variant === "solid"
            ? { backgroundColor: getColor(color, "fill") }
            : variant === "well"
              ? { backgroundColor: getColor(color, "fill", { opacity: 0.08 }) }
              : variant === "outline"
                ? { borderWidth: 1, borderColor: getColor(color, "fill") }
                : {};
      const highlightOverride: ViewStyle = highlightLabel
        ? {
            borderLeftWidth: 0,
            borderTopRightRadius: 360,
            borderBottomRightRadius: 360,
            paddingLeft: getUnits(1)
          }
        : { borderRadius: 360 };

      return {
        container: {
          ...variantStyle,
          paddingVertical: getUnits(2),
          paddingHorizontal: getUnits(3)
        },
        highlightOverride,
        highlightContainer: {
          ...variantStyle,
          borderRightWidth: 0,
          borderTopLeftRadius: 360,
          borderBottomLeftRadius: 360,
          backgroundColor: getColor("surface", "fill"),
          overflow: "hidden"
        },
        highlightBackground: {
          backgroundColor: getColor(highlightColor, "fill", { opacity: 0.6 }),
          paddingRight: getUnits(1),
          paddingHorizontal: getUnits(2)
        },
        icon: {
          marginRight: iconPlacement === "right" ? -getUnits(1) : getUnits(1),
          marginLeft: iconPlacement === "left" ? 0 : getUnits(1),
          width: getUnits(4),
          height: getUnits(4),
          justifyContent: "center",
          alignItems: "center"
        },
        label: {
          fontWeight: "700",
          minWidth: getUnits(3)
        },
        highlightLabel: { lineHeight: 17 }
      };
    },
    [color, iconPlacement, icon, variant, highlightColor, highlightLabel]
  );

  return (
    <ColorPlacementProvider
      color={color}
      use={variant === "solid" ? "placement" : "fill"}
    >
      <Row>
        {!!highlightLabel && (
          <View style={styles.highlightContainer}>
            <Row
              fill={true}
              alignItems="center"
              style={styles.highlightBackground}
            >
              <Text
                variant="note"
                weight="semibold"
                style={styles.highlightLabel}
              >
                {highlightLabel.toUpperCase()}
              </Text>
            </Row>
          </View>
        )}
        <Row
          style={[styles.container, styles.highlightOverride]}
          alignItems="center"
          alignSelf="flex-start"
          reverse={iconPlacement !== "left"}
        >
          {!!icon && (
            <Pressable
              testID={`${testID}-bubble-icon`}
              eventEntityType="Bubble Icon"
              eventTargetName="Bubble Icon"
              style={styles.icon}
              onPress={onIconPress}
              disabled={!onIconPress}
            >
              <Icon size="small" variant="solid" name={icon} />
            </Pressable>
          )}
          {label ? (
            <Text
              style={styles.label}
              selectable={false}
              testID={testID && `${testID}-label`}
            >
              {label}
            </Text>
          ) : (
            children
          )}
        </Row>
      </Row>
    </ColorPlacementProvider>
  );
}
