export function rectangle(width: number, height: number) {
  return {
    width,
    height
  };
}

export function square(sideLength: number) {
  return rectangle(sideLength, sideLength);
}

export function circle(diameter: number) {
  return {
    ...square(diameter),
    borderRadius: diameter / 2
  };
}
