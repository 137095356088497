import React from "react";
import { ContentArea, IconCircle } from "../atoms";
import Column from "../quarks/Column";
import Text from "../quarks/Text";

type Props = {
  searchTerm: string;
};

export default function ListEmptySearch({ searchTerm }: Props) {
  return (
    <ContentArea justifyContent="center" constraint="xsmall">
      <Column alignItems="center" justifyContent="center" gap="standard">
        <IconCircle
          icon="table-cells"
          size="medium"
          variant="well"
          color="neutral"
          iconColor="disabled"
        />
        <Text>
          No results found for <Text weight="bold">"{searchTerm}"</Text>
        </Text>
      </Column>
    </ContentArea>
  );
}
