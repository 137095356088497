import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType } from "react";
import type { BreakpointStylesFn, StylesProps } from "./style-helpers";
import useStyles from "./use-styles";

/** @deprecated Use hooks instead of HOCs */
export default <T extends string = string, Props extends StylesProps<T> = any>(
  callback: BreakpointStylesFn<T>
): HOCVoid =>
  (WrappedComponent: ComponentType<Props>) =>
    applyHOCProperties({
      WrappedComponent,
      displayName: "withStyles",
      HigherOrderComponent: (props: Props) => {
        const { styles, theme } = useStyles<T>(callback);
        return <WrappedComponent {...props} styles={styles} theme={theme} />;
      }
    });
