import ShadowView from "@gigsmart/shadow-view";
import React, { type ReactNode } from "react";

import { ContentArea, IconSquare, useIsConstrained } from "../atoms";
import {
  Column,
  Divider,
  Icon,
  type IconName,
  Row,
  Spacer,
  Text
} from "../quarks";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  testID?: string;
  variant?: "shadow" | "flat";
  icon?: IconName;
  iconVariant?: "square" | "flat";
  title?: ReactNode;
  titleVariant?: "header" | "subheader";
  titleColor?: Color;
  description?: ReactNode;
  inset?: boolean;
  tintColor?: Color;
  action?: ReactNode;
  backgroundColor?: Color;
}

export default function TitleBar({
  testID,
  variant = "shadow",
  icon,
  iconVariant = "flat",
  title,
  titleVariant = "header",
  titleColor,
  description,
  inset,
  tintColor = "primary",
  action,
  backgroundColor
}: Props) {
  const isConstrained = useIsConstrained();
  const styles = useStyles(({ getUnits, getColor }) => ({
    bar: {
      zIndex: 10,
      shadowColor: "#000000",
      shadowOpacity: 0.15,
      shadowRadius: 4,
      shadowOffset: { width: 0, height: 2 },
      backgroundColor: getColor("surface", "fill")
    },
    barInset: { borderRadius: getUnits(1) },
    barFlat: { shadowOpacity: 0 },

    content: {
      minHeight: getUnits(13),
      paddingVertical: getUnits(2),
      backgroundColor: backgroundColor
        ? getColor(backgroundColor, "fill", { opacity: 0.08 })
        : undefined
    }
  }));

  inset ??= isConstrained;
  const isFlat = variant === "flat";
  const leftNode = !icon ? null : iconVariant === "square" ? (
    <>
      <Spacer horizontal size="compact" />
      <IconSquare icon={icon} variant="well" size="medium" color={tintColor} />
    </>
  ) : (
    <>
      <Spacer horizontal />
      <Icon
        align="center"
        name={icon}
        variant="solid"
        size="medium"
        color={tintColor}
      />
    </>
  );

  return (
    <ShadowView
      testID={testID}
      style={[styles.bar, isFlat && styles.barFlat, inset && styles.barInset]}
    >
      <Row style={styles.content} alignItems="center">
        {leftNode}
        <Spacer horizontal />
        <Column fill gap={2}>
          {typeof title === "string" ? (
            <Text
              variant={titleVariant}
              weight="bold"
              color={titleColor ?? tintColor}
              testID={testID ? `${testID}-title` : undefined}
            >
              {title}
            </Text>
          ) : (
            title
          )}
          {typeof description === "string" ? (
            <Text
              variant="note"
              color="neutral"
              testID={testID ? `${testID}-description` : undefined}
            >
              {description}
            </Text>
          ) : (
            description
          )}
        </Column>
        {!!action && <ContentArea size="none">{action}</ContentArea>}
      </Row>
      {isFlat && <Divider />}
    </ShadowView>
  );
}
