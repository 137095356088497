export interface IBarcode {
  data: string;
  symbology: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  onSelect?: (barcodes: IBarcode[]) => void;
}

export default function BarcodeScanner(_props: Props) {
  return null;
}
