/**
 * index.tsx
 * This module provides utility functions and hooks for handling permissions.
 */
import { logger as baseLogger, hasPermissionFromStatus } from "./helpers";
import { type Permissions, permissions } from "./implementation";
import type {
  DetailedPermissionResponse,
  PermissionRequestOptions
} from "./registry";

export type { PermissionRequestOptions };

const logger = baseLogger.createLogger("!👉", "commands");

/**
 * Asynchronously requests a specific permission.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to request.
 * @param {Partial<Permissions[K]["requestOptions"]>} options - An object containing options for the permission request.
 *
 * @returns {Promise} - Returns a promise that resolves with the result of the permission request.
 */
export const requestPermission = logger.wrap(function requestPermission<
  K extends keyof Permissions
>(permission: K, options?: Partial<PermissionRequestOptions>) {
  logger.info(permission, "requestPermission");
  return permissions[permission].request(options);
});

/**
 * Asynchronously checks a specific permission.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to check.
 * @param {Partial<Permissions[K]["checkOptions"]>} options - An object containing options for the permission check.
 *
 * @returns {Promise} - Returns a promise that resolves with the result of the permission check.
 */
export const checkPermission = logger.wrap(function checkPermission<
  K extends keyof Permissions
>(permission: K, options: Partial<PermissionRequestOptions> = {}) {
  logger.info(permission, "checkPermission", options);
  return permissions[permission].check(options);
});

/**
 * Asynchronously checks if a specific permission has been granted.
 *
 * @template K - A key from the Permissions object.
 * @param {K} permission - The name of the permission to check.
 * @param {Partial<Permissions[K]["checkOptions"]>} options - An object containing options for the permission check.
 *
 * @returns {Promise} - Returns a promise that resolves with a boolean indicating whether the permission has been granted.
 */
export const hasPermission = logger.wrap(async function hasPermission<
  K extends keyof Permissions
>(
  permission: K,
  {
    request = false,
    ...options
  }: Partial<PermissionRequestOptions> & { request?: boolean } = {}
) {
  logger.info(permission, "hasPermission", options);
  return hasPermissionFromStatus(
    await (request
      ? requestPermission(permission, options)
      : checkPermission(permission))
  );
});

export const missingPermission = logger.wrap(async function missingPermission<
  K extends keyof Permissions
>(
  permission: K,
  options: Partial<PermissionRequestOptions> & { request?: boolean } = {}
) {
  return !(await hasPermission(permission, options));
});

export const addPermissionListener = logger.wrap(function addPermissionListener<
  K extends keyof Permissions
>(
  permission: K,
  listener: Parameters<Permissions[K]["addListener"]>[0],
  options: Parameters<Permissions[K]["addListener"]>[1] = {}
) {
  return permissions[permission].addListener(listener, options);
});

export const getRawPermissionStatus = logger.wrap(
  async function getRawPermissionStatus<K extends keyof Permissions>(
    permission: K,
    trace?: string
  ) {
    return new Promise<DetailedPermissionResponse>((resolve) => {
      const listener = addPermissionListener(
        permission,
        ({ response }) => {
          resolve(response);
          listener.remove();
        },
        { trace }
      );
    });
  }
);
