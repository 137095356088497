import React, { type ReactNode, type ComponentProps } from "react";
import ContentArea from "../atoms/ContentArea";

type Props = Omit<ComponentProps<typeof ContentArea>, "children"> & {
  /** Use Tag elements */
  tags?: ReactNode;
};

export default function TagContainer({ tags, ...rest }: Props) {
  return (
    <ContentArea horizontal size="none" gap="compact" wrap="wrap" {...rest}>
      {tags}
    </ContentArea>
  );
}
