import React, { type ReactNode, type ComponentProps } from "react";
import Column from "../quarks/Column";
import Icon from "../quarks/Icon";
import type { IconName } from "../quarks/Icon";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useStyles } from "../style";
import type { ColorProp } from "../style/theme/colors";
import { useInCardContext } from "./Card";
import IconButton from "./IconButton";
import IconCircle from "./IconCircle";
import IconSquare from "./IconSquare";

interface Props {
  iconType?: "IconSquare" | "Icon" | "IconCircle";
  icon?: IconName;
  title?: string | ReactNode;
  note?: string | ReactNode;
  testID?: string;
  color?: ColorProp;
  titleColor?: ColorProp;
  variant?: ColorProp;
  onDismiss?: () => void;
  action?: ReactNode;
  rightSpacing?: "standard" | "compact" | "none";
  numberOfLines?: ComponentProps<typeof Text>["numberOfLines"];
}

export default function FancyHeader({
  iconType = "Icon",
  icon,
  title,
  note,
  testID,
  color = "primary",
  titleColor,
  variant = "highlight",
  rightSpacing = "standard",
  numberOfLines,
  action,
  onDismiss
}: Props) {
  const inCard = useInCardContext();
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      container: {
        backgroundColor: getColor(variant, "fill", { opacity: 0.08 }),
        height: 44,
        ...(inCard && {
          borderTopLeftRadius: getUnits(1),
          borderTopRightRadius: getUnits(1)
        })
      },
      text: { flex: 1, marginLeft: getUnits(2) },
      icon: {
        width: 20,
        textAlign: "center"
      }
    }),
    [color, variant]
  );

  action ??= onDismiss ? (
    <IconButton
      name="times"
      size="small"
      color={color}
      variant="solid"
      onPress={onDismiss}
      testID={`${testID}-close-icon`}
      eventEntityType="close"
    />
  ) : null;

  const renderIcon = () => {
    if (!icon) return null;
    switch (iconType) {
      case "IconSquare":
        return (
          <IconSquare icon={icon} size="small" color={color} variant="well" />
        );
      case "IconCircle":
        return (
          <IconCircle icon={icon} size="small" color={color} variant="well" />
        );
      default:
        return (
          <Icon
            name={icon}
            size="small"
            color={color}
            variant="solid"
            style={styles.icon}
          />
        );
    }
  };

  return (
    <Row style={styles.container} alignItems="center" testID={testID}>
      <Spacer horizontal size={iconType === "Icon" ? undefined : "compact"} />
      {icon && renderIcon()}
      <Row alignItems="center" fill gap="compact">
        {!note ? (
          <Text
            testID="fancy-header-title"
            style={styles.text}
            color={titleColor ?? color}
            weight="bold"
            numberOfLines={numberOfLines}
          >
            {title}
          </Text>
        ) : (
          <Column justifyContent="center" fill style={styles.text}>
            <Text
              testID="fancy-header-title"
              color={titleColor ?? color}
              weight="bold"
              numberOfLines={numberOfLines}
            >
              {title}
            </Text>
            <Text variant="note" numberOfLines={numberOfLines}>
              {note}
            </Text>
          </Column>
        )}
        {action}
      </Row>
      <Spacer size={rightSpacing} horizontal />
    </Row>
  );
}
