import compact from "lodash/compact";
import startCase from "lodash/startCase";

const clean = (str: string) => str.replace(/(\d[,.]?)+/g, "#");

interface EventNameOptions {
  contextName: string | null;
  action: string;
  targetName: string;
  entityType: string;
}

export const generateEventName = (options: EventNameOptions | string) => {
  if (typeof options === "string") return options;
  const {
    contextName: context,
    action,
    targetName: target,
    entityType
  } = options;
  const contextName = context ?? "Application";
  const actionName = startCase(clean(action));
  const targetName = startCase(clean(target));
  const entityTypeName = startCase(entityType);
  const actionWithTarget = `${actionName} ${targetName}`;
  let eventName = `${contextName}: ${actionWithTarget}`;
  if (!targetName.endsWith(entityTypeName)) eventName += ` ${entityTypeName}`;
  return eventName;
};

interface ContextNameOptions {
  contextName: string | null | undefined;
  name: string;
}
export const generateContextName = ({
  contextName,
  name
}: ContextNameOptions) => compact([contextName, startCase(name)]).join(" > ");

interface ConversionNameOptions {
  name: string;
}
export const generateConversionName = ({ name }: ConversionNameOptions) =>
  `Conversion: ${startCase(name)}`;
