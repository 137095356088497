import { chunk } from "lodash";
import React, { type ComponentProps, useMemo } from "react";
import { SectionList, type SectionListProps, View } from "react-native";
import type { ContentArea } from "../atoms";
import { useGridStyles } from "../layout/Grid";
import { Row } from "../quarks";
import { type ResponsiveValue, type Size, useStyles } from "../style";

export interface BaseSection<T = any> {
  key: string;
  data: T[];
}

export interface Props<TItem, TSection extends BaseSection<TItem>>
  extends Pick<
      SectionListProps<TItem, TSection>,
      | "onEndReached"
      | "onEndReachedThreshold"
      | "ListFooterComponentStyle"
      | "ListHeaderComponentStyle"
      | "nestedScrollEnabled"
    >,
    Pick<
      ComponentProps<typeof ContentArea>,
      "fill" | "constraint" | "size" | "variant"
    > {
  data?: readonly TSection[] | null;
  header?: ((isEmpty: boolean) => JSX.Element | null) | JSX.Element | null;
  footer?: JSX.Element | null;
  emptyContent?: JSX.Element | null;
  numColumns?: ResponsiveValue<number>;
  renderItem?: (item: TItem) => JSX.Element | null;
  keyExtractor?: (item: TItem) => string | number;
  renderSectionHeader?: (section: TSection) => JSX.Element | null;
  sectionHeaderHeight?: number;
  itemHeight?: number;
  spacing?: Size | "none";

  /// list props
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  testID?: string;
  footerBottom?: boolean;
}

export default function GridSectionList<
  TItem,
  TSection extends BaseSection<TItem>
>({
  data = [],
  header = null,
  footer = null,
  emptyContent = null,
  numColumns = { medium: 2, xlarge: 3, xxlarge: 4 },
  renderItem,
  renderSectionHeader,
  keyExtractor,
  footerBottom,
  ListFooterComponentStyle,
  constraint,
  variant,
  size,
  spacing,
  ...listProps
}: Props<TItem, TSection>) {
  const { contentStyle, gridStyles, columns } = useGridStyles(
    { numColumns, constraint, size, variant, spacing },
    false
  );
  const styles = useStyles(
    ({ getUnits }) => ({
      topSection: { paddingTop: getUnits(4) },
      container: { flex: 1, minHeight: 100 },
      grow: { flexGrow: 1 },
      footerBottom: { marginTop: "auto" }
    }),
    []
  );

  const isEmpty = !data?.length;
  const sections = useMemo(
    () =>
      (data ?? []).map((d) => ({
        original: d,
        key: d.key,
        data: chunk(d.data, columns).map((chunk, idx) => ({
          chunk,
          key: keyExtractor ? chunk.map(keyExtractor).join(".") : `${idx}`
        }))
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, data]
  );

  return (
    <SectionList
      style={styles.container}
      stickySectionHeadersEnabled={false}
      sections={sections}
      contentContainerStyle={[
        contentStyle,
        (isEmpty || footerBottom) && styles.grow
      ]}
      ListEmptyComponent={emptyContent}
      ListHeaderComponent={header}
      ListFooterComponent={footer}
      ListFooterComponentStyle={[
        footerBottom && styles.footerBottom,
        ListFooterComponentStyle
      ]}
      renderSectionHeader={({ section }) =>
        renderSectionHeader?.(section.original) ?? null
      }
      keyExtractor={(item) => item.key}
      renderItem={({ item }) => (
        <Row style={gridStyles.itemWrapper}>
          {item.chunk.map((item, index) => (
            <View key={keyExtractor?.(item) ?? index} style={gridStyles.item}>
              {renderItem?.(item)}
            </View>
          ))}
        </Row>
      )}
      {...listProps}
    />
  );
}
