import React, { useState } from "react";
import { Button, ModalBody, ModalFooter } from "../atoms";
import { TextInput } from "../molecules";
import { showModal } from "./ModalProvider";

interface Props {
  onResult: (value: string | null) => void;
}
function BarcodeModal({ onResult }: Props) {
  const [value, setValue] = useState("");
  return (
    <>
      <ModalBody>
        <TextInput
          value={value}
          onChangeText={setValue}
          label="Barcode"
          placeholder="Enter Barcode Number"
          testID="barcode-input"
        />
      </ModalBody>
      <ModalFooter horizontal>
        <Button
          fill
          outline
          testID="cancel-btn"
          label="Cancel"
          onPress={() => onResult(null)}
        />
        <Button
          fill
          testID="save-btn"
          label="Save"
          onPress={() => onResult(value)}
        />
      </ModalFooter>
    </>
  );
}

export async function showBarcodeModal() {
  return await new Promise<string | null>((resolve) => {
    let modal: { dispose: () => void } | undefined;

    const handleSelect = (value: string | null) => {
      modal?.dispose();
      modal = undefined;
      setTimeout(() => resolve(value), 200);
    };

    modal = showModal({
      eventContext: "Enter Barcode Modal",
      variant: "prompt",
      title: "Enter Barcode Manually",
      onRequestClose: () => handleSelect(null),
      useModalBody: false,
      children: () => <BarcodeModal onResult={handleSelect} />
    });
  });
}
