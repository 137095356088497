import React from "react";

import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";
import { useStyles } from "../style/styles";
import Stack from "./Stack";

interface Props {
  icon?: IconName;
  label: string;
  onPress: () => void;
  testID: string;
}

export default function ScreenHeaderLink({
  icon,
  label,
  testID,
  onPress
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    text: {
      fontSize: getUnits(4)
    },
    icon: {
      fontSize: getUnits(6)
    }
  }));
  return (
    <Pressable
      eventEntityType="HeaderLink"
      onPress={onPress}
      testID={testID}
      eventTargetName="Header Link"
    >
      <Stack horizontal alignItems="center" size="compact">
        {icon && <Icon name={icon} color="primary" style={styles.icon} />}
        <Text style={styles.text} color="primary">
          {label}
        </Text>
      </Stack>
    </Pressable>
  );
}
