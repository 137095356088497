const defaultFormatter = new Intl.NumberFormat("en-US");

export const humanize = (num: number, options?: Intl.NumberFormatOptions) => {
  const instance = options
    ? new Intl.NumberFormat("en-US", options)
    : defaultFormatter;

  return instance.format(num);
};

export const rating = (num?: number | null) => {
  if (typeof num === "number")
    return humanize(num, { maximumFractionDigits: 2 });
  return "-";
};

export const getNumberSuffix = (num: number) => {
  if (num >= 10 && num <= 20) return "th";
  switch (num % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
