import React, { type ReactElement, type ReactNode } from "react";
import { ContentArea, ListRow, Surface } from "../atoms";
import AbsoluteContainer from "../atoms/AbsoluteContainer";
import { Icon, type IconName, Pressable, Text } from "../quarks";

interface Props {
  testID: string;
  icon: IconName;
  eventEntityType?: string;
  onPress?: () => void;
  label: string | ReactElement<typeof Text>;
  note?: string;
  children?: ReactNode;
}

// TODO: Check this one
export default function StickyReminder({
  testID,
  icon,
  label,
  note,
  eventEntityType = "StickyReminder",
  onPress,
  children
}: Props) {
  return (
    <AbsoluteContainer bottom={0}>
      <ContentArea>
        <Pressable
          onPress={onPress}
          eventTargetName=""
          eventEntityType={eventEntityType}
          testID={testID}
        >
          <Surface variant="flat" color="highlight">
            <ListRow
              stackSize="small"
              spacing="medium"
              left={<Icon name={icon} color="white" variant="solid" />}
              right={
                <Icon
                  name="chevron-right"
                  color="white"
                  size="small"
                  variant="solid"
                />
              }
              label={
                <Text numberOfLines={1} variant="subheader" color="white">
                  {label}
                </Text>
              }
            >
              {!!note && (
                <Text numberOfLines={1} variant="note" color="white">
                  {note}
                </Text>
              )}
              {children}
            </ListRow>
          </Surface>
        </Pressable>
      </ContentArea>
    </AbsoluteContainer>
  );
}
