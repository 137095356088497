import React, { memo } from "react";
import type { TextStyle } from "react-native";
import { Renderer, useMarkdown } from "react-native-marked";
import type { MarkedStyles } from "react-native-marked/dist/typescript/theme/types";
import { useStyles } from "../style";
interface Props {
  children: string | string[];
}

const Markdown = ({ children }: Props) => {
  const styles = useStyles<MarkedStyles>(
    ({ getColor, getFontSize, fonts, getUnits }) => {
      const fontBase: TextStyle = {
        fontFamily: fonts.sansSerif
      };

      return {
        text: {
          ...getFontSize(3.5),
          ...fontBase
        },
        em: {
          ...getFontSize(3.5),
          ...fontBase
        },
        strong: {
          ...getFontSize(3.5),
          ...fontBase
        },
        strikethrough: {
          ...getFontSize(3.5),
          ...fontBase
        },
        paragraph: {
          ...getFontSize(3.5),
          ...fontBase
        },
        link: {
          color: getColor("link", "fill"),
          ...getFontSize(3.5),
          ...fontBase
        },
        blockquote: {
          ...getFontSize(3.5),
          ...fontBase
        },
        h1: {
          ...getFontSize(6),
          ...fontBase,
          color: getColor("primary", "fill")
        },
        h2: {
          ...getFontSize(5.5),
          ...fontBase,
          color: getColor("primary", "fill")
        },
        h3: {
          ...getFontSize(5),
          ...fontBase,
          color: getColor("primary", "fill")
        },
        h4: {
          ...getFontSize(4.5),
          ...fontBase
        },
        h5: {
          ...getFontSize(4.25),
          ...fontBase
        },
        h6: {
          ...getFontSize(4),
          ...fontBase
        },
        codespan: {
          ...fontBase
        },
        code: {},
        hr: {},
        list: {},
        li: {
          ...fontBase,
          paddingVertical: getUnits(1)
        },
        image: {},
        table: {
          ...fontBase
        },
        tableRow: {},
        tableCell: {}
      };
    }
  );
  const elements = useMarkdown(
    Array.isArray(children) ? children.join(" ") : children,
    {
      styles,
      baseUrl: process.env.DEEPLINK_FALLBACK_URL,
      colorScheme: "light",
      renderer: new Renderer()
    }
  );

  return <>{elements}</>;
};

export default memo(Markdown);
