import React from "react";
import Icon from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import { ColorPlacementProvider } from "../style/colorPlacement";
import { useStyles } from "../style/styles";
import type { Color } from "../style/theme/colors";

interface Props {
  testID?: string;
  selected: boolean;
  disabled?: boolean;
  color?: Color;
  onChange?: (selected: boolean) => void;
  eventTargetName?: string;
}

export default function Checkbox({
  testID = "checkbox",
  selected,
  disabled,
  onChange,
  eventTargetName = "Checkbox",
  color = "primary"
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      view: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: selected ? getColor(color, "fill") : "transparent",
        borderColor: selected
          ? undefined
          : getColor(color, "fill", { fallthrough: false }),
        borderWidth: selected ? undefined : 2,
        width: getUnits(5),
        height: getUnits(5),
        borderRadius: getUnits(1)
      },
      viewDisabled: { opacity: 0.5 }
    }),
    [selected]
  );
  return (
    <Pressable
      onPress={() => onChange?.(!selected)}
      eventEntityType="Checkbox"
      eventTargetName={eventTargetName}
      style={[styles.view, disabled && styles.viewDisabled]}
      disabled={!!disabled || !onChange}
      testID={testID}
    >
      <ColorPlacementProvider
        color={color}
        use={selected ? "fill" : "placement"}
      >
        {selected && (
          <Icon name="check" color="surface" variant="solid" size="tiny" />
        )}
      </ColorPlacementProvider>
    </Pressable>
  );
}
