import { createContext, useContext } from "react";
import type { CalendarContext } from "./helpers";

export const Context = createContext<CalendarContext>({
  isSm: false,
  isMd: false,
  isLg: false,
  cellSize: 0
});

export const useCalendarContext = () => useContext(Context);
