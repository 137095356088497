import InstrumentScroller, {
  type OnScrollParams
} from "@gigsmart/pickle/support/utils/instrument-scroller";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  type DependencyList,
  type MutableRefObject,
  useState
} from "react";
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  Platform,
  type ScrollView as RNScrollView,
  type TextInput,
  findNodeHandle
} from "react-native";
import {
  KeyboardAwareScrollView,
  type KeyboardAwareScrollViewProps
} from "react-native-keyboard-aware-scroll-view";
import { type UseTransformFlexProps, useTransformFlexProps } from "../style";
import { useLatestCallback, useThrottledCallback } from "../utils";

type ScrollToFn = (arg0: OnScrollParams) => void;
type ScrollEndListener = (distanceToEnd: number) => void;

export interface ScrollToProps {
  scrollable: boolean;
  scrollTo: ScrollToFn;
  addEndListener: (cb: ScrollEndListener) => () => void;
}

const Context = createContext<ScrollToProps>({
  scrollable: true,
  scrollTo: () => {},
  addEndListener: () => () => {}
});

const END_THRESHOLD = 40;

export const useScrollRef = () => useRef<RNScrollView>(null);
export const useScrollContext = () => useContext(Context);
export const useOnEndReached = (
  fn?: (distanceToEnd: number) => void,
  deps: DependencyList = []
) => {
  const { addEndListener } = useScrollContext();
  useEffect(() => {
    if (!fn) return;
    return addEndListener(fn);
  }, [addEndListener, ...deps]);
};

export interface ScrollViewProps
  extends Omit<
    UseTransformFlexProps & KeyboardAwareScrollViewProps,
    "direction" | "testID" | "innerRef" | "grow"
  > {
  testID: string;
  reverse?: boolean;
  innerRef?: MutableRefObject<RNScrollView | null>;
  grow?: boolean;
  onScrollToEnd?: (distanceToEnd: number) => void;
}

export function ScrollView({
  testID,
  horizontal,
  reverse,
  children,
  grow = false,
  onScroll,
  innerRef,
  scrollEnabled = true,
  onContentSizeChange,
  onScrollToEnd,
  onLayout,
  ...props
}: ScrollViewProps) {
  const scrollRef = useRef<KeyboardAwareScrollView>(null);
  const dir = horizontal ? "row" : "column";

  const transformedProps = useTransformFlexProps(
    {
      direction: reverse ? `${dir}-reverse` : `${dir}`,
      grow: grow ? 1 : undefined,
      ...props
    },
    "contentContainerStyle"
  );

  const handleScrollTo = useCallback((params: OnScrollParams) => {
    const scroller = scrollRef.current;
    if (!scroller) return;

    if (params === "start") {
      scroller.scrollToPosition(0, 0, true);
    } else if (params === "end") {
      scroller.scrollToEnd(true);

      // Android specific hack:
      if (Platform.OS === "android") {
        // Wait for the scroll to end animation
        setTimeout(() => {
          // ensure the scroll is at the end and force enabling a scroll end event
          distanceToEndRef.current = 0;
          checkScrollToEnd();
        }, 600);
      }
    } else if ("y" in params || "x" in params) {
      scroller.scrollToPosition(params.x || 0, params.y || 0, true);
    } else if (
      "scrollIntoView" in scroller &&
      typeof scroller.scrollIntoView === "function"
    ) {
      const node = findNodeHandle(params as typeof TextInput);
      if (node) scroller.scrollIntoView(node);
    }
  }, []);

  const contentSizeRef = useRef(0);
  const containerSizeRef = useRef(0);
  const distanceToEndRef = useRef(0);

  const listeners = useRef<ScrollEndListener[]>([]);
  const [isEndReached, setEndReached] = useState(false);

  const addEndListener = useLatestCallback((cb: ScrollEndListener) => {
    listeners.current.push(cb);
    if (isEndReached) setTimeout(() => cb(0), 0);

    return () => {
      const idx = listeners.current.indexOf(cb);
      if (idx >= 0) listeners.current.splice(0, idx);
    };
  });

  const checkScrollToEnd = useThrottledCallback(() => {
    const distanceToEnd = distanceToEndRef.current;
    const newEndReached = distanceToEnd <= END_THRESHOLD;

    if (newEndReached && !isEndReached) {
      setEndReached(true);
      onScrollToEnd?.(distanceToEnd);
      for (const fn of listeners.current) fn(distanceToEnd);
    } else if (!newEndReached && isEndReached) {
      setEndReached(false);
    }
  }, 300);

  const handleContentSizeChange = useLatestCallback((w: number, h: number) => {
    onContentSizeChange?.(w, h);
    contentSizeRef.current = horizontal ? w : h;

    if (h <= containerSizeRef.current) {
      distanceToEndRef.current = containerSizeRef.current - h;
      checkScrollToEnd();
    }
  });

  const handleLayout = useLatestCallback((e: LayoutChangeEvent) => {
    if (!e.nativeEvent) return;
    onLayout?.(e);
    containerSizeRef.current = horizontal
      ? e.nativeEvent.layout.width
      : e.nativeEvent.layout.height;
  });

  const handleScroll = useLatestCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (!e.nativeEvent) return;
      onScroll?.(e);

      containerSizeRef.current = horizontal
        ? e.nativeEvent.layoutMeasurement.width
        : e.nativeEvent.layoutMeasurement.height;
      const contentOffset = horizontal
        ? e.nativeEvent.contentOffset.x
        : e.nativeEvent.contentOffset.y;

      distanceToEndRef.current =
        contentSizeRef.current - containerSizeRef.current - contentOffset;
      checkScrollToEnd();
    }
  );

  return (
    <KeyboardAwareScrollView
      ref={scrollRef}
      innerRef={(ref) => {
        if (innerRef) innerRef.current = ref as any;
      }}
      onLayout={handleLayout}
      onContentSizeChange={handleContentSizeChange}
      scrollEnabled={scrollEnabled}
      extraScrollHeight={30}
      viewIsInsideTabBar
      enableOnAndroid={false}
      automaticallyAdjustContentInsets
      contentInsetAdjustmentBehavior="automatic"
      showsVerticalScrollIndicator={Platform.OS === "web"}
      keyboardShouldPersistTaps="handled"
      testID={testID}
      enableResetScrollToCoords={false}
      onScroll={handleScroll}
      scrollEventThrottle={32}
      {...transformedProps}
    >
      <Context.Provider
        value={{
          addEndListener,
          scrollable: scrollEnabled,
          scrollTo: handleScrollTo
        }}
      >
        {children}
        <InstrumentScroller targetId={testID} onScroll={handleScrollTo} />
      </Context.Provider>
    </KeyboardAwareScrollView>
  );
}
