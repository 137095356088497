import { useInstrument } from "@gigsmart/pickle/support/utils/instrumentation-client";
import { createLogger } from "@gigsmart/roga";
import Immutable from "immutable";
import { isEqual } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Column from "../../quarks/Column";
import { useStyle } from "../../style";
import Toast, { type ToastProps } from "./Toast";
import { toast } from "./actions";
import eventStore, { type ToastSelector } from "./eventStore";

let toastId = 0;

interface Props {
  disabled?: boolean;
  instrument?: boolean;
}

export default function ToastProvider({ disabled, instrument = false }: Props) {
  const trackedToasts = useRef<Map<number, Omit<ToastProps, "id">>>(new Map());
  const containerStyle = useStyle(({ getUnits }) => ({
    top: getUnits(2),
    left: getUnits(2),
    right: getUnits(2),
    bottom: getUnits(2),
    position: "absolute",
    overflow: "hidden"
  }));

  const [toastQueue, setToastQueue] = useState(
    Immutable.OrderedMap<number, Omit<ToastProps, "id">>()
  );
  const [toastBlocks, setToastBlocks] = useState(
    Immutable.Set<ToastSelector>()
  );

  eventStore.useEventListener("toast", (event) => {
    if (disabled) return;
    const id = toastId++;
    const spec = {
      ...event,
      icon: event.icon ?? "bell",
      dismissable: event.dismissable ?? true,
      dismissAfter: event.sticky ? undefined : event.dismissAfter,
      dismiss: () => setToastQueue((queue) => queue.remove(id))
    };
    logger.debug("toast", spec);
    if (process.env.IS_TESTING === "true") {
      trackedToasts.current.set(id, spec);
      setTimeout(() => trackedToasts.current.delete(id), 60_000);
    }
    setToastQueue((toastQueue) => {
      const shouldSkip = spec.label
        ? toastQueue.some((queuedToast, id) => {
            if (!queuedToast.exclusive || !spec.label || !queuedToast.label) {
              return false;
            }
            return matchesSelector(
              { id, ...queuedToast },
              { label: spec.label }
            );
          })
        : false;
      return shouldSkip ? toastQueue : toastQueue.set(id, spec);
    });
    return spec.dismiss;
  });

  eventStore.useEventListener("dismiss", (selector) => {
    logger.debug("dismiss", selector);
    setToastQueue((queue) =>
      queue.filter((toast, id) => !matchesSelector({ id, ...toast }, selector))
    );
  });

  eventStore.useEventListener("block", (selector) => {
    logger.debug("block", selector);
    setToastBlocks((blocks) => blocks.add(selector));
  });

  eventStore.useEventListener("unblock", (selector) => {
    logger.debug("unblock", selector);
    setToastBlocks((blocks) =>
      blocks.filter((block) => isEqual(block, selector))
    );
  });

  const visibleModalQueue = useMemo<ToastProps[]>(
    () =>
      toastQueue
        .filter(
          (toast, id) =>
            !toastBlocks.some((selector) =>
              matchesSelector({ id, ...toast }, selector)
            )
        )
        .toArray()
        .map(([id, toast]) => ({ id, ...toast }))
        .reverse(),
    [toastQueue, toastBlocks]
  );

  useInstrument("toast", undefined, ({ message, ...options }) =>
    toast(message, options)
  );

  const getToastMessages = useCallback(
    () =>
      [...trackedToasts.current.values()].map(({ message }) => {
        if (message === null) return "message";
        if (typeof message === "string") return message;
        if (typeof message === "object" && "detail" in message) {
          if (typeof message.detail === "string")
            return [message.title, message.detail].join(" ");
          return message.title;
        }
        return "";
      }),
    []
  );

  useInstrument(
    "didToastsHaveText",
    instrument ? undefined : "none",
    (text: string) => getToastMessages().some((message) => message === text)
  );

  useInstrument(
    "didToastsContainText",
    instrument ? undefined : "none",
    (text: string) =>
      getToastMessages().some((message) => message.includes(text))
  );

  useInstrument("clearTrackedToasts", instrument ? undefined : "none", () => {
    trackedToasts.current.clear();
  });

  useInstrument("dismissAllToasts", instrument ? undefined : "none", () => {
    setToastQueue((queue) => queue.clear());
  });

  if (!toastQueue.size || process.env.IS_TESTING === "true") return null;

  return (
    <Column
      style={containerStyle}
      alignItems="flex-end"
      justifyContent="flex-start"
      rowGap="small"
      pointerEvents="box-none"
      zIndex={4}
    >
      {visibleModalQueue.map((toast) => (
        <Toast key={`toast.${toast.id}`} {...toast} />
      ))}
    </Column>
  );
}

function matchesSelector(toast: ToastProps, selector: ToastSelector) {
  return (
    ("all" in selector && selector.all) ||
    ("label" in selector && selector.label === toast.label) ||
    ("id" in selector && selector.id === toast.id)
  );
}

const logger = createLogger("🍞", "Toast");
