import type { LatLng } from "react-native-maps";

export type AnyLatLng = LatLng | google.maps.LatLngLiteral;

export function latlng(obj: LatLng | AnyLatLng): LatLng {
  if ("latitude" in obj) return obj;
  return { latitude: obj.lat, longitude: obj.lng };
}

export function sameLatLng(a: LatLng, b: LatLng): boolean {
  return a.latitude === b.latitude && a.longitude === b.longitude;
}

latlng.toLiteral = (obj: LatLng): google.maps.LatLngLiteral => ({
  lat: obj?.latitude,
  lng: obj?.longitude
});

export type GoogleMapViewRefType = {
  panTo: (latLng: LatLng) => void;
};
