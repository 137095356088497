import React from "react";
import IconSquareButton from "../atoms/IconSquareButton";
import { showAgeVerificationModal } from "../molecules/AgeVerificationModalContent";
import type { Color } from "../style/theme/colors";

interface Props {
  dob?: string;
  driversLicense?: string;
  issueDate?: string;
  expirationDate?: string;
  onSave: (
    dob: string,
    issueDate: string,
    expirationDate: string,
    driversLicense: string
  ) => void;
  labelColor?: Color;
  minimumAge?: number;
}

export default function AgeVerificationInput({
  onSave,
  dob,
  driversLicense,
  labelColor,
  issueDate,
  expirationDate,
  minimumAge
}: Props) {
  return (
    <IconSquareButton
      testID="verify-age-input-btn"
      color="primary"
      label="Verify Age"
      labelColor={labelColor}
      icon="id-card"
      onPress={() =>
        showAgeVerificationModal({
          dob,
          driversLicense,
          minimumAge,
          issueDate,
          expirationDate,
          onSave
        })
      }
    />
  );
}
