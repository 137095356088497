import hoistNonReactStatics from "hoist-non-react-statics";
import {
  Component,
  type ComponentClass,
  type ComponentProps,
  type ComponentType,
  type ForwardRefExoticComponent,
  type ForwardRefRenderFunction,
  type FunctionComponent,
  type PropsWithoutRef,
  type ReactNode,
  type Ref,
  type RefAttributes,
  forwardRef
} from "react";
import getComponentDisplayName from "./get-component-display-name";
import getWrappedComponent from "./get-wrapped-component";

export type HOComponentType<Props, RefType> =
  | ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<RefType>>
  | ComponentType<Props>;

export default function applyHOCProperties<
  InjectedProps,
  InnerComponent extends ComponentType<
    ComponentProps<InnerComponent> & InjectedProps
  > = ComponentType<InjectedProps>,
  OuterProps = JSX.LibraryManagedAttributes<
    InnerComponent,
    Omit<ComponentProps<InnerComponent>, keyof InjectedProps>
  >,
  RefType = InnerComponent extends ComponentClass<any>
    ? InstanceType<InnerComponent>
    : unknown
>({
  displayName,
  WrappedComponent,
  HigherOrderComponent
}: {
  displayName: string;
  WrappedComponent: InnerComponent;
  HigherOrderComponent:
    | ForwardRefRenderFunction<RefType, OuterProps>
    | ComponentClass<OuterProps>;
}): HOComponentType<OuterProps, RefType> {
  const FinalComponent = hoistNonReactStatics(
    HigherOrderComponent.prototype instanceof Component
      ? (HigherOrderComponent as ComponentClass<OuterProps>)
      : HigherOrderComponent.length === 2
        ? forwardRef(
            (
              props: OuterProps & { children?: ReactNode },
              ref: Ref<RefType | null>
            ) =>
              (
                HigherOrderComponent as ForwardRefRenderFunction<
                  RefType,
                  OuterProps
                >
              )(props, ref)
          )
        : (HigherOrderComponent as FunctionComponent<OuterProps>),
    WrappedComponent
  );

  FinalComponent.displayName = getComponentDisplayName(
    displayName,
    WrappedComponent
  );

  FinalComponent.defaultProps = WrappedComponent.defaultProps as
    | any
    | Partial<OuterProps>
    | undefined;

  Object.assign(FinalComponent, {
    WrappedComponent: getWrappedComponent(WrappedComponent)
  });

  if (
    Object.prototype.hasOwnProperty.call(WrappedComponent, "WrappedComponent")
  ) {
    Object.assign(FinalComponent, { NextDecorated: WrappedComponent });
  }

  return FinalComponent as HOComponentType<OuterProps, RefType>;
}
