import React from "react";
import { StyleSheet } from "react-native";
import Icon from "../../quarks/Icon";
import Pressable from "../../quarks/Pressable";
import Row from "../../quarks/Row";
import Text from "../../quarks/Text";
import { useStyles } from "../../style";
import ContentArea from "../ContentArea";
import FacebookLogo from "./facebook-logo";
import GoogleLogo from "./google-logo";

export type Brands = "facebook" | "google" | "apple" | "email";

export interface Props {
  label: string;
  brand: Brands;
  appleFont?: boolean;
  onPress?: () => void;
}

export default function BrandButton({
  brand,
  label,
  appleFont,
  onPress
}: Props) {
  const styles = useStyles(
    ({ getUnits, getFontSize, getColor }) => ({
      container: {
        borderWidth: 1,
        borderColor: getColor(appleFont ? "black" : "primary", "fill"),
        borderRadius: getUnits(1),
        ...(appleFont && {
          borderWidth: StyleSheet.hairlineWidth
        })
      },
      containerHovered: {
        backgroundColor: getColor("primary", "fill", { opacity: 0.08 })
      },
      containerPressed: {
        opacity: 0.2
      },
      svgIcon: {
        width: getUnits(4.75),
        height: getUnits(4.75),
        justifyContent: "center",
        alignItems: "center"
      },
      text: {
        flex: 1,
        textAlign: "center",
        ...getFontSize(appleFont ? 4.75 : 3.5),
        ...(appleFont && {
          fontWeight: "500",
          fontFamily: "System"
        })
      }
    }),
    [appleFont]
  );
  const icon =
    brand === "google" ? (
      <GoogleLogo style={styles.svgIcon} />
    ) : brand === "facebook" ? (
      <FacebookLogo style={styles.svgIcon} />
    ) : brand === "apple" ? (
      <Icon name="apple" />
    ) : (
      <Icon name="square-envelope" variant="solid" color="primary" />
    );

  return (
    <Pressable
      testID={`${brand}-btn`}
      eventEntityType="BrandButton"
      eventTargetName="Brand Button"
      style={({ pressed, hovered }) => [
        styles.container,
        hovered && styles.containerHovered,
        pressed && styles.containerPressed
      ]}
      onPress={onPress}
    >
      <ContentArea>
        <Row alignItems="center">
          {icon}
          <Text
            style={styles.text}
            selectable={false}
            weight={!appleFont ? "bold" : undefined}
            color={!appleFont ? "primary" : "black"}
          >
            {label}
          </Text>
        </Row>
      </ContentArea>
    </Pressable>
  );
}
