import React, { type ReactNode } from "react";
import { useMatchesViewport } from "../style";
import type { ThemeableMediaThunk } from "../style/theme/media";
interface Props {
  children: ReactNode;
  media: ThemeableMediaThunk;
}

export default function Visible({ children, media }: Props) {
  return useMatchesViewport(media) ? <>{children}</> : null;
}
