import React, { memo, type ReactNode } from "react";
import { StyleSheet } from "react-native";
import { Column, Text, getSpaceUnits } from "../../quarks";

type Props = {
  cellWidth?: number;
  cellHeight?: number;
  cellAlign?: "flex-start" | "center" | "flex-end";
  action?: boolean;
  children?: ReactNode;
};

/// here for performance reasons
const styles = StyleSheet.create({
  container: {
    minHeight: 44,
    overflow: "hidden",
    paddingHorizontal: getSpaceUnits("standard"),
    paddingVertical: getSpaceUnits("compact")
  },
  cellAction: {
    alignItems: "flex-end",
    paddingHorizontal: getSpaceUnits("compact"),
    paddingVertical: getSpaceUnits("compact")
  }
});

export const CellBody = memo(function CellBody({
  cellWidth,
  cellHeight,
  cellAlign = "flex-start",
  action,
  children
}: Props) {
  children = typeof children === "string" ? <Text>{children}</Text> : children;
  return (
    <Column
      style={[
        styles.container,
        action && styles.cellAction,
        { width: cellWidth, minHeight: cellHeight }
      ]}
      alignItems={action ? "flex-end" : cellAlign}
      justifyContent="center"
    >
      {children}
    </Column>
  );
});
