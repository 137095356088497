import React, { type ComponentProps } from "react";
import Button, { type ButtonVariant } from "../atoms/Button";
import ContentArea from "../atoms/ContentArea";
import IconSquare from "../atoms/IconSquare";
import Surface from "../atoms/Surface";
import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";
import type { Color } from "../style/theme/colors";

export const TEST_IDS = {
  countText: "snackbar-count-text",
  labelText: "snackbar-label-text",
  button: "snackbar-button"
};

interface Props {
  count?: number;
  label: string;
  icon?: IconName;
  buttonLabel?: string;
  buttonColor?: Color;
  buttonVariant?: ButtonVariant | "outline";
  color?: Color;
  onPress?: () => void;
  onDismiss?: () => void;
  surfaceVariant?: ComponentProps<typeof Surface>["variant"];
}

export default function Snackbar({
  count,
  icon,
  label,
  buttonLabel,
  buttonColor = "primary",
  buttonVariant = "solid",
  color = "danger",
  onPress,
  onDismiss,
  surfaceVariant
}: Props) {
  return (
    <Surface variant={surfaceVariant}>
      <ContentArea size="compact" horizontal alignItems="center" gap="compact">
        {!!icon && (
          <IconSquare icon={icon} color={color} size="medium" variant="well" />
        )}
        {count && (
          <Text testID={TEST_IDS.countText} color={color} weight="bold">
            {count}
          </Text>
        )}
        <Text testID={TEST_IDS.labelText} color={color} numberOfLines={1} fill>
          {label}
        </Text>

        {!!buttonLabel && onPress && (
          <Button
            testID={TEST_IDS.button}
            size="small"
            color={buttonColor}
            label={buttonLabel}
            variant={buttonVariant !== "outline" ? buttonVariant : undefined}
            icon="chevron-right"
            iconPlacement="right"
            outline={buttonVariant === "outline"}
            onPress={onPress}
          />
        )}
        {onDismiss && (
          <Pressable
            testID="snackbar-dismiss"
            onPress={onDismiss}
            eventTargetName="Snackbar Dismiss Button"
            eventEntityType="snackbar-dismissed"
          >
            <Icon name="times" color="neutral" variant="solid" size="small" />
          </Pressable>
        )}
      </ContentArea>
    </Surface>
  );
}
