import React, { type ReactNode, createContext, useContext } from "react";
import type { TextVariant } from "../quarks/Text";
import { useThunk } from "../utils/thunk";
import { useTheme } from "./theme";
import type {
  Color,
  ColorProp,
  ColorSpec,
  ThemeableColorThunk
} from "./theme/colors";

interface ColorPlacementInfo {
  name?: ColorProp;
  color?: Color;
  contrastColor?: Color;
  spec?: ColorSpec;
  use?: "fill" | "placement";
}

const ColorPlacementContext = createContext<ColorPlacementInfo>({});

export interface Props {
  use?: keyof ColorSpec;
  color: ThemeableColorThunk | string;
  children: ReactNode;
}

const autoThreshold = 0.6;

export function ColorPlacementProvider({
  color,
  use = "placement",
  children
}: Props) {
  const theme = useTheme();

  const nextColorSpec = useThunk(
    typeof color === "string" ? theme.getColorSpec(color as Color, use) : color,
    [theme.colors],
    [color]
  );
  if (
    typeof nextColorSpec === "undefined" ||
    (typeof nextColorSpec === "object" && !nextColorSpec?.[use])
  ) {
    return <>{children}</>;
  }

  return (
    <ColorPlacementContext.Provider
      value={{
        spec: nextColorSpec,
        use,
        name: color as ColorProp,
        color: nextColorSpec[use]
      }}
    >
      {children}
    </ColorPlacementContext.Provider>
  );
}

const textVariantColors: {
  [key in Extract<TextVariant, "title" | "header" | "subheader">]:
    | { name: ColorProp; use: "fill" | "placement" }
    | undefined;
} = {
  title: {
    name: "primary",
    use: "fill"
  },
  header: {
    name: "primary",
    use: "fill"
  },
  subheader: {
    name: "neutral",
    use: "fill"
  }
};

export function usePlacementColor(variant?: TextVariant): ColorPlacementInfo {
  const { getColor } = useTheme();
  const context = useContext(ColorPlacementContext);
  if (
    variant &&
    variant in textVariantColors &&
    (context.name === "surface" || context.name === "background")
  ) {
    const variantColor =
      textVariantColors[
        variant as Extract<TextVariant, "title" | "header" | "subheader">
      ];
    if (!variantColor) return context;
    return {
      ...context,
      color: getColor(variantColor.name, variantColor?.use)
    };
  }
  return context;
}
