import React, { type ComponentType } from "react";
import {
  type LoadMoreFn,
  type RefetchFnDynamic,
  usePaginationFragment
} from "react-relay";
import {
  type GraphQLTaggedNode,
  type OperationType,
  getFragment
} from "relay-runtime";
import type { FragmentContainerProps } from "./createRelayFragmentContainer";
import type { KeyType } from "./use-relay-fragment";

export type PaginationFragmentContainerInnerProps<
  F extends KeyType<unknown>,
  Q extends OperationType,
  P extends {} = {}
> = P &
  NonNullable<F[" $data"]> & {
    result: NonNullable<F[" $data"]>;
    refetch: RefetchFnDynamic<Q, F>;
    loadNext: LoadMoreFn<Q>;
    loadPrevious: LoadMoreFn<Q>;
    hasNext: boolean;
    hasPrevious: boolean;
    isLoadingNext: boolean;
    isLoadingPrevious: boolean;
  };

export function createRelayPaginationFragmentContainer<
  F extends KeyType<unknown> = never,
  Q extends OperationType = never,
  P extends {} = {}
>(
  fragment: GraphQLTaggedNode,
  Component: React.ComponentType<PaginationFragmentContainerInnerProps<F, Q, P>>
): ComponentType<FragmentContainerProps<F, P>> {
  const { name } = getFragment(fragment);

  function FragmentContainer(props: FragmentContainerProps<F, P>) {
    const {
      data,
      loadNext,
      loadPrevious,
      hasNext,
      hasPrevious,
      isLoadingNext,
      isLoadingPrevious,
      refetch // For refetching connection
    } = usePaginationFragment<Q, F>(fragment, props.fragmentRef ?? null);
    if (!data) return null;
    return (
      <Component
        {...data}
        {...props}
        result={data}
        refetch={refetch}
        loadNext={loadNext}
        loadPrevious={loadPrevious}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        isLoadingNext={isLoadingNext}
        isLoadingPrevious={isLoadingPrevious}
      />
    );
  }

  FragmentContainer.displayName = `RelayPaginationFragmentContainer[${name}]`;

  return FragmentContainer;
}
