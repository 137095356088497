import {
  type Middleware,
  type MiddlewareNextFn,
  RelayNetworkLayerRequestBatch,
  type RelayNetworkLayerResponse,
  type RelayRequestAny
} from "react-relay-network-modern/es";
import type { PayloadError } from "../../payload-error";

const errorCodesMiddleware =
  (
    codes: string[] | "*",
    reportFn: (error: PayloadError, request: RelayRequestAny) => void
  ): Middleware =>
  (next: MiddlewareNextFn): MiddlewareNextFn =>
  async (req: RelayRequestAny): Promise<RelayNetworkLayerResponse> => {
    if (req instanceof RelayNetworkLayerRequestBatch || !req.operation.text) {
      return await next(req);
    }
    const response = await next(req);
    response.errors?.forEach((error: PayloadError) => {
      if (codes === "*" || codes.includes(error?.code ?? "")) {
        reportFn(error, req);
      }
    });
    return response;
  };

export default errorCodesMiddleware;
