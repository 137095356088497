import React, { createContext, type ReactNode, useContext } from "react";
import type { UnitSize } from "../quarks";
import ContentArea from "./ContentArea";
import Stack from "./Stack";

import type { ThemeMedia } from "../style/theme";

interface Props {
  children?: ReactNode;
  constraint?: keyof ThemeMedia["size"] | "none";
  constrainedVariant?: UnitSize;
  size?: UnitSize;
  variant?: UnitSize;
  gap?: UnitSize;
}

const Context = createContext<UnitSize | undefined>(undefined);

export const useGridGap = () => useContext(Context);

export default function GridContainer({
  children,
  size = "none",
  constraint,
  constrainedVariant,
  variant = "standard",
  gap = "standard"
}: Props) {
  return (
    <Context.Provider value={gap}>
      <ContentArea
        size={size}
        constraint={constraint}
        variant={variant}
        constrainedVariant={constrainedVariant}
      >
        <Stack size={gap}>{children}</Stack>
      </ContentArea>
    </Context.Provider>
  );
}
