import { ArgumentIcon, SettingsIcon, StarIcon } from "@graphiql/react";
import React, { useState, useCallback, type ReactElement } from "react";
import Modal from "react-modal";
import Iql from "./iql";
import Login from "./login";
import SetCapabilities from "./set-capabilities";
import SetToken from "./set-token";

export default function Main() {
  const [modalContent, setModalContent] = useState<{
    title: string;
    body: ReactElement;
  } | null>(null);
  const closeModal = useCallback(
    () => setModalContent(null),
    [setModalContent]
  );
  const showTokenModal = useCallback(
    () =>
      setModalContent({
        title: "Set Token",
        body: <SetToken onComplete={closeModal} />
      }),
    [closeModal]
  );
  const showCapabilitiesModal = useCallback(
    () =>
      setModalContent({
        title: "Set Capabilities",
        body: <SetCapabilities onComplete={closeModal} />
      }),
    [closeModal]
  );
  const showLoginModal = useCallback(
    () =>
      setModalContent({
        title: "Visualize Graph",
        body: <Login onComplete={closeModal} />
      }),
    [closeModal]
  );
  return (
    <>
      <Iql
        buttons={[
          {
            label: "Set Token",
            title: "Set Token",
            onClick: showTokenModal,
            IconComponent: SettingsIcon
          },
          {
            label: "Login",
            title: "Login",
            onClick: showLoginModal,
            IconComponent: ArgumentIcon
          },
          {
            label: "Capabilities",
            title: "Capabilities",
            onClick: showCapabilitiesModal,
            IconComponent: StarIcon
          }
        ]}
      />
      <Modal
        isOpen={modalContent !== null}
        style={{ overlay: { backgroundColor: "#000000cc", zIndex: 99 } }}
        onRequestClose={closeModal}
        contentLabel={modalContent?.title}
      >
        {modalContent?.body ?? null}
      </Modal>
    </>
  );
}
