import { useEventer } from "@gigsmart/dekigoto";
import { useCallback, useEffect, useRef, useState } from "react";
import { Keyboard } from "react-native";

export interface InputFocusOptions {
  value: string;
  focused?: boolean | null | undefined;
  disabled?: boolean | null | undefined;
  onFocus?: (e?: any) => unknown;
  onBlur?: (e?: any) => unknown;
  onChangeText?: (value: string) => void;
  targetName?: string;
}

export interface InputFocusProps {
  onFocus: (e?: any) => void;
  onBlur: (e?: any) => void;
  onCancel: (e?: any) => void;
}

const useInputFocus = ({
  targetName,
  value,
  disabled,
  focused,
  onChangeText,
  onFocus: _onFocus,
  onBlur: _onBlur
}: InputFocusOptions): [boolean, InputFocusProps] => {
  const [isFocused, setFocused] = useState(false);
  const valueOnFocus = useRef<string | null>(value);
  const trackBlurred = useEventer("blurred", targetName ?? "Unknown", "Input");

  const onFocus = useCallback(
    (e: any) => {
      if (disabled) return;
      valueOnFocus.current = value;
      setFocused(true);
      if (_onFocus) _onFocus(e);
    },
    [_onFocus, disabled, value]
  );

  const onBlur = useCallback(
    (e: any) => {
      valueOnFocus.current = null;
      setFocused(false);
      if (_onBlur) _onBlur(e);
      trackBlurred();
    },
    [_onBlur, trackBlurred]
  );

  const onCancel = useCallback(() => {
    Keyboard.dismiss();
    if (onChangeText) onChangeText(valueOnFocus.current ?? "");
  }, [onChangeText]);

  useEffect(() => {
    setFocused(focused === true);
  }, [focused]);

  return [isFocused, { onFocus, onBlur, onCancel }];
};

export default useInputFocus;
