import * as Immutable from "immutable";
import { isEqual } from "lodash";
/* eslint-disable react/prop-types */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useCallback,
  type ReactNode,
  useContext,
  useState
} from "react";
import { FormContext } from "./form-context";
import { collectErrors, mapToObject, objectToMap } from "./helpers";
import {
  Director,
  type FormInitFn,
  type FormRespondFn,
  type FormSceneType,
  Initer,
  Responder,
  defaultScene
} from "./scene";
import type { ValueMap, ValueObject } from "./types";

export interface FomuOnSubmitArgs {
  values: ValueObject;
  rawValues: ValueMap;
  valid: boolean;
  errors: Error[] | null | undefined;
  dirty: boolean;
}

export type FomuSubmitFn = (
  changeset: FomuOnSubmitArgs,
  done: () => void,
  reset: (initialValuesOverride?: ValueObject) => void
) => void | Promise<void>;

export type FomuOnChangeFn = (changeset: FomuOnSubmitArgs) => void;

export interface FormProps {
  onSubmit: FomuSubmitFn;
  onChange?: FomuOnChangeFn;
  onErrors?: (changeset: { errors: Error[] | null | undefined }) => void;
  onDirty?: (isDirty: boolean) => void;
  onReset?: (values: ValueObject) => void;
  initialValues?: Record<string, any>;
  fieldPrefix?: string;
  debug?: boolean;
  children: ReactNode;
  checkRemovedFields?: boolean;
  contextKey?: string;
  automaticallyCallReset?: boolean;
}

export type FormHandlersType = FormSceneType & {
  act?: InstanceType<typeof Director>["act"];
  reset: () => void;
  setValues: (values: ValueObject, merge?: boolean) => void;
};

export const useFormRef = () => {
  return useRef<FormHandlersType | null>(null);
};

export const Form = forwardRef<FormHandlersType, FormProps>(
  (
    {
      onSubmit,
      onChange,
      onDirty,
      onReset,
      children,
      fieldPrefix,
      onErrors,
      initialValues: iv = {},
      checkRemovedFields = false,
      contextKey,
      debug = false,
      automaticallyCallReset = true
    }: FormProps,
    ref
  ) => {
    const [formContextValues, setFormContextValues, removeFormContextValues] =
      useContext(FormContext);
    const directorRef = useRef<InstanceType<typeof Director> | null>(null);
    const [initialValues, setInitialValues] = useState<ValueMap>(
      objectToMap(iv)
    );
    const initialValuesIncludingContext = useMemo(() => {
      if (contextKey) {
        const contextValues = formContextValues[contextKey];
        return contextValues ? objectToMap(contextValues) : initialValues;
      }
      return initialValues;
    }, [initialValues, contextKey, formContextValues]);

    // Handle submission
    const handleSubmit = useCallback<FormRespondFn>(
      async (nextData, act) => {
        act("submitting", (scene) => ({ ...scene, submitting: true }));
        const finalValues = mapToObject(nextData.values);
        const valid = !(nextData as { invalid: boolean }).invalid;
        const errors = collectErrors(nextData.errors, fieldPrefix);
        const dirty = nextData.dirty;
        if (errors && errors.length > 0) {
          act("submitting", (data: FormSceneType) => ({
            ...data,
            submitting: false
          }));
          return;
        }

        let calledDone = false;
        let calledReset = false;

        const done = () => {
          act("submitting", (data: FormSceneType) => ({
            ...data,
            submitting: false
          }));
          calledDone = true;
        };

        const reset = (valuesOverride: typeof finalValues = finalValues) => {
          if (contextKey) removeFormContextValues(contextKey);

          const nextInitialValues = objectToMap(valuesOverride);
          const nextDirty = !valuesEqual(nextInitialValues, nextData.values);

          setInitialValues(nextInitialValues);
          act("dirty", (data: FormSceneType) => ({
            ...data,
            dirty: nextDirty
          }));
          calledReset = true;
        };

        const maybePromise = onSubmit(
          {
            values: finalValues,
            rawValues: nextData.values,
            valid,
            errors,
            dirty
          },
          done,
          reset
        );

        if (maybePromise && "then" in maybePromise) {
          try {
            await Promise.resolve(maybePromise);
            if (automaticallyCallReset && !calledReset) reset();
          } finally {
            if (!calledDone) done();
          }
        }
      },
      [fieldPrefix, onSubmit, contextKey, removeFormContextValues]
    );

    const handleReset = useCallback<FormRespondFn>((_, act) => {
      if (contextKey) {
        removeFormContextValues(contextKey);
      }
      act("values", (scene) => {
        return { ...scene, values: scene.initialValues };
      });
    }, []);

    useImperativeHandle<FormHandlersType, FormHandlersType>(ref, () => ({
      ...(directorRef.current?.scene ?? defaultScene),
      act: directorRef.current?.act ?? (() => undefined),
      reset: () => {
        directorRef.current?.act("reset");
        onReset?.(iv);
      },
      setValues: (rawValues: ValueObject, merge = false) => {
        directorRef.current?.act("values", (scene) => ({
          ...scene,
          values: merge
            ? scene.values.merge(objectToMap(rawValues))
            : objectToMap(rawValues)
        }));
      }
    }));

    const handleChange = useCallback<FormRespondFn>(
      async (nextData) => {
        if (contextKey) {
          const nextValues = mapToObject(nextData.values);
          setFormContextValues(contextKey, nextValues);
        }
        if (!onChange) return;
        const nextValues = mapToObject(nextData.values);
        const errors = collectErrors(nextData.errors, fieldPrefix);
        const dirty =
          !valuesEqual(initialValues, nextData.values) ||
          (checkRemovedFields && nextValues.size !== initialValues.size);

        onChange({
          values: nextValues,
          rawValues: nextData.values,
          valid: errors.length === 0,
          errors,
          dirty
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fieldPrefix, onChange, setFormContextValues, contextKey, initialValues]
    );

    // When values update, update the dirty flag
    const handleDirty = useCallback<FormRespondFn>(
      async ({ values: nextValues, dirty: prevDirty }, act) => {
        const nextDirty =
          !valuesEqual(initialValues, nextValues) ||
          (checkRemovedFields && nextValues.size !== initialValues.size);

        if (prevDirty !== nextDirty) {
          // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
          if (onDirty) await Promise.resolve(onDirty(nextDirty));
          act("dirty", (data: FormSceneType) => ({
            ...data,
            dirty: nextDirty
          }));
        }
      },
      [checkRemovedFields, initialValues, onDirty]
    );

    // When errors update, update the invalid flag
    const handleInvalid = useCallback<FormRespondFn>(
      async ({ errors, invalid: prevInvalid }, act) => {
        const allErrors = collectErrors(errors, fieldPrefix);
        const nextInvalid = allErrors.length > 0;
        if (prevInvalid !== nextInvalid) {
          act("invalid", (data) => ({ ...data, invalid: nextInvalid }));
          if (onErrors) onErrors({ errors: allErrors });
        }
      },
      []
    );

    const handleInit = useCallback<FormInitFn>((_, act) => {
      act("values", (data) => ({
        ...data,
        values: initialValuesIncludingContext.merge(data.values)
      }));
      act("errors");
    }, []);

    return (
      <Director
        ref={directorRef}
        debug={debug}
        initialScene={{
          ...defaultScene,
          initialValues,
          values: initialValuesIncludingContext,
          dirty: false
        }}
      >
        <Responder eventType="submit" onResponse={handleSubmit} />
        <Responder eventType="values" onResponse={handleDirty} />
        <Responder eventType="errors" onResponse={handleInvalid} />
        <Responder eventType="errors" onResponse={handleChange} />
        <Responder eventType="reset" onResponse={handleReset} />
        {children}
        <Initer onInit={handleInit} />
      </Director>
    );
  }
);

// helpers:

// if a key doesn't exist, then they are still equal
const valuesEqual = (prevValues: any, nextValues: any): boolean => {
  if (!Immutable.Map.isMap(prevValues) || !Immutable.Map.isMap(nextValues)) {
    return isEqual(prevValues, nextValues);
  }
  return nextValues.every((v, k) => valuesEqual(prevValues.get(k), v));
};
