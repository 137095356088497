import { setFeatureUser } from "@gigsmart/feature-flags";
import { DateTime } from "luxon";
import { addIdentifyHook } from "../hooks";

export default () => {
  return addIdentifyHook(
    (_userId, { email, __typename, insertedAt, organizationId }) => {
      if (!email) return;
      setFeatureUser({
        organizationId: String(organizationId),
        emailAddress: String(email),
        userType: String(__typename),
        insertedAt: insertedAt
          ? DateTime.fromISO(String(insertedAt))
          : DateTime.fromMillis(0)
      });
    }
  );
};
