import { useInstrument } from "@gigsmart/pickle/support/utils/instrumentation-client";
import React from "react";
import {
  InputAccessoryView,
  Platform,
  TouchableOpacity,
  View
} from "react-native";
import { useStyles } from "../style";
import { StyledText } from "../text";

export const accessoryID = (() => {
  let inc = 0;
  return () => {
    inc = inc + 1;
    return `styled-input-accessory-${inc}`;
  };
})();

interface Props {
  nativeID?: string;
  onSubmit: () => void;
  onCancel?: () => void;
  attachedToKeyboard?: boolean;
  cancelText?: string;
  doneText?: string;
}

const StyledInputAccessory = ({
  doneText = "Done",
  cancelText = "Cancel",
  attachedToKeyboard = true,
  nativeID,
  onCancel,
  onSubmit
}: Props) => {
  useInstrument("accessory:confirm", null, () => onSubmit?.());
  useInstrument("accessory:cancel", null, () => onCancel?.());

  const { styles, theme } = useStyles(({ color, metric }) => ({
    accessory: {
      width: metric.deviceWidth(),
      paddingVertical: 3,
      paddingHorizontal: 5,
      flexDirection: "row",
      backgroundColor: color.neutralLight,
      justifyContent: "space-between"
    }
  }));

  const accessory = (
    <View style={styles.accessory}>
      {onCancel ? (
        <TouchableOpacity onPress={onCancel} testID="keyboard-cancel">
          <StyledText color="blue" fontSize={theme.font.size.extraLarge}>
            {cancelText}
          </StyledText>
        </TouchableOpacity>
      ) : (
        <View />
      )}
      <TouchableOpacity testID="input-done" onPress={onSubmit}>
        <StyledText color="blue" fontSize={theme.font.size.extraLarge}>
          {doneText}
        </StyledText>
      </TouchableOpacity>
    </View>
  );

  return attachedToKeyboard ? (
    Platform.OS !== "ios" ? null : (
      <InputAccessoryView nativeID={nativeID}>{accessory}</InputAccessoryView>
    )
  ) : (
    accessory
  );
};

export default StyledInputAccessory;
