import { remove } from "lodash";
import { addIdentifyHook, addTrackHook, collectHooks } from "../../hooks";

declare global {
  interface Window {
    dataLayerLL?: undefined | unknown[];
  }
}

interface User {
  userId: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPhoneNumber: string;
  userBusinessName: string;
}

export default async () => {
  let user: User | null = null;

  return collectHooks([
    addTrackHook(async (_event, properties, category) => {
      if (category !== "conversion") return;
      const listenLayer = (window.dataLayerLL ??= []);
      const event = { ...properties, ...user };
      listenLayer.push(event);
      setTimeout(remove, 300_000, listenLayer, (i) => i === event);
    }),
    addIdentifyHook(
      (userId, { firstName, lastName, email, phone, companyName }) => {
        user = {
          userId: String(userId),
          userFirstName: String(firstName),
          userLastName: String(lastName),
          userEmail: String(email),
          userPhoneNumber: String(phone),
          userBusinessName: String(companyName)
        };
      }
    )
  ]);
};
