import { each } from "lodash";
import type { ComponentType, ReactNode } from "react";
import type { LayoutChangeEvent } from "react-native";

export type GetCellHeightFn = (row: number) => {
  cellHeight: number;
  onLayout?: (e: LayoutChangeEvent) => void;
};

export type TableKey<T> = keyof T | (string & { isAttr?: never });

export type TableSortBy<T> = [TableKey<T>, "asc" | "desc"] | [null, null];

export type TableCol<T> = {
  attr?: TableKey<T>;
  header?: string | null;
  CellComponent?: ComponentType<{ data: T }>;
  renderCell?: (row: T) => ReactNode;
  sortable?: boolean;
  action?: boolean;

  align?: "flex-start" | "center" | "flex-end" | null;
  width?: number;
  minWidth?: number;
  maxWidth?: number;

  /**
   * how the column should grow:
   * 0: column will shrink to the minimum size
   * 1: grows proportionally to the available space (default)
   * N: grows by N times the available space
   */
  grow?: number;
};

export type NormalizedTableCol<T> = {
  header: string;
  cellWidth: number;
  cellAlign: "flex-start" | "center" | "flex-end";
  attr: TableKey<T> | null;
  action: boolean;
  sortable: boolean;
  renderCell?: (data: T) => ReactNode;
  CellComponent?: ComponentType<{ data: T }>;
};

/**
 * Normalize columns to be compatible with the TableView.
 * 1. Computes each column ideal width based on the table width
 */
export function normalizeColumns<T>({
  columns,
  left,
  right,
  isLg,
  tableWidth = 0,
  cellMinWidth = 0,
  cellMaxWidth
}: {
  columns?: TableCol<T>[];
  left?: TableCol<T>[] | null;
  right?: TableCol<T>[] | null;
  isLg?: boolean;
  tableWidth?: number;
  cellMinWidth?: number;
  cellMaxWidth?: number;
} = {}) {
  let totalGrow = 0;
  const sections = {
    left: [] as NormalizedTableCol<T>[],
    center: [] as NormalizedTableCol<T>[],
    right: [] as NormalizedTableCol<T>[]
  };

  if (!tableWidth || !columns?.length) return sections;

  const allColumns: Array<{
    normalizedCol: NormalizedTableCol<T>;
    col: TableCol<T>;
  }> = [];

  each({ left, right, center: columns }, (g, key) => {
    if (!g) return;

    const normalized = sections[key as keyof typeof sections];

    for (const col of g) {
      let cellWidth = 0; // will be computed based on the tableWidth and totalGrow
      const cellGrow = col.grow ?? 1;
      totalGrow += cellGrow;
      if (col.grow === 0) {
        cellWidth = Math.ceil(Math.max(col.minWidth ?? cellMinWidth, 96));
        tableWidth -= cellWidth;
      }

      const normalizedCol: NormalizedTableCol<T> = {
        renderCell: col.renderCell,
        CellComponent: col.CellComponent,
        header: col.header ?? "",
        attr: col.attr ?? null,
        sortable: col.sortable === true,
        action: col.action === true,
        cellAlign: col.align ?? "flex-start",
        cellWidth
      };

      allColumns.push({ normalizedCol, col });
      normalized.push(normalizedCol);
    }
  });

  if (!isLg) {
    sections.center.push(...sections.right);
    sections.right = [];
  }

  // adjust width for components that still need to grow
  const growUnit = tableWidth / totalGrow;
  for (const { normalizedCol, col } of allColumns) {
    if (!normalizedCol || normalizedCol.cellWidth > 0) continue;

    const maxWidth = col.maxWidth ?? cellMaxWidth ?? tableWidth;
    const minWidth = Math.max(col.minWidth ?? cellMinWidth, 96);
    const grow = col.grow ?? 1;
    normalizedCol.cellWidth = Math.max(
      minWidth,
      Math.ceil(Math.min(grow * growUnit, maxWidth))
    );
  }

  return sections;
}
