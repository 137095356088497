import React, { type ReactElement, type ReactText } from "react";
import type { ComponentProps } from "react";
import Stack from "../atoms/Stack";
import Well from "../molecules/Well";
import Icon, { type IconName } from "../quarks/Icon";
import Text from "../quarks/Text";

interface Props
  extends Omit<ComponentProps<typeof Well>, "size" | "color" | "children"> {
  testID?: string;
  header: ReactElement<typeof Text> | ReactText;
  note?: ReactElement<typeof Text> | ReactText;
  icon?: IconName;
  variant?: "primary" | "success" | "error" | "neutral" | "info" | "danger";
  alignSelf?: "flex-start" | "flex-end" | "center";
}

export default function HighlightedReminder({
  icon,
  header,
  note,
  variant = "primary",
  testID,
  ...rest
}: Props) {
  const color = variant === "neutral" ? undefined : variant;
  return (
    <Well size="compact" color={variant} {...rest}>
      <Stack horizontal size="standard" alignItems="center" testID={testID}>
        {icon && (
          <Icon name={icon} color={color} size="medium" variant="solid" />
        )}
        <Stack size="small" fill>
          <Text
            weight="semibold"
            color={color}
            testID={`${testID}-header`}
            align={icon ? undefined : "center"}
          >
            {header}
          </Text>
          {!!note && (
            <Text variant="note" color={color}>
              {note}
            </Text>
          )}
        </Stack>
      </Stack>
    </Well>
  );
}
