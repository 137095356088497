import { getOriginalDisplayName } from "@gigsmart/hoc-utils";
import firebase from "@react-native-firebase/app";
import { snakeCase, truncate } from "lodash";
import { Platform } from "react-native";
import { addScreenHook, addTrackHook, collectHooks } from "../hooks";

const firebaseName = (name: string) =>
  truncate(snakeCase(name), { omission: "", length: 40 })
    .replace("🐼", "panda")
    .replace(/(\p{Emoji}|[^a-zA-Z0-9_])/gu, "");

export default () => {
  return collectHooks(
    Platform.OS !== "web"
      ? [
          addTrackHook((event, properties) => {
            firebase
              ?.analytics?.()
              ?.logEvent?.(firebaseName(event), properties)
              ?.catch(console.warn);
          }),
          addScreenHook((name, _, Component) => {
            const componentDisplayName =
              Component && getOriginalDisplayName(Component);
            if (Platform.OS !== "web") {
              firebase
                ?.analytics?.()
                ?.logScreenView?.({
                  screen_name: firebaseName(name),
                  screen_class: componentDisplayName as any
                })
                ?.catch(console.warn);
            }
          })
        ]
      : []
  );
};
