import React, { type ReactElement } from "react";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Icon from "../quarks/Icon";
import type { IconName, IconVariant } from "../quarks/Icon";
import Text from "../quarks/Text";
import type { Color } from "../style/theme/colors";
import type { OnlyChildren, TestIDProps } from "../utils/types";

interface Props
  extends TestIDProps,
    OnlyChildren<string | ReactElement<Text> | null> {
  iconName: IconName;
  iconColor?: Color;
  iconVariant?: IconVariant;
}

/** @deprecated use ListEmpty */
export default function ListEmptyInfo({
  iconName,
  iconColor = "primary",
  iconVariant = "light",
  children,
  testID
}: Props) {
  return (
    <Column testID={testID} justifyContent="center" fill>
      <Stack alignItems="center">
        <Icon name={iconName} color={iconColor} variant={iconVariant} />
        {typeof children === "string" ? <Text>{children}</Text> : children}
      </Stack>
    </Column>
  );
}
