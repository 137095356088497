import React from "react";

import {
  Button,
  ContentArea,
  IconCircle,
  ModalHeader,
  Stack
} from "../../atoms";
import { Row, Spacer, Text } from "../../quarks";
import { showModal } from "../ModalProvider";

interface Props {
  onPhotoPress: () => void;
  onDocumentPress: () => void;
  onClose?: () => void;
}

export function showDocumentTypeSelectionModal({
  onPhotoPress,
  onDocumentPress
}: Props) {
  return showModal({
    eventContext: "documentTypeSelectionModal",
    variant: "shadow",
    fixedHeight: 320,
    children: (close) => (
      <DocumentTypeSelectionModal
        onClose={close}
        onPhotoPress={onPhotoPress}
        onDocumentPress={onDocumentPress}
      />
    )
  });
}

function DocumentTypeSelectionModal({
  onClose,
  onPhotoPress,
  onDocumentPress
}: Props) {
  return (
    <>
      <ModalHeader onClose={onClose} />
      <ContentArea>
        <Stack justifyContent="center" alignItems="center">
          <Text variant="header" weight="bold" align="center" color="primary">
            Upload Photos & Documents
          </Text>
          <IconCircle
            icon="upload"
            color="primary"
            size="medium"
            variant="well"
          />
          <Text>
            Select whether you want to upload photos or documents. JPEG, PNG,
            HEIC and PDF formats are supported.
          </Text>
        </Stack>
        <ContentArea variant="none">
          <Row fill justifyContent="space-between">
            <Button
              label="Upload Photos"
              variant="clear"
              outline
              size="small"
              testID="document-type-selection-modal-photo-button"
              fill
              onPress={() => {
                onClose?.();
                setTimeout(onPhotoPress, 600, undefined);
              }}
            />
            <Spacer size="compact" horizontal />
            <Button
              label="Upload Documents"
              variant="clear"
              outline
              size="small"
              testID="document-type-selection-modal-document-button"
              fill
              onPress={() => {
                onClose?.();
                setTimeout(onDocumentPress, 600, undefined);
              }}
            />
          </Row>
        </ContentArea>
      </ContentArea>
    </>
  );
}
