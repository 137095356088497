import React, { type ReactElement } from "react";
import { useActor } from "./scene";

export interface FormResetOptions {
  allowInvalid?: boolean;
}

export interface FormResetArgs {
  reset: () => void;
}

export function useFormReset({
  allowInvalid = false
}: FormResetOptions = {}): FormResetArgs {
  // The reset callback
  const resetRaw = useActor<typeof undefined>("reset");
  const reset = (e?: any) => {
    e?.preventDefault?.();
    resetRaw();
  };

  return { reset };
}

interface Props {
  children: (args: FormResetArgs) => ReactElement;
}

export const FormReset = React.memo<Props>(function FormReset({
  children
}: Props) {
  const props = useFormReset();
  return children(props);
});
