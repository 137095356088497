/* eslint-disable react-native/no-color-literals */
import { UrlDebugFeature } from "@gigsmart/feature-flags";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { navigationRef } from "./delegate";
import { useCurrentUrl } from "./helpers";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    minWidth: 36,
    backgroundColor: "#fff",
    borderRadius: 5,
    opacity: 0.8,
    padding: 2,
    top: 0,
    zIndex: 100,
    alignSelf: "center"
  },
  label: {
    textAlign: "center",
    color: "#dc4405",
    fontWeight: "bold",
    fontSize: 13
  },
  labelsm: {
    textAlign: "center",
    color: "#003b5c",
    fontSize: 11,
    fontWeight: "bold"
  }
});

export function RouteWatcher() {
  const inset = useSafeAreaInsets();

  const url = useCurrentUrl();
  const [routeName, setRouteName] = useState(
    () => navigationRef.getCurrentRoute()?.name
  );
  const urlRef = useRef(url);
  const enabled = RouteWatcher.enabled();

  useEffect(() => {
    if (urlRef.current !== url) {
      console.debug(
        "[ROUTE CHANGE]",
        `navigate from "${urlRef.current}" to "${url}"`
      );
      urlRef.current = url;
      setRouteName(navigationRef.getCurrentRoute()?.name);
    }
  }, [url]);

  return (
    <View
      pointerEvents="none"
      style={[styles.container, { top: Math.max(inset.top - 8, 0) }]}
    >
      <Text testID="route-watcher-pathname" style={styles.label}>
        {url}
      </Text>
      <Text testID="route-watcher-routename" style={styles.labelsm}>
        ~{routeName ?? "N/A"}~
      </Text>
    </View>
  );
}

RouteWatcher.enabled = () =>
  process.env.IS_TESTING === "true" ||
  process.env.CONFIG_ENV === "e2e" ||
  !(process.env.DEBUG_URLS !== "true" && UrlDebugFeature.isDisabled());
