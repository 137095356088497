import React from "react";
import { Row } from "../quarks";
import FilePickerButton from "./FilePickerButton";
import FilePreview from "./FilePreview";
import type { FileItem, MediaPickerFile } from "./MediaPicker";

interface Props {
  onSelect: (value: MediaPickerFile[]) => void;
  onRemove: (id: string) => void;
  selectedItems: FileItem[];
}

export default function FilePickerInput({
  onSelect,
  onRemove,
  selectedItems
}: Props) {
  return (
    <>
      <Row alignSelf="flex-start">
        <FilePickerButton onSelect={onSelect} />
      </Row>
      <FilePreview onRemove={onRemove} selectedItems={selectedItems} />
    </>
  );
}
