import React from "react";
import { Text } from "../quarks";

interface Props {
  html?: string | null;
  height?: number;
  fontSize?: number;
}

export default function WysiwygContent({ html, height, fontSize = 14 }: Props) {
  return (
    <Text numberOfLines={height ? 3 : undefined}>
      <div
        style={{ fontSize }}
        dangerouslySetInnerHTML={{ __html: html ?? "" }}
      />
    </Text>
  );
}
