import React from "react";
import { Row } from "../../quarks";
import { useStyles } from "../../style";
import type { Color } from "../../style";
import { CellHeader } from "./CellHeader";
import type {
  NormalizedTableCol,
  TableKey,
  TableSortBy
} from "./TableView.helpers";

type Props<T> = {
  variant?: "default" | "shadow";
  backgroundColor?: Color;
  columns: NormalizedTableCol<T>[];
  sortBy: TableSortBy<T>;
  onSort?: (key: TableKey<T>, dir: "asc" | "desc") => void;
};

export function TableHeader<T>({
  variant,
  columns,
  sortBy,
  onSort,
  backgroundColor = "surface"
}: Props<T>) {
  const styles = useStyles(({ getUnits, getColor }) => ({
    container: {
      backgroundColor: getColor("foreground", "fill")
    },
    shadow: {
      backgroundColor: getColor(backgroundColor, "fill"),
      shadowColor: "#000000",
      shadowOpacity: 0.15,
      shadowRadius: getUnits(0.5),
      shadowOffset: { width: 0, height: getUnits(0.5) }
    }
  }));

  const [sortKey, sortDir] = sortBy;
  const handleSort = (attr: TableKey<T> | null) => {
    if (!attr || !onSort) return;

    if (sortKey !== attr) onSort(attr, "asc");
    else onSort(attr, sortDir === "asc" ? "desc" : "asc");
  };

  const totalWidth = columns.reduce((acc, c) => acc + c.cellWidth, 0);
  return (
    <Row
      alignItems="stretch"
      style={[
        styles.container,
        variant === "shadow" && styles.shadow,
        { width: totalWidth }
      ]}
    >
      {columns.map((it, idx) => (
        <CellHeader
          key={`${idx}-${String(it.attr ?? it.header)}`}
          cellWidth={it.cellWidth}
          label={it.header}
          sort={it.sortable && sortKey === it.attr ? sortDir : null}
          onPress={
            it.attr && it.sortable && onSort
              ? () => handleSort(it.attr)
              : undefined
          }
        />
      ))}
    </Row>
  );
}
