import React, { useEffect, useMemo, useRef } from "react";
import {
  Platform,
  type TextInputProps,
  View,
  requireNativeComponent
} from "react-native";
import { RichEditor } from "react-native-pell-rich-editor";
import type { WebViewNativeConfig } from "react-native-webview/src/WebViewTypes";

import { Spacer } from "../../quarks";
import { useStyles } from "../../style";
import colors from "../../style/theme/colors";
import { fontFaceStyle } from "../../style/theme/loadFonts";
import { showModal } from "../ModalProvider";
import PromptLink from "./PromptLink";
import Toolbar from "./Toolbar";
import useWysiwyg from "./useWysiwyg";

type Props = TextInputProps & {
  nativeLinkPrompt?: boolean;
};

const nativeConfig: WebViewNativeConfig | undefined =
  Platform.OS === "android" && typeof requireNativeComponent !== "undefined"
    ? { component: requireNativeComponent("RCTCustomWebView") }
    : undefined;

const Editor = ({
  value,
  placeholder,
  onChangeText,
  onBlur,
  onFocus,
  testID,
  nativeLinkPrompt = Platform.OS !== "web"
}: Props) => {
  const editorRef = useRef<RichEditor>(null);
  const editorStyle = useMemo(() => {
    const cssText = `html, body, .content { font-size: 14px; font-family: 'Proxima Nova', sans-serif; }
a { color: ${colors.link.fill}; }
${fontFaceStyle(
  () =>
    Platform.select({
      web: require("../../fonts/ProximaNova-Regular.otf"),
      android: "file:///android_asset/fonts/ProximaNova-Regular.otf",
      ios: "ProximaNova-Regular.otf"
    }),
  "Proxima Nova",
  "normal"
)}
${fontFaceStyle(
  () =>
    Platform.select({
      web: require("../../fonts/ProximaNova-Bold.otf"),
      android: "file:///android_asset/fonts/ProximaNova-Bold.otf",
      ios: "ProximaNova-Bold.otf"
    }),
  "Proxima Nova",
  "bold"
)}`;

    return {
      backgroundColor: "transparent",
      color: "#000",
      contentCSSText:
        "padding: 12px 8px;overflow-wrap: break-word;line-height: 18px;",
      cssText
    };
  }, []);

  const styles = useStyles(({ getUnits }) => ({
    wrapper: {
      flex: 1
    },
    editor: {
      flex: 1,
      width: "100%",
      height: 200
    }
  }));

  const handleInsertLink = useMemo(() => {
    if (nativeLinkPrompt) return;

    return () => {
      showModal({
        eventContext: null,
        useModalBody: false,
        title: "Please enter the URL",
        children: (close) => (
          <PromptLink
            onClose={close}
            onSubmit={(url) => {
              if (url) {
                editorRef?.current?.insertLink("", url);
              }
            }}
          />
        )
      });
    };
  }, [nativeLinkPrompt]);

  const { setWysiwygRef } = useWysiwyg();
  useEffect(() => {
    setWysiwygRef(editorRef);
  }, [editorRef, setWysiwygRef]);

  return (
    <View style={styles.wrapper}>
      <RichEditor
        testID={testID}
        style={styles.editor}
        pasteAsPlainText
        initialContentHTML={value}
        placeholder={placeholder}
        ref={editorRef}
        onChange={onChangeText}
        hideKeyboardAccessoryView
        editorStyle={editorStyle}
        useContainer={false}
        onBlur={onBlur as any}
        onFocus={onFocus as any}
        nativeConfig={nativeConfig}
      />
      <Spacer size="compact" />
      <Toolbar editorRef={editorRef} onInsertLink={handleInsertLink} />
    </View>
  );
};

export default Editor;
