import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentClass } from "react";
import { useViewport } from "./viewport";

export interface WithBreakpointProps {
  breakpoint: string;
}

const withBreakpoint: HOCVoid = (WrappedComponent: ComponentClass<any>) =>
  applyHOCProperties({
    WrappedComponent,
    displayName: "withUser",
    HigherOrderComponent: (props) => {
      const { breakpoint } = useViewport();
      return <WrappedComponent {...props} breakpoint={breakpoint} />;
    }
  });

export default withBreakpoint;
