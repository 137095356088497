import { Form, FormField, FormSubmit } from "@gigsmart/fomu";
import React, { useCallback } from "react";
import { useRequestContext } from "./request-context";

interface Props {
  onComplete: () => void;
}

export default function SetToken({ onComplete }: Props) {
  const { token, setToken } = useRequestContext();
  const handleSubmit = useCallback(
    async ({ values }: { values: any }) => {
      setToken(values.token ?? null);
      onComplete();
    },
    [onComplete, setToken]
  );
  return (
    <form>
      <Form initialValues={{ token }} onSubmit={handleSubmit}>
        <FormField name="token">
          {({ value, setValue, name }) => (
            <div>
              <label htmlFor={name}>Set your token</label>
              <br />
              <textarea
                rows={10}
                id={name}
                name={name}
                style={{ width: 500 }}
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
            </div>
          )}
        </FormField>
        <br />
        <FormSubmit>
          {({ submit }) => (
            <input type="submit" title="save" onClick={submit} />
          )}
        </FormSubmit>
      </Form>
    </form>
  );
}
