import times from "lodash/times";
import React, { type ComponentProps } from "react";
import Stack from "../atoms/Stack";
import Icon from "../quarks/Icon";
import Pressable from "../quarks/Pressable";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
  alignSelf?: ComponentProps<typeof Stack>["alignSelf"];
}

export default function PressableRatingStars({
  value,
  onChange,
  alignSelf
}: Props) {
  const renderStar = (i: number) => {
    const index = i + 1;
    const isSelected = (value ?? 0) >= index;
    const testID = `pressable_rating_stars_${index}`;
    return (
      <Pressable
        key={testID}
        testID={testID}
        onPress={() => onChange?.(index)}
        eventEntityType="PressableRatingStar"
        eventTargetName="Pressable Rating Star"
      >
        <Icon
          key={index}
          size="default"
          name="star"
          variant={isSelected ? "solid" : "regular"}
          color={isSelected ? "star" : "neutral"}
        />
      </Pressable>
    );
  };

  return (
    <Stack alignSelf={alignSelf} horizontal size="slim">
      {times(5, renderStar)}
    </Stack>
  );
}
