import { useCallback } from "react";
import { useRelayEnvironment } from "react-relay";
import {
  type CacheConfig,
  type FetchQueryFetchPolicy,
  type GraphQLTaggedNode,
  type OperationType,
  fetchQuery
} from "relay-runtime";
import { ObserableStub } from "../orchestrator/observable-stub";
import type { RelayObservable } from "../types";
import { useRelayBatch } from "./relay-batch";

export interface FetchQueryOptions {
  fetchPolicy?: FetchQueryFetchPolicy;
  networkCacheConfig?: CacheConfig;
  batch?: boolean;
  batchKey?: string;
}

export function useFetchQuery<TOperation extends OperationType>(
  query: GraphQLTaggedNode | null,
  options: FetchQueryOptions = {}
): (
  variables: TOperation["variables"],
  fetchOptions?: FetchQueryOptions
) => RelayObservable<TOperation["response"]> {
  const environment = useRelayEnvironment();
  const { enable: batch, batchKey } = useRelayBatch(options.batch);
  return useCallback(
    (
      variables: TOperation["variables"],
      fetchOptions: FetchQueryOptions = {}
    ) => {
      if (!query) return new ObserableStub<TOperation["response"]>();
      return fetchQuery<TOperation>(environment, query, variables, {
        ...options,
        ...fetchOptions,
        networkCacheConfig: {
          ...options.networkCacheConfig,
          ...fetchOptions.networkCacheConfig,
          batch: options.batch ?? batch,
          batchKey: options.batchKey ?? batchKey
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [environment, query, batch, batchKey]
  );
}
