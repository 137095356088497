import React, { type ComponentProps } from "react";
import { Column } from "../quarks";
import { useStyle } from "../style";
import MapView from "./MapView";

const specs = {
  size: { default: 160 }
};

interface Props extends ComponentProps<typeof MapView> {
  size?: keyof typeof specs.size;
}

const InlineMapView = ({ size = "default", ...props }: Props) => {
  const mapStyle = useStyle(() => ({ borderRadius: 4, overflow: "hidden" }));
  return (
    <Column style={[mapStyle, { height: specs.size[size] }]}>
      <MapView {...props} />
    </Column>
  );
};

export default InlineMapView;
