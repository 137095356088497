import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect
} from "react";
import type { ComponentProps } from "react";
import {
  type LayoutChangeEvent,
  type LayoutRectangle,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  ScrollView,
  View
} from "react-native";
import LinearGradient from "../../atoms/LinearGradient";
import type Surface from "../../atoms/Surface";
import { useResponsiveValue, useStyles, useTheme } from "../../style";
import TabItem, { TAB_HEIGHT } from "./TabButton";

export interface Tab<TKey extends string> {
  key: TKey;
  label: string | { sm?: string; md: string; lg?: string };
  testID: string;
  showBadge?: boolean;
}

interface Props<TKey extends string> {
  tabs: Array<Tab<TKey>>;
  activeTab: TKey | null | undefined;
  onChangeTab?: (tabName: TKey) => void;
  variant?: ComponentProps<typeof Surface>["variant"];
}

export default function Tabs<TKey extends string>({
  tabs,
  activeTab,
  onChangeTab
}: Props<TKey>) {
  const { getColor } = useTheme();
  const fadeGradient = {
    colors: [
      String(getColor("black", "fill", { opacity: 0 })),
      String(getColor("white", "fill", { opacity: 0 }))
    ],
    locations: [0, 0.9],
    opacities: [0, 1],
    start: { x: 0, y: 0 },
    end: { x: 1, y: 0 }
  };
  const styles = useStyles(({ getUnits, getColor }) => ({
    wrapper: {
      height: TAB_HEIGHT
    },
    itemsContainer: { flexGrow: 1 },
    itemsContainerFill: {
      backgroundColor: getColor("surface", "fill"),
      justifyContent: "center",
      marginBottom: -1
    },
    gradient: {
      position: "absolute",
      bottom: 0,
      top: 0,
      width: getUnits(12),
      right: 0
    },
    gradientStart: {
      left: 0,
      right: undefined,
      transform: [{ rotate: "180deg" }]
    }
  }));
  const size = useResponsiveValue({ mini: "sm", medium: "md", large: "lg" });
  const scrollRef = useRef<ScrollView>(null);
  const containerWidthRef = useRef(0);
  const contentSizeRef = useRef(0);
  const scrollPosRef = useRef(0);
  const tabItemsLayout = useMemo(() => new Map<string, LayoutRectangle>(), []);
  const handledInitialScroll = useRef(false);
  const tabButtonMaxWidth = 300;
  const [fadeStart, setFadeStart] = useState(false);
  const [fadeEnd, setFadeEnd] = useState(false);

  // "isScrollable" only need to be handled if tabItemsMaxWidth is set
  const [isScrollable, setScrollable] = useState(!tabButtonMaxWidth);

  const checkScroll = useCallback(() => {
    const containerSize = containerWidthRef.current;
    const contentSize = contentSizeRef.current;
    if (!containerSize || !contentSize) return;

    let newFadeStart: boolean;
    let newFadeEnd: boolean;

    if (contentSize > containerSize) {
      const leftPos = scrollPosRef.current;
      const rightPos = leftPos + containerWidthRef.current;
      newFadeStart = leftPos > 16;
      newFadeEnd = rightPos < contentSizeRef.current - 16;
    } else {
      newFadeStart = false;
      newFadeEnd = false;
    }
    if (newFadeStart !== fadeStart) setFadeStart(newFadeStart);
    if (newFadeEnd !== fadeEnd) setFadeEnd(newFadeEnd);

    if (tabButtonMaxWidth && containerSize >= contentSize) {
      if (isScrollable) setScrollable(false);
    } else if (!isScrollable) {
      setScrollable(true);
    }
  }, [fadeStart, fadeEnd, tabButtonMaxWidth, isScrollable]);

  const scrollToTab = useCallback(
    (tab: string) => {
      const layout = tab ? tabItemsLayout.get(tab) : null;
      if (layout && scrollRef.current) {
        const halfContainer = (containerWidthRef.current || 0) / 2;
        scrollRef.current.scrollTo({
          x: Math.max(0, layout.x - halfContainer + layout.width / 2),
          animated: handledInitialScroll.current
        });
      }
    },
    [tabItemsLayout]
  );

  const handleContainerLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!e.nativeEvent) return;
      const newWidth = e.nativeEvent.layout.width;
      if (newWidth !== containerWidthRef.current) {
        containerWidthRef.current = newWidth;
        checkScroll();
      }

      // handle the initialScroll when the content first becomes available
      if (!handledInitialScroll.current) {
        if (activeTab) scrollToTab(activeTab);
        handledInitialScroll.current = true;
      }
    },
    [checkScroll, scrollToTab, activeTab]
  );

  const handleContentSizeChanged = useCallback((w: number) => {
    contentSizeRef.current = w;
  }, []);

  const handleScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      scrollPosRef.current = e.nativeEvent.contentOffset.x;
      checkScroll();
    },
    [checkScroll]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkScroll(), []);

  useEffect(() => {
    if (activeTab) scrollToTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  return (
    <View style={styles.wrapper} onLayout={handleContainerLayout}>
      <ScrollView
        ref={scrollRef}
        horizontal
        bounces={false}
        scrollEnabled={isScrollable}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[
          styles.itemsContainer,
          !isScrollable && styles.itemsContainerFill
        ]}
        onContentSizeChange={handleContentSizeChanged}
        onScroll={handleScroll}
        onScrollEndDrag={handleScroll}
        scrollEventThrottle={16}
      >
        {tabs.map(({ key, label, testID, showBadge }) => {
          const humanizedLabel =
            typeof label === "string" ? label : size ? label[size] : label.md;
          return (
            <TabItem
              key={key}
              testID={testID}
              label={humanizedLabel}
              active={key === activeTab}
              onPress={() => {
                onChangeTab?.(key);
              }}
              maxWidth={tabButtonMaxWidth}
              onLayout={(e) =>
                e.nativeEvent && tabItemsLayout.set(key, e.nativeEvent.layout)
              }
              showBadge={showBadge}
            />
          );
        })}
      </ScrollView>
      {fadeEnd && (
        <LinearGradient
          pointerEvents="none"
          style={styles.gradient}
          {...fadeGradient}
        />
      )}
      {fadeStart && (
        <LinearGradient
          pointerEvents="none"
          style={[styles.gradient, styles.gradientStart]}
          {...fadeGradient}
        />
      )}
    </View>
  );
}
