import React from "react";
import { type TextStyle, View, type ViewStyle } from "react-native";
import { FastImage } from "../image";
import {
  type ImageStyleProp,
  type TextStyleProp,
  type ViewStyleProp,
  theme,
  useStyles
} from "../style";

import StyledIcon, { type IconName, type IconVariant } from "./styled-icon";

type IconCircleSize = "xs" | "sm" | "md" | "lg" | "xl";

interface Props {
  size?: IconCircleSize;
  color?: string;
  tintColor?: string;
  style?: ViewStyleProp;
  iconStyle?: TextStyleProp;
  imageUri?: string;
  name?: IconName;
  variant?: IconVariant;
}

const WRAPPER_STYLES: { [key in IconCircleSize]?: ViewStyle } = {
  xs: {
    width: 24,
    height: 24,
    borderRadius: 12
  },
  sm: {
    width: 28,
    height: 28,
    borderRadius: 14
  },
  md: {
    width: 56,
    height: 56,
    borderRadius: 28
  },
  lg: { width: 124, height: 124, borderRadius: 62 },
  xl: {
    width: 160,
    height: 160,
    borderRadius: 80
  }
};

const ICON_STYLES: { [key in IconCircleSize]?: TextStyle } = {
  xs: { fontSize: 12 },
  sm: { fontSize: 13 },
  md: { fontSize: 28 },
  lg: { fontSize: 64 },
  xl: { fontSize: 80 }
};

const IconCircle = ({
  style,
  iconStyle,
  size = "xs",
  color = "white",
  tintColor = "spiceOrange",
  variant = "solid",
  name,
  imageUri,
  ...props
}: Props) => {
  const { styles } = useStyles(
    () => ({
      iconWraper: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.color.getColor(tintColor),
        ...WRAPPER_STYLES[size]
      },
      image: {
        ...WRAPPER_STYLES[size]
      },
      icon: {
        color: theme.color.getColor(color),
        textAlign: "center",
        ...ICON_STYLES[size]
      }
    }),
    [color, tintColor, size]
  );

  return (
    <View style={[styles.iconWraper, style]}>
      {imageUri ? (
        <FastImage
          source={{ uri: imageUri }}
          style={styles.image as ImageStyleProp}
        />
      ) : name ? (
        <StyledIcon
          style={[styles.icon, iconStyle]}
          variant={variant}
          name={name}
          {...props}
        />
      ) : null}
    </View>
  );
};

export default IconCircle;
