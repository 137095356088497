import { APP_ID } from "@gigsmart/isomorphic-shared/constants";
import { DateTime } from "luxon";

export type TimeSizes = "sm" | "md" | "lg";
export type DateTimeConvertable = string | Date | DateTime | null | undefined;
export interface DateTimeProps {
  startsAt: DateTimeConvertable | null;
  actualStartsAt?: DateTimeConvertable | null;
  endsAt?: DateTimeConvertable | null;
  timezone?: string | null;
  size: TimeSizes;
  showDayOfWeek?: boolean;
  showDuration?: boolean;
  separator?: string;
}

const formats = {
  date: {
    sm: "MM/dd/yyyy",
    md: "MMM d, yyyy",
    lg: "MMMM d, yyyy"
  },
  dateTime:
    APP_ID === "worker"
      ? {
          sm: "MM/dd/yyyy',' h:mma",
          md: "MMM d, yyyy',' h:mma",
          lg: "MMMM d, yyyy 'at' h:mma"
        }
      : {
          sm: "MM/dd/yyyy',' h:mma ZZZZ",
          md: "MMM d, yyyy',' h:mma ZZZZ",
          lg: "MMMM d, yyyy 'at' h:mma ZZZZ"
        },
  time:
    APP_ID === "worker"
      ? { sm: "h:mma", md: "h:mma", lg: "h:mma" }
      : { sm: "h:mma ZZZZ", md: "h:mma ZZZZ", lg: "h:mma ZZZZ" },
  dayOfWeekPrefix: {
    sm: "EEE, ",
    md: "EEE, ",
    lg: "EEEE, "
  }
};

export function humanizeDate({
  startsAt,
  actualStartsAt,
  endsAt,
  timezone,
  showDayOfWeek,
  size
}: DateTimeProps) {
  const prefix = showDayOfWeek
    ? getDayOfWeekPrefix(startsAt ?? actualStartsAt ?? endsAt, size)
    : "";
  const suffix = endsAt
    ? ` - ${humanize({ startsAt: endsAt, timezone, size, type: "date" })}`
    : "";
  return (
    prefix +
    humanize({
      startsAt: startsAt ?? actualStartsAt,
      timezone,
      size,
      type: "date"
    }) +
    suffix
  );
}

export function humanizeTime({
  startsAt,
  endsAt,
  timezone,
  showDuration,
  size
}: DateTimeProps) {
  const suffix = endsAt
    ? ` - ${humanize({ startsAt: endsAt, timezone, size, type: "time" })}`
    : "";
  let body = humanize({ startsAt, timezone, size, type: "time" });
  if (
    endsAt &&
    getDateTime(startsAt, timezone).toFormat("ZZZZ") ===
      getDateTime(endsAt, timezone).toFormat("ZZZZ")
  ) {
    body = body.replace(
      ` ${getDateTime(startsAt, timezone).toFormat("ZZZZ")}`,
      ""
    );
  }
  return body + suffix + formatDuration({ showDuration, startsAt, endsAt });
}

export function humanizeDateTime({
  startsAt,
  actualStartsAt,
  endsAt,
  timezone,
  showDayOfWeek,
  showDuration,
  size,
  separator = ","
}: DateTimeProps) {
  const prefix = showDayOfWeek
    ? getDayOfWeekPrefix(startsAt ?? actualStartsAt ?? endsAt, size)
    : "";
  const suffix = endsAt
    ? ` - ${humanize({ startsAt: endsAt, timezone, size, type: "time" })}`
    : "";
  let body = humanize({
    startsAt,
    timezone,
    size,
    type: "dateTime",
    actualStartsAt
  });
  if (endsAt) body = body.replace(" at", separator);
  if (
    endsAt &&
    getDateTime(startsAt, timezone).toFormat("ZZZZ") ===
      getDateTime(endsAt, timezone).toFormat("ZZZZ")
  ) {
    body = body.replace(
      ` ${getDateTime(startsAt, timezone).toFormat("ZZZZ")}`,
      ""
    );
  }
  return (
    prefix + body + suffix + formatDuration({ showDuration, startsAt, endsAt })
  );
}

type FormatDurationOpt = {
  startsAt: DateTimeConvertable | null;
  endsAt?: DateTimeConvertable | null;
  showDuration?: boolean;
};

function formatDuration({ startsAt, endsAt, showDuration }: FormatDurationOpt) {
  if (showDuration && endsAt) {
    if (!startsAt) return " (TBD)";
    let formattedDuration = getDateTime(endsAt)
      .diff(getDateTime(startsAt))
      .toFormat("h'h' m'm'");
    if (formattedDuration.includes("0h"))
      formattedDuration = formattedDuration.replace("0h ", "");
    if (formattedDuration.includes("0m"))
      formattedDuration = formattedDuration.replace(" 0m", "");
    return ` (${formattedDuration})`;
  }

  return "";
}

function humanize({
  startsAt,
  actualStartsAt,
  timezone,
  type,
  size
}: DateTimeProps & {
  type: "dateTime" | "date" | "time";
}) {
  const format = formats[type][size];
  if (APP_ID === "worker") timezone = undefined;

  if (!startsAt && type === "time") return "ASAP";
  if (!startsAt && type === "dateTime" && actualStartsAt) {
    const obj = getDateTime(actualStartsAt, timezone);
    return `${obj.toFormat(formats.date[size])}, ASAP`;
  }
  if (!startsAt) return "Invalid Date";
  const obj = getDateTime(startsAt, timezone);
  return obj.toFormat(format);
}

function getDayOfWeekPrefix(datetime: DateTimeConvertable, size: TimeSizes) {
  if (!datetime) return "";

  const obj = getDateTime(datetime);
  const isToday = obj.hasSame(DateTime.now(), "day");
  const isTomorrow = obj.hasSame(DateTime.now().plus({ days: 1 }), "day");
  return isToday
    ? "Today, "
    : isTomorrow
      ? "Tomorrow, "
      : obj.toFormat(formats.dayOfWeekPrefix[size]);
}

export function getDateTime(
  datetime: DateTimeConvertable,
  timezone?: string | null
) {
  const obj =
    datetime instanceof DateTime
      ? datetime
      : datetime instanceof Date
        ? DateTime.fromJSDate(datetime)
        : typeof datetime === "string"
          ? DateTime.fromISO(datetime)
          : !datetime
            ? DateTime.now()
            : null;

  if (!obj) throw new Error("Invalid date format.");
  return timezone ? obj.setZone(timezone) : obj;
}

export function isInThePast(datetime: DateTimeConvertable) {
  const obj = getDateTime(datetime);
  return obj < DateTime.now();
}
