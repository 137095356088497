import React, { memo, forwardRef } from "react";
import { Image, type ImageProps } from "react-native";

type Props = ImageProps & { imageId?: string };

const FastImage = forwardRef<Image, Props>(
  ({ imageId, ...props }: Props, ref) => <Image ref={ref} {...props} />
);

export default memo(FastImage, (prevProps, nextProps) => {
  if (nextProps.imageId) return nextProps.imageId === prevProps.imageId;
  return false;
});
