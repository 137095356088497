import { compact } from "lodash";
import React from "react";
import Stack from "../atoms/Stack";
import { Icon, Row, Text } from "../quarks";
import { useStyles } from "../style/styles";

interface Props {
  maxStars: number;
  hideUnfilledStars?: boolean;
  boldText?: boolean;
  testID?: string;
  small?: boolean;
}

export default function Stars({
  small,
  maxStars,
  boldText,
  testID,
  hideUnfilledStars
}: Props) {
  const styles = useStyles(({ getUnits }) => ({
    paddedText: { paddingTop: getUnits(0.5) }
  }));
  if (maxStars < 1) return null;

  const stars = compact(
    Array.from({ length: 5 }, (_, idx) => {
      const selected = idx <= maxStars;
      if (hideUnfilledStars && !selected) return null;
      return (
        <Icon
          key={idx}
          size="tiny"
          name="star"
          color="star"
          variant={selected ? "solid" : "light"}
        />
      );
    })
  );

  return (
    <Row>
      <Stack horizontal size="small" alignItems="center">
        {stars}
        <Text
          weight={boldText ? "bold" : "normal"}
          testID={testID}
          align="center"
          style={styles.paddedText}
          {...(small && { variant: "note", color: "neutral" })}
        >
          {" "}
          & Up
        </Text>
      </Stack>
    </Row>
  );
}
