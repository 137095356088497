import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import React, { type ReactNode } from "react";
import type { FlatListProps, ListRenderItem } from "react-native";
import { KeyboardAwareFlatList } from "react-native-keyboard-aware-scroll-view";

import { IsConstrainedProvider, useContentArea } from "../atoms";
import { SelectableListHeader, SelectableListItem } from "../molecules";
import { Spacer } from "../quarks";
import type { ThemeMedia } from "../style/theme";

export interface SelectableListProps<T, V extends string>
  extends Pick<FormFieldOptions<V[]>, "name" | "validates">,
    Pick<
      FlatListProps<T>,
      "onEndReached" | "ListFooterComponent" | "ListEmptyComponent"
    > {
  constraint?: keyof ThemeMedia["size"];
  keyExtractor: (item: T) => V;
  data: readonly T[];
  renderItem: (item: T) => ReactNode;
  wrapItem?: boolean;
  multiple?: boolean;
  selectAll?: null | string | ((count: number) => string);
  totalCount?: number;
  onSelectAll?: (selected: boolean, setValue: (v: V[]) => void) => void;
}

export default function SelectableList<T, V extends string>({
  name,
  validates,
  constraint = "xsmall",
  renderItem,
  wrapItem,
  data,
  keyExtractor,
  multiple,
  selectAll,
  totalCount,
  onEndReached,
  onSelectAll,
  ListFooterComponent,
  ListEmptyComponent
}: SelectableListProps<T, V>) {
  const { value, setValue } = useFormField({ name, validates });
  const [contentStyle, isConstrained] = useContentArea({ constraint });

  const handleChange = (key: V, selected: boolean) => {
    let newValue = value ?? [];
    if (multiple) {
      newValue = selected
        ? [...newValue, key]
        : newValue.filter((it) => it !== key);
      setValue(newValue);
    } else {
      setValue(selected ? [key] : []);
    }
  };

  const handleRenderItem: ListRenderItem<any> = ({ item }) => {
    const key = keyExtractor(item);
    const isSelected = !!value?.includes(key);
    return (
      <SelectableListItem
        multiple={multiple}
        wrap={wrapItem}
        selected={isSelected}
        onChange={(selected) => handleChange(key, selected)}
      >
        {renderItem(item)}
      </SelectableListItem>
    );
  };

  const showSelectAll = multiple && data.length > 1;
  const allSelected = value?.length === data.length;
  const header = showSelectAll ? (
    <>
      <SelectableListHeader
        title={
          typeof selectAll === "function"
            ? selectAll(totalCount ?? data.length)
            : selectAll ?? "Select All"
        }
        selected={allSelected}
        onChange={(selected) => {
          if (onSelectAll) {
            onSelectAll(selected, setValue);
          } else {
            setValue(selected ? data.map(keyExtractor) : []);
          }
        }}
      />
      <SeparatorComponent />
    </>
  ) : null;

  return (
    <IsConstrainedProvider value={isConstrained}>
      <KeyboardAwareFlatList
        contentContainerStyle={contentStyle}
        extraScrollHeight={30}
        enableOnAndroid={false}
        keyExtractor={keyExtractor}
        keyboardShouldPersistTaps="handled"
        data={data}
        renderItem={handleRenderItem}
        ItemSeparatorComponent={SeparatorComponent}
        ListHeaderComponent={header}
        onEndReachedThreshold={200}
        onEndReached={onEndReached}
        ListFooterComponent={ListFooterComponent}
        ListEmptyComponent={ListEmptyComponent}
      />
    </IsConstrainedProvider>
  );
}

const SeparatorComponent = () => <Spacer size="compact" />;
