import color from "./color";
import { unit } from "./unit";

interface ShadowOptions {
  shadowColor?: string;
  shadowOffset?: { width?: number; height?: number };
  shadowOpacity?: number;
  shadowRadius?: number;
  elevation?: number;
}

export const shadow = (options?: ShadowOptions) => {
  if (options) {
    const { shadowColor, shadowOpacity, elevation } = options;
    let { shadowRadius } = options;
    const width = options?.shadowOffset?.width;
    const height = options?.shadowOffset?.height;
    if (typeof shadowRadius === "string") {
      shadowRadius = shadowRadius || unit.shadowRadius;
    }

    return {
      shadowColor: shadowColor ?? color.shadow,
      shadowOffset: {
        width: width ?? unit.shadowOffset.width,
        height: height ?? unit.shadowOffset.height
      },
      shadowOpacity: shadowOpacity ?? 0.3,
      shadowRadius,
      elevation: elevation ?? unit.elevation
    };
  }

  return {
    shadowColor: color.shadow,
    shadowOpacity: 0.5,
    shadowOffset: {
      width: unit.shadowOffset.width,
      height: unit.shadowOffset.height
    },
    shadowRadius: unit.shadowRadius,
    elevation: unit.elevation
  };
};

export const namedShadows = {
  card1: {
    shadowColor: color.black,
    shadowOpacity: 0.15,
    shadowOffset: {
      width: unit.shadowOffset.width,
      height: unit.shadowOffset.height
    },
    shadowRadius: unit.shadowRadius / 2,
    elevation: unit.elevation / 2
  },
  card1Hover: {
    shadowColor: color.black,
    shadowOpacity: 0.3,
    shadowOffset: {
      width: unit.shadowOffset.width,
      height: unit.shadowOffset.height * 2
    },
    shadowRadius: unit.shadowRadius,
    elevation: unit.elevation
  },
  noShadow: {
    shadowColor: "transparent",
    shadowOpacity: 0,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 0,
    elevation: 0
  }
};
