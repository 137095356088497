import React, { type ComponentProps, type ReactNode } from "react";
import { Avatar, Stack } from "../atoms";
import { Divider, Text, type UnitSize } from "../quarks";

interface Props
  extends Pick<
    ComponentProps<typeof Avatar>,
    "uri" | "icon" | "initials" | "size"
  > {
  testID?: string;
  titleTestID?: string;
  title?: string | null;
  titleNumberOfLines?: number;
  children?: ReactNode;
  divider?: boolean;
  spacing?: UnitSize;
}

export default function ThumbnailRow({
  testID,
  titleTestID,
  title,
  titleNumberOfLines,
  divider,
  children,
  spacing = "standard",
  size = "large",
  ...props
}: Props) {
  return (
    <Stack horizontal alignItems="center" testID={testID} size={spacing}>
      <Avatar size={size} {...props} />
      <Stack fill size="slim">
        <Text
          weight="bold"
          color="primary"
          testID={titleTestID}
          numberOfLines={titleNumberOfLines}
        >
          {title}
        </Text>
        {divider && <Divider />}
        {children}
      </Stack>
    </Stack>
  );
}
