import React, {
  createContext,
  useMemo,
  useContext,
  type ReactNode
} from "react";

const Context = createContext({ enable: false, batchKey: "Main" });

interface Props {
  disable?: true;
  batchKey?: string;
  children: ReactNode;
}

export function RelayBatch({
  disable,
  batchKey: staticBatchKey,
  children
}: Props) {
  const batchKey = useMemo(
    () => staticBatchKey ?? Math.round(Math.random() * 10 ** 9).toString(36),
    [staticBatchKey]
  );
  return (
    <Context.Provider value={{ enable: !disable, batchKey }}>
      {children}
    </Context.Provider>
  );
}

export function useRelayBatch(override?: boolean | undefined) {
  const { enable, batchKey } = useContext(Context);
  return typeof override !== "undefined"
    ? { enable: override, batchKey }
    : { enable, batchKey };
}
