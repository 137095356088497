import React from "react";
import { SectionList, type SectionListProps } from "react-native";

import { createScreenComponent } from "./ScreenWrapper";

type Props<TItem, TSection> = SectionListProps<TItem, TSection> & {
  testID: string;
};

const ScreenSectionList = createScreenComponent(
  <TItem, TSection>(props: Props<TItem, TSection>) => <SectionList {...props} />
);

export default ScreenSectionList;
