import React, { type ReactNode } from "react";
import { ContentArea } from "../../atoms";
import { Column } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";

interface Props {
  children: ReactNode;
  index: number;
}

export default function SectionListEmptyItemContainer<T>({
  children,
  index
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);

  const computeWidth = () => {
    return isSm ? 35 : 52;
  };

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      listItem: {
        marginTop: index === 0 ? 4 : undefined,
        minWidth: getUnits(computeWidth()),
        maxWidth: getUnits(computeWidth()),
        backgroundColor: getColor("foreground", "fill"),
        shadowColor: getColor("black", "fill"),
        zIndex: 1,
        minHeight: "100%"
      }
    }),
    [isSm, index]
  );

  return (
    <Column style={[styles.listItem]} fill={1} testID="empty-container">
      <ContentArea size="compact" variant="compact">
        {children}
      </ContentArea>
    </Column>
  );
}
