import React, { useMemo } from "react";
import { View } from "react-native";

import Stack from "../atoms/Stack";
import Text from "../quarks/Text";
import { useStyles } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  progress: number;
}

interface BarProps {
  color: Color;
  textColor: Color;
  text: string;
  width: number;
}

export default function ProgressBar({ progress = 0 }: Props) {
  const computedProps: BarProps = useMemo(() => {
    if (progress === 0) {
      return {
        color: "background",
        textColor: "orange",
        width: 0,
        text: "Weak"
      };
    }
    if (progress <= 25) {
      return {
        color: "orange",
        textColor: "orange",
        width: 25,
        text: "Weak"
      };
    }
    if (progress > 25 && progress <= 50) {
      return {
        color: "star",
        textColor: "star",
        width: 50,
        text: "Fair"
      };
    }
    if (progress > 50 && progress <= 75) {
      return {
        color: "emphasized",
        textColor: "emphasized",
        width: 75,
        text: "Good"
      };
    }
    return {
      color: "success",
      textColor: "success",
      width: 100,
      text: "Excellent"
    };
  }, [progress]);

  const styles = useStyles(
    ({ getColor }) => ({
      container: {
        borderRadius: 2,
        height: 4,
        backgroundColor: getColor("background", "fill"),
        width: "100%"
      },
      progress: {
        borderRadius: 2,
        backgroundColor: getColor(computedProps.color, "fill"),
        height: 4,
        width: `${computedProps.width}%`
      }
    }),
    [progress]
  );

  return (
    <Stack size="compact" testID="progressBarContainer">
      <View style={styles.container}>
        <View style={styles.progress} />
      </View>
      <Text
        variant="subheader"
        weight="normal"
        color={computedProps.textColor}
        testID="progress-bar-text"
      >
        {computedProps.text}
      </Text>
    </Stack>
  );
}
