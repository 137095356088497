import parse, { isValidNumber, type CountryCode } from "libphonenumber-js";
import { isEqual, startCase } from "lodash";
import moment, { type Moment, type unitOfTime } from "moment-timezone";
import { validate } from "postal-codes-js";
import * as Normalizer from "./normalizer";
import type { Validator, ValueMap } from "./types";

// E-mail validation from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const cardNumberRegex =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/;

const BLOCK_EMAIL_REGEX = /[A-Za-z0-9._%+\-']+@[A-Za-z0-9.-]+\.[A-Za-z]+/;
const emailErrorMessage = "GigSmart policy prohibits sharing of emails";

// regex to match a phonenumber but only if it's outside a markdown link syntax
const BLOCK_PHONE_REGEX =
  // /(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?/gi;
  /(?<![\w:-])(^|\b)(\(?\d{1,3}\)?[-.\s()]*\d{3}[-.\s]*\d{4})(?![\w:-])/;

const TIME_12HRS = /^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/;
const TIME_24HRS = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

interface WithMessage {
  message?: string;
}

export const create = <Options extends object, T>(
  fn: (options: Options) => Validator<T>,
  defaultOptions: Options = {} as unknown as Options
): ((options?: Options) => Validator<T>) => {
  const memo: Array<[Options, () => Validator<T>]> = [];
  return (options: Options = defaultOptions) => {
    let memoFn = (memo.find(([k]) => isEqual(k, options)) ?? [])[1];
    if (memoFn) return memoFn();
    memoFn = fn.bind(null, options);
    memo.push([options, memoFn]);
    return memoFn();
  };
};

export const length = create<
  {
    exact?: number;
    min?: number;
    max?: number;
    required?: boolean;
  } & WithMessage,
  string
>(({ min, max, exact, message, required = true }) => (_field, value) => {
  const len = value?.trim()?.length ?? 0;
  if (!required && len === 0) return null;
  if (typeof exact === "number" && exact !== len) {
    return [new Error(message ?? `must have exactly ${exact} characters`)];
  }
  if (typeof min === "number" && len < min) {
    return [new Error(message ?? `must have ${min} or more characters`)];
  }
  if (typeof max === "number" && len > max) {
    return [new Error(message ?? `must have less than ${max} characters`)];
  }
  return null;
});

export const arrayLength = create<
  {
    exact?: number;
    min?: number;
    max?: number;
    required?: boolean;
  } & WithMessage,
  any[] | Set<any>
>(({ min, max, exact, message, required = true }) => (_field, value) => {
  const len = value ? ("length" in value ? value.length : value.size) : 0;
  if (!required && len === 0) return null;
  if (typeof exact === "number" && exact !== len) {
    return [new Error(message ?? `must select ${exact} options`)];
  }
  if (typeof min === "number" && len < min) {
    return [new Error(message ?? `must select ${min} or more options`)];
  }
  if (typeof max === "number" && len > max) {
    return [new Error(message ?? `must select less than ${max} options`)];
  }
  return null;
});

// Validates string matches regex
export const match = create<
  { pattern: RegExp } & WithMessage,
  string | null | undefined
>(
  ({ pattern, message }) =>
    (_fieldName: string, value: string | null | undefined) => {
      if (value === "" || !value?.match(pattern)) {
        return [new Error(message ?? "invalid format")];
      }
      return null;
    }
);

// Validates value is one of in the array
export const oneOf = create<{ options: unknown[] } & WithMessage, any>(
  ({ options, message }) =>
    (_fieldName: string, value: any) => {
      if (!options.some((option) => option === value)) {
        return [new Error(message ?? `must be one of: ${options.join(", ")}`)];
      }
      return null;
    }
);

// Validates value is true
export const isTrue = create<{ required?: boolean } & WithMessage, boolean>(
  ({ required = true, message } = {}) =>
    (_fieldName: string, value: boolean) =>
      required && !value ? [new Error(message ?? "is required")] : []
);

// Validates value is a valid email address
export const email = create<WithMessage, string>(({ message } = {}) =>
  match({
    pattern: emailRegex,
    message: message ?? "is not a valid email"
  })
);

// Validates email list
export const emailList = create<{}, string | string[]>(
  () => (_fieldName: string, value: string | string[]) => {
    const emails = Array.isArray(value)
      ? value
      : value.replace(/\s+/g, "").split(",");
    const invalidEmails = emails
      .map((e) => (e.match(emailRegex) ? "" : e))
      .filter(Boolean);

    if (invalidEmails.length === 0) return null;

    return invalidEmails.length === 1
      ? [new Error(`${invalidEmails[0]} is not valid`)]
      : [new Error(`${invalidEmails.join(", ")} are not valid`)];
  }
);

// Validates value is a valid zip code
export const zipcode = create<{ country?: string } & WithMessage, string>(
  ({ country = "us", message } = {}) =>
    (_fieldName: string, value: string) => {
      if (!!value && validate(country, value) !== true) {
        return [new Error(message ?? "is not a valid zip code")];
      }
      return [];
    }
);

// Validates value is a valid credit/debit card number
export const cardNumber = create<WithMessage, string>(
  ({ message } = {}) =>
    (_fieldName, value) => {
      const digits = Normalizer.digits(value);
      if (!digits?.match(cardNumberRegex)) {
        return [new Error(message ?? "is not a valid card number")];
      }
      return null;
    }
);

// Validates the value has been provided
export const presence = create<
  { required?: boolean; mustContainCharacters?: boolean } & WithMessage,
  any
>(
  ({ required = true, mustContainCharacters = false, message } = {}) =>
    (_fieldName: string, value: any) => {
      if (!required) return [];
      if (
        (mustContainCharacters &&
          value &&
          value.match(/.*[a-zA-Z].*/) === null) ||
        (typeof value === "string" && value?.trim() === "")
      ) {
        return [new Error(message ?? "cannot be blank")];
      }
      switch (value) {
        case "":
        case null:
        case undefined:
          return [new Error(message ?? "cannot be blank")];
        default:
          if (isEqual(value, []) || isEqual(value, {})) {
            return [new Error(message ?? "cannot be empty")];
          }
          return [];
      }
    }
);

export const name = create<
  WithMessage & { multipleWhitespaceMatches?: boolean },
  string | null | undefined
>(
  ({ message, multipleWhitespaceMatches = true } = {}) =>
    (_fieldName: string, rawValue?: string | null | undefined) => {
      if (rawValue === null || rawValue === undefined || rawValue.trim() === "")
        return [new Error(message ?? "Name cannot be blank")];
      const value = rawValue.trim();
      const alphaNumericMatches = value.match(/^[a-zA-Z0-9'\- \u2018\u2019]+$/);
      if (alphaNumericMatches === null || alphaNumericMatches.length < 1) {
        return [new Error(message ?? "Name cannot include special characters")];
      }
      if (
        multipleWhitespaceMatches &&
        (value.match(/[ ]{2,}/g)?.length ?? 0) > 0
      ) {
        return [new Error(message ?? "Invalid entry")];
      }
      return [];
    }
);

// Validates the value confirms another value
// Note: other field name must be a fully-qualified API-compatible name like
// "work_history[0].some_field"
export const confirmationOf = create<{ field: string } & WithMessage, any>(
  ({ field, message }) =>
    (_fieldName: string, value: any, otherFields?: ValueMap): Error[] => {
      const otherFieldValue = otherFields?.get(field);
      if (
        value === null ||
        value === undefined ||
        !isEqual(value, otherFieldValue)
      ) {
        return [
          new Error(message ?? `must match ${startCase(field).toLowerCase()}`)
        ];
      }
      return [];
    }
);

// Validates the value is a valid phone number
export const phoneNumber = create<
  { defaultCountry?: CountryCode } & WithMessage,
  any
>(
  ({ defaultCountry = "US", message } = { defaultCountry: "US" }) =>
    (_fieldName: string, value: any) => {
      const valid =
        !!value &&
        (parse(value, defaultCountry)?.nationalNumber === "5558675309" ||
          isValidNumber(value, defaultCountry));
      if (!valid) return [new Error(message ?? "invalid phone number")];
      return [];
    }
);

//
export const numberBetween = create<
  { min?: number; max?: number } & WithMessage,
  number | string
>(
  ({ min = 0, max = Number.POSITIVE_INFINITY, message } = {}) =>
    (_fieldName: number | string, rawValue: number | string) => {
      const value = Number(rawValue);
      if (Number.isNaN(value)) {
        return [new Error(message ?? "value is not a number")];
      }
      if (max < min) return [new Error(message ?? "invalid range")];
      if (value < min) return [new Error(message ?? "value is below minimum")];
      if (value > max) return [new Error(message ?? "value is above maximum")];
      return [];
    }
);

export const maxNumber = create<{ max: number } & WithMessage, number | string>(
  ({ max, message } = { max: Number.POSITIVE_INFINITY }) =>
    (_fieldName: number | string, rawValue: number | string) => {
      const value = Number(rawValue);
      if (value <= max) return [];
      if (value > max) return [new Error(message ?? "value is above maximum")];
      return [];
    }
);

export const minNumber = create<
  { min: number; required?: boolean } & WithMessage,
  number | string
>(
  ({ min, message, required = true } = { min: 0, required: true }) =>
    (_fieldName: number | string, rawValue: number | string) => {
      if ((rawValue === null || rawValue === "") && required)
        return [new Error(message ?? "value is required")];
      const value = Number(rawValue);
      if ((!rawValue && !required) || value >= min) return null;
      if (!required && value === 0) return null;
      return [new Error(message ?? "value is below minimum")];
    }
);

// Validates the value is a valid date
export const date = create<
  {
    format?: string;
    message?: string;
    options?: {
      min?: Date | Moment;
      max?: Date | Moment;
      precision?: unitOfTime.StartOf | null;
      strict?: boolean;
    };
  },
  string | Date | Moment
>(
  ({ format, message = "invalid date", options = {} }) =>
    (_fieldName: string, value: string | Date | Moment): Error[] => {
      if (
        typeof value === "string" &&
        !!format &&
        value.length !== format.length
      ) {
        return [new Error(message)];
      }

      const momentObj = moment(value, format, options?.strict);
      let isValid = momentObj.isValid();
      let messageResponse = message;
      if (!format && isValid) return [];
      if (isValid && options.min) {
        isValid = momentObj.isSameOrAfter(
          options.min,
          options.precision ?? "day"
        );
        if (
          !isValid &&
          !momentObj.isSameOrAfter(options.min, options.precision ?? "day")
        ) {
          messageResponse = `Date must be on or after ${moment(
            options?.min
          ).format("MM/DD/YYYY")}`;
        }
      }
      if (isValid && options.max) {
        isValid = momentObj.isSameOrBefore(
          options.max,
          options.precision ?? "day"
        );
        if (!isValid && momentObj.isSameOrBefore(moment())) {
          messageResponse = `You must be at least ${moment().diff(
            options.max,
            "years"
          )} years old to create an account`;
        }
      }

      return isValid ? [] : [new Error(messageResponse)];
    }
);

// Validates the value doesn't contain a phone number
export const noPhoneNumbers = create<WithMessage, string>(
  ({ message } = {}) =>
    (_fieldName: string, rawValue?: string | null | undefined) => {
      const value =
        rawValue !== undefined && rawValue !== null ? rawValue.trim() : "";
      if (BLOCK_PHONE_REGEX.test(value)) {
        return [
          new Error(
            message ?? "GigSmart policy prohibits sharing of phone numbers"
          )
        ];
      }
      return [];
    }
);

// Validates the value doesn't contain an email address
export const noEmailAddressesOrGmail = create<
  WithMessage,
  string | null | undefined
>(
  ({ message } = {}) =>
    (_fieldName: string, rawValue?: string | null | undefined) => {
      const value =
        rawValue !== undefined && rawValue !== null ? rawValue.trim() : "";
      if (
        value.match(BLOCK_EMAIL_REGEX) ??
        value.toLowerCase().includes("gmail")
      ) {
        return [new Error(message ?? emailErrorMessage)];
      }
      return [];
    }
);

// Validates the value is a valid phone number
export const json = create<WithMessage, string>(
  ({ message } = {}) =>
    (_fieldName: string, value: string) => {
      if (value === "") return [];
      try {
        const j = JSON.parse(value);
        return j === Object(j)
          ? []
          : [new Error(message ?? "invalid json object")];
      } catch (e) {
        return [new Error(message ?? "invalid json")];
      }
    }
);

// Validates the value is a valid url
export const url = create<{ secureOnly?: boolean } & WithMessage, string>(
  ({ secureOnly, message } = {}) =>
    (_fieldName: string, value: string) => {
      if (value === "") return [];
      try {
        const { protocol } = new URL(value);
        switch (protocol) {
          case "http:":
            return secureOnly
              ? [new Error(message ?? "is not a secure url")]
              : [];
          case "https:":
            return [];
          default:
            return [new Error(message ?? "is not a valid url")];
        }
      } catch (e) {
        return [new Error(message ?? "is not a valid url")];
      }
    }
);

// Validates time is in HH:MM format
export const time = create<WithMessage, any>(
  ({ message }) =>
    (fieldName: string, value: string | null) => {
      if (!value || value.length < 4) return [];
      const isValid = TIME_12HRS.test(value) || TIME_24HRS.test(value);
      return isValid ? [] : [new Error(message ?? "Invalid time")];
    }
);

export const optional = create<Validator<any>, any>(
  (otherValidator) => (name, value, otherValues) => {
    if (value === "" || value === null) return null;
    return otherValidator(name, value, otherValues);
  }
);

// The validations' order matters.
// biome-ignore format: ignore or TS breaks
export const composeValidations =
  // biome-ignore format: it will break everything
  <T,>(...validations: Array<Validator<T>>): Validator<T> =>
    (name, value, otherValues) => {
      for (let i = 0; i < validations.length; i++) {
        const errors = validations?.[i]?.(name, value, otherValues);
        if (!!errors?.length && errors.length > 0) return errors;
      }
      return null;
    };

// ex: valid.conditionalValidation(value => value === "nana" , valid.match(...))
export const conditionalValidation =
  (
    preposition: (arg0: string) => boolean,
    validation: (arg0: string) => Error[]
  ) =>
  (value: string): Error[] => {
    if (preposition(value)) {
      return validation(value);
    }
    return [];
  };

export const dependsOn = create<
  { field: string } & WithMessage & {
      predicate?: ({
        currentFieldValue,
        otherFieldValue
      }: {
        currentFieldValue: any;
        otherFieldValue: any;
      }) => boolean | string;
    },
  any
>(
  ({ field, message, predicate }) =>
    (_fieldName: string, value: any, otherFields?: ValueMap) => {
      const otherFieldValue = otherFields?.get(field);
      if (value === "" || value === null) return null;
      const result = predicate
        ? predicate({ currentFieldValue: value, otherFieldValue })
        : !!otherFieldValue;
      if (typeof result === "boolean" && !result) {
        return [new Error(message ?? `${field} must be given`)];
      }
      if (typeof result === "string") {
        return [new Error(result ?? `${field} must be given`)];
      }
      return [];
    }
);

export const withinGeofence = create<
  WithMessage & { geofenceRadius?: number } & {
    location: {
      latitude: number;
      longitude: number;
    };
  },
  any
>(
  ({ message, location, geofenceRadius }) =>
    (_fieldName: string, value: any) => {
      // Radius of Earth in miles
      if (!value?.latitude || !value?.longitude) return [];

      const R = 3959.87433;
      const defaultGeofenceRadius = 15;

      // Convert degrees to radians
      const toRadians = (degrees: number) => degrees * (Math.PI / 180);
      const lat1Rad = toRadians(location?.latitude);
      const lon1Rad = toRadians(location?.longitude);
      const lat2Rad = toRadians(value?.latitude);
      const lon2Rad = toRadians(value?.longitude);

      // Differences in coordinates
      const deltaLat = lat2Rad - lat1Rad;
      const deltaLon = lon2Rad - lon1Rad;

      // Haversine formula
      const a =
        Math.sin(deltaLat / 2) ** 2 +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      // Distance in miles
      const distance = R * c;

      if (distance > (geofenceRadius ?? defaultGeofenceRadius)) {
        return [new Error(message ?? "Location is outside the geofence area")];
      }
      return [];
    }
);
