import React from "react";
import { type TextStyleProp, useStyles } from "../../style";
import { KatanaText } from "../../text";

interface Props {
  style?: TextStyleProp;
  label?: string;
  error?: boolean;
  testID?: string;
}

export default function InputSubtext({ style, label, error, testID }: Props) {
  const { styles } = useStyles(({ color, font }) => ({
    label: {
      color: color.neutralDark,
      fontSize: font.size.small,
      marginHorizontal: 8
    },
    withError: {
      color: color.error
    }
  }));

  return typeof label === "string" ? (
    <KatanaText
      testID={testID}
      style={[styles.label, style, error && styles.withError]}
    >
      {label}
    </KatanaText>
  ) : null;
}
