process.env.GOOGLE_API_KEY_WEB ?? "";

import { APIProvider } from "@vis.gl/react-google-maps";
import React, { type ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export default function MapProvider({ children }: Props) {
  return (
    <APIProvider apiKey={process.env.GOOGLE_API_KEY_WEB ?? ""}>
      {children}
    </APIProvider>
  );
}
