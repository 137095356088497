import { EventContext } from "@gigsmart/dekigoto";
import React, { type ComponentType, type ReactNode } from "react";
import { Route as ReactRouterRoute, type RouteProps } from "react-router";
import type { ContextRouter } from "./types";

type Props<T extends ContextRouter> = Omit<
  RouteProps,
  "component" | "render" | "children"
> & {
  component?: ComponentType<T> | ComponentType<any>;
  render?: (props: T) => React.ReactNode;
  children?: ((props: T) => ReactNode) | ReactNode;
  eventContext: string | null;
  eventProperties?: {};
};

/** @deprecated use navigation components */
export default function Route<T extends ContextRouter>({
  eventContext,
  eventProperties,
  path,
  exact,
  ...routeProps
}: Props<T>) {
  return (
    <ReactRouterRoute
      path={path}
      render={({ match: { params, path: matchPath, url } }) => (
        <EventContext
          name={eventContext}
          properties={{ url, path: matchPath, params, ...eventProperties }}
          Component={routeProps.component}
        >
          <ReactRouterRoute
            path={path}
            exact={exact}
            {...(routeProps as RouteProps)}
          />
        </EventContext>
      )}
    />
  );
}
