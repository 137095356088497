import React from "react";
import { View as AnimatedView } from "react-native-animatable";

import { useStyle } from "../style";
import type { Color } from "../style/theme/colors";

const variants = {
  size: {
    xs: 2,
    sm: 3,
    md: 4
  }
};

interface Props {
  color?: Color;
  size?: keyof typeof variants.size;
  animation?: "flash";
}

const Dot = ({ size = "xs", color = "danger", animation }: Props) => {
  const dotStyle = useStyle(
    ({ getUnits, getColor }) => {
      const px = getUnits(variants.size[size]);
      return {
        width: px,
        height: px,
        borderRadius: px / 2,
        backgroundColor: getColor(color, "fill"),
        marginTop: -1
      };
    },
    [size]
  );

  return (
    <AnimatedView
      style={dotStyle}
      animation={animation}
      iterationCount="infinite"
      easing="ease-out"
      duration={3500}
    />
  );
};

export default Dot;
