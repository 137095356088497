import React from "react";
import Dropzone, { type Accept } from "react-dropzone";
import { ModalBody, ModalFooter, ModalHeader } from "../../atoms";
import { Text } from "../../quarks";
import { useStyles } from "../../style";
import { showModal } from "../ModalProvider";
import { toast } from "../Toast";

interface Props {
  onSelect: (file: File[]) => void;
  onClose?: () => void;
  acceptType?: "CSV" | "PDF" | "IMAGE";
  max?: number;
}

export function showDocumentPicker({
  onSelect,
  max,
  acceptType = "IMAGE"
}: Props) {
  return showModal({
    useModalBody: false,
    eventContext: "documentTypeSelectionModal",
    children: (close) => (
      <DocumentPickerContent
        onSelect={onSelect}
        onClose={close}
        max={max}
        acceptType={acceptType}
      />
    )
  });
}

function DocumentPickerContent({
  onSelect,
  max,
  onClose,
  acceptType = "IMAGE"
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: {
      borderWidth: 1,
      borderColor: getColor("neutral", "fill"),
      borderStyle: "dashed",
      padding: 20,
      marginHorizontal: 20,
      height: 280,
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }
  }));
  const handleOnDrop = async (acceptedFiles: File[]) => {
    if (max && acceptedFiles.length > max) {
      acceptedFiles = acceptedFiles.slice(0, max);
      toast.warning(
        `The first ${max} images have been selected. The number of images selected per message cannot exceed ${max}`
      );
    }
    onSelect(acceptedFiles);
    onClose?.();
  };

  const accept: Accept = {
    CSV: { "text/csv": [] },
    PDF: { "application/pdf": [] },
    IMAGE: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/heic": [],
      "application/pdf": [] // Leaving for backwards compatibility
    }
  }[acceptType];
  return (
    <>
      <ModalHeader onClose={onClose} />
      <ModalBody>
        <Dropzone
          onDrop={handleOnDrop}
          accept={accept}
          multiple={max ? max > 1 : false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={styles.container}>
              <input
                {...getInputProps()}
                data-testid="document-picker-input-file"
              />
              <Text>
                Drop an image here, or click to select a file to upload.
              </Text>
            </div>
          )}
        </Dropzone>
      </ModalBody>
      <ModalFooter />
    </>
  );
}
