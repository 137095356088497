import React, { type ReactNode, type ComponentProps, useEffect } from "react";
import { Form } from "./form";
import type { FormFieldOptions } from "./form-field";
import { useActor } from "./scene";

type Props = Omit<FormFieldOptions<object>, "initialValue"> & {
  children: ReactNode;
  initialValues?: Record<string, any>;
  onSubmit?: ComponentProps<typeof Form>["onSubmit"];
};
export default function NestedForm({
  children,
  name,
  initialValues,
  onSubmit
}: Props) {
  const setValue = useActor(
    "values",
    (nextValue, prevState) => {
      const values =
        nextValue === null
          ? prevState.values.delete(name)
          : prevState.values.set(name, nextValue);
      return { ...prevState, values };
    },
    [name]
  );

  const setErrors = useActor<Error[] | null>(
    "errors",
    (nextErrors, prevState) => {
      return {
        ...prevState,
        errors: prevState.errors.set(name, nextErrors)
      };
    },
    [name]
  );

  const submit = useActor("submit");

  useEffect(() => {
    // reset input on unmount
    return () => {
      setValue(null);
      setErrors(null);
    };
  }, []);

  return (
    <Form
      onSubmit={onSubmit ? onSubmit : submit}
      onChange={({ values, errors }) => {
        setValue(values);
        setErrors(errors);
      }}
      fieldPrefix={name}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
}
