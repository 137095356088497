import { definePermission } from "../registry";

export default definePermission({
  name: "Camera Access",
  icon: "camera",
  defaults: {
    preview: false,
    reason:
      "We need the ability to access your camera so that you can take and upload images."
  }
});
