import React from "react";
import Visible from "../../atoms/Visible";
import ActionSheetModal from "./ActionSheetModal";
import MobileActionSheet from "./MobileActionSheet";
import type { ActionSheetProps as Props } from "./types";

export default function ActionSheet({ children, ...props }: Props) {
  return (
    <>
      <Visible media={({ size }) => [size.medium.up]}>
        <ActionSheetModal {...props}>{children}</ActionSheetModal>
      </Visible>
      <Visible media={({ size }) => size.small.down}>
        {children}
        <MobileActionSheet {...props} />
      </Visible>
    </>
  );
}
