import React from "react";
import type { VideoProperties } from "react-native-video";

// TODO: Improve
export default function VideoComponent({ style, source }: VideoProperties) {
  if (typeof source === "number") return null;
  return (
    <video
      // eslint-disable-next-line react-native/no-inline-styles
      style={style as any}
      controls
    >
      <source src={source.uri} type={source.type ?? "video/mp4"} />
    </video>
  );
}
