import type { ValuesType } from "@gigsmart/type-utils";
import { StyleSheet } from "react-native";
import * as theme from "./theme";
import type { StylesType } from "./type-helpers";
import { breakpoint } from "./unit";
export type Theme = typeof theme;
export type NamedStyles<T extends string> = Record<T, StylesType>;
export type BreakpointType = keyof typeof breakpoint;
export type BreakpointedStyles<S extends StylesType> = Record<
  BreakpointType,
  S
>;
export type BreakpointedStyleSheets<
  T extends string,
  S extends StylesType = StylesType
> = Record<BreakpointType, Record<T, S>>;
export type BreakpointStylesInternal<
  T extends string,
  S extends StylesType = StylesType
> = Record<T, Partial<S & BreakpointedStyles<S>>>;
export interface StylesProps<T extends string = string> {
  theme: typeof theme;
  styles: NamedStyles<T>;
}
export type StylesFn<InputType> = (theme: Theme) => InputType;
export type BreakpointStylesFn<T extends string = string> = StylesFn<
  BreakpointStylesInternal<T>
>;

// Removes all breakpoint blocks by merging them into their parents
const stripBreakpointStyles = <T extends string>(
  breakpointStyles: ValuesType<BreakpointStylesInternal<T>>,
  breakpoints: BreakpointType[]
): NamedStyles<T> =>
  Object.keys(breakpointStyles).reduce<Partial<NamedStyles<T>>>((acc, key) => {
    if (breakpoints.includes(key as BreakpointType)) {
      return { ...acc, ...breakpointStyles[key] };
    }
    if (sortedBreakpoints.includes(key as BreakpointType)) return acc;
    return { ...acc, [key as keyof T]: breakpointStyles[key] };
  }, {}) as NamedStyles<T>;

export function createStylesObject<T extends string>(
  fn: StylesFn<BreakpointStylesInternal<T>>
) {
  return fn(theme);
}

export function hasBreakpoints<T extends string>(
  fn: StylesFn<BreakpointStylesInternal<T>>
): boolean {
  const stylesObject = createStylesObject(fn);
  return Object.values(stylesObject).some((value: any) =>
    Object.keys(value).some((key) => key.indexOf("@") === 0)
  );
}

export function createStyles<T extends string>(fn: StylesFn<NamedStyles<T>>) {
  return StyleSheet.create<NamedStyles<T>>(fn(theme));
}

export const stylesStubs: StylesProps<string> = { theme, styles: {} };

export const sortedBreakpoints: BreakpointType[] = (
  Object.keys(breakpoint) as BreakpointType[]
).sort((keyA, keyB) => {
  if (breakpoint[keyA] < breakpoint[keyB]) return -1;
  if (breakpoint[keyA] === breakpoint[keyB]) return 0;
  return 1;
});

// Generate the stylesheets for each breakpoint
export function breakpointStyleSheets<T extends string>(
  callback: StylesFn<BreakpointStylesInternal<T>>
): BreakpointedStyleSheets<T> {
  const themedStyles = createStylesObject<T>(callback);
  return sortedBreakpoints.reduce<Partial<BreakpointedStyleSheets<T>>>(
    (breakpointedStyleSheets, currentBreakpoint) => ({
      ...breakpointedStyleSheets,
      [currentBreakpoint]: Object.keys(themedStyles).reduce<
        Partial<NamedStyles<T>>
      >(
        (namedStyles, styleName) => ({
          ...namedStyles,
          [styleName]: stripBreakpointStyles<T>(
            themedStyles[styleName as keyof typeof themedStyles] as ValuesType<
              BreakpointStylesInternal<T>
            >,
            validBreakpoints(currentBreakpoint)
          )
        }),
        {}
      )
    }),
    {}
  ) as BreakpointedStyleSheets<T>;
}

// Get all of the valid breakpoints for the current variant
const validBreakpoints = (currentBreakpoint: BreakpointType) => {
  const valid = sortedBreakpoints.slice(
    sortedBreakpoints.indexOf(currentBreakpoint),
    sortedBreakpoints.length
  );
  return valid;
};
