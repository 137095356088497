import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import { addInitHook, track } from "../hooks";

export default async () => {
  addInitHook(async () => {
    if (Platform.OS !== "web") {
      // Track App Installs
      const installKey = "@dekigoto/applicationInstalled";
      const installItem = await AsyncStorage.getItem(installKey).catch(
        () => null
      );
      if (installItem === null) {
        track("Application Installed");
        await AsyncStorage.setItem(installKey, "tracked");
      }

      // Track App Updates (including Code Pushes)
      const updateKey = `@dekigoto/applicationUpdated/${process.env.PACKAGE_BUILD_NUMBER}`;
      const updateItem = await AsyncStorage.getItem(updateKey).catch(
        () => null
      );
      if (updateItem === null && process.env.PACKAGE_BUILD_NUMBER) {
        track("Application Updated", {
          buildNumber: process.env.PACKAGE_BUILD_NUMBER,
          codePush:
            process.env.PACKAGE_BUILD_NUMBER !== DeviceInfo.getBuildNumber()
        });
        await AsyncStorage.setItem(updateKey, "tracked");
      }
    }
  });
  return () => {};
};
