import React from "react";
import type { ComponentProps } from "react";
import { SectionList } from "react-native";
import ContentArea from "../atoms/ContentArea";
import Spacer from "../quarks/Spacer";
import Text from "../quarks/Text";
import { useStyle } from "../style";

type Props<T> = Omit<
  ComponentProps<typeof SectionList<T, { title: string; data: T[] }>>,
  "testID" | "style" | "inverted" | "renderSectionHeader"
>;

export default function MessageList<T>(props: Props<T>) {
  const style = useStyle({ flexGrow: 0 });
  const containerStyle = useStyle({ padding: 16 });
  return (
    <SectionList<T, { title: string; data: T[] }>
      {...props}
      testID="message-list"
      style={style}
      contentContainerStyle={containerStyle}
      inverted
      ItemSeparatorComponent={() => <Spacer size="compact" />}
      renderSectionFooter={({ section: { title } }) => (
        <ContentArea size="compact" variant="compact">
          <Text
            variant="note"
            color="disabled"
            weight="semibold"
            align="center"
          >
            {title}
          </Text>
        </ContentArea>
      )}
    />
  );
}
