import React, { type ComponentType, Fragment, type ReactNode } from "react";
import type { OnlyChildren } from "../utils/types";

export interface RadioGroupProps<T extends object> {
  Wrapper: ComponentType<OnlyChildren>;
  onChange: (nextValue: string) => void;
  value?: string | null;
  options: readonly T[];
  valueExtractor: (data: T) => string;
  renderItem: (item: {
    data: T;
    value: string;
    selected: boolean;
    onSelect: () => void;
  }) => ReactNode;
}

export default function RadioGroup<T extends object>({
  Wrapper,
  options,
  value: currentValue,
  renderItem,
  valueExtractor,
  onChange
}: RadioGroupProps<T>) {
  return (
    <Wrapper>
      {options.map((data) => {
        const value = valueExtractor(data);
        return (
          <Fragment key={String(value)}>
            {renderItem({
              data,
              value,
              selected: value === currentValue,
              onSelect: () => onChange(value)
            })}
          </Fragment>
        );
      })}
    </Wrapper>
  );
}

RadioGroup.defaultProps = {
  Wrapper: Fragment,
  valueExtractor: String
};
