import React, { type ReactNode, type ComponentProps } from "react";
import { View } from "react-native";
import type { TypeForStyleKey, ViewStyleProp } from "../style/type-helpers";

export type Props = ComponentProps<typeof View> & {
  children?: ReactNode;
  fill?: TypeForStyleKey<"flex">;
  direction?: TypeForStyleKey<"flexDirection">;
  justifyContent?: TypeForStyleKey<"justifyContent">;
  alignItems?: TypeForStyleKey<"alignItems">;
  alignSelf?: TypeForStyleKey<"alignSelf">;
  alignContent?: TypeForStyleKey<"alignContent">;
  wrap?: TypeForStyleKey<"flexWrap">;
  grow?: TypeForStyleKey<"flexGrow">;
  shrink?: TypeForStyleKey<"flexShrink">;
  basis?: TypeForStyleKey<"flexBasis">;
  style?: ViewStyleProp;
};

export default function Flex({
  style,
  children,
  fill,
  direction,
  justifyContent,
  alignItems,
  alignSelf,
  alignContent,
  wrap,
  grow,
  shrink,
  basis,
  ...props
}: Props) {
  const flexStyle: ViewStyleProp = {
    display: "flex",
    flex: fill,
    flexDirection: direction,
    justifyContent,
    alignItems,
    alignSelf,
    alignContent,
    flexWrap: wrap,
    flexGrow: grow,
    flexShrink: shrink,
    flexBasis: basis
  };
  return (
    <View style={[flexStyle, style]} {...props}>
      {children}
    </View>
  );
}
