import React, { type PropsWithChildren, type ComponentProps } from "react";
import { View } from "react-native";

// const RNTShadowView = requireNativeComponent
//   ? requireNativeComponent<any>("RNTShadowView")
//   : (_: any) => null;

export default function ShadowView(
  props: PropsWithChildren<ComponentProps<typeof View>>
) {
  return <View {...props} />;
}
