import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";

export default function useCurrentMonth(
  selectedDay: DateTime | null | undefined,
  min?: DateTime,
  timezone?: string | null | undefined
): [month: DateTime, onSetMonth: (add: number) => void] {
  const [selectedMonth, setSelectedMonth] = useState<DateTime | null>(null);

  const month = useMemo(() => {
    if (selectedMonth) return selectedMonth;
    if (selectedDay) return selectedDay;
    return min
      ? min.startOf("month")
      : DateTime.local({ zone: timezone ?? undefined });
  }, [min, selectedDay, selectedMonth, timezone]);

  const onSetMonth = useCallback(
    (add: number) => {
      const newSelectedMonth = month.plus({ month: add });
      setSelectedMonth(newSelectedMonth);
    },
    [month]
  );

  return [month, onSetMonth];
}
