import type { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import type { ComponentProps } from "react";
import Button from "../../atoms/Button";
import ModalFooter from "../../atoms/ModalFooter";
import ModalHeader from "../../atoms/ModalHeader";
import Stack from "../../atoms/Stack";
import CalendarPicker from "../CalendarPicker";
import ModalContainer from "../ModalContainer";

interface Props
  extends Pick<
    ComponentProps<typeof CalendarPicker>,
    "multiple" | "min" | "max"
  > {
  visible: boolean;
  testID: string;
  onClose: () => void;
  submitLabel: string;
  title: string;
  onChange: (value?: DateTime[]) => void;
  initialValues?: DateTime[];
}

export default function CalendarModal({
  visible,
  testID,
  onClose,
  submitLabel,
  initialValues,
  title,
  onChange,
  ...rest
}: Props) {
  const [calendarSelection, setCalendarSelection] = useState<
    DateTime[] | undefined
  >(initialValues);

  useEffect(() => {
    if (initialValues && !calendarSelection) {
      setCalendarSelection(initialValues);
    }
  }, [initialValues]);
  return (
    <ModalContainer
      testID={`${testID}-date-picker-calendar-modal`}
      eventContext="Date Picker Calendar Modal"
      visible={visible}
      onRequestClose={onClose}
    >
      <ModalHeader title={title} />
      <CalendarPicker
        testID={`${testID}-picker-modal`}
        onChange={(value) => setCalendarSelection(value)}
        value={calendarSelection}
        {...rest}
      />
      <ModalFooter>
        <Stack>
          <Button
            testID={`${testID}-date-picker-calendar-modal-close-btn`}
            label="Cancel"
            onPress={onClose}
            outline
          />
          <Button
            testID={`${testID}-date-picker-calendar-modal-save-btn`}
            label={submitLabel}
            onPress={() => {
              onChange(calendarSelection);
              onClose();
            }}
          />
        </Stack>
      </ModalFooter>
    </ModalContainer>
  );
}
