import React, {
  Children,
  isValidElement,
  type ReactElement,
  type ReactNode
} from "react";
import Stack from "../atoms/Stack";
import Column from "../quarks/Column";
import Icon from "../quarks/Icon";
import Text from "../quarks/Text";
import type { ColorProp } from "../style/theme/colors";
import type { OnlyChildren } from "../utils/types";
import IconCircle from "./IconCircle";

type Props = OnlyChildren<ReactElement<Text | ReactNode> | null> & {
  variant?: "ordered" | "bullet" | "check" | "circle-check" | "plain";
  size?: "standard" | "medium" | "compact" | "slim" | "small";
  iconColor?: ColorProp;
  disabled?: boolean;
  fill?: boolean | number;
};

export default function List({
  children,
  variant = "ordered",
  size = "medium",
  iconColor = "success",
  disabled,
  fill
}: Props) {
  const validChildren = Children.toArray(children).filter(isValidElement);

  return (
    <Stack size={size} fill={fill}>
      {validChildren.map((child, index) => (
        <Stack
          key={index}
          horizontal
          size="compact"
          alignItems={
            variant === "check" || variant === "circle-check"
              ? "center"
              : "flex-start"
          }
        >
          {variant === "ordered" ? (
            <Text weight="bold">{`${index + 1}`}.</Text>
          ) : variant === "bullet" ? (
            <Text color={disabled ? "disabled" : "black"}>{"\u2022"}</Text>
          ) : variant === "check" ? (
            <Icon name="check" variant="solid" size="small" color={iconColor} />
          ) : variant === "circle-check" ? (
            <IconCircle
              size="tiny"
              icon="check"
              color={iconColor}
              variant="well"
            />
          ) : null}
          <Column fill justifyContent="center">
            {child}
          </Column>
        </Stack>
      ))}
    </Stack>
  );
}
