import React, { useState } from "react";

import { Button, ContentArea, Stack, Surface } from "../atoms";
import { TextInput } from "../molecules";
import { Spacer } from "../quarks";
import { showModal } from "./ModalProvider";

interface Props {
  testID: string;
  onSave: (val: string) => void;
}

export function showHyperLinkModal(props: Props) {
  showModal({
    title: "Add Hyperlink",
    eventContext: "hyperlink-modal",
    children: (close) => (
      <HyperlinkInput
        {...props}
        onSave={(val: string) => {
          props.onSave(val);
          setTimeout(close, 100);
        }}
      />
    )
  });
}

export default function HyperlinkInput({ testID, onSave }: Props) {
  const [displayText, setDisplayText] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  return (
    <Stack testID={testID}>
      <Surface variant="outline" testID={testID}>
        <ContentArea>
          <Stack>
            <TextInput
              label="Display Text (Optional)"
              testID={`${testID}-text-input`}
              value={displayText}
              onChangeText={setDisplayText}
            />
            <TextInput
              label="Link"
              testID={`${testID}-link-input`}
              value={url}
              onChangeText={setUrl}
              type="url"
              autoCapitalize="none"
            />
          </Stack>
        </ContentArea>
      </Surface>
      <Button
        label="Add Hyperlink"
        testID={`${testID}-add-button`}
        disabled={!url}
        onPress={() => {
          if (displayText) {
            onSave(`[${displayText}](${url})`);
          } else {
            onSave(`[${url}](${url})`);
          }
        }}
      />
      <Spacer />
    </Stack>
  );
}
