import React, { type ReactNode } from "react";
import { View } from "react-native";
import { useStyle } from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";
import type { Color } from "../style/theme/colors";

interface Props {
  children: ReactNode;
  color?: Color;
  divider?: boolean;
  spacing?: "default" | "none";
  variant?: "standard" | "well";
}

export default function CardFooter({
  children,
  divider,
  color = "neutral",
  spacing = "default",
  variant = "standard"
}: Props) {
  const viewStyle = useStyle(
    ({ getUnits, getColor }) => ({
      backgroundColor: getColor(color, "fill", {
        opacity: variant === "well" ? 0.08 : 1
      }),
      borderBottomLeftRadius: getUnits(1),
      borderBottomRightRadius: getUnits(1),
      ...(spacing !== "none" && {
        paddingHorizontal: getUnits(4),
        paddingVertical: getUnits(1)
      }),
      ...(divider && { borderTopColor: "#0001", borderTopWidth: 1 })
    }),
    [color, divider, spacing]
  );

  return (
    <ColorPlacementProvider color={color}>
      <View style={viewStyle}>{children}</View>
    </ColorPlacementProvider>
  );
}
