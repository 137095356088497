import React, { memo, useEffect, useRef } from "react";
import { Animated } from "react-native";
import { useTheme } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  progress: number;
  show?: boolean;
  height?: number;
  color?: Color;
}

export default memo(
  function ProgressStrip({ progress, show = true, height = 2, color }: Props) {
    const theme = useTheme();

    if (progress > 1) progress = 1;
    const backgroundColor = theme.getColor(color, "fill") ?? "#8BA5B4";

    const opacity = useRef(new Animated.Value(show ? 1.0 : 0.0)).current;
    const width = useRef(new Animated.Value(0)).current;
    useEffect(() => {
      Animated.timing(opacity, {
        toValue: show ? height : 0,
        duration: show ? 0 : 2000,
        useNativeDriver: false
      }).start();
      Animated.timing(width, {
        toValue: Math.round(progress * 100),
        duration: 250,
        useNativeDriver: false
      }).start();
    }, [show, progress, height, opacity, width]);

    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <Animated.View style={{ width: "100%", opacity }}>
        <Animated.View
          style={{
            backgroundColor,
            height,
            width: width.interpolate({
              inputRange: [0, 1],
              outputRange: ["0%", "1%"]
            })
          }}
        />
      </Animated.View>
    );
  },
  (p1, p2) => Math.abs(p1.progress - p2.progress) < 0.01
);
