import ShadowView from "@gigsmart/shadow-view";
import React from "react";

import { Pressable } from "react-native";
import { Icon, type IconName } from "../quarks";
import { useStyles } from "../style";

interface Props {
  icon: IconName;
  testID: string;
  onPress?: () => void;
}

export default function ToolbarIcon({ testID, onPress, icon }: Props) {
  const styles = useStyles(({ getUnits, getColor }) => {
    const size = getUnits(8);
    return {
      button: {
        width: size,
        height: size,
        borderRadius: size / 2,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: getColor("surface", "fill"),
        shadowColor: "#000000",
        shadowOpacity: 0.15,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 2 }
      },
      pressed: { opacity: 0.5 }
    };
  });

  return (
    <Pressable
      testID={testID}
      onPress={onPress}
      style={({ pressed }) => pressed && styles.pressed}
    >
      <ShadowView style={styles.button}>
        <Icon name={icon} variant="solid" size="medium" color="primary" />
      </ShadowView>
    </Pressable>
  );
}
