import React, { type ComponentProps, type ReactNode } from "react";
import type { FlexAlignType } from "react-native";
import { Card, IconSquare, ListRow, Stack, Surface } from "../atoms";
import InfoElement from "../organisms/InfoElement";
import { Icon, type IconName, Pressable, Text } from "../quarks";
import type { Color } from "../style/theme/colors";

interface Props {
  variant?: ComponentProps<typeof Surface>["variant"] | "card";
  title?: string | ReactNode;
  color?: Color;
  numberOfLines?: number;
  note?: string | ReactNode;
  noteNumberOfLines?: number;
  noteColor?: Color;
  icon?: IconName;
  iconSquareVariant?: "standard" | "well";
  iconColor?: Color;
  left?: ReactNode;
  leftSpacing?: "medium" | "small";
  right?: ReactNode;
  rightSpacing?: ComponentProps<typeof ListRow>["rightSpacing"];
  children?: ReactNode;
  onPress?: () => void;
  eventEntityType?: string;
  onInfo?: () => void;
  testID: string;
  active?: boolean;
  activeColor?: Color;
  backgroundColor?: Color;
  alignItems?: FlexAlignType;
  rightAlign?: FlexAlignType;
  pressableIconColor?: Color;
}

export default function ActionRow({
  testID,
  eventEntityType = "ActionRow",
  numberOfLines,
  title,
  color = "primary",
  note,
  noteNumberOfLines,
  noteColor,
  variant = "mixed",
  icon,
  iconColor,
  iconSquareVariant = "well",
  leftSpacing,
  left,
  right,
  onInfo,
  onPress,
  active,
  activeColor = "highlight",
  children,
  backgroundColor,
  pressableIconColor,
  ...rest
}: Props) {
  leftSpacing ??= !left && icon ? "small" : "medium";
  left ??= icon ? (
    <IconSquare
      color={iconColor ?? color}
      size="medium"
      icon={icon}
      variant={iconSquareVariant}
    />
  ) : null;
  right ??= onPress ? (
    <Icon
      name="chevron-right"
      color={pressableIconColor ?? color}
      size="small"
      variant="solid"
    />
  ) : null;

  if (typeof title === "string") {
    title = (
      <Text variant="subheader" color={color} numberOfLines={numberOfLines}>
        {title}
      </Text>
    );
  }
  if (onInfo) {
    title = (
      <Stack horizontal size="compact">
        {title}
        <InfoElement
          testID={`${testID}-info-icon`}
          variant="icon"
          openModal={onInfo}
        />
      </Stack>
    );
  }

  const content = (
    <ListRow
      {...rest}
      spacing="medium"
      stackSize="small"
      leftSpacing={leftSpacing}
      left={left}
      right={right}
      label={title}
    >
      {!!note && (
        <Text
          variant="note"
          color={noteColor}
          numberOfLines={noteNumberOfLines}
        >
          {note}
        </Text>
      )}
      {children}
    </ListRow>
  );

  if (variant === "card") {
    return (
      <Card onPress={onPress} testID={testID} eventTargetName="Action Row Card">
        {content}
      </Card>
    );
  }

  return (
    <Surface
      variant={variant}
      {...(active && { color: activeColor, fade: true })}
      {...(!!backgroundColor &&
        backgroundColor !== "surface" && {
          fade: true,
          variant: variant ?? "flat",
          color: backgroundColor
        })}
    >
      <Pressable
        eventEntityType={eventEntityType}
        onPress={onPress}
        testID={testID}
        disabled={!onPress}
        eventTargetName=""
      >
        {content}
      </Pressable>
    </Surface>
  );
}
