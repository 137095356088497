import { useMap } from "@vis.gl/react-google-maps";
import { debounce } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  type Ref
} from "react";
import type { LatLng } from "react-native-maps";
import { latlng } from "../helpers";

type PolygonRef = Ref<google.maps.Polygon | null>;
type Props = {
  paths: LatLng[];
  editable?: boolean;
  draggable?: boolean;
  strokeWeight?: number;
  fillOpacity?: number;
  onChangePath?: (paths: LatLng[]) => void;
};

function usePolygon({ paths, onChangePath, ...polygonProps }: Props) {
  const map = useMap();
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [path, setPath] =
    useState<google.maps.MVCArray<google.maps.LatLng> | null>(null);

  useEffect(() => {
    if (!map) return;

    const path = createPath(paths);
    const p = new google.maps.Polygon({
      map,
      paths: path,
      strokeColor: "#0c4cb3",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#3b82f6",
      fillOpacity: 0.3,
      ...polygonProps
    });

    setPolygon(p);
    setPath(path);
    return () => {
      p.setMap(null);
      setPolygon(null);
      setPath(null);
    };
  }, [map]);

  useEffect(() => {
    if (!path || !polygon || !onChangePath) return;

    const gme = google.maps.event;
    const triggerPathChange = debounce(() => {
      const path = polygon
        ?.getPath()
        .getArray()
        .map((it) => latlng(it.toJSON()));
      if (path) onChangePath(path);
    }, 500);

    gme.addListener(path, "set_at", triggerPathChange);
    gme.addListener(path, "insert_at", triggerPathChange);
    gme.addListener(polygon, "dragend", triggerPathChange);

    return () => {
      gme.clearInstanceListeners(path);
    };
  }, [polygon, path]);

  useEffect(() => {
    if (!polygon) return;
    polygon.setOptions(polygonProps);
  }, [polygonProps]);

  useEffect(() => {
    if (!polygon) return;

    const newPath = createPath(paths);
    if (path && isEqual(newPath, path)) return;
    polygon.setPath(newPath);
    setPath(newPath);
  }, [paths]);

  return polygon;
}

/**
 * Component to render a polygon on a map
 */
export const Polygon = forwardRef((props: Props, ref: PolygonRef) => {
  const polygon = usePolygon(props);
  useImperativeHandle(ref, () => polygon, []);
  return <></>;
});

export default Polygon;

const isEqual = (
  a: google.maps.MVCArray<google.maps.LatLng>,
  b: google.maps.MVCArray<google.maps.LatLng>
) => {
  const len = a.getLength();
  if (len !== b.getLength()) return false;
  for (let i = 0; i < len; i++) {
    if (!a.getAt(i).equals(b.getAt(i))) return false;
  }

  return true;
};

const createPath = (points: LatLng[]) => {
  const path = new google.maps.MVCArray<google.maps.LatLng>();
  for (const latlng of points) {
    path.push(new google.maps.LatLng(latlng.latitude, latlng.longitude));
  }
  return path;
};
