export default function borderRadius(...args: number[]) {
  if (!args) return {};
  if (args.length === 1) {
    return { borderRadius: args[0] };
  }
  if (args.length === 2) {
    return {
      borderTopLeftRadius: args[0],
      borderTopRightRadius: args[1],
      borderBottomRightRadius: args[0],
      borderBottomLeftRadius: args[1]
    };
  }
  if (args.length === 3) {
    return {
      borderTopLeftRadius: args[0],
      borderTopRightRadius: args[1],
      borderBottomRightRadius: args[2],
      borderBottomLeftRadius: args[1]
    };
  }
  return {
    borderTopLeftRadius: args[0],
    borderTopRightRadius: args[1],
    borderBottomRightRadius: args[2],
    borderBottomLeftRadius: args[3]
  };
}
