import React from "react";
import { StyleSheet, View } from "react-native";
import { useModalContext } from "../organisms/ModalContext";
import Pressable from "../quarks/Pressable";
import { useStyle } from "../style";

export default function ModalBackdrop() {
  const { onRequestClose, dismissable } = useModalContext();
  const rootStyle = useStyle(() => ({
    // TODO: Jus - Get color from theme?
    backgroundColor: "#000000C0",
    width: "100%",
    height: "100%"
  }));
  return (
    <Pressable
      style={StyleSheet.absoluteFill}
      testID={"modal-backdrop"}
      eventTargetName="Modal Backdrop"
      eventEntityType="Backdrop"
      onPress={dismissable ? onRequestClose : null}
      disabled={!onRequestClose}
    >
      <View style={rootStyle} />
    </Pressable>
  );
}
