import React, { type ReactNode } from "react";
import InfoElement from "../organisms/InfoElement";
import { Column, Row, Text } from "../quarks";
import { useStyles } from "../style";

type Props = {
  fill?: boolean;
  title: string;
  numberOfLines?: number;
  onTitlePress?: () => void;
  onInfo?: () => void;
  children?: ReactNode;
};

export default function DescriptionItem({
  fill,
  title,
  onTitlePress,
  onInfo,
  children,
  numberOfLines
}: Props) {
  const styles = useStyles(() => ({
    title: { height: 24 }
  }));
  return (
    <Column fill={fill}>
      <Row style={styles.title} gap="slim" alignItems="center">
        <Text
          variant="note"
          color={onTitlePress ? "link" : "neutral"}
          onPress={onTitlePress}
          numberOfLines={numberOfLines}
        >
          {title}
        </Text>
        {!!onInfo && (
          <InfoElement
            variant="icon"
            openModal={onInfo}
            testID="descripton-item-info-trigger"
          />
        )}
      </Row>
      {children}
    </Column>
  );
}
