import React, { type ReactNode } from "react";
import { IconText } from "../../atoms";
import { Column, Icon, Pressable, Row, Text } from "../../quarks";
import { useStyles } from "../../style";
import { useMatchesViewport } from "../../style";
import DayIndicator from "./DayIndicator";
import type { PeriodDay } from "./types";

interface Props {
  testID?: string;
  day: PeriodDay;
  fill?: boolean;
  grow?: boolean;
  children: ReactNode;
  onPressAddNewCalendarItem: (day: PeriodDay) => void;
}

export default function MonthlyCalendarDayCell({
  day,
  children,
  onPressAddNewCalendarItem,
  ...rest
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);

  const styles = useStyles(({ getColor }) => ({
    content: {
      padding: isSm ? 4 : 8
    }
  }));

  return (
    <Column {...rest} style={styles.content} gap="tiny">
      <Row justifyContent={isSm ? "center" : "space-between"}>
        <DayIndicator day={day} />
        {!isSm && (
          <Column justifyContent="center">
            <Pressable
              onPress={() => onPressAddNewCalendarItem(day)}
              eventEntityType="Add Button"
              eventTargetName="Add Button"
              testID={`${rest.testID}-add-button`}
            >
              <IconText icon="plus" color="neutral" size="tiny">
                <Text variant="note" weight="bold">
                  Add
                </Text>
              </IconText>
            </Pressable>
          </Column>
        )}
      </Row>
      {children}
      {isSm && (
        <Column fill={1} justifyContent="flex-end">
          <Pressable
            testID={`add-item-day-${day.date}`}
            eventTargetName="Add Day Item Button"
            eventEntityType="Add Button"
            style={{ justifyContent: "center" }}
            onPress={() => onPressAddNewCalendarItem(day)}
          >
            <Icon
              name="plus"
              size="medium"
              variant="solid"
              color="neutral"
              align="center"
            />
          </Pressable>
        </Column>
      )}
    </Column>
  );
}
