import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import type { DeepPartial } from "@gigsmart/type-utils";
import { camelCase } from "lodash";
import React, { type ComponentType, type Ref } from "react";
import type { GraphQLTaggedNode } from "react-relay";
import type {
  DeclarativeMutationConfig,
  MutationParameters,
  RecordSourceSelectorProxy,
  SelectorStoreUpdater
} from "relay-runtime";
import {
  type RelayOrchestratorProp,
  relayOrchestratorStub,
  useRelayOrchestrator
} from "../orchestrator";
import type { PayloadError } from "../payload-error";
import {
  type RelayMutationCommitOptions,
  useRelayMutation
} from "./use-relay-mutation";

export const mutationStub = {
  commit: (
    _variables?: any,
    _commitOptions?: RelayMutationCommitOptions<any>
  ) => {
    throw new Error("Not implemented");
  },
  errors: null
} as any;

export const relayMutationStubs = {
  mutation: mutationStub,
  relay: relayOrchestratorStub
};

export interface Mutation<TOperation extends MutationParameters> {
  commit: (
    variables?: TOperation["variables"],
    commitOptions?: RelayMutationCommitOptions<TOperation>
  ) => void | Promise<void>;
  errors: Array<Error | PayloadError> | null | undefined;
  componentRef: Ref<any> | null | undefined;
}

export interface WithRelayMutationOptions<
  TOperation extends MutationParameters
> {
  onError?: (arg0: PayloadError) => unknown;
  onErrors?: (arg0: PayloadError[]) => unknown;
  onCompleted?: (
    response:
      | (TOperation["rawResponse"] & TOperation["response"])
      | null
      | undefined
  ) => unknown;
  onSuccess?: (
    response: TOperation["rawResponse"] & TOperation["response"]
  ) => unknown;
  withOptimisticResponse?: (
    props: Record<string, any>,
    variables: TOperation["variables"]
  ) => DeepPartial<TOperation["rawResponse"]>;
  withOptimisticUpdater?: (
    props: Record<string, any>,
    store: RecordSourceSelectorProxy<TOperation["rawResponse"]>,
    data: TOperation["rawResponse"] | null | undefined
  ) => unknown;
  withUpdater?: (
    props: any,
    store: RecordSourceSelectorProxy<
      TOperation["rawResponse"] & TOperation["response"]
    >,
    data:
      | (TOperation["rawResponse"] & TOperation["response"])
      | null
      | undefined
  ) => unknown;
  withConfigs?: (props: any) => DeclarativeMutationConfig[];
  withVariables?:
    | ((props: any) => Partial<TOperation["variables"]>)
    | Partial<TOperation["variables"]>;
}

export interface RelayMutationProps<TOperation extends MutationParameters> {
  mutation: Mutation<TOperation>;
  graphqlMutation?: GraphQLTaggedNode;
  relay: RelayOrchestratorProp;
}

type Props<TOperation extends MutationParameters> =
  WithRelayMutationOptions<TOperation> & {
    prop?: string;
  };

/** @deprecated Use hooks instead of HOCs */
export const withRelayMutation =
  <TOperation extends MutationParameters>(
    graphqlMutation?: GraphQLTaggedNode | null | undefined,
    {
      prop = "mutation",
      withOptimisticResponse,
      withOptimisticUpdater,
      withUpdater,
      withConfigs,
      withVariables,
      ...options
    }: Props<TOperation> = {}
  ): HOCVoid =>
  (WrappedComponent: ComponentType<RelayMutationProps<TOperation>>) => {
    return applyHOCProperties({
      WrappedComponent,
      displayName: "withRelayMutation",
      HigherOrderComponent: ({
        componentRef,
        ...props
      }: {
        componentRef: Ref<typeof WrappedComponent>;

        [key: string]: unknown;
      }) => {
        const propKey = camelCase(`graphql_${prop}`);
        const propName = camelCase(prop);
        const optimisticResponse =
          withOptimisticResponse &&
          ((variables: TOperation["variables"]) =>
            withOptimisticResponse(props, variables));
        const updater:
          | SelectorStoreUpdater<
              TOperation["rawResponse"] & TOperation["response"]
            >
          | undefined =
          withUpdater &&
          ((
            store: RecordSourceSelectorProxy<
              TOperation["rawResponse"] & TOperation["response"]
            >,
            data:
              | (TOperation["rawResponse"] & TOperation["response"])
              | null
              | undefined
          ) => withUpdater(props, store, data));
        const optimisticUpdater:
          | SelectorStoreUpdater<TOperation["rawResponse"]>
          | undefined =
          withOptimisticUpdater &&
          ((
            store: RecordSourceSelectorProxy<TOperation["rawResponse"]>,
            data: TOperation["rawResponse"] | null | undefined
          ) => withOptimisticUpdater(props, store, data));
        const configs = withConfigs?.(props);
        const variables =
          typeof withVariables === "function"
            ? withVariables(props)
            : withVariables;
        const relay = useRelayOrchestrator();
        const [commit, { loading, response, errors }] =
          useRelayMutation<TOperation>(
            (props[propKey] as GraphQLTaggedNode) || graphqlMutation,
            {
              optimisticResponse,
              optimisticUpdater,
              updater,
              configs,
              variables,
              ...options
            }
          );
        const mutation = {
          commit,
          errors,
          loading,
          response,
          componentRef
        };
        return (
          <WrappedComponent
            {...{
              ...(props as any),
              relay,
              ref: componentRef,
              [propName]: mutation
            }}
          />
        );
      }
    });
  };
