import React, { type ReactNode } from "react";
import { View, type ViewProps } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { type UnitSize, getSpaceUnits } from "../quarks/Spacer";

interface Props {
  size?: UnitSize;
  pointerEvents?: ViewProps["pointerEvents"];
  children?: ReactNode;
}

export default function FooterSpacer({
  pointerEvents,
  size = "standard",
  children
}: Props) {
  const { bottom } = useSafeAreaInsets();
  const paddingBottom = Math.max(bottom, getSpaceUnits(size));

  return (
    <View pointerEvents={pointerEvents} style={{ paddingBottom }}>
      {children}
    </View>
  );
}
