export const BASE_UNIT = 4;

export function units(value: number): number;
export function units(value: string): string;
export function units(value: string | number) {
  if (typeof value === "string") return value;
  return value * BASE_UNIT;
}

export const rem = (number: number) => units(4) * number;

export function pixelsToUnits(pixels: string): string;
export function pixelsToUnits(pixels: number): number;
export function pixelsToUnits(pixels: string | number) {
  if (typeof pixels === "string") return pixels;
  return pixels / BASE_UNIT;
}

export const unit = {
  padding: {
    title: BASE_UNIT * 3,
    small: BASE_UNIT,
    medium: BASE_UNIT * 2,
    large: BASE_UNIT * 4
  },
  margin: {
    small: BASE_UNIT,
    medium: BASE_UNIT * 2,
    large: BASE_UNIT * 4
  },
  borderRadius: BASE_UNIT,
  shadowOffset: {
    width: 0,
    height: BASE_UNIT / 2
  },
  shadowRadius: BASE_UNIT,
  elevation: BASE_UNIT
};

export const breakpoint = {
  // 0px
  "@none": 0,

  // 320px
  "@phone": rem(20),

  // 512px
  "@phoneDown": rem(32),

  // 768px
  "@tablet": rem(48),

  // 960px
  "@tabletDown": rem(60),

  // 1200px
  "@desktop": rem(75),

  // 1440px
  "@desktopWide": rem(90)
};
