export default function normalizeOptions(
  options: Array<string | { label?: string; value: unknown | null | undefined }>
): Array<{ label?: string; value: unknown | null | undefined }> {
  const items: Array<{
    label?: string;
    value: unknown | null | undefined;
  }> = options.map(
    (option: string | { label?: string; value: unknown | null | undefined }) =>
      typeof option === "object" ? option : { label: option, value: option }
  );
  return items;
}
