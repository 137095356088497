import { type FormFieldOptions, useFormField } from "@gigsmart/fomu";
import React, { type ComponentProps } from "react";
import { FlatList, View } from "react-native";
import type { FlatListProps } from "react-native";
import { SelectableListItem } from "../molecules";
import { Spacer } from "../quarks";
import { useStyles } from "../style";
import type Grid from "./Grid";
import { useGridStyles } from "./Grid";

export type SelectableGridListProps<T, F extends string> = ComponentProps<
  typeof Grid
> &
  Omit<
    FlatListProps<T>,
    | "contentContainerStyle"
    | "data"
    | "keyExtractor"
    | "renderItem"
    | "ListHeaderComponent"
    | "ListFooterComponent"
    | "ListEmptyComponent"
    | "numColumns"
  > &
  Pick<FormFieldOptions<F[]>, "name" | "validates"> & {
    data: T[] | readonly T[] | null;
    header?: JSX.Element | null;
    footer?: JSX.Element | null;
    emptyContent?: JSX.Element | null;
    renderItem: (item: T) => JSX.Element | null;
    keyExtractor: (item: T, index: number) => F;
    grow?: boolean;
    multiple?: boolean;
    selectAll?: null | string | ((count: number) => string);
    totalCount?: number;
    onSelectAll?: (selected: boolean, setValue: (v: F[]) => void) => void;
  };

export default function SelectableGridList<T, F extends string>({
  name,
  validates,
  multiple,
  data,
  selectAll,
  onSelectAll,
  renderItem,
  spacing = "medium",
  keyExtractor,
  header,
  numColumns = { medium: 2, xlarge: 3, xxlarge: 4 },
  size = "none",
  variant,
  constraint,
  emptyContent,
  footer,
  grow = true,
  ...props
}: SelectableGridListProps<T, F>) {
  const { gridStyles, columns } = useGridStyles({ spacing, numColumns }, false);
  const styles = useStyles(
    () => ({
      container: { flex: 1 },
      grow: { flexGrow: 1 },
      footer: { justifyContent: "flex-end" }
    }),
    []
  );
  const { value, setValue } = useFormField({ name, validates });

  const handleChange = (key: F, selected: boolean) => {
    let newValue = value ?? [];
    if (multiple) {
      newValue = selected
        ? [...newValue, key]
        : newValue.filter((it) => it !== key);
      setValue(newValue);
    } else {
      setValue(selected ? [key] : []);
    }
  };

  return (
    <FlatList
      // Changing numColumns on the fly is not supported
      key={`cols_${columns}`}
      data={data}
      style={styles.container}
      contentContainerStyle={grow && styles.grow}
      numColumns={columns}
      keyExtractor={keyExtractor}
      ListFooterComponentStyle={[grow && styles.grow, styles.footer]}
      ListEmptyComponent={emptyContent}
      ListHeaderComponent={header}
      ListFooterComponent={footer}
      columnWrapperStyle={columns > 1 && gridStyles.itemWrapper}
      renderItem={({ item, index }) => {
        const key = keyExtractor(item, index);
        const isSelected = !!value?.includes(key);
        return (
          <View style={gridStyles.item}>
            <SelectableListItem
              multiple={multiple}
              selected={isSelected}
              onChange={(selected) => handleChange(key, selected)}
            >
              {renderItem(item)}
            </SelectableListItem>
          </View>
        );
      }}
      {...props}
    />
  );
}

const SeparatorComponent = () => <Spacer size="compact" />;
