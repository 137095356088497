import React from "react";
import { Platform } from "react-native";

export const APP_ID = process.env.GS_APP_ID ?? "any";

export const IS_TESTING =
  process.env.IS_TESTING === "true" || process.env.CONFIG_ENV === "e2e";

export const WOR_WELCOME_YT = "haA76rMDE38";

export const LINE_BREAK = Platform.select({
  web: React.createElement("br") as any,
  default: "\n"
});
