import { useMap } from "@vis.gl/react-google-maps";
import {
  type Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import React from "react";
import type { LatLng } from "react-native-maps";

type PolylineRef = Ref<google.maps.Polyline | null>;

type Props = {
  coordinates: LatLng[];
  editable?: boolean;
  draggable?: boolean;
  strokeColor?: string;
};

const LatLngToMVCArray = (coordinates: LatLng[]) => {
  const mvcArray = new google.maps.MVCArray();
  coordinates.forEach((coord) => {
    mvcArray.push(new google.maps.LatLng(coord.latitude, coord.longitude));
  });
  return mvcArray;
};

function usePolyline({
  coordinates,
  strokeColor = "#0c4cb3",
  ...polylineProps
}: Props) {
  const [polyline, setPolyline] = useState<google.maps.Polyline | null>(null);
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const p = new google.maps.Polyline({
      ...polylineProps,
      path: LatLngToMVCArray(coordinates),
      map,
      strokeColor,
      strokeOpacity: 1,
      strokeWeight: 4
    });

    setPolyline(p);
    return () => {
      p.setMap(null);
      setPolyline(null);
    };
  }, [map]);

  useEffect(() => {
    if (!polyline) return;
    // const pos = latlng.toLiteral(center);
    // if (!latLngEquals(pos, circle.getCenter())) circle.setCenter(pos);
    polyline.setPath(LatLngToMVCArray(coordinates));
  }, []);

  useEffect(() => {
    if (!polyline) return;
    polyline.setOptions({
      ...polylineProps
    });
  }, [coordinates, polylineProps]);

  return polyline;
}

/**
 * Component to render a polyline on a map
 */
export default forwardRef(function Polyline(props: Props, ref: PolylineRef) {
  const polyline = usePolyline(props);
  useImperativeHandle(ref, () => polyline, [polyline]);
  return <></>;
});
