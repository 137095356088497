import { createContext, useContext, useEffect, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import type { ErrorEvent } from "reconnecting-websocket/dist/events";
interface InstrumenterOptions {
  connectMetadata: any;
  onConnected?: () => void;
  onClose?: () => void;
  onError?: (arg0: ErrorEvent) => void;
  onWaiting?: () => void;
  onTeardown?: () => void;
  onHandled?: (
    id: string | number,
    command: string,
    target: string | null | undefined,
    payload: any,
    response: any
  ) => void;
}

type Handler = (arg0: any) => any;

class Instrumenter {
  testerUrl?: string;
  handlerSets: Map<string, Set<Handler>> = new Map<string, Set<Handler>>();
  ws?: ReconnectingWebSocket;
  connectTimeout?: number;
  heartbeatInterval?: ReturnType<typeof setInterval>;
  heartbeatResponseTimeout?: ReturnType<typeof setTimeout>;
  onWaiting: () => void;
  onTeardown: () => void;
  onHandled?: (
    id: string | number,
    command: string,
    target: string | null | undefined,
    payload: any,
    response: any
  ) => void;

  constructor(
    sessionId: string | null | undefined,
    {
      onWaiting = () => {},
      onConnected,
      onClose,
      onError,
      onTeardown = () => {},
      onHandled,
      connectMetadata
    }: InstrumenterOptions = { connectMetadata: null }
  ) {
    this.onTeardown = onTeardown;
    this.onWaiting = onWaiting;
    this.onHandled = onHandled;
    if (sessionId !== null && sessionId !== undefined && sessionId !== "") {
      const clientUrl = `${instrumentationBaseUrl}/s/${sessionId}/c`;
      this.testerUrl = `${instrumentationBaseUrl}/s/${sessionId}/t`;
      this.ws = new ReconnectingWebSocket(clientUrl);
      this.ws.onclose = () => {
        if (onClose) onClose();
      };
      this.ws.onmessage = async ({ data }) => {
        const { command, target, payload, requestId } = JSON.parse(
          String(data)
        );
        await this.handle(command, target, payload, requestId);
      };
      this.ws.onopen = () => {
        if (onWaiting) onWaiting();
        console.debug(
          `connect manually: ${instrumentationBaseUrl.replace(
            /^ws/,
            "http"
          )}#${sessionId}`
        );
        this.reportReady(connectMetadata);
      };
      this.ws.onerror = (error) => {
        console.error(error);
        if (onError) onError(error);
      };
      this.addHandler("/ready", null, () => {
        if (this.heartbeatInterval) clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = setInterval(this.heartbeatTester, 10000);
        if (onConnected) onConnected();
      });
      this.addHandler("/list", null, () => ({
        availableInstruments: this.availableInstruments
      }));
      this.addHandler("/waiting", null, () => {
        this.reportReady(connectMetadata);
      });
      this.addHandler("/heartbeat", null, () => {
        if (this.heartbeatResponseTimeout) {
          clearTimeout(this.heartbeatResponseTimeout);
        }
        return true;
      });
      this.addHandler("/teardown", null, () => {
        onTeardown();
        if (this.ws !== undefined) {
          this.ws.send(JSON.stringify({ requestId: "torndown" }));
        }
      });
    }
  }

  throwError = (error: Error) => {
    const { message, name, stack } = error;
    if (this.ws !== undefined) {
      this.ws.send(
        JSON.stringify({ requestId: "error", error: { message, name, stack } })
      );
    }
  };

  reportReady(connectMetadata: any) {
    if (this.ws !== undefined) {
      this.ws.send(
        JSON.stringify({ requestId: "ready", response: connectMetadata })
      );
    }
  }

  heartbeatTester = () => {
    if (this.ws !== undefined) {
      if (this.heartbeatResponseTimeout) {
        clearTimeout(this.heartbeatResponseTimeout);
      }
      this.ws.send(JSON.stringify({ requestId: "heartbeat" }));
      this.heartbeatResponseTimeout = setTimeout(
        this.handleHeartbeatTimeout,
        5000
      );
    }
  };

  handleHeartbeatTimeout = () => {
    if (this.heartbeatInterval) clearInterval(this.heartbeatInterval);
    this.onWaiting();
    this.onTeardown();
  };

  destroy() {
    if (this.heartbeatResponseTimeout) {
      clearTimeout(this.heartbeatResponseTimeout);
    }
    if (this.heartbeatInterval) clearInterval(this.heartbeatInterval);
    if (this.connectTimeout) clearTimeout(this.connectTimeout);
    if (this.ws) {
      this.ws.onclose = () => null;
      this.ws.close();
    }
  }

  get availableInstruments(): string[] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    return [...this.handlerSets.keys()].map((k) => k.split(":")[0]!);
  }

  async handle(
    command: string,
    target: string | null | undefined,
    payload: any,
    requestId: string | number,
    retriesRemaining = 100
  ): Promise<any> {
    const handlers = this.getHandlersFor(command, target);

    if (retriesRemaining > 0 && handlers.size === 0) {
      return await new Promise((resolve) =>
        setTimeout(
          () =>
            resolve(
              this.handle(
                command,
                target,
                payload,
                requestId,
                retriesRemaining - 1
              )
            ),
          100
        )
      );
    }
    await Promise.allSettled(
      [...handlers].map(async (handler) => {
        let response;
        let error;

        if (typeof requestId !== "undefined" && this.ws !== undefined) {
          this.ws.send(
            JSON.stringify({
              requestId,
              ack: true
            })
          );
        }

        try {
          response = await Promise.resolve(handler(payload));
        } catch (err: any) {
          error = {
            name: (err as any).name,
            message: (err as any).message,
            stack: (err as any).stack
          };
        }

        if (typeof requestId !== "undefined" && this.ws !== undefined) {
          const data = JSON.stringify({
            requestId,
            response,
            error
          });
          this.onHandled?.(requestId, command, target, payload, response);
          this.ws.send(data);
        }
      })
    );
  }

  getHandlersFor = (command: string, target: string | null | undefined) => {
    const name = [command, target].join(":");
    let handlers = this.handlerSets.get(name);
    if (!handlers) {
      handlers = new Set<Handler>();
      this.handlerSets.set(name, handlers);
    }
    return handlers;
  };

  addHandler = (
    command: string,
    target: string | null | undefined,
    handler: Handler
  ) => {
    this.getHandlersFor(command, target).add(handler);
  };

  removeHandler = (
    command: string,
    target: string | null | undefined,
    handler: Handler
  ) => {
    this.getHandlersFor(command, target).delete(handler);
  };
}

export const useTestInstrumenter = (
  sessionId: string | null | undefined,
  connectMetadata?: any,
  onTeardown?: () => void,
  onHandled?: (
    id: string | number,
    command: string,
    target: string | null | undefined,
    payload: any,
    response: any
  ) => void
) => {
  const [instrumenter, setInstrumenter] = useState<Instrumenter>(
    new Instrumenter(null)
  );
  const [status, setStatus] = useState<
    "NONE" | "WAITING" | "READY" | "CLOSED" | "ERRORED"
  >("NONE");
  useEffect(() => {
    const newInstrumenter = new Instrumenter(sessionId, {
      onConnected: () => setStatus("READY"),
      onWaiting: () => setStatus("WAITING"),
      onClose: () => setStatus("CLOSED"),
      onError: () => setStatus("ERRORED"),
      onTeardown,
      onHandled,
      connectMetadata
    });
    setInstrumenter(newInstrumenter);
    return () => newInstrumenter.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);
  return {
    instrumenter,
    status,
    testerUrl: instrumenter.testerUrl
  };
};

const Context = createContext<Instrumenter>(new Instrumenter(null));
const instrumentationBaseUrl =
  process.env.INSTRUMENTATION_BASE_URL ??
  window.process?.env?.INSTRUMENTATION_BASE_URL ??
  "";

export const useErrorReporter = () => useContext(Context).throwError;

export const useInstrument = (
  command: string,
  target: string | null | undefined,
  handler: Handler,
  deps: any[] = []
) => {
  const { addHandler, removeHandler } = useContext(Context);
  useEffect(() => {
    addHandler(command, target, handler);
    return () => removeHandler(command, target, handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addHandler, removeHandler, command, target, ...deps]);
};

export const InstrumentationError = ({ error }: { error: Error }) => {
  const reportError = useErrorReporter();
  useEffect(() => {
    reportError(error);
  }, [error, reportError]);
  return null;
};

export const Instrument = ({
  command,
  target,
  handler
}: {
  command: string;
  target?: string | undefined;
  handler: Handler;
}) => {
  useInstrument(command, target, handler);
  return null;
};
export const InstrumentationProvider = Context.Provider;
export const isInstrumented =
  instrumentationBaseUrl !== null &&
  instrumentationBaseUrl !== undefined &&
  instrumentationBaseUrl !== "";
