import React from "react";
import { View } from "react-native";
import { useStyle } from "../style";
import type { OnlyChildren } from "../utils/types";

type Props = OnlyChildren & {
  dim: boolean | number;
};

export default function Dimmable({ dim, children }: Props) {
  const viewStyle = useStyle(
    { opacity: dim === true ? 0.5 : dim === false ? 1 : dim },
    [dim]
  );
  return <View style={viewStyle}>{children}</View>;
}
