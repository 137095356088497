import React from "react";

import { Stack } from "../atoms";
import { Column, Text } from "../quarks";
import { useStyles } from "../style";

type Props =
  | { placeholder: true }
  | { placeholder?: false; header: string; content: string; footer?: string };

const InfoSquare = (props: Props) => {
  const styles = useStyles(({ getColor }) => ({
    container: {
      borderRadius: 4,
      backgroundColor: getColor("input", "fill"),
      height: 84,
      width: 84
    },
    placeholder: {
      width: 36,
      height: 84
    }
  }));

  if (props.placeholder) return <Column style={styles.placeholder} />;

  const { content, header, footer } = props;
  return (
    <Column style={styles.container}>
      <Stack alignItems="center" justifyContent="center" fill size="compact">
        <Text color="warning" variant="note">
          {header}
        </Text>
        <Text
          weight="bold"
          color="warning"
          variant={
            content?.length <= 6
              ? "title"
              : content?.length <= 8
                ? "header"
                : "body"
          }
        >
          {content}
        </Text>
        <Text color="warning" variant="note">
          {footer}
        </Text>
      </Stack>
    </Column>
  );
};

export default InfoSquare;
