import React, { type ReactNode } from "react";
import { Row, getSpaceUnits } from "../quarks";
import { type UseTransformFlexProps, useStyles } from "../style";
import { useGridGap } from "./GridContainer";

interface Props extends Omit<UseTransformFlexProps, "style"> {
  children?: ReactNode;
}

export default function GridRow({ children, wrap = "wrap", ...rest }: Props) {
  const gap = useGridGap();
  const styles = useStyles(() => ({
    row: { margin: -getSpaceUnits(gap) / 2 }
  }));

  return (
    <Row style={styles.row} wrap={wrap} {...rest}>
      {children}
    </Row>
  );
}
