import React, { type ReactNode } from "react";
import { View } from "react-native";

import { createScreenComponent } from "./ScreenWrapper";

interface Props {
  testID: string;
  children: ReactNode;
}

const Screen = createScreenComponent<Props>(
  ({ style, contentContainerStyle, ...props }) => (
    <View {...props} style={[style, contentContainerStyle]} />
  )
);

export default Screen;
