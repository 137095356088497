export type FontStyleName = "normal" | "italic";
export type FontWeightName = keyof typeof fontWeights;

export const fontWeights = {
  thin: "100",
  extralight: "200",
  light: "300",
  normal: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
  heavy: "800",
  black: "900"
} as const;

export const findWeightKeyByValue = (
  value:
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
    | "bold"
    | "normal"
): FontWeightName => {
  if (value === "bold" || value === "normal") return value;
  const key = Object.keys(fontWeights).find(
    (k) => fontWeights[k as FontWeightName] === value
  );
  if (!key) throw new Error(`invalid key ${value}`);
  return key as FontWeightName;
};

export type FontRegistry = Record<
  string,
  Record<
    FontWeightName | "*",
    Record<
      FontStyleName | "*",
      { posixName: string; loadFile: () => string; format?: string }
    >
  >
>;
