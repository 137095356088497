import React, { type ReactNode } from "react";
import { Text, View } from "react-native";

import { type TextStyleProp, theme, useStyles } from "../../style";
import { KatanaText } from "../../text";

interface Props {
  style?: TextStyleProp;
  label: string;
  tip?: boolean;
  tintColor?: string;
  error?: boolean;
  inlineAccessory?: ReactNode;
  inlineAccessoryPosition?: "left" | "right";
  focused?: boolean;
  testID?: string;
}

export default function BaseInputLabel({
  style,
  tip,
  label,
  error,
  tintColor = theme.color.blue,
  inlineAccessory,
  inlineAccessoryPosition = "right",
  focused,
  testID
}: Props) {
  const { styles } = useStyles(
    ({ color, font, units }) => ({
      container: {
        flexDirection: "row",
        marginHorizontal: units(2),
        marginBottom: units(1)
      },
      label: {
        ...font.body("bold"),
        color: color.blue,
        fontSize: font.size.bodyCopy,
        lineHeight: font.size.bodyCopy * font.lineHeight.body
      },
      tip: {
        ...font.body("regular")
      },
      withFocus: { color: color.blue },
      withError: { color: color.red }
    }),
    [tintColor]
  );

  return (
    <View style={styles.container}>
      {inlineAccessoryPosition === "left" && inlineAccessory}
      <KatanaText
        style={[
          styles.label,
          style,
          focused && styles.withFocus,
          error && styles.withError
        ]}
        testID={testID}
      >
        {label} {tip && <Text style={styles.tip}>*</Text>}
      </KatanaText>
      {inlineAccessoryPosition === "right" && inlineAccessory}
    </View>
  );
}
