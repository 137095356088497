import { omit } from "lodash";
import { sha256 as Sha256 } from "sha.js";
import { createRawMiddleware } from "../middleware";

interface SessionPin {
  signature: string;
  headers: string[];
}

function downcaseKeys(obj: Record<string, string>) {
  return Object.keys(obj).reduce<Record<string, string>>(
    (acc, key) => ({ ...acc, [key.toLowerCase()]: String(obj[key]) }),
    {}
  );
}

function verifySignature(
  headers: Record<string, string>,
  sessionPin: SessionPin
) {
  const [requestSignature, nonce = ""] = sessionPin.signature.split(".");
  const expectedSignature = sessionPin.headers
    .reduce((acc, header) => {
      const value = headers[header.toLowerCase()];
      if (!value) return acc;
      const part = `${header}:${value}`;
      return acc.update(part);
    }, new Sha256())
    .update(nonce)
    .digest("base64");
  return requestSignature === expectedSignature;
}

export default function sessionPinningMiddleware() {
  let sessionPin: SessionPin | null = null;

  return createRawMiddleware((next) => async (req) => {
    const headers = downcaseKeys(req.fetchOpts.headers);
    const requestSessionPin = async () => {
      sessionPin = null;
      req.fetchOpts.headers = downcaseKeys({
        ...headers,
        "session-pin-signature": "request"
      });
      const response = await next(req);
      const sessionPinSignature = response.headers.get("session-pin-signature");
      const sessionPinVerifyHeaders = response.headers.get(
        "session-pin-verify-headers"
      );
      if (sessionPinSignature && sessionPinVerifyHeaders) {
        const headers = sessionPinVerifyHeaders.split(",");
        const signature = sessionPinSignature;
        sessionPin = { signature, headers };
      }
      return response;
    };

    if (!sessionPin || !verifySignature(headers, sessionPin)) {
      return await requestSessionPin();
    }

    const nextReq = req.clone();

    nextReq.fetchOpts.headers = {
      ...omit(
        downcaseKeys(req.fetchOpts.headers),
        sessionPin.headers.map((name) => name.toLowerCase())
      ),
      "session-pin-signature": sessionPin.signature
    };
    const response = await next(nextReq);
    if (response.status === 428) return await requestSessionPin();
    return response;
  });
}
