import times from "lodash/times";
import React from "react";
import { Platform } from "react-native";
import { Row } from "../quarks";
import Icon from "../quarks/Icon";
import Text from "../quarks/Text";
import { useStyles } from "../style/styles";

interface Props {
  rating: number;
  numberOfRatings?: number;
  disabled?: boolean;
  variant?: "aggregate" | "singular";
  showRatings?: boolean;
}

export default function StarRating({
  rating,
  numberOfRatings,
  disabled,
  variant = "aggregate",
  showRatings = false
}: Props) {
  const styles = useStyles(({ getFontSize }) => ({
    label: { ...getFontSize(3, 1), top: Platform.OS === "web" ? 1.5 : 3 } // fix alignment
  }));

  if (numberOfRatings === 0) {
    return (
      <Text style={styles.label} color="neutral">
        <Text weight="bold">New</Text> (No Reviews)
      </Text>
    );
  }

  return (
    <Row alignItems="center" gap="slim">
      {showRatings && (
        <Text
          color={disabled ? "neutral" : "warning"}
          style={styles.label}
          weight="bold"
        >
          {rating.toFixed(1)}
        </Text>
      )}
      <Row alignItems="center">
        {times(5, (i) => (
          <Icon
            key={i}
            size={variant === "aggregate" ? "tiny" : "medium"}
            name={rating > i && rating < i + 1 ? "star-half-alt" : "star"}
            variant={rating > i ? "solid" : "regular"}
            color={disabled ? "neutral" : "warning"}
          />
        ))}
      </Row>
      {!!numberOfRatings && (
        <Text
          style={styles.label}
          color={disabled || showRatings ? "neutral" : "primary"}
        >
          ({`${numberOfRatings}`})
        </Text>
      )}
    </Row>
  );
}
