import { DateTime } from "luxon";
import React, { type ReactNode } from "react";
import { Button } from "../../atoms";
import { FilterDrawer, SearchInput } from "../../molecules";
import { Column, Row } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import Picker from "../Picker";
import DateTraverser from "./DateTraverser";
import type { PeriodType } from "./types";

interface Props {
  date: DateTime;
  onDateChange: (date: DateTime) => void;
  period: PeriodType;
  onPeriodChange: (period: PeriodType) => void;
  customControls?: ReactNode;
  filters?: ReactNode;
  filterCount: number;
  onResetFilters: () => void;
  filterSubmitLabel: string;
  onFilterSubmit: (filters: Record<string, unknown>) => void;
  searchPlaceholder: string;
  searchTerm: string;
  onSearch: (term: string) => void;
  appliedFilters?: Record<string, unknown>;
}

const periodOptions = [
  { label: "Month", value: "month" },
  { label: "Week", value: "week" },
  { label: "Day", value: "day" }
];

export default function CalendarHeader({
  date,
  onDateChange,
  period,
  onPeriodChange,
  customControls,
  filters,
  filterCount,
  onResetFilters,
  filterSubmitLabel,
  onFilterSubmit,
  searchPlaceholder,
  searchTerm,
  onSearch,
  appliedFilters
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);
  const isMd = useMatchesViewport((media) => media.size.small.down);

  const styles = useStyles(
    ({ getUnits }) => ({
      container: {
        height: getUnits(13),
        paddingLeft: getUnits(4)
      },
      controlsContainer: {
        paddingRight: getUnits(35)
      },
      searchContainer: {
        paddingLeft: getUnits(4),
        paddingRight: isSm ? getUnits(4) : getUnits(2)
      },
      pickerContainer: {
        marginHorizontal: isSm ? getUnits(-1.5) : undefined
      }
    }),
    []
  );

  const renderTodayButton = () => (
    <Button
      size="small"
      outline
      label="Today"
      testID="calendar-today"
      onPress={() => onDateChange(DateTime.now())}
      alignSelf="center"
    />
  );

  const renderPeriodPicker = () => (
    <Picker
      testID="calendar-period-picker"
      value={period}
      onChange={(period) => onPeriodChange?.(period as PeriodType)}
      eventTargetName="Calendar Period Picker"
      options={periodOptions}
      style={styles.pickerContainer}
      customControl={({ isOpen, label, onPress }) => (
        <Button
          size="small"
          outline
          label={label ?? "-"}
          testID="calendar-period-picker"
          icon={isOpen ? "chevron-up" : "chevron-down"}
          iconPlacement="right"
          onPress={onPress}
          minWidth={80}
        />
      )}
    />
  );

  const renderFilter = () => (
    <FilterDrawer
      filterCount={filterCount}
      onResetFilters={onResetFilters}
      submitLabel={filterSubmitLabel}
      onSubmit={onFilterSubmit}
      appliedFilters={appliedFilters}
    >
      {filters}
    </FilterDrawer>
  );

  const renderSearch = () => {
    if (period === "month") {
      return (
        <Row fill style={styles.searchContainer}>
          <SearchInput
            size="small"
            testID="calendar-header-search-input"
            placeholder={searchPlaceholder}
            onChangeText={onSearch}
            value={searchTerm}
            fill
          />
        </Row>
      );
    }
    return null;
  };

  const renderDesktop = () => (
    <Row
      alignItems="center"
      justifyContent="space-between"
      style={styles.container}
    >
      <Column justifyContent="center">
        <DateTraverser
          date={date}
          period={period}
          onDateChange={onDateChange}
        />
      </Column>
      <Row
        gap="compact"
        alignItems="center"
        style={styles.controlsContainer}
        justifyContent="flex-end"
        fill
      >
        {renderSearch()}
        {!isMd && customControls}
        {renderTodayButton()}
        {renderPeriodPicker()}
      </Row>
      {renderFilter()}
    </Row>
  );

  const renderMobile = () => (
    <Column gap="standard">
      <Row justifyContent="space-between">
        <DateTraverser
          date={date}
          period={period}
          onDateChange={onDateChange}
        />
        {renderTodayButton()}
      </Row>
      <Row justifyContent="space-between">
        {renderPeriodPicker()}
        {renderSearch()}
        {renderFilter()}
      </Row>
    </Column>
  );

  return isSm ? renderMobile() : renderDesktop();
}
