import backgroundLocation from "./backgroundLocation";
import calendar from "./calendar";
import camera from "./camera";
import location from "./location";
import notification from "./notification";
import photoGallery from "./photoGallery";
import preciseLocation from "./preciseLocation";

export const permissions = {
  location,
  backgroundLocation,
  preciseLocation,
  calendar,
  notification,
  photoGallery,
  camera
};

export type Permissions = typeof permissions;
