import React, { type ComponentProps } from "react";
import { ActivityIndicator } from "react-native";

import ContentArea from "./ContentArea";

type Props = Omit<ComponentProps<typeof ContentArea>, "children">;

export default function LoadingView(props: Props) {
  return (
    <ContentArea {...props} alignItems="center" justifyContent="center">
      <ActivityIndicator />
    </ContentArea>
  );
}
