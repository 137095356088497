import { Parser } from "json2csv";
import { Platform } from "react-native";

export interface IField {
  label: string;
  value: string | ((arg0: any) => string);
}

export class CSVExporter<Type> {
  fields: IField[];
  constructor(fields: IField[]) {
    this.fields = fields;
  }

  encodeCSV(data: Type[]) {
    try {
      const csvParser = new Parser({ fields: this.fields });
      const csv = csvParser.parse(data);
      return csv;
    } catch (err) {
      console.error("🧪 error csv parsing", err);
    }
  }

  toUrl(data: Type[]) {
    const csvData = this.encodeCSV(data);
    const blob = new Blob([csvData ?? ""]);
    return URL.createObjectURL(blob);
  }
}

export function downloadCsv<T>(
  filename: string,
  data: T[],
  exporter: CSVExporter<T>
) {
  if (Platform.OS !== "web")
    throw new Error("downloadCsv is only available on web");

  const str = exporter.toUrl(data);
  const a = window.document.createElement("a");

  a.style.display = "none";
  a.href = str;
  a.target = "_blank";
  a.download = filename ?? "document.csv";

  document.body.appendChild(a);
  a.click();

  // Cleanup
  document.body.removeChild(a);
}
