import React, { type ReactNode } from "react";
import Constraint, { IsConstrainedProvider } from "../atoms/Constraint";
import Column from "../quarks/Column";
import {
  type ViewStyle,
  responsive,
  useStyle,
  useTheme,
  useViewport
} from "../style";
import { ColorPlacementProvider } from "../style/colorPlacement";

interface Props {
  children?: ReactNode;
  testID?: string;
}

export default function StandaloneScreenFooter({ testID, children }: Props) {
  const { media } = useTheme();
  const { width } = useViewport();
  const { minWidth } = media.size.medium;
  const viewStyle = useStyle<ViewStyle>(({ colors, media, getUnits }) => [
    { backgroundColor: colors.background.fill },
    responsive(media.size.medium.up, {
      paddingBottom: getUnits(13),
      paddingHorizontal: getUnits(15)
    }),
    responsive(media.size.small.down, {
      paddingBottom: getUnits(8)
    })
  ]);

  return (
    <Column style={viewStyle} testID={testID}>
      <Constraint size="xlarge" fill>
        <IsConstrainedProvider value={!!minWidth && width > minWidth}>
          <ColorPlacementProvider color="background">
            {children}
          </ColorPlacementProvider>
        </IsConstrainedProvider>
      </Constraint>
    </Column>
  );
}
