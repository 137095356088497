import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  type ReactNode
} from "react";
import { Keyboard, Platform, Pressable, View } from "react-native";
import { StyledIcon } from "../../icon";
import { useScrollTo } from "../../scrollable/styled-scroll-view";
import { type TextStyleProp, type ViewStyleProp, useStyles } from "../../style";
import StyledInputAccessory, { accessoryID } from "../styled-input-accessory";
import BaseInputLabel from "./base-input-label";
import BaseTextInput from "./base-text-input";
import type {
  BaseTextInputHandlerType,
  Props as BaseTextInputProps
} from "./base-text-input";
import InputContainer from "./input-container";
import InputSubtext from "./input-subtext";
import MaskedTextInput from "./masked-text-input";
import type { ValidMask } from "./types";
import useInputFocus from "./use-input-focus";

interface Props extends BaseTextInputProps {
  fill?: number | true;
  style?: TextStyleProp;
  containerStyle?: ViewStyleProp;
  inputContainerStyle?: ViewStyleProp;
  inputContainerFocusedStyle?: ViewStyleProp;
  labelStyle?: TextStyleProp;
  subtextStyle?: TextStyleProp;
  label?: string;
  tip?: boolean;
  error?: string | null | boolean;
  errors?: Error[] | null;
  labelAccessory?: ReactNode | null;
  labelAccessoryPosition?: "left" | "right";
  subtext?: string;
  focused?: boolean;
  inputStyle?: TextStyleProp; // @deprecated
  customPressFunction?: () => void;
  renderRightAccessory?: () => ReactNode;
  renderLeftAccessory?: () => ReactNode;
  normalize?: (arg0: string) => string;
  format?: (arg0: string) => string;
  disabled?: boolean;
  showCounter?: boolean;
  testID?: string;
  onDone?: () => unknown;
  editable?: boolean;
  clearable?: boolean;
  mask?: ValidMask;
  autoScrollTo?: boolean;
  showMinLength?: boolean;
  minLength?: number;
  width?: number;
  eventTargetName: string | null;
}

const StyledTextInput = ({
  fill,
  containerStyle,
  inputContainerStyle,
  inputContainerFocusedStyle,
  labelStyle,
  subtextStyle,
  label,
  tip,
  inputAccessoryViewID: _inputAccessoryViewID,
  onChangeText,
  error,
  errors,
  subtext,
  labelAccessory,
  labelAccessoryPosition,
  renderRightAccessory,
  renderLeftAccessory,
  focused,
  customPressFunction,
  disabled,
  showCounter,
  onDone,
  testID,
  editable = true,
  inputStyle,
  style,
  clearable,
  autoScrollTo = true,
  showMinLength,
  eventTargetName,
  minLength,
  width,
  ...inputProps
}: Props) => {
  const inputErrors =
    (typeof error === "string" && error !== ""
      ? [new Error(error), ...(errors ?? [])]
      : errors) ?? [];
  const hasError = error === true || inputErrors.length > 0;
  const rightAccessory = renderRightAccessory?.();
  const leftAccessory = renderLeftAccessory?.();

  const { styles, theme } = useStyles(({ font, color, units }) => ({
    container: {
      flex: fill === true ? 1 : fill
    },
    inputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: width ? units(width) : undefined,
      marginBottom: units(1)
    },
    inputContainerDisabled: { opacity: 0.5 },
    accessoryWrapper: { paddingHorizontal: 4 },
    input: {
      ...font.body("regular"),
      flex: 1,
      height: units(11),
      padding: units(2),
      width: "100%",
      paddingVertical: 10,
      marginHorizontal: 8,
      color: color.black,
      fontSize: font.size.medium,
      ...Platform.select({
        web: { outlineStyle: "none" }
      })
    },
    inputWithError: {
      marginRight: 0
    },
    inputMultiline: {
      ...font.inputMultiline(font.size.medium),
      marginTop: 5,
      paddingBottom: 5,
      verticalAlign: "top"
    }
  }));

  const [isFocused, { onCancel, onBlur, onFocus }] = useInputFocus({
    targetName: eventTargetName ?? label,
    focused,
    disabled,
    value: inputProps.value ?? "",
    onFocus: inputProps.onFocus,
    onBlur: inputProps.onBlur,
    onChangeText
  });

  const scrollTo = useScrollTo();
  const scrollToRef = useRef<View>(null);
  const inputRef = useRef<BaseTextInputHandlerType>();

  const handleDone = useCallback(() => {
    Keyboard.dismiss();
    onDone?.();
  }, [onDone]);

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
      if (scrollToRef.current) scrollTo?.(scrollToRef.current as any);
    }
  }, [focused, scrollTo]);

  const inputAccessoryViewID = useMemo(
    () => _inputAccessoryViewID ?? accessoryID(),
    [_inputAccessoryViewID]
  );

  const TextInputComponent = getTextInputComponent(inputProps);

  return (
    <Pressable
      onPress={customPressFunction}
      accessible={false}
      disabled={disabled ?? !customPressFunction}
      testID={typeof testID === "string" ? `${testID}-touchable` : undefined}
      style={[styles.container, containerStyle]}
    >
      <View ref={autoScrollTo ? scrollToRef : undefined}>
        {typeof label === "string" && (
          <BaseInputLabel
            style={labelStyle}
            focused={isFocused}
            error={hasError}
            label={label}
            inlineAccessory={labelAccessory}
            inlineAccessoryPosition={labelAccessoryPosition}
            tip={tip}
            testID={typeof testID === "string" ? `${testID}-label` : undefined}
          />
        )}
        <InputContainer
          style={[
            styles.inputContainer,
            inputContainerStyle,
            disabled && styles.inputContainerDisabled
          ]}
          focusedStyle={inputContainerFocusedStyle}
          error={hasError}
          focused={isFocused}
        >
          {leftAccessory && (
            <>
              <View style={styles.accessoryWrapper} />
              <View style={styles.accessoryWrapper}>{leftAccessory}</View>
            </>
          )}
          <TextInputComponent
            ref={inputRef}
            inputAccessoryViewID={inputAccessoryViewID}
            onChangeText={onChangeText}
            style={[
              styles.input,
              hasError && styles.inputWithError,
              inputProps.multiline && styles.inputMultiline,
              inputStyle,
              style
            ]}
            placeholderTextColor={theme.color.neutralDark}
            allowFontScaling={false}
            {...inputProps}
            testID={testID}
            editable={!disabled && editable}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          {!!rightAccessory && (
            <View style={styles.accessoryWrapper}>{rightAccessory}</View>
          )}
          {!!hasError && (
            <View pointerEvents="none" style={styles.accessoryWrapper}>
              <StyledIcon
                testID="error-icon"
                name="circle-exclamation"
                variant="solid"
                color={theme.color.orange}
                size={14}
              />
            </View>
          )}
          {!!hasError || rightAccessory ? (
            <View style={styles.accessoryWrapper} />
          ) : null}
          {clearable && !!inputProps.value && (
            <Pressable
              onPress={() => {
                onChangeText?.("");
                inputRef?.current?.focus();
              }}
            >
              <StyledIcon
                style={styles.accessoryWrapper}
                name="times-circle"
                variant="solid"
                color={theme.color.neutralDark}
                size={theme.font.size.large}
              />
            </Pressable>
          )}
        </InputContainer>
        {showCounter && typeof inputProps.maxLength === "number" ? (
          <InputSubtext
            label={`${inputProps.value?.length ?? 0}/${inputProps.maxLength}`}
          />
        ) : null}
        {showMinLength && minLength && (
          <InputSubtext label={`Minimum ${minLength} characters`} />
        )}
        <InputSubtext
          style={subtextStyle}
          error={hasError}
          label={
            inputErrors.length > 0
              ? inputErrors
                  .map((err) => err.message)
                  .filter(Boolean)
                  .join(", ")
              : subtext
          }
          testID={
            typeof testID === "string"
              ? `${testID}-${inputErrors.length > 0 ? "error" : "subtext"}`
              : "styled-input-error-message"
          }
        />
        <StyledInputAccessory
          onCancel={onCancel}
          onSubmit={handleDone}
          nativeID={inputAccessoryViewID}
        />
      </View>
    </Pressable>
  );
};

export default StyledTextInput;

const getTextInputComponent = (props: { mask?: string }) => {
  if (props.mask) return MaskedTextInput;
  return BaseTextInput;
};
