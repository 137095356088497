import React, {
  type ReactNode,
  useEffect,
  type SuspenseProps as ReactSuspenseProps,
  Suspense as ReactSuspense,
  useCallback,
  useState,
  type ComponentType,
  createContext,
  useContext
} from "react";
import RelayErrorBoundary from "./relay-error-boundary";

const QueryIsInFallbackContext = createContext({
  suspended: false,
  isBlocking: false
});

export const useRelayQueryIsInFallback = () =>
  useContext(QueryIsInFallbackContext);

export function FallbackWrapper({
  children,
  isBlocking = false,
  onWaiting = () => {}
}: {
  children: ReactNode;
  isBlocking?: boolean;
  onWaiting?: () => void;
}) {
  useEffect(() => {
    const interval = setInterval(() => {
      onWaiting();
    }, 250);
    return () => clearInterval(interval);
  }, [onWaiting]);

  return (
    <QueryIsInFallbackContext.Provider value={{ suspended: true, isBlocking }}>
      {children}
    </QueryIsInFallbackContext.Provider>
  );
}

export interface SuspenseProps extends ReactSuspenseProps {
  isBlocking?: boolean;
  onError?: (_: Error) => void;
  ErrorComponent?: ComponentType<{ error: Error }> | null | false;
}

export function Suspense({
  fallback = null,
  children,
  onError = () => {},
  ErrorComponent = null,
  isBlocking: currentIsBlocking = false
}: SuspenseProps) {
  const [, setWaitingRand] = useState(0);
  const handleWaiting = useCallback(() => {
    setWaitingRand(Math.random());
  }, []);
  const { isBlocking: parentIsBlocking } = useRelayQueryIsInFallback();
  const isBlocking = currentIsBlocking || parentIsBlocking;
  if (parentIsBlocking) {
    return (
      <FallbackWrapper
        onWaiting={handleWaiting}
        isBlocking={currentIsBlocking || parentIsBlocking}
      >
        {fallback}
      </FallbackWrapper>
    );
  }
  return (
    <RelayErrorBoundary onError={onError} ErrorComponent={ErrorComponent}>
      <ReactSuspense
        fallback={
          <FallbackWrapper onWaiting={handleWaiting} isBlocking={isBlocking}>
            {fallback}
          </FallbackWrapper>
        }
      >
        {children}
      </ReactSuspense>
    </RelayErrorBoundary>
  );
}
