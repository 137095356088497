import { useEventer } from "@gigsmart/dekigoto";
import { numeric } from "@gigsmart/isomorphic-shared/iso";
import _, { compact } from "lodash";
import React, {
  type ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";
import {
  Keyboard,
  type NativeSyntheticEvent,
  Platform,
  TextInput as RNTextInput,
  type StyleProp,
  type TextInputFocusEventData,
  type TextInputProps,
  type ViewStyle
} from "react-native";

import { faker as fakerJs } from "@faker-js/faker";
import { FakerData } from "@gigsmart/feature-flags";
import InputAccessory, { getAccessoryID } from "../atoms/InputAccessory";
import InputContainer from "../atoms/InputContainer";
import InputLabel from "../atoms/InputLabel";
import InputNote from "../atoms/InputNote";
import InputTag from "../atoms/InputTag";
import PillInputContainer from "../atoms/PillInputContainer";
import Column from "../quarks/Column";
import Icon, { type IconName } from "../quarks/Icon";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import { useStyles, useTheme } from "../style";
import { fontWeights } from "../style/theme/fontHelpers";

export type InputType = "displayName" | "number" | "url" | "email" | "numeric";

const InputTypeDict: Record<InputType, TextInputProps> = {
  number: { keyboardType: Platform.OS === "web" ? "default" : "number-pad" },
  numeric: { keyboardType: "numeric" },
  email: {
    keyboardType: "email-address",
    autoCapitalize: "none",
    textContentType: "emailAddress"
  },
  url: { keyboardType: "url" },
  displayName: { autoCapitalize: "words", autoCorrect: false }
};

export interface Props extends TextInputProps {
  containerStyle?: StyleProp<ViewStyle>;
  testID: string;
  fill?: number | boolean;
  label?: string | ReactNode;
  labelIcon?: IconName;
  errors?: string | string[] | Error | Error[] | null;
  min?: number | null;
  max?: number | null;
  showCounter?: "length" | "max" | boolean;
  note?: string;
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;
  horizontal?: boolean;
  inputFill?: number;
  type?: InputType;
  labelAccessory?: ReactNode;
  renderInputComponent?: (inputProps: TextInputProps) => JSX.Element;
  valueLength?: number;
  showErrorMessage?: boolean;
  variant?: "standard" | "pill";
}

const multilineFontSize = (minLines = 5, maxLines = 15, fontSize = 14) => {
  const minHeight = 28 + fontSize * minLines;
  const maxHeight = 28 + fontSize * maxLines;
  return { fontSize, minHeight, maxHeight };
};

const defaultRenderInputComponent = (props: TextInputProps) => (
  <RNTextInput allowFontScaling={false} {...props} />
);

export default function TextInput({
  fill,
  testID,
  label,
  labelIcon,
  errors,
  min,
  max,
  showCounter = typeof min === "number",
  note,
  leftAccessory,
  rightAccessory,
  type,
  labelAccessory,
  horizontal,
  inputFill = 1,
  variant = "standard",
  renderInputComponent = defaultRenderInputComponent,
  // input props
  value = "",
  valueLength = value?.length,
  multiline,
  onBlur,
  onFocus,
  pointerEvents,
  showErrorMessage = true,
  editable,
  containerStyle,
  onChangeText,
  ...inputProps
}: Props) {
  // TODO: scrollTo on focus (needs scroller impl)

  const trackBlurred = useEventer(
    "blurred",
    typeof label === "string" ? label : testID ?? "Unknown",
    "Input"
  );

  const theme = useTheme();
  const styles = useStyles(({ fonts, getUnits, getColor, getFontSize }) => ({
    input: {
      flex: 1,
      width: "100%",
      height: getUnits(11),
      paddingVertical: getUnits(2),
      color: getColor("surface", "placement"),
      ...Platform.select({
        web: { outlineStyle: "none" }
      }),
      ...getFontSize(),
      fontFamily: fonts.sansSerif,
      fontWeight: fontWeights.normal
    },
    inputMultiline: {
      ...multilineFontSize(inputProps.numberOfLines ?? 5),
      height: "auto",
      marginTop: getUnits(1),
      paddingBottom: getUnits(1),
      verticalAlign: "top"
    },
    inputMultilineRightAccessory: { paddingRight: getUnits(10) },
    inputMultilineCharCount: { paddingRight: getUnits(12) },

    rightAccessoryContainer: { minWidth: getUnits(2) },
    rightAccessoryMultiline: { position: "absolute", right: 0 },

    leftAccessoryContainer: { paddingLeft: getUnits(2) },
    leftAccessoryMultiline: { position: "absolute", right: 0 }
  }));

  const valueOnFocus = useRef(value);
  const [focused, setFocused] = useState(false);
  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      valueOnFocus.current = value;
      setFocused(true);
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocused(false);
      trackBlurred();
      onBlur?.(e);
    },
    [trackBlurred, onBlur]
  );

  const handleDone = useCallback(() => {
    Keyboard.dismiss();
  }, []);

  const handleCancel = useCallback(() => {
    Keyboard.dismiss();
    onChangeText?.(valueOnFocus.current ?? "");
  }, []);

  const inputAccessoryViewID = useMemo(getAccessoryID, []);

  if (!note) note = minCharCountMessage(value?.length, min);
  const charCount =
    showCounter && maxCharCountMessage(showCounter, valueLength, max);
  const errorMessage = Array.isArray(errors)
    ? _.uniq(compact(errors.map(formatError))).join(", ")
    : formatError(errors);

  const hasError = !!errorMessage;
  const rightNode = hasError ? (
    <Icon
      name="circle-exclamation"
      color="danger"
      size="small"
      variant="solid"
    />
  ) : (
    rightAccessory
  );
  const helperText = hasError && showErrorMessage ? errorMessage : note;
  const handleOnChangeText = useCallback(
    (text: string) => {
      if (!onChangeText) return;
      if (FakerData.isDisabled()) return onChangeText?.(text);
      const matches = text.match(
        /(faker|\?)(\.?[a-zA-Z0-9]+){1,2}(\(.*?\)|\?)/g
      );
      console.log({ matches });
      if (!matches) return onChangeText?.(text);
      try {
        const nextValue = matches.reduce((s, f) => {
          const fakerFn = new Function(
            "faker",
            `return ${f.replace(/^\?/, "faker.").replace(/\?$/, "()")}`
          ) as (faker: typeof fakerHelper) => string;
          return s.replace(f, fakerFn(fakerHelper));
        }, text);
        onChangeText(nextValue);
      } catch (e) {
        onChangeText?.(text);
      }
    },
    [onChangeText]
  );
  const inputNode = renderInputComponent({
    ...inputProps,
    ...(type && InputTypeDict[type]),
    onChangeText: handleOnChangeText,
    editable,
    inputAccessoryViewID,
    testID,
    value,
    multiline,
    style: [
      styles.input,
      multiline && styles.inputMultiline,
      multiline && !!rightNode && styles.inputMultilineRightAccessory,
      multiline && !!charCount && styles.inputMultilineCharCount
    ],
    placeholderTextColor: theme.getColor("neutral", "fill"),
    onBlur: handleBlur,
    onFocus: handleFocus
  });

  const renderContent = (content: JSX.Element) => {
    if (variant === "pill") {
      return (
        <PillInputContainer
          hasRightAccessory={!!rightNode}
          hasLeftAccessory={!!leftAccessory}
        >
          {content}
        </PillInputContainer>
      );
    }

    return (
      <InputContainer focused={focused} error={hasError} editable={editable}>
        {content}
      </InputContainer>
    );
  };

  const Wrapper = horizontal ? Row : Column;

  return (
    <>
      <Wrapper
        gap="slim"
        pointerEvents={pointerEvents}
        fill={fill}
        style={containerStyle}
      >
        {!!label && (
          <InputLabel
            testID={testID && `${testID}-label`}
            label={label}
            icon={labelIcon}
            error={hasError}
            horizontal={horizontal}
            accessory={labelAccessory}
          />
        )}
        <Column fill={horizontal ? inputFill : undefined}>
          {renderContent(
            <>
              {!!leftAccessory && (
                <Row
                  style={[
                    styles.leftAccessoryContainer,
                    multiline && styles.leftAccessoryMultiline
                  ]}
                  justifyContent="center"
                >
                  {leftAccessory}
                  <Spacer size="slim" horizontal />
                </Row>
              )}
              {inputNode}
              {!!charCount && (
                <InputTag
                  multiline={multiline}
                  testID="character-count"
                  error={hasError}
                  label={charCount}
                />
              )}
              {!!rightNode && (
                <Row
                  style={[
                    styles.rightAccessoryContainer,
                    multiline && styles.rightAccessoryMultiline
                  ]}
                  justifyContent="center"
                >
                  {rightNode}
                </Row>
              )}
            </>
          )}

          {!!helperText && (
            <InputNote
              error={hasError}
              testID="validator-error"
              note={helperText}
            />
          )}
        </Column>
      </Wrapper>
      <InputAccessory
        onSubmit={handleDone}
        onCancel={handleCancel}
        nativeID={inputAccessoryViewID}
      />
    </>
  );
}

function formatError(error?: Error | string | null) {
  if (error) return typeof error === "string" ? error : error.message;
}

function maxCharCountMessage(
  showCounter: Props["showCounter"],
  len = 0,
  max?: number | null
) {
  if (len <= 0 || !showCounter) return;
  return typeof max === "number" && showCounter === "max"
    ? `${len}/${numeric.humanize(max)}`
    : `${len}`;
}

function minCharCountMessage(_len?: number, min?: number | null) {
  if (typeof min === "number") return `Minimum ${min} characters`;
}

const fakerHelper = {
  person: fakerJs.person,
  company: fakerJs.company,
  internet: fakerJs.internet,
  location: fakerJs.location,
  commerce: fakerJs.commerce,
  email: () => fakerJs.internet.email({ provider: "example.com" }),
  desc: () => fakerJs.lorem.words(30),
  firstName: fakerJs.person.firstName,
  lastName: fakerJs.person.lastName,
  sentence: fakerJs.lorem.sentence,
  paragraph: fakerJs.lorem.paragraph,
  lines: fakerJs.lorem.lines,
  words: fakerJs.lorem.words
};
