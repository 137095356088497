import React, { type ReactNode } from "react";
import RNCollapsible from "react-native-collapsible";
import Button from "../atoms/Button";
import ContentRow from "../atoms/ContentRow";
import Stack from "../atoms/Stack";
import { Column, Icon, Pressable, Radio, Text } from "../quarks";

export type ExpandableRadioRowVariant = "standard" | "info" | "no-button";

export interface Props {
  children?: ReactNode;
  variant?: ExpandableRadioRowVariant;
  header?: ReactNode | string;
  selected?: boolean;
  expanded?: boolean;
  onSelect?: () => void;
  selectable?: boolean;
  rejected?: boolean;
  testID: string;
  eventTargetName: string | null;
  onLearnMorePress?: () => void;
  toggleExpanded?: () => void;
  renderChildrenCollapsed?: boolean;
}

export default function ExpandableRadioRow({
  variant = "standard",
  header,
  testID,
  eventTargetName,
  onSelect,
  selected,
  onLearnMorePress,
  expanded,
  toggleExpanded,
  selectable,
  rejected,
  renderChildrenCollapsed,
  children
}: Props) {
  return (
    <Column>
      <Pressable
        eventEntityType="Radio"
        eventTargetName={eventTargetName}
        testID={testID}
        onPress={onSelect}
        disabled={variant === "info" || !selectable}
      >
        <ContentRow>
          <Stack
            horizontal
            fill={1}
            alignItems="center"
            justifyContent="space-between"
          >
            {typeof header === "string" ? <Text>{header}</Text> : header}
            <Stack horizontal alignItems="center">
              {variant === "standard" ? (
                <Button
                  label={!expanded ? "Show More" : "Show Less"}
                  variant="clear"
                  icon={!expanded ? "chevron-down" : "chevron-up"}
                  onPress={toggleExpanded}
                  testID={`${testID}-expand-collapse-btn`}
                />
              ) : variant === "info" ? (
                <Button
                  label="Learn More"
                  icon="circle-question"
                  variant="clear"
                  onPress={onLearnMorePress}
                  testID={`${testID}-learn-more-btn`}
                />
              ) : null}
              {variant === "standard" || variant === "no-button" ? (
                selectable ? (
                  <Radio
                    selected={selected}
                    eventTargetName={eventTargetName}
                    onSelect={(value) => value && onSelect?.()}
                    testID={`${testID}-radio-btn`}
                  />
                ) : selected ? (
                  <Icon
                    name="check"
                    color="success"
                    variant="solid"
                    size="small"
                  />
                ) : rejected ? (
                  <Icon
                    name="times"
                    color="error"
                    variant="solid"
                    size="small"
                  />
                ) : (
                  <Text>N/A</Text>
                )
              ) : (
                <Text>N/A</Text>
              )}
            </Stack>
          </Stack>
        </ContentRow>
      </Pressable>
      <RNCollapsible
        renderChildrenCollapsed={renderChildrenCollapsed}
        collapsed={!expanded}
      >
        {children}
      </RNCollapsible>
    </Column>
  );
}
