import React from "react";
import type { ComponentProps, ReactNode } from "react";
import { View } from "react-native";
import ContentArea from "../atoms/ContentArea";
import IconButton from "../atoms/IconButton";
import TooltipButton from "../atoms/TooltipButton";
import { Column, Spacer } from "../quarks";
import Divider from "../quarks/Divider";
import Pressable from "../quarks/Pressable";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useStyles } from "../style";

interface Props {
  testID: string;
  title: string | ReactNode;
  note?: string | ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  expand?: boolean;
  onPress?: ComponentProps<typeof Pressable>["onPress"];
  onTooltipPress?: ComponentProps<typeof TooltipButton>["onPress"];
  onEditPress?: ComponentProps<typeof IconButton>["onPress"];
  disabled?: boolean;
  selectedChildren?: ReactNode;
  children?: ReactNode;
}

export default function InputRow({
  title,
  note,
  left,
  right,
  expand,
  onPress,
  onTooltipPress,
  onEditPress,
  testID,
  disabled,
  selectedChildren,
  children
}: Props) {
  const styles = useStyles(
    ({ getColor, getUnits }) => ({
      wrapper: {
        borderRadius: getUnits(1),
        borderWidth: 1,
        borderColor: getColor("divider", "fill")
      },
      container: {
        minHeight: 52,
        paddingLeft: getUnits(4)
      },
      buttonContainer: {
        width: 52,
        justifyContent: "center",
        alignItems: "center"
      },
      contentPadding: {
        paddingRight: right ? getUnits(4) : undefined
      },
      titleContainer: {
        paddingTop: expand ? getUnits(1) : undefined,
        paddingBottom: expand ? getUnits(1) : undefined
      },
      selectedChildrenContainer: {
        // padding: getUnits(4),
        paddingTop: getUnits(1), // we have to move the top of the container up for the rounding so the lines are connected
        borderLeftColor: getColor("divider", "fill"),
        borderRightColor: getColor("divider", "fill"),
        borderBottomColor: getColor("divider", "fill"),
        borderWidth: 1,
        borderTopWidth: 0,
        borderBottomLeftRadius: getUnits(1),
        borderBottomRightRadius: getUnits(1),
        top: getUnits(1) * -1
      }
    }),
    []
  );
  return (
    <>
      <Pressable
        eventEntityType="Input Row"
        eventTargetName={testID}
        onPress={onPress}
        testID={testID}
        disabled={disabled}
      >
        <Column style={styles.wrapper}>
          <Row style={styles.container} testID={testID}>
            {left}
            <Column
              style={styles.titleContainer}
              gap="small"
              justifyContent="center"
              fill
            >
              {typeof title === "string" ? (
                <Text
                  numberOfLines={expand ? undefined : note ? 1 : 2}
                  testID={`${testID}-title`}
                >
                  {title}
                </Text>
              ) : (
                title
              )}
              {!!note && (
                <Text
                  testID={`${testID}-note`}
                  variant="note"
                  color="neutral"
                  numberOfLines={expand ? undefined : 1}
                >
                  {note}
                </Text>
              )}
            </Column>
            <Spacer horizontal />
            {!!onTooltipPress && (
              <>
                <Divider dir="y" />
                <View style={styles.buttonContainer}>
                  <TooltipButton
                    testID={`${testID}-tooltip`}
                    onPress={onTooltipPress}
                  />
                </View>
              </>
            )}
            {!!onEditPress && (
              <>
                <Divider dir="y" />
                <View style={styles.buttonContainer}>
                  <IconButton
                    size="small"
                    variant="solid"
                    testID={`${testID}-edit-btn`}
                    onPress={onEditPress}
                    name="pen-to-square"
                    color="primary"
                  />
                </View>
              </>
            )}
            {!!right && <Divider dir="y" />}
            {right}
          </Row>
          {!!children && <Divider />}
          {children}
        </Column>
      </Pressable>
      {selectedChildren && (
        <Row
          style={styles.selectedChildrenContainer}
          testID={`${testID}-selectedChildren`}
          alignItems="stretch"
        >
          <ContentArea fill>{selectedChildren}</ContentArea>
        </Row>
      )}
    </>
  );
}
