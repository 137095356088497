import React from "react";
import { Card, IconSquare, ListRow, Surface } from "../atoms";
import { Icon, type IconName, Text } from "../quarks";
import type { Color } from "../style/theme/colors";

interface Props {
  label: string | JSX.Element;
  color?: Color;
  labelColor?: Color;
  noteColor?: Color;
  iconSquare?: IconName;
  note?: string;
  icon?: IconName;
  iconColor?: Color;
  onPress?: () => void;
  right?: JSX.Element;
  testID: string;
  noteNumberOfLines?: number;
}

export default function HighlightedCard({
  label,
  note,
  color,
  labelColor = "primary",
  iconSquare,
  noteColor,
  icon,
  iconColor = color,
  right,
  testID,
  onPress,
  noteNumberOfLines
}: Props) {
  const actionNode = onPress ? (
    <Icon
      name="chevron-right"
      size="medium"
      variant="solid"
      color={labelColor}
    />
  ) : (
    right
  );

  return (
    <Card onPress={onPress} testID={testID} eventTargetName="Highlighted Card">
      <Surface fade variant="flat" color={color}>
        <ListRow
          label={
            <Text weight="bold" color={labelColor}>
              {label}
            </Text>
          }
          left={
            icon ? (
              <Icon name={icon} color={iconColor} variant="solid" />
            ) : iconSquare ? (
              <IconSquare
                size="medium"
                icon={iconSquare}
                color={iconColor ?? "primary"}
                iconVariant="solid"
                variant="well"
              />
            ) : undefined
          }
          right={actionNode}
        >
          {note && (
            <Text
              variant="note"
              color={noteColor}
              numberOfLines={noteNumberOfLines}
            >
              {note}
            </Text>
          )}
        </ListRow>
      </Surface>
    </Card>
  );
}
