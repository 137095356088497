import { getOriginalDisplayName } from "@gigsmart/hoc-utils";
import { noop } from "lodash";
import mixpanel from "mixpanel-browser";
import {
  addAliasHook,
  addIdentifyHook,
  addResetHook,
  addScreenHook,
  addTrackHook,
  collectHooks
} from "../../hooks";
import { WRITE_KEY } from "./config";

export default async () => {
  if (process.env.IS_TESTING === "true" || !WRITE_KEY) return noop;
  mixpanel.init(WRITE_KEY);

  return collectHooks([
    addTrackHook(async (event, properties) => {
      mixpanel.track(event, properties);
    }),

    addScreenHook(async (name, properties, Component) => {
      const componentDisplayName =
        Component && getOriginalDisplayName(Component);
      if (typeof componentDisplayName !== "undefined") {
        properties.name = componentDisplayName;
      }
      mixpanel.track(name, properties);
    }),

    addAliasHook(async (prevUserId, userId) => {
      mixpanel.alias(userId, prevUserId ?? undefined);
    }),

    addIdentifyHook(
      async (
        userId,
        {
          email,
          avatar,
          firstName,
          lastName,
          phone,
          globalId,
          insertedAt,
          organizationId,
          companyName,
          buildNumber
        }
      ) => {
        if (!userId) return;
        mixpanel.identify(userId);
        mixpanel.people.set({
          $avatar: avatar,
          $email: email,
          $phone: phone,
          $distinct_id: userId,
          $first_name: firstName,
          $last_name: lastName,
          $created: insertedAt,
          global_id: globalId,
          company_name: companyName,
          org_id: organizationId,
          build_number: buildNumber
        });
      }
    ),

    addResetHook(async () => mixpanel.reset())
  ]);
};
