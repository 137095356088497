import React, { type ComponentType, type ComponentProps } from "react";
import { View } from "react-native";
import Stack from "../atoms/Stack";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useStyle } from "../style";

export interface Props {
  ImageComponent: ComponentType<{}>;
  header: string;
  testID: string;
  note: ComponentProps<typeof Text>["children"];
}

export default function SvgInfoRow({
  ImageComponent,
  header,
  note,
  testID
}: Props) {
  const style = useStyle(({ getUnits }) => ({
    maxHeight: 70,
    marginRight: getUnits(4)
  }));
  return (
    <Row alignItems="center" testID={testID}>
      <View style={style}>
        <ImageComponent />
      </View>
      <Stack size="slim" fill>
        <Text weight="bold" color="primary">
          {header}
        </Text>
        <Text variant="note" color="neutral">
          {note}
        </Text>
      </Stack>
    </Row>
  );
}
