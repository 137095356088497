import toLower from "lodash/toLower";
import { useContext, useMemo } from "react";
import Context from "./context";
import { track } from "./hooks";
import type { JsonMap } from "./hooks";

type EventerFn = (properties?: JsonMap, targetName?: string) => void;

export type Eventers<T extends string> = Record<T, EventerFn>;

export function useEventers<T extends string>(
  target: string,
  entityType: string,
  actions: T[],
  contextNameOverride?: string
): Eventers<string> {
  const { name: contextName, disabled } = useContext(Context);
  return useMemo<Eventers<string>>(
    () =>
      actions.reduce<Record<string, EventerFn>>((acc, action) => {
        return {
          ...acc,
          [toLower(action)]: (
            properties?: JsonMap,
            targetName = target ?? "Unknown"
          ) => {
            if (disabled) return () => {};
            return track(
              {
                contextName: contextNameOverride ?? contextName,
                action,
                targetName,
                entityType
              },
              properties
            );
          }
        };
      }, {}),
    [actions, contextName, contextNameOverride, disabled, entityType, target]
  );
}
