import React from "react";
import Text from "../quarks/Text";
import { useResponsiveValue } from "../style";
import { humanizeDateTime } from "./date-helpers";
import type { DateTimeConvertable, TimeSizes } from "./date-helpers";

interface Props
  extends Omit<
    React.ComponentProps<typeof Text>,
    "onPress" | "eventTargetName"
  > {
  startsAt: DateTimeConvertable | null;
  actualStartsAt?: DateTimeConvertable | null;
  endsAt?: DateTimeConvertable | null;
  timezone?: string | null;
  size?: TimeSizes;
  showDayOfWeek?: boolean;
  separator?: string;
}

export default function DateTime({
  startsAt,
  endsAt,
  size: sizeOverride,
  timezone,
  actualStartsAt,
  showDayOfWeek,
  separator,
  ...textProps
}: Props) {
  const size = useResponsiveValue({ mini: "sm", medium: "md", large: "lg" });
  return (
    <Text {...textProps}>
      {humanizeDateTime({
        startsAt,
        endsAt,
        timezone,
        actualStartsAt,
        showDayOfWeek,
        size: sizeOverride ?? size ?? "lg",
        separator
      })}
    </Text>
  );
}
