import { getDateTime } from "@gigsmart/isomorphic-shared/iso/time";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useMemo, useState, useEffect } from "react";
import type { ComponentProps } from "react";
import InputLabel from "../atoms/InputLabel";
import InputNote from "../atoms/InputNote";
import MaxWidthContainer from "../atoms/MaxWidthContainer";
import { TextInput } from "../molecules";
import { Column, Row } from "../quarks";
import Picker from "./Picker";

const OPTIONS_AM_PM = ["AM", "PM"];

interface Props
  extends Omit<
    ComponentProps<typeof TextInput>,
    "testID" | "value" | "label" | "onChange" | "errors" | "onBlur"
  > {
  testID: string;
  label?: string;
  reference?: string | DateTime | null | undefined;
  value?: DateTime | null | undefined;
  timezone?: string | null | undefined;
  onChange?: (
    date: DateTime | null | undefined,
    isAmpmSet: boolean
  ) => void | Promise<void>;
  errors?: string | string[] | Error | Error[] | null;
  onBlur?: () => void;
}

export default function TimeInput({
  fill,
  onChange,
  value,
  errors,
  label,
  onBlur,
  placeholder,
  testID,
  reference = DateTime.now(),
  timezone,
  ...inputProps
}: Props) {
  const [time, setTime] = useState<string>();
  const [meridiem, setMeridiem] = useState<string | null>();

  useEffect(() => {
    if (value && !time) {
      setTime(value.toFormat("hh:mm"));
      setMeridiem(value.toFormat("a"));
    }
  }, [value]);

  useEffect(() => {
    if (time && meridiem) updateValue(time, meridiem);
  }, [reference]);

  const handleTimeChange = (v: string) => {
    const newTime = parseHm(v);
    if (newTime !== time) {
      setTime(newTime);
      updateValue(newTime, meridiem);
    }
  };

  const handleTimeBlur = () => {
    onBlur?.();
    if (time && time?.length < 5) {
      const newTime = formatHm(time);
      const newMeridiem =
        meridiem ?? getDateTime(value ?? reference, timezone).toFormat("a");
      if (!meridiem) {
        setMeridiem(newMeridiem);
        updateValue(newTime, newMeridiem);
      }
      if (!value && (time.length === 1 || time.length === 2))
        updateValue(newTime, newMeridiem);
      setTime(newTime);
    }
  };
  const handleMeridiemChange = (v: string | null) => {
    if (v !== meridiem) {
      setMeridiem(v);
      updateValue(time, v);
    }
  };

  const computedMeridiem = useMemo(() => {
    return meridiem ?? getDateTime(value ?? reference, timezone).toFormat("a");
  }, [value, meridiem, reference, timezone]);

  const updateValue = (newTime?: string, newMeridiem?: string | null) => {
    if (!onChange) return;
    const [hours = 0, minutes = 0] = newTime
      ? newTime.split(":").map((num) => (num ? Number.parseInt(num, 10) : 0))
      : [];

    const computedAmpm = newMeridiem ?? computedMeridiem;
    let computedHours = hours + (computedAmpm === "PM" ? 12 : 0);
    if (hours === 12) computedHours = computedAmpm === "AM" ? 0 : 12;

    const isValid =
      !!newTime &&
      !!newTime.match(/\d?\d:\d\d$/) &&
      hours > 0 &&
      hours <= 12 &&
      minutes < 60;
    const currVal = getDateTime(reference, timezone);
    const newDate = isValid
      ? currVal.set({
          hour: computedHours,
          minute: minutes,
          second: 0,
          millisecond: 0
        })
      : null;
    if (newDate !== value) void onChange(newDate, !!newMeridiem);
  };
  const error = time && time.length > 3 && !value ? "Invalid time" : errors;
  return (
    <Column gap="slim" fill={fill}>
      {!!label && <InputLabel label={label} />}
      <Row gap="compact">
        <TextInput
          {...inputProps}
          fill
          testID={`${testID}-hm-input`}
          value={time}
          placeholder={placeholder ?? "-- : --"}
          onBlur={handleTimeBlur}
          onChangeText={handleTimeChange}
          maxLength={5}
        />
        <MaxWidthContainer maxWidth={88} fill>
          <Picker
            eventTargetName="Meridiem Picker"
            placeholder="--"
            testID={`${testID}-meridiem`}
            value={computedMeridiem}
            options={OPTIONS_AM_PM}
            onChange={handleMeridiemChange}
          />
        </MaxWidthContainer>
      </Row>
      {!!errors && (
        <InputNote
          error
          testID={`${testID}-hm-input-error`}
          note={
            Array.isArray(error)
              ? _.uniq(_.compact(error.map(formatError))).join(", ")
              : formatError(error)
          }
        />
      )}
    </Column>
  );
}

function parseHm(str: string) {
  const digits = toDigits(str);
  if (digits.length < 3) return digits;
  if (digits.length === 3) return `${digits[0]}:${digits.substr(1)}`;
  return `${digits.substr(0, 2)}:${digits.substr(2)}`;
}

function formatHm(str: string) {
  if (!str) return str;
  let localStr = str;
  if (!localStr?.includes(":")) localStr = `${localStr}:00`;
  const [hours, minutes] = localStr.split(":");
  const newHours = hours?.length === 1 ? `0${hours}` : hours;
  const newMinutes = minutes?.length === 1 ? `${minutes}0` : minutes;
  return `${newHours}:${newMinutes}`;
}

function toDigits(str: string) {
  return str
    .replace(/[^0-9]/g, "")
    .trim()
    .slice(0, 4);
}

function formatError(error?: Error | string | null) {
  if (error) return typeof error === "string" ? error : error.message;
}
