import React, { type ReactNode } from "react";
import { IconCircle, Stack } from "../../atoms";
import { Column, type IconName, Spacer, Text } from "../../quarks";

interface Props {
  reason: string | ReactNode;
  name: string;
  icon: IconName;
}

export default function PermissionRequestModal({ icon, name, reason }: Props) {
  return (
    <Stack>
      <Column alignItems="center">
        <Spacer size="xlarge" />
        <IconCircle
          color="primary"
          size="extraLarge"
          icon={icon}
          variant="well"
        />
        <Spacer size="xlarge" />
      </Column>
      <Text variant="title" color="primary" align="center">
        Allow {name}
      </Text>
      {typeof reason === "string" ? (
        <Stack size="compact">
          {reason.split("\n").map((p, idx) => (
            <Text key={idx} align="center">
              {p}
            </Text>
          ))}
        </Stack>
      ) : (
        reason
      )}
      )
    </Stack>
  );
}
