import { FlagType } from "@gigsmart/feature-flags/registry";
import { createLogger } from "./createLogger";

let isDev = false;

if (typeof __DEV__ !== "undefined") {
  isDev = __DEV__;
}

export const { log, info, warn, error, debug, trace, wrap, inspect } =
  createLogger("🏮", "console", isDev ? FlagType.PINNED : FlagType.REMOTE);
