import React, { Component, type ComponentProps } from "react";
import Select from "react-dropdown-select";
import { View } from "react-native";
import {
  type StylesProps,
  type ViewStyleProp,
  stylesStubs,
  theme,
  withStyles
} from "../../style";
import StyledText from "../../text/styled-text";
import type StyledTextInput from "../styled-text-input";
import normalizeOptions from "./normalizeOptions";
import StyledPickerArrow from "./styled-picker-arrow";

export type StyledPickerOptions = Array<
  { label?: string; value: unknown | null | undefined } | string
>;

const pickerStyles = {
  backgroundColor: theme.color.neutralLightGray,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomColor: theme.color.neutralMedium,
  borderBottomWidth: 1,
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  boxShadow: "none",
  fontFamily: "Proxima Nova",
  minHeight: 29,
  fontSize: theme.font.size.medium,
  height: 44,
  zIndex: 200
};

const cleanPickerStyles = {
  height: "auto",
  width: "auto",
  boxShadow: "none",
  fontFamily: "Proxima Nova",
  borderWidth: 0,
  zIndex: 200
};

type Props = StylesProps &
  ComponentProps<typeof StyledTextInput> &
  Omit<
    ComponentProps<typeof Select>,
    "style" | "onChange" | "onChangeText" | "values"
  > & {
    testID?: string;
    inputContainerStyle?: ViewStyleProp;
    placeholder?: string;
    label?: string;
    style?: ViewStyleProp;
    value?: string | null | undefined;
    options: StyledPickerOptions;
    onChangeText: (value: string) => void;
    searchable?: boolean;
    renderPickerPressable?: (pressableProps: {
      isOpen: boolean;
      label: string | null;
    }) => JSX.Element;
    dropdownPosition?: "auto" | "top" | "bottom";
  };

@withStyles(({ color, font }) => ({
  label: {
    ...font.body("bold"),
    color: color.blue,
    fontSize: font.size.bodyCopy,
    lineHeight: font.size.bodyCopy * font.lineHeight.body,
    marginHorizontal: 8,
    marginBottom: 4
  },
  labelError: {
    color: color.red
  },
  subtext: {
    color: color.neutralDark,
    fontSize: font.size.medium,
    marginHorizontal: 2,
    marginTop: 3
  },
  validationMessage: {
    color: color.red
  }
}))
export default class StyledPicker extends Component<
  Props,
  { isOpen: boolean }
> {
  static defaultProps = {
    ...stylesStubs,
    placeholder: "Pick One",
    onChangeText: () => {},
    errors: [],
    searchable: false,
    dropdownPosition: "auto"
  };

  state = { isOpen: false };

  render() {
    const {
      inputContainerStyle,
      errors,
      label,
      styles,
      subtext,
      testID,
      placeholder,
      onChangeText,
      value,
      style,
      disabled,
      searchable,
      options,
      renderPickerPressable,
      dropdownPosition
    } = this.props;
    const { isOpen } = this.state;

    const zIndex = isOpen ? 100 : 0;
    const normOptions = normalizeOptions(options);
    const values = value
      ? normOptions.filter((opt) => opt.value === value)
      : [];

    return (
      <View style={[{ zIndex }, style]}>
        {!!label && (
          <StyledText
            testID={testID && `${testID}-label`}
            style={[styles.label, errors?.length ? styles.labelError : {}]}
          >
            {label}
          </StyledText>
        )}
        <View testID={testID} style={[inputContainerStyle, { zIndex }]}>
          {renderPickerPressable ? (
            <Select
              options={normOptions}
              values={values}
              contentRenderer={() => (
                <View pointerEvents="none">
                  {renderPickerPressable({
                    isOpen,
                    label: values[0]?.label ?? null
                  })}
                </View>
              )}
              dropdownHandle={false}
              style={cleanPickerStyles}
              disabled={disabled}
              onChange={(itemValue) =>
                onChangeText(itemValue[0]?.value as string)
              }
            />
          ) : (
            <Select
              options={normOptions}
              placeholder={placeholder}
              searchable={searchable}
              name={label}
              dropdownHandle
              values={values}
              onChange={(itemValue) =>
                onChangeText(itemValue[0]?.value as string)
              }
              dropdownHandleRenderer={({ state }) => (
                <StyledPickerArrow open={state.dropdown} />
              )}
              onDropdownOpen={() => this.setState({ isOpen: true })}
              onDropdownClose={() => this.setState({ isOpen: false })}
              style={pickerStyles}
              disabled={disabled}
              dropdownPosition={dropdownPosition}
            />
          )}
        </View>
        {!!(subtext ?? errors?.length) && (
          <StyledText
            testID={
              testID
                ? `${testID}-${errors?.length ? "error" : "subtext"}`
                : "styled-input-error-message"
            }
            style={[
              styles.subtext,
              !!errors?.length && styles.validationMessage
            ]}
          >
            {errors?.length
              ? errors?.map(({ message }) => message).join(", ")
              : subtext}
          </StyledText>
        )}
      </View>
    );
  }
}
