import React from "react";
import Column from "../quarks/Column";
import Icon from "../quarks/Icon";
import Image from "../quarks/Image";
import Pressable from "../quarks/Pressable";
import Row from "../quarks/Row";
import Text from "../quarks/Text";
import { useStyles } from "../style";
import ContentArea from "./ContentArea";

interface Props {
  image: string;
  title: string;
  author: string;
  subText: string;
  onPress: () => void;
  testID: string;
}

export default function BlogPostPreviewRow({
  image,
  title,
  author,
  subText,
  onPress,
  testID
}: Props) {
  const styles = useStyles(
    ({ getUnits, getFontSize }) => ({
      icon: {
        marginHorizontal: getUnits(2)
      },
      textContainer: {
        marginLeft: getUnits(3),
        marginRight: getUnits(2)
      },
      textSize: getFontSize(3),
      author: {
        marginVertical: getUnits(1)
      }
    }),
    []
  );
  return (
    <Pressable
      onPress={onPress}
      eventEntityType="BlogPostPreviewRow"
      eventTargetName="Blog Post Preview Row"
      testID={testID}
    >
      <ContentArea size="compact" variant="compact">
        <Row alignItems="center">
          <Image
            height={76}
            variant="square"
            resizeMode="cover"
            source={{
              uri: image
            }}
            borderRadius={0}
          />
          <Column fill={1} style={styles.textContainer}>
            <Text
              color="black"
              weight="bold"
              numberOfLines={2}
              style={styles.textSize}
            >
              {title}
            </Text>
            <Text
              color="neutral"
              italic
              style={[styles.textSize, styles.author]}
            >
              {author}
            </Text>
            <Text color="black" numberOfLines={2} style={styles.textSize}>
              {subText}
            </Text>
          </Column>
          <Icon
            name="chevron-right"
            size="small"
            color="primary"
            variant="solid"
            style={styles.icon}
          />
        </Row>
      </ContentArea>
    </Pressable>
  );
}
