import { type DependencyList, useEffect, useState } from "react";

export default function useAsyncMemo<V>(
  fn: () => Promise<V>,
  deps: DependencyList = []
) {
  const [value, setValue] = useState<V>();
  useEffect(() => {
    setValue(undefined);
    let setter = setValue;
    void fn()
      .then((v) => setter(v))
      .catch(console.warn);

    return () => {
      setter = () => {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return value;
}
