import React, { useMemo } from "react";
import { ContentArea, DocumentTile, GalleryImageTile, Stack } from "../atoms";
import { DocumentTileContainer, GalleryImageTileContainer } from "../molecules";
import { DocumentTypes } from "./FilePicker";
import type { FileItem } from "./MediaPicker";

interface Props {
  onRemove: (id: string) => void;
  selectedItems: FileItem[];
}

export default function FilePreview({ onRemove, selectedItems }: Props) {
  const galleryItems = selectedItems?.filter(
    ({ type }) => type !== "application/pdf"
  );
  const documents = selectedItems?.filter(
    ({ type }) => type === "application/pdf"
  );

  const galleryImageTiles = useMemo(() => {
    return galleryItems?.map((item) => (
      <GalleryImageTile
        key={item.id}
        id={item.id}
        variant="rounded"
        uri={item.uri ?? ""}
        size={60}
        onRemove={onRemove}
      />
    ));
  }, [galleryItems, onRemove]);

  const documentTiles = useMemo(() => {
    return documents?.map((item) => (
      <DocumentTile
        key={item?.id}
        id={item?.id}
        name={item?.name ?? ""}
        type={DocumentTypes[item?.type ?? ""] ?? ""}
        icon={item.type === "application/pdf" ? "file-pdf" : "file"}
        onRemove={onRemove}
      />
    ));
  }, [documents, onRemove]);

  return (
    <>
      {galleryImageTiles?.length || documentTiles?.length ? (
        <ContentArea variant="none" fill>
          <Stack size="compact">
            {!!galleryImageTiles?.length && (
              <GalleryImageTileContainer imageTiles={galleryImageTiles} />
            )}
            {!!documentTiles?.length && (
              <DocumentTileContainer documentTiles={documentTiles} />
            )}
          </Stack>
        </ContentArea>
      ) : null}
    </>
  );
}
