import React, { type ReactNode } from "react";
import type { TestIDProps } from "../utils/types";
import TitleBar from "./TitleBar";

import type { IconName } from "../quarks";
import type { Color } from "../style/theme/colors";

interface Props extends TestIDProps {
  title: ReactNode;
  description?: ReactNode;
  titleColor?: Color;
  primaryAction?: ReactNode;
  icon?: IconName;
  variant?: "shadow" | "flat";
}

/** @deprecated use TitleBar */
export default function ScreenHeader({
  testID,
  title,
  description,
  titleColor = "primary",
  primaryAction,
  icon,
  variant
}: Props) {
  return (
    <TitleBar
      testID={testID}
      variant={variant}
      icon={icon}
      title={title}
      description={description}
      tintColor={titleColor}
      action={primaryAction}
    />
  );
}
