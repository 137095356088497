import React from "react";

import { Row, Text } from "../quarks";
import { useStyle } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  value: number;
  color?: Color;
}

export default function ButtonTag({ value, color = "primary" }: Props) {
  const style = useStyle(({ getUnits, getColor }) => ({
    backgroundColor: getColor(color, "fill"),
    borderRadius: getUnits(1),
    paddingHorizontal: getUnits(1),
    marginRight: getUnits(2),
    height: 18,
    paddingTop: 1
  }));

  return (
    <Row style={style} justifyContent="center" alignItems="center">
      <Text weight="bold" color="surface">
        {value.toLocaleString()}
      </Text>
    </Row>
  );
}
