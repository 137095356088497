import React from "react";
import Column from "../quarks/Column";
import Row from "../quarks/Row";
import Spacer from "../quarks/Spacer";
import Text, { type CommonTextProps as TextProps } from "../quarks/Text";
import { useStyle } from "../style";
import InputLabel from "./InputLabel";
import Stack from "./Stack";

interface Props extends TextProps {
  label?: string;
  inset?: boolean;
}

export default function InputContainerText({
  label,
  inset = true,
  ...rest
}: Props) {
  const columnStyle = useStyle(() => ({ minHeight: 44 }), []);
  return (
    <Stack size="slim">
      {label && <InputLabel label={label} />}
      <Column style={columnStyle} justifyContent="center">
        <Row>
          {inset && <Spacer horizontal size="compact" />}
          <Text {...rest} />
        </Row>
      </Column>
    </Stack>
  );
}
