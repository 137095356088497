import { pull } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from "react";

export interface ContextType {
  register: (fn: () => void) => void;
  deregister: (fn: () => void) => void;
  retryAll: (top?: boolean) => void;
}

const outOfBoundaryError = () => {
  throw new Error(
    "Relay: Expected to be inside a <RelayQueryRetryBoundary> but was not found."
  );
};

const Context = createContext<ContextType>({
  register: outOfBoundaryError,
  deregister: outOfBoundaryError,
  retryAll: outOfBoundaryError
});

export const useRegisterRelayRetry = (fn: () => void, verify = true) => {
  const { register, deregister } = useContext(Context);
  useEffect(() => {
    if (!verify && register === outOfBoundaryError) return;
    register(fn);
    return () => deregister(fn);
  }, [register, deregister, fn, verify]);
};

export const useRelayRetrier = (top = false) => {
  const { retryAll } = useContext(Context);
  return useMemo(
    () =>
      retryAll === outOfBoundaryError
        ? outOfBoundaryError
        : () => retryAll(top),
    [retryAll, top]
  );
};

export function RelayQueryRetryBoundary({
  children,
  watcher
}: {
  children: React.ReactNode;
  watcher?: boolean;
}) {
  const parentRetryAll = useRelayRetrier(true);
  const prevWatcher = useRef(watcher);
  const registry = useRef<Array<() => void>>([]);

  const register = useCallback((fn: () => void) => {
    if (registry.current.includes(fn)) return;
    registry.current.push(fn);
  }, []);

  const deregister = useCallback((fn: () => void) => {
    pull(registry.current, fn);
  }, []);

  const retryAll = useCallback(
    (top?: boolean) => {
      if (top && parentRetryAll !== outOfBoundaryError) {
        parentRetryAll();
        return;
      }
      registry.current.forEach((fn) => fn());
    },
    [parentRetryAll]
  );

  useRegisterRelayRetry(retryAll, false);

  useEffect(() => {
    if (prevWatcher.current === watcher) return;
    prevWatcher.current = watcher;
    if (watcher) retryAll();
  }, [watcher, retryAll]);

  const value = useMemo(
    () => ({ register, deregister, retryAll }),
    [register, deregister, retryAll]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
