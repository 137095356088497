import { definePermission } from "../registry";

export default definePermission({
  name: "Background Location",
  icon: "map-location",
  defaults: {
    preview: true,
    reason:
      "To help you find relevant opportunities close to your area, please allow location access. This ensures we can show you the most relevant opportunities available."
  }
});
