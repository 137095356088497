import { type HOCVoid, applyHOCProperties } from "@gigsmart/hoc-utils";
import React, { type ComponentType, type JSXElementConstructor } from "react";
import {
  type ConnectionConfig,
  type GraphQLTaggedNode,
  type RelayPaginationProp,
  createPaginationContainer
} from "react-relay";
import type { OperationType } from "relay-runtime";
import { createEnvironmentStub } from "../environment";

export interface RelayPaginationProps<TOperation extends OperationType> {
  relay: RelayPaginationProp;
  variables: TOperation["variables"];
}

type PropsType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

type PropsWithoutRelay<P extends PropsType> = JSX.LibraryManagedAttributes<
  P,
  Omit<React.ComponentProps<P>, "relay">
>;

interface RelayPaginationConfigType<P extends PropsType, S extends string>
  extends ConnectionConfig<PropsWithoutRelay<P>> {
  getFragmentRefs?: (props: P) => Partial<Record<S, any>>;
}

export const relayPaginationStubs: RelayPaginationProps<any> = {
  relay: {
    get environment() {
      return createEnvironmentStub();
    },
    hasMore: () => false,
    isLoading: () => false,
    loadMore: () => undefined,
    refetchConnection: () => undefined,
    refetch: undefined
  },
  variables: {} as any
};

export const withRelayPagination =
  <P extends PropsType, S extends string = string>(
    fragmentSpec: Record<S, GraphQLTaggedNode>,
    {
      getFragmentRefs = () => ({}),
      ...connectionConfig
    }: RelayPaginationConfigType<P, S>
  ): HOCVoid =>
  (WrappedComponent: ComponentType<P & { relay: RelayPaginationProp }>) => {
    const PaginationContainer = createPaginationContainer<
      typeof WrappedComponent
    >(WrappedComponent, fragmentSpec, connectionConfig as ConnectionConfig);
    return applyHOCProperties({
      displayName: "withRelayPagination",
      WrappedComponent,
      HigherOrderComponent: (props) => {
        return <PaginationContainer {...props} {...getFragmentRefs(props)} />;
      }
    });
  };
