import { useContext } from "react";
import { WysiwygContext } from "./WysiwygProvider";

export default function useWysiwyg() {
  const ctx = useContext(WysiwygContext);

  return {
    dismissKeyBoard: () => {
      ctx.getRef()?.current?.dismissKeyboard();
    },
    setWysiwygRef: ctx.setRef
  };
}
