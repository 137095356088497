import { Duration } from "luxon";
import initDurationFormat from "moment-duration-format";
import * as moment from "moment-timezone";

initDurationFormat(moment);

export type DurationHumanizeVariant =
  | "readable"
  | "relative"
  | "compact-no-seconds"
  | "compact-no-seconds-no-trim"
  | "compact"
  | "compact-no-trim"
  | "semi-compact"
  | "semi-compact-no-spaces"
  | "long-no-seconds"
  | "long"
  | "full-clock"
  | "clock"
  | "hours"
  | "days-hours-minutes"
  | "hours-decimal";

const humanizeVariants: Record<
  DurationHumanizeVariant,
  (duration: moment.Duration) => string
> = {
  readable: (d) => d.humanize(false),
  relative: (d) => d.humanize(true),
  "compact-no-seconds": (d) => d.format("h[h] m[m]", 0, { trim: "both" }),
  "compact-no-seconds-no-trim": (d) =>
    d.format("h[h] m[m]", 0, { trim: false }),
  compact: (d) => d.format("h[h] m[m] s[s]", 0, { trim: "both" }),
  "compact-no-trim": (d) => d.format("h[h] m[m] s[s]", 0, { trim: false }),
  "semi-compact": (d) => d.format("h[ hr] m[ min]", 0, { trim: "both" }),
  "semi-compact-no-spaces": (d) =>
    d.format("h[hr] m[min]", 0, { trim: false, usePlural: false }),
  "long-no-seconds": (d) =>
    d.format("h[ hours] m[ minutes]", 0, {
      trim: "both"
    }),
  long: (d) =>
    d.format("h[ hours] m[ minutes] s[ seconds]", 0, {
      trim: "both"
    }),
  "full-clock": (d) => d.format("HH:mm:ss", { trim: false }),
  clock: (d) =>
    d.format(
      d.hours() >= 1 ? "h:mm:ss" : d.minutes() >= 1 ? "m:ss" : "00:ss",
      0,
      { trim: false }
    ),
  hours: (d) => d.format("H"),
  "days-hours-minutes": (d) =>
    d.format("d[ days] h[ hr] m[ min]", 0, { trim: "both" }),
  "hours-decimal": (d) => d.asHours().toFixed(2)
};

export const humanize = (
  iso8601: string | null | undefined | moment.Duration,
  variant: DurationHumanizeVariant = "clock"
) => {
  if (!iso8601) return "";

  const fmtFn =
    variant in humanizeVariants
      ? humanizeVariants[variant as DurationHumanizeVariant]
      : (d: moment.Duration) => {
          console.warn("UNSAFE: Using a non-standard time format");
          return d.format(variant, { trim: false });
        };

  return fmtFn(moment.duration(iso8601));
};

export const fromDays = (seconds?: number | null) => {
  return moment.duration(seconds ?? 0, "d");
};

export const fromSeconds = (seconds?: number | null) => {
  return moment.duration(seconds ?? 0, "s");
};

export const fromMinutes = (minutes?: number | null) => {
  return moment.duration(minutes ?? 0, "m");
};

export const fromHours = (hours?: number | null) => {
  return moment.duration(hours ?? 0, "h");
};

export const toSeconds = (iso8601?: string | moment.Duration | null) => {
  if (!iso8601) return 0;
  return moment.duration(iso8601).asSeconds();
};

export const toMinutes = (iso8601?: string | moment.Duration | null) => {
  if (!iso8601) return 0;
  return moment.duration(iso8601).asMinutes();
};

export const toHours = (iso8601?: string | moment.Duration | null) => {
  if (!iso8601) return 0;
  return moment.duration(iso8601).asHours();
};

export const unitsFromDuration = (
  iso8601: string | Duration
): { days: number; hours: number; minutes: number; seconds: number } => {
  let d: Duration;
  if (typeof iso8601 === "string") {
    d = Duration.fromISO(iso8601);
  } else {
    d = iso8601;
  }

  return {
    days: d.days,
    hours: d.hours,
    minutes: d.minutes,
    seconds: d.seconds
  };
};
