import { useEffect } from "react";
import { AppState } from "react-native";

export default function useActiveAppStateCallback(
  cb: () => void,
  invokeOnStart = false,
  deps: any[] = []
) {
  useEffect(() => {
    if (invokeOnStart && AppState.currentState === "active") cb();
    return AppState.addEventListener("change", (state) => {
      if (state !== "active") return;
      cb();
    }).remove;
  }, deps);
}
