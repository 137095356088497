import React, { type ReactNode } from "react";
import { type UseTransformFlexProps, type ViewStyle, useStyle } from "../style";
import Column from "./Column";

const positionSpec = {
  top: { left: 0, right: 0, top: 0 },
  bottom: { left: 0, right: 0, bottom: 0 },
  right: { top: 0, bottom: 0, right: 0 },
  left: { top: 0, bottom: 0, left: 0 }
};

interface Props
  extends Pick<UseTransformFlexProps, "alignItems" | "justifyContent"> {
  children: ReactNode;
  position?: keyof typeof positionSpec;
  pointerEvents?: "none";
  style?: Pick<ViewStyle, keyof typeof positionSpec>;
}

export default function Floating({
  children,
  position = "bottom",
  pointerEvents = "none",
  style,
  ...props
}: Props) {
  const viewStyle = useStyle(
    () => ({ position: "absolute", elevation: 3 }),
    []
  );
  return (
    <Column
      pointerEvents={pointerEvents}
      style={[viewStyle, positionSpec[position], style]}
      zIndex={1000}
      {...props}
    >
      {children}
    </Column>
  );
}
