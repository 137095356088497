interface GradientPos {
  x: number;
  y: number;
}

interface GradientShape {
  colors: string[];
  locations: number[];
  start: GradientPos;
  end: GradientPos;
}

const nightSky: GradientShape = {
  colors: ["#396F90", "#4D87A8", "#78B9DA", "#89CDEE"],
  locations: [0, 0.25, 0.75, 1],
  start: { x: 1, y: 0 },
  end: { x: 1, y: 1 }
};

export default {
  nightSky
};
