import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "../quarks";
import { ColorPlacementProvider, useStyles } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  text: string;
  color?: Color;
}

export default function VerticalLabel({ text, color = "highlight" }: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      container: { backgroundColor: getColor(color, "fill") },
      placeholder: { width: 20, height: 20 },
      content: {
        ...StyleSheet.absoluteFillObject,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
      },
      text: {
        width: 240,
        maxWidth: 240,
        transform: [{ rotate: "-90deg" }]
      }
    }),
    [color]
  );
  return (
    <View style={styles.container}>
      <View style={styles.placeholder} />
      <ColorPlacementProvider color={color} use="placement">
        <View style={styles.content}>
          <Text
            style={styles.text}
            variant="note"
            weight="bold"
            align="center"
            numberOfLines={1}
          >
            {text.toUpperCase()}
          </Text>
        </View>
      </ColorPlacementProvider>
    </View>
  );
}
