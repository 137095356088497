import React from "react";
import { View } from "react-native";
import AbsoluteContainer from "../atoms/AbsoluteContainer";
import IconCircle from "../atoms/IconCircle";
import Icon, { type IconName } from "../quarks/Icon";
import Pressable from "../quarks/Pressable";
import Text from "../quarks/Text";
import { useStyles } from "../style";
import ListRow from "./ListRow";

interface Props {
  id?: string;
  name: string;
  type: string;
  icon?: IconName;
  onRemove?: (id: string) => void;
}

export default function DocumentTile({
  id,
  name,
  type,
  icon = "file",
  onRemove
}: Props) {
  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      container: {
        borderWidth: 1,
        borderRadius: 5,
        borderColor: getColor("background", "fill"),
        width: getUnits(45),
        height: getUnits(15),
        backgroundColor: getColor("disabled", "fill", { opacity: 0.08 })
      }
    }),
    []
  );

  return (
    <View style={styles.container} testID={`document-tile-${name}`}>
      <ListRow
        label={name}
        numberOfLines={1}
        left={<Icon name={icon} color="primary" variant="solid" />}
        testID={name}
      >
        <Text variant="note" color="neutral">
          {type}
        </Text>
      </ListRow>
      {onRemove && id && (
        <AbsoluteContainer top={-5} left={170}>
          <Pressable
            onPress={() => onRemove(id)}
            testID={`document-tile-${name}-remove-icon`}
            eventEntityType="document-tile-remove-icon"
            eventTargetName="Document Tile Remove Icon"
          >
            <IconCircle
              icon="times"
              variant="standard"
              size="micro"
              color="primary"
            />
          </Pressable>
        </AbsoluteContainer>
      )}
    </View>
  );
}
