import { DateTime } from "luxon";
import React, { useMemo } from "react";

import { typedMemo } from "../../utils";

import Surface from "../../atoms/Surface";
import Column from "../../quarks/Column";
import Row from "../../quarks/Row";
import { useCalendarContext } from "./CalendarContext";
import DayHeader from "./DayHeader";
import WeekRow from "./WeekRow";
import {
  type CalendarVariant,
  WEEKDAYS,
  monthlyView,
  renderWithDivider
} from "./helpers";

type Props<T> = CalendarVariant<T>;

export default typedMemo(
  function MonthlyCalendar<T>({
    date = DateTime.local(),
    events,
    renderDay
  }: Props<T>) {
    const { isMd } = useCalendarContext();
    const view = useMemo(() => monthlyView(date), [date]);
    return (
      <Surface variant="outline" fill>
        <Row>
          {renderWithDivider(WEEKDAYS, "y", (it) => (
            <DayHeader fill label={isMd ? it : it[0]} />
          ))}
        </Row>
        <Column fill>
          {renderWithDivider(view, "x", (week) => (
            <WeekRow fill week={week} events={events} renderDay={renderDay} />
          ))}
        </Column>
      </Surface>
    );
  },
  (p1, p2) => p1.date.month === p2.date.month && p1.events === p2.events
);
