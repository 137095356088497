import type { DateTime } from "luxon";
import React, { type ComponentProps } from "react";
import { Button, Stack } from "../../atoms";
import { Text } from "../../quarks";
import { useStyles } from "../../style";
import { useCalendarContext } from "./CalendarContext";
import { type PeriodType, calendarPeriod } from "./helpers";

type Props = Pick<ComponentProps<typeof Stack>, "fill"> & {
  period: PeriodType;
  date: DateTime;
  onDateChange?: (date: DateTime) => void;
};

function formatTitle(date: DateTime, period: PeriodType, isSm = false) {
  if (period === "day") {
    return date.toFormat(`${isSm ? "MMMM" : "MMM"} d, yyyy`);
  }

  if (period === "week") {
    const { start, end } = calendarPeriod(date, "week");
    if (!start.hasSame(end, "month")) {
      return `${start.toFormat("MMM")} - ${end.toFormat("MMM yyyy")}`;
    }
  }

  return date.toFormat(`${isSm ? "MMMM" : "MMM"} yyyy`);
}

export default function CalendarHeader({
  fill,
  date,
  period,
  onDateChange
}: Props) {
  const { isLg, isMd, isSm } = useCalendarContext();
  const styles = useStyles(
    () => ({
      headerLabel: { minWidth: isLg ? 156 : isMd ? 122 : undefined }
    }),
    [isLg, isMd]
  );

  const handleChange = (diff: 1 | -1) => {
    onDateChange?.(date.plus({ [period]: diff }));
  };
  return (
    <Stack
      fill={fill}
      size={isSm ? "standard" : "slim"}
      horizontal
      alignItems="center"
      justifyContent="center"
    >
      <Button
        label=""
        size="small"
        outline
        icon="chevron-left"
        testID="calendar-decrease-period"
        onPress={() => handleChange(-1)}
      />
      <Text
        style={styles.headerLabel}
        variant={isLg ? "title" : "header"}
        color="primary"
        weight="bold"
        align="center"
      >
        {formatTitle(date, period, isSm)}
      </Text>
      <Button
        label=""
        size="small"
        outline
        icon="chevron-right"
        testID="calendar-increase-period"
        onPress={() => handleChange(1)}
      />
    </Stack>
  );
}
