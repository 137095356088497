import React from "react";
import { ContentArea } from "../../atoms";
import { Column, Icon, Pressable, Text } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";

interface Props {
  itemKey: string;
  isCollapsed: boolean;
  onToggleCollapse: (itemKey: string) => void;
}

export default function SectionListCollapsibleContainer({
  itemKey,
  isCollapsed,
  onToggleCollapse
}: Props) {
  const isSm = useMatchesViewport((media) => media.size.xsmall.down);

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      collapseControlColumn: {
        backgroundColor: getColor("primary", "fill", { opacity: 0.08 }),
        minWidth: isSm ? getUnits(8) : getUnits(9),
        alignItems: "center"
      }
    }),
    [isSm]
  );

  return (
    <Pressable
      onPress={() => onToggleCollapse(itemKey)}
      testID="toggle-view-button"
      eventTargetName="Toggle View Button"
      eventEntityType="Toggle View Button"
    >
      <Column style={styles.collapseControlColumn} fill={1}>
        <ContentArea size="medium" variant="slim">
          {!isSm && (
            <Text variant="note" color="primary" weight="bold">
              {isCollapsed ? "View" : "Hide"}
            </Text>
          )}
          <Icon
            size="small"
            variant="solid"
            name={isCollapsed ? "chevron-down" : "chevron-up"}
            align="center"
          />
        </ContentArea>
      </Column>
    </Pressable>
  );
}
