import type { Object as ObjectUtils } from "@gigsmart/type-utils";
import { compact } from "lodash";
import { useMemo } from "react";
import {
  type GraphQLTaggedNode,
  getRelayHandleKey,
  getRequest
} from "relay-runtime";

import { getStableStorageKey } from "relay-runtime/lib/store/RelayStoreUtils";
import type { Mutation, UseMutationCommitFunctionType } from "./mutation";
import type { PayloadError } from "./payload-error";
export interface BasicEdge<N> {
  [key: string]: unknown;
  readonly cursor?: string | null;
  readonly node?: N | null | undefined;
}

export interface BasicConnection<E extends BasicEdge<unknown>> {
  readonly edges?: ReadonlyArray<E | null | undefined> | null | undefined;
  readonly pageInfo?: {
    readonly hasPreviousPage?: boolean;
    readonly endCursor?: string | null;
    readonly hasNextPage?: boolean;
    readonly startCursor?: string | null;
  } | null;
}

export type ConnectionEdgeType<T extends object | null | undefined> =
  NonNullable<ObjectUtils.Path<NonNullable<T>, ["edges", 0]>>;

export type ConnectionNodeType<T extends object | null | undefined> =
  NonNullable<ObjectUtils.Path<NonNullable<T>, ["edges", 0, "node"]>>;

export function getFieldError(
  payloadErrors: readonly PayloadError[] | undefined | null,
  name: string
): PayloadError | undefined {
  if (payloadErrors) {
    return payloadErrors.find(({ field }) => field === name);
  }
}

export function getConnectionNodes<N, R = N>(
  connection?: BasicConnection<BasicEdge<N>> | null | undefined,
  format?: (node: N, idx: number) => R,
  filter?: (node: N) => boolean
): R[] {
  let arr: any[] = compact(getConnectionEdges(connection, ({ node }) => node));
  if (filter) arr = arr.filter(filter);
  if (format) arr = arr.map(format);
  return arr;
}

export function getConnectionEdges<E extends BasicEdge<unknown>, R = E>(
  connection?: BasicConnection<E> | null | undefined,
  format?: (edge: E, idx: number) => R,
  filter?: (edge: E) => boolean
): R[] {
  let arr: any[] = compact(connection?.edges ?? []);
  if (filter) arr = arr.filter(filter);
  if (format) arr = arr.map(format);
  return arr;
}

export const commitBatchMutations = <Raw, Input>(
  data: Raw[],
  formatInput: (data: Raw) => Input,
  getMutation: (
    input: Input
  ) => Mutation<any> | UseMutationCommitFunctionType<any> | null,
  rawVariables?: any | ((input: Input) => any)
) => {
  const errors: Error[] = [];
  const promises = data.map(async (entry) => {
    await new Promise<void>((resolve) => {
      const input = formatInput(entry);
      const mutation = getMutation(input);
      if (!mutation) {
        resolve();
        return;
      }
      const variables =
        typeof rawVariables === "function" ? rawVariables(input) : rawVariables;
      const vars = { ...variables, input };

      const options = {
        onSuccess: () => resolve(),
        onError: (err: Error) => {
          errors.push(err);
          resolve();
        }
      };

      if ("commit" in mutation) {
        void mutation.commit(vars, options);
      } else {
        mutation(vars, options);
      }
    });
  });

  return { promises, errors };
};

export const useRequestMetadata = (taggedNode: GraphQLTaggedNode) =>
  useMemo(() => getRequest(taggedNode), [taggedNode]);

export const getConnectionKey = (
  parentId: string | null | undefined,
  connectionKey: string,
  filters: Record<string, any> = {}
) =>
  compact([
    "client",
    parentId,
    getStableStorageKey(getRelayHandleKey("connection", connectionKey), filters)
  ]).join(":");
