import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint
} from "@vis.gl/react-google-maps";
import React, { type ComponentProps } from "react";
import { View } from "react-native";
import Avatar from "../../atoms/Avatar";
import { useStyles } from "../../style";

interface Props {
  active?: boolean;
  latitude: number;
  longitude: number;
  avatarUri: string;
  onPress?: () => void;
  testID: string;
  zIndex?: ComponentProps<typeof AdvancedMarker>["zIndex"];
}

export default function MapAvatarMarker({
  active,
  latitude,
  longitude,
  avatarUri,
  onPress,
  testID,
  zIndex
}: Props) {
  const styles = useStyles(
    ({ getColor }) => ({
      border: {
        borderColor: getColor(active ? "success" : "surface", "fill"),
        borderWidth: 4,
        borderRadius: 20
      },
      borderAfter: {
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: 4,
        borderRightWidth: 4,
        borderTopWidth: 8,
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderTopColor: getColor(active ? "success" : "surface", "fill"),
        position: "absolute",
        top: 34,
        left: 14
      },
      container: {
        height: 44
      }
    }),
    [active, latitude, longitude]
  );

  return (
    <AdvancedMarker
      title="animated-marker"
      position={{
        lat: latitude ?? 0,
        lng: longitude ?? 0
      }}
      onClick={onPress}
      zIndex={zIndex}
      anchorPoint={AdvancedMarkerAnchorPoint.BOTTOM_CENTER}
    >
      <View style={styles.container}>
        <View style={styles.border} testID={testID}>
          <Avatar uri={avatarUri} size="small" />
        </View>
        <View style={styles.borderAfter} />
      </View>
    </AdvancedMarker>
  );
}
