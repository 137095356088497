import React, { useRef, useEffect, type ReactNode } from "react";
import { Animated, Platform, View } from "react-native";

import { type ViewStyleProp, useStyles } from "../../style";

interface Props {
  style?: ViewStyleProp;
  focusedStyle?: ViewStyleProp;
  focused?: boolean;
  error?: boolean;
  children?: ReactNode;
}

const InputContainer = ({
  style,
  focusedStyle,
  focused,
  error,
  children
}: Props) => {
  // @see https://github.com/facebook/react-native/issues/6278
  const lineScale = useRef(new Animated.Value(focused ? 1 : 0.005)).current;
  const isFirstRef = useRef(true);

  const { styles } = useStyles(({ color }) => ({
    container: {
      minHeight: 40,
      backgroundColor: color.neutralLightGray,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    },
    containerFocused: {
      backgroundColor: color.neutralLight
    },
    underline: {
      borderBottomColor: color.neutralMedium,
      borderBottomWidth: 1,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0
    },
    underlineError: {
      borderBottomColor: color.red
    },
    underlineActive: {
      borderBottomColor: color.blueTint,
      borderBottomWidth: 2
    }
  }));

  useEffect(() => {
    if (!isFirstRef.current) {
      Animated.timing(lineScale, {
        toValue: focused ? 1 : 0.005,
        useNativeDriver: Platform.OS !== "web",
        duration: 250
      }).start();
    }

    isFirstRef.current = false;
  }, [focused, lineScale]);

  return (
    <View
      style={[
        styles.container,
        style,
        focused && styles.containerFocused,
        focused && focusedStyle
      ]}
    >
      {children}
      <View style={[styles.underline, error && styles.underlineError]} />
      <Animated.View
        style={[
          styles.underline,
          styles.underlineActive, // error && styles.underlineError,
          { transform: [{ scaleX: lineScale }], opacity: lineScale }
        ]}
      />
    </View>
  );
};

export default InputContainer;
