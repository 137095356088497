import { DateTime } from "luxon";
import React, { Fragment, type ReactNode } from "react";

import { Divider } from "../../quarks";

export type PeriodType = "month" | "week" | "day";

export type EventMap<T> = Map<string, T[]>;

export type DayRenderer<T> = (day: DayView, items: T[]) => ReactNode;

export interface CalendarContext {
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  cellSize: number;
}

export interface CalendarVariant<T> {
  loading?: boolean;
  date: DateTime;
  events?: EventMap<T> | null;
  renderDay?: DayRenderer<T>;
}

export interface DayView {
  value: DateTime;
  key: string;
  label: string;
  isToday: boolean;
  isSameMonth: boolean;
}

export type WeeklyView = DayView[];

export type MonthlyView = WeeklyView[];

export function createEventMap<T>(obj: Record<string, T[]>): EventMap<T> {
  return new Map(Object.entries(obj));
}

// Constants

export const WEEKDAYS = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT"
] as const;

export const TODAY = DateTime.now();

//
// Calendar math

const START_AT_DIFF = -1;

export function calendarKey(value: DateTime) {
  return value.toFormat("yyyy-MM-dd");
}

export function dayView(value: DateTime, monthRef?: DateTime): DayView {
  return {
    value,
    key: calendarKey(value),
    label: value.toFormat("d"),
    isToday: TODAY.hasSame(value, "day"),
    isSameMonth: !monthRef || monthRef.hasSame(value, "month")
  };
}

export function calendarPeriod(
  ref: DateTime,
  period: PeriodType
): { start: DateTime; end: DateTime } {
  if (period === "month") {
    const start = calendarPeriod(ref.startOf("month"), "week").start;
    const end = calendarPeriod(ref.endOf("month"), "week").end;
    return { start, end };
  }

  if (period === "week") {
    // Ensure we do not shift one week back if it's Sunday
    const start =
      ref.weekday === 7
        ? ref.startOf("day")
        : ref.startOf("week").plus({ day: START_AT_DIFF });
    return { start, end: start.plus({ day: 6 }).endOf("day") };
  }

  const start = ref.startOf(period);
  const end = ref.endOf(period);
  return { start, end };
}

export function weeklyView(week: DateTime, monthRef?: DateTime) {
  const { start, end } = calendarPeriod(week, "week");

  const result: WeeklyView = [];
  let current = start;
  while (current < end) {
    result.push(dayView(current, monthRef));
    current = current.plus({ day: 1 });
  }

  return result as WeeklyView;
}

export function monthlyView(month: DateTime) {
  const result: MonthlyView = [];

  let week = month.startOf("month");
  do {
    result.push(weeklyView(week, month));
    week = week.plus({ week: 1 });
  } while (isWithinMonth(week, month));

  return result;
}

function isWithinMonth(week: DateTime, month: DateTime) {
  const { start, end } = calendarPeriod(week, "week");
  return (
    start &&
    end &&
    (start.hasSame(month, "month") || end.hasSame(month, "month"))
  );
}

//
// Render helpers

export function renderWithDivider<T>(
  arr: T[] | readonly T[],
  dir: "x" | "y",
  renderFn: (it: T) => JSX.Element
) {
  return arr.map((it, idx) => {
    return (
      <Fragment key={idx}>
        {idx !== 0 && <Divider dir={dir} color="background" />}
        {renderFn(it)}
      </Fragment>
    );
  });
}
