import type { TypeForStyleKey } from "../type-helpers";

export default function padding(...args: Array<TypeForStyleKey<"padding">>) {
  if (args.length === 1) {
    return { padding: args[0] };
  }
  if (args.length === 2) {
    return {
      paddingVertical: args[0],
      paddingHorizontal: args[1]
    };
  }
  if (args.length === 3) {
    return {
      paddingTop: args[0],
      paddingHorizontal: args[1],
      paddingBottom: args[2]
    };
  }
  return {
    paddingTop: args[0],
    paddingRight: args[1],
    paddingBottom: args[2],
    paddingLeft: args[3]
  };
}
