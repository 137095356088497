import React, { type ComponentProps } from "react";
import Svg, { Path } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: title */
type Props = ComponentProps<typeof Svg> & {
  color?: string;
};

const FacebookLogo = ({ color = "#34589D", ...props }: Props) => (
  <Svg data-name="Layer 1" viewBox="0 0 58 58" {...props}>
    <Path
      d="M53.85 0H3.15A3.15 3.15 0 0 0 0 3.15v50.7A3.15 3.15 0 0 0 3.15 57h27.3V35H23v-8.67h7.41V20c0-7.37 4.49-11.38 11.06-11.38a62.15 62.15 0 0 1 6.68.38v7.69h-4.54c-3.57 0-4.26 1.69-4.26 4.18v5.5h8.55L46.79 35h-7.44v22h14.5A3.15 3.15 0 0 0 57 53.85V3.15A3.15 3.15 0 0 0 53.85 0z"
      fill={color}
    />
  </Svg>
);

export default FacebookLogo;
