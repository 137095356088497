import type { ReactElement } from "react";
import { type FormFieldOptions, useFormField } from "./form-field";

export type FormToggleOptions = FormFieldOptions<boolean>;
export interface FormToggleArgs {
  name: string;
  value: boolean;
  initialValue: boolean | null;
  dirty: boolean;
  invalid: boolean;
  toggle: () => void;
  triggerBlur: () => void;
  triggerFocus: () => void;
  errors: Error[] | null;
  errorMessage: string | null;
  submit: () => void;
  submitting: boolean;
  formDirty: boolean;
  formInvalid: boolean;
}

export function useFormToggle(options: FormToggleOptions): FormToggleArgs {
  const { setValue, value, showErrors, hideErrors, ...props } =
    useFormField<boolean>(options);
  return {
    toggle: () => {
      setValue(!value);
      showErrors();
    },
    value: !!value,
    ...props
  };
}

type Props = FormToggleOptions & {
  children: (arg0: FormToggleArgs) => ReactElement | null;
};

export function FormToggle({ children, ...formFieldOptions }: Props) {
  const props = useFormToggle(formFieldOptions);
  return children(props);
}
