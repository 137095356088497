import React, { useMemo, type ReactElement } from "react";
import type { ReactNode } from "react";
import reactStringReplace from "react-string-replace";
import {
  Avatar,
  ContentArea,
  Link,
  Stack,
  type Sticker,
  Surface
} from "../atoms";
import { Column, Icon, Pressable, Text } from "../quarks";

interface Props {
  testID: string;
  avatarUri?: string;
  author: string;
  sticker?: ReactElement<typeof Sticker>;
  timeStamp: string;
  message: string;
  active?: boolean;
  pinned?: boolean;
  unread?: boolean;
  onPress?: () => void;
  conversationContext?: ReactNode;
}

export default function ConversationRow({
  testID,
  avatarUri,
  author,
  sticker,
  timeStamp,
  message: rawMessage,
  active,
  pinned,
  unread,
  onPress,
  conversationContext
}: Props) {
  const avatarInitials = !avatarUri
    ? author
        ?.split(" ")
        ?.map((n) => n[0])
        ?.join("")
    : undefined;

  const message = useMemo(() => {
    let newBody: ReactNode[] | string = rawMessage ?? "";
    // eslint-disable-next-line no-useless-escape
    const parsedBody = rawMessage?.match(/\[([^\[]+)\](\(([^)]*))\)/gim);
    parsedBody?.forEach((item) => {
      const splitItem = item.split("](");
      const link = splitItem?.[1]?.replace(")", "");
      const text = splitItem?.[0]?.replace("[", "");
      newBody = reactStringReplace(newBody, item, () => (
        <Link openURL={link}>{text}</Link>
      ));
    });
    return newBody;
  }, [rawMessage]);

  return (
    <Pressable
      eventEntityType="ConversationRow"
      eventTargetName="Conversation Row"
      testID={testID}
      onPress={onPress}
    >
      <Surface
        variant="flat"
        {...(active ? { color: "primary", fade: true } : null)}
      >
        <ContentArea>
          <Stack horizontal alignItems="center">
            <Avatar
              uri={avatarUri}
              initials={avatarInitials}
              sticker={sticker}
            />
            <ContentArea size="compact" variant="none" fill>
              <Stack size="slim">
                <Stack horizontal justifyContent="space-between" fill>
                  <Column fill>
                    <Text
                      color="primary"
                      weight="bold"
                      testID={`${testID}-author`}
                      numberOfLines={1}
                    >
                      {author}
                    </Text>
                  </Column>
                  <Text
                    variant="note"
                    color="neutral"
                    testID={`${testID}-timestamp`}
                  >
                    {timeStamp}
                  </Text>
                </Stack>
                <Stack horizontal justifyContent="space-between">
                  <Text
                    numberOfLines={1}
                    weight={unread ? "bold" : "normal"}
                    testID={`${testID}-message`}
                  >
                    {message}
                  </Text>
                  <Stack horizontal size="compact">
                    {unread && (
                      <Column
                        justifyContent="center"
                        testID={`${testID}-unread-icon`}
                      >
                        <Icon
                          name="circle"
                          variant="solid"
                          color="success"
                          size="xtiny"
                        />
                      </Column>
                    )}
                    {pinned && (
                      <Column testID={`${testID}-pinned-icon`}>
                        <Icon
                          name="thumbtack"
                          variant="solid"
                          color="primary"
                          size="small"
                        />
                      </Column>
                    )}
                  </Stack>
                </Stack>
                {conversationContext}
              </Stack>
            </ContentArea>
          </Stack>
        </ContentArea>
      </Surface>
    </Pressable>
  );
}
