import React from "react";
import Svg, { Path } from "react-native-svg";
import { useTheme } from "../style";
import type { Color } from "../style/theme/colors";

interface Props {
  size?: number;
  color?: Color;
}

const GigSmartIcon = ({ size = 24, color = "danger" }: Props) => {
  const theme = useTheme();
  const fill = theme.getColor(color, "fill");

  return (
    <Svg
      data-name="Layer 1"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
    >
      <Path d="M4.622 4.537a10.486 10.486 0 017.463-3.092V.051A11.949 11.949 0 00.137 12H1.53a10.486 10.486 0 013.091-7.463zM12.085 22.555v1.394a11.9 11.9 0 007.68-2.796l-.895-1.068a10.475 10.475 0 01-6.785 2.47zM8.366 12H6.972a5.113 5.113 0 005.113 5.113V15.72A3.724 3.724 0 018.365 12zM12.085 9.165a1.44 1.44 0 110-2.881V4.89a2.835 2.835 0 100 5.67 1.44 1.44 0 110 2.88v1.395a2.835 2.835 0 000-5.67z" />
      <Path d="M12.085 17.998a5.996 5.996 0 01-5.193-8.996l-1.21-.698a7.393 7.393 0 1013.625 5.279H17.87a6.008 6.008 0 01-5.786 4.415z" />
      <Path d="M15.805 11.303v1.394h4.527a8.276 8.276 0 11-2.927-7.037l.896-1.067a9.67 9.67 0 103.43 8.104c.016-.23.025-1.394.025-1.394z" />
    </Svg>
  );
};

export default GigSmartIcon;
