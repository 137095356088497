import React from "react";
import { Column, Text } from "../../quarks";
import { useStyles } from "../../style";
import { typedMemo } from "../../utils";
import type { DayView } from "./helpers";

interface Props {
  day: DayView;
}

export default typedMemo(
  function DayIndicator({ day }: Props) {
    const styles = useStyles(({ getColor }) => ({
      header: {
        margin: 4,
        width: 24,
        height: 24,
        borderRadius: 12,
        alignItems: "center",
        justifyContent: "center"
      },
      today: { backgroundColor: getColor("primary", "fill") }
    }));

    return (
      <Column
        alignSelf="center"
        alignItems="center"
        justifyContent="center"
        style={[styles.header, day.isToday && styles.today]}
      >
        {day.isToday ? (
          <Text color="surface" weight="bold">
            {day.label}
          </Text>
        ) : day.isSameMonth ? (
          <Text>{day.label}</Text>
        ) : (
          <Text color="neutral">{day.label}</Text>
        )}
      </Column>
    );
  },
  (p1, p2) => p1.day.key === p2.day.key
);
