import { useEffect, useState } from "react";
import {
  Keyboard,
  type KeyboardEvent,
  LayoutAnimation,
  Platform
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type KeyboardStateOptions = {
  enabled?: boolean;
  extraSpace?: number;
};
export function useKeyboardState({
  enabled = true,
  extraSpace = 0
}: KeyboardStateOptions = {}) {
  const [bottom, setBottom] = useState(0);
  const [visible, setVisible] = useState(false);
  const bottomInset = useSafeAreaInsets().bottom;
  if (!extraSpace) extraSpace = -Math.max(bottomInset - 16, extraSpace);

  if (Platform.OS !== "ios") enabled = false;
  useEffect(() => {
    const prepareAnimation = (e: KeyboardEvent) => {
      const animType = LayoutAnimation.Types[e.easing];
      if (animType && e.duration) {
        try {
          const config = LayoutAnimation.create(
            e.duration,
            animType,
            LayoutAnimation.Properties.opacity
          );
          if (config) LayoutAnimation.configureNext(config);
        } catch (err) {
          console.warn(err);
        }
      }
    };
    const handleShow = (e: KeyboardEvent) => {
      if (enabled) {
        prepareAnimation(e);
        const keyboardHeight = e.endCoordinates.height;
        setBottom(keyboardHeight + extraSpace);
      }
      setVisible(true);
    };

    const handleHide = (e: KeyboardEvent) => {
      if (enabled) {
        prepareAnimation(e);
        setBottom(0);
      }
      setVisible(false);
    };

    const listeners = [
      Keyboard.addListener(
        Platform.OS === "ios" ? "keyboardWillShow" : "keyboardDidShow",
        handleShow
      ),
      Keyboard.addListener(
        Platform.OS === "ios" ? "keyboardWillHide" : "keyboardDidHide",
        handleHide
      )
    ];
    return () => {
      listeners.forEach((emitter) => emitter.remove());
    };
  }, [enabled, extraSpace]);

  return { bottom, visible };
}
