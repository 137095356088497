import React, {
  useLayoutEffect,
  useMemo,
  useState,
  type RefObject,
  useEffect
} from "react";
import type { Animated, ScrollView } from "react-native";
import { IconCircleButton } from "../../atoms";
import { Column, Row, Text, getSpaceUnits } from "../../quarks";
import { useStyles } from "../../style";
import { useKeyboardState } from "../../utils";
import type { NormalizedTableCol } from "./TableView.helpers";

type Props = {
  scrollRef: RefObject<ScrollView | null>;
  scrollX: Animated.Value;
  columns: NormalizedTableCol<any>[];
  width: number;
};

export default function TableScrollHelper({
  scrollRef,
  width,
  columns,
  scrollX
}: Props) {
  const styles = useStyles(({ getColor }) => ({
    container: { backgroundColor: "#fff" },
    row: {
      paddingHorizontal: getSpaceUnits("standard"),
      paddingVertical: getSpaceUnits("medium"),
      backgroundColor: getColor("primary", "fill", { opacity: 0.08 })
    },
    button: {
      width: 28
    }
  }));

  // @ts-ignore
  const getOffset = () => scrollX._value as number;

  const currentColumnIndex = () => {
    const offset = getOffset();
    let accum = 0;
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      if (!col) continue;

      accum += col.cellWidth;
      if (offset < accum) return i;
    }
    return 0;
  };

  const tableWidth = useMemo(() => {
    return columns.reduce((acc, c) => acc + c.cellWidth, 0);
  }, [columns]);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const keyb = useKeyboardState();

  useLayoutEffect(() => {
    const offset = getOffset();
    setCanScrollRight(width + offset < tableWidth - 5);
  }, [width, tableWidth, keyb.visible]);

  useEffect(() => {
    const id = scrollX.addListener(({ value }) => {
      setCanScrollLeft(value > 5);
      setCanScrollRight(width + value < tableWidth - 5);
    });
    return () => scrollX.removeListener(id);
  }, [width, tableWidth]);

  const handleScroll = (diff: 1 | -1) => {
    let n = Math.max(0, currentColumnIndex() + diff);
    let x = 0;
    if (n > 0) while (n--) x += columns[n]?.cellWidth ?? 0;

    scrollRef.current?.scrollTo({ x, animated: true });
  };

  if (keyb.visible || (!canScrollLeft && !canScrollRight)) {
    return null;
  }

  return (
    <Column style={styles.container}>
      <Row style={styles.row} alignItems="center">
        <Column style={styles.button}>
          {canScrollLeft && (
            <IconCircleButton
              size="small"
              color="primary"
              icon="chevron-left"
              testID="scroll-left"
              onPress={() => handleScroll(-1)}
            />
          )}
        </Column>
        <Text fill color="neutral" align="center">
          Select arrow to scroll
        </Text>
        <Column style={styles.button}>
          {canScrollRight && (
            <IconCircleButton
              size="small"
              color="primary"
              icon="chevron-right"
              testID="scroll-right"
              onPress={() => handleScroll(1)}
            />
          )}
        </Column>
      </Row>
    </Column>
  );
}
