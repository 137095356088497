import { createLogger } from "@gigsmart/roga";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useCallback, useEffect, useState } from "react";
import { AppState } from "react-native";

interface LoadOptions {
  maxAge?: number;
  includeExpired?: boolean;
}

function deserialize<T>(str: string | null): {
  value?: T | null;
  expiresAt?: number;
  keep?: boolean;
  insertedAt?: number;
} {
  try {
    return JSON.parse(str ?? "") || { value: null };
  } catch (error) {
    return { value: undefined };
  }
}

function serialize<T>(value: T, ttl: number, keep: boolean) {
  return JSON.stringify({
    value,
    expiresAt:
      ttl && ttl !== Number.POSITIVE_INFINITY ? +new Date() + ttl : undefined,
    keep
  });
}

const logger = createLogger("💾", "Persistence");

class PersistenceService {
  constructor(private readonly prefix: string) {
    AppState.addEventListener("change", () => {
      void this.garbageCollect();
    });
  }

  async saveRaw<T>(
    key: string,
    {
      value,
      expiresAt,
      insertedAt,
      keep
    }: { value?: T; expiresAt?: number; keep?: boolean; insertedAt?: number }
  ) {
    const {
      keep: prevKeep,
      value: prevValue,
      expiresAt: prevExpiresAt,
      insertedAt: prevInsertedAt
    } = await this.loadRaw<T>(key);
    await AsyncStorage.setItem(
      this.transformKey(key),
      JSON.stringify({
        value: value ?? prevValue,
        expiresAt: expiresAt ?? prevExpiresAt,
        keep: keep ?? prevKeep,
        insertedAt: insertedAt ?? prevInsertedAt
      })
    );
  }

  async save<T>(key: string, value: T, ttl = Number.POSITIVE_INFINITY) {
    await this.saveRaw(key, {
      value,
      expiresAt:
        ttl && ttl !== Number.POSITIVE_INFINITY ? +new Date() + ttl : undefined,
      insertedAt: +new Date()
    });
  }

  async fetch<T>(
    key: string,
    fallback: () => T,
    options: LoadOptions = {}
  ): Promise<T> {
    let value = await this.load<T>(key, options).catch(() => null);
    if (!value) {
      value = await Promise.resolve(fallback());
      await this.save<T>(key, value, options.maxAge);
    }
    return value;
  }

  async loadRaw<T>(key: string) {
    return deserialize<T>(await AsyncStorage.getItem(this.transformKey(key)));
  }

  async load<T>(
    key: string,
    {
      maxAge = Number.POSITIVE_INFINITY,
      includeExpired = false
    }: LoadOptions = {}
  ): Promise<T | null | undefined> {
    const {
      value,
      insertedAt = 0,
      expiresAt = Number.POSITIVE_INFINITY
    } = await this.loadRaw<T>(key);
    const now = +new Date();
    const maxAgeEndOfLife = insertedAt + maxAge;
    const isBeyondMaxAge = maxAgeEndOfLife < now;
    const isExpired = expiresAt < now;
    const isExpiredOrBeyondMaxAge = isExpired || isBeyondMaxAge;
    // const lifetimeRemaining = now - Math.min(maxAgeEndOfLife, expiresAt);
    return includeExpired || !isExpiredOrBeyondMaxAge ? value : undefined;
  }

  keep(key: string): string {
    void this.saveRaw(key, { keep: true });
    return key;
  }

  async pop<T>(key: string): Promise<T | null | undefined> {
    const value = await this.load<T>(key);
    await this.delete(key);
    return value;
  }

  async delete(key: string) {
    await AsyncStorage.removeItem(key);
  }

  async deleteMany(keys: string[]) {
    await AsyncStorage.multiRemove(this.transformKeys(keys));
  }

  async loadPairs(
    keys: string[],
    { includeExpired = false }: LoadOptions = {}
  ) {
    const pairs = await AsyncStorage.multiGet(this.transformKeys(keys));
    return pairs
      .map(
        ([k, serialized]) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          [k.split(`${this.prefix}$`)[1]!, deserialize(serialized)] as const
      )
      .filter(([_key, { expiresAt = 0 }]) => {
        return includeExpired || expiresAt > +new Date();
      });
  }

  async preload(keys: string[]) {
    const pairs = await this.loadPairs(keys);
    pairs.forEach(([k, { value }]) => InMemory.save(k, value));
  }

  async getAllKeys() {
    return (await AsyncStorage.getAllKeys()).filter((key) =>
      key.startsWith(`${this.prefix}$`)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deleteMatching(matchFn: (k: string, v: any) => any) {
    const pairs = await this.loadAllPairs();
    const toDelete = pairs.filter(([k, v]) => matchFn(k, v)).map(([k]) => k);
    await this.deleteMany(toDelete);
  }

  async loadAllPairs(options: LoadOptions = {}) {
    const keys = await this.getAllKeys();
    return await this.loadPairs(keys, options);
  }

  async loadAll(options: LoadOptions = {}) {
    const pairs = await this.loadAllPairs(options);
    return pairs.reduce((acc, [key, { value }]) => ({
      ...acc,
      [key as string]: value
    }));
  }

  async clear(all?: boolean) {
    if (all === true) {
      await AsyncStorage.multiRemove(await this.getAllKeys());
    } else {
      const pairs = await this.loadAllPairs({ includeExpired: true });
      const keysToDelete = pairs
        .filter(([_k, { keep }]) => !keep)
        .map(([k, _]) => k);
      await this.deleteMany(keysToDelete);
    }
  }

  async isOn(key: string) {
    const value = await await this.load(key);
    return value === true;
  }

  async toggle(key: string) {
    if (await this.isOn(key)) {
      await this.toggleOff(key);
    } else {
      await this.toggleOn(key);
    }
  }

  async toggleOn(key: string) {
    await this.save(key, true);
  }

  async toggleOff(key: string) {
    await this.save(key, false);
  }

  async trackFunction(
    key: string,
    fn: (cb: () => void) => void,
    force?: boolean
  ) {
    const toggled = await this.isOn(key);
    if (!force && toggled) return;
    fn(() => void this.toggleOn(key));
  }

  private async garbageCollect() {
    const pairs = await this.loadAllPairs();
    const now = Date.now();
    const toRemove = pairs.reduce<string[]>((arr, [key, { expiresAt }]) => {
      if (expiresAt && expiresAt <= now) arr.push(key);
      return arr;
    }, []);

    if (toRemove.length) {
      void this.deleteMany(toRemove);
    }
  }

  private transformKey(key: string) {
    return key.startsWith(this.prefix) ? key : `${this.prefix}$${key}`;
  }

  private transformKeys(keys: string[]) {
    return keys.map((key) => this.transformKey(key));
  }
}

export const Persistence = new PersistenceService("Persistence");

const cache = new Map<string, string>();

export const InMemory = {
  save<T>(key: string, value: T) {
    cache.set(key, serialize(value, 0, false));
  },
  load<T>(key: string) {
    return deserialize<T>(cache.get(key) ?? null).value;
  },
  delete(key: string) {
    cache.delete(key);
  }
};

export default Persistence;

export function usePersistentState<T>(
  key: string,
  fallbackValue?: T,
  impl?: "in-memory" | "default"
): [T | undefined, (value: T) => void] {
  const storage = impl === "in-memory" ? InMemory : Persistence;
  const [state, setState] = useState(() => ({
    ready: typeof fallbackValue !== "undefined",
    value: fallbackValue
  }));

  const setPersistentValue = useCallback(
    (newValue: T) => {
      void storage.save(key, newValue);
      setState({ ready: true, value: newValue });
    },
    [key, storage]
  );

  useEffect(() => {
    void Promise.resolve(storage.load<T>(key)).then((value) => {
      if (typeof value === "undefined" || value === null) return;
      setState({ ready: true, value });
    });
  }, [key, storage]);

  return [state.ready ? state.value : undefined, setPersistentValue];
}
